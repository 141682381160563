import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Box, Container, Heading, Paragraph, Button, Image,
} from 'base-ui';

import './styles.css';

const UnycosCourseConfirmation = ({
  course: {
    title, by, price, thumb
  },
  actions: {
    confirm, decline
  },
  confirmed,
  children,
  className,
}) => (
  <Box class={cs('course-confirmation white-bg', className)}>
    <Box className="thumb">
      <Box className="thumb-cover"></Box>
      <Image lazy src={thumb} alt="title" loadingProps={{ height: 302, type: 'gradient' }} />
    </Box>
    <Box className="content">
      <Container fluid wrap>
        <Box className="content-header">
          <Box>
            <Heading type="h3" textColor="secondary" className="title letter-spacing__normal normal-font text-uppercase font-size-x-medium without-margin-top without-margin-bottom fade-in">{by}</Heading>
            <Heading type="h4" textColor="secondary" className="desc letter-spacing__normal normal-font text-uppercase without-margin-top margin-bottom-big fade-in delay-1">
              enseña
              {' '}
              <span className="regent-grey-color">{title}</span>
            </Heading>
          </Box>
          <Heading type="h5" className="price without-margin fade-in delay-2" textColor="main">{price}</Heading>
        </Box>
        <Box className="content-footer">
          {!confirm ? (
            <>
              {children}
            </>
          ) : (
            <>
              <Paragraph textColor="text" className="text-uppercase letter-spacing__small fade-in delay-3">¿Es el curso que compraste?</Paragraph>
              <Box className={cs('actions', { confirmed })}>
                <Button
                  tag="button"
                  textColor="main"
                  borderColor="main"
                  size="big"
                  className="left text-uppercase letter-spacing__normal full without-margin fade-in delay-4"
                  onClick={decline}
                >
                  No
                </Button>
                <Button
                  tag="button"
                  textColor="white"
                  color="main"
                  borderColor="main"
                  size="big"
                  className="right text-uppercase letter-spacing__normal full without-margin fade-in delay-5"
                  onClick={confirm}
                >
                  Sí
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Box>
  </Box>
);

UnycosCourseConfirmation.defaultProps = {
  actions: {}
};

UnycosCourseConfirmation.propTypes = {
  course: PropTypes.object,
  actions: PropTypes.object,
  confirmed: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default UnycosCourseConfirmation;
