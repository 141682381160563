import React from 'react';
import {
  Container, Box, StoreCategoryMenu
} from 'base-ui';

const categories = [
  { name: 'Botellas', image: './img/dummy/store-categorias-botellas.jpg', link: '.' },
  { name: 'Tazas', image: './img/dummy/store-categorias-tazas.jpg', link: '.' },
  { name: 'Agendas', image: './img/dummy/store-categorias-agendas.jpg', link: '.' },
  { name: 'Camisetas', image: './img/dummy/store-categorias-camisetas.jpg', link: '.' },
  { name: 'Cuadernos', image: './img/dummy/store-categorias-cuadernos.jpg', link: '.' },
  { name: 'Libretas', image: './img/dummy/store-categorias-libretas.jpg', link: '.' },
  { name: 'Carpetas', image: './img/dummy/store-categorias-carpetas.jpg', link: '.' },
  { name: 'Bolígrafos', image: './img/dummy/store-categorias-boligrafos.jpg', link: '.' },
  { name: 'Libro', image: './img/dummy/store-categorias-libro.jpg', link: '.' },
];

const StoreHome = () => (
  <Container>
    <Box className="store-category-menu">
      <StoreCategoryMenu categories={categories} />
    </Box>
  </Container>
);

export default StoreHome;
