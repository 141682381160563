import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Icon,
  Card,
  Heading,
  Paragraph,
  NavLink
} from 'base-ui';

const AccessNotAllowed = () => (

  <PageWrapper config={{ title: 'Consultation Access Not Allowed', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section">
        <Container container fluid wrap>
          <Row className="center-xs">
            <Col xs="12" md="10">
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Algo esta mal...</Box>
                </Box>
                <Box className="align-center">
                  <Icon prefix="far" name="hand-paper" className="regent-grey-color x-big" />
                  <Heading type="h2" className="font-size-big without-margin-bottom">Lo sentimos, no tienes acceso a esta sección.</Heading>
                  <Paragraph>Si crees que esto es un error puedes contactarnos y exponer tu caso.</Paragraph>
                  <NavLink to="#" className="btn red-bg red-border big white-color">
                    <Icon prefix="fas" name="arrow-left" />
                    {' '}
                    Regresar
                  </NavLink>
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default AccessNotAllowed;
