import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Heading,
  Box,
} from 'base-ui';

// Blog Components
import BlogMenu from '../../../components/Blog/BlogMenu';
import Payment from '../../../components/Payment';

const BlogPayment = () => {
  const config = {
    title: '¡Que no se te escape ningún artículo!',
  };

  return (
    <>
      <BlogMenu />
      <PageWrapper config={config}>
        <Container fluid wrap>
          <Box tag="main" className="full-width-bg big white-bg">
            <Heading type="h1" size="big" className="align-center text-uppercase normal-font">Completa Tu Compra</Heading>
          </Box>
        </Container>
        <Payment isUnycos={false} />
      </PageWrapper>
    </>
  );
};

export default BlogPayment;
