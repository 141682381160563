import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Heading,
  Paragraph,
  Row,
  Col,
  Box
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';

const config = {
  title: 'Términos y condiciones de uso',
};

const BlogPage = () => (
  <React.Fragment>
    <BlogMenu />
    <PageWrapper config={config}>
      <Container fluid wrap>
        <Box tag="main" id="site-main">
          <Row className="center-xs">
            <Col xs="12">
              <Box tag="section" id="single" className="single-post">
                <Box className="main-post">
                  <Heading type="h1" className="single-title">{config.title}</Heading>
                  <Box className="the-content">
                    <Heading type="h2">1. Datos de identificación y propiedad del sitio web</Heading>
                    <Paragraph>
                      Según la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, se informa que el titular de la página web es GRUPO M CONTIGO SL con CIF B37527900 con domicilio en la localidad de Calle Concejo, 13, 2ºA, 37002 Salamanca e Inscrita en el Registro Mercantil de Salamanca, fecha 20-08-2013, Folio 146, Tomo 442, Libro 0, Hoja SA-15157 y con correo electrónico de contacto: contacto@mcontigo.com.
                    </Paragraph>
                    <ul>
                      <li>
                        <strong>Propietario:</strong>
                        {' '}
                        Grupo MContigo S.L.
                      </li>
                      <li>
                        <strong>Oficina:</strong>
                        {' '}
                        Calle Concejo 13, 37002 – Salamanca (España).
                      </li>
                      <li>
                        <strong>NIF:</strong>
                        {' '}
                        B-37527900
                      </li>
                      <li>
                        <strong>Información de registro mercantil:</strong>
                        {' '}
                        Registrado en Salamanca, fecha 20-08-2013, Folio
                      </li>
                      <li>
                        146, Tomo 442, Libro 0, Hoja SA-15157
                      </li>
                      <li>
                        <strong>E-mail:</strong>
                        {' '}
                        contacto@mcontigo.com
                      </li>
                    </ul>
                    <Heading type="h2">2. Aceptación del usuario</Heading>
                    <Paragraph>
                      Estos términos y condiciones de uso regulan la utilización del sitio web www.mejorconsalud.com de GRUPO M CONTIGO SL.
                    </Paragraph>
                    <Paragraph>
                      El acceso y uso del sitio web implica la aceptación, sin reserva ni limitación, de los presentes términos y condiciones de uso, la política de privacidad y su uso está supeditado a las condiciones que se expresan a continuación, así como a la legislación vigente.
                    </Paragraph>
                    <Paragraph>
                      GRUPO M CONTIGO SL se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida en la página web, así como de la configuración y presentación de esta y de las condiciones de uso y acceso a la misma. El usuario quedará obligado automáticamente por las condiciones de uso que se hallen vigentes en el momento en que acceda a la web, por lo que deberá leer periódicamente los términos y condiciones de uso.
                    </Paragraph>
                    <Heading type="h2">3. Legislación</Heading>
                    <Paragraph>
                      Con carácter general las relaciones entre GRUPO M CONTIGO SL con los Usuarios de sus servicios telemáticos, presentes en este sitio web, se encuentran sometidas a la legislación y jurisdicción españolas.
                    </Paragraph>
                    <Heading type="h2">4. Uso y acceso de usuarios</Heading>
                    <Paragraph>
                      El usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con GRUPO M CONTIGO SL o cualquiera de sus delegaciones.
                    </Paragraph>
                  </Box>
                </Box>
              </Box>
            </Col>
          </Row>
        </Box>
      </Container>
    </PageWrapper>
  </React.Fragment>
);

export default BlogPage;
