import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  NavLink,
  Heading,
  Image,
  Paragraph,
  Button,
  Card
} from 'base-ui';

const CardContentPost = ({ className, titleType, post }) => (
  <Card className={`content-post news ${className}`}>
    <Box className="thumb">
      <NavLink href={post.url}>
        <Image src={post.thumb} alt={`Thumb article ${post.title}`} />
      </NavLink>
    </Box>
    <Box className="caption">
      {post.category && (
        <Box className="metas">
          <span className="meta cat">
            <NavLink href={post.url}>{post.category}</NavLink>
          </span>
        </Box>
      )}
      <Heading type={titleType} className="title">
        <NavLink to={post.url}>
          {post.title}
        </NavLink>
      </Heading>
      {post.excerpt && (
        <>
          <Paragraph className="excerpt regent-grey-color">
            <NavLink href={post.url}>
              {post.excerpt}
            </NavLink>
          </Paragraph>
          <Button color="main" textColor="white">Leer más</Button>
        </>
      )}
    </Box>
  </Card>
);

CardContentPost.defaultProps = {
  titleType: 'h4',
  className: '',
};

CardContentPost.propTypes = {
  post: PropTypes.object.isRequired,
  titleType: PropTypes.string,
  className: PropTypes.string,
};

export default CardContentPost;
