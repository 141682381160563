import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Icon
} from 'base-ui';

class UnycosMyCertificates extends Component {
  state = {
    sectionTitle: 'Mis Certificados',
  }

  render() {
    const { sectionTitle } = this.state;

    return (
      <section id="my-certificates" className="fade-exit-active">

        <span className="box-title">{sectionTitle}</span>
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="table-container">
                <table className="history-table certificate-table" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Curso</th>
                      <th>Fecha de Conclusión</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="no-wrap">Maquillaje</td>
                      <td>31 de Mar, 2017</td>
                      <td className="action-cell">
                        <Link to="/unycos/my-account/request-certificate" className="btn orange square hover-effect">Solicitar Certificado</Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="no-wrap">Maquillaje Creativo</td>
                      <td>20 de Feb, 2017</td>
                      <td className="action-cell">
                        <Link to="/unycos/my-account/request-certificate" className="btn orange square hover-effect">Solicitar Certificado</Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="no-wrap">Desarrollo Personal</td>
                      <td>17 de Feb, 2017</td>
                      <td className="action-cell">
                        <Link to="./assets/images/img_certificate.jpg" download target="_blank" className="table-link table-link--download" title="Download">
                          <Icon prefix="fas" name="download" size="medium" title="Download"></Icon>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default UnycosMyCertificates;
