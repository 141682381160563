import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Box, Container, Row, Col, Heading, InputField, Button, Icon, Paragraph
} from 'base-ui';

import './styles.css';

const UnycosCouponForm = ({
  value, status, onSubmit,
}) => {
  const [coupon, setCoupon] = useState(value);

  return (
    <Container fluid wrap className="coupon-form">
      <Row className="center-xs">
        <Col xs="12" md="4">
          <Heading
            type="h3"
            textColor="regent-grey"
            size="medium"
            className="text-uppercase with-letter-spacing normal-font"
          >
            ¿Tienes un Cupón?
          </Heading>
        </Col>
        <Col xs="12" md="8">
          <Row>
            <Col xs="12" md="7" className={cs({ 'coupon-error': status === 'error' })}>
              <InputField
                name="coupon"
                type="text"
                onChange={(v) => setCoupon(v)}
                required
                full
                placeholder="CÓDIGO DE CUPÓN"
                className="without-shadow rounded-small full text-uppercase letter-spacing__small"
              />
              {status === 'error' && (
                <Box className="coupon-error-message">
                  <Paragraph textColor="red" size="small" className="text-uppercase">
                    <Icon name="exclamation-triangle" prefix="fas" size="small" />
                    {' '}
                    Cupón no Válido
                  </Paragraph>
                </Box>
              )}
            </Col>
            <Col xs="12" md="5">
              <Button
                type="submit"
                tag="button"
                color="main"
                borderColor="main"
                textColor="white"
                size="medium"
                className="text-uppercase full with-letter-spacing bt-click"
                onClick={() => onSubmit(coupon)}
              >
                Comienza Ahora
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

UnycosCouponForm.propTypes = {
  value: PropTypes.string,
  status: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default UnycosCouponForm;
