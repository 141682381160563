import React from 'react';

import {
  Box,
  Container,
  Row,
  Col,
  NavLink,
  Heading,
  Icon,
} from 'base-ui';

import BlogMenu from '../../components/Blog/BlogMenu';
import BlogAdvertising from '../../components/Blog/BlogAdvertising';
import BlogCategoryHero from '../../components/Blog/BlogCategoryHero';
import BlogCategorySlider from '../../components/Blog/BlogCategorySlider';
import AccordionFaq from '../../components/Blog/AccordionFaq';
import BlogMorePostsList from '../../components/Blog/BlogMorePostsList';
import BlogWidgetPosts7 from '../../components/Blog/BlogWidgetPosts7';
import BlogWidgetPosts from '../../components/Blog/BlogWidgetPosts';

const BlogNewPage = () => {
  const contentSlider = [
    {
      id: '1',
      title: 'El valle de Spiti, un lugar lleno de aventura',
      time: '0 min',
      image: './img/dummy/post-1.png'
    },
    {
      id: '2',
      title: 'Un lugar lleno de aventura',
      time: '1 min',
      image: './img/dummy/video-1.png'
    },
    {
      id: '3',
      title: 'Valle de Spiti, un lugar lleno',
      time: '3 min',
      image: './img/dummy/service-3.png'
    },
    {
      id: '4',
      title: 'El valle de Spiti, un lugar lleno de aventura',
      time: '12 min',
      image: './img/dummy/featured-posts-5.png'
    },
    {
      id: '5',
      title: 'El valle de Spiti, un lugar lleno de aventura',
      time: '34 min',
      image: './img/dummy/featured-posts-6.png'
    },
    {
      id: '6',
      title: 'El valle de Spiti, un lugar lleno de aventura',
      time: '45 min',
      image: './img/dummy/featured-posts-7.png'
    }
  ];
  const contentAccordion = [
    {
      id: '1',
      title: '¿10 maneras de comenzar una rutina y no morir intento?',
      desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
      image: './img/dummy/post-1.png'
    },
    {
      id: '2',
      title: 'Un lugar lleno de aventura?',
      desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
      image: './img/dummy/post-1.png'
    },
    {
      id: '3',
      title: '¿Qué es la rinoplastia y cuáles son sus cuidados?',
      desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
      image: './img/dummy/post-1.png'
    },
    {
      id: '4',
      title: '¿Qué son los excipientes de los medicamentos?',
      desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
      image: './img/dummy/post-1.png'
    },
    {
      id: '5',
      title: '¿Puede ayudar el té verde al tratamiento del acné?',
      desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
      image: './img/dummy/post-1.png'
    },
    {
      id: '6',
      title: '¿Por qué se cae más el pelo en otoño?',
      desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
      image: './img/dummy/post-1.png'
    }
  ];
  return (
    <React.Fragment>

      <BlogMenu />

      <Box id="wrapper" className="category-home">

        <BlogCategoryHero title="Dieta" text="Toda dieta debe ser saludable y cumplir el objetivo propuesto: perder peso, cubrir un déficit nutricional, reducir el colesterol y triglicéridos, mejorar la digestión, reducir la inflamación, etc. En nuestro espacio te ofrecemos las mejores propuestas para todas tus necessidades. Descubre qué dieta es la que más puede ayudarte en el día de hoy." />

        <main id="site-main">

          <Container fluid wrap>

            <BlogAdvertising />

            <BlogCategorySlider content={contentSlider} />

            <Box tag="section">
              <Row>
                <Col xs="12" md="6">

                  <BlogWidgetPosts title="ARTÍCULOS INTERESANTES" styleGroup="8" nrPosts={4} />

                </Col>
                <Col xs="12" md="6" className="first-xs last-md">
                  <Box className="section-title main-border">
                    <NavLink to="#">
                      <Heading type="h2" className="label text-uppercase titles-color font-normal-small">Preguntas Frecuentes</Heading>
                      <Icon prefix="fas" name="chevron-right" />
                    </NavLink>
                  </Box>

                  <AccordionFaq content={contentAccordion} />

                </Col>
              </Row>
            </Box>

            <BlogAdvertising />

            <BlogWidgetPosts title="SALUD" styleGroup="1" nrPosts={4} />
            <BlogWidgetPosts title="REMEDIOS NATURALES" styleGroup="6" nrPosts={5} />

            <BlogAdvertising />

            <BlogWidgetPosts title="BIENESTAR" styleGroup="2" nrPosts={4} />
            <BlogWidgetPosts title="DIETA" styleGroup="3" nrPosts={4} />

            <BlogAdvertising />

            <BlogWidgetPosts7 />

            <BlogMorePostsList />

          </Container>

        </main>

      </Box>
    </React.Fragment>
  );
};

export default BlogNewPage;
