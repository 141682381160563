import React from 'react';

import {
  Box,
  Paragraph
} from 'base-ui';

const CourseExcerptMobile = () => (
  <Box className="hidden-lg">
    <Paragraph className="alpha-color">Dominar la palabra hablada constituye una herramienta imprescindible para el ejercicio de cualquier profesión, y para el desarrollo de todas las relaciones personales y sociales. De continuo surgen oportunidades de hablar en público, en privado, en radio y en televisión. En cualquier situación, el que sabe comunicarse aventaja a los demás.</Paragraph>
  </Box>
);

export default CourseExcerptMobile;
