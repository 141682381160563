import React, { Component } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Badge,
  Icon,
  Heading,
  Lists,
  Image,
  Modal,
  NavLink,
  InputField,
  Label,
  Tabs,
  InputSelect,
  Button,
  Message,
  Paragraph
} from 'base-ui';

// Components
import MenuConsultations from '../../../components/Consultation/MenuConsultations';

class ScheduleManage extends Component {
  state = { showModalAvailability: false, showModalLocation: false };

  openModalAvailability= () => {
    this.setState({ showModalAvailability: true });
  }

  closeModalAvailability = () => {
    this.setState({ showModalAvailability: false });
  }

  openModalLocation= () => {
    this.setState({ showModalAvailability: true });
  }

  closeModalLocation= () => {
    this.setState({ showModalAvailability: false });
  }

  render() {
    const {
      showModalAvailability, showModalLocation
    } = this.state;
    const styleModalAvailability = {
      overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.80)',
        zIndex: '9999'
      },
      content: {
        position: 'absolute',
        right: '10px',
        left: '10px',
        bottom: '20px',
        margin: 'auto',
        boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
        overflow: 'auto',
        padding: '10px',
        borderRadius: '5px',
        maxWidth: '990px',
        display: 'block'
      }
    };
    const styleModalLocation = {
      overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.80)',
        zIndex: '9999'
      },
      content: {
        position: 'absolute',
        right: '10px',
        left: '10px',
        bottom: '20px',
        margin: 'auto',
        boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
        overflow: 'auto',
        padding: '10px',
        borderRadius: '5px',
        maxWidth: '990px',
        display: 'block'
      }
    };
    return (
      <PageWrapper config={{ title: 'Consultation Schedule Manage', bodyClasses: 'consultation' }}>

        <Box tag="main" id="site-main">

          <Box tag="section" className="consultation-consults">
            <Container container fluid wrap>
              <Row className="center-xs">
                <Col xs="12" sm="4">
                  <MenuConsultations />
                </Col>
                <Col xs="12" sm="8">

                  <Card className="medium">
                    <Row>
                      <Box xs="12" sm="2" md="1" className="align-center">
                        <Icon prefix="fas" name="question-circle" className="big margin-bottom-normal main-color" />
                      </Box>
                      <Box xs="12" sm="10" md="11">
                        <Heading type="h2" className="font-x-big without-margin-top">¡Bienvenido a tu agenda!</Heading>
                        <Paragraph>En esta pantalla puedes gestionar los días y horarios para ofrecer tus consultas. Cuántas más horas indiques, más posibilidades habrá de aumentar tu número de clientes.</Paragraph>
                        <Paragraph>A continuación te indicamos los pasos a seguir para configurar tu agenda.</Paragraph>
                        <Lists type="ol">
                          <li>Selecciona los días que tienes disponible</li>
                          <li>Haz clic sobre cada día para indicar los horarios</li>
                        </Lists>
                      </Box>
                    </Row>
                  </Card>

                  {/* <!-- Start availability widget --> */}
                  <Card>
                    <Box className="box-title grey-bg">
                      <Box tag="span" className="label main-font">Gestión de agenda</Box>
                    </Box>

                    <Button color="white" borderColor="white" textColor="main">
                      <Icon prefix="fas" name="chevron-left" />
                    </Button>

                    <Tabs>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Lun.</small>
                            <Badge rounded>14</Badge>
                          </React.Fragment>
                        )}
                      >
                        <Row>
                          <Col xs="12" sm="6">
                            <Card>
                              <Row className="middle-xs align-center">
                                <Box xs="12" md="5" className="align-center">
                                  <Box tag="span">12:30 - 2:30</Box>
                                </Box>
                                <Box xs="12" md="7" className="align-center">
                                  <Button to="#" borderColor="transparent" color="main"><Icon prefix="fas" name="fa-globe" /></Button>
                                  <Button to="#" borderColor="transparent" color="main"><Icon prefix="fas" name="edit" /></Button>
                                  <Button to="#" borderColor="transparent" color="main"><Icon prefix="fas" name="trash-alt" /></Button>
                                </Box>
                              </Row>
                            </Card>
                          </Col>
                          <Col xs="12" sm="6">
                            <Card>
                              <Row className="middle-xs align-center">
                                <Box xs="12" md="5" className="align-center">
                                  <Box tag="span">2:30 - 4:30</Box>
                                </Box>
                                <Box xs="12" md="7" className="align-center">
                                  <Button to="#" borderColor="transparent" color="main"><Icon prefix="fas" name="globe" /></Button>
                                  <Button to="#" borderColor="transparent" color="main"><Icon prefix="fas" name="edit" /></Button>
                                  <Button to="#" borderColor="transparent" color="main"><Icon prefix="fas" name="trash-alt" /></Button>
                                </Box>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Mar.</small>
                            <Badge rounded>15</Badge>
                          </React.Fragment>
                        )}
                      >
                        <Message borderColor="blue" iconName="info-circle" textColor="text">
                          Sin nada este día
                        </Message>
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Mie.</small>
                            <Badge rounded>17</Badge>
                          </React.Fragment>
                        )}
                      >
                        <Message borderColor="blue" iconName="info-circle" textColor="text">
                          Sin nada este día
                        </Message>
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Jue.</small>
                            <Badge rounded>18</Badge>
                          </React.Fragment>
                        )}
                      >
                        <Message borderColor="blue" iconName="info-circle" textColor="text">
                          Sin nada este día
                        </Message>
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Vie.</small>
                            <Badge rounded>19</Badge>
                          </React.Fragment>
                        )}
                      >
                        <Message borderColor="blue" iconName="info-circle" textColor="text">
                          Sin nada este día
                        </Message>
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Sab.</small>
                            <Badge rounded>20</Badge>
                          </React.Fragment>
                        )}
                      >
                        <Message borderColor="blue" iconName="info-circle" textColor="text">
                          Sin nada este día
                        </Message>
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Dom.</small>
                            <Badge rounded>21</Badge>
                          </React.Fragment>
                        )}
                      >
                        <Message borderColor="blue" iconName="info-circle" textColor="text">
                          Sin nada este día
                        </Message>
                      </Box>
                    </Tabs>

                    <Button color="white" borderColor="white" textColor="main">
                      <Icon prefix="fas" name="chevron-right" />
                    </Button>

                  </Card>
                  {/* <!-- End availability widget --> */}

                  <Card>
                    <Box className="box-title grey-bg">
                      <Box tag="span" className="label main-font">Próximas reservas</Box>
                    </Box>
                    <Row className="center-xs">
                      <Box xs="12" md="7">
                        <Card className="main-border align-center">
                          <Paragraph className="font-size-x-medium main-color">No tienes consultas agendadas aún.</Paragraph>
                          <Button to="#" color="main" border="main" textColor="white" className="medium">
                            Ver catalogo
                            <Icon prefix="fas" name="arrow-right" />
                          </Button>
                        </Card>
                      </Box>
                    </Row>
                  </Card>

                  <Card>
                    <Box className="box-title grey-bg">
                      <Box tag="span" className="label main-font">Próximas reservas</Box>
                    </Box>
                    <Row>
                      <Box xs="3" sm="2">
                        <Box className="display-block align-center">
                          <Box tag="span">Jue</Box>
                          <Box tag="span" className="font-m-big badge rounded grey-bg">28</Box>
                        </Box>
                      </Box>
                      <Box xs="9" sm="10">
                        <Row>
                          <Box xs="12" sm="5">
                            <Box className="thumb">
                              <Image src="./img/dummy/service-3.png" alt="img service" />
                            </Box>
                          </Box>
                          <Col xs="12" sm="7">
                            <NavLink to="single">
                              <Heading type="h2" className="title font-m-big without-margin">Ser feliz en pareja</Heading>
                            </NavLink>
                            <Box className="metas">
                              <strong className="badge grey-bg rounded">
                                Presencial
                              </strong>
                              <Button to="#" onClick="modal-myclinics" borderColor="transparent" color="main">
                                <Icon prefix="fas" name="store-alt" />
                                {' '}
                                Ver dirección
                              </Button>
                            </Box>
                            <Box tag="span" className="display-block">
                              Por
                              <strong>Sara Gonzalez</strong>
                            </Box>
                            <small>18h - 19h</small>
                          </Col>
                          <Col xs="12" className="end-xs">
                            <hr />
                            <Button to="#" borderColor="grey">
                              <Icon prefix="far" className="main" name="facomment" />
                              {' '}
                              Mensaje
                            </Button>
                            <Button to="#" borderColor="grey">
                              <Icon prefix="far" className="main" name="file" />
                              {' '}
                              Factura
                            </Button>
                            <Button to="#" borderColor="grey">
                              <Icon prefix="fas" className="main" name="ban" />
                              {' '}
                              Cancelar
                            </Button>
                          </Col>
                        </Row>
                      </Box>
                    </Row>

                    <hr />

                    <Row>
                      <Col xs="3" sm="2">
                        <Box className="display-block align-center">
                          <Box tag="span">Vie</Box>
                          <Box tag="span" className="font-m-big badge rounded grey-bg">29</Box>
                        </Box>
                      </Col>
                      <Col xs="9" sm="10">
                        <Row>
                          <Col xs="12" sm="5">
                            <Box className="thumb">
                              <Image src="./img/dummy/service-2.png" alt="img service" />
                            </Box>
                          </Col>
                          <Col xs="12" sm="7">
                            <NavLink to="single">
                              <Heading type="h2" className="title font-m-big without-margin">Ser feliz en pareja</Heading>
                            </NavLink>
                            <Box className="metas">
                              <strong className="badge grey-bg rounded">
                                Presencial
                              </strong>
                              <Button to="#" onClick="modal-myclinics" borderColor="transparent" color="main">
                                <Icon prefix="fas" name="store-alt" />
                                {' '}
                                Ver dirección
                              </Button>
                            </Box>
                            <Box tag="span" className="display-block">
                              Por
                              <strong>Sara Gonzalez</strong>
                            </Box>
                            <small>18h - 19h</small>
                          </Col>
                          <Col xs="12" className="end-xs">
                            <hr />
                            <Button to="#" borderColor="grey">
                              <Icon prefix="far" className="main" name="facomment" />
                              {' '}
                              Mensaje
                            </Button>
                            <Button to="#" borderColor="grey">
                              <Icon prefix="far" className="main" name="file" />
                              {' '}
                              Factura
                            </Button>
                            <Button to="#" borderColor="grey">
                              <Icon prefix="fas" className="main" name="ban" />
                              {' '}
                              Cancelar
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <Box className="align-center">
                      <Button full textColor="white" border="main" color="main">Cargar más</Button>
                    </Box>
                  </Card>


                  {/* ini #modal-location-map */}
                  <Modal
                    idModal="modal-location-map"
                    isOpen={showModalLocation}
                    appElement="#root"
                    className="modal"
                    onAfterOpen={this.afterOpenModal}
                    closeModal={this.closeModalLocation}
                    closeIcon={false}
                    styles={styleModalLocation}
                  >
                    <Tabs>
                      <Box
                        label={(
                          <React.Fragment>
                            <strong className="main-color">MyClinic 1</strong>
                            <Box tag="span">Salamanca, Spain</Box>
                          </React.Fragment>
                        )}
                      >
                        <Box className="box-title">
                          <Icon prefix="fas" name="location-arrow" className="icon" />
                          <Box tag="span" className="label">
                            <Heading type="h2" className="text-uppercase without-margin white-color">MyClinic 1</Heading>
                            <Box tag="span" className="main-font normal-font">
                                MyAddress Streen, 89 - Salamanca, ES
                            </Box>
                          </Box>
                        </Box>
                        <Box className="subline">
                          <Box tag="span" className="meta white-color">
                            <Icon prefix="fas" name="phone" />
                            {' '}
                            +34 493 89 76 69
                          </Box>
                          <Box tag="span" className="meta white-color">
                            <Icon prefix="fas" name="envelope" />
                            {' '}
                            MyClinic@gmail.com
                          </Box>
                        </Box>
                        <Box className="map">
                          <iframe title="iframe map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.69269173511!2d-5.666579984874644!3d40.96631052995562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f26167e8474f3%3A0xe4de0bd19319fabf!2sGrupo+MContigo!5e0!3m2!1ses-419!2sco!4v1534822774222" width="600" height="450" frameBorder="0" allowFullScreen></iframe>
                        </Box>
                      </Box>
                    </Tabs>
                  </Modal>
                  {/* end #modal-location-map  --> */}

                  {/* ini #modal-availability --> */}
                  <Modal
                    isOpen={showModalAvailability}
                    appElement="#root"
                    idModal="modal-availability"
                    className="modal"
                    onAfterOpen={this.afterOpenModal}
                    closeModal={this.closeModalAvailability}
                    closeIcon={false}
                    styles={styleModalAvailability}
                  >
                    <Box className="align-center font-size-medium margin-bottom-small"><strong>Julio 2019</strong></Box>
                    <Button color="white" borderColor="white" textColor="main">
                      <Icon prefix="fas" name="chevron-left" />
                    </Button>
                    <Tabs>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Lun.</small>
                            <Badge rounded>14</Badge>
                          </React.Fragment>
                        )}
                      >
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Mar.</small>
                            <Badge rounded>15</Badge>
                          </React.Fragment>
                        )}
                      >
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Mie.</small>
                            <Badge rounded>17</Badge>
                          </React.Fragment>
                        )}
                      >
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Jue.</small>
                            <Badge rounded>18</Badge>
                          </React.Fragment>
                        )}
                      >
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Vie.</small>
                            <Badge rounded>19</Badge>
                          </React.Fragment>
                        )}
                      >
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Sab.</small>
                            <Badge rounded>20</Badge>
                          </React.Fragment>
                        )}
                      >
                      </Box>
                      <Box
                        label={(
                          <React.Fragment>
                            <small className="display-block align-center">Dom.</small>
                            <Badge rounded>15</Badge>
                          </React.Fragment>
                        )}
                      >
                      </Box>
                    </Tabs>
                    <Button color="white" borderColor="white" textColor="main">
                      <Icon prefix="fas" name="chevron-right" />
                    </Button>

                    <Card>
                      <Row className="middle-xs">
                        <Col xs="6" className="margin-bottom-small">
                          <InputSelect
                            label="Desde:"
                            name="since"
                            options={[
                              { value: '1330', label: '13:30' },
                              { value: '1400', label: '14:00' },
                            ]}
                          />
                        </Col>
                        <Col xs="6" className="end-xs margin-bottom-small">
                          <InputSelect
                            label="Hasta:"
                            name="since"
                            options={[
                              { value: '1530', label: '15:30' },
                              { value: '1600', label: '16:00' },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row className="middle-xs">
                        <Col xs="12" sm="6" className="margin-bottom-small">
                          <InputField
                            name=""
                            id="c2"
                            type="checkbox"
                            required
                          />
                          <Label inputId="c2">
                            Copiar la misma disponibilidad para:
                          </Label>
                        </Col>
                        <Col xs="12" sm="6" className="margin-bottom-small">
                          <InputSelect
                            label="Full:"
                            name="dates1"
                            options={[
                              { value: 'abc', label: 'Todos los días laborales (L - V)' },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Row className="middle-xs">
                        <Col xs="12" sm="6" className="margin-bottom-small">
                          <InputField
                            name=""
                            id="c3"
                            type="checkbox"
                            required
                          />
                          <Label inputId="c3">
                            Copiar la misma configuración para:
                          </Label>
                        </Col>
                        <Col xs="12" sm="6" className="margin-bottom-small">
                          <InputSelect
                            label="Full:"
                            name="dates2"
                            options={[
                              { value: 'abc', label: 'Los próximos 3 meses' },
                            ]}
                          />
                        </Col>
                      </Row>
                      <Button color="main" borderCorlor="main" textColor="white">Guardar</Button>
                    </Card>
                  </Modal>
                  {/* end #modal-availability --> */}


                </Col>
              </Row>
            </Container>
          </Box>

        </Box>
        {/* end main section */}

      </PageWrapper>
    );
  }
}

export default ScheduleManage;
