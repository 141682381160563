import React, { Component } from 'react';
import { NavLink } from 'base-ui';

class BlogLatestPostsBasic extends Component {

	render() {
		return(
			<section id="latest-posts-widget" className="widget posts-style-9">

        <div className="posts-row">

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img src="./img/dummy/post-13.png" className="thumb-bg" alt="" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  10 maneras de comenzar una rutina diaria y no morir en el intento
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img src="./img/dummy/post-14.png" className="thumb-bg" alt="" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img src="./img/dummy/post-15.png" className="thumb-bg" alt="" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img src="./img/dummy/post-16.png" className="thumb-bg" alt="" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

        </div>

      </section>
		)
	}
}

export default BlogLatestPostsBasic;
