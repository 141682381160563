import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Icon,
  Button,
  Row,
  Col
} from 'base-ui';


const SidebarWidgetInfoCMobile = (props) => {
  const {
    className
  } = props;
  return (
    <Box className={`widget sticky box-group-discount box-group-discount-mobile ${className}`}>
      <Card className="white-bg white-border small">
        <Row className="middle-xs">
          <Col xs="3" sm="2">
            <Box tag="del" className="text-old-price font-size-normal text-color">59.90 US$</Box>
            <Box tag="strong" className="text-discount titles-color">39.90 US$</Box>
          </Col>
          <Col xs="7" sm="8">
            <Button color="darkgray" className="text-uppercase bt-buy" textColor="white" borderColor="darkgray" size="big" full>
              Comprar ahora
            </Button>
          </Col>
          <Col xs="2">
            <Button color="white" textColor="secondary" borderColor="secondary" size="big" full className="center-xs">
              <Icon prefix="fas" name="gift" />
            </Button>
          </Col>
        </Row>
      </Card>
    </Box>
  );
};

SidebarWidgetInfoCMobile.propTypes = {
  className: PropTypes.string
};

export default SidebarWidgetInfoCMobile;
