import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Box,
  Card,
  Button,
  Heading,
  Image,
  Icon,
  Paragraph
} from 'base-ui';

import './styles.css';

const Psychologist = ({
  name, role, profileImage, price, estimedTime, recommended, onSelect
}) => (
  <Card className={cs('psy-card', { recommended })}>
    {recommended && (
      <Box className="recommended-top yellow-bg white-color align-center">
        <Icon name="star" prefix="fas" size="small" color="white" />
        {' '}
        <strong>Recomendado para ti</strong>
      </Box>
    )}
    <Box className="psy">
      <Image src={profileImage} className="psy-avatar" />
      <Box className="psy-info">
        <Heading type="h3" size="x-medium" textColor="text" className="without-margin">{name}</Heading>
        <Heading type="h4" size="normal" textColor="secondary" className="without-margin">{role}</Heading>
        <Paragraph className="text-psy" textColor="text">
          <span>
            <Icon name="money-bill-alt" prefix="far" size="small" />
            {' '}
            {price}
            {' '}
            / consulta
          </span>
          <span>
            <Icon name="clock" prefix="far" size="small" />
            {' '}
            {estimedTime}
          </span>
        </Paragraph>
      </Box>
      <Box className="psy-button">
        <Button
          type="button"
          color="secondary"
          textColor="white"
          borderColor="secondary"
          className="without-margin"
          onClick={() => onSelect(name, role, price, estimedTime)}
          style={{ margin: '0' }}
        >
          Seleccionar
        </Button>
      </Box>
    </Box>
  </Card>
);

Psychologist.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  profileImage: PropTypes.string,
  price: PropTypes.string,
  estimedTime: PropTypes.string,
  recommended: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default Psychologist;
