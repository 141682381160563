import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Heading,
  Paragraph,
  Row,
  Col,
  Box,
  Image
} from 'base-ui';

// Blog Components
import BlogMenu from '../../../components/Blog/BlogMenu';
import Subscription from '../../../components/Blog/Subscription';

const BlogSubscription = () => {
  const config = {
    title: '¡Que no se te escape ningún artículo!',
  };

  const plans = [
    {
      name: 'Premium',
      period: 'mes',
      image: './img/reading-2.svg',
      color: 'secondary',
      price: '4.99€',
      advantages: [
        {
          icon: 'bookmark',
          prefix: 'fas',
          text: 'Guarda tus artículos favoritos en una lista personalizada'
        },
        {
          icon: 'plane',
          prefix: 'fas',
          text: 'Lee offline en nuestra app'
        },
        {
          icon: 'no-ad',
          text: '¡Sin anuncios!'
        }
      ]
    },
    {
      name: 'Ultra',
      period: 'mes',
      color: 'golden',
      price: '14.99€',
      ultra: true,
      advantages: [
        {
          icon: 'bookmark',
          prefix: 'fas',
          text: 'Guarda tus artículos favoritos en una lista personalizada'
        },
        {
          icon: 'plane',
          prefix: 'fas',
          text: 'Lee offline en nuestra app'
        },
        {
          icon: 'no-ad',
          text: '¡Sin anuncios!'
        }
      ]
    },
    {
      name: 'Premium',
      period: 'ano',
      image: './img/reading-3.svg',
      color: 'main',
      price: '20% off - 47.90€',
      advantages: [
        {
          icon: 'tag',
          prefix: 'fas',
          text: '20% de descuento',
          isBold: true
        },
        {
          icon: 'bookmark',
          prefix: 'fas',
          text: 'Guarda tus artículos favoritos en una lista personalizada'
        },
        {
          icon: 'plane',
          prefix: 'fas',
          text: 'Lee offline en nuestra app'
        },
        {
          icon: 'no-ad',
          text: '¡Sin anuncios!'
        }
      ]
    },
  ];

  return (
    <>
      <BlogMenu />
      <PageWrapper config={config}>
        <Container fluid wrap>
          <Box tag="main" className="margin-top-x-big margin-bottom-x-big">
            <Row className="center-xs">
              <Col xs="12">
                <Box tag="section" className="info-box">
                  <Row>
                    <Col xs="12" sm="3">
                      <Image src="./img/reading.svg" alt="Reading Illustration" />
                    </Col>
                    <Col xs="12" sm="9">
                      <Heading type="h1" className="margin-bottom-medium">{config.title}</Heading>
                      <Paragraph className="margin-bottom-medium">
                        ¿Has encontrado algo interesante? ¡Guárdalo en tus favoritos! Y si eres Usuario Premium, utiliza
                        los marcadores para acceder a tu lista de artículos!
                      </Paragraph>
                      <Paragraph>
                        Si quieres guardar un artículo, simplemente haz clic en  bookmark  y empieza a crear tu biblioteca
                        personalizada de nuestros contenidos.
                      </Paragraph>
                    </Col>
                  </Row>
                </Box>
                <Box tag="section" className="margin-top-x-big align-center">
                  <Heading type="h2" size="big">Explora tu mente sin límites</Heading>
                  <Paragraph>
                    Descubre, aprende y disfruta de artículos escritos y avalados por psicólogos y profesionales de la
                    salud y el ámbito social. Somos contenidos líderes en el campo de la divulgación informativa sobre
                    psicología gracias a nuestros lectores.
                  </Paragraph>
                  <Box className="margin-top-x-big">
                    <Row>
                      {plans.map(({
                        name, period, image, advantages, color, price, ultra
                      }) => (
                        <Col xs="12" sm="4" key={name}>
                          <Subscription
                            name={name}
                            period={period}
                            image={image}
                            advantages={advantages}
                            color={color}
                            price={price}
                            ultra={ultra}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Box>
                </Box>
              </Col>
            </Row>
          </Box>
        </Container>
      </PageWrapper>
    </>
  );
};

export default BlogSubscription;
