import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Box,
  Card,
  Heading,
  Paragraph,
  NavLink,
  Image,
  Icon,
} from 'base-ui';

const CourseUser = (props) => {
  const {
    title, thumb, professional, finishOn, btLink, btLabel, progress, buttons, modules
  } = props;

  return (
    <Card>
      <Row className="middle-xs">
        <Col xs="12" sm={finishOn || modules ? 12 : 5} md={finishOn || modules ? 12 : 3} className={`${!finishOn && !modules ? 'last-sm' : ''} margin-bottom-small`}>
          <Image className="full" src={thumb} alt={`Thumb Curso ${title}`} />
        </Col>
        <Col xs="12" sm={finishOn || modules ? 12 : 7} md={finishOn || modules ? 12 : 9}>
          <Heading type="h2" className="font-size-medium main-font without-margin">{title}</Heading>
          <Box className="metas">
            <Box className="meta">
              Por
              {' '}
              <strong>{professional}</strong>
            </Box>
          </Box>
          {progress && (
            <>
              <Paragraph className="font-size-normal">
                Progreso:
                {' '}
                <strong>
                  {progress}
                  %
                </strong>
              </Paragraph>
              <Box className="percent-bar grey-bg">
                <Box className="percent-bar-percentage main-bg" style={{ width: `${progress}%` }}>
                  {''}
                </Box>
              </Box>
            </>
          )}
          {btLink && (
            <NavLink to={btLink} className="btn main-bg white-color main-border btn-module">{btLabel}</NavLink>
          )}
          {buttons && (
            <Box className="group-buttons margin-top-normal">
              {buttons.map((button) => (
                <NavLink key={button.id} to={button.link} className={`btn normal ${button.classes} full`}>
                  <Icon prefix="fas" name={button.icon} />
                  {' '}
                  {button.label}
                </NavLink>
              ))}
            </Box>
          )}
          {finishOn && (
            <Paragraph className="font-size-small align-center">{finishOn}</Paragraph>
          )}
          {modules && (
            <>
              <hr />
              <Box className="metas">
                <Box className="meta">
                  <Icon prefix="far" name="play-circle" />
                  {' '}
                  7 módulos - 13 horas
                </Box>
              </Box>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
};

CourseUser.propTypes = {
  title: PropTypes.string.isRequired,
  thumb: PropTypes.string.isRequired,
  professional: PropTypes.string.isRequired,
  progress: PropTypes.string,
  finishOn: PropTypes.string,
  btLink: PropTypes.string,
  btLabel: PropTypes.string,
  modules: PropTypes.string,
  buttons: PropTypes.any
};

export default CourseUser;
