import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Image,
  Paragraph,
  Button,
} from 'base-ui';

class UnycosAccountSubscription extends Component {
  state = {}

  render() {
    return (
      <section id="account-subscription" className="fade-exit-active">
        <span className="box-title">Información de Suscripción</span>
        <Row className="center-xs">
          <Col xs="8" className="center-xs">
            <Paragraph className="center-xs text-gray">
              Actualmente tienes activa tu suscripción al plan
              {' '}
              <strong>Premium</strong>
              , la
              cual se renovara el
              {' '}
              <strong>7/7/2017</strong>
              {' '}
              y se te cobrara
              {' '}
              <strong>14.90€</strong>
            </Paragraph>
          </Col>
          <Col xs="10" className="center-xs">
            <Button className="orange square hover-effect">Cambiar o cancelar</Button>
          </Col>
        </Row>

        <span className="box-title">Datos de Pago</span>
        <Container fluid>
          <Row>
            <Col xs="12" sm="6">
              <div className="payment-method">
                <Image src="./img/credit-flags/visa.png" alt="Visa"></Image>
                <p>
                  <strong>XXXXXXXXXXXX4233</strong>
                  <br />
                  06 / 2021
                </p>
                <Link to="#">Predeterminada</Link>
                <br />
                <Link to="#">Elminar</Link>
              </div>
            </Col>
            <Col xs="12">
              <Button className="orange square hover-effect">Cambiar o actualizar</Button>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default UnycosAccountSubscription;
