import React from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Box,
  PageWrapper,
  Col,
	Heading,
	InputField,
	Paragraph,
	Icon
} from 'base-ui';

const StepTwo = () => (
  <PageWrapper>
    <Box className="learn-steps">
      <Box className="learn-steps-progress">
        <Box className="item active main-bg"></Box>
        <Box className="item active main-bg"></Box>
        <Box className="item"></Box>
        <Box className="item"></Box>
        <Box className="item"></Box>
      </Box>
      <Container fluid wrap>
        <Row className="special-row middle-xs center-xs">
          <Col xs="12" sm="10" md="7" className="align-center">
            <Heading type="h2" size="big">¿Cuál es tu nombre?</Heading>
						<Paragraph>Estás a poco de comenzar a aprender Educación</Paragraph>
            <Box className="center-xs margin-top-x-medium">
              <InputField 
								placeholder="Tu nombre completo"
								big
								full
							/>
							<Link to="/courses/learn/three" className="btn main-bg main-border white-color big">
								Continuar <Icon prefix="fas" name="arrow-right" />
							</Link>
							<Paragraph><Box tag="small">Si desea saber cómo protegemos sus datos personales, lea nuestro <Link to=".">Política de privacidad</Link></Box></Paragraph>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default StepTwo;
