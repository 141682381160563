import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import moment from 'moment';
import Lottie from 'react-lottie';

import {
  PageWrapper,
  Container,
  Row,
  Box,
  Col,
  Heading,
  Hero,
  Slider,
  InputSelect,
  Icon,
  Loading,
  Paragraph,
  Image,
  InputField,
  Button,
  Message,
  NavLink
} from 'base-ui';

import QuizQuestion from '../../../../components/Consultation/QuizQuestion';
import Testimony from '../../../../components/Consultation/Testimony';
import ScheduleCalendar from '../../../../components/Consultation/ScheduleCalendar';
import ScheduleTime from '../../../../components/Consultation/ScheduleTime';
import SchedulePayment from '../../../../components/Consultation/SchedulePayment';
import Auth from '../../../../components/Consultation/Auth';
import Psychologist from '../../../../components/Consultation/Psychologist';
import ResultConsult from '../../../../components/Consultation/ResultConsult';

import './styles.css';

import animationData from './lotties/data.json';

class ConsultationQuizApp extends Component {
  state = {
    windowWidth: window.innerWidth,
    questions: [{}],
    currentStep: 0,
    estimedTime: '35 min',
    timezone: 'Europa Central',
    hours: [
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
    ],
    scheduleDate: '',
    showTimeSelect: false,
    msgSendSMS: false,
    stage: 'quiz',
    scrollToTop: true,
    psychologists: [],
    related: [
      {
        id: 1,
        thumb: 'https://content.lamenteesmaravillosa.com/services/5c002741a900057d073146c2/image.jpeg',
        title: 'Tratamiento psicológico',
        price: '75€',
        type: 'Consulta',
      },
      {
        id: 2,
        thumb: 'https://content.lamenteesmaravillosa.com/services/5c001ad7c46ec97cf15d5de6/image.png',
        title: 'Tratamiento de trastornos de alimentación e imagen corporal',
        price: '70€',
        type: 'Consulta',
      },
      {
        id: 3,
        thumb: 'https://content.lamenteesmaravillosa.com/services/5c001291a900057d07314149/image.jpeg',
        title: 'Terapia infanto-juvenil',
        price: '75€',
        type: 'Consulta',
      },
      {
        id: 4,
        thumb: 'https://content.lamenteesmaravillosa.com/services/5c000902c46ec97cf15d58d2/image.jpeg',
        title: 'Terapia psicológica para superar la dependencia emocional',
        price: '70€',
        type: 'Consulta',
      }
    ],
  };

  componentDidMount() {
    const {
      location
    } = this.props;
    this.setState({
      currentStep: (location.state) ? location.state.currentStep : 0,
      questions: [
        {
          title: '¿Qué tipo de terapia buscas?',
          options: [
            { label: 'Individual', value: 'individual' },
            { label: 'Pareja', value: 'pareja' },
            { label: 'Niños o adolescentes', value: 'ninos-o-adolescentes' },
          ],
        },
        {
          title: '¿Cuál es tu género?',
          options: [
            { label: 'Masculino', value: 'masculino' },
            { label: 'Femenino', value: 'femenino' },
            { label: 'Agénero', value: 'agenero' },
            { label: 'Indefinido', value: 'indefinido' },
            { label: 'Género fluido', value: 'genero-fluido' },
            { label: 'Bigénero', value: 'bigenero' },
            { label: 'Otro', value: 'otro' },
          ],
        },
        {
          title: '¿Qué edad tienes? (13+)',
          options: [
            { label: '13 - 17 años', value: '13-17' },
            { label: '18 - 35 años', value: '18-35' },
            { label: '36 - 64 años', value: '36-64' },
            { label: '65 + años', value: '65-' },
          ],
        },
        {
          title: '¿Qué orientación sexual tienes?',
          options: [
            { label: 'Heterosexual', value: 'hetero' },
            { label: 'Homosexual', value: 'homo' },
            { label: 'Bisexual', value: 'bi' },
            { label: 'Pansexual', value: 'pan' },
            { label: 'Queer', value: 'queer' },
            { label: 'Asexual', value: 'asexual' },
            { label: 'No estoy seguro', value: 'no-estoy-seguro' },
          ],
        },
        {
          title: '¿Eres una persona religiosa?',
          options: [
            { label: 'Sí', value: 'si' },
            { label: 'No', value: 'no' },
          ],
        },
        {
          title: '¿Con qué religión te identificas?',
          options: [
            { label: 'Cristianismo', value: 'cristianismo' },
            { label: 'Judaísmo', value: 'judaismo' },
            { label: 'Islamismo', value: 'islanismo' },
            { label: 'Hinduismo', value: 'hinduismo' },
            { label: 'Budismo', value: 'budismo' },
            { label: 'Otra', value: 'otra' },
          ],
        },
        {
          title:
            'Si te encuentras en una situación de riesgo, no utilices esta web.',
          emergency: true,
          options: [
            {
              label: 'Ver recursos',
              value: 'ver-recursos',
              url: 'https://lamenteesmaravillosa.com/consultas/emergencia/',
            },
            { label: 'No estoy en situación de riesgo', value: 'ok' },
          ],
        },
      ],
      psychologists: [
        {
          name: 'Gema Sánchez',
          role: 'Psicóloga',
          profileImage: './img/dummy/avatar-9.png',
          price: '40€',
          estimedTime: '35 min',
        },
        {
          name: 'Gema Sánchez',
          role: 'Psicóloga',
          profileImage: './img/dummy/avatar-9.png',
          price: '40€',
          estimedTime: '25 min',
          recommended: true
        },
        {
          name: 'Gema Sánchez',
          role: 'Psicóloga',
          profileImage: './img/dummy/avatar-9.png',
          price: '40€',
          estimedTime: '45 min',
        },
      ]
    });
  }

  handleClickSMS = () => {
    this.setState({
      msgSendSMS: true,
      scrollToTop: false,
    });
  };

  onSetAnswer = (value) => {
    const { currentStep } = this.state;

    // eslint-disable-next-line
    const questions = [...this.state.questions];
    questions[currentStep].answer = value;

    if (currentStep + 1 === questions.length) {
      this.setState({
        stage: 'analyzing',
      });
    } else {
      this.setState((prevState) => ({
        questions,
        currentStep: prevState.currentStep + 1,
      }));
    }
  };

  onChangeStep = (i) => {
    const { currentStep, questions } = this.state;
    const lastStep = questions.filter((obj) => Object.prototype.hasOwnProperty.call(obj, 'answer')
    ).length;

    if (currentStep >= i || lastStep >= i) {
      this.setState({
        currentStep: i,
      });
    }
  };

  questionNavigation = () => {
    const { questions, currentStep } = this.state;
    const steps = [];

    for (let i = 0; i < questions.length; i += 1) {
      steps.push(
        <button
          key={questions[i]}
          type="button"
          className={cs('question-step', {
            'is-visited': Object.prototype.hasOwnProperty.call(
              questions[i],
              'answer'
            ),
            'is-active': currentStep === i,
          })}
          onClick={() => this.onChangeStep(i)}
        >
        </button>
      );
    }

    return steps;
  };

  setDate = (date) => {
    const { windowWidth } = this.state;
    const {
      dayName, day, selectedMonth, year
    } = date;

    const scheduleDate = {
      dayName,
      day,
      selectedMonth,
      year,
    };

    this.setState({
      scheduleDate,
      showTimeSelect: true,
    });

    // Scroll to Timer selection if is mobile
    if (windowWidth < 768) {
      setTimeout(() => {
        window.scroll(0, 650);
      }, 500);
    }
  };

  setTimezone = (timezone) => {
    this.setState({
      timezone,
    });
  };

  setHour = (hour) => {
    const { windowWidth, scheduleDate } = this.state;
    scheduleDate.hour = hour;

    this.setState({
      scheduleDate,
      showTimeSelect: false,
      stage: 'auth',
    });

    // Scroll to normal again
    if (windowWidth < 768) {
      window.scroll(0, 300);
    }
  };

  formatSchedule = () => {
    const { scheduleDate, sessionWith } = this.state;
    const {
      day, selectedMonth, year, hour
    } = scheduleDate;

    const endTime = String(
      parseFloat(hour.split(':').join('.'))
        + parseFloat(`0.${sessionWith.estimedTime.split(' ')[0]}`)
    );
    const monthNumber = moment().month(selectedMonth).format('M');
    const formatedDate = moment(`${monthNumber}-${day}-${year} ${hour}`);
    const capitalFormatedDate = formatedDate.format('LLLL').charAt(0).toUpperCase() + formatedDate.format('LLLL').slice(1);
    return `${capitalFormatedDate} - ${endTime.split('.').join(':')}`;
  };

  backStage = () => {
    let { stage, showTimeSelect } = this.state;

    switch (stage) {
      case 'analyzing':
        stage = 'quiz';
        break;
      case 'selectPsy':
        stage = 'analyzing';
        break;
      case 'schedule':
        stage = 'selectPsy';
        break;
      case 'auth':
        stage = 'schedule';
        showTimeSelect = true;
        break;
      case 'payment':
        stage = 'auth';
        break;
      default:
        break;
    }

    this.setState({ stage, showTimeSelect });
  }

  renderQuiz = () => {
    const {
      questions,
      currentStep,
    } = this.state;

    return (
      <Row className="center-xs">
        <Col xs="12" sm="5">
          <Box className="quiz-app-steps" style={{ width: `calc(33px * ${questions.length})` }}>{this.questionNavigation()}</Box>
          <QuizQuestion
            question={questions[currentStep]}
            setAnswer={this.onSetAnswer}
          />
        </Col>
        <Col xs="12" sm="1">&nbsp;</Col>
        <Col xs="12" sm="6">
          <Box className="quiz-app-testemonials">
            <Slider
              autoplay
              dots
              responsive={[
                {
                  breakpoint: 3500,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                  },
                },
              ]}
            >
              <Col xs="12" className="align-center">
                <Testimony
                  textColor="text"
                  by="un paciente"
                  forName="Gema Sánchez"
                  withRole="Psicóloga/Doctoranda"
                  showQuote
                  profileImage="./img/dummy/avatar-4.png"
                  testimony="Ya había asistido a consulta presencial con Gema, pero mi situación actual no me permite seguir haciéndolo, así que decidí darle una oportunidad a la plataforma. Al principio dudaba de la terapia online, pero me ha sorprendido mucho lo bien que funciona. Gema es una psicóloga excelente, muy cercana, y me ha servido de gran ayuda para darle un cambio a mi vida, incluso a través de una pantalla."
                />
              </Col>
              <Col xs="12" className="align-center">
                <Testimony
                  textColor="text"
                  by="un paciente"
                  forName="Julia Marquez Arrico"
                  withRole="Psicóloga/Doctoranda"
                  showQuote
                  profileImage="./img/dummy/avatar-4.png"
                  testimony="Desde pequeña he tenido problemas de autoestima que me han impedido luchar por lo que realmente quiero, pero no me atrevía a buscar ayuda profesional. Pensé en hacerlo de forma online y encontré esta plataforma y a Julia. Las consultas por videollamada son una buena manera de comenzar la terapia para los que no lo hemos hecho nunca. Gracias a Julia me siento capaz de lograr lo que me proponga."
                />
              </Col>
              <Col xs="12" className="align-center">
                <Testimony
                  textColor="text"
                  by="un paciente"
                  forName="Fátima Servián Franco"
                  withRole="Psicóloga/Doctoranda"
                  showQuote
                  profileImage="./img/dummy/avatar-4.png"
                  testimony="Hace poco decidí abrir un negocio y desde entonces he padecido estrés y ansiedad. Al principio decidí llevarlo solo, pero llegó un punto en que no pude más. Con la ayuda de Fátima siento que puedo manejar la situación, lo cual repercute también en el éxito del negocio. Si no fuera por esta plataforma y la comodidad de las consultas online, ni siquiera habría tenido tiempo de encontrar la solución."
                />
              </Col>
            </Slider>
          </Box>
        </Col>
      </Row>
    );
  };

  auth = () => {
    this.setState({
      stage: 'payment',
    });
  };

  renderAnalyzing = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
      }
    };

    const phases = [
      'Procesando tus respuestas',
      'Analizando perfiles',
      'Revisando disponibilidad',
      '¡Lo encontramos!'
    ];

    return (
      <Row className="center-xs margin-top-x-big margin-bottom-big">
        <Col xs="12" sm="12" className="analyzing align-center">
          <Lottie
            options={defaultOptions}
            height={150}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => this.setState({ stage: 'selectPsy' })
              }
            ]}
          />
          <Heading type="h2" size="big" className="margin-top-big margin-bottom-small">
            Buscando el mejor
            <br />
            psicólogo para ti
          </Heading>
          <Heading type="h3" size="x-medium" textColor="regent-grey" className="analyzing-phase align-center">
            {phases.map((phase) => (
              <span key={phase}>
                {phase}
              </span>
            ))}
          </Heading>
        </Col>
      </Row>
    );
  };

  selectPsy = (name, role, price, estimedTime) => {
    this.setState({
      sessionWith: {
        name,
        role,
        price,
        estimedTime
      },
      stage: 'schedule'
    });
  }

  renderSelectPsy = () => {
    const { psychologists } = this.state;

    return (
      <Box>
        <Row className="psycos center-xs margin-top-big margin-bottom-medium">
          {psychologists.map(({
            name, role, profileImage, price, estimedTime, recommended
          }) => (
            <Col key={name} xs="12" sm="4" className="psyco">
              <Psychologist
                name={name}
                role={role}
                profileImage={profileImage}
                price={price}
                estimedTime={estimedTime}
                recommended={recommended}
                onSelect={this.selectPsy}
              />
            </Col>
          ))}
        </Row>
      </Box>
    );
  };

  renderSchedule = () => {
    const {
      estimedTime, hours, timezone, showTimeSelect, scheduleDate
    } = this.state;

    return (
      <Row className="center-xs margin-top-big margin-bottom-medium">
        <Col xs="12" md="5">
          <Box className="quiz-app-calendar">
            <Row className="quiz-app-calendar-heading align-center">
              <Col xs="12">
                <Heading type="h2" size="medium">
                  o selecciona un día
                </Heading>
              </Col>
            </Row>
            <ScheduleCalendar
              lang="es"
              daysAvailable={{
                5: [1, 4, 5, 6, 7, 8, 11, 12, 13],
                6: [4, 5, 6],
                7: [7, 8, 9],
              }}
              setDate={this.setDate}
              selectedDay={scheduleDate.day || ''}
              selectedMonth={scheduleDate.selectedMonth || ''}
              haveSelectFirstDay
            />
            <Box className="schedule-timezone">
              <Icon name="globe-americas" prefix="fas" className="icon" />
              <InputSelect
                value={timezone}
                required
                onChange={(v) => this.setTimezone(v)}
                options={[
                  {
                    value: 'Europa Central',
                    label: 'Europa Central',
                  },
                ]}
              />
            </Box>
          </Box>
        </Col>
        {showTimeSelect && (
          <React.Fragment>
            <Col xs="12" md="1">&nbsp;</Col>
            <Col xs="12" md="6">
              <ScheduleTime
                isActive={showTimeSelect}
                title="Selecciona una hora"
                estimedTime={estimedTime}
                hours={hours}
                setHour={this.setHour}
                activeHour={scheduleDate.hour || ''}
              />
            </Col>
          </React.Fragment>
        )}
      </Row>
    );
  };

  renderAuth = () => (
    <Auth
      title="Crea una cuenta para continuar"
      onSubmitRegister={this.auth}
      onSubmitLogin={this.auth}
    />
  );

  onBuy = () => {
    this.setState({
      stage: 'done',
    });
  };

  applyCoupon = (coupon) => {
    const { sessionWith } = this.state;

    // fake coupon validation
    if (coupon === 'blablabla') {
      const discountPercentage = 50 / 100;
      const actualPrice = Number(sessionWith.price.split('€')[0]);
      // calculate new price
      const newPrice = actualPrice - (actualPrice * discountPercentage);
      sessionWith.price = `${newPrice.toFixed(2)}€`;

      this.setState({
        hasCoupon: true,
        sessionWith,
      });

      return {
        status: 'success',
        message: `Descuento aplicado -${discountPercentage.toFixed(2).split('.')[1]}%`
      };
    }

    return {
      status: 'error',
      message: '¡Cupón no válido!'
    };
  }

  renderPayment = () => {
    const { sessionWith, hasCoupon } = this.state;

    return (
      <SchedulePayment
        onBuy={this.onBuy}
        price={sessionWith.price}
        validateCoupon={this.applyCoupon}
        hasCoupon={hasCoupon}
        // textColor="white"
        // color="main"
      />
    );
  }

  renderDone = () => {
    const { related, msgSendSMS } = this.state;
    return (
      <React.Fragment>
        <Row className="center-xs">
          <Col xs="12" md="6" className="quiz-success">
            <Image src="./img/lmem/paper-plane.svg" />
            <Box className="quiz-success-message">
              <Paragraph>
                <strong>¡Listo! Ya no necesitas hacer nada más.</strong>
                {' '}
                El psicólogo se pondrá en contacto contigo por correo electrónico.
              </Paragraph>
              <Paragraph>
                <strong style={{ textTransform: 'uppercase' }}>Importante:</strong>
                {' '}
                Revisa la bandeja de entrada del correo electrónico con el que te has registrado en la plataforma (incluso la carpeta de SPAM o correos no deseados) y asegúrate de haber recibido su mensaje.
                <br />
                Si tienes cualquier problema no dudes en escribirnos a
                {' '}
                <NavLink mailto="contacto@lamenteesmaravillosa.com" title="contacto@lamenteesmaravillosa.com">contacto@lamenteesmaravillosa.com</NavLink>
              </Paragraph>
            </Box>
          </Col>
        </Row>
        <hr />
        <Row className="padding-bottom-big row-buttons">
          <Col xs="12">
            <Button color="main" textColor="white" size="big">
              Crear alerta en iCalendar
            </Button>
            <Button color="main" textColor="white" size="big">
              Crear alerta en Google calendar
            </Button>
            <Button color="secondary" textColor="white" size="big">
              Ver mis reservas
            </Button>
          </Col>
        </Row>
        <Box className="full-width-bg grey-bg small">
          <Heading type="h3" size="big" textColor="text" className="align-center">
            ¿Quieres recibir un SMS de recordatorio antes de tu consulta?
          </Heading>
          <Heading type="h5" size="normal" className="align-center">
            Escribe tu número de teléfono:
          </Heading>
          <Row className="center-xs">
            <Col xs="12" sm="4">
              <form className="tel-form">
                <InputField type="tel" name="tel" placeholder="Teléfono" />
                <Button
                  type="submit"
                  color="main"
                  textColor="white"
                  borderColor="main"
                  onClick={this.handleClickSMS}
                >
                  OK
                </Button>
                {msgSendSMS && (
                  <Message iconName="info-circle" color="green" textColor="white" iconPrefix="fas" className="position-absolute animate-msg">
                    ¡Tu número se ha registrado correctamente! te enviaremos un mensaje minutos antes de tu sesión
                  </Message>
                )}
              </form>
            </Col>
          </Row>
        </Box>
        <Box className="margin-top-big margin-bottom-big">
          <Heading type="h4" size="big" className="align-center margin-bottom-big">
            Aprende más sobre psicología en LMEM
          </Heading>
          <Row className="center-xs">
            <Col xs="12" md="8">
              <Row className="center-xs">
                { related.map(({
                  id, thumb, title, price, type
                }) => (
                  <Col key={id} xs="12" sm="6">
                    <ResultConsult
                      data={{
                        thumb,
                        title,
                        price,
                        type,
                      }}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Box>
      </React.Fragment>
    );
  }

  renderLoading = () => (
    <Row>
      <Col style={{ height: '400px' }}>
        <Loading scale="4" justify="center" vAlign="center" />
      </Col>
    </Row>
  );

  renderInfoBar = () => {
    const {
      sessionWith, scheduleDate, timezone, hasCoupon, stage
    } = this.state;
    const infoBar = [];

    if (sessionWith) {
      infoBar.push(
        <React.Fragment>
          <li className="yellow-color">
            <Icon name="user" prefix="fas" color="yellow" />
            {' '}
            Sesíon con
            {' '}
            {sessionWith.name}
          </li>
          <li className={cs({ 'secondary-color': hasCoupon })}>
            <Icon name="money-bill" prefix="fas" />
            {' '}
            { sessionWith.price }
            {' '}
            / consulta
          </li>
          <li>
            <Icon name="clock" prefix="far" />
            {' '}
            {sessionWith.estimedTime}
          </li>
        </React.Fragment>
      );
    }

    if (scheduleDate && scheduleDate.hour) {
      infoBar.push(
        <React.Fragment>
          <li>
            <Icon name="calendar" prefix="far" />
            {' '}
            {this.formatSchedule()}
          </li>
          <li>
            <Icon name="globe-americas" prefix="fas" />
            {' '}
            {timezone}
          </li>
        </React.Fragment>
      );
    }

    return (
      <Box className="main-bg white-color">
        {stage === 'done' && (
          <Heading type="h2" size="medium" textColor="white" className="without-margin align-center" style={{ paddingTop: '12px' }}>
            Estos son los datos de tu reserva:
          </Heading>
        )}
        {infoBar.length > 0 && (
          <ul className="quiz-sessionInfo align-center">
            { infoBar }
          </ul>
        )}
      </Box>
    );
  }

  render() {
    const { questions, stage, scrollToTop } = this.state;

    let pageTitle = 'Ayúdanos a seleccionar el psicólogo más adecuado para ti';
    let pageContent = this.renderLoading();

    if ((stage === 'auth' || stage === 'payment' || stage === 'done') && scrollToTop === true) {
      window.scrollTo(0, 0);
    }

    if (stage === 'schedule') {
      pageTitle = 'Elige la fecha y hora de tu sesión';
    } else if (stage === 'analyzing') {
      pageTitle = 'Estamos analizando tus respuestas';
    } else if (stage === 'selectPsy') {
      pageTitle = 'Elige una de nuestras recomendaciones';
    } else if (stage === 'auth') {
      pageTitle = 'Queda muy poco para terminar';
    } else if (stage === 'payment') {
      pageTitle = 'Confirma tu reserva';
    } else if (stage === 'done') {
      pageTitle = '¡Tu sesión está confirmada!';
    }

    switch (stage) {
      case 'quiz':
        pageContent = this.renderQuiz();
        break;
      case 'analyzing':
        pageContent = this.renderAnalyzing();
        break;
      case 'selectPsy':
        pageContent = this.renderSelectPsy();
        break;
      case 'schedule':
        pageContent = this.renderSchedule();
        break;
      case 'auth':
        pageContent = this.renderAuth();
        break;
      case 'payment':
        pageContent = this.renderPayment();
        break;
      default:
        pageContent = this.renderDone();
    }

    return (
      <React.Fragment>
        <Box className="quiz-topbar" ref={this.myRef}>
          {(
            Object.prototype.hasOwnProperty.call(questions[0], 'answer')
            && stage !== 'quiz'
            && stage !== 'done'
          ) && (
            <Button
              tag="button"
              color="transparent"
              textColor="text"
              borderColor="transparent"
              onClick={this.backStage}
            >
              <Icon name="chevron-left" prefix="fas" />
            </Button>
          )}
          <NavLink to="/" className="quiz-topbar-logo">
            <Image src="./img/logo.svg" alt="Logo La Mente es Maravillosa" />
          </NavLink>
        </Box>
        <PageWrapper
          config={{ title: 'Terapia' }}
          style={{ overflow: 'inherit' }}
        >
          <Box className={`quiz-landing top_${stage}`}>
            <Hero
              BgImage="./img/lmem/woman-hero.jpeg"
              className="align-center hero"
            >
              <Container fluid wrap>
                <Row className="middle-xs">
                  <Col xs="12" md="12">
                    <Heading
                      type="h1"
                      size="x-big"
                      className="title"
                      textColor="white"
                      style={{ textTransform: 'none' }}
                    >
                      {stage === 'done' && (
                        <Icon
                          name="check-circle"
                          prefix="fas"
                          className="align-center margin-bottom-small"
                          style={{ display: 'block' }}
                        />
                      )}
                      {pageTitle}
                    </Heading>
                  </Col>
                </Row>
              </Container>
            </Hero>
            { this.renderInfoBar() }
            <Box tag="main" className="quiz-app">
              <Container fluid wrap>
                {pageContent}
              </Container>
            </Box>
          </Box>
        </PageWrapper>
      </React.Fragment>
    );
  }
}

ConsultationQuizApp.propTypes = {
  /**
   * Current params
   */
  location: PropTypes.object,
};

export default ConsultationQuizApp;
