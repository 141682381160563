import React, { Component } from 'react';

// Base-UI Ccomponents
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Icon,
  Heading,
  Paragraph,
  InputSelect
} from 'base-ui';

// Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogArticle from '../../components/Blog/BlogArticle';

class BlogBookmarks extends Component {
  state = {
    categories: [
      {
        label: 'Filter Categories',
        value: 'all',
      },
      {
        label: 'Psychology',
        value: 'Psychology',
      },
      {
        label: 'Emotions',
        value: 'Emotions',
      },
      {
        label: 'Curiosities',
        value: 'Curiosities',
      },
      {
        label: 'Movies',
        value: 'Movies',
      },
    ],
    filter: 'all',
    sort: 'a-zAsc',
    bookmarks: []
  }

  componentDidMount() {
    this.setState({
      bookmarks: [
        {
          id: 1,
          title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
          subcategory: 'Psychology',
          thumb: './img/dummy/post-13.png',
          link: '/single'
        },
        {
          id: 2,
          title: '10 maneras de tener una menstruación menos dolorosa',
          subcategory: 'Emotions',
          thumb: './img/dummy/post-3.png',
          link: '/single'
        },
        {
          id: 3,
          title: '10 maneras en que se desperdicia energía eléctrica',
          subcategory: 'Curiosities',
          thumb: './img/dummy/post-4.png',
          link: '/single'
        },
        {
          id: 4,
          title: '10 maneras curiosas de reciclar tubos de cartón',
          subcategory: 'Movies',
          thumb: './img/dummy/post-5.png',
          link: '/single'
        },
        {
          id: 5,
          title: '10 maneras de condimentar tus alimentos de manera saludable',
          subcategory: 'Psychology',
          thumb: './img/dummy/post-6.png',
          link: '/single'
        },
        {
          id: 6,
          title: '10 maneras de descubrir tu personalidad según la forma de tus labios',
          subcategory: 'Emotions',
          thumb: './img/dummy/post-7.png',
          link: '/single'
        }
      ]
    });
  }

  handleFilterChange = (e) => {
    this.setState({
      filter: e
    });
  }

  handleRemove = (id) => {
    this.setState((prevState) => ({
      bookmarks: prevState.bookmarks.filter((bookmark) => bookmark.id !== id)
    }));
  }

  render() {
    const {
      bookmarks, categories, filter, sort
    } = this.state;

    return (
      <React.Fragment>
        <BlogMenu />
        <PageWrapper config={{ title: 'Marcadores' }}>
          <Container fluid wrap>
            <Box tag="main" id="site-main">
              <Row className="center-xs">
                <Col xs="12">
                  <Card size="medium">
                    <Row className="middle-xs">
                      <Col xs="2" sm="1" className="align-center">
                        <Icon name="bookmark" size="big" color="utilitary" />
                      </Col>
                      <Col xs="10" sm="11">
                        <Heading type="h2" className="without-margin">
                          You can bookmark items to read later.
                        </Heading>
                        <Paragraph className="without-margin">
                          Look for this icon in each article in order to choose the content that will be shown here.
                        </Paragraph>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs="12">
                  <br />
                  <Row>
                    <Col xs="12" md="4" className="center-xs start-md">
                      <InputSelect
                        name="filter"
                        onChange={this.handleFilterChange}
                        value={filter}
                        options={categories}
                      />
                    </Col>
                    <Col xs="12" md="4" className="center-xs start-md">
                      <Box className="horizontal-scroll-md center-xs start-md">
                        <InputSelect
                          autocomplete
                          name="PostFilter"
                          onChange={this.handleFilterChange}
                          value={filter}
                          options={
                            [
                              { label: 'All posts', value: 'all' }
                            ].concat(
                              bookmarks.map((post) => ({ label: post.title, value: post.id }))
                            )
                          }
                        />
                      </Box>
                    </Col>
                    <Col xs="12" md="4" className="center end-md">
                      <label
                        htmlFor="a-zAsc"
                        className={`btn ${sort === 'a-zAsc' ? 'main-bg white-color' : 'grey-bg text-color'} transparent-border`}
                      >
                        <input
                          type="radio"
                          name="sort"
                          id="a-zAsc"
                          value="a-zAsc"
                          checked={sort === this.value}
                          onChange={this.handleFilterChange}
                        />
                        <Icon prefix="fas" name="sort-alpha-up" />
                      </label>
                      <label
                        htmlFor="a-zDesc"
                        className={`btn ${sort === 'a-zDesc' ? 'main-bg white-color' : 'grey-bg text-color'} transparent-border`}
                      >
                        <input
                          type="radio"
                          name="sort"
                          id="a-zDesc"
                          value="a-zDesc"
                          onChange={this.handleFilterChange}
                        />
                        <Icon prefix="fas" name="sort-alpha-down" />
                      </label>
                      <label
                        htmlFor="1-9Asc"
                        className={`btn ${sort === '1-9Asc' ? 'main-bg white-color' : 'grey-bg text-color'} transparent-border`}
                      >
                        <input
                          type="radio"
                          name="sort"
                          id="1-9Asc"
                          value="1-9Asc"
                          onChange={this.handleFilterChange}
                        />
                        <Icon prefix="fas" name="sort-numeric-up" />
                      </label>
                      <label
                        htmlFor="1-9Desc"
                        className={`btn ${sort === '1-9Desc' ? 'main-bg white-color' : 'grey-bg text-color'} transparent-border`}
                      >
                        <input
                          type="radio"
                          name="sort"
                          id="1-9Desc"
                          value="1-9Desc"
                          onChange={this.handleFilterChange}
                        />
                        <Icon prefix="fas" name="sort-numeric-down" />
                      </label>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <Box className="posts-row">
                { bookmarks.filter((bookmark) => {
                  if (filter !== 'all') {
                    return bookmark.subcategory.includes(filter);
                  }
                  return bookmark;
                }).map((bookmark) => (
                  <BlogArticle
                    key={bookmark.id}
                    thumb={bookmark.thumb}
                    title={bookmark.title}
                    subcategory={bookmark.subcategory}
                    linkTo={bookmark.link}
                    className="content-post news"
                    isBookmark
                  >
                    <span role="button" className="remove-favorite" onClick={() => this.handleRemove(bookmark.id)}>
                      <Icon prefix="fas" name="trash" />
                    </span>
                  </BlogArticle>
                ))}
              </Box>
            </Box>
          </Container>
        </PageWrapper>
      </React.Fragment>
    );
  }
}

export default BlogBookmarks;
