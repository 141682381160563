import React, { Component } from 'react';

// Base-UI Ccomponents
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Paragraph,
  Loading
} from 'base-ui';

// Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogMorePostsList from '../../components/Blog/BlogMorePostsList';
import BlogArticle from '../../components/Blog/BlogArticle';

class BlogArchive extends Component {
  state = {
    posts: [],
    loading: true
  }

  componentDidMount() {
    this.getPosts();
  }

  getPosts = async (milliseconds = 2000) => {
    await this.sleep(milliseconds);
    this.setState({
      posts: [
        {
          id: 1,
          thumb: './img/dummy/post-13.png',
          title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
          description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
          subcategory: 'Subcategoria',
          link: '/something'
        },
        {
          id: 2,
          thumb: './img/dummy/post-14.png',
          title: '9 things I wish I knew before playing Rainbow Six Siege Outbreak mode',
          description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
          subcategory: 'Subcategoria',
          link: '/something'
        },
        {
          id: 3,
          thumb: './img/dummy/post-16.png',
          title: '9 things I wish I knew before playing Rainbow Six Siege Outbreak mode',
          description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
          subcategory: 'Subcategoria',
          link: '/something'
        },
        {
          id: 4,
          thumb: './img/dummy/post-12.png',
          title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
          description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
          subcategory: 'Subcategoria',
          link: '/something'
        },
      ],
      loading: false
    });
  }

  // Just for simulate fetch api with loading state
  sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds))

  render() {
    const { posts, loading } = this.state;

    return (
      <React.Fragment>
        <BlogMenu />
        <PageWrapper config={{ title: 'Archivo' }}>
          <Container fluid wrap>
            <Box tag="main" id="site-main">
              <Row>
                <Col xs="12">
                  <Box className="section-header">
                    <Heading type="h1" className="label">Buenos hábitos</Heading>
                    <Paragraph className="description">
                    En nuestro día a día podemos llevar a cabo múltiples estrategias con las que contribuir al máximo a nuestro bienestar y la calidad de vida. Desde técnicas de relajación, gestión emocional, ejercicios para cuidar de nuestras articulaciones o perder peso…Descubre todo ese amplio abanico de estrategias con las que sentirte bien por dentro y por fuera.
                    </Paragraph>
                  </Box>
                </Col>
                <Col xs="12" md="8">
                  <Box tag="section" id="latest-posts-widget" className="widget posts-style-5">
                    {loading
                      ? <Loading />
                      : (
                        <Box className="posts-row">
                          { posts.map((post) => (
                            <BlogArticle
                              key={post.id}
                              thumb={post.thumb}
                              title={post.title}
                              description={post.description}
                              subcategory={post.subcategory}
                              linkTo={post.link}
                              className="content-post news"
                            />
                          )) }
                        </Box>
                      )
                    }
                  </Box>
                </Col>
                <Box tag="aside" id="sidebar" className="col-xs-12 col-md-4">
                  <Box className="section-title text-uppercase align-center small utilitary-border utilitary-color">
                    <Box tag="span" className="label">Síguenos en...</Box>
                  </Box>
                  <Box className="facebook display-block align-center">
                    <br />
                    {/* Facebook Code */}
                  </Box>
                  <Box className="facebook display-block align-center">
                    <br />
                    <iframe
                      id="twitter-widget-0"
                      scrolling="no"
                      frameBorder="0"
                      allowTransparency="true"
                      allowFullScreen
                      className="twitter-follow-button twitter-follow-button-rendered"
                      style={{
                        position: 'static', visibility: 'visible', width: '157px', height: '28px'
                      }}
                      title="Twitter Follow Button"
                      src="https://platform.twitter.com/widgets/follow_button.d0f13be8321eb432fba28cfc1c3351b1.en.html#dnt=false&amp;id=twitter-widget-0&amp;lang=en&amp;screen_name=TwitterDev&amp;show_count=false&amp;show_screen_name=true&amp;size=l&amp;time=1585142058481"
                      data-screen-name="TwitterDev"
                    >
                    </iframe>
                  </Box>
                </Box>
              </Row>
            </Box>
            <BlogMorePostsList />
          </Container>
        </PageWrapper>
      </React.Fragment>
    );
  }
}

export default BlogArchive;
