import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Slider,
  Heading
} from 'base-ui';

// Components
import CardContentPost from '../CardPost';

const SliderPosts = ({
  className, title, titleType, data
}) => (
  <Box tag="section" className={`section-related-articles ${className}`}>
    {title && (
      <Heading type={titleType} className="titleLine">{title}</Heading>
    )}
    <Slider
      autoplay
      className="slider-related-articles"
      responsive={[
        {
          breakpoint: 2500,
          settings:
            {
              slidesToShow: 3,
              arrows: false,
              dots: true,
              slidesToScroll: 1
            }
        },
        {
          breakpoint: 1024,
          settings:
            {
              slidesToShow: 2,
              arrows: false,
              dots: true,
              slidesToScroll: 1
            }
        },
        {
          breakpoint: 550,
          settings:
            {
              slidesToShow: 1,
              arrows: false,
              dots: true,
              slidesToScroll: 1
            }
        },
        {
          breakpoint: 50,
          settings:
            {
              slidesToShow: 1,
              arrows: false,
              dots: true,
              slidesToScroll: 1
            }
        }
      ]}
    >
      {data.map((item) => (
        <CardContentPost
          className="without-shadow"
          titleType="h3"
          key={item.id}
          post={{
            url: item.url,
            thumb: item.thumb,
            title: item.title
          }}
        />
      ))}
    </Slider>
  </Box>
);


SliderPosts.defaultProps = {
  className: '',
};

SliderPosts.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  titleType: PropTypes.string,
  className: PropTypes.string
};

export default SliderPosts;
