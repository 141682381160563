import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Row,
  Col,
  Image,
  Heading,
  Paragraph,
  Button,
  Icon
} from 'base-ui';

const BlogCategoryHero = (props) => {
  const {
    title, text
  } = props;
  return (
    <Box tag="section" id="box-hero">
      <Box className="background-hero position-absolute">
        {' '}
      </Box>
      <Container fluid wrap>
        <Row>
          <Col xs="12" sm="8" md="9">
            <Box className="content-hero margin-small">
              <Heading type="h1" className="main-color font-x-big">{title}</Heading>
              <Paragraph className="main-color line-height-x-medium font-medium margin-top-small margin-bottom-small">{text}</Paragraph>
              <Box className="share-hero">
                <Button to="#" className="main-color lightweight-font white-color main-bg line-height-small">
                  <Icon prefix="fab" name="facebook-f" />
                  <Box tag="span" className="hidden-sm">Facebook</Box>
                </Button>
                <Button to="#" className="main-color lightweight-font white-color main-bg line-height-small">
                  <Icon prefix="fab" name="twitter" />
                  <Box tag="span" className="hidden-sm">Twitter</Box>
                </Button>
                <Button to="#" className="main-color lightweight-font white-color main-bg line-height-small">
                  <Icon prefix="fab" name="pinterest-p" />
                  <Box tag="span" className="hidden-sm">Pinterest</Box>
                </Button>
                <Button to="#" className="main-color lightweight-font white-color main-bg line-height-small">
                  <Icon prefix="fab" name="whatsapp" />
                  <Box tag="span" className="hidden-sm">Whatsapp</Box>
                </Button>
              </Box>
            </Box>
          </Col>
          <Col xs="12" sm="4" md="3" className="first-xs last-sm hidden-sm">
            <Image src="./img/blog/img_hero.svg" />
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

BlogCategoryHero.defaultProps = {
  title: '',
  text: ''
};

BlogCategoryHero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

export default BlogCategoryHero;
