import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Row,
  Col,
  Button,
  Paragraph,
  Image
} from 'base-ui';

import UnycosAccordion from './UnycosAccordion';

const UnycosSubscriptionWidget = ({ className, click }) => (
  <Box id="course-subscription-widget" className={className}>
    <Card className="without-shadow rounded-normal">
      <UnycosAccordion version="subscription-version">
        <Box
          title={(
            <Box>
              <Row className="middle-xs">
                <Col xs="8">
                  <Paragraph size="medium" className="text-uppercase titles-color without-margin">Acceso total</Paragraph>
                </Col>
                <Col xs="4" className="end-xs">
                  <Box tag="span" className="font-size-medium normal-font titles-color">
                    16,66 €/
                    <Box tag="span" className="font-size-small">MES</Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
          label="module-1"
          isOpen
        >
          <Paragraph className="text-color without-margin-bottom">Acceso a todos los cursos offline en una misma suscripción con un solo pago anual.</Paragraph>
          <Box className="cost-row">
            <Row className="middle-xs margin-top-small">
              <Col xs="8">
                <Button className="main-bg main-border btn--normal black-color text-uppercase with-letter-spacing normal-font font-size-normal" full onClick={click}>Suscribirse</Button>
              </Col>
              <Col xs="2">
                <Button className="transparent-bg gift-btn strong-border border-color__white--dark btn--normal text-color__white--dark text-uppercase with-letter-spacing normal-font font-size-small" onClick={click} style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center' }} full>
                  <Image className="icon" src="./img/unycos/icons/icon-gift.svg" />
                </Button>
              </Col>
              <Col xs="2">
                <Button className="transparent-bg heart-btn strong-border border-color__white--dark btn--normal text-color__white--dark text-uppercase with-letter-spacing normal-font font-size-small" style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center' }} full>
                  <Image className="icon" src="./img/unycos/icons/icon-heart.svg" />
                </Button>
              </Col>
            </Row>
          </Box>
        </Box>
        <Box
          title={(
            <Box>
              <Row className="middle-xs">
                <Col xs="8">
                  <Paragraph size="medium" className="titles-color text-uppercase without-margin">Curso</Paragraph>
                </Col>
                <Col xs="4" className="end-xs">
                  <Box tag="span" className="font-size-medium normal-font titles-color">100 €</Box>
                </Col>
              </Row>
            </Box>
          )}
          label="module-2"
        >
          <Paragraph className="text-color without-margin-bottom">Un solo curso con acceso completo siempre disponible para ti.</Paragraph>
          <Box className="cost-row">
            <Row className="middle-xs margin-top-small">
              <Col xs="8">
                <Button className="main-bg main-border btn--normal black-color text-uppercase with-letter-spacing normal-font font-size-normal" full>Comprar</Button>
              </Col>
              <Col xs="2">
                <Button className="transparent-bg gift-btn strong-border border-color__white--dark btn--normal text-color__white--dark text-uppercase with-letter-spacing normal-font font-size-small" style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center' }} full>
                  <Image className="icon" src="./img/unycos/icons/icon-gift.svg" />
                </Button>
              </Col>
              <Col xs="2">
                <Button className="transparent-bg heart-btn strong-border border-color__white--dark btn--normal text-color__white--dark text-uppercase with-letter-spacing normal-font font-size-small" style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center' }} full>
                  <Image className="icon" src="./img/unycos/icons/icon-heart.svg" />
                </Button>
              </Col>
            </Row>
          </Box>
        </Box>
      </UnycosAccordion>
    </Card>
  </Box>
);

UnycosSubscriptionWidget.defaultProps = {
  className: ''
};

UnycosSubscriptionWidget.propTypes = {
  className: PropTypes.string,
  click: PropTypes.func
};

export default UnycosSubscriptionWidget;
