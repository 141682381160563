import React from 'react';
import {
  Row,
  Col,
	Box,
	Button,
	Paragraph,
	Image,
	Card
} from 'base-ui';

const SubscriptionLandingCategories = () => {
  return(
    <Box>
      <Row id="subscription-categories" className="padding-top-x-big">
				<Col xs="12">
					<Box className="full-width-bg grey-bg">
						<Row className="center-xs">
							<Col xs="12" sm="10" md="6">
								<Paragraph textColor="main" size="big" className="without-margin center-xs"><strong>Categorías destacadas</strong></Paragraph>
								<Paragraph size="medium" className="without-margin center-xs">Estas son algunas de las categorías de cursos que tenemos disponibles.</Paragraph>
							</Col>
						</Row>
						<Row className="center-xs between-md padding-top-x-big padding-bottom-x-big">
							<Col xs="12" md="6">
								<Card className="with-top-thumb one">
									<Box className="thumb">
										<Box className="thumb-wrapper">
											<Image src="/img/categories/autoconocimiento.jpg" />
										</Box>
									</Box>
									<Box>
										<Paragraph textColor="main" className="without-margin-bottom"><strong>Educación</strong></Paragraph>
										<Paragraph className="without-margin">+27 horas de material</Paragraph>
									</Box>
								</Card>
							</Col>
							<Col xs="12" md="6">
								<Row>
									<Col xs="12" md="6">
										<Card className="with-top-thumb two">
											<Box className="thumb">
												<Box className="thumb-wrapper">
													<Image src="/img/categories/relaciones.jpg" />
												</Box>
											</Box>
											<Box>
												<Paragraph textColor="main" className="without-margin-bottom"><strong>Educación</strong></Paragraph>
												<Paragraph className="without-margin">+27 horas de material</Paragraph>
											</Box>
										</Card>
									</Col>
									<Col xs="12" md="6">
										<Card className="with-top-thumb three">
											<Box className="thumb">
												<Box className="thumb-wrapper">
													<Image src="/img/categories/desarrollo-de-aptitudes.jpg" />
												</Box>
											</Box>
											<Box>
												<Paragraph textColor="main" className="without-margin-bottom"><strong>Educación</strong></Paragraph>
												<Paragraph className="without-margin">+27 horas de material</Paragraph>
											</Box>
										</Card>
									</Col>
									<Col xs="12" md="6">
										<Card className="with-top-thumb four">
											<Box className="thumb">
												<Box className="thumb-wrapper">
													<Image src="/img/categories/salud-y-bienestar.jpg" />
												</Box>
											</Box>
											<Box>
												<Paragraph textColor="main" className="without-margin-bottom"><strong>Educación</strong></Paragraph>
												<Paragraph className="without-margin">+27 horas de material</Paragraph>
											</Box>
										</Card>
									</Col>
									<Col xs="12" md="6">
										<Card className="with-top-thumb five">
											<Box className="thumb">
												<Box className="thumb-wrapper">
													<Image src="/img/categories/coaching.jpg" />
												</Box>
											</Box>
											<Box>
												<Paragraph textColor="main" className="without-margin-bottom"><strong>Educación</strong></Paragraph>
												<Paragraph className="without-margin">+27 horas de material</Paragraph>
											</Box>
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xs="12" className="center-xs">
								<Button size="big" rounded textColor="white" borderColor="main" tag="a" color="main">
									Suscríbete
								</Button>
							</Col>
						</Row>
					</Box>
				</Col>
			</Row>
    </Box>
  )
}

export default SubscriptionLandingCategories;