import React from 'react';

import {
  Container
} from 'base-ui';

import BlogMenu from '../../components/Blog/BlogMenu';
import BlogFeaturedPostsGrid from '../../components/Blog/BlogFeaturedPostsGrid';
import BlogFeaturedVideos from '../../components/Blog/BlogFeaturedVideos';
import BlogFeaturedPosts from '../../components/Blog/BlogFeaturedPosts';
import BlogRecommendedPosts from '../../components/Blog/BlogRecommendedPosts';
import BlogMostSearched from '../../components/Blog/BlogMostSearched';
import BlogDiseaseList from '../../components/Blog/BlogDiseaseList';
import BlogPostsByCategory from '../../components/Blog/BlogPostsByCategory';
import BlogMorePostsList from '../../components/Blog/BlogMorePostsList';

const BlogHome = () => (
  <React.Fragment>
    <BlogMenu />
    <div id="wrapper">

      <Container fluid wrap>

        <BlogFeaturedPostsGrid />

        <main id="site-main">
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <BlogFeaturedVideos />
            </div>

            <aside id="sidebar" className="col-xs-12 col-md-4">
              <br />
              <div className="ad-space">
                <img alt="dummy" src="https://via.placeholder.com/300x250?Text=Banner+300x250" />
              </div>
            </aside>

            <div className="col-xs-12">
              <BlogFeaturedPosts />
              <BlogRecommendedPosts />
              <BlogMostSearched />
              <BlogDiseaseList />
              <BlogPostsByCategory />
            </div>
          </div>
        </main>

        {/* More topics list widget */}
        <BlogMorePostsList />

      </Container>
    </div>
  </React.Fragment>

);

export default BlogHome;
