/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
  PageWrapper,
  Container,
  Row,
} from 'base-ui';
import { CSSTransition } from 'react-transition-group';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';

import UnycosSidebar from './components/UnycosSidebar';
import UnycosMyCourses from './components/UnycosMyAccount/UnycosMyCourses';
import UnycosMyCertificates from './components/UnycosMyAccount/UnycosMyCertificates';
import UnycosProfileComments from './components/UnycosMyAccount/UnycosProfileComments';
import UnycosProfileEdit from './components/UnycosMyAccount/UnycosProfileEdit';
import UnycosProfileVerification from './components/UnycosMyAccount/UnycosProfileVerification';
import UnycosAccountHistory from './components/UnycosMyAccount/UnycosAccountHistory';
import UnycosAccountMethods from './components/UnycosMyAccount/UnycosAccountMethods';
import UnycosAccountConfigNotification from './components/UnycosMyAccount/UnycosAccountConfigNotification';
import UnycosAccountNotification from './components/UnycosMyAccount/UnycosAccountNotification';
import UnycosAccountPreferences from './components/UnycosMyAccount/UnycosAccountPreferences';
import UnycosAccountSecurity from './components/UnycosMyAccount/UnycosAccountSecurity';
import UnycosAccountDownloadData from './components/UnycosMyAccount/UnycosAccountDownloadData';
import UnycosAccountSubscription from './components/UnycosMyAccount/UnycosAccountSubscription';

/* To show the approval screen that needs to check how it would look if the
 * user was logged in and with a saved card, I did it with just a
 * simple parameter check in the url
*/

function UnycosMyAccount({ history }) {
  const [pageTitle, setPageTitle] = useState('Mis Cursos');
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState('courses-processing');

  useEffect(() => {
    if (history.location.hash) {
      const hashTab = history.location.hash.replace('#', '');
      setSelectedTab(hashTab);
      setSidebarStatus(!sidebarStatus);
    }
    // eslint-disable-next-line
  }, [history.location]);

  const showSidebarHandler = () => {
    sidebarStatus(!sidebarStatus);
  };

  return (
    <PageWrapper config={{ title: pageTitle }}>
      <UnycosLayout className="unycos payment">
        <UnycosHeader statuslogged isLoggedArea showMenu={false} toggleSidebar={() => showSidebarHandler()} />
        <Container fluid>
          <Row id="my-account" className="my-account center-xs">
            <UnycosSidebar show={sidebarStatus} tab={selectedTab}></UnycosSidebar>

            <Container className="logged-area" id="content">
              <CSSTransition in={selectedTab.indexOf('courses-') > -1} timeout={500} classNames="fade">
                <UnycosMyCourses filter={selectedTab}></UnycosMyCourses>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'certificates'} timeout={500} classNames="fade">
                <UnycosMyCertificates></UnycosMyCertificates>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'profile-edit'} timeout={500} classNames="fade">
                <UnycosProfileEdit></UnycosProfileEdit>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'profile-verification'} timeout={500} classNames="fade">
                <UnycosProfileVerification></UnycosProfileVerification>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'profile-comments'} timeout={500} classNames="fade">
                <UnycosProfileComments></UnycosProfileComments>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'account-config-notification'} timeout={500} classNames="fade">
                <UnycosAccountConfigNotification></UnycosAccountConfigNotification>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'account-notification'} timeout={500} classNames="fade">
                <UnycosAccountNotification></UnycosAccountNotification>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'account-methods'} timeout={500} classNames="fade">
                <UnycosAccountMethods></UnycosAccountMethods>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'account-preferences'} timeout={500} classNames="fade">
                <UnycosAccountPreferences></UnycosAccountPreferences>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'account-history'} timeout={500} classNames="fade">
                <UnycosAccountHistory></UnycosAccountHistory>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'account-security'} timeout={500} classNames="fade">
                <UnycosAccountSecurity></UnycosAccountSecurity>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'account-download-data'} timeout={500} classNames="fade">
                <UnycosAccountDownloadData></UnycosAccountDownloadData>
              </CSSTransition>
              <CSSTransition in={selectedTab === 'account-subscription'} timeout={500} classNames="fade">
                <UnycosAccountSubscription></UnycosAccountSubscription>
              </CSSTransition>
            </Container>
          </Row>
        </Container>
        <UnycosFooter
          showHideElements={{ social: true, cta: true }}
          contactText="Contacta con tu asesor personal"
          contactNumber="0000000"
          goldenButton
          from="my-account"
        />
      </UnycosLayout>
    </PageWrapper>
  );
}

export default withRouter(UnycosMyAccount);
