/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Paragraph,
  Heading,
  Row,
  Col,
	Box
} from 'base-ui';

const DiscussionList = ({ items, className }) => {
  return (
    <Box className={`${className}`}>
      {items.map((items) =>
        <Box className="content-forum discussions" key={items._id}>
          <Link to=".">
            <Row className="middle-xs between-md">
              <Col xs="12" sm="7">
                <Heading size="medium" type="h2" className="without-margin-bottom">{items.title}</Heading>
								<Paragraph className="normal-font font-size-small text-color without-margin-top">
									Por <Box tag="strong">{items.author}</Box> / Hace 2 minutos
								</Paragraph>
              </Col>
              <Col xs="12" sm="5" className="align-center">
								<Row>
									<Col xs="4" className="align-center">
										<Paragraph className="without-margin"><Box tag="strong">{items.stats.answers}</Box></Paragraph>
										<Box tag="span" className="font-size-small normal-font">Respuestas</Box>
									</Col>
									<Col xs="4" className="align-center">
										<Paragraph className="without-margin"><Box tag="strong">{items.stats.votes}</Box></Paragraph>
										<Box tag="span" className="font-size-small normal-font">Votos</Box>
									</Col>
									<Col xs="4" className="align-center">
										<Paragraph className="without-margin"><Box tag="strong">{items.stats.visits}</Box></Paragraph>
										<Box tag="span" className="font-size-small normal-font">Visitas</Box>
									</Col>
								</Row>
              </Col>
            </Row>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default DiscussionList;
