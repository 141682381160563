import React from 'react';
import PropTypes from 'prop-types';

import {
  Image
} from 'base-ui';

const BlogAdInfo = (props) => {
  const {
    logo, bgColor, fontColor, borderColor, clientName, adType
  } = props;

  return (
    <div className={`ad-info font-small ${borderColor}-border ${bgColor}-bg ${fontColor}-color`}>
      <p>{adType}</p>
      <Image src={logo} alt={clientName} />
    </div>
  );
};

BlogAdInfo.propTypes = {
  logo: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  fontColor: PropTypes.string,
  clientName: PropTypes.string,
  adType: PropTypes.string,
};

BlogAdInfo.defaultProps = {
  bgColor: 'grey',
  borderColor: 'regent-grey',
  fontColor: 'regent-grey',
  clientName: 'client',
  adType: 'Especial Publi',
};

export default BlogAdInfo;
