import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Lists,
  Paragraph,
} from 'base-ui';

const InformativeClause = () => (
  <PageWrapper config={{ title: 'Consultation Informative Clause', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section">
        <Container fluid wrap>
          <Row className="center-xs">
            <Col xs="12">

              <Heading type="h1" class="section-title big">CLAUSULA INFORMATIVA Y DE CONSENTIMIENTO</Heading>
              <Paragraph><strong>¿Quién es el responsable del tratamiento de sus datos?</strong></Paragraph>
              <Lists class="list-unstyled">
                <li>Identidad: GRUPO M CONTIGO, S.L</li>
                <li>CIF: B37527900</li>
                <li>Dir. postal: C/ Consejo,12 37002 / Salamanca</li>
                <li>Teléfono: 923267346</li>
                <li>Correo elect: protecciondedatos@mcontigo.com</li>
              </Lists>
              <Paragraph><strong>¿Con qué finalidad tratamos sus datos personales?</strong></Paragraph>
              <Paragraph>En GRUPO M CONTIGO, S.L tratamos la información que nos facilitan las personas interesadas con el fin de cumplir con la prestación del servicio.</Paragraph>
              <Paragraph><strong>¿Por cuánto tiempo conservaremos sus datos?</strong></Paragraph>
              <Paragraph>Los datos personales proporcionados se conservarán mientras se preste el servicio o posteriormente para posibles responsabilidades legales y para el caso de comunicaciones comerciales hasta que retire el consentimiento.</Paragraph>
              <Paragraph><strong>¿Cuál es la legitimación para el tratamiento de sus datos?</strong></Paragraph>
              <Paragraph>La base legal para el tratamiento de sus datos es el consentimiento del interesado.</Paragraph>
              <Paragraph><strong>¿A qué destinatarios se comunicarán sus datos?</strong></Paragraph>
              <Paragraph>Los datos no se ceden a terceros ajenos a la empresa.</Paragraph>
              <Paragraph><strong>¿Cuáles son sus derechos cuando nos facilita sus datos?</strong></Paragraph>
              <Paragraph>Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos personales que les conciernan, o no.</Paragraph>
              <Paragraph>Las personas interesadas tienen derecho a si acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</Paragraph>
              <Paragraph>En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</Paragraph>
              <Paragraph>En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En ese caso dejaremos de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Le informamos también del derecho a tutela de la autoridad de protección de datos, www.agpd.es.</Paragraph>

            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);


export default InformativeClause;
