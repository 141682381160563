import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Slider,
  Heading
} from 'base-ui';

// Components
import CardContentPost from '../CardPost';

const SliderFeaturedPosts = ({
  className, title, titleType, data
}) => (
  <Box tag="section" className={`section-featured-posts ${className}`}>
    {title && (
      <Heading type={titleType} className="titleLine">{title}</Heading>
    )}
    <Slider
      autoplay
      responsive={[{ breakpoint: 2500, settings: { slidesToShow: 1, arrows: true, slidesToScroll: 1 } }]}
    >
      {data.map((item) => (
        <CardContentPost
          key={item.id}
          className="without-shadow featured-post"
          post={{
            url: item.url,
            thumb: item.thumb,
            title: item.title,
            excerpt: item.excerpt
          }}
        />
      ))}
    </Slider>
  </Box>
);


SliderFeaturedPosts.defaultProps = {
  className: '',
};

SliderFeaturedPosts.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  titleType: PropTypes.string,
  className: PropTypes.string
};

export default SliderFeaturedPosts;
