import React from 'react';
import {
	Card,
  Col,
	Box,
	InputField,
	Paragraph,
	CTA,
	Button
} from 'base-ui';

const SubscriptionLandingCTA = () => {
  return(
    <Box>
      <Box className="main-bg padding-top-small padding-bottom-small">
				<Paragraph textColor="white" size="x-medium" className="without-margin center-xs"><strong>¿Dudas? Te llamamos</strong></Paragraph>
			</Box>
			<CTA
			BgImage="/img/support.jpg" className="without-margin">
				<Col xs="12" sm="9" md="7">
					<Card className="align-center alpha-bg-inverse without-margin without-shadow without-margin without-border">
						<InputField
							name='name'
							required
							type="text"
							placeholder="Nombre completo"
							className="without-shadow"
							full
							big
						/>
						<InputField
							name='name'
							required
							type="text"
							placeholder="Télefono movil"
							className="without-shadow"
							full
							big
						/>
						<Box className="margin-top-normal margin-bottom-normal">
							<input id="c3" type="checkbox" />
							<label for="c3">
									<Box tag="span" className="white-color font-size-small">Acepto los Terminos y condiciones</Box>
							</label>
						</Box>
						<Button size="big" textColor="white" borderColor="orange" full tag="a" color="orange">
							Solicitar información
						</Button>
					</Card>
				</Col>
			</CTA>
    </Box>
  )
}

export default SubscriptionLandingCTA;