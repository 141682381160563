import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading
} from 'base-ui';

// Components
import CourseUser from '../../../components/Courses/CourseUser';

const dataCourses = [
  {
    id: 1,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-10.png',
    professional: 'Cristina Soria',
    progress: '13',
    btLink: '#',
    btLabel: 'Ir al módulo 1',
    buttons: [
      {
        id: 1,
        label: 'Certificado',
        link: '#',
        icon: 'graduation-cap',
        classes: 'grey-border text-color'
      },
      {
        id: 2,
        label: 'Regalar',
        link: '#',
        icon: 'gift',
        classes: 'grey-border text-color'
      }
    ]
  },
];

const dataCoursesAvailable = [
  {
    id: 1,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-9.png',
    professional: 'Cristina Soria',
    progress: '0',
    btLink: '#',
    btLabel: 'Ir al módulo 1',
    buttons: [
      {
        id: 1,
        label: 'Empezar ya',
        link: '#',
        icon: 'graduation-cap',
        classes: 'grey-border text-color'
      },
      {
        id: 2,
        label: 'Regalar',
        link: '#',
        icon: 'gift',
        classes: 'grey-border text-color'
      }
    ]
  },
  {
    id: 2,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-8.png',
    professional: 'Cristina Soria',
    progress: '0',
    btLink: '#',
    btLabel: 'Ir al módulo 1',
    buttons: [
      {
        id: 1,
        label: 'Empezar ya',
        link: '#',
        icon: 'graduation-cap',
        classes: 'grey-border text-color'
      },
      {
        id: 2,
        label: 'Regalar',
        link: '#',
        icon: 'gift',
        classes: 'grey-border text-color'
      }
    ]
  },
];

const dataCoursesFree = [
  {
    id: 1,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-7.png',
    professional: 'Mario P.',
    progress: '0',
    btLink: '#',
    btLabel: 'Ir al módulo 1',
    buttons: [
      {
        id: 1,
        label: 'Empezar ya',
        link: '#',
        icon: 'graduation-cap',
        classes: 'grey-border text-color'
      },
      {
        id: 2,
        label: 'Regalar',
        link: '#',
        icon: 'gift',
        classes: 'grey-border text-color'
      }
    ]
  }
];

const dataCoursesCompleted = [
  {
    id: 1,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-5.png',
    professional: 'Cristina Soria',
    finishOn: 'Finalizado el 12 de Marzo/2019',
    buttons: [
      {
        id: 1,
        label: 'Certificado',
        link: '#',
        icon: 'graduation-cap',
        classes: 'blue-border blue-color'
      },
      {
        id: 2,
        label: 'Factura',
        link: '#',
        icon: 'file',
        classes: 'grey-border text-color'
      }
    ]
  },
  {
    id: 2,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-6.png',
    professional: 'Beverly Martins',
    finishOn: 'Finalizado el 18 de Marzo/2019',
    buttons: [
      {
        id: 1,
        label: 'Certificado',
        link: '#',
        icon: 'graduation-cap',
        classes: 'blue-border blue-color'
      },
      {
        id: 2,
        label: 'Factura',
        link: '#',
        icon: 'file',
        classes: 'grey-border text-color'
      }
    ]
  },
  {
    id: 3,
    title: 'Cambios',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-7.png',
    professional: 'Mario Peña',
    finishOn: 'Finalizado el 25 de Marzo/2019',
    buttons: [
      {
        id: 1,
        label: 'Certificado',
        link: '#',
        icon: 'graduation-cap',
        classes: 'blue-border blue-color'
      },
      {
        id: 2,
        label: 'Factura',
        link: '#',
        icon: 'file',
        classes: 'grey-border text-color'
      }
    ]
  },
];

const Courses = () => (
  <PageWrapper config={{ title: 'Courses User', bodyClasses: 'user-courses courses' }}>

    <Box tag="main" id="site-main">

      <Box tag="section" id="taking-course">
        <Container fluid wrap>
          <Row>

            <Col xs="12" className="margin-bottom-medium">
              <Heading type="h1" className="font-x-big margin-bottom-x-medium">Cursos en progresso</Heading>
              { dataCourses.map((course) => (
                <CourseUser
                  key={course.id}
                  title={course.title}
                  thumb={course.thumb}
                  professional={course.professional}
                  progress={course.progress}
                  btLink={course.btLink}
                  btLabel={course.btLabel}
                  buttons={course.buttons}
                />
              )) }

              <Box className="margin-top-x-big">
                <Heading type="h2" className="main-font margin-bottom-medium">Cursos disponibles en tu suscripción</Heading>
              </Box>
              { dataCoursesAvailable.map((course) => (
                <CourseUser
                  key={course.id}
                  title={course.title}
                  thumb={course.thumb}
                  professional={course.professional}
                  progress={course.progress}
                  btLink={course.btLink}
                  btLabel={course.btLabel}
                  buttons={course.buttons}
                />
              )) }

              <Box className="margin-top-x-big">
                <Heading type="h2" className="main-font margin-bottom-medium">Cursos gratis</Heading>
              </Box>
              { dataCoursesFree.map((course) => (
                <CourseUser
                  key={course.id}
                  title={course.title}
                  thumb={course.thumb}
                  professional={course.professional}
                  progress={course.progress}
                  btLink={course.btLink}
                  btLabel={course.btLabel}
                  buttons={course.buttons}
                />
              )) }

              <Box className="margin-top-x-big">
                <Heading type="h2" className="main-font margin-bottom-medium">Cursos completados</Heading>
              </Box>
              <Row className="row-courses-completed">
                { dataCoursesCompleted.map((course) => (
                  <Col key={course.id} xs="12" md="4">
                    <CourseUser
                      title={course.title}
                      thumb={course.thumb}
                      professional={course.professional}
                      finishOn={course.finishOn}
                      buttons={course.buttons}
                    />
                  </Col>
                )) }
              </Row>

            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default Courses;
