import React, { useState } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Icon,
  Heading,
  Label,
  Paragraph,
  NavLink,
  Image,
  InputField,
  InputFile,
  InputTextEditor
} from 'base-ui';

// Components
import MenuPublish from '../../../components/Consultation/MenuPublish';

const PublishPart2 = () => {
  const [showMenuPublish, setShowMenuPublish] = useState(false);

  const handlerClickMenuPublish = () => {
    setShowMenuPublish(!showMenuPublish);
  };

  return (
    <PageWrapper config={{ title: 'Consultation Publish Part 2', bodyClasses: `consultation publish ${showMenuPublish ? 'open-aside' : ''}` }}>

      <Box tag="main" id="site-main">

        <MenuPublish toggleAction={handlerClickMenuPublish} />

        <Box tag="section" id="single" className="single-publish">
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Card>
                  <Heading type="h4">Consulta</Heading>
                  <Paragraph>Sube una imagen que defina la consulta que ofreces. Por ejemplo, si va a ofrecer terapia de pareja, puede seleccionar una imagen de un compañero preocupado o reconciliado.</Paragraph>
                  <InputFile
                    name="file"
                    onChange={(file) => console.log(file)}
                  />
                  <figure className="featured-image">
                    <Image src="https://storage.googleapis.com/staging-storage-1/content/dev.lamenteesmaravillosa.com/services/5e8cbee2cd0d240012dd5c87/1-consulta-gratis.jpeg" alt="Image Example" />
                  </figure>
                  <InputField
                    label="Resumen corto"
                    name="textarea_resume"
                    type="textarea"
                    placeholder="Escribe el resumen aquí"
                  />
                  <Paragraph className="align-right">0/60</Paragraph>
                  <Box>
                    <InputTextEditor
                      name="editor_description"
                      label="Descripción"
                    />
                  </Box>

                  <Heading type="h4" className="margin-top-x-medium without-margin-bottom font-size-normal">Elige el precio más adecuado para tus consultas.</Heading>
                  <InputField
                    name="input_price"
                    label="Precio en euros (por consulta)"
                    type="text"
                    big
                    placeholder="50"
                  />

                  <Heading type="h4" className="margin-top-x-medium without-margin-bottom font-size-normal">¿Cuánto tiempo durará la consulta que ofreces? Indica la duración en minutos. Por ejemplo, 50, 60 o 120 minutos.</Heading>
                  <InputField
                    name="radio_minutes"
                    value="radio_50"
                    type="radio"
                    id="r1"
                  />
                  <Label inputId="r1">
                    50 minutos
                  </Label>
                  <InputField
                    name="radio_minutes"
                    value="radio_60"
                    type="radio"
                    id="r2"
                  />
                  <Label inputId="r2">
                    60 minutos
                  </Label>
                  <InputField
                    name="radio_minutes"
                    value="radio_120"
                    type="radio"
                    id="r3"
                  />
                  <Label inputId="r3">
                    120 minutos
                  </Label>
                  <InputField
                    name="radio_minutes"
                    value="radio_other"
                    type="radio"
                    id="r4"
                  />
                  <Label inputId="r4">
                    Otro
                  </Label>
                  <hr className="margin-top-x-big" />
                  <NavLink to="publish-3" className="btn main-bg main-border white-color big">
                    Continuar
                    {' '}
                    <Icon prefix="fas" name="arrow-right" />
                  </NavLink>
                </Card>
              </Col>
            </Row>
          </Container>
        </Box>

      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};

export default PublishPart2;
