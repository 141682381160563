import React, { Component } from 'react';
import { NavLink } from 'base-ui';

class BlogFeaturedPosts extends Component {

	render() {
		return(

      <section id="featured-posts-widget" className="widget posts-style-1">

        <div className="section-title main-border">
          <span className="label text-uppercase titles-color">Artículos destacados</span>
        </div>

        <div className="posts-row">

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-1.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href={"."}>Buenos hábitos</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  10 maneras de comenzar una rutina diaria y no morir en el intento
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-2.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href={"."}>Recetas</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-3.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href={"."}>Buenos hábitos</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-4.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Salud</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>
        </div>
      </section>
		)
	}
}

export default BlogFeaturedPosts;
