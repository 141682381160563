import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  Container,
  Paragraph,
  Heading,
  Row,
  Col,
  Box,
  Button,
  Icon,
  InputField
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';

class UnycosRequestCertificate extends Component {
  state = {
    pageTitle: 'Certificate',
    student: 'Joselito da Silva',
    course: 'Maquillaje',
    teachers: [
      {
        genre: 'Male',
        name: 'Lewis Amarante',
        signature: './assets/images/firma-lewis.png',
        desc: 'Identifica los diferentes tipos de piel, aprende la teoría del color, las técnicas más avanzadas de maquillaje de ojos, la aplicación perfecta del eyeliner y cómo realizar un maquillaje creativo de impacto.',
      },
      {
        genre: 'Male',
        name: 'Lewis Amarante',
        signature: './assets/images/firma-lewis.png',
        desc: 'Identifica los diferentes tipos de piel, aprende la teoría del color, las técnicas más avanzadas de maquillaje de ojos, la aplicación perfecta del eyeliner y cómo realizar un maquillaje creativo de impacto.',
      }
    ],
    message: '',
    rated: false,
    rate: null,
    certificateRequest: false,
    certificateName: ''
  }

  toggleRate() {
    const { rate } = this.state;

    this.setState({ rate: !rate });
  }

  sendRate() {
    this.setState({ rated: true });
  }

  showInputName() {
    this.setState({ certificateRequest: true });
  }

  render() {
    const {
      pageTitle,
      student,
      course,
      teachers,
      rate,
      rated,
      message,
      certificateName,
      certificateRequest
    } = this.state;

    return (
      <PageWrapper id="wrapper" config={{ title: pageTitle }}>
        <UnycosLayout className="unycos payment">
          <UnycosHeader statuslogged isLoggedArea showMenu={false} onlyBackBtn />
          <Container fluid>
            <Row id="my-account" className="my-account request-certificate">
              <Col xs="12" sm="6" className="logged-area">
                <Heading type="h2" className="request-certificate__title text-white">
                  {'🎉  '}
                  ¡Enhorabuena
                  <Box tag="span" className="text-gold">{` ${student}`}</Box>
                  {'!  🎉'}
                </Heading>
                <Paragraph className="request-certificate__text text-gray">
                  Has completado con éxito el curso
                  <br />
                  <Box tag="span" className="text-white">{course}</Box>
                  {' con '}
                  <Box tag="span" className="text-white">{teachers[1].name}</Box>
                </Paragraph>
                <Box className={`request-certificate__rate ${!rated ? 'visible' : ''}`}>
                  <Box tag="span" className="text-gold">¿Cuál ha sido tu experiencia?</Box>
                  <Button onClick={() => this.toggleRate()} className={`orange square hover-effect ${rate ? 'active' : ''}`}>
                    <Icon name="thumbs-up"></Icon>
                    <Box tag="span">Positiva</Box>
                  </Button>
                  <Button onClick={() => this.toggleRate()} className={`orange square hover-effect ${!rate && rate !== null ? 'active' : ''}`}>No muy buena</Button>
                </Box>
                <Box className={`request-certificate__comment ${!rated && rate !== null ? 'visible' : ''}`}>
                  <InputField
                    className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                    name="Mensaje"
                    type="textarea"
                    placeholder="Comentario o sugerencia."
                    label="Comentario:"
                    required={false}
                    value={message}
                    onChange={(value) => this.setState({ message: value })}
                    full
                    big
                  />
                  <Button onClick={() => this.sendRate()} className="orange square hover-effect">Enviar</Button>
                </Box>
                <Box className={`request-certificate__thanks ${rated ? 'visible' : ''}`}>
                  <Heading type="h2" className="request-certificate__title text-white">Obrigado pelo seu endosso.</Heading>
                  <InputField
                    className={`without-shadow rounded-small letter-spacing__small regent-grey-bg ${certificateRequest ? 'visible' : ''}`}
                    name="nombre"
                    type="text"
                    placeholder="Nombre completo"
                    required={false}
                    value={certificateName}
                    onChange={(value) => this.setState({ certificateName: value })}
                    full
                    big
                  />
                  <Button onClick={() => this.showInputName()} className={`orange square hover-effect ${!certificateRequest ? 'visible' : ''}`}>Quiero Mi Certificado</Button>
                  <Link to="/unycos/my-account#certificates" className={`btn orange square hover-effect ${certificateRequest ? 'visible' : ''}`}>Solicitar Certificado</Link>
                </Box>
              </Col>
            </Row>
          </Container>
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

export default UnycosRequestCertificate;
