import React, { useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';

// Base-UI components
import {
  Button
} from 'base-ui';

const SubjectButton = (props) => {
  const [subjectActive, setsubjectActive] = useState(false);
  const { button, onClick } = props;

  const handlerClick = (e) => {
    setsubjectActive(!subjectActive);
    onClick(e.currentTarget.getAttribute('value'));
  };
  return (
    <Button
      value={button.value}
      to={button.url}
      onClick={(e) => handlerClick(e)}
      className={cs('button-subject', {
        'is-active': subjectActive
      })}
      borderColor="title"
      textColor="title"
      color="white"
    >
      {button.label}
    </Button>
  );
};

SubjectButton.propTypes = {

  /**
  * The data from buttons subjects
  */
  button: PropTypes.shape({
    value: PropTypes.any.isRequired, /* the value  */
    label: PropTypes.string.isRequired, /* the label  */
    url: PropTypes.string.isRequired, /* the link/url  */
  }),

  /**
  * The function to return to parent, button data.
  */
  onClick: PropTypes.func,

};

export default SubjectButton;
