import React from 'react';
import PropTypes from 'prop-types';

const BlogShareButtonsFloating = ({ bookmarked }) => (
  <div className="share floating">
    <div className="buttons">
      <a href="." className={bookmarked ? 'pink-bg share-btn white-color' : 'regent-grey-bg share-btn dark-grey-color'}>
        <i className={bookmarked ? 'fas fa-bookmark' : 'far fa-bookmark'}></i>
      </a>
      <a href="." className="facebook-bg share-btn white-color"><i className="fab fa-facebook"></i></a>
      <a href="." className="twitter-bg share-btn white-color"><i className="fab fa-twitter"></i></a>
      <a href="." className="pinterest-bg share-btn white-color"><i className="fab fa-pinterest"></i></a>
      <a href="." className="whatsapp-bg share-btn white-color"><i className="fab fa-whatsapp"></i></a>
      <a href="." className="titles-bg share-btn white-color hidden-lg"><i className="fas fa-ellipsis-h"></i></a>
      <a href="." className="grey-bg next-article titles-color hidden-lg">
        Next
        <i className="fas fa-chevron-right"></i>
      </a>
    </div>
  </div>
);

BlogShareButtonsFloating.defaultProps = {
  bookmarked: false
};

BlogShareButtonsFloating.propTypes = {
  bookmarked: PropTypes.bool
};

export default BlogShareButtonsFloating;
