import React from 'react';
import PropTypes from 'prop-types';

import {
  Box
} from 'base-ui';

import './styles.css';

const UnycosPresaleRibbon = (props) => {
  const {
    className,
    ribbonText
  } = props;

  return (
    <Box className={`presale-ribbon black-color text-uppercase with-letter-spacing normal-font font-size-small ${className}`}>{ribbonText}</Box>
  );
};

UnycosPresaleRibbon.defaultProps = {
  className: '',
  ribbonText: 'PRESALE'
};

UnycosPresaleRibbon.propTypes = {
  className: PropTypes.string,
  ribbonText: PropTypes.string
};

export default UnycosPresaleRibbon;
