import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  PaywallLimit,
  PaywallLimitExcceded
} from 'base-ui';

import BlogMenu from '../../../components/Blog/BlogMenu';

const Paywall = () => (
  <React.Fragment>
    <BlogMenu />
    <PageWrapper config={{ title: 'Testing Paywall Components' }}>
      <Container fluid wrap>
        <Box tag="main" id="site-main" style={{ marginBottom: '200px' }}>
          <Row className="center-xs">
            <Col xs="12">
              <Box style={{ marginBottom: '20px' }}>
                <PaywallLimit missingArticles={6} />
              </Box>
              <Box style={{ marginBottom: '20px' }}>
                <PaywallLimit missingArticles={4} colors={{ color1: '#FFE175', color2: '#FFF3C7' }} />
              </Box>
            </Col>
          </Row>
          <Row className="center-xs" style={{ marginBottom: '20px' }}>
            <Col xs="12" sm="4">
              <PaywallLimit missingArticles={6} isMobile />
            </Col>
            <Col xs="12" sm="1"></Col>
            <Col xs="12" sm="4">
              <PaywallLimit missingArticles={4} colors={{ color1: '#FFE175', color2: '#FFF3C7' }} isMobile />
            </Col>
          </Row>
          <Row className="center-xs" style={{ marginBottom: '20px' }}>
            <Col xs="12">
              <PaywallLimitExcceded colors={{ color1: '#FFE175', color2: '#FFF3C7' }} />
            </Col>
          </Row>
          <Row className="center-xs" style={{ marginBottom: '20px' }}>
            <Col xs="12">
              <PaywallLimitExcceded colors={{ color1: '#FFE175', color2: '#FFF3C7' }} isLogged />
            </Col>
          </Row>
          <Row className="center-xs" style={{ marginBottom: '20px' }}>
            <Col xs="12" sm="4">
              <PaywallLimitExcceded colors={{ color1: '#FFE175', color2: '#FFF3C7' }} isMobile />
            </Col>
          </Row>
        </Box>
      </Container>
    </PageWrapper>
  </React.Fragment>
);

export default Paywall;
