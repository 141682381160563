import React from 'react';
import {
  Box,
  Row,
  Col,
  Image
} from 'base-ui';

const BlogAdvertising = () => (
  <Box tag="section" className="box-advertising">
    <Row>
      <Col xs="12">
        <Box className="ad-space hidden-md">
          <Image alt="dummy" src="https://via.placeholder.com/800x90?Text=Advertising" />
        </Box>
        <Box className="ad-space hidden-sm hidden-lg">
          <Image alt="dummy" src="https://via.placeholder.com/600x280?Text=Advertising" />
        </Box>
        <Box className="ad-space visible-sm hidden-md hidden-lg">
          <Image alt="dummy" src="https://via.placeholder.com/320x100?Text=Advertising" />
        </Box>
      </Col>
    </Row>
  </Box>
);

export default BlogAdvertising;
