import React, { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

import {
  PageWrapper, Container, Row, Col, Modal, Box, Paragraph, Button, Image, InputField, Label, NavLink, Heading, Tabs, Icon
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooterCTA from './components/UnycosFooterCTA';
import UnycosFooter from './components/UnycosFooter';
import UnycosSingleHero from './components/UnycosSingleHero';
import UnycosSingleExclusive from './components/UnycosSingleExclusive';
import UnycosSingleCourses from './components/UnycosSingleCourses';
import UnycosBlogArticles from './components/UnycosBlogArticles';
import UnycosSingleLesson from './components/UnycosSingleLesson';
import UnycosSingleSlides from './components/UnycosSingleSlides';
import UnycosStudentComments from './components/UnycosStudentComments';
import UnycosSingleLessonMobile from './components/UnycosSingleLessonMobile';
import UnycosSingleFAQ from './components/UnycosSingleFAQ';
import UnycosSingleReviews from './components/UnycosSingleReviews';
import UnycoUserComments from './components/UnycosUserComments';

const UnycosSingleThree = () => {
  // -- state
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [floatingCTAclassName, setFloatingCTAclassName] = useState('');

  // -- scripts
  const toggleCoupon = (e, go, type) => {
    let lastStep;
    if (type === 'add') {
      lastStep = go - 1;
    } else {
      lastStep = go + 1;
    }
    document.getElementById(`coupon-step-${lastStep}`).style.display = 'none';
    document.getElementById(`coupon-step-${go}`).style.display = 'flex';
    if (go === 5) {
      document.getElementById('old-price').style.display = 'inline-block';
      document.getElementById('new-price').style.display = 'inline-block';
      document.getElementById('atual-price').style.display = 'none';
    }
    return false;
  };

  const keyPress = (e) => {
    let val;
    const v = e.target.value;
    if (v.match(/^\d{2}$/) !== null) {
      e.target.value = `${v}/`;
    } else if (v.match(/^\d{3}\/\d{2}$/) !== null) {
      e.target.value = `${v}/`;
    }
    document.getElementById('credit-card-validate').setAttribute('value', val);
  };

  useEffect(() => {
    const d = document.documentElement;

    // scroll
    const handleScroll = throttle(() => {
      const e = document.body;
      const st = 'scrollTop';
      const sh = 'scrollHeight';
      setScrollPosition(Math.round(((d[st] || e[st]) / ((d[sh] || e[sh]) - d.clientHeight)) * 100));
    }, 50);

    // resize
    const handleResize = throttle(() => {
      if (d.clientWidth <= 1024) {
        setFloatingCTAclassName('bottom-fixed');
      } else {
        setFloatingCTAclassName('top-fixed');
      }
    }, 50);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    // eslint-disable-next-line consistent-return
    handleResize();
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [scrollPosition]);

  const handleControlPaymentModal = (v) => {
    setShowPaymentModal(v || !showPaymentModal);
  };

  const handleControlVideoModal = (v) => {
    setShowVideoModal(v || !showVideoModal);
  };

  return (
    <PageWrapper config={{ title: 'Unycos single' }}>
      <UnycosLayout>
        <Box className={`box-floating-cta ${floatingCTAclassName} ${scrollPosition > 2 ? 'show' : ''}`}>
          <UnycosHeader statuslogged={false} sticky />
          <div className={`btn-group bt-buy-group ${scrollPosition > 16 ? 'show' : ''}`}>
            <Button to="#" onClick={() => handleControlVideoModal(true)} className="bt-spot">Ver spot</Button>
            <span className="bt-buy-text">
              <span className="bt-buy-text-value">
                MEX$ 99
                <sup>99</sup>
                {' '}
                €
              </span>
              <span className="bt-buy-text-discount">
                10% DTO.
                <small>
                  Mex$ 120
                  <sup>00</sup>
                  {' '}
                  €
                </small>
              </span>
            </span>
            <Button onClick={() => window.open('https://feature-single-three.base.layout.review.mcontigo.io/unycos/payment')} className="bt-buy main-bg main-border strong-border btn--medium black-color text-uppercase with-letter-spacing normal-font font-size-small" full style={{ paddingLeft: '0', paddingRight: '0', fontSize: '1em' }}>
              Comprar
            </Button>
            <Button className="btn-gift transparent-bg border-color__white--dark btn--medium strong-border text-color__white--dark text-uppercase with-letter-spacing normal-font" style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center' }} full onClick={() => handleControlPaymentModal(true)}><Icon prefix="fas" name="gift" /></Button>
          </div>
        </Box>
        <UnycosHeader statuslogged={false} />
        <UnycosSingleHero presale />
        <Box className="unycos-features">
          <Container fluid wrap>
            <Row className="around-xs middle-xs">
              <Col xs="5" md="4" className="margin-top-small margin-bottom-small">
                <Row className="center-xs middle-xs">
                  <Col xs="12" sm="4" className="center-xs">
                    <Icon className="with-outline white-border">
                      <Image className="icon" src="./img/unycos/icons/icon-book.svg" alt="Book Icon" />
                    </Icon>
                  </Col>
                  <Col xs="12" sm="7" className="without-padding-left without-padding-right">
                    <Paragraph className="label line-height-medium center-xs start-md text-uppercase white-color font-size-medium">12 módulos</Paragraph>
                  </Col>
                </Row>
              </Col>
              <Col xs="5" md="4" className="margin-top-small margin-bottom-small">
                <Row className="center-xs middle-xs">
                  <Col xs="12" sm="4" className="center-xs">
                    <Icon className="with-outline white-border">
                      <Image className="icon" src="./img/unycos/icons/icon-video.svg" alt="Video Icon" />
                    </Icon>
                  </Col>
                  <Col xs="12" sm="7" className="without-padding-left without-padding-right">
                    <Paragraph className="label line-height-medium center-xs start-md text-uppercase white-color font-size-medium">+3,5 horas</Paragraph>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="4" className="first-xs last-md">
                <Row>
                  <Col xs="12" md="12">
                    <div className="bt-buy-group">
                      <span className="bt-buy-text">
                        <span className="bt-buy-text-value">
                          MEX$ 99
                          <sup>99</sup>
                          {' '}
                          €
                        </span>
                        <span className="bt-buy-text-discount">
                          10% DTO.
                          <small>
                            Mex$ 120
                            <sup>00</sup>
                            {' '}
                            €
                          </small>
                        </span>
                      </span>
                      <Button onClick={() => window.open('https://feature-single-three.base.layout.review.mcontigo.io/unycos/payment')} className="bt-buy main-bg main-border strong-border btn--medium black-color text-uppercase with-letter-spacing normal-font font-size-small " full style={{ paddingLeft: '0', paddingRight: '0', fontSize: '1em' }}>
                        Comprar
                      </Button>
                      <Button className="btn-gift transparent-bg border-color__white--dark btn--medium strong-border text-color__white--dark text-uppercase with-letter-spacing normal-font" style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center' }} full onClick={() => handleControlPaymentModal(true)}><Icon prefix="fas" name="gift" /></Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Box>
        <UnycosSingleFAQ className="hidden-lg" />
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Row>
                <Col xs="12" md="8">
                  <Heading type="h4" className="curso-slide-subheader center-xs letter-spacing__normal normal-font text-uppercase white-color font-size-x-medium without-margin-bottom">
                    <Box tag="span" className="teaches">ESTE CURSO ES PARTE DEL</Box>
                  </Heading>
                  <Heading type="h3" className="curso-slide-header center-xs letter-spacing__normal normal-font text-uppercase white-color font-size-x-medium without-margin-bottom without-margin-top">PROGRAMA COMPLETO DE MAQUILLAJE</Heading>
                </Col>
                <Col xs="12" md="4" className="slider-box-button">
                  <Button onClick={() => window.open('https://unycos.com/landing/maquillaje-programa/')} className="main-border main-bg white-color text-uppercase">Más Información</Button>
                </Col>
              </Row>
              <UnycosSingleSlides />
            </Col>
          </Row>
        </Container>
        <UnycosSingleExclusive buttonText="Comprar curso 120€" onClick={() => window.open('https://feature-single-three.base.layout.review.mcontigo.io/unycos/payment')} />
        <UnycosSingleLesson />
        <UnycosSingleLessonMobile className="hidden-lg" />
        <UnycosSingleReviews className="hidden-lg" />
        <UnycosSingleCourses className="hidden-md" />
        <UnycoUserComments className="hidden-lg" />
        <UnycosSingleCourses className="hidden-lg" />
        <UnycosStudentComments className="hidden-md margin-top-x-big" margin="-4px" />
        <UnycosBlogArticles />
        <UnycosFooterCTA />

        <UnycosFooter showHideElements={{ social: true, cta: true }} />

        <Modal
          isOpen={showPaymentModal}
          appElement="#root"
          idModal="modal-pagos"
          className="modal-unycos"
          closeModal={handleControlPaymentModal}
          closeIcon={false}
          styles={{
            overlay: {
              position: 'fixed',
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
            },
            content: {
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              boxShadow: 'none',
              padding: '0.5rem',
              left: '',
              top: '',
              bottom: '',
            }
          }}
        >
          <Box className="modal-header">
            <NavLink onClick={() => handleControlPaymentModal(false)} id="bt-close-modal"><Image src="./img/unycos/icons/icon_times.svg" alt="Time Icon" /></NavLink>
            <Heading type="h2">ACCESO COMPLETO</Heading>
            <Heading type="h3" className="hidden-sm hidden-md hidden-lg">Creatividad, desaprendizaje y inteligencia emocional</Heading>
            <Paragraph className="text-intro">
              Todos los cursos, en cualquier dispositivo, para que aprendas sin límites.
              <br />
              <span id="old-price">• 200.00 € </span>
              <strong id="new-price"> • 160.00 € / año</strong>
              <strong id="atual-price"> • 200.00 € / año</strong>
            </Paragraph>
            <Box id="box-price" className="hidden-sm hidden-md hidden-lg">
              <Paragraph className="text-price">
                <span className="text-old">€ 100.00 </span>
                € 65.00
              </Paragraph>
              <Paragraph className="text-end-promo">
                La promoción termina en:
                <strong> 11:53:13</strong>
              </Paragraph>
            </Box>
            <Box id="box-coupon">
              <Box className="bt-coupon coupon-add" id="coupon-step-1" onClick={(e) => { toggleCoupon(e, 2, 'add'); }}>
                <span className="txt-step">
                  <Icon prefix="fas" name="ticket-alt" />
                  ¿tienes un cupón?
                </span>
              </Box>
              <Box className="bt-coupon coupon-input" id="coupon-step-2">
                <InputField
                  name="descuento"
                  required
                  full={false}
                  type="text"
                  placeholder="Ex.: EXB123"
                />
                <Button onClick={(e) => { toggleCoupon(e, 3, 'add'); }}>
                  Aplicar
                </Button>
                <Button className="bt-cancel" onClick={(e) => { toggleCoupon(e, 1, 'del'); }}>
                  <Image src="./img/unycos/icons/icon_times.svg" alt="Time Icon" />
                </Button>
              </Box>
              <Box className="bt-coupon coupon-error" id="coupon-step-3">
                <span className="txt-step">
                  <Icon prefix="fas" name="times" />
                  Cupón inválido
                </span>
                <Box
                  tag="span"
                  className="coupon-other"
                  onClick={(e) => { toggleCoupon(e, 4, 'add'); }}
                >
                  &gt;&gt; Probar otro cupón
                </Box>
              </Box>
              <Box className="bt-coupon coupon-input" id="coupon-step-4">
                <InputField
                  name="descuento"
                  required
                  full={false}
                  type="text"
                  placeholder="Ex.: EXB123"
                />
                <Button onClick={(e) => { toggleCoupon(e, 5, 'add'); }}>
                  Aplicar
                </Button>
                <Box tag="span" className="bt-cancel" onClick={(e) => { toggleCoupon(e, 3, 'del'); }}>
                  <Image src="./img/unycos/icons/icon_times.svg" alt="Time Icon" />
                </Box>
              </Box>
              <Box className="bt-coupon coupon-ok" id="coupon-step-5">
                <span className="txt-step">
                  <Icon prefix="fas" name="check" />
                  ¡descuento aplicado! 20%
                </span>
              </Box>
            </Box>
          </Box>
          <Box className="modal-content">
            <Tabs>
              <Box label="1">
                <Row>
                  <Col xs="12" md="6">
                    <InputField
                      name="card_name"
                      required
                      type="text"
                      placeholder="Nombre del titular de la tarjeta"
                    />
                  </Col>
                  <Col xs="12" md="6">
                    <InputField
                      name="card_number"
                      required
                      type="number"
                      placeholder="Número de tarjeta"
                    />
                  </Col>
                  <Col xs="6">
                    <InputField
                      id="credit-card-validate"
                      name="card_validate"
                      required
                      type="text"
                      placeholder="MM/AA"
                      onKeyUp={keyPress}
                      maxLength="5"
                    />
                  </Col>
                  <Col xs="6">
                    <InputField
                      name="card_cvv"
                      required
                      type="text"
                      placeholder="CVV"
                    />
                  </Col>
                  <Col xs="12" md="6">
                    <InputField id="c1" name="guardar_datos" type="checkbox" />
                    <Label inputId="c1">
                      <Box tag="span">
                        Guardar datos de tu tarjeta para futuros pagos.
                        <strong>
                          &nbsp;Los datos de tu tarjeta serán almacenados de forma segura.
                        </strong>
                      </Box>
                    </Label>
                  </Col>
                  <Col xs="12" md="6">
                    <InputField id="c2" name="acepta_terminos" type="checkbox" />
                    <Label inputId="c2">
                      <Box tag="span">
                        Al hacer click en el botón “Comprar”, aceptas nuestros
                        <NavLink to="#">
                        &nbsp;términos de uso&nbsp;
                        </NavLink>
                        y nuestra
                        <NavLink to="#">
                        &nbsp;política de privacidad.
                        </NavLink>
                      </Box>
                    </Label>
                  </Col>
                  <Col xs="12">
                    <Button className="bt-cta"> Comprar</Button>
                  </Col>
                  <Col xs="12" className="texts-safty">
                    <span className="text-safe">
                      <Image src="./img/unycos/icons/icon-locked-padlock.svg" alt="Pago seguro" />
                      Pago seguro
                    </span>
                    <span className="text-guaranty">
                      <Image src="./img/unycos/icons/icon-return-invest.svg" alt="Garantía de satisfacción" />
                      Garantía de satisfacción
                    </span>
                  </Col>
                </Row>
              </Box>
              <Box label="2">
                <Paragraph>Data PayPal</Paragraph>
              </Box>
            </Tabs>
          </Box>
          <Box className="modal-footer">
            <Row>
              <Col xs="6">
                <Heading type="h5">100% garantizado</Heading>
                <Paragraph>Estamos seguros de que te encantará pero si no es así puedes solicitar solicitar tu reembolso.</Paragraph>
              </Col>
              <Col xs="6">
                <Heading type="h5">¿Tienes Dudas?</Heading>
                <Paragraph>
                  <i className="fab fa-whatsapp"></i>
                  +34 653 46 73 60
                </Paragraph>
              </Col>
            </Row>
          </Box>
        </Modal>
        <Modal
          isOpen={showVideoModal}
          appElement="#root"
          idModal="modal-video-single"
          className="modal-video-single"
          closeModal={() => handleControlVideoModal(false)}
          closeIcon={false}
          styles={{
            overlay: {
              position: 'fixed',
            },
            content: {
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              boxShadow: 'none',
              padding: '0',
              overflow: 'unset',
              top: '25px',
              right: '10px',
              bottom: '10px',
              left: '10px',
            },
            close: {
              display: 'none',
            }
          }}
        >
          <Button onClick={() => handleControlVideoModal(false)} id="bt-close-modal"><Image src="./img/unycos/icons/icon_times.svg" alt={''} /></Button>
          <iframe id="video" title="Mireia Belmonte" src="https://player.vimeo.com/video/345710922?embedparameter=value" width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
        </Modal>
      </UnycosLayout>
    </PageWrapper>
  );
};

export default UnycosSingleThree;
