import React, { Component } from 'react';

import {
  Container,
} from 'base-ui';

import BlogMenu from '../../components/Blog/BlogMenu';

// Sidebars
import BlogLinksListSidebar from '../../components/Blog/BlogLinksListSidebar';

// SocialBar
import SocialBar from '../../components/SocialBar/SocialBar';
import BlogMostSearched from '../../components/Blog/BlogMostSearched';

class BlogSingle extends Component {

  render() {
    return(

      <React.Fragment>
        
        <div className="archive">

          <BlogMenu />

          <div id="wrapper">

            <Container fluid wrap>

              <main id="site-main">
                <div className="row">
                  
                  <aside id="sidebar" class="col-xs-12 col-sm-4 col-md-3 last-xs first-sm">
                      <BlogLinksListSidebar />
                  </aside>

                  <div class="col-xs-12 col-sm-8">
                    <div class="section-header">
                        <h1 class="label">Cáncer</h1>
                        <p class="description">El nombre común que recibe un conjunto de enfermedades relacionadas en las que se observa un proceso descontrolado en la división de las células del cuerpo.</p>
                    </div>

                    <iframe title="uniqueTitleForIframe" width="560" height="315" src="https://www.youtube.com/embed/j2cD_c6Wy2s" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>

                    { /* <?php
                        //Latest posts
                        include 'template-parts/widgets/category-latest-slider.php';

                        //Most searched widget
                        include 'template-parts/widgets/category-most-searched.php';
                    ?> */ }
                    <BlogMostSearched />
                    <div class="card infographics">
                        <div class="row center-xs">
                            <div class="col-xs-12 col-md-8 align-center">
                                <dl>
                                    <dd class="font-big align-center">10'000.000</dd>
                                    <dt class="align-center">Nuevos casos cada año durante la última decada</dt>
                                </dl>
                            </div>
                        </div>
                        <div class="row middle-xs">
                            <div class="col-xs-2 align-center">
                                <span class="utilitary-color font-big">
                                    <i class="fas fa-venus"></i>
                                    <span class="display-block align-center">63%</span>
                                </span>
                            </div>
                            <div class="col-xs-8">
                                <div class="percent-bar main-bg">
                                    <div class="percent-bar-percentage utilitary-bg" style={{'width': '63%'}}></div>
                                </div>
                            </div>
                            <div class="col-xs-2 align-center">
                                <span class="main-color font-big">
                                    <i class="fas fa-mars"></i>
                                    <span class="display-block align-center">37%</span>
                                </span>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-xs-12">
                                <h2>Causas</h2>
                            </div>
                            <div class="col-xs-12">
                                <ul class="with-img">
                                    <li>
                                        <div class="thumb-wrap">
                                            <img alt="" src="https://i.pinimg.com/736x/56/c5/a8/56c5a8aacc6725d064eb89b52d8ff6fb--womens-health-health-care.jpg" class="thumb-bg" />
                                        </div>
                                        <h3 class="title">Virus del papiloma humano (VPH)</h3>
                                    </li>
                                </ul>
                                <hr />
                            </div>
                            <div class="col-xs-12">
                                <h2>Síntomas</h2>
                            </div>
                            <div class="col-xs-12">
                                <ul class="with-img">
                                    <li>
                                        <div class="thumb-wrap">
                                            <img alt="" src="https://thumbs.dreamstime.com/b/virus-del-papiloma-hpv-52736124.jpg" class="thumb-bg" />
                                        </div>
                                        <h3 class="title">Verrugas y lesiones escamosas genitales</h3>
                                    </li>
                                </ul>
                                <hr />
                            </div>
                            <div class="col-xs-12">
                                <h2>Diagnostico</h2>
                            </div>
                            <div class="col-xs-12">
                                <ul class="with-img">
                                    <li>
                                        <div class="thumb-wrap">
                                            <img alt="" src="https://www.healthcareitnews.com/sites/default/files/genetics-charting-stock-712.jpg" class="thumb-bg" />
                                        </div>
                                        <h3 class="title">Exploración médica</h3>
                                    </li>
                                </ul>
                                <hr />
                            </div>
                            <div class="col-xs-12">
                                <h2>Tratamiento</h2>
                            </div>
                            <div class="col-xs-12">
                                <ul class="with-img">
                                    <li>
                                        <div class="thumb-wrap">
                                            <img src="http://www.sunnyph.com/Content/Uploads/2015/01/pills.jpg" class="thumb-bg" alt="" />
                                        </div>
                                        <h3 class="title">Farmacológico con antivirales</h3>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr /><br />
                        <div class="row center-xs">
                            <div class="col-xs-9 align-center">
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male secondary-color big"></i>
                                <i class="fas fa-male big"></i>
                                <dl>
                                    <dd class="font-big align-center">9 de cada 10 casos</dd>
                                    <dt class="align-center">Tienen un pronostico favorable</dt>
                                </dl>
                            </div>
                        </div>
                    </div>

                    <div class="section-title">
                        <span class="label titles-color text-uppercase">Podrían gustarte</span>
                    </div>

                    { /*
                    <?php
                        //Interesting posts widget
                        include 'template-parts/widgets/latest-posts-basic.php';
                    ?> */ }
                    
                  </div>

                </div>
              </main>

            </Container>

          </div>

          <SocialBar />
        </div>
        </React.Fragment>

    )
  }
}

export default BlogSingle;
