import React from 'react';

import {
  Container,
  Row,
  Box,
  PageWrapper,
  Col,
	Card,
	Image,
	Icon,
	Tabs,
	Heading,
	Paragraph,
	Button
} from 'base-ui';

const TabOne = <Row className="middle-xs">
	<Col xs="3">
		<Image src="/img/categories/coaching.jpg" alt="coaching" />
	</Col>
	<Col xs="6">
		<Box tag="span" className="title"><Icon prefix="fas" name="play-circle" /> Introducción</Box>
	</Col>
	<Col xs="3" className="align-center">
		<Box tag="span">3 minutos</Box>
	</Col>
</Row>;

const TabTwo = <Row className="middle-xs">
	<Col xs="3">
		<Image src="/img/categories/relaciones.jpg" alt="relaciones" />
	</Col>
	<Col xs="6">
		<Box tag="span" className="title"><Icon prefix="fas" name="play-circle" /> Tensión entre mandatos reales e irreales</Box>
	</Col>
	<Col xs="3" className="align-center">
		<Box tag="span">3 minutos</Box>
	</Col>
</Row>;

const StepFive = () => (
  <PageWrapper>
    <Box className="learn-steps">
      <Box className="learn-steps-progress">
        <Box className="item active main-bg"></Box>
        <Box className="item active main-bg"></Box>
        <Box className="item active main-bg"></Box>
        <Box className="item active main-bg"></Box>
        <Box className="item active main-bg"></Box>
      </Box>
      <Container fluid wrap>
        <Row className="special-row middle-xs center-xs">
          <Col xs="12" sm="10" md="8" className="align-center margin-top-x-big margin-bottom-x-big">
						<Paragraph>Disfruta tu primer clase totalmente gratis</Paragraph>
						<Heading type="h2" size="big">Aprendiendo a ser buenos padres</Heading>
            <Card className="margin-top-medium">
							<Tabs className="learn-steps-tabs">
								<Box label={TabOne}>
									<Box className="videoPlayer">
										<iframe title="YTVideo" width="560" height="315" src="https://www.youtube.com/embed/IJFWLAfY9zU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									</Box>
								</Box>
								<Box label={TabTwo}>
									<Box className="videoPlayer">
										<iframe title="YTVideo" width="560" height="315" src="https://www.youtube.com/embed/IJFWLAfY9zU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
									</Box>
								</Box>
							</Tabs>
							<Button color="orange" borderColor="orange" textColor="white" size="big" full>
								Comprar curso <Icon prefix="fas" name="arrow-right" />
							</Button>
						</Card>
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default StepFive;
