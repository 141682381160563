import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Box, Row, Col, Heading, Message
} from 'base-ui';

// Components
import QuizAnsware from './QuizAnsware';

// Styles
import './style.scss';

const QuizQuestion = ({ data }) => {
  const {
    id, question, options, answare, teacherComment
  } = data;

  const [answareEnabled, setAnswareEnabled] = useState(true);

  const handleClicked = (v) => {
    setAnswareEnabled(!v);
  };
  return (
    <Box className="question-group">
      <Row>
        <Col xs="12">
          <Heading type="h2" className="question-title padding-bottom-normal">
            {question}
          </Heading>
        </Col>
        <Col xs="12" md="7">
          {options.map((item) => (
            <QuizAnsware
              key={item.id}
              data={
                {
                  questionId: id,
                  id: item.id,
                  text: item.text,
                  answare,
                  click: (v) => handleClicked(v),
                  canAnsware: answareEnabled
                }
              }
            />
          ))}
        </Col>
        <Col
          xs="12"
          md="5"
          className={cs('col-comment',
            {
              'increase-height': !answareEnabled && (teacherComment !== null)
            }
          )}
        >
          {!answareEnabled && (teacherComment !== null) && (
            <Message
              iconName="comment"
              textColor="title"
              iconPrefix="fas"
              className="fade-in increase-height"
            >
              {teacherComment}
            </Message>
          )}
        </Col>
      </Row>
    </Box>
  );
};

QuizQuestion.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    question: PropTypes.string,
    options: PropTypes.array,
    answare: PropTypes.number,
    teacherComment: PropTypes.string
  }),
};

export default QuizQuestion;
