/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import { useAmp } from 'next/amp';
import { LazyImage } from 'react-lazy-images';
// import browserDetection from '../../utils/browserDetection';

import Loading from '../Loading';

/**
 * Image.js
 */
const Image = ({
  alt,
  className,
  src,
  srcSet,
  children,
  maxHeight,
  maxWidth,
  loading,
  lazy,
  style,
  width,
  height,
  sizes,
  loadingProps,
  layout,
  title,
  place
}) => {
  const handleCreateStyle = (styles) => {
    if (maxWidth) styles.maxWidth = maxWidth;
    if (maxHeight) styles.maxHeight = maxHeight;
    return styles;
  };

  if (useAmp()) {
    return (
      <amp-img
        src={src}
        srcSet={srcSet}
        width={width}
        height={height}
        sizes={sizes}
        alt={alt}
        layout={layout}
        title={title}
      />
    );
  }


  if (lazy) {
    // if (browserDetection('ie')) {
    //   return (
    //     <img
    //       alt={alt}
    //       className={className}
    //       src={src}
    //       srcSet={srcSet}
    //       sizes={sizes}
    //       width={width}
    //       height={height}
    //       title={title}
    //     />
    //   );
    // }

    const spinner = children || (loadingProps.type === 'blur' && (
      <div
        style={{
          transition: 'filter 0s',
          '-webkit-filter': 'blur(17px)',
          filter: 'blur(17px)'
        }}
      >
        <img alt={alt} className="LazyImage" src={src} title={title} />
      </div>
    )) || <Loading {...loadingProps} className="LazyImage" />;


    if (place === 'background') {
      return (
        <LazyImage
          src={src}
          placeholder={
            ({ imageProps, ref }) => (
              <div
                alt={alt}
                title={title}
                className={className}
                src={src}
                srcSet={srcSet}
                sizes={sizes}
                width={width}
                height={height}
                debounceDurationMs={loadingProps.time}
                ref={ref}
                style={{
                  width: loadingProps.width
                    ? `${loadingProps.width}px`
                    : '100%',
                  height: loadingProps.height
                    ? `${loadingProps.height}px`
                    : '100%',
                  transition: 'filter 0s',
                  '-webkit-filter': 'blur(17px)',
                  filter: 'blur(17px)',
                  backgroundImage: `url(${imageProps.src})`
                }}
              />
            )
          }

          actual={
            ({ imageProps, ref }) => (
              <div
                alt={alt}
                title={title}
                className={className}
                src={src}
                srcSet={srcSet}
                sizes={sizes}
                width={width}
                height={height}
                debounceDurationMs={loadingProps.time}
                ref={ref}
                style={{
                  width: loadingProps.width
                    ? `${loadingProps.width}px`
                    : '100%',
                  height: loadingProps.height
                    ? `${loadingProps.height}px`
                    : '100%',
                  backgroundImage: `url(${imageProps.src})`
                }}
              />
            )
          }
        />
      );
    }

    return (
      <React.Fragment>
        <noscript>
          <img
            alt={alt}
            className={className}
            src={src}
            srcSet={srcSet}
            sizes={sizes}
            width={width}
            height={height}
            title={title}
          />
        </noscript>
        <LazyImage
          alt={alt}
          title={title}
          className={className}
          src={src}
          srcSet={srcSet}
          sizes={sizes}
          width={width}
          height={height}
          debounceDurationMs={loadingProps.time}
          placeholder={
            ({ imageProps, ref }) => (
              <div
                ref={ref}
                className="LazyImage"
                style={{
                  width: loadingProps.width
                    ? `${loadingProps.width}px`
                    : '100%',
                  height: loadingProps.height
                    ? `${loadingProps.height}px`
                    : '100%'
                }}
              >
                {spinner}
                <img
                  className="LazyImage"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  alt={imageProps.alt}
                />
              </div>
            )
          }
          actual={
            ({ imageProps }) => <img {...imageProps} />}
        />
      </React.Fragment>
    );
  }

  return (
    <img
      loading={loading}
      alt={alt}
      className={className}
      src={src}
      srcSet={srcSet}
      style={handleCreateStyle(style)}
      title={title}
    />
  );
};

Image.defaultProps = {
  style: {},
  loadingProps: {
    time: 0
  },
  loading: 'auto',
  lazy: false,
  srcSet: '',
  title: '',
  place: 'inline'
};

Image.propTypes = {

  /**
   * The classname prop
   */
  className: PropTypes.string,
  /**
   * The alt tag
   */
  alt: PropTypes.string.isRequired,

  /**
   * The title tag
   */
  title: PropTypes.string,

  /**
   * The max height of the image (rhythm units)
   */
  maxHeight: PropTypes.number,

  /**
   * The max width of the image (rhythm units)
   */
  maxWidth: PropTypes.number,

  /**
   * The image src
   */
  src: PropTypes.string.isRequired,

  /**
   * Custom styles
   */
  loading: PropTypes.oneOf(['auto', 'eager', 'lazy']),

  /**
   * Set image Lazy Loading
   */
  lazy: PropTypes.bool,

  /**
   * Props to be spread onto the constructicon loading dots (width, height, time, type [bars, circle, pulse, blur])
   */
  loadingProps: PropTypes.object,

  /**
   * Custom styles
   */
  style: PropTypes.object,

  /**
   * Sets image according to user connection quality (`${small-image} 300w, ${medium-image} 768w, ${large-image} 1280w`)
   */
  srcSet: PropTypes.string,

  /**
   * For AMP image width (300px)
   */
  width: PropTypes.string,

  /**
   * For AMP image height (300px)
   */
  height: PropTypes.string,

  /**
   * For AMP image sizes ((min-width: 765px) 50vw, 100vw)
   */
  sizes: PropTypes.string,

  /**
   * For AMP image (responsive)
   */
  layout: PropTypes.string,

  /**
   * Optional children that will be rendered when loading, instead of the constructicon loading dots
   * */
  children: PropTypes.any,

  /**
   * For render image on inline or background
   */
  place: PropTypes.oneOf(['inline', 'background'])
};

export default Image;
