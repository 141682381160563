import React, { useState } from 'react';

// Base-UI Components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Paragraph,
  InputField,
  Label,
  Image,
  Button,
  NavLink,
  Card,
  Heading
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogHero from '../../components/Blog/BlogHero';
import BlogFooterOffices from '../../components/Blog/BlogFooterOffices';

const pageConfig = {
  product: 'La Mente es Maravillosa',
  title: 'Contacta con nosotros',
  meta: [
    { name: 'description', content: 'Envíanos un mensaje a través del siguiente formulario.' }
  ]
};

const BlogFeedback = () => {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`
      Nombre: ${name},
      Asunto: ${subject},
      Email: ${email},
      Message: ${message},
    `);
  };

  return (
    <React.Fragment>
      <BlogMenu />
      <PageWrapper config={pageConfig}>
        <Box className="page-template-feedback">
          <Box id="feedback-hero">
            <BlogHero
              title={pageConfig.title}
              subtitle={pageConfig.meta[0].content}
              backgroundImage="./img/feedback-hero.jpg"
              className="hero"
            />
          </Box>
          <Box className="special-form">
            <Container fluid wrap>
              <Row className="center-xs">
                <Col xs="12" md="11" className="align-center">
                  <Paragraph>
                    Hacemos todo lo posible para garantizar la calidad de nuestro contenido y brindar la mejor experiencia de usuario.
                  </Paragraph>
                  <Paragraph>
                    Por eso tu ayuda es tan importante.
                  </Paragraph>
                  <Paragraph>
                    <strong>
                      Muchas gracias por visitar
                      {' '}
                      {pageConfig.product}
                      .
                    </strong>
                  </Paragraph>
                </Col>
                <Col xs="12" md="8">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Box>
                      <Image src="./img/user-icon.svg" alt="User Icon" className="icon" />
                      <InputField
                        name="name"
                        value={name}
                        required
                        type="text"
                        full
                        big
                        placeholder="Nombre"
                        onChange={(v) => setName(v)}
                      />
                    </Box>
                    <Box>
                      <Image src="./img/briefcase-icon.svg" alt="Briecase Icon" className="icon" />
                      <InputField
                        name="name"
                        value={subject}
                        required
                        type="text"
                        full
                        big
                        placeholder="Asunto"
                        onChange={(v) => setSubject(v)}
                      />
                    </Box>
                    <Box>
                      <Image src="./img/icon-envelope.svg" alt="Email Icon" className="icon" />
                      <InputField
                        name="name"
                        value={email}
                        required
                        type="email"
                        full
                        big
                        placeholder="Email"
                        onChange={(v) => setEmail(v)}
                      />
                    </Box>
                    <Box>
                      <Image src="./img/pencil-icon.svg" alt="Pencil Icon" className="icon" />
                      <InputField
                        name="name"
                        value={message}
                        required
                        type="textarea"
                        full
                        big
                        placeholder="Mensaje"
                        onChange={(v) => setMessage(v)}
                      />
                    </Box>
                    <Box className="row between-xs">
                      <Col xs="12" sm="8" className="center-xs start-sm">
                        <Box>
                          <InputField
                            id="c1"
                            type="checkbox"
                            required
                          />
                          <Label inputId="c1">
                            He leído y acepto la
                            {' '}
                            <NavLink to="/page">política de privacidad</NavLink>
                          </Label>
                        </Box>
                      </Col>
                      <Col xs="12" sm="4" className="center-xs end-sm">
                        <Button
                          type="submit"
                          tag="button"
                          color="main"
                          textColor="white"
                          borderColor="main"
                          size="big"
                        >
                          Enviar
                        </Button>
                      </Col>
                    </Box>
                  </form>
                </Col>
              </Row>
            </Container>
          </Box>
          <Box className="special-form">
            <Container fluid wrap>
              <Row className="center-xs">
                <Col xs="12" md="12">
                  <Card color="yellow" size="medium" textColor="black" borderColor="yellow" className="covid-alert">
                    <Row className="center-xs">
                      <Col xs="12" sm="2" className="image-content">
                        <Image src="./img/blog/work-from-home.png" />
                      </Col>
                      <Col xs="12" sm="10" className="alert-content">
                        <Heading type="h2" size="x-medium" textColor="black">Nos quedamos en casa</Heading>
                        <Paragraph>
                          Apoyamos la cuarentena, pero seguimos disponibles. Por ello, si deseas contactar con nosotros, llámanos a
                          <strong> (+34) 687 586 929</strong>
                        </Paragraph>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Box>
          <BlogFooterOffices />
        </Box>
      </PageWrapper>
    </React.Fragment>
  );
};

export default BlogFeedback;
