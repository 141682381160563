/* eslint-disable no-plusplus */
import React from 'react';

import {
  Col, Accordion, AccordionGroup, AccordionContent, AccordionControl
} from 'base-ui';


/* const accordionContent = [
  {
    label: 'item 1',
    content: 'content item 1',
    isOpen: false
  },
  {
    label: 'item 2',
    content: 'content item 2',
    isOpen: false
  },
  {
    label: 'item 3',
    content: 'content item 3',
    isOpen: true
  },
  {
    label: 'item 4',
    content: 'content item 4',
    isOpen: false
  },
  {
    label: 'item 5',
    content: 'content item 5',
    isOpen: false
  },
]; */


function AccordionScreen() {
  return (
    <div>
      <Col
        xs="10"
        style={{ border: '5px', marginLeft: '20px' }}
      >
        <h1>Accordion Screen Allow Multiple Open</h1>
        <AccordionGroup>
          <Accordion id={1}>
            <AccordionControl>
              Accordion title 1
            </AccordionControl>
            <AccordionContent>
              <Col xs="12">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec venenatis laoreet nunc vitae gravida. Curabitur tempor sem vehicula laoreet dapibus. Integer non neque sed sapien vehicula faucibus. Praesent diam mi, lacinia vitae mattis in, tempor et nibh. Nullam elementum laoreet massa ac hendrerit. Integer fermentum quam ligula, et sodales mi facilisis sed. Morbi tincidunt nulla id vulputate rhoncus. Ut fermentum arcu id sapien dignissim congue. Nam cursus sem magna, id tempus nibh eleifend in. Duis diam diam, lacinia sed diam vel, porttitor hendrerit nulla. Donec magna dolor, ultricies at erat quis, venenatis hendrerit quam.
              </Col>
            </AccordionContent>
          </Accordion>
          <Accordion id={2}>
            <AccordionControl>
              Accordion title 2
            </AccordionControl>
            <AccordionContent>
              <Col xs="12">
                In quis quam vestibulum, auctor mi ut, cursus quam. Donec ut tristique neque. Suspendisse nisi eros, volutpat a tempor at, suscipit at libero. Vivamus vitae est nisl. Nunc malesuada dolor eu hendrerit egestas. Aenean quis orci sed metus ornare hendrerit. Pellentesque sit amet cursus lacus. Curabitur a velit sed nulla aliquam varius. Donec a massa nec lectus blandit dignissim sit amet ut sem. In dapibus massa a elementum mattis. Phasellus bibendum iaculis ligula ac accumsan. Mauris eu scelerisque dolor. Nam vel elit rhoncus, tincidunt diam a, rhoncus est. Duis dictum dui augue, placerat iaculis eros fringilla nec. Mauris porttitor mi nec euismod ultrices. Donec id congue velit.
              </Col>
            </AccordionContent>
          </Accordion>
          <Accordion id={3}>
            <AccordionControl>
              Accordion title 3
            </AccordionControl>
            <AccordionContent>
              <Col xs="12">
                In quis quam vestibulum, auctor mi ut, cursus quam. Donec ut tristique neque. Suspendisse nisi eros, volutpat a tempor at, suscipit at libero. Vivamus vitae est nisl. Nunc malesuada dolor eu hendrerit egestas. Aenean quis orci sed metus ornare hendrerit. Pellentesque sit amet cursus lacus. Curabitur a velit sed nulla aliquam varius. Donec a massa nec lectus blandit dignissim sit amet ut sem. In dapibus massa a elementum mattis. Phasellus bibendum iaculis ligula ac accumsan. Mauris eu scelerisque dolor. Nam vel elit rhoncus, tincidunt diam a, rhoncus est. Duis dictum dui augue, placerat iaculis eros fringilla nec. Mauris porttitor mi nec euismod ultrices. Donec id congue velit.
              </Col>
            </AccordionContent>
          </Accordion>
        </AccordionGroup>
      </Col>

      <Col
        xs="10"
        style={{ border: '5px', marginLeft: '20px' }}
      >
        <h1>Accordion 2</h1>
        <AccordionGroup open-single="true">
          <Accordion isOpen id={1}>
            <AccordionControl>
              Accordion title 1
            </AccordionControl>
            <AccordionContent>
              <Col xs="12">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec venenatis laoreet nunc vitae gravida. Curabitur tempor sem vehicula laoreet dapibus. Integer non neque sed sapien vehicula faucibus. Praesent diam mi, lacinia vitae mattis in, tempor et nibh. Nullam elementum laoreet massa ac hendrerit. Integer fermentum quam ligula, et sodales mi facilisis sed. Morbi tincidunt nulla id vulputate rhoncus. Ut fermentum arcu id sapien dignissim congue. Nam cursus sem magna, id tempus nibh eleifend in. Duis diam diam, lacinia sed diam vel, porttitor hendrerit nulla. Donec magna dolor, ultricies at erat quis, venenatis hendrerit quam.
              </Col>
            </AccordionContent>
          </Accordion>
          <Accordion id={2}>
            <AccordionControl>
              Accordion title 2
            </AccordionControl>
            <AccordionContent>
              <Col xs="12">
                In quis quam vestibulum, auctor mi ut, cursus quam. Donec ut tristique neque. Suspendisse nisi eros, volutpat a tempor at, suscipit at libero. Vivamus vitae est nisl. Nunc malesuada dolor eu hendrerit egestas. Aenean quis orci sed metus ornare hendrerit. Pellentesque sit amet cursus lacus. Curabitur a velit sed nulla aliquam varius. Donec a massa nec lectus blandit dignissim sit amet ut sem. In dapibus massa a elementum mattis. Phasellus bibendum iaculis ligula ac accumsan. Mauris eu scelerisque dolor. Nam vel elit rhoncus, tincidunt diam a, rhoncus est. Duis dictum dui augue, placerat iaculis eros fringilla nec. Mauris porttitor mi nec euismod ultrices. Donec id congue velit.
              </Col>
            </AccordionContent>
          </Accordion>
          <Accordion id={3}>
            <AccordionControl>
              Accordion title 3
            </AccordionControl>
            <AccordionContent>
              <Col xs="12">
                In quis quam vestibulum, auctor mi ut, cursus quam. Donec ut tristique neque. Suspendisse nisi eros, volutpat a tempor at, suscipit at libero. Vivamus vitae est nisl. Nunc malesuada dolor eu hendrerit egestas. Aenean quis orci sed metus ornare hendrerit. Pellentesque sit amet cursus lacus. Curabitur a velit sed nulla aliquam varius. Donec a massa nec lectus blandit dignissim sit amet ut sem. In dapibus massa a elementum mattis. Phasellus bibendum iaculis ligula ac accumsan. Mauris eu scelerisque dolor. Nam vel elit rhoncus, tincidunt diam a, rhoncus est. Duis dictum dui augue, placerat iaculis eros fringilla nec. Mauris porttitor mi nec euismod ultrices. Donec id congue velit.
              </Col>
            </AccordionContent>
          </Accordion>
        </AccordionGroup>
      </Col>


    </div>
  );
}

export default AccordionScreen;
