import React from 'react';
import {
  Box,
  Image,
  Heading,
  Paragraph,
  Card,
  Button
} from 'base-ui';

import Slider from 'react-slick';

class UnycosSingleSlides extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const MainSlides = [
      {
        id: '1',
        title: 'Fudamentos de Maquillaje',
        desc: 'Aprende cuáles son los principales pilares del maquillaje, desde los mejores cuidados para la la piel, cómo aplicar el iluminador y dar carácter a tu mirada hasta cómo conseguir unos labios perfectos y maquillajes exprés de día y de noche.',
        backgroundImage: './img/unycos/courses/maquillaje/maquillaje-fundamentos.jpg',
        link: '/unycos/single'
      },
      {
        id: '2',
        title: 'Maquillaje Profesional',
        desc: 'Saca el mayor provecho a tus productos y herramientas, identifica los diferentes tipos de rostro, realiza correcciones de nariz, cejas y labios de diferentes tipos y lleva a cabo los mejores maquillajes de novia e invitada.',
        backgroundImage: './img/unycos/courses/maquillaje/maquillaje-profesional.jpg',
        link: '/unycos/single'
      },
      {
        id: '3',
        title: 'Maquillaje Creativo',
        desc: 'Identifica los diferentes tipos de piel, aprende la teoría del color, las técnicas más avanzadas de maquillaje de ojos, la aplicación perfecta del eyeliner y cómo realizar un maquillaje creativo de impacto.',
        backgroundImage: './img/unycos/courses/maquillaje/maquillaje-creativo.jpg',
        link: '/unycos/single'
      }
    ];

    const SlidesNav = [
      {
        id: '1',
        title: 'Fundamento de Maquillaje',
        backgroundImage: './img/unycos/courses/maquillaje/thumb-maquillaje-fundamentos.jpg'
      },
      {
        id: '2',
        title: 'Maquillaje Profesional',
        backgroundImage: './img/unycos/courses/maquillaje/thumb-maquillaje-profesional.jpg'
      },
      {
        id: '3',
        title: 'Maquillaje Creativo',
        backgroundImage: './img/unycos/courses/maquillaje/thumb-maquillaje-creativo.jpg'
      }
    ];

    const bigSlides = MainSlides.map((value) => (
      <Box key={value.id} className="slide">
        <Box className="thumb">
          <Image src={value.backgroundImage} alt="title" />
        </Box>
        <Card className="course-info">
          <Heading type="h3" className="title letter-spacing__normal normal-font text-uppercase font-size-x-medium without-margin-top margin-bottom-small">{value.title}</Heading>
          <Paragraph className="desc">{value.desc}</Paragraph>
          <Button onClick={() => window.open(value.link)}>SOBRE ESTE CURSO</Button>
        </Card>
      </Box>
    ));

    const thumbSlides = SlidesNav.map((value) => (
      <Box key={value.id} className="slide">
        <Box className="thumb">
          <Image src={value.backgroundImage} alt="title" />
        </Box>
        <Box className="caption center-xs">
          <Box tag="span" className="text-uppercase title">{value.title}</Box>
        </Box>
      </Box>
    ));

    const responsive = {
      responsive: [
        {
          breakpoint: 1020,
          settings: {
            centerMode: true,
            slidesToShow: 3,
            variableWidth: true,
            infinite: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            slidesToShow: 1,
            variableWidth: false,
            infinite: true,
            focusOnSelect: true,
            centerPadding: '40px'
          }
        }
      ]
    };

    return (
      <Box id="unycos-single-slides">
        <Box className="unycos-single-slides rounded-small">
          <Slider
            className="main-slides"
            asNavFor={this.state.nav2}
            ref={(slider) => (this.slider1 = slider)}
            arrows={false}
            fade
          >
            {bigSlides}
          </Slider>
          <Slider
            className="main-slides-nav"
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            slidesToShow={3}
            focusOnSelect
            arrows={false}
            {...responsive}
          >
            {thumbSlides}
          </Slider>
        </Box>
      </Box>
    );
  }
}

export default UnycosSingleSlides;
