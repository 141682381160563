import React from 'react';
import {
  DefaultFooter,
  DefaultFooterTypes,
  DefaultFooterCertificationTypes,
} from 'base-ui';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import MenusMock from './AppFooterMenusMock';


const AppFooter = (props) => {
  const { location } = props;

  const verifyUrl = (regexUrlArray) => {
    let reply = false;
    regexUrlArray.forEach((regex) => {
      if (location.pathname.match(regex)) {
        reply = true;
      }
    });
    return reply;
  };
  const regexUrlArray = [
    /consultation\//,
    /courses\//
  ];
  return (
    <React.Fragment>

      {!verifyUrl(regexUrlArray)
        ? (
          <DefaultFooter
            menus={MenusMock}
            certification={[
              DefaultFooterCertificationTypes.HONCODE,
            ]}
            platformConfig={{
              name: 'Mejor con Salud',
              description: 'Revista sobre buenos hábitos y cuidados para tu salud',
              disclaimer: 'Los contenidos de esta publicación se redactan solo con fines informativos. En ningún momento pueden servir para facilitar diagnósticos o sustituir la labor de un profesional. Le recomendamos que contacte con su especialista de confianza.',
              textLastUpdated: 'Última actualización: 14 julio, 2019',
            }}
            type={DefaultFooterTypes.FOOTER_DEFAULT}
          />
        ) : null }
    </React.Fragment>
  );
};

AppFooter.propTypes = {
  location: PropTypes.object
};

export default withRouter(AppFooter);
