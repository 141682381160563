import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Container, PageWrapper, Heading
} from 'base-ui';

import BlogMenu from '../../components/Blog/BlogMenu';
import BlogSingleContent from '../../components/Blog/BlogSingleContent';
import BlogAdInfo from '../../components/Blog/BlogAdInfo/BlogAdInfo';

// SocialBar
import SocialBar from '../../components/SocialBar/SocialBar';

const BlogSingleSpecialPubli = (props) => {

  const {
    featuredImage, logo, bgColor, fontColor, borderColor, clientName, adType
  } = props;

  const [bookmarked] = useState(false);

  useEffect(() => {
    function getPositions() {
      // setShowAdvertising(true);
      window.removeEventListener('load', getPositions);
    }
    window.addEventListener('load', getPositions);
  });

  const divStyle = {
    backgroundImage: `linear-gradient(to bottom, rgba(55, 72, 88, 0) 0%, #374858 130%), url(${featuredImage})`
  };

  return (
    <PageWrapper
      config={{
        title: 'Special Publi',
        bodyClasses: 'publi__special',
      }}
    >
      <BlogMenu />
      <div id="wrapper">
        <BlogAdInfo
          logo={logo}
          bgColor={bgColor}
          borderColor={borderColor}
          clientName={clientName}
          fontColor={fontColor}
          text={adType}
        />
        <div style={divStyle} className="hero padding-bottom-big">
          <Heading type="h1" textColor="white" size="x-big" className="single-title align-center padding-normal">Si quieres llevar una vida más sana sigue estos 4 increíbles consejos</Heading>
          <div id="breadcrumbs" className="font-small">
            <span>
              <span>
                <a href="index.php">Home</a>
                {' '}
                »
                <span>
                  <a href="archive.php"> Health</a>
                  {' '}
                  »
                  <span className="breadcrumb_last"> Para los niños el verano significa diversión</span>
                </span>
              </span>
            </span>
          </div>
        </div>
        {/* <div className="ad-space header">
          <div className={`ad-slot large fadeIn ${showAdvertising ? 'open' : 'close'}`}>
            <img alt="dummy" src="https://via.placeholder.com/980x250?Text=Banner+980x250" />
          </div>
        </div> */}
        <Container fluid wrap className="container-single">
          <main id="site-main">
            <div className="row">
              <div className="col-xs-12 col-md-8 col-md-offset-2">
                <BlogSingleContent bookmarked={bookmarked} isPubli />
              </div>
            </div>
          </main>
        </Container>
      </div>
      <SocialBar bookmarked={bookmarked} />
    </PageWrapper>
  );
};

BlogSingleSpecialPubli.propTypes = {
  logo: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  fontColor: PropTypes.string,
  clientName: PropTypes.string,
  adType: PropTypes.string,
  featuredImage: PropTypes.string,
};

BlogSingleSpecialPubli.defaultProps = {
  bgColor: 'grey',
  borderColor: 'regent-grey',
  fontColor: 'regent-grey',
  clientName: 'client',
  adType: 'Especial Publi',
  featuredImage: './'
};

export default BlogSingleSpecialPubli;
