import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Button, Heading, Paragraph, Box, NavLink
} from 'base-ui';

import './styles.css';

const QuizQuestion = ({ question, setAnswer }) => {
  const {
    title, options, answer, emergency
  } = question;

  const [showMore, setShowMore] = useState(false);

  const renderOptions = () => {
    const optionResult = [];

    if (options) {
      // eslint-disable-next-line
      options.map((option) => {
        const url = option.url ? option.url : '#';

        optionResult.push(
          <NavLink to={url} key={option.value}>
            <Button
              tag="button"
              key={option.value}
              color="main"
              textColor="white"
              borderColor="main"
              size="big"
              className={cs('question-option', {
                'is-active': answer === option.value,
              })}
              onClick={() => {
                setAnswer(option.value);
                setShowMore(false);
              }}
            >
              {option.label}
            </Button>
          </NavLink>
        );
      });
    }

    return optionResult;
  };

  return (
    <Box className="question">
      <Heading size="big" className="question-title">
        {title}
      </Heading>
      {emergency && (
        <Paragraph className="question-description align-center">
          <NavLink
            to="https://lamenteesmaravillosa.com/consultas/emergencia/"
            className="secondary-color"
          >
            <strong style={{ textTransform: 'uppercase' }}>
              Estos recursos
            </strong>
          </NavLink>
          {' '}
          pueden facilitarte ayuda inmediata.
        </Paragraph>
      )}
      <Box className={cs('question-options', { active: showMore })}>
        {renderOptions()}
        {options && options.length > 4 && (
          <Button
            color="grey"
            textColor="text"
            borderColor="grey"
            size="big"
            className="question-option show-more"
            onClick={() => setShowMore(true)}
          >
            Mostrar más
          </Button>
        )}
      </Box>
    </Box>
  );
};

QuizQuestion.propTypes = {
  /**
   * Object of current Question
   * ex: {title: 'to be or not to be?', options:[{ label: 'Yes', value: 'yes'}, ...]}
   */
  question: PropTypes.object.isRequired,

  /**
   * Callback function to pass data to parent
   */
  setAnswer: PropTypes.func.isRequired,
};

export default QuizQuestion;
