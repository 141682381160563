import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Box,
  Row,
  Col,
  Image,
  Paragraph,
  Heading,
  Button
} from 'base-ui';

// Components
import UnycosSingleComment from './UnycosSingleComment';

// Data
const comments = [
  {
    id: 1,
    labels: {
      showHide: 'Mostrar',
      seeMore: 'Ver más'
    },
    student: {
      picture: <Image src="./img/teachers/josejuanrivero.png" alt="teacher" className="radius__rounded" />,
      name: 'Samuel Vidal Hernández',
      date: 'Hace 2 meses',
      comment: 'Ha sido un curso muy gratificante, en el que he podido aprender muchas cosas gracias a los grandes ponentes que han colaborado y aportado sus conocimientos. Ahora toca ponerse manos a la obra y poner en practica todo lo aprendido. Estoy muy orgulloso. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    unycos: null,
  },
  {
    id: 2,
    labels: {
      showHide: 'Mostrar',
      seeMore: 'Ver más',
    },
    student: {
      picture: <Box className="avatar__letter">F</Box>,
      name: 'Francisco José Sacedo',
      date: 'Hace 2 meses',
      comment: 'Ha sido un curso muy gratificante, en el que he podido aprender muchas cosas gracias a los grandes ponentes que han colaborado y aportado sus conocimientos. Ahora toca ponerse manos a la obra y poner en practica todo lo aprendido. Estoy muy orgulloso. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Ha sido un curso muy gratificante, en el que he podido aprender muchas cosas gracias a los grandes ponentes que han colaborado y aportado sus conocimientos. Ahora toca ponerse manos a la obra y poner en practica todo lo aprendido. Estoy muy orgulloso. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    },
    unycos: {
      type: 'Team',
      name: 'Equipo de Unycos',
      date: 'Hace 1 mes',
      comment: 'Este texto es una simulación de una contestación del equipo o del profesor directamente. El texto tendrá un aspecto diferenciado, y también tendrá un destaque mayor.',
    }
  },
  {
    id: 3,
    labels: {
      showHide: 'Mostrar',
      seeMore: 'Ver más',
    },
    student: {
      picture: <Box className="avatar__letter">AM</Box>,
      name: 'ANA MILENA GÓMEZ',
      date: 'Hace 2 mes',
      comment: 'Profundizar en el entrenamiento y proceso de enseñanza.',
    },
    unycos: {
      type: 'Profesor ',
      name: 'Mireia Belmonte',
      date: 'Hace 1 mes',
      comment: 'Hola, Ana! Continúa a practicar y seguro que mejorarás más y más tu desempeño! Un abrazo!',
    }
  }
];

const UnycosStudentComments = (props) => {
  const {
    className, from, margin
  } = props;
  return (
    <Box className={`student-comments margin-bottom-x-medium ${className}`}>
      <Container fluid wrap>
        <Row className="center-xs">
          {from === 'landing-page' && (
            <Col xs="12">
              <Paragraph className=" text-class normal-font text-uppercase padding-x-big font-normal-small">
                <i className="far fa-star font-size-x-big "></i>
                {' '}
                <Box tag="span">
                Los estudiantes le dan a Unycos una  calificación promedio de 4.7 de 5 estrellas.
                </Box>
              </Paragraph>
            </Col>
          )}
          <Col xs="12">
            <Heading type="h3" className="center-xs letter-spacing__normal normal-font text-uppercase white-color margin-bottom-normal font-size-x-medium">Comentarios de los estudiantes</Heading>
          </Col>
          <Col xs="12" className="center-xs margin-bottom-x-big">
            <Box className="center-xs group-text-approved">
              <Box tag="span" className="text-percent center-xs letter-spacing__normal normal-font text-uppercase main-color font-size-medium margin-bottom-normal">
                <Image src="./img/unycos/icons/icon-thumbs-up-main.svg" className="icon" style={{ marginTop: margin }} />
                {' '}
  98.7% de valoraciones positivas
              </Box>
              <Box tag="span" className="text-total center-xs letter-spacing__normal normal-font text-uppercase text-color__white--dark font-size-medium margin-bottom-normal">
                {' '}
                <span>/</span>
                {' '}
  Total de 726 valoraciones
              </Box>
            </Box>
          </Col>
          <Col xs="12" md={from === 'landing-page' ? '7' : '9'}>

            { comments.map((comment) => (
              <UnycosSingleComment
                key={comment.id}
                from={from}
                labels={comment.labels}
                student={comment.student}
                unycos={comment.unycos}
              />
            )) }

          </Col>
        </Row>
        <Row className="center-xs">
          <Col xs="12" sm="5" md="2">
            <Button borderColor="white" color="transparent" textColor="white" className="text-uppercase normal-font" full>Ver más</Button>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

UnycosStudentComments.defaultProps = {
  className: '',
  from: '',
  margin: ''
};

UnycosStudentComments.propTypes = {
  className: PropTypes.string,
  from: PropTypes.string,
  margin: PropTypes.string
};

export default UnycosStudentComments;
