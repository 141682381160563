import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Icon,
  Button,
  Paragraph
} from 'base-ui';

const UnycosWhatsapp = ({
  className, isGolden, callText, callNumber,
}) => (
  <Box className={`box-whatsapp ${className}`}>
    <Paragraph tag="span" className="display-block center-xs normal-font text-uppercase white-color font-size-small margin-bottom-small hidden-lg">
      <span>¿Dudas?</span>
    </Paragraph>
    {isGolden ? (
      <Button href={`https://api.whatsapp.com/send?phone=${callNumber}`} target="_blank" borderColor="main" textColor="main" className="margin-top-normal margin-bottom-normal text-uppercase" size="big">
        <Icon prefix="fab" name="whatsapp" style={{ margin: '0', marginRight: '8px' }} />
        { callText }
      </Button>
    ) : (
      <Button href={`https://api.whatsapp.com/send?phone=${callNumber}`} target="_blank" borderColor="whatsapp" color="whatsapp" textColor="white" className="margin-bottom-normal text-uppercase" size="medium">
        <Box tag="span">
          { callText }
          <Icon prefix="fab" name="whatsapp" />
        </Box>
      </Button>
    )}
  </Box>
);

UnycosWhatsapp.defaultProps = {
  className: '',
  isGolden: false,
  callNumber: '573127131460',
};

UnycosWhatsapp.propTypes = {
  className: PropTypes.string,
  isGolden: PropTypes.bool,
  callText: PropTypes.string,
  callNumber: PropTypes.string,
};

export default UnycosWhatsapp;
