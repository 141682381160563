import React, { useState } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  InputField,
  NavLink,
  Image,
  Icon,
  Card,
  Paragraph,
  Button
} from 'base-ui';

// Components
import PaymentTabs from '../../../components/Sections/PaymentTabs';

// Data
const cards = [
  {
    id: 1,
    value: 'card01',
    label: 'visa, xxx.4242',
    name: 'Daniela',
    cardValid: '10/2022',
    mainCard: true
  },
  {
    id: 2,
    value: 'card02',
    label: 'master, xxx.8250',
    name: 'Daniela',
    cardValid: '12/2021',
    mainCard: false
  },
  {
    id: 3,
    value: 'card03',
    label: 'amex, xxx.3321',
    name: 'Henrique',
    cardValid: '05/2023',
    mainCard: false
  }
];

const BuyConfirmation = () => {
  const [showBox, setShowBox] = useState(false);
  const [registerNewCard, setRegisterNewCard] = useState(false);

  const toggleBox = () => {
    setShowBox(!showBox);
  };

  const onBuy = (e) => {
    if (e === 'back') {
      setRegisterNewCard(false);
    } else if (e === 'cardNew') {
      setRegisterNewCard(true);
    } else {
      window.location.href = '/consultation/reservations';
    }
  };

  const Cards = () => {
    if (registerNewCard) {
      return (
        <Box className="payment-animate">
          <PaymentTabs
            onBuy={(e) => onBuy(e)}
            color="white"
            textColor="title"
            borderColor="transparent"
            buttonColor="mediumgray"
            buttonTextColor="white"
            buttonText="Guardar y continuar"
            className="card-add without-shadow without-border"
            hasEmail
            hasSave
            hasLabel
            securityIcon
            securityText={false}
          />
          <NavLink onClick={() => onBuy('back')} className="btn transparent-border btn-back">
            <Icon prefix="fas" name="arrow-left" className="min-color small" />
            {' '}
            Regresar, y usar una tarjeta registrada
          </NavLink>
        </Box>
      );
    }
    return (
      <Box className="card-buy payment-animate">
        <PaymentTabs
          onBuy={(e) => onBuy(e)}
          color="white"
          textColor="title"
          borderColor="transparent"
          buttonColor="mediumgray"
          buttonTextColor="white"
          buttonText="Confirmar y continuar (50€)"
          className="card-add without-shadow without-border"
          securityText={false}
          hasSave={false}
          cards={cards}
        />
      </Box>
    );
  };

  return (
    <PageWrapper config={{ title: 'Consultation Buy Confirmation', bodyClasses: 'consultation' }} className="wrap-overflow">

      <Box tag="main" id="site-main">

        <Box tag="section">
          <Container wrap>
            <Row className="center-xs">
              <Col xs="12" sm="4" className="last-sm">
                <Image alt="ADS" src="https://via.placeholder.com/350x215" className="small-ads" />
                <Card className="card-values without-shadow">
                  <Heading type="h3" className="without-margin-top titles-color font-big visible-sm hidden-md hidden-lg">
                    Estás reservando una
                  </Heading>
                  <Box className="info-professional margin-bottom-medium">
                    <Image src="./img/dummy/author.jpg" className="thumb-60" alt="Thumb Avatar Professional Daniela Fluchaire" />
                    <Heading type="h4" className="line-height-x-medium">
                      <strong>consulta online</strong>
                      {' '}
                      CON
                      {' '}
                      <strong>DANIELA FLUCHAIRE</strong>
                    </Heading>
                  </Box>
                  <Row className="center-xs visible-sm hidden-md hidden-lg">
                    <Col xs="12">
                      <Paragraph className="line-height-big padding-bottom-normal">
                        <strong>Miércoles, 28 de Noviembre</strong>
                        <br />
                        12:00 - 12:30
                      </Paragraph>
                    </Col>
                  </Row>
                  <Row className="center-xs">
                    <Col xs="6">
                      <Paragraph>Subtotal</Paragraph>
                    </Col>
                    <Col xs="6">
                      <Paragraph>por consulta</Paragraph>
                    </Col>
                    <Col xs="6">
                      <Paragraph>Total</Paragraph>
                    </Col>
                    <Col xs="6">
                      <Paragraph>
                        NaN €
                      </Paragraph>
                    </Col>
                  </Row>
                  <form>
                    <InputField
                      name="input_cupon"
                      required
                      type="text"
                      placeholder="¿Tienes cupón?"
                    />
                    <Button href="#" size="normal" color="white" textColor="white" borderColor="main" className="display-block">Aplicar</Button>
                  </form>
                </Card>
              </Col>
              <Col xs="12" sm="8">
                <Card className="card-reservation without-shadow">
                  <Box className="message message-medium yellow-border yellow-color align-center">
                    <Box tag="span" className="bold-font">La reserva caducará en 12:41</Box>
                    <NavLink to="#" className=" yellow-border">Cancelar la reserva</NavLink>
                  </Box>
                  <Row className="center-xs hidden-sm">
                    <Col xs="12" sm="5">
                      <Paragraph>
                        <strong>Consulta</strong>
                      </Paragraph>
                    </Col>
                    <Col xs="12" sm="7">
                      <Paragraph className="line-height-big margin-bottom-x-big">
                        <strong>Miércoles, 28 de Noviembre</strong>
                        <br />
                        12:00 - 12:30
                      </Paragraph>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="center-xs">
                    <Col xs="12" sm="5">
                      <Paragraph>
                        <strong>Política de cancelación</strong>
                      </Paragraph>
                    </Col>
                    <Col xs="12" sm="7">
                      <Paragraph className="line-height-big margin-bottom-x-big">
                        <NavLink to="#" className="link-underline">Política flexible</NavLink>
                      </Paragraph>
                    </Col>
                  </Row>
                  <Button onClick={toggleBox} size="big" color="white" textColor="main" borderColor="main" className="display-block">Confirmar y continuar</Button>
                </Card>
                { showBox && (
                  <Box className="payment-animate margin-medium">
                    <Heading type="h3" className="margin-top-big title-cards">2. Elige tu método de pago</Heading>
                    <Cards />
                  </Box>
                )}
              </Col>
            </Row>
          </Container>
        </Box>

      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};

export default BuyConfirmation;
