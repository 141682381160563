import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box } from 'base-ui';

import AccordionItem from './AccordionItem';

class AccordionCat extends Component {
  static propTypes = {
    allowMultipleOpen: PropTypes.bool,
    children: PropTypes.instanceOf(Object).isRequired,
    version: PropTypes.string
  };

  constructor(props) {
    super(props);
    const openSections = {};
    this.props.children.forEach(child => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true;
      }
    });
    this.state = { openSections };
  }

  onClick = label => {
    const {
      props: { allowMultipleOpen },
      state: { openSections },
    } = this;

    const isOpen = !!openSections[label];

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen
        }
      });
    } else {
      this.setState({
        openSections: {
          [label]: !isOpen
        }
      });
    }
  };

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections },
    } = this;
    return (
      <Box className={`accordion-cats ${this.props.version} ${this.props.className}`}>
        {children.map(child => (
          <AccordionItem
            key=""
            isOpen={!!openSections[child.props.label]}
            title={child.props.title}
            label={child.props.label}
            onClick={onClick}
          >
            {child.props.children}
          </AccordionItem>
        ))}
      </Box>
    );
  }
}

export default AccordionCat;
