import React from 'react';

import {
  PageWrapper,
  Box,
  Container,
  Row,
  Col,
  Image,
  NavLink,
  Heading,
  Paragraph,
  Icon
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';

const backgroundImage = './img/unycos/hero/hero404.jpg';
const Unycos404 = () => (
  <PageWrapper className="page-404" onfig={{ title: 'Unycos Error 404 Page' }}>
    <UnycosLayout>
      <UnycosHeader statuslogged={false} />
      <Box id="unycos-single-hero">
        <Box className="unycos-single-hero">
          <Container fluid wrap>
            <Row className="middle-xs position-relative">
              <Box className="thumb" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <Image src={backgroundImage} alt="title" />
              </Box>
              <Col xs="12">
                <Box className="caption center-xs align-center">
                  <Heading type="h2" className="normal-font text-uppercase line-height-small ">
                    404
                  </Heading>
                  <Heading type="h3" className="text-uppercase normal-font line-height-big ">
                    Page Not Found
                  </Heading>
                  <Paragraph className="margin-x-big line-height-x-medium">
                    No hay nada que ver por aquí, pero vamos ayudarte a encontrar un camino.
                  </Paragraph>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
      </Box>
      <Box>
        <Container fluid wrap>
          <Row className="center-xs top-xs">
            <Col xs="12" sm="11" md="10" lg="9">
              <Box className="box-shortcuts">
                <NavLink to="/unycos" className="align-center">
                  <Icon className="with-outline white-border">
                    <Image className="icon" src="./img/unycos/icons/icon-home.svg" />
                  </Icon>
                  <Paragraph className="label line-height-medium align-center center-xs text-uppercase font-size-medium">Home</Paragraph>
                </NavLink>

                <NavLink to="/unycos/courses" className="align-center">
                  <Icon className="with-outline white-border">
                    <Image className="icon" src="./img/unycos/icons/icon-courses.svg" />
                  </Icon>
                  <Paragraph className="label line-height-medium align-center center-xs text-uppercase font-size-medium">
                    Nuestros
                    <br />
                    Cursos
                  </Paragraph>
                </NavLink>

                <NavLink to="/blog" className="align-center">
                  <Icon className="with-outline white-border">
                    <Image className="icon" src="./img/unycos/icons/icon-book.svg" />
                  </Icon>
                  <Paragraph className="label line-height-medium align-center center-xs text-uppercase font-size-medium">Blog</Paragraph>
                </NavLink>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
      <UnycosFooter showHideElements={{ social: true, cta: true }} />
    </UnycosLayout>
  </PageWrapper>
);

export default Unycos404;
