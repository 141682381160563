import React, { Component } from 'react';

class BlogLinksListSidebar extends Component {

	render() {
		return(
			<React.Fragment>
        <div class="widget widget-links-list card">
          <div class="box-title text-uppercase align-center grey-bg small main-border main-color">
            <span class="label">Centro del cáncer</span>
          </div>
          <div>
            <ul>
              <li><a href=".">¿Qué es el cáncer?</a></li>
              <li><a href=".">Tipos de cáncer</a></li>
              <li><a href=".">Síntomas</a></li>
              <li><a href=".">Pruebas, exámenes y diagnósticos</a></li>
              <li><a href=".">Tratamiento del cáncer</a></li>
              <li><a href=".">Prevención del cáncer</a></li>
            </ul>
          </div>
        </div>

        <div class="widget widget-links-list card">
          <div class="box-title text-uppercase align-center grey-bg small main-border main-color">
            <span class="label">Tipos de cáncer</span>
          </div>
          <div>
            <ul>
              <li><a href=".">Cáncer de mama</a></li>
              <li><a href=".">Cáncer de colon</a></li>
              <li><a href=".">Cáncer de útero</a></li>
              <li><a href=".">Cáncer de piel</a></li>
              <li><a href=".">Leucemia</a></li>
              <li><a href=".">Cáncer de páncreas</a></li>
              <li><a href=".">Cáncer de próstata</a></li>
              <li><a href=".">Cáncer de pulmón</a></li>
              <li><a href=".">Cáncer de tiroides</a></li>
              <li><a href=".">Cáncer de higado</a></li>
              <li><a href=".">Cáncer de testicular</a></li>
              <li><a href=".">Cáncer de estómago</a></li>
              <li><a href=".">Cáncer de riñon</a></li>
            </ul>
          </div>
        </div>
      </React.Fragment>
		)
	}
}

export default BlogLinksListSidebar;
