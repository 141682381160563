import React from 'react';

import {
  Container,
  Row,
  Col,
  Box,
  PageWrapper,
} from 'base-ui';

import CourseSidebarCard from '../../../components/Single/CourseSidebarCard';
import CourseGiftForm from '../../../components/Single/CourseGiftForm';

const SingleCourseGift = () => (
  <PageWrapper config={{ title: 'Single course gift' }}>
    <Box>
      <Container fluid wrap>
        <Row>
          <Col xs="12" sm="5">
            <CourseSidebarCard
              imgSrc="./img/categories/autoconocimiento.jpg"
              title="Mindfulness para la vida cotidiana"
              modules="12 modulos - 13 horas"
              subtotal="50.00 €"
              total="50.00 €"
            />
          </Col>
          <Col xs="12" sm="7">
            <CourseGiftForm />
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default SingleCourseGift;
