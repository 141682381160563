import React from 'react';

import {
  Box,
  Col,
  Row,
  Heading,
  Image,
  Paragraph
} from 'base-ui';
import ReadMore from './ReadMore';

const AboutTeacher = () => (
  <Box className="margin-top-x-medium">
    <Box className="section-title thin-border without-margin-bottom font-size-normal">
      <Box tag="span" className="label line-height-small without-margin text-uppercase titles-color">
        Acerca del instructor
      </Box>
    </Box>
    <Row className="about-teacher">
      <Col xs="12" sm="3">
        <Box>
          <Row className="middle-xs">
            <Col xs="3" sm="12">
              <Image src="./img/teachers/tomas.jpg" className="rounded-circle img-teacher" />
            </Col>
            <Col xs="9" sm="12">
              <Heading type="h4" size="normal" className="main-font">Ángel Lafuente Z.</Heading>
              <Paragraph className="line-height-medium without-margin"><Box tag="small">Experto comunicador y amante de la palabra hablada</Box></Paragraph>
            </Col>
          </Row>
        </Box>
      </Col>
      <Col xs="12" sm="9">
        <ReadMore
          maxHeightvalue="270px"
          excerpt={(
            <Box>
              <Paragraph>Es un enamorado de la palabra hablada; y lleva más de cinco décadas difundiendo el amor por “su chica” a decenas de miles de personas: políticos, docentes, empresarios, técnicos, obreros, sindicalistas, clérigos, periodistas, marginados, etc.</Paragraph>
              <Paragraph>Licenciado en Filosofía, por las Universidades de Comillas y Complutense, entre otras muchas titulaciones.</Paragraph>
              <Paragraph>Profesor asociado o colaborador en las Universidades de Navarra, Pontificia de Comillas, CEU, Complutense de Madrid, Ramón Llull de Barcelona, Las Palmas G. C., Carlos III, y otras muchas.</Paragraph>
            </Box>
          )}
          fullText={(
            <Box>
              <Paragraph>Formador en escuelas de negocios, partidos políticos, medios de comunicación y entidades privadas y públicas entre las que destacan los Ministerios de Interior, Sanidad, Defensa, Hacienda y Trabajo.</Paragraph>
            </Box>
          )}
        />
      </Col>
    </Row>
  </Box>
);

export default AboutTeacher;
