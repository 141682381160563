import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class UnycosSidebar extends PureComponent {
  static propTypes = {
    tab: PropTypes.string,
    show: PropTypes.bool,
  };

  render() {
    const { tab, show } = this.props;

    return (
      <div id="sidebar" className={`sidebar ${show ? 'open' : ''}`}>
        <div className="content black main-border widget links-widget less-padding">
          <div className="textpart">
            <ul className="sidebar-menu">
              <li className={tab === 'courses' ? 'active' : ''}><Link to=".">Mis Cursos</Link></li>
              <li className="submenu">
                <ul>
                  <li className={tab === 'courses-processing' ? 'active' : ''}><Link to="/unycos/my-account#courses-processing">En proceso</Link></li>
                  <li className={tab === 'courses-finished' ? 'active' : ''}><Link to="/unycos/my-account#courses-finished">Finalizado</Link></li>
                  <li className={tab === 'certificates' ? 'active' : ''}><Link to="/unycos/my-account#certificates">Mis Certificados</Link></li>
                </ul>
              </li>
              <li className={tab.indexOf('profile-') > -1 ? 'active' : ''}><Link to=".">Mi Perfil</Link></li>
              <li className="submenu">
                <ul>
                  <li className={tab === 'profile-edit' ? 'active' : ''}><Link to="/unycos/my-account#profile-edit">Editar perfil</Link></li>
                  <li className={tab === 'profile-verification' ? 'active' : ''}><Link to="/unycos/my-account#profile-verification">Verificación</Link></li>
                  <li className={tab === 'profile-comments' ? 'active' : ''}><Link to="/unycos/my-account#profile-comments">Evaluaciones</Link></li>
                </ul>
              </li>
              <li className={tab.indexOf('account-') > -1 ? 'active' : ''}><Link to=".">Mi Cuenta</Link></li>
              <li className="submenu">
                <ul>
                  <li className={tab === 'account-notification' ? 'active' : ''}><Link to="/unycos/my-account#account-notification">Notificaciones</Link></li>
                  <li className={tab === 'account-config-notification' ? 'active' : ''}><Link to="/unycos/my-account#account-config-notification">Configuración de Notificaciones</Link></li>
                  <li className={tab === 'account-methods' ? 'active' : ''}><Link to="/unycos/my-account#account-methods">Metodos de pago</Link></li>
                  <li className={tab === 'account-preferences' ? 'active' : ''}><Link to="/unycos/my-account#account-preferences">Preferencias de pago</Link></li>
                  <li className={tab === 'account-history' ? 'active' : ''}><Link to="/unycos/my-account#account-history">Historial</Link></li>
                  <li className={tab === 'account-security' ? 'active' : ''}><Link to="/unycos/my-account#account-security">Seguridad</Link></li>
                  <li className={tab === 'account-download-data' ? 'active' : ''}><Link to="/unycos/my-account#account-download-data">Bajar Datos</Link></li>
                  <li className={tab === 'account-subscription' ? 'active' : ''}><Link to="/unycos/my-account#account-subscription">Suscripción</Link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default UnycosSidebar;
