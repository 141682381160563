import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Heading,
  Row,
  Col,
  Box,
  CustomError
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogArticle from '../../components/Blog/BlogArticle';

const articles = [
  {
    id: 1,
    title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-13.png',
    link: '/single'
  },
  {
    id: 2,
    title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-3.png',
    link: '/single'
  },
  {
    id: 3,
    title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-4.png',
    link: '/single'
  },
  {
    id: 4,
    title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-5.png',
    link: '/single'
  },
  {
    id: 5,
    title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-6.png',
    link: '/single'
  },
  {
    id: 6,
    title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-7.png',
    link: '/single'
  }
];

const Blog404 = () => (
  <React.Fragment>
    <BlogMenu />
    <PageWrapper config={{ title: '404 - Página no encontrada' }} className="align-center">
      <CustomError
        title="¡Ops! Error 404"
        description="No encontramos lo que buscabas. Inténtalo otra vez o regresa al inicio."
        button={{
          label: 'Ir a Home',
          to: '/',
          target: '_blank',
          as: 'as',
          routerStrategy: 'next',
          bgColor: 'secondary',
          borderColor: 'secondary',
          textColor: 'white'
        }}
        imageColor="secondary"
      >
        <Container fluid wrap>
          <Row className="center-xs">
            <Col xs="12">
              <hr />
              <Heading type="h2">Quiza te puedan interesar</Heading>
              <Box tag="section" id="latest-posts-widget" className="widget">
                {articles.map((article) => (
                  <BlogArticle
                    key={article.id}
                    title={article.title}
                    subcategory={article.subcategory}
                    thumb={article.thumb}
                    link={article.link}
                    className="content-post news"
                  />
                ))}
              </Box>
            </Col>
          </Row>
        </Container>
      </CustomError>
    </PageWrapper>
  </React.Fragment>
);

export default Blog404;
