import React from 'react';

import {
  PageWrapper,
  Row,
  Col,
  Box,
  Hero,
  Image,
  Icon,
  Heading,
  Paragraph,
  NavLink
} from 'base-ui';

const About = () => (
  <PageWrapper config={{ title: 'Consultation About', bodyClasses: 'consultation' }}>

    <Hero className="hero-banner about-hero" BgImage="/img/consultation/about-hero.jpg">
      <Row className="middle-xs center-xs start-md">
        <Col xs="12" sm="6" md="5" className="center-xs start-md">
          <Paragraph className="font-big secondary-font white-color center-xs start-md">
            Todos merecemos
            <strong>sentirnos bien</strong>
            {' '}
            y este es el momento para conseguirlo.
          </Paragraph>
          <Paragraph className="font-big secondary-font white-color center-xs start-md">
            <strong>Queremos ayudarte</strong>
            {' '}
            estés donde estés y sea cual sea tu problema.
          </Paragraph>
          <NavLink to="#" className="btn white-bg white-border main-color">Consúltanos</NavLink>
        </Col>
      </Row>
    </Hero>

    <Box tag="main" id="site-main">

      <Box tag="section" className="container-fluid wrap">
        <Row className="middle-xs">
          <Col xs="12" md="6">
            <Heading type="h2">Profesionales con experiencia</Heading>
            <Paragraph>Todos los profesionales tienen formación y experiencia en cada una de las temáticas que ofrecen y son elegidos bajo un proceso riguroso de selección.</Paragraph>
            <NavLink to="#" className="btn main-bg main-border white-color">Conócelos</NavLink>
          </Col>
          <Col xs="12" md="6">
            <Image alt="About" src="/img/consultation/about-professionals.png" />
          </Col>
        </Row>
      </Box>

      <Box tag="section" id="quotes">
        <Box className="quote one" style={{ backgroundImage: 'url(/img/consultation/about-test-1.jpg)' }}>
          <Box className="caption align-center">
            <Icon prefix="fas" name="quote-left" className="x-big white-color" />
            <Paragraph className="font-medium white-color">La terapia me ha ayudado a ver las cosas de otra manera. He mejorado mucho en muy poco tiempo. La recomiendo 100%.</Paragraph>
          </Box>
        </Box>
        <Box className="quote two" style={{ backgroundImage: 'url(/img/consultation/about-test-2.jpg)' }}>
          <Box className="caption align-center">
            <Icon prefix="fas" name="quote-left" className="x-big white-color" />
            <Paragraph className="font-medium white-color">Estoy realmente contento de haber encontrado esta plataforma. Se ha convertido en una ayuda fundamental para conocerme a mí mismo, gestionar mis emociones y sobre todo, crecer personalmente”. </Paragraph>
          </Box>
        </Box>
      </Box>

      <Box tag="section" id="store-app" className="container-fluid padding-medium">
        <Row>
          <Col xs="12">
            <Box className="medium align-center">
              <Heading type="h2" className="padding-bottom-small">Confidencialidad  y seguridad</Heading>
              <Paragraph>Las sesiones no son grabadas ni los datos son compartidos con ningún tercero.</Paragraph>
              <Paragraph className="padding-bottom-medium">Nuestra plataforma está alojada en servidores de alta fiabilidad. Y además, puede hacer uso de cualquier dispositivo con acceso a internet.</Paragraph>
              <NavLink to="#" className="margin-small"><Image alt="" src="./img/appstore.png" style={{ maxHeight: '40px' }} /></NavLink>
              <NavLink to="#" className="margin-small"><Image alt="" src="./img/googleplay.png" style={{ maxHeight: '40px' }} /></NavLink>
            </Box>
          </Col>
        </Row>
      </Box>

      <Box tag="section" id="how-works" className="container-fluid wrap padding-big">
        <Row className="between-md">
          <Col xs="12" className="align-center">
            <Heading type="h2">¿Cómo funciona?</Heading>
          </Col>
          <Col xs="12" md="6">
            <Heading type="h4" className="align-center main-color margin-bottom-big">Empezando</Heading>
            <Row style={{ lineHeight: '1.5em' }}>
              <Col xs="12" sm="6" className="position-relative">
                <Box tag="span" className="number">1</Box>
                <Heading type="h6">Crea tu cuenta</Heading>
                <Paragraph style={{ lineHeight: '1.5em' }}>Regístrate de forma sencilla y gratuita para acceder a tu área privada.</Paragraph>
              </Col>
              <Col xs="12" sm="6" className="position-relative">
                <Box tag="span" className="number">2</Box>
                <Heading type="h6">Elige un profesional o un servicio</Heading>
                <Paragraph style={{ lineHeight: '1.5em' }}>Busca por profesionales especializados o elige el servicio según tus necesidades.</Paragraph>
              </Col>
              <Col xs="12" sm="6" className="position-relative">
                <Box tag="span" className="number">3</Box>
                <Heading type="h6">Reserva una cita</Heading>
                <Paragraph style={{ lineHeight: '1.5em' }}>Selecciona cuándo quieres tener la cita.</Paragraph>
              </Col>
              <Col xs="12" sm="6" className="position-relative">
                <Box tag="span" className="number">4</Box>
                <Heading type="h6">Confirma tu asistencia</Heading>
                <Paragraph style={{ lineHeight: '1.5em' }}>Envía un mensaje para confirmar la cita o exponer tus dudas. </Paragraph>
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="6">
            <Heading type="h4" className="align-center aqua-color margin-bottom-big">Durante la terapia</Heading>
            <Row style={{ lineHeight: '1.5em' }}>
              <Col xs="12" sm="6" className="position-relative">
                <Heading type="h6">Atención al cliente</Heading>
                <Paragraph style={{ lineHeight: '1.5em' }}>Consulta nuestro chat para resolver dudas y problemas técnicos. </Paragraph>
              </Col>
              <Col xs="12" sm="6" className="position-relative">
                <Heading type="h6">Valora tu experiencia</Heading>
                <Paragraph style={{ lineHeight: '1.5em' }}>Tu opinión es muy importante para ayudar a otras personas y mejorar nuestros servicios.</Paragraph>
              </Col>
              <Col xs="12" sm="6" className="position-relative">
                <Heading type="h6">Escribe cuando quieras</Heading>
                <Paragraph style={{ lineHeight: '1.5em' }}>Envía mensajes al profesional cuando desees.</Paragraph>
              </Col>
              <Col xs="12" sm="6" className="position-relative">
                <Heading type="h6">Realiza la sesión online</Heading>
                <Paragraph style={{ lineHeight: '1.5em' }}>El día y hora elegidos recibirás un email para conectarte y realizar la videollamada.</Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default About;
