export const wrapperConfig = {
  title: 'Page title',
  meta: [
    { name: 'description', content: 'Description of the page' }
  ],
  bodyClasses: 'single-landing'
};

export const contentMenu = [
  {
    id: 1,
    label: 'Introducción',
    title: 'Acetaminofén',
    titleType: 'h1',
    revision: {
      message: 'Contenido revisado y avalado por el médico',
      person: 'Lonardo Bioltto',
      link: '#',
    },
    date: '25 de Junio, 2020',
    image: 'https://www.drrondo.com/wp-content/uploads/2014/09/paracetamol.jpg',
    imageLegend: 'Un infarto de miocardio se origina cuando hay un bloqueo en el flujo sanguíneo que lleva el oxígeno hacia el músculo cardíaco. Debido a esta interrupción, los tejidos mueren y sufren daños irreparables.',
    url: '.',
    content: ''
  },
  {
    id: 2,
    label: 'Início',
    title: '¿Qué es el acetaminofén?',
    titleType: 'h2',
    image: '',
    imageLegend: '',
    url: '.',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: 3,
    label: 'Efectos secundarios',
    title: 'Efectos secundarios',
    titleType: 'h2',
    image: 'https://mejorconsalud.com/wp-content/uploads/2019/07/te-verde.jpg',
    imageLegend: 'El dolor de pecho es el síntoma más conocido del infarto de miocardio. Sin embargo, simultáneamente se pueden presentar otras molestias como sudoración excesiva, mareos, vértigo, náuseas y sensación de ahogo',
    url: '.',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: 4,
    label: 'Precauciones',
    title: 'Precauciones',
    titleType: 'h2',
    image: 'https://mejorconsalud.com/wp-content/uploads/2018/11/operacion-corazon-miocardio.jpg',
    imageLegend: 'El dolor de pecho es el síntoma más conocido del infarto de miocardio. Sin embargo, simultáneamente se pueden presentar otras molestias como sudoración excesiva, mareos, vértigo, náuseas y sensación de ahogo',
    url: '.',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: 5,
    label: 'Interacciones',
    title: 'Interacciones',
    titleType: 'h2',
    image: 'https://mejorconsalud.com/wp-content/uploads/2018/02/acne.jpg',
    imageLegend: 'El dolor de pecho es el síntoma más conocido del infarto de miocardio. Sin embargo, simultáneamente se pueden presentar otras molestias como sudoración excesiva, mareos, vértigo, náuseas y sensación de ahogo',
    url: '.',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: 6,
    label: 'Sobredosis',
    title: 'Sobredosis',
    titleType: 'h2',
    image: '',
    imageLegend: '',
    url: '.',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  }
];

export const lastArticles = [
  {
    id: 1,
    category: 'Buenos hábitos',
    title: 'Calzado diseñado para reducir el dolor por artrosis en la rodilla',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/06/plantillas-deporte.jpg',
    url: 'https://mejorconsalud.com/calzado-reducir-dolor-artrosis-rodilla/'
  },
  {
    id: 2,
    category: 'Enfermedades',
    title: 'Las enfermedades del sistema digestivo más comunes',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/06/enfermedades-sistema-digestivo.jpg',
    url: 'https://mejorconsalud.com/enfermedades-sistema-digestivo-comunes/'
  },
  {
    id: 3,
    category: 'Niños',
    title: 'Mononucleosis en niños',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/06/mononucleosis-ni%C3%B1a.jpg',
    url: 'https://mejorconsalud.com/mononucleosis-ninos/'
  },
  {
    id: 4,
    category: 'Mente sana',
    title: 'El estrés diario puede causar depresión',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/06/estr%C3%A9s-cabeza.jpg',
    url: 'https://mejorconsalud.com/estres-diario-causar-depresion/'
  },
  {
    id: 5,
    category: 'Medicamentos',
    title: 'Antibiograma: ¿para qué se usa?',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/05/antibiograma-petri.jpg',
    url: 'https://mejorconsalud.com/antibiograma/'
  }
];

export const featuredPost = {
  id: 1,
  title: 'La personalidad autosaboteadora',
  excerpt: 'La procrastinación es una forma de autosabotaje emocional, esa en la que se esconden miedos',
  thumb: 'https://lamenteesmaravillosa.com/wp-content/uploads/2020/01/chica-frente-a-un-lago.jpg',
  url: 'https://lamenteesmaravillosa.com/la-personalidad-autosaboteadora-te-identificas/'
};

export const faqQuestions = [
  {
    id: 1,
    title: '¿10 maneras de comenzar una rutina y no morir intento?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 2,
    title: 'Un lugar lleno de aventura?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 3,
    title: '¿Qué es la rinoplastia y cuáles son sus cuidados?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 4,
    title: '¿Qué son los excipientes de los medicamentos?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 5,
    title: '¿Puede ayudar el té verde al tratamiento del acné?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 6,
    title: '¿Por qué se cae más el pelo en otoño?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  }
];

export const relatedDiseases = [
  {
    id: 1,
    label: 'Fiebre',
    title: 'Fiebre',
    url: '#'
  },
  {
    id: 2,
    label: 'Dolor de cabeza',
    title: 'Dolor de cabeza',
    url: '#'
  },
  {
    id: 3,
    label: 'Dolor muscular',
    title: 'Dolor muscular',
    url: '#'
  },
  {
    id: 4,
    label: 'Dolor abdominal',
    title: 'Dolor abdominal',
    url: '#'
  },
  {
    id: 5,
    label: 'Migraña',
    title: 'Migraña',
    url: '#'
  },
  {
    id: 6,
    label: 'Gripe',
    title: 'Gripe',
    url: '#'
  },
  {
    id: 7,
    label: 'Rinitis',
    title: 'Rinitis',
    url: '#'
  },
  {
    id: 8,
    label: 'Resfriado común',
    title: 'Resfriado común',
    url: '#'
  }
];

export const relatedMedicines = [
  {
    id: 1,
    title: 'Ibuprofeno',
    excerpt: 'Por suerte, hoy disponemos de muchos tratamientos para el acné bastante eficaces.  Esta enfermedad representa el 25% de las consultas al dermatólogo en nuestro país.',
    thumb: 'https://www.tricurioso.com/wp-content/uploads/2019/03/para-que-serve-ibuprofeno-tricurioso-1200x900.jpg',
    url: 'https://mejorconsalud.com/tratamientos-acne/'
  },
  {
    id: 2,
    title: 'Naproxen',
    excerpt: 'La utilización Eridosis para el acné, si bien no es nueva, ha encontrado una cierta fama en base a las recomendaciones que se realizan a través de las redes sociales.',
    thumb: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRskTsUZCDKDK0M_XeK0xUJOmUapFAFNZrX1w&usqp=CAU',
    url: 'https://mejorconsalud.com/eridosis-acne-precauciones/'
  },
  {
    id: 3,
    title: 'Diclofenaco',
    excerpt: 'El acné suele estar presente durante la adolescencia, sin embargo, también en la edad adulta puede hacer acto de presencia',
    thumb: 'https://conteudo.imguol.com.br/c/entretenimento/cd/2020/04/06/diclofenaco-1586185712724_v2_1281x818.jpg',
    url: 'https://mejorconsalud.com/eridosis-acne-precauciones/'
  },
  {
    id: 4,
    title: 'Panadol',
    excerpt: 'El acné suele estar presente durante la adolescencia, sin embargo, también en la edad adulta puede hacer acto de presencia',
    thumb: 'https://extra.globo.com/incoming/13666826-425-2e8/w976h550-PROP/paracetamol.jpg',
    url: 'https://mejorconsalud.com/eridosis-acne-precauciones/'
  },
];
