import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Icon,
  Card,
  Paragraph,
  NavLink
} from 'base-ui';

// Components
import CourseUser from '../../../components/Courses/CourseUser';

const dataCoursesInterest = [
  {
    id: 1,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-5.png',
    professional: 'Cristina Soria',
    finishOn: 'Finalizado el 12 de Marzo/2019',
    modules: '7 módulos - 13 horas'
  },
  {
    id: 2,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-6.png',
    professional: 'Beverly Martins',
    modules: '7 módulos - 13 horas'
  },
  {
    id: 3,
    title: 'Cambios',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-7.png',
    professional: 'Mario Peña',
    modules: '7 módulos - 13 horas'
  },
  {
    id: 4,
    title: 'Gestión del cambio',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-9.png',
    professional: 'Beverly Martins',
    modules: '7 módulos - 13 horas'
  },
  {
    id: 5,
    title: 'Cambios',
    thumb: 'https://layouts.mcontigo.com/lmem-projects/assets/images/sample-data/course-10.png',
    professional: 'Mario Peña',
    modules: '7 módulos - 13 horas'
  },
];

const CoursesWithout = () => {

  return (
    <PageWrapper config={{ title: 'Courses User', bodyClasses: 'user-courses courses' }}>

      <Box tag="main" id="site-main">

        <Box tag="section" id="taking-course">
          <Container fluid wrap>
            <Row>
              <Col xs="12" className="margin-bottom-medium">

                <Card className="center-xs medium align-center">
                  <strong className="titles-color font-size-big">¿Aún no estas inscrito en ningun curso?</strong>
                  <Paragraph>Comienza ahora mismo a adquirir nuevos conocimientos gracias a nuestra modalidad 100% en linea.</Paragraph>
                  <NavLink to="#" className="btn big main-bg main-border white-color">
                    Ver todos los cursos
                    {' '}
                    <Icon prefix="fas" name="arrow-right" />
                  </NavLink>
                </Card>

                <Box className="margin-top-x-big">
                  <Heading type="h2" className="main-font margin-bottom-medium">Cursos que te pueden interesar</Heading>
                </Box>

                <Row>
                  { dataCoursesInterest.map((course) => (
                    <Col key={course.id} xs="12" md="4">
                      <CourseUser
                        title={course.title}
                        thumb={course.thumb}
                        professional={course.professional}
                        modules={course.modules}
                      />
                    </Col>
                  )) }
                </Row>

                <Col xs="12" className="align-center center-xs margin-top-medium">
                  <NavLink to="#" className="btn big main-bg main-border white-color">
                    Ver todos los cursos
                    {' '}
                    <Icon prefix="fas" name="arrow-right" />
                  </NavLink>
                </Col>

              </Col>
            </Row>
          </Container>
        </Box>

      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};

export default CoursesWithout;
