import React, { Component } from 'react';
import {
  Col,
  Icon,
  Image,
  Paragraph,
  Row
} from 'base-ui';

class UnycosAccountConfigNotification extends Component {
  state = {}

  render() {
    return (
      <section id="account-notifications" className="account-notifications fade-exit-active">
        <span className="box-title">
          Mis Notificaciones
        </span>
        <div className="box-body">
          <Row className="middle-xs notification">
            <Col xs="12" md="2" className="center-xs">
              <Image className="account-notifications__avatar" src="/assets/images/profile.png" alt="Avatar Thumb"></Image>
            </Col>
            <Col xs="12" md="10">
              <Paragraph className="text-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Paragraph>
              <Paragraph className="text-gold">
                <Icon prefix="fa" name="clock"></Icon>
                <b>hace 2 minutos</b>
              </Paragraph>
            </Col>
          </Row>
          <Row className="middle-xs notification readed">
            <Col xs="12" md="2" className="center-xs">
              <Image className="account-notifications__avatar" src="/assets/images/profile.png" alt="Avatar Thumb"></Image>
            </Col>
            <Col xs="12" md="10">
              <Paragraph className="text-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Paragraph>
              <Paragraph className="text-gold">
                <Icon prefix="fa" name="clock"></Icon>
                <b>hace 2 minutos</b>
              </Paragraph>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default UnycosAccountConfigNotification;
