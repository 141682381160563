import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Icon,
  Paragraph,
  Tabs,
} from 'base-ui';

class UnycosAccountDownloadData extends Component {
  state = {}

  render() {
    return (
      <section id="download-data" className="fade-exit-active">
        <span className="box-title">
          Bajar Datos
        </span>
        <div className="box-body">
          <Paragraph className="text-gray">
            Puedes descargar una copia de su información en cualquier momento. Puedes recibir su información en un formato JSON, lo que puede permitirle
            {' '}
            <span className="text-gold">importar más fácilmente</span>
            {' '}
            a través de otro servicio.
          </Paragraph>
          <Paragraph className="text-gray">La descarga de su información es un proceso protegido por contraseña, al que sólo tiene acceso. Después de crear un archivo, estará disponible para descargar durante tres días.</Paragraph>
        </div>

        <Tabs className="unycos-tabs">
          <Box label="Nuveo Backup">
            <div className="box-body">
              <Paragraph className="text-gray">Crea un archivo con toda su información</Paragraph>
              <Button className="orange square hover-effect">Crear</Button>
            </div>
          </Box>
          <Box label="Mis Archivos">
            <div className="box-body">
              <div className="table-container">
                <table className="history-table certificate-table" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Creado</th>
                      <th>Caducidad</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>17 de Feb, 2017</td>
                      <td>17 de Feb, 2017</td>
                      <td className="action-cell">
                        <Link to="./assets/images/img_certificate.jpg" download target="_blank" className="table-link table-link--download" title="Download">
                          <Icon prefix="fas" name="download" size="medium" title="Download"></Icon>
                        </Link>
                      </td>
                    </tr>
                    <tr className="empty-table">
                      <td colSpan="3">Sin Transacciones</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
        </Tabs>
      </section>
    );
  }
}

export default UnycosAccountDownloadData;
