/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Box,
  Row,
  Col,
  Heading,
  Label,
  InputField,
  InputSelect,
  Button
} from 'base-ui';

const UnycosSingleMoreInfo = (props) => {
  const [inputSelect, setInputSelect] = useState('');
  const {
    from
  } = props;
  return (
    <Box className="single-more-info" id="single-more-info">
      <Container fluid wrap>
        <Row className="center-xs">
          <Col xs="12">
            <Heading type="h3" className="center-xs letter-spacing__normal normal-font text-uppercase white-color font-size-medium margin-bottom-medium">Obtén Más Información</Heading>
          </Col>
          <Col xs="12" md="9">
            <Row>
              <Col xs="12" md="6">
                <Label htmlFor="input_name">
                  <Box tag="span" className="display-none">
                    Nombre completo
                  </Box>
                  <InputField
                    id="input_name"
                    mae="input_name"
                    borderColor="white"
                    placeholder="Nombre completo"
                    full
                    className="input-error without-shadow rounded-small text-uppercase letter-spacing__small"
                  />
                </Label>
                <span className="field-error">Campo requerido</span>
              </Col>
              <Col xs="12" md="6">
                <Label htmlFor="input_email">
                  <Box tag="span" className="display-none">
                    Correo electrónico
                  </Box>
                  <InputField
                    id="input_email"
                    name="input_email"
                    borderColor="white"
                    placeholder="Correo electrónico"
                    full
                    className="without-shadow rounded-small text-uppercase letter-spacing__small"
                  />
                </Label>
              </Col>
              <Col xs="12" md="6">
                <Label htmlFor="input_country">
                  <Box tag="span" className="display-none">
                    País
                  </Box>
                  <InputSelect
                    id="input_contry"
                    name="input_country"
                    className="rounded-small"
                    placeholder="País/Código País"
                    value={inputSelect}
                    onChange={(value) => setInputSelect(value)}
                    options={[
                      { value: 'Aruba', label: 'Aruba (+297)' },
                      { value: 'Afganistán', label: 'Afganistán (+93)' },
                      { value: 'Angola', label: 'Angola (+244)' },
                      { value: 'Anguilla', label: 'Anguilla (+1264)' },
                      { value: 'Alandia', label: 'Alandia (+358)' },
                    ]}
                  />
                </Label>
              </Col>
              <Col xs="12" md="6">
                <Label htmlFor="input_phone">
                  <Box tag="span" className="display-none">
                    Teléfono
                  </Box>
                  <InputField
                    id="input_phone"
                    name="input_phone"
                    borderColor="white"
                    placeholder="Teléfono"
                    full
                    className="without-shadow rounded-small text-uppercase letter-spacing__small"
                  />
                </Label>
              </Col>
              <Col xs="12" md="6">
                <InputField id="c4" name="date" type="checkbox" className="inverse" />
                <Label inputId="c4" className="label-terms">
                  <Box tag="span" className="text-color__white--dark font-size-small">
                    Acepto las
                    &nbsp;
                    <a href="." className="white-color">condiciones de uso</a>
                    {' '}
                    y&nbsp;
                    <a href="." className="white-color">protección de datos</a>
                    .
                  </Box>
                </Label>
              </Col>
              <Col xs="12" md="6">
                <InputField id="c5" name="date" type="checkbox" className="inverse" />
                <Label inputId="c5" className="label-terms">
                  <Box tag="span" className="text-color__white--dark font-size-small">Me gustaría recibir información sobre nuevos cursos, ofertas y promociones.</Box>
                </Label>
              </Col>
            </Row>
          </Col>
          <Col xs="12" md={from === 'landing-page' ? '9' : '4'} className="margin-top-small">
            <Button className="main-bg main-border btn--normal black-color text-uppercase with-letter-spacing normal-font font-size-small" full>Enviar</Button>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

UnycosSingleMoreInfo.defaultProps = {
  from: ''
};

UnycosSingleMoreInfo.propTypes = {
  from: PropTypes.string
};

export default UnycosSingleMoreInfo;
