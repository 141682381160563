/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import LazyImage from '../../../components/Image';
// import { Container } from './styles';

function Image() {
  return (
    <div>
      <h2>Image page</h2>
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image1"
        src="https://ik.imagekit.io/demo/img/image1.jpeg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image2"
        src="https://ik.imagekit.io/demo/img/image2.jpeg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image3"
        src="https://ik.imagekit.io/demo/img/image3.jpg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image4"
        src="https://ik.imagekit.io/demo/img/image4.jpeg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image5"
        src="https://ik.imagekit.io/demo/img/image5.jpeg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image6"
        src="https://ik.imagekit.io/demo/img/image6.jpeg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image7"
        src="https://ik.imagekit.io/demo/img/image7.jpeg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image8"
        src="https://ik.imagekit.io/demo/img/image8.jpeg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <LazyImage
        alt="image9"
        src="https://ik.imagekit.io/demo/img/image9.jpeg?tr=w-400,h-300"
        place="background"
        width={400}
        height={300}
        lazy
        loadingProps={{
          type: 'blur',
          width: 400,
          height: 300
        }}
      />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
    </div>
  );
}

export default Image;
