import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Modal,
  Heading,
  Icon,
  Tabs,
  Image,
  Paragraph,
  NavLink,
  Button,
} from 'base-ui';

// Components
import MenuLogged from '../../../components/Consultation/MenuLogged';
import Review from '../../../components/Consultation/Review';

const Profile = () => {
  const [showModalClinics, setModalClinics] = useState(false);

  // Content form .box-highlight
  const BoxHighlight = (props) => {
    const { className } = props;
    return (
      <Box
        className={`box-highlight padding-small margin-top-small ${className}`}
      >
        <Paragraph className="titles-color">
          <Image src="./img/icons/videocall.svg" alt="Icon Videocall" />
          Videollamada
        </Paragraph>
        <Paragraph className="titles-color">
          <Image src="./img/icons/time.svg" alt="Icon time" />
          30 Min
        </Paragraph>
      </Box>
    );
  };
  BoxHighlight.propTypes = {
    className: PropTypes.string,
  };

  const styleModalClinics = {
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.80)',
      zIndex: '9999',
    },
    content: {
      position: 'absolute',
      right: '10px',
      left: '10px',
      bottom: '20px',
      margin: 'auto',
      boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
      overflow: 'auto',
      padding: '10px',
      borderRadius: '5px',
      display: 'block',
    },
    close: {
      position: 'absolute',
      right: '10px',
      top: '5px',
      zIndex: 1,
      cursor: 'pointer',
    },
  };

  return (
    <PageWrapper
      config={{
        title: 'Consultation Profile',
        bodyClasses: 'consultation profile',
      }}
    >
      <MenuLogged />

      <Box tag="main" id="site-main" className="with-thumbs">
        <Box tag="section">
          <Container fluid wrap>
            <Row className="row-profile">
              <Col xs="4" sm="3">
                <Image
                  src="./img/dummy/avatar-1-big.jpg"
                  alt="Img Profile Stiven Martínez"
                  className="thumb-border"
                />
              </Col>
              <Col xs="8" sm="9">
                <Heading type="h2" className="font-x-big without-margin">
                  Stiven Martínez
                  <Icon
                    prefix="far"
                    name="check-circle"
                    className="green-color icon-verify"
                  />
                </Heading>
                <Row className="middle-xs">
                  <Col xs="12">
                    <Box className="box-profile-links">
                      <Button
                        color="transparent"
                        textColor="text"
                        borderColor="transparent"
                        className="link-professional without-padding-left without-padding-bottom"
                      >
                        <Icon prefix="far" name="address-card" />
                        <strong>Psicólogo</strong>
                      </Button>
                      <NavLink
                        to="#"
                        className="transparent-border blue-color without-padding-bottom link-profile"
                      >
                        <Icon prefix="fab" name="linkedin" />
                      </NavLink>
                      <NavLink
                        to="#"
                        className="facebook-color transparent-border without-padding-bottom link-profile"
                      >
                        <Icon prefix="fab" name="facebook" />
                      </NavLink>
                      <NavLink
                        to="#"
                        className="transparent-border twitter-color without-padding-bottom link-profile"
                      >
                        <Icon prefix="fab" name="twitter" />
                      </NavLink>
                      <NavLink
                        to="#"
                        className="instagram-color transparent-border without-padding-bottom link-profile"
                      >
                        <Icon prefix="fab" name="instagram" />
                      </NavLink>
                      <NavLink
                        to="#"
                        className="youtube-color transparent-border without-padding-bottom link-profile"
                      >
                        <Icon prefix="fab" name="youtube" />
                      </NavLink>
                    </Box>
                  </Col>
                </Row>
                <Paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  ultrices gravida dui id tempor. Donec et condimentum tellus.
                  Vestibulum id enim nibh.
                </Paragraph>
                <NavLink to="schedule-new">
                  <Button
                    to="#"
                    color="main"
                    textColor="white"
                    borderColor="main"
                    className="bt-request-appointment"
                  >
                    <Icon prefix="far" name="calendar" />
                    {' '}
Request appointment
                  </Button>
                </NavLink>
                <Button borderColor="grey">
                  <Icon prefix="far" name="comment" />
                  {' '}
Mensaje
                </Button>
              </Col>
            </Row>

            <Row className="between-xs">
              <Col xs="12" md="8">
                <Tabs className="tabs-about">
                  <Box label="About">
                    <Paragraph>
                      As a therapist for over 30 years, I have experience
                      working with people of diverse backgrounds. I bring to my
                      practice a special sensitivity to individuals and families
                      experiencing difficulties with alcohol, drug use; mental
                      health issues, marital conflict; academic, peer problems,
                      parenting issues and family conflict.. My approach is to
                      work with my clients strengths; help them develop new
                      views and skills and then organize them into a plan that
                      works for them in obtaining their goals.
                    </Paragraph>
                    <Paragraph>
                      As a Director and clinical supervisor of major mental
                      health and substance abuse clinics I have developed top
                      notch clinicians over the years. My experience is thus not
                      just my own, but I have learned through the supervision of
                      others and seeing the benefit their clients have received.
                    </Paragraph>
                    <NavLink
                      to="#"
                      className="highlight main-border btn-read-more"
                    >
                      Read more
                      {' '}
                      <Icon prefix="fas" name="sort-down" />
                    </NavLink>
                    <br />
                    <br />
                    <Heading type="h5" className="text-uppercase">
                      General information
                    </Heading>
                    <Row>
                      <Col xs="12" md="6">
                        <dl>
                          <dd className="text-uppercase">
                            <strong>University degree and university:</strong>
                          </dd>
                          <dt>Specialist in clinical psychology</dt>
                        </dl>
                      </Col>
                      <Col xs="12" md="6">
                        <dl>
                          <dd className="text-uppercase">
                            <strong>Professional association:</strong>
                          </dd>
                          <dt>Specialist in clinical psychology</dt>
                        </dl>
                      </Col>
                      <Col xs="12" md="6">
                        <dl>
                          <dd className="text-uppercase">
                            <strong>Sanitary registration number:</strong>
                          </dd>
                          <dt>400900345</dt>
                        </dl>
                      </Col>
                      <Col xs="12" md="6">
                        <dl>
                          <dd className="text-uppercase">
                            <strong>Insurances:</strong>
                          </dd>
                          <dt>Liability insurance</dt>
                        </dl>
                      </Col>
                    </Row>
                    <Box id="reviews">
                      <Heading className="text-uppercase">Reviews</Heading>
                      <br />
                      <Review
                        data={{
                          name: 'Jorge P.',
                          avatar: './img/dummy/avatar-2.png',
                          stars: 5,
                          date: '25 January 2018',
                        }}
                      >
                        <Paragraph className="comment">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Morbi id purus sit amet purus pharetra iaculis
                          eget sit amet arcu.
                        </Paragraph>
                      </Review>
                      <br />
                      <Review
                        data={{
                          name: 'Mario P.',
                          avatar: './img/dummy/avatar-2.png',
                          stars: 5,
                          date: '25 January 2018',
                        }}
                      >
                        <Paragraph className="comment">
                          Etiam arcu velit, suscipit id velit a, interdum
                          accumsan urna. Ut a porta sem. Pellentesque habitant
                          morbi tristique senectus et netus et malesuada fames
                          ac turpis egestas. Mauris orci nulla, hendrerit vitae
                          erat id, gravida molestie erat. Class aptent taciti
                          sociosqu ad litora torquent per conubia nostra, per
                          inceptos himenaeos.
                        </Paragraph>
                        <Paragraph className="comment">
                          Mauris orci nulla, hendrerit vitae erat id, gravida
                          molestie erat. Class aptent taciti sociosqu ad litora
                          torquent per conubia nostra, per inceptos himenaeos.
                        </Paragraph>
                      </Review>
                    </Box>
                  </Box>

                  <Box label="Services">
                    <Box className="posts-row">
                      <article className="content-post service">
                        <Box className="thumb">
                          <Image
                            src="./img/dummy/service-2.png"
                            alt="Thumb Service Retirement planning"
                          />
                        </Box>
                        <Box className="caption">
                          <NavLink to="#">
                            <Heading type="h2" className="title">
                              Retirement planning
                            </Heading>
                          </NavLink>
                          <Box className="metas">
                            <Box tag="span" className="font-medium">
                              40 € / session
                            </Box>
                          </Box>
                          <Paragraph className="excerpt">
                            For some, it’s something inimaginable. For other,
                            something natural, and that will change the way you
                            live completely…
                            <NavLink to="#" className="main-color">
                              Read more
                            </NavLink>
                          </Paragraph>
                          <NavLink to="schedule-new">
                            <Button
                              to="#"
                              color="main"
                              textColor="white"
                              borderColor="main"
                              className="bt-request-appointment"
                            >
                              <Icon prefix="far" name="calendar" />
                              {' '}
Request
                              appointment
                            </Button>
                          </NavLink>
                        </Box>
                      </article>
                    </Box>
                  </Box>

                  <Box label="Articles">
                    <Box className="posts-row">
                      <article className="content-post article">
                        <Box className="thumb">
                          <Image
                            src="./img/dummy/service-1.png"
                            alt="Thumb Article 3 Interesting Ideas About the Parallel Universes"
                          />
                        </Box>
                        <Box className="caption">
                          <NavLink to="#">
                            <Heading type="h2" className="title">
                              3 Interesting Ideas About the Parallel Universes
                              Hypothesis
                            </Heading>
                          </NavLink>
                          <Box className="metas">
                            <Paragraph>
                              August 19, 2018 in
                              {' '}
                              <NavLink to="#" className="main-color">
                                Curiosities
                              </NavLink>
                            </Paragraph>
                          </Box>
                          <Paragraph className="excerpt">
                            Almost everyone has heard about the impressive
                            parallel universes hypothesis. Although the idea
                            belongs to the field of physics, its ideas and
                            implications are so broad…
                          </Paragraph>
                        </Box>
                      </article>
                      <article className="content-post article">
                        <Box className="thumb">
                          <Image
                            src="./img/dummy/service-3.png"
                            alt="Thumb Article 3 Interesting Ideas About the Parallel Universes"
                          />
                        </Box>
                        <Box className="caption">
                          <NavLink to="#">
                            <Heading type="h2" className="title">
                              3 Interesting Ideas About the Parallel Universes
                              Hypothesis
                            </Heading>
                          </NavLink>
                          <Box className="metas">
                            <Paragraph>
                              August 19, 2018 in
                              {' '}
                              <NavLink to="#" className="main-color">
                                Curiosities
                              </NavLink>
                            </Paragraph>
                          </Box>
                          <Paragraph className="excerpt">
                            Almost everyone has heard about the impressive
                            parallel universes hypothesis. Although the idea
                            belongs to the field of physics, its ideas and
                            implications are so broad…
                          </Paragraph>
                        </Box>
                      </article>
                    </Box>
                    <Box className="align-center">
                      <Button
                        to="#"
                        color="white"
                        textColor="main"
                        borderColor="main"
                      >
                        + Load more articles
                      </Button>
                    </Box>
                  </Box>

                  <Box label="Courses">
                    <Paragraph>Nothing</Paragraph>
                  </Box>
                </Tabs>
              </Col>
              <Col id="sidebar" xs="12" md="4" className="consultation-sidebar">
                <Box className="widget consultation-sticky-widget">
                  <Heading type="h5" className="text-uppercase">
                    Locations
                  </Heading>
                  <Box>
                    <iframe
                      title="map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.69269173511!2d-5.666579984874644!3d40.96631052995562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f26167e8474f3%3A0xe4de0bd19319fabf!2sGrupo+MContigo!5e0!3m2!1ses-419!2sco!4v1534970083114"
                      width="600"
                      height="250"
                      style={{ border: '0' }}
                    >
                    </iframe>
                  </Box>
                  <Paragraph>
                    <strong>Calle Concejo, 13</strong>
                    <br />
                    <Box tag="span">
                      <em>Salamanca - Spain</em>
                    </Box>
                  </Paragraph>
                  <Row>
                    <Col xs="12" md="6">
                      <Button
                        to="#"
                        full
                        color="main"
                        textColor="white"
                        borderColor="main"
                      >
                        <Icon prefix="fas" name="phone" />
                        {' '}
Call
                      </Button>
                    </Col>
                    <Col xs="12" md="6">
                      <Button to="#" full borderColor="grey">
                        <Icon prefix="far" name="comment" />
                        {' '}
Mensaje
                      </Button>
                    </Col>
                  </Row>
                  <Row className="between-xs">
                    <Col xs="12" md="6">
                      <strong>3 locations</strong>
                    </Col>
                    <Col xs="12" md="6" className="end-md">
                      <NavLink
                        to="#"
                        className="main-color"
                        onClick={() => setModalClinics(true)}
                      >
                        See all locations
                      </NavLink>
                    </Col>
                  </Row>
                  <hr />
                  <Heading type="h5" className="text-uppercase">
                    Rating
                  </Heading>
                  <Row className="between-xs middle-xs">
                    <Col xs="12" md="6">
                      <Box tag="span" className="font-big">
                        4.5 / 5
                      </Box>
                    </Col>
                    <Col xs="12" md="6" className="end-xs">
                      <Icon prefix="fas" name="star" className="yellow-color" />
                      <Icon prefix="fas" name="star" className="yellow-color" />
                      <Icon prefix="fas" name="star" className="yellow-color" />
                      <Icon prefix="fas" name="star" className="yellow-color" />
                      <Icon prefix="fas" name="star" className="yellow-color" />
                    </Col>
                  </Row>
                  <NavLink
                    to="#"
                    className="main-color"
                    style={{ display: 'block', textAlign: 'right' }}
                  >
                    2 reviews
                  </NavLink>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
      </Box>
      {/* end main section */}

      {/* ini #modal-clincs */}
      <Modal
        isOpen={showModalClinics}
        appElement="#root"
        idModal="modal-clinics"
        className="modal"
        closeModal={() => setModalClinics(false)}
        closeIcon
        styles={styleModalClinics}
        width="700px"
        height="580px"
      >
        <Tabs align="center">
          <Box
            label={(
              <React.Fragment>
                <strong className="main-color">MyClinic 1</strong>
                <Box tag="span">Salamanca, Spain</Box>
              </React.Fragment>
            )}
          >
            <Box className="box-title">
              <Icon prefix="fas" name="location-arrow" className="icon" />
              <Box className="label">
                <Heading
                  type="h2"
                  className="text-uppercase without-margin white-color"
                >
                  MyClinic 1
                </Heading>
                <Box tag="span" className="main-font normal-font">
                  MyAddress Streen, 89 - Salamanca, ES
                </Box>
              </Box>
            </Box>
            <Box className="subline">
              <Box tag="span" className="meta white-color">
                <Icon prefix="fas" name="phone" />
                {' '}
+34 493 89 76 69
              </Box>
              <Box tag="span" className="meta white-color">
                <Icon prefix="fas" name="envelope" />
                {' '}
MyClinic@gmail.com
              </Box>
            </Box>
            <Box className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.69269173511!2d-5.666579984874644!3d40.96631052995562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f26167e8474f3%3A0xe4de0bd19319fabf!2sGrupo+MContigo!5e0!3m2!1ses-419!2sco!4v1534822774222"
                width="600"
                height="450"
                title="map 1"
                style={{ border: '0px' }}
              >
              </iframe>
            </Box>
          </Box>
          <Box
            label={(
              <React.Fragment>
                <strong className="main-color">MyClinic 2</strong>
                <Box tag="span">Salamanca, Spain</Box>
              </React.Fragment>
            )}
          >
            <Box className="box-title">
              <Icon prefix="fas" name="location-arrow" className="icon" />
              <Box className="label">
                <Heading
                  type="h2"
                  className="text-uppercase without-margin white-color"
                >
                  MyClinic 2
                </Heading>
                <Box tag="span" className="main-font normal-font">
                  MyAddress Streen, 89 - Salamanca, ES
                </Box>
              </Box>
            </Box>
            <Box className="subline">
              <Box tag="span" className="meta white-color">
                <Icon prefix="fas" name="phone" />
                {' '}
+34 493 89 76 69
              </Box>
              <Box tag="span" className="meta white-color">
                <Icon prefix="fas" name="envelope" />
                {' '}
MyClinic@gmail.com
              </Box>
            </Box>
            <Box className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.69269173511!2d-5.666579984874644!3d40.96631052995562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f26167e8474f3%3A0xe4de0bd19319fabf!2sGrupo+MContigo!5e0!3m2!1ses-419!2sco!4v1534822774222"
                width="600"
                height="450"
                title="map 2"
                style={{ border: '0px' }}
              >
              </iframe>
            </Box>
          </Box>
          <Box
            label={(
              <React.Fragment>
                <strong className="main-color">MyClinic 3</strong>
                <Box tag="span">Salamanca, Spain</Box>
              </React.Fragment>
            )}
          >
            <Box className="box-title">
              <Icon prefix="fas" name="location-arrow" className="icon" />
              <Box tag="span" className="label">
                <Heading
                  tag="h2"
                  className="text-uppercase without-margin white-color"
                >
                  MyClinic 3
                </Heading>
                <Box tag="span" className="main-font normal-font">
                  MyAddress Streen, 89 - Salamanca, ES
                </Box>
              </Box>
            </Box>
            <Box className="subline">
              <Box tag="span" className="meta white-color">
                <Icon prefix="fas" name="phone" />
                {' '}
+34 493 89 76 69
              </Box>
              <Box tag="span" className="meta white-color">
                <Icon prefix="fas" name="envelope" />
                {' '}
MyClinic@gmail.com
              </Box>
            </Box>
            <Box className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3012.69269173511!2d-5.666579984874644!3d40.96631052995562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd3f26167e8474f3%3A0xe4de0bd19319fabf!2sGrupo+MContigo!5e0!3m2!1ses-419!2sco!4v1534822774222"
                width="600"
                height="450"
                title="map 3"
                style={{ border: '0px' }}
              >
              </iframe>
            </Box>
          </Box>
        </Tabs>
      </Modal>
      {/* end #modal-clinics */}
    </PageWrapper>
  );
};

export default Profile;
