export const wrapperConfig = {
  title: 'Page title',
  meta: [
    { name: 'description', content: 'Description of the page' }
  ],
  bodyClasses: 'single-landing'
};

export const contentMenu = [
  {
    id: '1',
    label: 'Introducción',
    title: 'Acné',
    titleType: 'h1',
    revision: {
      message: 'Contenido revisado y avalado por el médico',
      person: 'Lonardo Bioltto',
      link: '#',
    },
    date: '24 de Junio, 2020',
    image: 'https://mejorconsalud.com/wp-content/uploads/2017/08/Ataque-card%C3%ADaco-o-infarto-del-miocardio.jpg',
    imageLegend: 'Un infarto de miocardio se origina cuando hay un bloqueo en el flujo sanguíneo que lleva el oxígeno hacia el músculo cardíaco. Debido a esta interrupción, los tejidos mueren y sufren daños irreparables.',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: '2',
    label: 'Cuasas',
    title: 'Causas del acné',
    titleType: 'h2',
    image: 'https://mejorconsalud.com/wp-content/uploads/2018/05/dolor-pecho-infarto.jpg',
    imageLegend: 'El dolor de pecho es el síntoma más conocido del infarto de miocardio. Sin embargo, simultáneamente se pueden presentar otras molestias como sudoración excesiva, mareos, vértigo, náuseas y sensación de ahogo',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: '3',
    label: 'Factores de riesgo',
    title: 'Factores de riesgo',
    titleType: 'h2',
    image: 'https://mejorconsalud.com/wp-content/uploads/2019/07/te-verde.jpg',
    imageLegend: 'El dolor de pecho es el síntoma más conocido del infarto de miocardio. Sin embargo, simultáneamente se pueden presentar otras molestias como sudoración excesiva, mareos, vértigo, náuseas y sensación de ahogo',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: '4',
    label: 'Sintomas',
    title: 'Sintomas',
    titleType: 'h2',
    image: 'https://mejorconsalud.com/wp-content/uploads/2018/11/operacion-corazon-miocardio.jpg',
    imageLegend: 'El dolor de pecho es el síntoma más conocido del infarto de miocardio. Sin embargo, simultáneamente se pueden presentar otras molestias como sudoración excesiva, mareos, vértigo, náuseas y sensación de ahogo',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: '5',
    label: 'Diagnóstico',
    title: 'Diagnóstico',
    titleType: 'h2',
    image: 'https://mejorconsalud.com/wp-content/uploads/2018/02/acne.jpg',
    imageLegend: 'El dolor de pecho es el síntoma más conocido del infarto de miocardio. Sin embargo, simultáneamente se pueden presentar otras molestias como sudoración excesiva, mareos, vértigo, náuseas y sensación de ahogo',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: '6',
    label: 'Tratamiento',
    title: 'Tratamiento',
    titleType: 'h2',
    image: '',
    imageLegend: '',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: '7',
    label: 'Pronóstico',
    title: 'Pronóstico',
    titleType: 'h2',
    image: '',
    imageLegend: '',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: '8',
    label: 'Prevención',
    title: 'Prevención',
    titleType: 'h2',
    image: '',
    imageLegend: '',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
  {
    id: '9',
    label: 'Bibliografia',
    title: 'Bibliografia',
    titleType: 'h2',
    image: '',
    imageLegend: '',
    url: '#',
    content: '<p>Coloquialmente el término infarto se relaciona de manera directa con el infarto agudo de miocardio. No obstante, en general, un infarto es la muerte celular que se produce en el tejido de un órgano o músculo debido a una interrupción del flujo sanguíneo.</p><p>Durante un infarto de miocardio, el suministro de sangre que alimenta al corazón con oxígeno sufre un bloqueo y, en consecuencia, el músculo cardíaco comienza a morir. Así, cuanto más tiempo pase sin un tratamiento para restablecer el flujo sanguíneo, mayor es el daño que sufre el corazón.</p><p>Esta condición es una emergencia médica grave que debe ser atendida lo antes posible. Si bien muchos casos se dan de manera asintomática, casi siempre hay algunas señales de alerta que son determinantes para lograr una intervención que evite daños irreparables.</p>'
  },
];

export const symptoms = [
  {
    id: 1,
    label: 'Cicatrices',
    url: '#'
  },
  {
    id: 2,
    label: 'Comedones',
    url: '#'
  },
  {
    id: 3,
    label: 'Enrojecimiento',
    url: '#'
  },
  {
    id: 4,
    label: 'Espinillas',
    url: '#'
  },
  {
    id: 5,
    label: 'Granos',
    url: '#'
  },
  {
    id: 6,
    label: 'Inflamación',
    url: '#'
  },
  {
    id: 7,
    label: 'Pus',
    url: '#'
  },
  {
    id: 8,
    label: 'Protuberancias',
    url: '#'
  },
  {
    id: 9,
    label: 'Puntos negros',
    url: '#'
  }
];

export const lastArticles = [
  {
    id: 1,
    category: 'Buenos hábitos',
    title: 'Calzado diseñado para reducir el dolor por artrosis en la rodilla',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/06/plantillas-deporte.jpg',
    url: 'https://mejorconsalud.com/calzado-reducir-dolor-artrosis-rodilla/'
  },
  {
    id: 2,
    category: 'Enfermedades',
    title: 'Las enfermedades del sistema digestivo más comunes',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/06/enfermedades-sistema-digestivo.jpg',
    url: 'https://mejorconsalud.com/enfermedades-sistema-digestivo-comunes/'
  },
  {
    id: 3,
    category: 'Niños',
    title: 'Mononucleosis en niños',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/06/mononucleosis-ni%C3%B1a.jpg',
    url: 'https://mejorconsalud.com/mononucleosis-ninos/'
  },
  {
    id: 4,
    category: 'Mente sana',
    title: 'El estrés diario puede causar depresión',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/06/estr%C3%A9s-cabeza.jpg',
    url: 'https://mejorconsalud.com/estres-diario-causar-depresion/'
  },
  {
    id: 5,
    category: 'Medicamentos',
    title: 'Antibiograma: ¿para qué se usa?',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2020/05/antibiograma-petri.jpg',
    url: 'https://mejorconsalud.com/antibiograma/'
  }
];

export const relevantArticles = [
  {
    id: 1,
    title: 'Tratamientos para el acné',
    excerpt: 'Por suerte, hoy disponemos de muchos tratamientos para el acné bastante eficaces.  Esta enfermedad representa el 25% de las consultas al dermatólogo en nuestro país.',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2019/12/tratamientos-acne.jpg',
    url: 'https://mejorconsalud.com/tratamientos-acne/'
  },
  {
    id: 2,
    title: 'Eridosis para el acné: precauciones',
    excerpt: 'La utilización Eridosis para el acné, si bien no es nueva, ha encontrado una cierta fama en base a las recomendaciones que se realizan a través de las redes sociales.',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2019/09/eridosis-acne.jpg',
    url: 'https://mejorconsalud.com/eridosis-acne-precauciones/'
  },
  {
    id: 3,
    title: 'Preparado de aceites esenciales para combatir el acné',
    excerpt: 'El acné suele estar presente durante la adolescencia, sin embargo, también en la edad adulta puede hacer acto de presencia',
    thumb: 'https://mejorconsalud.com/wp-content/uploads/2018/09/aceites-esenciales-para-combatir-el-acne.jpg',
    url: 'https://mejorconsalud.com/preparado-de-aceites-esenciales-para-combatir-el-acne/'
  },
];

export const featuredPost = {
  id: 1,
  title: 'La personalidad auto saboteadora',
  excerpt: 'La procrastinación es una forma de autosabotaje emocional, esa en la que se esconden miedos',
  thumb: 'https://lamenteesmaravillosa.com/wp-content/uploads/2020/01/chica-frente-a-un-lago.jpg',
  url: 'https://lamenteesmaravillosa.com/la-personalidad-autosaboteadora-te-identificas/'
};

export const relatedArticles = lastArticles.slice(0, lastArticles.length);

export const faqQuestions = [
  {
    id: 1,
    title: '¿10 maneras de comenzar una rutina y no morir intento?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 2,
    title: 'Un lugar lleno de aventura?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 3,
    title: '¿Qué es la rinoplastia y cuáles son sus cuidados?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 4,
    title: '¿Qué son los excipientes de los medicamentos?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 5,
    title: '¿Puede ayudar el té verde al tratamiento del acné?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  },
  {
    id: 6,
    title: '¿Por qué se cae más el pelo en otoño?',
    desc: 'El té verde es un astringente natural que ofrece resultados interesantes en el tratamiento del acné. ¿Sabes cómo se utiliza? Aquí te lo contamos en detalle.',
    image: './img/dummy/post-1.png'
  }
];

export const moreArticles = lastArticles.slice(0, 4);
