import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  AdsHide,
} from 'base-ui';

import BlogMenu from '../../../components/Blog/BlogMenu';

const Paywall = () => (
  <React.Fragment>
    <BlogMenu />
    <PageWrapper config={{ title: 'Testing Paywall Components' }}>
      <Container fluid wrap>
        <Box tag="main" id="site-main">
          <Row className="center-xs">
            <Col xs="12">
              <AdsHide
                text='¿No quieres ver anuncios?'
                buttonText='¡Suscríbete!'
                link="/subscription"
                align= "right"
                style={{ fontSize: '12px'}}
              />
            </Col>
          </Row>
        </Box>
      </Container>
    </PageWrapper>
  </React.Fragment>
);

export default Paywall;
