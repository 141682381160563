import React from 'react';

import {
  Box,
  Card,
  Icon,
  Heading,
  Button,
  InputField
} from 'base-ui';

const SidebarWidgetInfo = () => (
  <Box className="widget sticky">
    <Card className="white-bg white-border small">
      <Box className="course-preview">
        <iframe title="iframe" width="560" height="315" src="https://www.youtube.com/embed/KmLVXffHLd4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Box>
      <Button color="white" textColor="titles" borderColor="titles" className="border-strong" full>
        <Icon prefix="fas" name="heart" />
        Guardar en la lista de deseos
      </Button>
      <Box>
        <Box className="meta font-size-small">
          <strong>Duración:</strong>
          16 módulos/7 horas
        </Box>
        <Box className="meta font-size-small">
          <strong>Valoraciones:</strong>
          100.0% positivas
        </Box>
        <Box className="meta font-size-small">
          <strong>Modalidad:</strong>
          Online y a tu ritmo
        </Box>
      </Box>
      <hr className="spacer" />
      <Box className="more-info-cta">
        <Box>
          <Heading type="h3" size="normal" className="text-uppercase main-font">Obtén más información</Heading>
        </Box>
        <InputField
          placeholder="Nombre completo"
          full
        />
        <InputField
          placeholder="Correo electrónico"
          full
        />
        <InputField
          placeholder="País / Código país"
          full
        />
        <InputField
          placeholder="Teléfono"
          full
        />
        <input id="c1" type="checkbox" />
        <label htmlFor="c1" className="font-size-small">Acepto las <a href="." className="secondary-color">condiciones de uso</a> y <a href="." className="secondary-color">protección de datos.</a></label>
        <input id="c2" type="checkbox" />
        <label htmlFor="c2" className="font-size-small">Me gustaría recibir información sobre nuevos cursos, ofertas y promociones</label>
        <Button full className="text-uppercase" color="orange" textColor="white" borderColor="orange">Enviar</Button>
      </Box>
    </Card>
  </Box>
);

export default SidebarWidgetInfo;
