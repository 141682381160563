import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Row,
  Col,
  Box,
  Card,
  Icon,
  Tabs,
  Image,
  InputField,
  InputSelect,
  Label,
  Heading,
  NavLink,
  Paragraph,
  Button,
} from 'base-ui';

import CouponForm from './CouponForm';

const PaymentTabs = ({
  className,
  color,
  textColor,
  borderColor,
  buttonColor,
  buttonText,
  buttonTextColor,
  hasLabel,
  hasEmail,
  hasSave,
  hasHrLine,
  termsAndConditions,
  onBuy,
  hasCoupon,
  onSubmitCoupon,
  securityIcon,
  securityText,
  couponActive,
  cards
}) => {
  const PlanTabPaymentCredit = <Image src="./img/creditcards.png" alt="Credit Cards" />;
  const PlanTabPaymentPaypal = <Image src="./img/paypal.png" alt="PayPal" />;
  const PlanTabPaymentPaypalWhite = <Image src="./img/paypal-white.png" alt="PayPal" />;

  const [cardNumber, setCardNumber] = useState('');
  const [cardDate, setCardDate] = useState('');
  const [cardCVV, setCardCVV] = useState('');

  const [cardsList, setCardsList] = useState('');
  const [cardData, setCardData] = useState('');

  const [termsAccept, setTermsAccept] = useState(false);
  const [errorTerms, setErrorTerms] = useState(false);

  useEffect(() => {
    if (cards) {
      const cardsArray = [];
      cards.map((card) => (
        cardsArray.push({ value: card.value, label: card.label })
      ));
      cardsArray.push({ value: 'cardNew', label: 'Una nueva tarjeta' });
      setCardsList(cardsArray);

      const mainCard = cards.find((item) => item.mainCard === true);
      setCardData(mainCard);
    }
  }, [cards]);

  const handlerSelectCard = (e) => {
    if (e !== 'cardNew') {
      const mainCard = cards.find((item) => item.value === e);
      setCardData(mainCard);
    } else {
      onBuy(e);
    }
  };

  const toggleChange = () => {
    setTermsAccept(!termsAccept);
    if (!termsAccept) {
      setErrorTerms(false);
    } else {
      setErrorTerms(true);
    }
  };


  const handlerButtonClick = () => {
    if (termsAccept) {
      onBuy();
    } else {
      setErrorTerms(true);
    }
  };

  const terms = () => (
    <>
      { hasHrLine && (<hr />) }
      <InputField id="terms" type="checkbox" onChange={toggleChange} />
      <Label
        inputId="terms"
        className={cs('position-relative', {
          'label-error': errorTerms,
        })}
      >
        <Box tag="span">
          Acepto los
          {' '}
          <NavLink
            href="."
            className={cs({
              'secondary-color': color !== 'white',
              'main-color': color === 'white',
            })}
          >
            términos y condiciones
          </NavLink>
          {' '}
          y nuestra
          {' '}
          <NavLink
            href="."
            className={cs({
              'secondary-color': color !== 'white',
              'main-color': color === 'white',
            })}
          >
            cláusula informativa.
          </NavLink>
          {errorTerms && (
            <strong className="msg-error red-color display-block position-absolute">Debes acceptar los términos y condiciones.</strong>
          )}
        </Box>
      </Label>
      {termsAndConditions && (
        <Paragraph
          size="small"
          className={cs({ 'white-color': color !== 'white' })}
        >
          {termsAndConditions}
        </Paragraph>
      )}
    </>
  );

  const btnBuyCreditCards = () => {
    const Btn = () => (
      <Button
        color={buttonColor}
        borderColor={buttonColor}
        size="big"
        textColor={buttonTextColor}
        onClick={() => handlerButtonClick()}
        full
      >
        {buttonText}
      </Button>
    );
    if (securityIcon) {
      return (
        <>
          { hasHrLine && (<hr />) }
          <Row className="bottom-xs margin-bottom-small button-security">
            <Col xs="3" sm="2">
              <Image loading="auto" src="./img/icons/icon_safe-pay.jpg" alt="Safe Pay" />
            </Col>
            <Col xs="9" sm="10">
              <Btn />
            </Col>
          </Row>
        </>
      );
    }
    return (
      <>
        { hasHrLine && (<hr />) }
        <Row>
          <Col xs="12" md="6" mdOffset="3">
            <Btn />
          </Col>
        </Row>
      </>
    );
  };

  const btnBuyPayPal = () => {
    const Btn = () => (
      <Button
        color={buttonColor}
        borderColor={buttonColor}
        textColor={buttonTextColor}
        size="big"
        className="margin-top-small margin-bottom-small"
        onClick={() => handlerButtonClick()}
        full
      >
        Pagar con Paypal
      </Button>
    );
    if (securityIcon) {
      return (
        <>
          { hasHrLine && (<hr />) }
          <Row className="margin-top-normal middle-xs margin-bottom-small">
            <Col xs="3" sm="2">
              <Image loading="auto" src="./img/icons/icon_safe-pay.jpg" alt="Safe Pay" />
            </Col>
            <Col xs="9" sm="10">
              <Btn />
            </Col>
          </Row>
        </>
      );
    }
    return (
      <>
        { hasHrLine && (<hr />) }
        <Row>
          <Col xs="12" md="6" mdOffset="3">
            <Btn />
          </Col>
        </Row>
      </>
    );
  };

  const security = () => {
    if (securityText) {
      return (
        <Box className="payment-security align-center">
          <Heading type="h3" size="medium" textColor={textColor}>
            <Icon name="lock" prefix="fas" />
            {' '}
            Pago Seguro
          </Heading>
          <Heading type="h3" size="medium" textColor={textColor}>
            <Icon name="grin-stars" prefix="far" />
            {' '}
            Garantía de satisfacción
          </Heading>
        </Box>
      );
    }
    return null;
  };

  const InputEmail = () => {
    if (hasEmail) {
      return (
        <InputField
          className="without-shadow margin-bottom-small"
          name="email"
          required
          type="email"
          placeholder="Correo electrónico"
          big
        />
      );
    }
    return null;
  };

  const maskInput = (value, type) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let matches; let match; let
      parts = '';

    switch (type) {
      case 'cardNumber':
        matches = v.match(/\d{4,16}/g);
        // eslint-disable-next-line
        match = matches && matches[0] || '';
        parts = [];

        for (let i = 0; i < match.length; i += 4) {
          parts.push(match.substring(i, i + 4));
        }

        if (parts.length) {
          setCardNumber(parts.join(' '));
        } else {
          setCardNumber(value);
        }
        break;
      case 'cardDate':
        v = v.replace(/^(\d\d)/g, '$1 / ');
        setCardDate(v.substring(0, 7));
        break;
      case 'cardCVV':
        setCardCVV(v.substring(0, 3));
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {hasCoupon && (
        <CouponForm
          onSubmitCoupon={onSubmitCoupon}
          couponActive={couponActive}
        />
      )}
      <Card
        className={cs(
          'pricing-plans-tabs margin-bottom-x-big',
          className
        )}
        size="medium"
        color={color}
        textColor={textColor}
        borderColor={borderColor}
      >
        <Tabs>
          <Box label={PlanTabPaymentCredit}>
            {cards
              ? (
                <>
                  <InputSelect
                    big
                    name="card_nr"
                    label="Seleccione una tarjeta"
                    value={cardsList.value}
                    onChange={(e) => handlerSelectCard(e)}
                    options={cardsList}
                  />
                  <Paragraph className="line-height-x-medium font-size-medium margin-top-big margin-bottom-big">
                    Nombre:
                    {' '}
                    <strong className="text-uppercase">{cardData.name}</strong>
                    <br />
                    Cuducidade:
                    {' '}
                    <strong>{cardData.cardValid}</strong>
                  </Paragraph>
                </>
              ) : (
                <>
                  <InputEmail />
                  <Row>
                    <Col xs="12" md="6">
                      <InputField
                        className="without-shadow"
                        name="name"
                        required
                        type="text"
                        placeholder="Nombre del titular"
                        big
                      />
                    </Col>
                    <Col xs="12" md="6">
                      <InputField
                        className="without-shadow"
                        name="text"
                        required
                        type="text"
                        value={cardNumber}
                        onChange={(v) => maskInput(v, 'cardNumber')}
                        placeholder="Número de tarjeta"
                        big
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="6" md="6">
                      <InputField
                        className="without-shadow"
                        name="date"
                        required
                        type="text"
                        value={cardDate}
                        onChange={(v) => maskInput(v, 'cardDate')}
                        label={hasLabel ? 'Fecha de caducidad*' : null}
                        placeholder={hasLabel ? 'Ej: 12/29' : 'MM/AA'}
                        big
                      />
                    </Col>
                    <Col xs="6" md="6">
                      <InputField
                        className="without-shadow"
                        name="cvv"
                        required
                        type="text"
                        value={cardCVV}
                        onChange={(v) => maskInput(v, 'cardCVV')}
                        label={hasLabel ? 'CVV*' : null}
                        placeholder={hasLabel ? 'Ej: 123' : 'CVV'}
                        big
                      />
                    </Col>
                  </Row>
                </>
              )
            }
            <Row className={`checkboxes margin-top-${hasHrLine ? 'small' : 'medium'} ${hasHrLine ? 'checkboxes-with-hr' : 'margin-bottom-medium'} `}>
              <Col xs="12">
                {terms()}
                {hasSave && (
                  <React.Fragment>
                    { hasHrLine && (<hr />) }
                    <InputField id="saveCard" type="checkbox" />
                    <Label inputId="saveCard">
                      Guardar tu tarjeta para futuros pagos. Los datos de tu tarjeta serán almacenados de forma segura.
                    </Label>
                  </React.Fragment>
                )}
              </Col>
            </Row>
            {btnBuyCreditCards()}
            {security()}
          </Box>
          <Box
            label={
              color !== 'white' ? PlanTabPaymentPaypalWhite : PlanTabPaymentPaypal
            }
          >
            <>
              <Card
                size="small"
                color={color}
                borderColor={textColor}
                className="without-shadow"
              >
                <Paragraph size="medium">
                  Serás redirigido a la página de pago.
                </Paragraph>
              </Card>
              <Row className={`checkboxes margin-top-${hasHrLine ? 'small' : 'medium'} ${hasHrLine ? 'checkboxes-with-hr' : 'margin-bottom-medium'} `}>
                <Col xs="12">
                  {terms()}
                </Col>
              </Row>
              {btnBuyPayPal()}
              {security()}
            </>
          </Box>
        </Tabs>
      </Card>
    </React.Fragment>
  );
};

PaymentTabs.defaultProps = {
  color: 'white',
  textColor: 'text',
  buttonColor: 'main',
  buttonTextColor: 'white',
  buttonText: 'Confirmar y continuar',
  hasEmail: true,
  hasSave: true,
  hasCoupon: false,
  hasLabel: false,
  hasHrLine: false,
  securityIcon: false,
  securityText: true
};

PaymentTabs.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  buttonText: PropTypes.string,
  hasEmail: PropTypes.bool,
  hasSave: PropTypes.bool,
  hasLabel: PropTypes.bool,
  hasHrLine: PropTypes.bool,
  termsAndConditions: PropTypes.string,
  onBuy: PropTypes.func,
  hasCoupon: PropTypes.bool,
  onSubmitCoupon: PropTypes.func,
  couponActive: PropTypes.bool,
  securityIcon: PropTypes.bool,
  securityText: PropTypes.bool,
  cards: PropTypes.array
};

export default PaymentTabs;
