import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Container,
  Box,
  Row,
  Col,
  Image,
  Lists,
  InputSelect
} from 'base-ui';

import UnycosWhatsapp from './UnycosWhatsapp';

const UnycosFooter = (props) => {
  const [inputSelect, setInputSelect] = useState('USD');
  const {
    showHideElements, from, goldenButton, contactText, contactNumber
  } = props;
  return (
    <Box id="unycos-footer" className={`unycos-footer ${from === 'my-account' ? 'margin-top-big' : 'margin-top-medium'}`}>
      <Container fluid>
        <Row className="middle-xs">
          {showHideElements.social !== false && (
            <Col xs="12" md={from === 'my-account' ? '9' : '12'} mdOffset={from === 'my-account' ? '3' : ''} className="center-xs align-center">
              <Box className={`footer-social margin-bottom-medium ${from === 'my-account' ? 'margin-top-big' : 'margin-top-medium'}`} >
                <Box tag="span" className="display-block center-xs normal-font text-uppercase white-color font-size-normal margin-bottom-normal hidden-lg">Únete a la comunidad</Box>
                <a href=".">
                  <Image src="./img/unycos/icons/icon-facebook.svg" alt="Facebook" />
                </a>
                <a href=".">
                  <Image src="./img/unycos/icons/icon-instagram.svg" alt="instagram" />
                </a>
                <a href=".">
                  <Image src="./img/unycos/icons/icon-twitter.svg" alt="Iwitter" />
                </a>
                <a href=".">
                  <Image src="./img/unycos/icons/icon-youtube.svg" alt="Youtube" />
                </a>
              </Box>
            </Col>
          )}
          {(showHideElements.cta !== false) && (
            <Col xs="12">
              <Box
                className={cs({
                  'hidden-lg footer-cta center-xs align-center': from !== 'landing-page',
                  'footer-cta center-xs align-center': from === 'landing-page'
                })}
              >
                <UnycosWhatsapp isGolden={goldenButton} callText={contactText} callNumber={contactNumber} />
              </Box>
            </Col>
          )}
        </Row>
        <Row className="center-xs bottom-xs margin-bottom-medium">
          <Col xs="12" md={(from === 'landing-page' || from === 'my-account') ? '9' : '12'} mdOffset={from === 'my-account' ? '3' : ''} className="center-xs last-xs first-md">
            <Lists className="footer-menu">
              <li><a href="unycos/terminos-de-uso">Términos y condiciones</a></li>
              <li><a href="unycos/privacidad">Políticas de privacidad</a></li>
              <li><a href="unycos/aviso-legal">Aviso legal</a></li>
            </Lists>
          </Col>
          <Col xs={from === 'landing-page' ? '6' : '6'} md={from === 'landing-page' ? '6' : '6'} className="first-xs">
            <Box className="footer-logo">
              <Image src="./img/unycos/logo.svg" alt="Unycos" />
            </Box>
          </Col>
          <Col xs={from === 'landing-page' ? '6' : '6'} md={from === 'landing-page' ? '6' : '6'} className="end-xs">
            <Box className="footer-currency end-xs float-right">
              <InputSelect
                id="input_currency"
                name="input_currency"
                className="rounded-small"
                value={inputSelect}
                onChange={(value) => setInputSelect(value)}
                options={[
                  { value: 'EUR', label: 'EUR (€)' },
                  { value: 'USD', label: 'USD ($)' },
                  { value: 'CLP', label: 'CLP ($)' },
                  { value: 'COP', label: 'COP ($)' },
                  { value: 'BOB', label: 'BOB ($)' },
                  { value: 'MXN', label: 'MXN ($)' },
                  { value: 'ARS', label: 'ARS ($)' },
                  { value: 'PEN', label: 'PEN($)' },
                  { value: 'ECS', label: 'ECS ($)' },
                  { value: 'UYU', label: 'UYU ($)' },
                ]}
              />
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

UnycosFooter.defaultProps = {
  showHideElements: '',
  from: '',
  goldenButton: false,
  contactText: 'Comenzar chat',
};

UnycosFooter.propTypes = {
  showHideElements: PropTypes.object,
  from: PropTypes.string,
  goldenButton: PropTypes.bool,
  contactText: PropTypes.string,
  contactNumber: PropTypes.string
};

export default UnycosFooter;
