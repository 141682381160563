import React from 'react';
import {
  Box,
  Modal,
  Heading,
  NavLink,
  Button,
  InputField,
  Image
} from 'base-ui';

class UnycosRecoverPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  showMsg() {
    const form = this.document.getElementById('form');
    form.style.display = 'none';
    document.getElementById('msg-return').style.display = 'flex';
  }

  render() {
    const { showModal } = this.state;
    return (
      <Box>
        <br />
        <br />
        <Button className="bt-open-modal" onClick={this.openModal}>
          Open Modal Recover Password
        </Button>
        <br />
        <Modal
          isOpen={showModal}
          appElement="#root"
          idModal="modal-recover-password"
          className="modal-unycos"
          onAfterOpen={this.afterOpenModal}
          closeModal={this.closeModal}
          closeIcon={false}
          styles={{
            overlay: {
              position: 'absolute',
            },
            content: {
              backgroundColor: '#000',
              borderColor: '#000',
              padding: '0.5rem',
              left: '',
              top: '25%',
              bottom: '',
            }
          }}
        >
          <Box className="modal-header">
            <NavLink to="#" onClick={this.closeModal} id="bt-close-modal"><Image src="./img/unycos/icons/icon_times.svg" alt="" /></NavLink>
            <Heading type="h2">Recuperar Contraseña</Heading>
          </Box>
          <Box className="modal-content">
            <Box className="modal-form" id="form">
              <InputField
                name="input_email"
                required
                type="text"
                placeholder="Correo electrónico"
              />
              <Button className="bt-cta" onClick={this.showMsg}>Confirmar</Button>
            </Box>
            <Box id="msg-return">
              <Image src="./img/unycos/icons/icon-ok.png" />
              Un correo electrónico ha sido enviado con más instrucciones.
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }
}

export default UnycosRecoverPassword;
