import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Lists,
  Paragraph,
  NavLink
} from 'base-ui';

const Terms = () => (
  <PageWrapper config={{ title: 'Consultation Terms and Conditions', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section">
        <Container fluid wrap>
          <Row className="center-xs">
            <Col xs="12">

              <Heading type="h1" class="section-title big"><span>TÉRMINOS Y CONDICIONES PLATAFORMA DE CONSULTAS “LA MENTE ESMARAVILLOSA”</span></Heading>
              <Paragraph>1. “La Mente es Maravillosa” es un portal propiedad de GRUPO M CONTIGO, SL., sociedad inscrita en el Registro Mercantil de Salamanca Tomo 442, F 146, bajo la legislación española y en fecha 02 de agosto de 2013.</Paragraph>
              <Paragraph>Los datos de contacto de GRUPO M CONTIGO, SL., son los siguientes: Calle Concejo 13, Salamanca, España, CP: 37002.</Paragraph>
              <Paragraph>2. La plataforma de consultas de “La Mente es Maravillosa” ha sido desarrollada por la sociedad mercantil GRUPO M CONTIGO, SL.</Paragraph>
              <Paragraph>
                3. En esta plataforma de consultas de “La Mente es Maravillosa” se podrán solicitar consultas
                {' '}
                <em>online</em>
                {' '}
                con profesionales independientes o bien, consultas presenciales en clínicas. Se trata de una plataforma
                {' '}
                <em>online</em>
                {' '}
                a través de equipos informáticos y móviles, que se pone a disposición para los “usuarios” que requieran asistencia psicológica y para los “profesionales” que quieran ofrecer sus servicios de forma independiente en esta plataforma.
              </Paragraph>
              <Paragraph>4. Los “usuarios” aceptan que las opiniones emitidas por los profesionales en sus consultas es responsabilidad directa de los “profesionales” y se exonera a GRUPO M CONTIGO, SL., de cualquier tipo de responsabilidad. Los servicios son prestados directamente por los profesionales de forma independiente y no por parte de “La Mente es Maravillosa” y GRUPO M CONTIGO, SL.</Paragraph>
              <Paragraph>5. GRUPO M CONTIGO, SL., es un simple intermediario entre el usuario y los profesionales de la plataforma de consultas, con lo cual, GRUPO M CONTIGO, SL., no ofrece servicios de psicología. GRUPO M CONTIGO, SL, pone a disposición de los “usuarios” la posibilidad de poder contactar con los “profesionales”.</Paragraph>
              <Paragraph>6. Cualquier reclamación, los únicos responsables son los “profesionales” de forma independiente en relación a sus actuaciones.</Paragraph>
              <Paragraph>7. La plataforma “La Mente es Maravillosa” y GRUPO M CONTIGO, SL., informan a los usuarios que en caso que CONSIDEREN QUE SU SALUD ESTÁ EN PELIGRO O PIENSA EN UN POSIBLE SUICIDIO O BIEN, QUE PUEDA REALIZAR CUALQUIER ACTO QUE PONGA EN RIESGO SU VIDA O LA DE TERCEROS, O BIEN REQUIERE ASISTENCIA MÉDICA URGENTE, no deberá utilizar la plataforma de consultas de “La Mente es Maravillosa”.</Paragraph>
              <Paragraph>8. Para poder utilizar la plataforma de consultas de “La Mente es Maravillosa”, el usuario debe ser obligatoriamente mayor de edad y tener capacidad legal. Está prohibido el uso de esta plataforma a los menores de edad.</Paragraph>
              <Paragraph>
                9. En las consultas
                {' '}
                <em>online</em>
                {' '}
                o presenciales, los “usuarios” y “profesionales” se obligan a: - No utilizar este medio como difusión de contenidos delictivos, violentos, pornográficos, de apología al terrorismo, racistas o bien, contrario a la ley; - No comercializar el contenido de la plataforma de “La Mente es Maravillosa”, así como tampoco descargar, distribuir o derivar trabajos de esta plataforma; - Realizar un uso adecuado de la plataforma y no causar ningún tipo de daño informático; - Respetar el derecho a la intimidad, honor, imagen, protección de datos, de forma recíproca y cumplir en todo momento con la confidencialidad debida de las consultas online o presenciales; - No se pueden utilizar los datos de estas consultas con fines diferentes a los de la plataforma.
              </Paragraph>
              <Paragraph>10. Los usuarios aceptan de forma consciente, voluntario y plena todos los términos y condiciones aquí descritos para la utilización de la plataforma de consultas de “La Mente es Maravillosa”. Es responsabilidad del usuario leer con detenimiento y de forma periódica estos términos y condiciones.</Paragraph>
              <Paragraph>11. Los usuarios aceptan realizar los pagos de la forma planteada por GRUPO M CONTIGO, SL., en su portal “La Mente es Maravillosa”. Asimismo, los usuarios deberán rellenar o comprobar la información que en cada paso se le solicita.</Paragraph>
              <Paragraph>Los usuarios reconocen estar al corriente, en el momento de la compra, de ciertas condiciones particulares de venta que conciernen al producto y/o servicio en cuestión y que se muestran junto a la presentación; y reconoce que la realización del pedido de compra o adquisición materializa la aceptación plena y completa de las condiciones particulares de venta.</Paragraph>
              <Paragraph>Las comunicaciones, órdenes de compra y pagos que intervengan durante las transacciones efectuadas en la plataforma podrían ser archivadas y conservadas en los registros informatizados de "La Mente es Maravillosa" con el fin de constituir un medio de prueba de las transacciones, en todo caso, respetando las condiciones razonables de seguridad y las leyes y normativas vigentes que a este respecto sean de aplicación, y particularmente atendiendo al Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD) y a los derechos que asisten a los usuarios conforme a la política de privacidad de esta plataforma.</Paragraph>
              <Paragraph>Los datos de La mente es Maravillosa se almacenarán en un servidor en España.</Paragraph>
              <Paragraph>La plataforma utilizará un certificado SSL para que toda la información personal o sensible, incluidos datos de facturación, viaje cifrada y autenticada.</Paragraph>
              <Paragraph>La mente es maravillosa en ningún momento almacenará números de tarjeta de crédito o débito ni números de cuenta bancaria. Sí podrá almacenar, no obstante, para que puedan funcionar los pagos recurrentes, códigos de intermediarios como Stripe y PayPal que permiten hacer cargos monetarios desde las cuentas de los clientes a la cuenta de Grupo M Contigo, SL.</Paragraph>
              <Paragraph>12. Respetando lo establecido en la legislación vigente, Grupo M Contigo, SL se encuentra profundamente comprometido con el cumplimiento de la normativa española de protección de datos de carácter personal y se compromete a adoptar las medidas técnicas y organizativas necesarias, así como la implementación de las medidas de seguridad dispuestas en la legislación vigente en materia de Protección de Datos de Carácter Personal, según el nivel de seguridad adecuado al riesgo de los datos recogidos.</Paragraph>
              <Paragraph>Los datos que con carácter general serán recopilados por La mente es maravillosa son los siguientes:</Paragraph>
              <Lists>
                <li>Nombres y apellidos</li>
                <li>Dirección de email</li>
                <li>Fecha de nacimiento</li>
                <li>Género</li>
                <li>Imagen de perfil</li>
                <li>Historiales de chat entre pacientes y profesionales</li>
                <li>Datos de identificación (DNI, dirección, título)</li>
              </Lists>
              <Paragraph>La finalidad del tratamiento de los datos personales que se puedan recoger son usarlos principalmente por La mente es maravillosa para la gestión de su relación y servicio con el usuario, poder ofrecerle productos y servicios de acuerdo a sus intereses y mejorar la experiencia del usuario en la plataforma.</Paragraph>
              <Paragraph>Los datos proporcionados se conservarán mientras se mantenga la relación comercial, siempre que no se solicite por el interesado su supresión, o durante los años necesarios para cumplir las obligaciones legales.</Paragraph>
              <Paragraph>
                De conformidad con lo dispuesto en la legislación vigente de protección de datos, le comunicamos que podrá ejercer sus derechos de acceso, rectificación, cancelación y oposición ante el Responsable del Fichero en la dirección Calle Concejo 13, Salamanca, España, CP: 37002 o bien, enviando un email a
                {' '}
                <u><NavLink href="mailto:protecciondedatos@mcontigo.com">protecciondedatos@mcontigo.com</NavLink></u>
                {' '}
                .
              </Paragraph>
              <Paragraph>Al acceder, navegar o usar la plataforma el usuario consiente el tratamiento de dicha información y datos y declara que toda la información o datos que facilita son veraces.</Paragraph>
              <Paragraph>13. Todos los derechos de propiedad intelectual e industrial de esta plataforma de consultas pertenece a GRUPO M CONTIGO, SL., y, por tanto, se prohíbe la reproducción total o parcial sin el consentimiento expreso por parte de GRUPO M CONTIGO, SL.</Paragraph>
              <Paragraph>14. En caso de disfrute del servicio, no existe la posibilidad de reembolso del dinero a favor de los usuarios.</Paragraph>
              <Paragraph>15. Una vez disfrutado el servicio de consulta, no se podrán realizar devoluciones de importes recibidos. GRUPO M CONTIGO, SL.</Paragraph>
              <Paragraph>16. De igual forma, se remite al usuario a consultar la Política de Privacidad para recabar más información sobre cómo ejercer su derecho de rectificación según lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD).</Paragraph>
              <Paragraph>17. En caso de conflicto, las partes se someten a los juzgados y tribunales de la ciudad de Salamanca, España.</Paragraph>

            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);


export default Terms;
