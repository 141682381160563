import React, { Component } from 'react';
import {
  Button,
  InputField,
  Paragraph
} from 'base-ui';

class UnycosAccountNotification extends Component {
  state = {}

  render() {
    return (
      <section id="account-config-notifications" className="fade-exit-active">
        <span className="box-title">
          Configuración de notificaciones push
        </span>
        <div className="box-body">
          <Paragraph className="text-white">Recibe notificaciones push en tus diferentes dispositivos</Paragraph>
          <InputField
            id="notification-messages"
            full
            big
            className="input-checkbox without-shadow rounded-small full letter-spacing__small regent-grey-bg"
            name="notification-messages"
            type="checkbox"
            label="Mensajes: Profesionales y usuarios"
          />
          <InputField
            id="notification-reservation"
            full
            big
            className="input-checkbox without-shadow rounded-small full letter-spacing__small regent-grey-bg"
            name="notification-reservation"
            type="checkbox"
            label="Reservas: Actualizaciones sobre tus reservas"
          />
          <InputField
            id="notification-activity"
            full
            big
            className="input-checkbox without-shadow rounded-small full letter-spacing__small regent-grey-bg"
            name="notification-activity"
            type="checkbox"
            label="Actividades: Actualizaciones sobre nuestros eventos"
          />
        </div>

        <span className="box-title">
          Configuración de notificaciones por correo electrónico
        </span>
        <div className="box-body">
          <Paragraph className="text-white">Recibe notificaciones directamente en tu correo electrónico</Paragraph>
          <InputField
            id="notification-general"
            full
            big
            className="input-checkbox without-shadow rounded-small full letter-spacing__small regent-grey-bg"
            name="notification-general"
            type="checkbox"
            label="General: Promociones, novedades, noticias sobre la plataforma o promociones de servicios y campañas
              asociados, encuestas a los usuarios, historias inspiradora"
          />
          <InputField
            id="notification-remember"
            full
            big
            className="input-checkbox without-shadow rounded-small full letter-spacing__small regent-grey-bg"
            name="notification-remember"
            type="checkbox"
            label="Recordatorios: sobre las reservas y evaluaciones pendientes"
          />
        </div>

        <span className="box-title">
          Configuración de llamadas
        </span>
        <div className="box-body">
          <Paragraph className="text-white">Configura la forma en que podremos contactarte el equipo</Paragraph>
          <InputField
            id="notification-call"
            full
            big
            className="input-checkbox without-shadow rounded-small full letter-spacing__small regent-grey-bg"
            name="notification-call"
            type="checkbox"
            label="General: Los usuarios tendrá configurado sus números, por lo que podemos llamarles con respecto a la
              cuenta, sus anuncios, su reservas o la comunidad de la plataforma"
          />
        </div>
        <div className="box-body align-right margin-top-big">
          <Button className="orange square hover-effect">Guardar Configuraciones</Button>
        </div>
      </section>
    );
  }
}

export default UnycosAccountNotification;
