/* eslint-disable no-plusplus */
import React, { useState } from 'react';

import { Col, Button } from 'base-ui';
import { AccordionCategory } from '../../../components/AccordionV2';

const accordionContent = [
  {
    label: 'item 1',
    content: 'content item 1',
    isOpen: false
  },
  {
    label: 'item 2',
    content: 'content item 2',
    isOpen: false
  },
  {
    label: 'item 3',
    content: 'content item 3',
    isOpen: true
  },
  {
    label: 'item 4',
    content: 'content item 4',
    isOpen: false
  },
  {
    label: 'item 5',
    content: 'content item 5',
    isOpen: false
  },
];


function AccordionScreen() {
  const [accordionContentChange, setAccordionContentChange] = useState(
    [
      {
        label: 'item 1',
        content: 'Count:',
        isOpen: false,
        count: 0
      },
      {
        label: 'item 2',
        content: 'Count:',
        isOpen: false,
        count: 0
      },
    ]);


  function handleChangeAccordion(accordionItem) {
    const item = {
      ...accordionContentChange.find(
        (child) => child.label === accordionItem.label),
      accordionItem
    };
    ++item.count;
    const index = accordionContentChange.findIndex(
      (c) => c.label === accordionItem.label);
    setAccordionContentChange(
      [...accordionContentChange.slice(0, index),
        item,
        ...accordionContentChange.slice(index + 1)]
    );
  }

  return (
    <div>
      <Col
        xs="10"
        style={{ border: '5px', marginLeft: '20px' }}
      >
        <h1>Accordion Screen Allow Multiple Open</h1>
        <AccordionCategory allowMultipleOpen>
          {accordionContent.map((child) => (
            <div
              isOpen={child.isOpen}
              key={child.label}
              label={child.label}
            >
              <h2>{child.content}</h2>
            </div>
          ))
          }
        </AccordionCategory>
      </Col>

      <br />
      <br />
      <br />

      <Col
        xs="10"
        style={{ border: '5px', marginLeft: '20px' }}
      >
        <h1>Accordion Screen Open Just One</h1>
        <AccordionCategory>
          {accordionContent.map((child) => (
            <div
              isOpen={child.isOpen}
              key={child.label}
              label={child.label}
            >
              <h2>{child.content}</h2>
            </div>
          ))
          }
        </AccordionCategory>
      </Col>

      <br />
      <br />
      <br />

      <Col
        xs="10"
        style={{ border: '5px', marginLeft: '20px' }}
      >
        <h1>Accordion Screen with changes</h1>
        <AccordionCategory>
          {accordionContentChange.map((child) => (
            <div
              key={child.label}
              label={child.label}
            >
              <h2>
                {child.content}
                {' '}
                {child.count}
              </h2>

              <Button
                onClick={() => handleChangeAccordion(child)}
                style={{ cursor: 'default' }}
              >
                Add
              </Button>
            </div>
          ))
          }
        </AccordionCategory>
      </Col>
    </div>
  );
}

export default AccordionScreen;
