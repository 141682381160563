import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Container,
  Row,
  Col,
  Image,
  Heading,
  Paragraph,
  Button,
  Icon,
  NavLink
} from 'base-ui';

import Slider from 'react-slick';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-prev" onClick={onClick}>
      <Icon prefix="fas" name="chevron-right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-next" onClick={onClick}>
      <Icon prefix="fas" name="chevron-left" />
    </div>
  );
}

class UnycosHero extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  handleMiddleSlideClick = (id) => {
    const elm = document.querySelector('.unycos-hero-small-slider');

    if ([...elm.querySelector(`[data-index="${id - 1}"]`).classList].includes('slick-current')) {
      window.location.href = '/unycos/single';
    }
  }

  render() {
    const settings = {
      asNavFor: this.state.nav1,
      ref: (slider) => (this.slider2 = slider),
      slidesToShow: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      autoplay: true,
      autoplaySpeed: 8000,
      variableWidth: true,
      centerMode: true,
      centerPadding: '0',
    };

    const BigSlidesList = [
      {
        id: '1',
        teacher: 'Mireia Belmonte',
        courseName: 'Natación',
        backgroundImage: 'https://storage.googleapis.com/staging-storage-1/content/dev.unycos.com/courses/natacion/img/destaque-curso-mireia1.jpg'
      },
      {
        id: '2',
        teacher: 'Marcus Cooper',
        courseName: 'Piragüismo',
        backgroundImage: './img/unycos/hero/hero-marcus.jpg'
      },
      {
        id: '3',
        teacher: 'Ismael Cala',
        courseName: 'Desarrollo personal',
        backgroundImage: './img/unycos/hero/hero-ismael.jpg'
      },
      {
        id: '4',
        teacher: 'Lewis Amarante',
        courseName: 'Maquillaje',
        backgroundImage: './img/unycos/hero/hero-lewis.jpg'
      },
      {
        id: '5',
        teacher: 'Amós Lora',
        courseName: 'Guitarra',
        backgroundImage: './img/unycos/hero/hero-amos.jpg'
      }
    ];

    const SmallSlidesList = [
      {
        id: '1',
        teacher: 'Mireia Belmonte',
        courseName: 'Natación',
        backgroundImage: './img/unycos/hero/profesor-mireia.jpg'
      },
      {
        id: '2',
        teacher: 'Marcus Cooper',
        courseName: 'Piragüismo',
        backgroundImage: './img/unycos/hero/profesor-marcus.jpg'
      },
      {
        id: '3',
        teacher: 'Ismael Cala',
        courseName: 'Desarrollo personal',
        backgroundImage: './img/unycos/hero/profesor-ismael.jpg'
      },
      {
        id: '4',
        teacher: 'Lewis Amarante',
        courseName: 'Maquillaje',
        backgroundImage: './img/unycos/hero/profesor-lewis.jpg'
      },
      {
        id: '5',
        teacher: 'Amós Lora',
        courseName: 'Guitarra',
        backgroundImage: './img/unycos/hero/profesor-amos.jpg'
      }
    ];

    const bigSlides = BigSlidesList.map((value) => (
      <Box key={value.id} className="unycos-hero-slide">
        <NavLink to="/unycos/single">
          <Row className="bottom-xs middle-md">
            <Col xs="12" md="5" className="center-xs start-md">
              <Box className="caption">
                <Heading type="h2" textColor="white" className="center-xs start-md teacher-name font-size-x-big normal-font text-uppercase">
                  {value.teacher}
                </Heading>
                <Heading type="h3" textColor="white" className="center-xs start-md course-name font-size-big normal-font text-uppercase">
                  <Box tag="span" className="teaches">Enseña</Box>
                  {value.courseName}
                </Heading>
                <Row className="hidden-md">
                  <Col xs="6">
                    <Paragraph className="text-utilitary">Cursos online con los mejores exponentes de su área</Paragraph>
                  </Col>
                </Row>
                <Row className="hidden-md">
                  <Col xs="8">
                    <Button className="main-bg btn--more main-border btn--medium black-color text-uppercase with-letter-spacing normal-font font-size-small" full>Saber más</Button>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
          <Box className="thumb">
            <div className="box-shadow"></div>
            <Image lazy src={value.backgroundImage} loadingProps={{ type: 'blur' }} alt="title" />
          </Box>
          <Box className="hero-course-cta center-xs align-center">
            <Box tag="span" className="text-uppercase white-color display-block center-xs margin-bottom-small learn-with">
              Aprende con los mejores
            </Box>
            <Button className="main-bg main-border btn--medium black-color text-uppercase with-letter-spacing normal-font font-size-small" full>Ver curso</Button>
          </Box>
        </NavLink>
      </Box>
    ));

    const thumbSlides = SmallSlidesList.map((value) => (
      <Box key={value.id} className="unycos-hero-slide content-course-unycos" onClick={() => this.handleMiddleSlideClick(value.id)}>
        <Box className="thumb">
          <Image lazy src={value.backgroundImage} loadingProps={{ type: 'blur' }} alt="title" />
        </Box>
        <Box className="caption">
          <Box>
            <Heading type="h2" textColor="white" className="teacher-name normal-font text-uppercase">
              {value.teacher}
            </Heading>
            <Heading type="h3" textColor="white" className="course-name font-size-normal normal-font">
              <Box tag="span" className="teaches text-uppercase">enseña</Box>
              <Box tag="span" className="text-uppercase">{value.courseName}</Box>
            </Heading>
          </Box>
        </Box>
      </Box>
    ));

    const staticHero = () => {
      const { staticInfo: { bg, pageTitle } } = this.props;

      return (
        <Box className="unycos-hero-slide content-course-unycos">
          <Row className="bottom-xs middle-md">
            <Col xs="12" md="4" className="center-xs start-md">
              <Box className="caption">
                <Heading type="h2" textColor="white" className="title center-xs start-md font-size-x-big normal-font text-uppercase">
                  {pageTitle}
                </Heading>
              </Box>
            </Col>
          </Row>
          <Box className="thumb">
            <div className="box-shadow"></div>
            <Image lazy src={bg} loadingProps={{ type: 'blur' }} alt="title" />
          </Box>
        </Box>
      );
    };

    const {
      className,
      hasSlider
    } = this.props;

    const { nav2 } = this.state;

    return (
      <Box id="unycos-hero" className={className}>
        <Box className={`unycos-hero ${!hasSlider && 'has-no-margin'}`}>
          <Container fluid wrap>
            <Row>
              {hasSlider ? (
                <>
                  <Col xs="12">
                    <Slider
                      className="unycos-hero-big-slider"
                      asNavFor={nav2}
                      ref={(slider) => (this.slider1 = slider)}
                      arrows={false}
                      fade
                    >
                      {bigSlides}
                    </Slider>
                  </Col>
                  <Col xs="12">
                    <Slider className="unycos-hero-small-slider" {...settings}>
                      {thumbSlides}
                    </Slider>
                  </Col>
                </>
              ) : (
                <Col xs="12">
                  <Box className="unycos-hero-big-slider">
                    {staticHero()}
                  </Box>
                </Col>
              )}
            </Row>
          </Container>
        </Box>
      </Box>
    );
  }
}

UnycosHero.defaultProps = {
  className: '',
  hasSlider: true,
  staticInfo: {}
};

UnycosHero.propTypes = {
  className: PropTypes.string,
  hasSlider: PropTypes.bool,
  staticInfo: PropTypes.object
};

export default UnycosHero;
