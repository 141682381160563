import React, { Component } from 'react';
import { NavLink } from 'base-ui';
import BlogAdInfo from './BlogAdInfo/BlogAdInfo';

class BlogFeaturedPostsGrid extends Component {

	render() {
		return(
			<section id="featured-posts" className="white">
        <div className="row">

          <div className="col-xs-12">
            <div className="section-title 2 classeswithout-padding-left without-border">
              <span className="label main-color text-uppercase">Hoy en Mejor Con Salud</span>
            </div>
          </div>

          <div className="col-xs-12">
            <div className="big-slider">
              <article className="content-post news">
                <NavLink to="/single">
                  <div className="thumb">
                    <span className="favorite">
                      <i className="far fa-bookmark"></i>
                    </span>
                    <div className="thumb-wrap">
                      <img alt="dummy" src="./img/dummy/featured-posts-1.png" className="thumb-bg" />
                    </div>
                  </div>
                  <div className="caption">
                    <div className="metas">
                      <span className="meta cat text-uppercase main-color">Buenos hábitos</span>
                    </div>
                    <h2 className="title">
                      Fortalece tus huesos con estos 10 tips que te brindamos
                    </h2>
                    <BlogAdInfo
                      logo="https://cdn.worldvectorlogo.com/logos/google-2015.svg"
                      bgColor="titles"
                      borderColor="yellow"
                      fontColor="regent-grey"
                      text="Especial publicidad"
                    />
                  </div>
                </NavLink>
              </article>
            </div>

            <div className="horizontal-scroll-sm">

              <article className="content-post news">
                <NavLink to="/single">
                  <div className="thumb">
                    <span className="favorite">
                      <i className="far fa-bookmark"></i>
                    </span>
                    <div className="thumb-wrap">
                      <img alt="dummy" src="./img/dummy/featured-posts-2.png" className="thumb-bg" />
                    </div>
                  </div>
                  <div className="caption">
                    <div className="metas">
                      <span className="meta cat text-uppercase main-color">Otros</span>
                    </div>
                    <h2 className="title">
                      Cenar en familia, nada mejor para fin de año
                    </h2>
                  </div>
                </NavLink>
              </article>

              <article className="content-post news">
                <NavLink to="/single">
                  <div className="thumb">
                    <span className="favorite">
                      <i className="far fa-bookmark"></i>
                    </span>
                    <div className="thumb-wrap">
                      <img alt="dummy" src="./img/dummy/featured-posts-3.png" className="thumb-bg" />
                    </div>
                  </div>
                  <div className="caption">
                    <div className="metas">
                      <span className="meta cat text-uppercase main-color">Remedios naturales</span>
                    </div>
                    <h2 className="title">
                      Como curar rapidamente las cicatrices en los niños
                    </h2>
                  </div>
                </NavLink>
              </article>

              <article className="content-post news">
                <NavLink to="/single">
                  <div className="thumb">
                    <span className="favorite">
                      <i className="far fa-bookmark"></i>
                    </span>
                    <div className="thumb-wrap">
                      <img alt="dummy" src="./img/dummy/featured-posts-4.png" className="thumb-bg" />
                    </div>
                  </div>
                  <div className="caption">
                    <div className="metas">
                      <span className="meta cat text-uppercase main-color">Belleza</span>
                    </div>
                    <h2 className="title">
                      5 cuidados para el cabello rizado que desconocias
                    </h2>
                  </div>
                </NavLink>
              </article>

              <article className="content-post news">
                <NavLink to="/single">
                  <div className="thumb">
                    <span className="favorite">
                      <i className="far fa-bookmark"></i>
                    </span>
                    <div className="thumb-wrap">
                      <img alt="dummy" src="./img/dummy/featured-posts-5.png" className="thumb-bg" />
                    </div>
                  </div>
                  <div className="caption">
                    <div className="metas">
                      <span className="meta cat text-uppercase main-color">Buenos hábitos</span>
                    </div>
                    <h2 className="title">
                      Correr 5 minutos diarios es lo mejor que puedes hacerle a tu salud
                    </h2>
                  </div>
                </NavLink>
              </article>

              <article className="content-post news">
                <NavLink to="/single">
                  <div className="thumb">
                    <span className="favorite">
                      <i className="far fa-bookmark"></i>
                    </span>
                    <div className="thumb-wrap">
                      <img alt="dummy" src="./img/dummy/featured-posts-6.png" className="thumb-bg" />
                    </div>
                  </div>
                  <div className="caption">
                    <div className="metas">
                      <span className="meta cat text-uppercase main-color">Recetas</span>
                    </div>
                    <h2 className="title">
                      No necesitas ir al GYM para tener gluteos fuertes y sanos
                    </h2>
                  </div>
                </NavLink>
              </article>

              <article className="content-post news">
                <NavLink to="/single">
                  <div className="thumb">
                    <span className="favorite">
                      <i className="far fa-bookmark"></i>
                    </span>
                    <div className="thumb-wrap">
                      <img alt="dummy" src="./img/dummy/featured-posts-7.png" className="thumb-bg" />
                    </div>
                  </div>
                  <div className="caption">
                    <div className="metas">
                      <span className="meta cat text-uppercase main-color">Belleza</span>
                    </div>
                    <h2 className="title">
                      Como alizar tu cabello rapidamente con este producto natural
                    </h2>
                  </div>
                </NavLink>
              </article>

              <article className="content-post news">
                <NavLink to="/single">
                  <div className="thumb">
                    <span className="favorite">
                      <i className="far fa-bookmark"></i>
                    </span>
                    <div className="thumb-wrap">
                      <img alt="dummy" src="./img/dummy/featured-posts-8.png" className="thumb-bg" />
                    </div>
                  </div>
                  <div className="caption">
                    <div className="metas">
                      <span className="meta cat text-uppercase main-color">Buenos hábitos</span>
                    </div>
                    <h2 className="title">
                      Te contamos el secreto de la belleza
                    </h2>
                  </div>
                </NavLink>
              </article>

            </div>

          </div>

        </div>
      </section>
		)
	}
}

export default BlogFeaturedPostsGrid;
