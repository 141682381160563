import React, { Component } from 'react';
import {
  Container,
  Row,
  Col
} from 'base-ui';

class UnycosAccountHistory extends Component {
  state = {
    sectionTitle: 'Transacciones completadas',
  }

  render() {
    const { sectionTitle } = this.state;

    return (
      <section id="account-history" className="fade-exit-active">

        <span className="box-title">{sectionTitle}</span>
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="table-container">
                <table className="history-table" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Tipo</th>
                      <th>Información</th>
                      <th>Cantidad</th>
                      <th>Pagado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="no-wrap">17 de Feb, 2017</td>
                      <td>Ejemplo</td>
                      <td>Lorem ipsum dolor sit amet</td>
                      <td>1</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td className="no-wrap">17 de Feb, 2017</td>
                      <td>Ejemplo</td>
                      <td>Lorem ipsum dolor sit amet</td>
                      <td>1</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td className="no-wrap">17 de Feb, 2017</td>
                      <td>Ejemplo</td>
                      <td>Lorem ipsum dolor sit amet</td>
                      <td>1</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td className="no-wrap">17 de Feb, 2017</td>
                      <td>Ejemplo</td>
                      <td>Lorem ipsum dolor sit amet</td>
                      <td>1</td>
                      <td>$500</td>
                    </tr>
                    <tr>
                      <td className="no-wrap">17 de Feb, 2017</td>
                      <td>Ejemplo</td>
                      <td>Lorem ipsum dolor sit amet</td>
                      <td>1</td>
                      <td>$500</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default UnycosAccountHistory;
