import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import creditCardType from 'credit-card-type';
import { withRouter } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Box,
  Heading,
  Image,
  InputField,
  Paragraph,
  Label,
  NavLink,
  Button,
  Icon,
  Modal
} from 'base-ui';

import CouponForm from '../Sections/CouponForm';

class Payment extends Component {
  state = {
    cart: [],
    payment: {
      method: 'credit-card',
      card: {
        name: '',
        number: '',
        date: '',
        cvv: '',
        type: ''
      }
    },
    total: 0,
    hasCoupon: false,
    buttonActive: false,
    completed: false,
    savedCard: {},
    // modal for checking payment / coupon
    isModalOpened: false,
    modalText: 'cupón'
  }

  componentDidMount() {
    const { isUnycos } = this.props;
    const { cart, payment } = this.state;

    if (isUnycos) {
      cart.push({
        title: 'Natación',
        by: 'Mireia Belmonte',
        price: '€ 65.00',
        thumb: './img/unycos/courses/mireia-big-slider.jpg'
      });
    } else {
      cart.push({
        title: 'Suscripción Premium ilimitada Mensual',
        price: '4.99€ / mes'
      });
    }

    this.setState({
      cart,
      payment,
      period: 'mes'
    });

    this.calcTotal();
  }

  handlePaymentMethodChange = (e) => {
    const { payment } = this.state;

    payment.method = e.target.value;

    this.setState({
      payment,
    });
  }

  checkSavedCard = () => {
    const savedCard = {};

    savedCard.name = 'Bruno S. Almeida';
    savedCard.number = '4424 0230 7820 9043';
    savedCard.date = '04 / 20';
    savedCard.cvv = '233';
    savedCard.type = creditCardType(savedCard.number)[0].type;

    this.setCreditCardInfo(savedCard, 'saved-card');
  }

  maskInput = (value, type) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let matches;
    let match;
    let parts = '';

    const { payment: { card } } = this.state;

    switch (type) {
      case 'cardNumber':
        matches = v.match(/\d{4,16}/g);
        // eslint-disable-next-line
        match = matches && matches[0] || '';
        parts = [];

        for (let i = 0; i < match.length; i += 4) {
          parts.push(match.substring(i, i + 4));
        }

        if (parts.length) {
          card.number = parts.join(' ');
        } else {
          card.number = value;
        }

        if (creditCardType(v)[0] && v.length > 2) {
          card.type = creditCardType(v)[0].type;
        } else {
          card.type = '';
        }

        break;
      case 'cardDate':
        v = v.replace(/^(\d\d)/g, '$1 / ');
        card.date = v.substring(0, 7);
        break;
      case 'cardCVV':
        card.cvv = v.substring(0, 3);
        break;
      default:
        card.name = value;
        break;
    }

    this.setCreditCardInfo(card, 'credit-card');
  };

  setCreditCardInfo = (card, method) => {
    if (method === 'credit-card') {
      const { payment } = this.state;

      payment.card = card;
      this.setState({
        payment
      });
    }

    if (method === 'saved-card') {
      this.setState({
        savedCard: card
      });
    }
  }

  applyCoupon = (coupon) => {
    let { total } = this.state;

    // fake coupon validation
    if (coupon === 'blablabla') {
      const discountPercentage = 50 / 100;

      // calculate new total
      const newTotal = total - (total * discountPercentage);
      total = newTotal.toFixed(2);

      this.setState({
        hasCoupon: true,
        total,
      });
      this.closeModal();
      return {
        status: 'success',
        message: `Descuento aplicado -${discountPercentage.toFixed(2).split('.')[1]}%`
      };
    }
    this.closeModal();
    return {
      status: 'error',
      message: '¡Cupón no válido!'
    };
  }

  openModal = (text = 'coupon') => {
    const { history } = this.props;
    this.setState({ isModalOpened: true });

    if (text !== 'coupon') {
      this.setState({ modalText: text });
    }

    document.body.style.overflow = 'hidden';

    setTimeout(() => {
      this.closeModal();
      history.push('/subscription/payment/thanks');
    }, 5000);
  }

  closeModal = () => {
    this.setState({ isModalOpened: false });
    document.body.style.overflow = 'unset';
  }

  savedCard = () => {
    const {
      payment: { method },
      savedCard: { name, number, type }
    } = this.state;

    let lastNumbers = '0000';
    let cardName = '';
    let cardType = '';
    let cardImage = '';

    if (number) {
      lastNumbers = number.split(' ').slice(-1).pop();
      cardName = name;
      cardType = type;
      cardImage = `./img/credit-flags/${cardType}.png`;
    }

    return (
      <Box
        className={cs('payment-box payment-option grey-bg margin-bottom-small rounded-small', {
          active: method === 'saved-card'
        })}
      >
        <Box className="payment-option-header">
          <Box className="option">
            <input
              type="radio"
              id="saved-card"
              name="payment-method"
              value="saved-card"
              checked={method === 'saved-card'}
              onChange={this.handlePaymentMethodChange}
            />
            {/* eslint-disable-next-line */}
            <label htmlFor="saved-card">
              <Heading type="h4" size="normal" textColor="regent-grey" className="option-name saved">
                VISA ****
                {' '}
                {lastNumbers}
                <br />
                <span className="font-size-small">{cardName}</span>
              </Heading>
            </label>
            <ul className="credit-brands">
              <li><Image src={cardImage} alt={cardType} /></li>
            </ul>
          </Box>
        </Box>
      </Box>
    );
  }

  creditCardForm = () => {
    const {
      payment: {
        card: {
          name, number, date, cvv, type
        }
      }
    } = this.state;

    return (
      <form>
        <Row className="fade-in">
          <Col xs="12" sm="12">
            <InputField
              name="credit-name"
              type="text"
              label="Nombre del titular"
              full
              big
              required
              value={name}
              placeholder="Ex: Sergio Ramos Torres"
              onChange={(v) => this.maskInput(v)}
              className="without-shadow rounded-small full"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="6" className="position-relative">
            <InputField
              name="credit-number"
              type="text"
              label="Número de la Tarjeta"
              full
              big
              required
              value={number}
              placeholder="0000 0000 0000 0000"
              onChange={(v) => this.maskInput(v, 'cardNumber')}
              className="without-shadow rounded-small full  "
            />
            {type !== 'mastercard' ? (
              <Icon name={`cc-${type}`} prefix="fab" color="text" size="medium" className="credit-type position-absolute" />
            ) : (
              <Image src="./img/credit-flags/transparent/mastercard.png" alt="mastercard logo" className="credit-type position-absolute" />
            )}
          </Col>
          <Col xs="6" lg="3">
            <InputField
              name="credit-date"
              type="text"
              label="Fecha de Cad."
              full
              big
              required
              value={date}
              placeholder="00 / 00"
              onChange={(v) => this.maskInput(v, 'cardDate')}
              className="without-shadow rounded-small full regent-grey-bg"
            />
          </Col>
          <Col xs="6" lg="3">
            <InputField
              name="credit-cvv"
              type="number"
              label="CVV"
              full
              big
              required
              value={cvv}
              placeholder="000"
              onChange={(v) => this.maskInput(v, 'cardCVV')}
              className="without-shadow rounded-small full  "
            />
          </Col>
        </Row>
      </form>
    );
  }

  payPal = () => (
    <Paragraph className="rounded-small fade-in">
      Será redirigido al sitio web de Paypal para completar su
      compra de forma segura.
    </Paragraph>
  );

  calcTotal = () => {
    const { cart } = this.state;
    let { total } = this.state;

    total += cart
      .map(({ price }) => Number(price.split('€')[0]))
      .reduce((a, i) => a + i);

    this.setState({
      total
    });
  }

  buy = () => {
    this.openModal('pago');
  }

  renderBuyForm = (number) => {
    const { buttonActive, total, period } = this.state;

    return (
      <Box className="margin-top-medium">
        <Box>
          <InputField id={`save-card-${number}`} type="checkbox" />
          <Label inputId={`save-card-${number}`} className="font-size-small">
            Guardar de forma segura tu tarjeta para futuros pagos.
          </Label>
        </Box>
        <Box>
          <InputField
            id={`terms-${number}`}
            type="checkbox"
            onChange={() => this.setState((prevState) => ({ buttonActive: !prevState.buttonActive }))}
          />
          <Label inputId={`terms-${number}`} className="font-size-small">
            <Box tag="span">
              Acepto los
              {' '}
              <NavLink href="." className="secondary-color">
                términos y condiciones
              </NavLink>
              {' '}
              y nuestra
              {' '}
              <NavLink href="." className="secondary-color">
                cláusula informativa.
              </NavLink>
            </Box>
          </Label>
        </Box>
        <Button
          tag="button"
          color="secondary"
          textColor="white"
          borderColor="secondary"
          disabled={!buttonActive}
          full
          size="big"
          onClick={this.buy}
        >
          Comprar
          {' '}
          <span className="hidden-dsk">
            €
            {' '}
            {Number(total).toFixed(2)}
            {' '}
            /
            {' '}
            { period }
          </span>
        </Button>
      </Box>
    );
  };

  render() {
    const {
      payment: { method },
      cart,
      total,
      hasCoupon,
      completed,
      isModalOpened,
      modalText,
      period
    } = this.state;

    const { isUnycos } = this.props;

    const [master, visa, amex] = ['./img/credit-flags/mastercard.png', './img/credit-flags/visa.png', './img/credit-flags/amex.png'];

    return (
      <Container fluid wrap className="payment margin-bottom-x-big">
        <Row style={{ overflow: 'hidden' }}>
          {!completed && (
            <>
              <Col xs="12" md="7" className="payment-method margin-top-x-small">
                <Heading type="h3" size="medium" className="margin-bottom-medium">Método de Pago</Heading>
                <Box
                  className={cs('payment-box payment-option grey-bg margin-bottom-small rounded-small', {
                    active: method === 'credit-card'
                  })}
                >
                  <Box className="payment-option-header">
                    <Box className="option">
                      <input
                        type="radio"
                        id="credit-card"
                        name="payment-method"
                        value="credit-card"
                        checked={method === 'credit-card'}
                        onChange={this.handlePaymentMethodChange}
                      />
                      {/* eslint-disable-next-line */}
                      <label htmlFor="credit-card">
                        <Heading type="h4" size="normal" textColor="main" className="option-name text-uppercase">Tarjeta de crédito</Heading>
                      </label>
                      <ul className="credit-brands">
                        <li><Image src={master} alt="Mastercard Logo" /></li>
                        <li><Image src={visa} alt="Visa Logo" /></li>
                        <li><Image src={amex} alt="Amex Logo" /></li>
                      </ul>
                    </Box>
                  </Box>
                  <Box className="payment-option-body">
                    { this.creditCardForm() }
                  </Box>
                </Box>
                <Box
                  className={cs('payment-box payment-option paypal grey-bg margin-bottom-small rounded-small', {
                    active: method === 'paypal'
                  })}
                >
                  <Box className="payment-option-header">
                    <Box className="option">
                      <input
                        type="radio"
                        id="paypal"
                        name="payment-method"
                        value="paypal"
                        checked={method === 'paypal'}
                        onChange={this.handlePaymentMethodChange}
                      />
                      {/* eslint-disable-next-line */}
                      <label htmlFor="paypal">
                        <Image src="./img/paypal-normal.png" alt="Logo Paypal" className="option-name" />
                      </label>
                    </Box>
                  </Box>
                  <Box className="payment-option-body text">
                    { this.payPal() }
                  </Box>
                </Box>
                <Box className="hidden-dsk margin-top-big">
                  { this.renderBuyForm(1) }
                </Box>
                <Row className="margin-top-big">
                  <Col xs="12" sm="6" className="security-item margin-bottom-medium">
                    <Image src="./img/security.png" alt="security icon" />
                    <Box>
                      <Heading type="h5" textColor="main" size="normal" className="without-margin">Pago Seguro</Heading>
                      <Paragraph size="small" className="without-margin" textColor="text">
                        Todas las transacciones son seguras y encriptadas. La información crediticia nunca se almacena.
                      </Paragraph>
                    </Box>
                  </Col>
                  <Col xs="12" sm="6" className="security-item margin-bottom-medium">
                    <Image src="./img/cashback-blue.png" alt="cashback icon" />
                    <Box>
                      <Heading type="h5" textColor="main" size="normal" className="without-margin">Garantía de satisfacción</Heading>
                      <Paragraph size="small" className="without-margin" textColor="text">
                        Seguro que te encantará, pero si no es así, puedes solicitar reembolso hasta 30 días de tu compra.
                      </Paragraph>
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="1"></Col>
              <Col xs="12" md="4" className="payment-resume margin-top-x-small margin-bottom-small">
                <Heading type="h3" size="medium" className="margin-bottom-medium">Resumen del pedido</Heading>
                <Box className="payment-box grey-bg ronded-small rounded-small">
                  {cart.map(({
                    title, price
                  }) => (
                    <Box key={title} className="buy-item margin-bottom-small">
                      <Box className="item">
                        <Box className="item-info">
                          <Box className="item-heading">
                            <Heading type="h4" size="normal" textColor="main" className="without-margin text-uppercase">
                              {title}
                            </Heading>
                          </Box>
                        </Box>
                        <Box className="item-price">
                          <Heading type="h3" size="normal" textColor="main">{price}</Heading>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                  <Box className="coupon-form margin-top-medium margin-bottom-medium">
                    <CouponForm
                      onSubmitCoupon={this.applyCoupon}
                      couponActive={hasCoupon}
                      openModal={this.openModal}
                      closeModal={this.closeModal}
                      isUnycos={isUnycos}
                    />
                  </Box>
                  <hr className="regent-grey-bg" />
                  <Paragraph textColor="main" size="medium" className="total bold-font">
                    Total:
                    <strong className="secondary-color">
                      €
                      {' '}
                      {Number(total).toFixed(2)}
                      {' '}
                      /
                      {' '}
                      { period }
                    </strong>
                  </Paragraph>
                  <Box className="hidden-mb">
                    { this.renderBuyForm(2) }
                  </Box>
                </Box>
              </Col>
            </>
          )}
        </Row>
        <Modal
          isOpen={isModalOpened}
          appElement="#root"
          closeIcon={false}
          width={784}
          height={400}
          animate={{
            directionFrom: 'bottom',
            effectIni: 300,
            effectEnd: 200,
          }}
          className="modal-processing"
        >
          <Row>
            <Col xs={12} md={5} className="align-center">
              <Icon name="money-bill-wave" prefix="fas" size="big" color="black" className="pulsate" />
              <Heading type="h2" size="x-big" className="title normal-font">
                Estamos Procesando tu
                {' '}
                {modalText}
              </Heading>
              <Paragraph>Esto puede tardar algunos segundos no actualices la pantalla.</Paragraph>
            </Col>
            <Col xs={12} md={6} mdOffset={1} className="align-center">
              <Image src="./img/unycos/process-information.svg" alt="Processing Information" />
            </Col>
          </Row>
        </Modal>
      </Container>
    );
  }
}

Payment.defaultProps = {
  isUnycos: true
};

Payment.propTypes = {
  isUnycos: PropTypes.bool,
  history: PropTypes.any
};

export default withRouter(Payment);
