import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Icon,
  Paragraph,
  NavLink
} from 'base-ui';

// Components
import MenuAccount from '../../../components/Consultation/MenuAccount';

const AccountSubscription = () => (
  <PageWrapper config={{ title: 'Consultation Access Not Allowed' }}>

    <Box tag="main" id="site-main">

      <Box tag="section">
        <Container container fluid wrap>
          <Row className="center-xs">
            <Col xs="12" sm="4">
              <MenuAccount />
            </Col>
            <Col xs="12" sm="8">
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Información de suscripción</Box>
                </Box>
                <Box className="align-center">
                  <Paragraph>
                    Actualmente tienes activa tu suscripción al
                    {' '}
                    <strong className="highlight main-border">plan Premium</strong>
                    ,
                    <br />
                    {' '}
                    la cual se renovara el
                    {' '}
                    <strong>7/7/2017</strong>
                    {' '}
                    y se te cobrara 29.99€
                  </Paragraph>
                  <NavLink to="payment-methods" className="btn grey-border text-color">Cambiar/Cacelar</NavLink>
                </Box>
              </Card>
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Métodos de pago</Box>
                </Box>
                <Row>
                  <Col xs="12" sm="6">
                    <Card>
                      <Icon prefix="fab" name="cc-mastercard" className="big regent-grey-color" />
                      <br />
                      <br />
                      <strong>XXXXXXXXXXXX4233</strong>
                      <br />
                      06 / 2021
                    </Card>
                  </Col>
                </Row>
                <NavLink to="payment-methods" className="btn main-border main-color">Cambiar/Actualizar</NavLink>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default AccountSubscription;
