import React, { Component } from 'react';

import {
  Row,
  Col,
	Box,
	Image,
	Card,
	InputField,
	Button,
	Icon
} from 'base-ui';

class CourseGiftForm extends Component {
	render() {
		return(
			<Card className="margin-top-medium margin-bottom-big">
				<Row className="middle-xs">
					<Col xs="12">
						<Box tag="strong">Regalo para:</Box>
					</Col>
					<Col xs="12" md="6">
						<InputField
							className="without-shadow margin-bottom-small"
							name='name'
							required
							type="text"
							placeholder="Nombre"
							big
						/>
					</Col>
					<Col xs="12" md="6">
						<InputField
							className="without-shadow margin-bottom-small"
							name='email'
							required
							type="email"
							placeholder="Correo electrónico"
							big
						/>
					</Col>
					<Col xs="12">
						<Box tag="strong">Mensaje:</Box>
						<InputField
							className="without-shadow"
							name='subject'
							required
							type="textarea"
							placeholder="Mensaje"
							full
							big
						/>
					</Col>
					<Col xs="6">
						<Box className="margin-bottom-medium">
							<input id="c3" name="date" type="radio" />
							<label for="c3">
								<Box tag="span">Enviar ahora</Box>
							</label>
						</Box>
					</Col>
					<Col xs="6">
						<Box className="margin-bottom-medium">
							<input id="c4" name="date" type="radio" />
							<label for="c4">
								<Box tag="span">Elegir fecha <Icon name="calendar" /></Box>
							</label>
						</Box>
					</Col>
					<Col xs="3" className="align-center">
						<Image src="./img/safe-payment.jpg" />
					</Col>
					<Col xs="9">
						<Button color="main" borderColor="main" textColor="white" full size="big">Siguiente</Button>
					</Col>
				</Row>
			</Card>
		)
  }
}

export default CourseGiftForm;