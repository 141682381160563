import React from 'react';
import {
  Container,
  Box,
  Row,
  Col,
  Button
} from 'base-ui';

const Background = './img/unycos/footer-cta.png';

const UnycosFooterCTA = () => (
  <Box id="unycos-footer-cta" className="cta cta__bg--thumb margin-top-medium margin-bottom-medium" style={{ backgroundImage: `url(${Background})` }}>
    <Container fluid wrap>
      <Row className="middle-xs center-xs">
        <Col xs="12" sm="8" md="5" className="align-center center-xs">
          <Box tag="span" className="display-block center-xs normal-font text-uppercase white-color font-size-x-medium margin-bottom-x-medium">Aprende con los mejores</Box>
          <Box tag="span" className="display-block center-xs normal-font text-uppercase white-color font-size-medium margin-bottom-x-medium">Detrás de cada éxito, hay una historia. conócelas en nuestro blog.</Box>
        </Col>
        <Col xs="12">&nbsp;</Col>
        <Col xs="12" md="4" className="margin-top-normal">
          <Button borderColor="white" color="transparent" textColor="white" className="text-uppercase normal-font" full>Leer más en nuestro blog</Button>
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycosFooterCTA;
