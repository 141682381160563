import React from 'react';

import {
  Box,
  Paragraph,
  Icon,
  Image
} from 'base-ui';
import ReadMore from './ReadMore';

const CourseReviewsDesktop = () => (
  <Box id="course-reviews" className="margin-bottom-x-big hidden-md">
    <Box className="section-title thin-border without-margin-bottom font-size-normal">
      <Box tag="span" className="label line-height-small without-margin text-uppercase titles-color">
        Lo que dicen los alumnos
      </Box>
    </Box>
    <Box>
      <Paragraph className="secondary-color without-margin-top">
        <Box tag="strong">
          <Icon prefix="fas" name="thumbs-up" />
          100% de valoraciones positivas
        </Box>
      </Paragraph>
    </Box>
    <Box>
      <ReadMore
        maxHeightvalue="350px"
        excerpt={(
          <Box>
            <Box className="course-review margin-bottom-x-medium">
              <Box className="avatar rounded">
                <Image src="./img/teachers/cristina.jpg" />
              </Box>
              <Box className="author">
                <Box tag="strong" className="name">Rosa Pereira</Box>
                <Box tag="span" className="date font-size-small text-color">
                  <Icon prefix="fas" name="thumbs-up" />
                  Hace 4 meses
                </Box>
              </Box>
              <Paragraph className="review without-margin-top">Me ha encantado, es un gran curso.</Paragraph>
            </Box>
            <Box className="course-review margin-bottom-x-medium">
              <Box className="avatar rounded">
                <Image src="./img/teachers/claudia.png" />
              </Box>
              <Box className="author">
                <Box tag="strong" className="name">Demelsa Yanes Gutiérrez</Box>
                <Box tag="span" className="date font-size-small text-color">
                  <Icon prefix="fas" name="thumbs-up" />
                  Hace 4 meses
                </Box>
              </Box>
              <Paragraph className="review without-margin-top">Con la finalización de este curso, me llevo un gran aprendizaje para la vida. Gracias, Ángel Lafuente por esta gran oportunidad de aprendizaje y a La mente es Maravillosa, por incluir este curso en su plan de formación.</Paragraph>
            </Box>
            <Box className="course-review margin-bottom-x-medium">
              <Box className="avatar rounded">
                <Image src="./img/teachers/josejuanrivero.png" />
              </Box>
              <Box className="author">
                <Box tag="strong" className="name">Mauricio Bravo</Box>
                <Box tag="span" className="date font-size-small text-color">
                  <Icon prefix="fas" name="thumbs-up" />
                  Hace 6 meses
                </Box>
              </Box>
              <Paragraph className="review without-margin-top">Un curso sencillo y directo al grano. Me encantó.</Paragraph>
            </Box>
          </Box>
        )}
        fullText={(
          <Box>
            <Box className="course-review margin-bottom-x-medium">
              <Box className="avatar rounded">
                <Image src="./img/teachers/cristina.jpg" />
              </Box>
              <Box className="author">
                <Box tag="strong" className="name">Rosa Pereira</Box>
                <Box tag="span" className="date font-size-small text-color">
                  <Icon prefix="fas" name="thumbs-up" />
                  Hace 4 meses
                </Box>
              </Box>
              <Paragraph className="review without-margin-top">Me ha encantado, es un gran curso.</Paragraph>
            </Box>
          </Box>
        )}
      />
    </Box>
  </Box>
);

export default CourseReviewsDesktop;
