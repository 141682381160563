import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Card,
  Heading,
  Lists,
  Paragraph
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';

const UnycosAvisos = () => (
  <PageWrapper className="default-page" config={{ title: 'Unycos - Aviso Legal' }}>
    <UnycosLayout>
      <UnycosHeader statuslogged={false} />

      <Container fluid wrap>
        <Row className="around-xs middle-xs">
          <Col xs="12" className="align-center">
            <Heading type="h1" className="title-page margin-bottom-x-big margin-top-x-medium font-size-x-big light-white-color normal-font text-uppercase">Aviso Legal</Heading>
          </Col>
          <Col xs="12" md="10">
            <Card className="card-default-text">

              <Heading type="h5">1. Datos de identificación y propiedad del sitio web</Heading>
              <Paragraph>Según la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, se informa que el titular de la página web es GRUPO M CONTIGO SL con CIF B37527900 con domicilio en la localidad de Calle Concejo, 13, 2ºA, 37002 Salamanca e Inscrita en el Registro Mercantil de Salamanca, fecha 20-08-2013, Folio 146, Tomo 442, Libro 0, Hoja SA-15157 y con correo electrónico de contacto: contacto@mcontigo.com.</Paragraph>

              <blockquote>
                <Paragraph>
                  <strong>Propietario:</strong>
                  {' '}
  Grupo MContigo S.L.
                  <br />
                  <strong>Oficina:</strong>
                  {' '}
  Calle Concejo 13, 37002 – Salamanca (España).
                  <br />
                  <strong>NIF:</strong>
                  {' '}
  B-37527900
                  <br />
                  <strong>Información de registro mercantil:</strong>
                  {' '}
  Registrado en Salamanca, fecha 20-08-2013, Folio 146, Tomo 442, Libro 0, Hoja SA-15157
                  <br />
                  <strong>E-mail:</strong>
                  {' '}
  contacto@mcontigo.com
                </Paragraph>
              </blockquote>

              <Heading type="h5">2. Aceptación del usuario</Heading>
              <Paragraph>Estos términos y condiciones de uso regulan la utilización del sitio web www.unycos.com de GRUPO M CONTIGO SL.</Paragraph>
              <Paragraph>El acceso y uso del sitio web implica la aceptación, sin reserva ni limitación, de los presentes términos y condiciones de uso, la política de privacidad y su uso está supeditado a las condiciones que se expresan a continuación, así como a la legislación vigente.</Paragraph>
              <Paragraph>GRUPO M CONTIGO SL se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida en la página web, así como de la configuración y presentación de esta y de las condiciones de uso y acceso a la misma. El usuario quedará obligado automáticamente por las condiciones de uso que se hallen vigentes en el momento en que acceda a la web, por lo que deberá leer periódicamente los términos y condiciones de uso.</Paragraph>

              <Heading type="h5">3. Legislación</Heading>
              <Paragraph>Con carácter general las relaciones entre GRUPO M CONTIGO SL con los Usuarios de sus servicios telemáticos, presentes en este sitio web, se encuentran sometidas a la legislación y jurisdicción españolas.</Paragraph>

              <Heading type="h5">4. Uso y acceso de usuarios</Heading>
              <Paragraph>El usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio de una relación comercial con GRUPO M CONTIGO SL o cualquiera de sus delegaciones.</Paragraph>

              <Heading type="h5">5. Propiedad intelectual e industrial</Heading>
              <Paragraph>Los derechos de propiedad intelectual del contenido de las páginas web, su diseño gráfico, logos y códigos son titularidad de GRUPO M CONTIGO SL y, por tanto, queda prohibida su reproducción, distribución, comunicación pública, transformación o cualquier otra actividad que se pueda realizar con los contenidos de sus páginas web ni aún citando las fuentes, salvo consentimiento por escrito de GRUPO M CONTIGO SL.</Paragraph>
              <Paragraph>GRUPO M CONTIGO SL es el titular legítimo de los derechos de explotación sobre los contenidos publicados en el sitio web, en todo el mundo, por un plazo perpetuo, de manera absoluta y sin límites de ninguna naturaleza. En particular y a modo enunciativo pero no limitativo, GRUPO M CONTIGO SL podrá reproducir total o parcialmente, comercializar, difundir, traducir, publicar, imprimir, alterar, transformar, realizar adaptaciones y actualizaciones de los contenidos y celebrar contratos con terceros para ceder su uso, acordar su distribución e incluso ceder los derechos de explotación.</Paragraph>

              <Heading type="h5">6. Contenidos del sitio web</Heading>
              <Paragraph>GRUPO M CONTIGO SL se reserva el derecho a actualizar, modificar o eliminar la información contenida en sus páginas web pudiendo incluso limitar o no permitir el acceso a dicha información a ciertos usuarios.</Paragraph>
              <Paragraph>GRUPO M CONTIGO SL. no asume responsabilidad alguna por la información contenida en páginas web de terceros a las que se pueda acceder por &quot;links&quot; o enlaces desde cualquier página web propiedad de GRUPO M CONTIGO SL. La presencia de &quot;links&quot; o enlaces en las páginas web de GRUPO M CONTIGO SL tiene finalidad meramente informativa y en ningún caso supone sugerencia, invitación o recomendación sobre los mismos.</Paragraph>
              <Paragraph>Los autores de los contenidos ceden, de manera voluntaria, los derechos de propiedad de las obras, a la propiedad de GRUPO M CONTIGO SL estando prohibida la reproducción sin consentimiento o mención de la fuente original.</Paragraph>
              <Paragraph>Cada autor colaborador del sitio web se hace responsable de sus propios artículos así como de las opiniones vertidas en ellos. Asimismo, todos los contenidos que los autores deseen publicar serán previamente revisados por GRUPO M CONTIGO SL, sin que este asuma responsabilidad alguna por dicha labor de revisión o moderación para detectar contenidos que, a su juicio, puedan resultar ilícitos o, cuanto menos, inapropiados. No obstante, GRUPO M CONTIGO SL no asume responsabilidad alguna por los contenidos finalmente publicados, ni por la conducta del usuario respecto del contenido publicado.</Paragraph>
              <Paragraph>Si existen contenidos que pudieran estar vulnerando derechos de propiedad intelectual o cualesquiera otros derechos de terceras partes, GRUPO M CONTIGO SL los retirará del sitio web o impedirá el acceso a los mismos si un órgano competente así lo hubiera declarado, tal y como establece la normativa vigente.</Paragraph>
              <Paragraph>La web puede contener información sobre asuntos médicos y sanitarios. La información aquí contenida no pretende reemplazar el consejo de su médico o el asesoramiento de cualquier otro profesional sanitario. El usuario no deberá utilizar la información expuesta en la web para diagnosticar una enfermedad o problema de salud, sino que deberá siempre consultarlo con su médico.</Paragraph>
              <Paragraph>Los contenidos de la web tienen exclusivamente carácter informativo, de modo que son insuficientes para la toma de decisiones por parte del usuario.</Paragraph>

              <Heading type="h6">6.1 Contenidos gráficos</Heading>
              <Paragraph>Se encuentran amparados bajo la licencia adquirida con Fotolia © y ShutterStock Inc., Getty Images y su uso respeta los términos y condiciones del contrato establecido con dichas empresas.</Paragraph>
              <Paragraph>Los otorgantes de licencias retienen todos los derechos de propiedad y de las obras. Dichas obras están cubiertas y protegidas por derechos de autor, marca registrada y otros derechos de propiedad intelectual de sus licenciatarios.</Paragraph>
              <Paragraph>Si usted es fotógrafo y reconoce como propia alguna de las fotografías publicadas en esta web, póngase en contacto con nosotros de manera inmediata. Se procederá a reconocer la autoría de la misma o a retirarla instantáneamente de nuestro portal si así lo indicase.</Paragraph>

              <Heading type="h5">7. Exención de responsabilidad</Heading>
              <Paragraph>GRUPO M CONTIGO SL no será responsable de los daños derivados del uso del sitio web, ni de cualquier actuación realizada en base a la información que en el mismo se facilita.</Paragraph>
              <Paragraph>El sitio web está dirigido a usuarios mayores de catorce (14) años, eximiéndose GRUPO M CONTIGO SL por cualquier acceso y utilización del sitio web no autorizada.</Paragraph>

              <Heading type="h5">8. Notificación de infracciones</Heading>
              <Paragraph>Si cualquier persona o entidad considera que los contenidos publicados en el sitio web infringen sus derechos de propiedad intelectual o industrial, sus derechos al honor, a la intimidad, a la imagen o cualquier otro derecho podrá comunicarlo a GRUPO M CONTIGO SL por cualquiera de las vías de contacto que figuran en el sitio web con el asunto “Vulneración de derechos” y solicitar su retirada.</Paragraph>
              <Paragraph>Debe tener en cuenta que es fundamental aportar la siguiente información:</Paragraph>
              <Lists type="ol">
                <li>Identificación de los derechos protegidos por la legislación vigente que se consideran violados.</li>
                <li>Identificación de los contenidos que presuntamente violan los derechos en cuestión con información razonablemente suficiente para permitir a GRUPO M CONTIGO SL ubicar dicho material en el sitio web.</li>
                <li>Información de los datos de contacto (dirección física o electrónica y número de teléfono) para que GRUPO M CONTIGO SL pueda comunicarse con dicha persona o entidad.</li>
                <li>Una declaración en la que dicha persona o entidad afirme que la información incluida en la notificación es veraz y exacta y que es el titular de los derechos presuntamente vulnerados o que está autorizado a actuar en nombre de este.</li>
                <li>Copia del D.N.I, pasaporte u otro documento oficial que acredite su identidad.</li>
              </Lists>
              <Paragraph>GRUPO M CONTIGO SL atenderá dicha petición y si considera que dicha persona o entidad tiene motivos o causas justificadas para solicitar la retirada de los contenidos infractores, procederá a su eliminación inmediata del sitio web de forma que no sean accesibles por los usuarios ni siquiera desde la memoria caché.</Paragraph>

              <Heading type="h5">9. Ámbito</Heading>
              <Paragraph>Cualquier contenido, acuerdo, contrato o similar que figure en esta web se rige bajo las Leyes Españolas. Los visitantes de esta web y cualquier persona o empresa que utilice o contrate cualquier servicio que se preste en la misma, con renuncia expresa a cualquier otro fuero, se someten al Tribunal de Arbitraje de la Cámara Oficial de Comercio e Industria de Salamanca, como paso previo a la vía judicial en los Juzgados de la Ciudad de Salamanca, para la resolución de cualquier controversia o disputa legal que pudiera presentarse.</Paragraph>

            </Card>
          </Col>
        </Row>
      </Container>

      <UnycosFooter className="hidden-lg" showHideElements={{ social: true, cta: false }} />
    </UnycosLayout>
  </PageWrapper>
);

export default UnycosAvisos;
