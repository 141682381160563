import React from 'react';

import {
  Box,
  Heading,
  Icon,
  Paragraph
} from 'base-ui';

import ReadMore from './ReadMore';
import AccordionCat from '../Courses/AccordionCat';

const CourseContentMobile = () => (
  <Box className="margin-bottom-x-medium hidden-lg">
    <Box className="section-title thin-border font-size-normal margin-bottom-small">
      <Box tag="span" className="label line-height-small without-margin-top text-uppercase titles-color">
        Contenido del curso
      </Box>
    </Box>
    <ReadMore
      maxHeightvalue="400px"
      excerpt={(
        <AccordionCat version="modules-version" className="accordion-cat-mobile-single" allowMultipleOpen>
          <Box
            title={(
              <Box>
                <Paragraph size="medium" className="module-number without-margin"><Box tag="strong">Módulo 1</Box></Paragraph>
                <Heading type="h3" size="small" className="module-titles without-margin main-font normal-font">Título del módulo 1</Heading>
              </Box>
            )}
            label="module-1"
            isOpen
          >
            <Box className="item">
              <Icon prefix="fas" name="video" />
              <Box tag="span">Priorizar tus objetivos</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="file" />
              <Box tag="span">Características de las personas con una</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="link" />
              <Box tag="span">Características de las personas con una actitud positiva</Box>
            </Box>
          </Box>
          <Box
            title={(
              <Box>
                <Paragraph size="medium" className="module-number without-margin"><Box tag="strong">Módulo 2</Box></Paragraph>
                <Heading type="h3" size="small" className="module-titles without-margin main-font normal-font">Título del módulo 2</Heading>
              </Box>
            )}
            label="module-2"
          >
            <Box className="item">
              <Icon prefix="fas" name="video" />
              <Box tag="span">Priorizar tus objetivos</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="file" />
              <Box tag="span">Generar confianza</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="link" />
              <Box tag="span">Estabelecer metas</Box>
            </Box>
          </Box>
          <Box
            title={(
              <Box>
                <Paragraph size="medium" className="module-number without-margin"><Box tag="strong">Módulo 3</Box></Paragraph>
                <Heading type="h3" size="small" className="module-titles without-margin main-font normal-font">Título del módulo 3</Heading>
              </Box>
            )}
            label="module-3"
          >
            <Box className="item">
              <Icon prefix="fas" name="video" />
              <Box tag="span">Priorizar tus objetivos</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="file" />
              <Box tag="span">Generar confianza</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="link" />
              <Box tag="span">Estabelecer metas</Box>
            </Box>
          </Box>
          <Box
            title={(
              <Box>
                <Paragraph size="medium" className="module-number without-margin"><Box tag="strong">Módulo 4</Box></Paragraph>
                <Heading type="h3" size="small" className="module-titles without-margin main-font normal-font">Título del módulo 4</Heading>
              </Box>
            )}
            label="module-4"
          >
            <Box className="item">
              <Icon prefix="fas" name="video" />
              <Box tag="span">Priorizar tus objetivos</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="file" />
              <Box tag="span">Generar confianza</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="link" />
              <Box tag="span">Estabelecer metas</Box>
            </Box>
          </Box>
          <Box
            title={(
              <Box>
                <Paragraph size="medium" className="module-number without-margin"><Box tag="strong">Módulo 5</Box></Paragraph>
                <Heading type="h3" size="small" className="module-titles without-margin main-font normal-font">Título del módulo 5</Heading>
              </Box>
            )}
            label="module-5"
          >
            <Box className="item">
              <Icon prefix="fas" name="video" />
              <Box tag="span">Priorizar tus objetivos</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="file" />
              <Box tag="span">Generar confianza</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="link" />
              <Box tag="span">Estabelecer metas</Box>
            </Box>
          </Box>
          <Box
            title={(
              <Box>
                <Paragraph size="medium" className="module-number without-margin"><Box tag="strong">Módulo 6</Box></Paragraph>
                <Heading type="h3" size="small" className="module-titles without-margin main-font normal-font">Título del módulo 6</Heading>
              </Box>
            )}
            label="module-6"
          >
            <Box className="item">
              <Icon prefix="fas" name="video" />
              <Box tag="span">Priorizar tus objetivos</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="file" />
              <Box tag="span">Generar confianza</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="link" />
              <Box tag="span">Estabelecer metas</Box>
            </Box>
          </Box>
        </AccordionCat>
      )}
      fullText={(
        <AccordionCat version="modules-version" className="accordion-cat-mobile-single" allowMultipleOpen>
          <Box
            title={(
              <Box>
                <Paragraph size="medium" className="module-number without-margin"><Box tag="strong">Módulo 7</Box></Paragraph>
                <Heading type="h3" size="small" className="module-titles without-margin main-font normal-font">Título del módulo 7</Heading>
              </Box>
            )}
            label="module-7"
          >
            <Box className="item">
              <Icon prefix="fas" name="video" />
              <Box tag="span">Priorizar tus objetivos</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="file" />
              <Box tag="span">Generar confianza</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="link" />
              <Box tag="span">Estabelecer metas</Box>
            </Box>
          </Box>
          <Box
            title={(
              <Box>
                <Paragraph size="medium" className="module-number without-margin"><Box tag="strong">Módulo 8</Box></Paragraph>
                <Heading type="h3" size="small" className="module-titles without-margin main-font normal-font">Título del módulo 8</Heading>
              </Box>
            )}
            label="module-8"
          >
            <Box className="item">
              <Icon prefix="fas" name="video" />
              <Box tag="span">Priorizar tus objetivos</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="file" />
              <Box tag="span">Generar confianza</Box>
            </Box>
            <Box className="item">
              <Icon prefix="fas" name="link" />
              <Box tag="span">Estabelecer metas</Box>
            </Box>
          </Box>
        </AccordionCat>
      )}
    />
  </Box>
);

export default CourseContentMobile;
