import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Box, Card, Icon, Image, Button, NavLink, Modal
} from 'base-ui';
import cs from 'classnames';

function renderDescription(description) {
  description = description.replace(/<\/?a[^>]*>/g, '');
  return description.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

const AuthorBox = ({ author, user, followAuthor }) => {
  const [follow, setFollow] = useState(false);
  const [showModalCita, setModalCita] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(author.description.length < 100);
  }, [author]);

  const toggleFollow = () => {
    setFollow(!follow);
    followAuthor({ authorId: author.id, status: !follow });
  };

  const labelButtons = () => {
    const wWidth = window.innerWidth;
    if (wWidth > 450) {
      return follow ? ('Dejar de Seguir') : ('Seguir');
    }
    return follow ? ('Siguiendo') : ('Seguir');
  };

  return (
    <Card
      className={cs('post-author-box gray-border', {
        open: isExpanded,
      })}
    >
      {/* ===================================
      This modal is just an example,
      can be moved to parent component,
      and call a function from this componet
      ===================================== */}
      <Modal
        isOpen={showModalCita}
        appElement="#root"
        idModal="modal-cita"
        className="modal"
        closeModal={() => setModalCita(false)}
        closeIcon
        styles={{
          content: {
            boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
            borderRadius: '5px',
            display: 'block',
          },
          close: {
            position: 'absolute',
            right: '10px',
            top: '5px',
            zIndex: 1,
            cursor: 'pointer',
          }
        }}
        width="100px"
        height="100px"
      >
        &nbsp;
      </Modal>
      <Row>
        <Col xs="12">
          <Box className="author-avatar-box">
            <Box className="author-avatar">
              <Image alt={`Picture ${author.picture}`} src={author.picture} />
            </Box>
            <Box className="social-media-icons align-right">
              {author.social_profiles.facebook && (
                <NavLink href={author.social_profiles.facebook} target="_blank" className="btn facebook-color transparent-border">
                  <Icon prefix="fab" name="facebook-f" />
                </NavLink>
              )}
              {author.social_profiles.twitter && (
                <NavLink href={author.social_profiles.twitter} target="_blank" className="btn twitter-color transparent-border">
                  <Icon prefix="fab" name="twitter" />
                </NavLink>
              )}
              {author.social_profiles.linkedin && (
                <NavLink href={author.social_profiles.linkedin} target="_blank" className="btn blue-color transparent-border">
                  <Icon prefix="fab" name="linkedin" />
                </NavLink>
              )}
              {author.social_profiles.instagram && (
                <NavLink href={author.social_profiles.instagram} target="_blank" className="btn instagram-color transparent-border">
                  <Icon prefix="fab" name="instagram" />
                </NavLink>
              )}
            </Box>
          </Box>
          <NavLink href={author.permalink} className="txt-name font-size-medium highlight titles-color full">{author.name}</NavLink>
          {author.shortDescription && (
            <span className="txt-short-description font-size-small">{author.shortDescription}</span>
          )}
          {user && (
            <Box className="box-buttons margin-bottom-small">
              <Button
                textColor="white"
                borderColor="lmem-secondary"
                color="lmem-secondary"
                onClick={() => setModalCita(true)}
              >
                Pedir cita
              </Button>
              <Button
                onClick={toggleFollow}
                className={cs('btn-follow', {
                  'is-active': follow,
                })}
              >
                {labelButtons()}
              </Button>
            </Box>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: renderDescription(author.description) }}
            className={cs('margin-top-medium margin-bottom-medium', {
              clear: user,
            })}
          />
        </Col>
      </Row>
      {/*
        This button can be replaced by ReadMore component,
        but this solution have an animation...
      */}
      {!isExpanded && (
        <Box className="more-link-button continue-reading center-xs">
          <Button className="white-bg regent-grey-border" onClick={() => setExpanded(true)}>Leer más</Button>
        </Box>
      )}
    </Card>
  );
};

AuthorBox.propTypes = {
  user: PropTypes.object,
  author: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    permalink: PropTypes.string,
    slug: PropTypes.string,
    picture: PropTypes.string.isRequired,
    social_profiles: PropTypes.object,
    shortDescription: PropTypes.string,
    description: PropTypes.string.isRequired
  }),
  followAuthor: PropTypes.func
};

export default AuthorBox;
