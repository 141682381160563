/* eslint-disable no-underscore-dangle */
import React from 'react';

import {
  Heading,
  Row,
  Col,
	Box,
	Image,
	Card
} from 'base-ui';

const ForumDiscussion = (props) => {
  return (
    <Row>
			<Col xs="12" sm="2">
				<Box className="author">
					<Image src={props.authorImg} className="author-avatar rounded small" />
				</Box>
			</Col>
			<Col xs="12" sm="10">
				<Heading type="h1" size="big" className="without-margin-bottom">{props.discussionTitle}</Heading>
				<Box className="metas margin-bottom-normal">
					<Box tag="span" className="meta">Enviado por <strong>{props.authorName}</strong></Box>
					<Box tag="span" className="meta">Hace 2 minutos</Box>
				</Box>
				<Card>
					{props.children}
				</Card>
			</Col>
		</Row>
  );
};

export default ForumDiscussion;
