import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cs from 'classnames';

import { Button, Icon } from 'base-ui';

import 'moment/min/locales';
import './styles.css';

class ScheduleCalendar extends Component {
  state = {
    dateObject: moment().locale(this.props.lang),
    month: '',
    day: '',
    year: '',
    dayName: '',
    selectedMonth: '',
  };

  componentDidMount() {
    const { lang } = this.props;
    this.setState((prevState) => ({
      month: prevState.dateObject.locale(lang).format('MMMM'),
      year: prevState.dateObject.format('Y'),
    }));
  }

  daysInMonth = () => {
    const { dateObject } = this.state;
    return dateObject.daysInMonth();
  };

  firstDayOfMonth = () => {
    const { dateObject } = this.state;

    const firstDay = moment(dateObject).startOf('month').format('d');

    return firstDay;
  };

  checkDaysAvailable = (day) => {
    const { daysAvailable } = this.props;
    const { dateObject } = this.state;

    const currentMonth = dateObject.month() + 1;
    const daysAvailableByMonth = daysAvailable[currentMonth];

    if (!daysAvailableByMonth) {
      return;
    }

    if (daysAvailable[currentMonth].indexOf(day) !== -1) return true;
  };

  mountDaysName = () => {
    const { lang } = this.props;
    const weekdaysShort = moment.weekdaysShort(true);
    moment.locale(lang);
    const weekdayshortname = weekdaysShort.map((day) => (
      <th key={day}>{day}</th>
    ));

    return weekdayshortname;
  };

  mountDays = () => {
    const { month } = this.state;
    const { selectedDay, selectedMonth } = this.props;
    const blanks = [];

    for (let i = 0; i < this.firstDayOfMonth()-1; i += 1) {
      blanks.push(<td className="calendar-day is-empty">{''}</td>);
    }

    const daysInMonth = [];
    for (let d = 1; d <= this.daysInMonth(); d += 1) {
      daysInMonth.push(
        <td
          key={d}
          className={cs('calendar-day', {
            'is-available': this.checkDaysAvailable(d),
          })}
        >
          {this.checkDaysAvailable(d) ? (
            <Button
              tag="button"
              color="main"
              textColor="white"
              borderColor="transparent"
              className={cs('calendar-day-button', {
                'is-selected': d === selectedDay && selectedMonth === month,
              })}
              onClick={() => this.onSelectDay(d)}
            >
              {d}
            </Button>
          ) : (
            <>{d}</>
          )}
        </td>
      );
    }

    const totalSlots = [...blanks, ...daysInMonth];
    const rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      // if index not equal 7 that means not go to next week
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        // when reach next week we contain all td in last week to rows
        rows.push(cells);
        // empty container
        cells = [];
        // in current loop we still push current row to new container
        cells.push(row);
      }
      // when end loop we add remain date
      if (i === totalSlots.length - 1) {
        rows.push(cells);
      }
    });

    const daysinmonth = rows.map((d) => <tr key={d}>{d}</tr>);

    return daysinmonth;
  };

  changeDate = (delta) => {

    const { lang } = this.props;
    const allmonths = moment.months();

    moment.locale(lang);

    const { month } = this.state;

    let index = allmonths.indexOf(month.toLowerCase());

    if (index === 0 || index === allmonths.length - 1) {
      index += delta;
    }

    // Change Month
    if (index > 0 && index < allmonths.length - 1) {
      let dateObject = Object.assign({}, this.state.dateObject);
      dateObject = moment(dateObject).set('month', index + delta);

      return this.setState({
        dateObject,
        month: allmonths[index + delta],
      });
    }
  };

  onSelectDay = (day) => {
    const { setDate } = this.props;
    const { month, year } = this.state;
    const dayName = moment(`${year}-${month}-${day}`).format('dddd');

    const selectedMonth = month;

    this.setState({
      selectedMonth,
      day,
      dayName,
    });

    const date = {
      dayName,
      day,
      selectedMonth,
      year,
    };

    setDate(date);
  };

  onSelectFirstDay = () => {
    const { daysAvailable } = this.props;
    const firstDay = daysAvailable[Object.keys(daysAvailable)[0]][0];

    this.onSelectDay(firstDay);
  };

  render() {
    const { haveSelectFirstDay } = this.props;
    const { month, year } = this.state;

    return (
      <div className="calendar">
        {haveSelectFirstDay && (
          <Button
            tag="button"
            color="secondary"
            textColor="white"
            borderColor="secondary"
            onClick={() => this.onSelectFirstDay()}
            className="button-select-first"
          >
            Lo antes posible
          </Button>
        )}
        <div className="calendar-header">
          <div className="calendar-header-info">
            <span className="month">{month.charAt(0).toUpperCase() + month.slice(1)}</span>
            <span className="year">{year}</span>
          </div>
          <div className="calendar-header-actions">
            <Button
              tag="button"
              borderColor="transparent"
              color="transparent"
              onClick={() => this.changeDate(-1)}
            >
              <Icon name="chevron-left" prefix="fas" color="text" />
            </Button>
            <Button
              tag="button"
              borderColor="transparent"
              color="transparent"
              onClick={() => this.changeDate(1)}
            >
              <Icon name="chevron-right" prefix="fas" color="text" />
            </Button>
          </div>
        </div>
        <table className="calendar-days">
          <thead className="calendar-weekdays">
            <tr>{this.mountDaysName()}</tr>
          </thead>
          <tbody className="calendar-days-number">{this.mountDays()}</tbody>
        </table>
      </div>
    );
  }
}

ScheduleCalendar.propTypes = {
  /**
   * Default lang fot days and months
   */
  lang: PropTypes.string,

  /**
   * Object with month number and array of days availables
   * ex: {'5': [1,2,3], '6': [4,5,6]}
   * The month of May and June with respectives days
   */
  daysAvailable: PropTypes.object,

  /**
   * The callback for pass data to parent Component
   * Will Recieve 1 parameter (object with this values: dayname, day, month, year)
   */
  setDate: PropTypes.func.isRequired,

  /**
   * If will display the button to select first day available
   */
  haveSelectFirstDay: PropTypes.bool,

  /**
   * Day Already Selected
   */
  selectedDay: PropTypes.number,

  /**
   * Month Already Selected
   */
  selectedMonth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default ScheduleCalendar;
