import React from 'react';
import {
  Row,
  Col,
	Box,
	Button,
	Heading,
	Paragraph,
	Card,
	Image
} from 'base-ui';

const SubscriptionLandingTeachers = () => {
  return(
    <Box id="subscription-teachers">
      <Row className="center-xs padding-top-x-big">
				<Col xs="12" sm="10" md="6">
					<Paragraph textColor="main" size="big" className="without-margin center-xs"><strong>Dictados por profesionales</strong></Paragraph>
					<Paragraph size="medium" className="without-margin center-xs">Los mejores profesores expertos en sus areas.</Paragraph>
				</Col>
			</Row>
			<Row className="center-xs between-md padding-top-x-big padding-bottom-x-big">
				<Col xs="12" md="6">
					<Card className="with-left-thumb">
						<Row>
							<Col xs="12" sm="5">
								<Box className="thumb">
									<Box className="thumb-wrapper">
										<Image src="/img/teachers/tomas.jpg" />
									</Box>
								</Box>
							</Col>
							<Col xs="12" sm="7">
								<Box>
									<Heading type="h3" size="medium" textColor="main" className="without-margin-top main-font">Tómas Navarro</Heading>
									<Paragraph className="without-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam varius ligula mi, sed aliquet massa tristique vitae. Donec eu congue urna, sed hendrerit purus.</Paragraph>
								</Box>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs="12" md="6">
					<Card className="with-left-thumb">
						<Row>
							<Col xs="12" sm="5">
								<Box className="thumb">
									<Box className="thumb-wrapper">
										<Image src="/img/teachers/cristina.jpg" />
									</Box>
								</Box>
							</Col>
							<Col xs="12" sm="7">
								<Box>
									<Heading type="h3" size="medium" textColor="main" className="without-margin-top main-font">Tómas Navarro</Heading>
									<Paragraph className="without-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam varius ligula mi, sed aliquet massa tristique vitae. Donec eu congue urna, sed hendrerit purus.</Paragraph>
								</Box>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs="12" md="6">
					<Card className="with-left-thumb">
						<Row>
							<Col xs="12" sm="5">
								<Box className="thumb">
									<Box className="thumb-wrapper">
										<Image src="/img/teachers/josejuanrivero.png" />
									</Box>
								</Box>
							</Col>
							<Col xs="12" sm="7">
								<Box>
									<Heading type="h3" size="medium" textColor="main" className="without-margin-top main-font">Tómas Navarro</Heading>
									<Paragraph className="without-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam varius ligula mi, sed aliquet massa tristique vitae. Donec eu congue urna, sed hendrerit purus.</Paragraph>
								</Box>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col xs="12" md="6">
					<Card className="with-left-thumb">
						<Row>
							<Col xs="12" sm="5">
								<Box className="thumb">
									<Box className="thumb-wrapper">
										<Image src="/img/teachers/claudia.png" />
									</Box>
								</Box>
							</Col>
							<Col xs="12" sm="7">
								<Box>
									<Heading type="h3" size="medium" textColor="main" className="without-margin-top main-font">Tómas Navarro</Heading>
									<Paragraph className="without-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam varius ligula mi, sed aliquet massa tristique vitae. Donec eu congue urna, sed hendrerit purus.</Paragraph>
								</Box>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col xs="12" className="center-xs margin-bottom-big">
					<Button size="big" rounded textColor="white" borderColor="main" tag="a" color="main">
						Suscríbete
					</Button>
				</Col>
			</Row>
    </Box>
  )
}

export default SubscriptionLandingTeachers;