import React from 'react';

import {
  Box,
  Col,
  Row
} from 'base-ui';

import FeaturedCourses from '../Courses/FeaturedCourses';
import Loader from '../AppFooter/Loader';

const RelatedCourses = () => (
  <Box>
    <Row className="margin-bottom-x-medium">
      <Col xs="12">
        <Box className="section-title thin-border font-size-normal">
          <Box tag="span" className="label titles-color line-height-small without-margin text-uppercase">
            Cursos relacionados
          </Box>
        </Box>
      </Col>
      <Col xs="12">
        <Box className="horizontal-scroll-negative-md">
          <Box className="horizontal-scroll-md">
            <FeaturedCourses fallback={<Loader />} />
          </Box>
        </Box>
      </Col>
    </Row>
  </Box>
);

export default RelatedCourses;
