import React from 'react';

import {
  Container,
  Row,
  Col,
  Box,
  Hero,
  Button,
  Heading,
  Paragraph,
  PageWrapper,
} from 'base-ui';

import SubscriptionLandingFeatures from '../../../components/Subscription/SubscriptionLandingFeatures';
import SubscriptionLandingCategories from '../../../components/Subscription/SubscriptionLandingCategories';
import SubscriptionLandingTeachers from '../../../components/Subscription/SubscriptionLandingTeachers';
import SubscriptionLandingFAQ from '../../../components/Subscription/SubscriptionLandingFAQ';
import SubscriptionLandingCTA from '../../../components/Subscription/SubscriptionLandingCTA';

const CoursesSubscriptionLandingTwo = () => {
  const HeroSubline =
    <Paragraph size="x-medium" textColor="white"
               className="center-xs end-sm wow fadeInUp margin-top-small margin-bottom-medium"
               data-wow-delay="0.3s">La <strong>mejor oferta</strong> de <strong>cursos de
      psicología</strong> y <strong>desarrollo personal</strong> a tu alcance.</Paragraph>;
  return (
    <PageWrapper config={{ title: 'Courses subscription landing two' }}>
      <Box>
        <Hero BgImage="/img/subscription-hero.jpg">
          <Row className="middle-xs center-xs end-sm">
            <Col className="center-xs end-sm" xs="8" sm="7" md="6">
              <Heading type="h1" size="x-big" textColor="white" className="center-xs end-sm wow fadeInUp">
                Apuesta por tu felicidad
              </Heading>
              {HeroSubline}
              <Box tag="small" className="white-color">Descubre nuestros cursos y empieza a vivir mejor.</Box>
              <Box className="center-xs end-sm margin-top-small">
                <Button rounded size="big" textColor="white" borderColor="main" tag="a" color="main"
                        data-wow-delay="0.6s" className="wow fadeInUp">
                  Suscríbete
                </Button>
              </Box>
            </Col>
          </Row>
        </Hero>
        <Box id="site-main" style={{ overflow: 'hidden', margin: '0px' }}>
          <Container fluid wrap>
            <SubscriptionLandingFeatures />
            <SubscriptionLandingCategories />
            <SubscriptionLandingTeachers />
            <SubscriptionLandingFAQ />
          </Container>
        </Box>
        <SubscriptionLandingCTA />
      </Box>
    </PageWrapper>
  );
};

export default CoursesSubscriptionLandingTwo;
