import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Card,
  Heading,
  Paragraph
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';

const UnycosTerminos = () => (
  <PageWrapper className="default-page" config={{ title: 'Unycos - Términos y Condiciones' }}>
    <UnycosLayout>
      <UnycosHeader />
      <Container fluid wrap>
        <Row className="around-xs middle-xs">
          <Col xs="12">
            <Heading type="h1" className="title-page margin-bottom-x-big margin-top-x-medium font-size-x-big light-white-color normal-font text-uppercase">Términos y Condiciones</Heading>
          </Col>
          <Col xs="12" md="10">
            <Card className="card-default-text">
              <Paragraph>&quot;Unycos&quot; es una plataforma de cursos en línea con personalidades de reconocido prestigio. La plataforma &quot;Unycos&quot; es administrada por GRUPO M CONTIGO, S.L. con NIF nº: B37527900, y su equipo.</Paragraph>
              <Paragraph>Las condiciones de la suscripción de los cursos de “Unycos” son simples y se enumeran a continuación:</Paragraph>

              <Heading type="h5">1. Identificación y servicio</Heading>
              <Paragraph>En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes datos de información general de este sitio web:</Paragraph>
              <Paragraph>GRUPO M CONTIGO, SL, con CIF B37527900 y domicilio fiscal en Calle Concejo 13, Salamanca, España, CP: 37002, es el titular del portal www.unycos.com y la plataforma de cursos online. Se ofrece un servicio de suscripción anual que habilita a nuestros usuarios a acceder a los cursos que se ofertan e identifican en el portal de la suscripción, los cuales son transmitidos a través de Internet.</Paragraph>
              <Paragraph>Respetando lo establecido en la legislación vigente, GRUPO M CONTIGO, SL, se encuentra profundamente comprometido con el cumplimiento de la normativa española de protección de datos de carácter personal, y garantiza el cumplimiento íntegro de las obligaciones dispuestas, así como la implementación de las medidas de seguridad dispuestas en la legislación vigente en materia de Protección de Datos de Carácter Personal.</Paragraph>
              <Paragraph>De conformidad con lo dispuesto en la legislación de protección de datos, informamos a todos los usuarios del sitio web que faciliten o vayan a facilitar sus datos personales, que estos serán incorporados en una base de datos que cumple con todos los requisitos de la normativa vigente.</Paragraph>

              <Heading type="h5">2. Membresía</Heading>
              <Paragraph>La membresía de los cursos dispone de una modalidad anual. En cualquier modalidad el servicio se prestará con normalidad, hasta que este sea cancelado por el usuario. Para acceder y usar el servicio, debe tener acceso a Internet, un dispositivo compatible y proporcionar una forma de pago actual, válida y aceptada, que se puede actualizar cuando resulte necesario (&quot;Forma de Pago&quot;).</Paragraph>
              <Paragraph>A menos que cancele su membresía antes de la fecha de facturación correspondiente a su plan, nos otorga autorización para realizar el cobro de la respectiva membresía a su Forma de Pago registrada en nuestra plataforma.</Paragraph>

              <Heading type="h5">3. Tipos de membresías</Heading>
              <Heading type="h6">3.1 Membresía anual</Heading>
              <Paragraph>Existe un tipo de membresía anual con un costo de 200 euros por año y con acceso a todos los cursos. Estos cursos estarán disponibles en todos los dispositivos. Se puede hacer seguimiento al avance del curso. Se tendrá acceso al contenido offline en las apps. Los usuarios podrán tener acceso a la revisión de sus test y trabajos por parte de un experto adscrito a GRUPO M CONTIGO, SL. Los usuarios que aprueben el curso tendrán un certificado. Para lograr la aprobación no puede tener más de un error en los test.</Paragraph>

              <Heading type="h5">4. Facturación</Heading>

              <Heading type="h6">4.1. Ciclo de facturación</Heading>
              <Paragraph>Los cargos por el servicio de suscripción y cualquier otro cargo en el que incurra en relación con el uso que haga del servicio, como impuestos y gastos administrativos, se cobrarán, dependiendo el tipo de plan que haya elegido en el momento de su registro, a su forma de pago en el día calendario correspondiente al comienzo de la porción de pago de su membresía.</Paragraph>

              <Heading type="h6">4.2. Formas de pago</Heading>
              <Paragraph>El pago en nuestra plataforma se efectuará a través de una tarjeta de crédito o de débito, mediante una cuenta en PayPal o a través de la plataforma de pagos electrónicos Stripe. En el momento en el que se realiza la contratación de la suscripción de los cursos, será enviado el usuario a una pasarela de pago seguro proporcionada por las plataformas anteriormente nombradas, allí facilitará los datos de la tarjeta de crédito o débito, la fecha de caducidad, el CVV2/CVC2 y el nombre del titular de la cuenta si fuera requerido. “Unycos” y GRUPO M CONTIGO, SL., no guardan ningún dato bancario de sus usuarios, ya que el trámite es realizado en las pasarelas de pago seguras en línea. Asimismo, el proceso de pago funciona sobre un servidor seguro utilizando el protocolo SSL (Secure Socket Layer) y la información facilitada por el usuario es cifrada con algoritmos de 128 bits, permitiendo que esa sea sólo inteligible para el ordenador del usuario y el del sitio web. En caso que el pago no se pudiera hacer de forma satisfactoria, por razones de vencimiento, falta de fondos, si no se modifica la información de la forma de pago o bien, por cancelación de la cuenta, GRUPO M CONTIGO, SL, podrá suspender el acceso al curso hasta que se obtenga el pago correspondiente. Tan pronto como se actualice la forma de pago, se está autorizando a realizar los cobros pendientes. Esta situación podría generar un cambio en las fechas de cobros.</Paragraph>

              <Heading type="h6">4.3. Cancelación</Heading>
              <Paragraph>Se puede cancelar la membresía en cualquier momento, y continuará teniendo acceso al servicio hasta el final del ciclo de facturación. En la medida permitida por la ley aplicable, los pagos no son reembolsables y no se otorgarán reembolsos ni créditos por los períodos de membresía utilizados parcialmente o por el contenido del curso no visto, puesto que es su responsabilidad atender a la hora que pueda al contenido del curso. Si cancela su membresía, su cuenta se cerrará automáticamente al final de su ciclo de facturación actual. El cliente podrá ejercer su derecho de desistimiento en 14 días naturales desde la contratación de la suscripción, salvo en el supuesto que se hayan descargado contenidos de los cursos o haya accedido a información de éstos. Una vez transcurrido este plazo, la renovación de la suscripción será acorde al plan elegido. Este derecho podrá reclamarse a través del contacto del Departamento de Atención al Cliente, en el email: contacto@unycos.com.</Paragraph>

              <Heading type="h6">4.4. Cambios en el precio y planes de servicio</Heading>
              <Paragraph>GRUPO M CONTIGO, SL., se reserva el derecho de cambiar los planes de los servicios de suscripción. Sin embargo, cualquier cambio en los precios o en nuestros planes de servicios se aplicará a usted no antes de los 30 días siguientes a la notificación.</Paragraph>

              <Heading type="h5">5. Propiedad Intelectual</Heading>
              <Paragraph>El usuario se obliga a cumplir con la legislación en materia de propiedad intelectual y no podrá transferir, comercializar o enviar a terceros el contenido del curso que haya comprado. Tampoco podrá transferir, comercializar o enviar a terceros, el contenido de la suscripción. El usuario se compromete a no divulgar el contenido de la suscripción y de los cursos, cuyos derechos corresponden a GRUPO M CONTIGO, SL y a cada uno de los profesores.</Paragraph>

              <Heading type="h5">6. Prestación del servicio</Heading>
              <Paragraph>El usuario dispone de una información genérica de la suscripción y asume la responsabilidad de contratar la suscripción con esta información y declara que es suficiente para decidir la adquisición de la misma.</Paragraph>
              <Paragraph>El usuario podrá acceder al contenido de los cursos que se encuentren en la suscripción y sólo a los que hayan sido ofertados en la plataforma de suscripción.</Paragraph>
              <Paragraph>Cada usuario dispondrá de una cuenta única, personal e intransferible. En esta cuenta, el usuario deberá suministrar sus datos personales.</Paragraph>
              <Paragraph>El acceso a los cursos y la publicación de los módulos será de forma personalizada. Cada usuario ingresará a los módulos por períodos de tiempo en los cuales “Unycos” le dará acceso para completar los módulos de forma individual.</Paragraph>
              <Paragraph>Una vez registrado, el usuario tendrá acceso solo a su cuenta personal. Mediante ella podrá acceder a los servicios “Unycos”. Es importante que el usuario tenga en cuenta que al ser una plataforma web, debe mantener la confidencialidad de su nombre de usuario y contraseña, comprometiéndose a no facilitar estos datos a terceras personas. En este sentido el usuario es el único responsable de sus credenciales de acceso. En caso de detectar una anomalía en la utilización de sus datos y siendo el usuario el titular y único responsable por la cuenta, deberá notificar a contacto@unycos.com a efectos de modificar o cancelar el registro. Si el usuario detecta que su cuenta ha sido comprometida o suplantada, deberá comunicarse inmediatamente con “Unycos” a fin de que se puedan adoptar las medidas oportunas.</Paragraph>
              <Paragraph>Serán eliminados todos los perfiles que contengan: lenguaje vulgar u ofensivo; sugerencias o fotos de perfil de tipo sexual; contenido abusivo, agresivo, violento o que vulnere los derechos de cualquier persona o grupo de personas.</Paragraph>
              <Paragraph>El usuario podrá hacer un “feedback” del curso y la suscripción. Se prohíbe el uso de comentarios ofensivos e inapropiados. “Unycos” se reserva el derecho de eliminar mensajes ofensivos.</Paragraph>
              <Paragraph>Aunque estamos completamente orgullosos de nuestros cursos de categoría mundial, siempre pueden ocurrir situaciones inesperadas. “Unycos” se reserva el derecho de cancelar, interrumpir o reprogramar cualquier curso o modificar su contenido, o el valor numérico o peso de cualquier tarea, cuestionario o método evaluación, así como todo el contenido de la suscripción.</Paragraph>
              <Paragraph>Grupo M Contigo, SL, se reserva a realizar ofertas promocionales de los cursos y la suscripción. Un usuario puede también estar suscrito y comprar de forma individual cada curso.</Paragraph>

              <Heading type="h5">7. Política de privacidad</Heading>
              <Paragraph>“Unycos” y GRUPO M CONTIGO, SL, cumplen con toda la normativa de privacidad y protección de datos. Todo lo relativo a la política de protección de datos se encuentra recogido en el documento de política de privacidad.</Paragraph>

              <Heading type="h5">8. Responsabilidad</Heading>
              <Paragraph>“Unycos” y GRUPO M CONTIGO, SL., no asumen responsabilidad alguna por el contenido del curso. Solo sirve de medio divulgativo a nivel digital. “Unycos” reconoce la libertad de cátedra y de contenido por parte de los profesores. Los certificados son expedidos de forma totalmente gratuita y en formato digital por “Unycos” una vez se ha terminado satisfactoriamente el curso en línea y superado el test del curso.</Paragraph>

              <Heading type="h5">9. Legislación aplicable y jurisdicción</Heading>
              <Paragraph>Las contrataciones de la suscripción de los cursos de “Unycos” se someten a la legislación española. En caso de conflictos, el fuero aplicable será el de los Juzgados o Tribunales de Salamanca, España.</Paragraph>
            </Card>
          </Col>
        </Row>
      </Container>
      <UnycosFooter className="hidden-lg" showHideElements={{ social: true, cta: false }} />
    </UnycosLayout>
  </PageWrapper>
);

export default UnycosTerminos;
