import React from 'react';

import {
  PageWrapper,
  Container,
  Card,
  Row,
  Col,
  Box,
  Heading,
  Image,
  Icon,
  Paragraph,
  Button
} from 'base-ui';

const data = {
  icon: 'money-bill-wave',
  title: 'Estamos procesando tu pago',
  subtitle: 'Esta operación puede tardar varios minutos, pero normalmente es cuestión de segundos si todo esta bien con tu pago.',
  label: 'CTA',
  link: '#'
};

const PaymentProcessing = () => (
  <PageWrapper config={{ title: 'Consultation Payment Processing', bodyClasses: 'consultation' }} className="wrap-overflow">

    <Box tag="main" id="site-main">

      <Container fluid wrap>
        <Card className="card-loading">
          <Row className="middle-xs center-xs">
            <Col xs="12" md="6" lg="7">
              <Icon prefix="fas" name={data.icon} className="pulsate aqua-color loading-icon-animate" />
              <Heading type="h2" className="font-size-big mediumgray-color align-center">{data.title}</Heading>
              <Paragraph className="align-center font-size-normal mediumgray-color padding-normal padding-bottom-medium without-margin">{data.subtitle}</Paragraph>
              {data.label !== '' && (
                <Button href={data.link} size="normal" color="aqua" textColor="white" borderColor="aqua" className="display-block">{data.label}</Button>
              )}
            </Col>
            <Col xs="12" md="6" lg="5" className="align-center">
              <Image src="/img/lmem/image_page_loading.jpg" alt="" className="margin-top-x-medium margin-bottom-x-medium" />
            </Col>
          </Row>
        </Card>
      </Container>

    </Box>
    {/* end main section */}
  </PageWrapper>
);


export default PaymentProcessing;
