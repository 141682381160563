import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Box,
  Image,
  Container,
  Row,
  Col
} from 'base-ui';

import UnycosPresaleRibbon from './UnycosPresaleRibbon';

const backgroundImage = './img/unycos/hero/hero-mireia.jpg';

const UnycosSingleHero = (props) => {
  const {
    className,
    presale
  } = props;
  return (
    <Box id="unycos-single-hero" className={className}>
      <Box className="unycos-single-hero">
        <Container fluid wrap>
          <Row className="bottom-xs position-relative">
            <Box className="thumb" style={{ backgroundImage: `url(${backgroundImage})` }}>
              <Image src={backgroundImage} alt="title" />
            </Box>
            <Col xs="12">
              <Box className="caption center-xs align-center">
                <Heading type="h2" size="x-big" textColor="white" className="teacher-name normal-font text-uppercase">
                Mireia Belmonte
                </Heading>
                <Heading type="h3" size="big" textColor="white" className="text-uppercase course-name normal-font">
                  <Box tag="span" className="teaches">enseña</Box>
                  <Box tag="span">Natación</Box>
                </Heading>
                { presale && <UnycosPresaleRibbon ribbonText="Preventa" /> }
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </Box>
  );
};

UnycosSingleHero.defaultProps = {
  className: '',
  presale: false
};

UnycosSingleHero.propTypes = {
  className: PropTypes.string,
  presale: PropTypes.bool
};

export default UnycosSingleHero;
