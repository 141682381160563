import React, { useState } from 'react';

// Base-UI Components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  InputField,
  Label,
  Image,
  Button,
  NavLink
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogHero from '../../components/Blog/BlogHero';
import BlogFooterOffices from '../../components/Blog/BlogFooterOffices';

const pageConfig = {
  product: 'Mejor con Salud',
  title: 'Suscríbase a nuestra Newsletter',
  meta: [
    { name: 'description', content: 'Te contamos las últimas noticias sobre Salud, Nutrición y Bienestar.' }
  ]
};

const BlogNewsletter = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`
      Email: ${email},
    `);
  };

  return (
    <React.Fragment>
      <BlogMenu />
      <PageWrapper config={pageConfig}>
        <Box className="page-template-newsletter">
          <Box id="newsletter-hero">
            <BlogHero
              title="Te contamos las últimas noticias sobre Salud, Nutrición y Bienestar."
              backgroundImage="./img/newsletter-hero.jpg"
              className="hero"
            />
          </Box>
          <Box className="full-width-bg small main-bg big">
            <Container fluid wrap>
              <Row>
                <Col xs="12">
                  <Box className="special-form" style={{ margin: '0' }}>
                    <Heading type="h2" className="font-x-big white-color align-center" style={{ margin: '0 0 15px 0' }}>
                      Newsletter
                    </Heading>
                    <form onSubmit={handleSubmit} className="row">
                      <Col xs="12" sm="8" md="9">
                        <Image src="./img/icon-envelope.svg" alt="Email Icon" className="icon envelope" />
                        <InputField
                          name="name"
                          value={email}
                          required
                          type="email"
                          full
                          big
                          placeholder="Correo electrónico"
                          onChange={(v) => setEmail(v)}
                        />
                      </Col>
                      <Col xs="12" sm="4" md="3">
                        <Button
                          type="submit"
                          tag="button"
                          color="secondary"
                          textColor="white"
                          borderColor="secondary"
                          size="big"
                          full
                        >
                          Suscribirse
                        </Button>
                      </Col>
                      <Col xs="12">
                        <Box className="white-color" style={{ marginTop: '10px' }}>
                          <InputField
                            id="c1"
                            type="checkbox"
                            required
                          />
                          <Label inputId="c1">
                            He leído y acepto la
                            {' '}
                            <NavLink to="/page" className="white-color">política de privacidad</NavLink>
                          </Label>
                        </Box>
                      </Col>
                    </form>
                  </Box>
                </Col>
              </Row>
            </Container>
          </Box>
          <BlogFooterOffices />
        </Box>
      </PageWrapper>
    </React.Fragment>
  );
};

export default BlogNewsletter;
