import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  InputField,
  Label,
  Paragraph,
  NavLink
} from 'base-ui';

// Components
import MenuAccount from '../../../components/Consultation/MenuAccount';

const AccountSecurity = () => (
  <PageWrapper config={{ title: 'Consultation Accout Security', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section" className="consultation-account">
        <Container container fluid wrap>
          <Row className="center-xs">
            <Col xs="12" sm="4">
              <MenuAccount />
            </Col>
            <Col xs="12" sm="8">
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Cambiar contraseña</Box>
                </Box>
                <Row>
                  <form>
                    <Col xs="12">
                      <InputField type="password" full={false} label="Contraseña anterior" className="input" />
                    </Col>
                    <Col xs="6">
                      <InputField type="password" full={false} label="Nueva contraseña" className="input" />
                    </Col>
                    <Col xs="6">
                      <InputField type="password" full={false} label="Repetir nueva contraseña" className="input" />
                    </Col>
                    <Col xs="12">
                      <NavLink to="#" className="btn main-bg white-color main-border">
                        Actualizar
                      </NavLink>
                    </Col>
                  </form>
                </Row>
              </Card>
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Notificaciones de inicio de sesión</Box>
                </Box>
                <Box>
                  <InputField
                    id="c1"
                    type="checkbox"
                    required
                  />
                  <Label inputId="c1">
                    Activar las notificaciones de inicio de sesión
                    <Paragraph>Las notificaciones de inicio de sesión son una medida de seguridad adicional. Si las activas, te informaremos cada vez que se produzcan accesos a tu cuenta de Airbnb desde un nuevo navegador. Esto te permitirá mantener tu cuenta a salvo</Paragraph>
                  </Label>
                  <NavLink to="#" className="btn main-bg white-color main-border">
                    Guardar
                  </NavLink>
                </Box>
              </Card>
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Historial de inicio de sesión</Box>
                </Box>
                <Box>
                  <table className="full">
                    <tr>
                      <th>Navegador/Dispositivo</th>
                      <th>Ubicacióon</th>
                      <th>Actividad reciente</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th>
                        <strong>Chrome</strong>
                        <br />
                        Windows 10
                      </th>
                      <th>Colombia</th>
                      <th>Cerca de 6 horas atrás</th>
                      <th>
                        <NavLink to="#" className="red-color">Cerrar sesión</NavLink>
                      </th>
                    </tr>
                  </table>
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default AccountSecurity;
