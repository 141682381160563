import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Box,
  Row,
  Col,
  Heading
} from 'base-ui';

import UnycosContentCourse from './UnycosContentCourse';

const UnycosSingleCourses = (props) => {
  const {
    className
  } = props;
  return (
    <Box className={`margin-top-medium margin-bottom-medium ${className}`}>
      <Container fluid wrap>
        <Row>
          <Col xs="12">
            <Heading type="h3" className="center-xs letter-spacing__normal normal-font text-uppercase white-color font-size-x-medium margin-bottom-medium">Más cursos</Heading>
          </Col>
          <Col xs="12" sm="6" md="4" className="margin-bottom-small">
            <UnycosContentCourse image="./img/unycos/courses/marcus-big-slider.png" teacher="Marcus Cooper" course="piragüismo" />
          </Col>
          <Col xs="12" sm="6" md="4" className="margin-bottom-small">
            <UnycosContentCourse image="./img/unycos/courses/ismael-big-slider.jpg" teacher="ISMAEL CALA" course="DESARROLLO PERSONAL" />
          </Col>
          <Col xs="12" sm="4" className="margin-bottom-small hidden-md">
            <UnycosContentCourse image="./img/unycos/courses/lewis-big-slider.jpg" teacher="LEWIS AMARANTE" course="MAQUILLAJE" />
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

UnycosSingleCourses.defaultProps = {
  className: ''
};

UnycosSingleCourses.propTypes = {
  className: PropTypes.string
};

export default UnycosSingleCourses;
