import React from 'react'

import {
  Container,
  Row,
  Col,
  Box,
  Paragraph,
	Image,
	Heading
} from 'base-ui';

const MainFeaturesTwo = (props) => {
	return(
		<Box tag="section" className={props.className + ' main-features second-version titles-bg margin-bottom-normal'}>
			<Container fluid wrap>
				<Row>
					<Col className="feature wow fadeInUp margin-top-medium margin-bottom-small" data-wow-delay="0.3s" xs="12" md="4">
						<Image src="./img/icon-globe.svg" alt="icon" className="icon" />
						<Heading type="h4" className="label without-margin white-color">Curso 100% online</Heading>
						<Paragraph className="desc white-color font-size-small without-margin-top">Cursos con acceso 24 horas, los cursos empiezan y acaban cuando tú quieras.</Paragraph>
					</Col>
					<Col className="feature wow fadeInUp margin-top-medium margin-bottom-small" data-wow-delay="0.6s" xs="12" md="4">
						<Image src="./img/icon-checklist.svg" alt="icon" className="icon" />
						<Heading type="h4" className="label without-margin white-color">Donde quieras</Heading>
						<Paragraph className="desc white-color font-size-small without-margin-top">Puedes realizar los cursos desde cualquier dispositivo con acceso a internet.</Paragraph>
					</Col>
					<Col className="feature wow fadeInUp margin-top-medium margin-bottom-small" data-wow-delay="0.9s" xs="12" md="4">
						<Image src="./img/icon-responsive.svg" alt="icon" className="icon" />
						<Heading type="h4" className="label without-margin white-color">Certificado</Heading>
						<Paragraph className="desc white-color font-size-small without-margin-top">Completa el curso y recibe un certificado en tu correo eletrónico.</Paragraph>
					</Col>
				</Row>
			</Container>  
		</Box>
	)
}

export default MainFeaturesTwo;