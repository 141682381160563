import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Icon,
  Container,
  Row,
  Col,
	Box,
  Breadcrumbs,
  PageWrapper,
  Card,
} from 'base-ui';

import ForumList from '../../../components/Forum/ForumList';

class Forum extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialItems: [
        {
          id: '1',
          module: 'Módulo 1',
          title: 'Título del módulo',
          stats: {
						discussionNumber: '456',
					}
				},
				{
          id: '2',
          module: 'Módulo 2',
					title: 'Título del módulo 2',
					stats: {
						discussionNumber: '12',
					}
				},
				{
          id: '3',
          module: 'Módulo 3',
          title: 'Título del módulo 3',
          stats: {
						discussionNumber: '298',
					}
        },
			],
			items: []
		};
  }
  
  componentDidMount() {
    this.setState({items: this.state.initialItems})
	}
	render() {
		return(
			<PageWrapper config={{ title: 'Courses forum' }}>
				<Box>
					<Container fluid wrap>
						<Row className="center-xs">
							<Col xs="12">
								<Box>
									<Breadcrumbs className="margin-bottom-small">
										<Link to="."><Icon prefix="fas" name="home" /> Home</Link>
										<Link to=".">Cursos</Link>
										<Link to=".">Mindfulness para la vida cotidiana</Link>
										<Box tag="span">Foro</Box>
									</Breadcrumbs>
									<hr />
								</Box>
							</Col>
							<Col xs="12" md="10">
								<Card title="Módulos del curso">
									<ForumList
										items={this.state.items}
									/>
								</Card>
							</Col>
						</Row>
					</Container>
				</Box>
			</PageWrapper>
		)
	}
};

export default Forum;
