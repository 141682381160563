import React from 'react';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';

import {
  Icon,
  Button,
  Container,
  Row,
  Col,
  Box,
  Heading,
  InputSelect,
  InputField,
  Breadcrumbs,
  LoadMore,
  PageWrapper,
} from 'base-ui';

import Loader from '../../components/AppFooter/Loader';

const RecentCourses = loadable(() => import('../../components/Courses/RecentCourses'));

const CoursesArchive = () => (
  <PageWrapper config={{ title: 'Courses archive' }}>
    <Box>
      <Container fluid wrap>
        <Row>
          <Col xs="12">
            <Box>
              <Breadcrumbs className="margin-bottom-small">
                <Link to="."><Icon prefix="fas" name="home" /> Home</Link>
                <Link to=".">Cursos</Link>
                <Box tag="span">Todos</Box>
              </Breadcrumbs>
              <Heading type="h1" className="font-size-x-medium main-font without-margin">
                <Box tag="span" className="lightweight-font">Cursos:</Box> Todos
              </Heading>
              <hr />
              <Row>
                <Col xs="12" md="6" className="center-xs start-md">
                  <Button tag="button" type="button" size="medium" color="white" borderColor="grey" textColor="text">
                    <Icon prefix="fas" name="th-large" />
                  </Button>
                  <Button tag="button" type="button" size="medium" color="white" borderColor="grey" textColor="text">
                    <Icon prefix="fas" name="th-list" />
                  </Button>
                  <InputSelect
                    full="false"
                    className="without-shadow"
                    options={[
                      { value: 'all', label: 'Todos los cursos' },
                    ]}
                  />
                </Col>
                <Col xs="12" md="6" className="center-xs end-md">
                  <InputSelect
                    full="false"
                    className="without-shadow"
                    options={[
                      { value: 'all', label: 'Más recientes' },
                    ]}
                  />
                  <Box className="display-inline">
                    <InputField placeholder="Buscar por nombre..." color="white" full="false" borderColor="grey" textColor="text" type="text" className="without-shadow" />
                    <Button tag="button" color="main" borderColor="main" textColor="white">
                      <Icon prefix="fas" name="search" />
                    </Button>
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>
          <Col xs="12">
            <RecentCourses fallback={<Loader />} />
          </Col>
        </Row>
      </Container>
      <Container fluid wrap className="wow fadeIn">
        <LoadMore color="main">
          Load More
        </LoadMore>
      </Container>
    </Box>
  </PageWrapper>
);

export default CoursesArchive;
