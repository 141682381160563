import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  InputField,
  Modal,
  Row
} from 'base-ui';

class UnycosMyCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalState: false,
      destinationEmail: '',
      courseLink: './assets/images/destaque-curso-lewis.jpg',
      courses: [
        {
          id: 1,
          title: 'Maquillaje',
          professional: 'Lewis Amarante',
          image: 'assets/images/destaque-curso-lewis.jpg',
          progress: '0%',
        },
        {
          id: 2,
          title: 'Desarrollo Personal',
          professional: 'Ismael Cala',
          image: 'assets/images/destaque-curso-ismael3.jpg',
          progress: '75%',
        },
        {
          id: 3,
          title: 'Piragüismo',
          professional: 'Marcus Cooper Walz',
          image: 'assets/images/marcus.png',
          progress: '100%',
        }
      ]
    };
  }

  openModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalState: true });
  }

  closeModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalState: false });
  }

  render() {
    const {
      courses, modalState, destinationEmail, courseLink
    } = this.state;

    return (
      <section id="my-courses" className="my-courses">
        <div className="margin-bottom-medium center">
          <h2 className="my-courses__title">Bienvenido a Unycos</h2>
          <h4 className="my-courses__subtitle">Selecciona un curso para comenzar</h4>
        </div>
        <div className="your-courses">
          {courses.length && courses.map((course) => (
            <article className="course" key={course.id}>
              <div className="thumb">
                <div className="thumb-wrap" style={{ backgroundImage: `url(${course.image})` }}></div>
              </div>
              <div className="caption">
                <h2 className="title">{course.professional}</h2>
                <p className="name">
                  <em>Enseña</em>
                  {' '}
                  <strong>{course.title}</strong>
                </p>
                <div className="btns">
                  {course.progress === '100%' && (
                    <Link to="/unycos/my-account/request-certificate" className="btn square iconed">
                      <i className="fas fa-graduation-cap"></i>
                      <span>Solicitar el Certificado</span>
                    </Link>
                  )}
                  <Link to="#" className="btn square iconed">
                    <i className="fas fa-gift"></i>
                    <span>Regalar</span>
                  </Link>
                  <Button onClick={(e) => this.openModal(e)} className="square iconed">
                    <i className="fas fa-share-alt-square"></i>
                    <span>Recomendar</span>
                  </Button>
                  <Link to="#" className="btn square iconed">
                    <i className="fas fa-file-download"></i>
                    <span>Workbook</span>
                  </Link>
                </div>
                <div className="actions">
                  <Link to="my-account/start-course" className="play-button">
                    <div className="progress-track" style={{ '--progress-percent': course.progress }} data-progress={course.progress}></div>
                    <span className="play-button__text play-button--continue">
                      {course.progress !== '0%' && (
                        <b>{course.progress}</b>
                      )}
                      {course.progress === '0%' && <span>Comezar</span>}
                      {course.progress === '100%' && <span>Ver Curso</span>}
                      {course.progress !== '0%' && course.progress !== '100%' && <span>Seguir</span>}
                    </span>
                    <i className="fas fa-play"></i>
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
        <Modal
          isOpen={modalState}
          appElement="#root"
          idModal="modal-share-course"
          className="modal-unycos fade-in"
          closeIcon={false}
        >
          <Box className="modal-header">
            <NavLink to="#" onClick={(e) => this.closeModal(e)} id="bt-close-modal"><Image src="./img/unycos/icons/icon_times.svg" alt="Icon Times" /></NavLink>
            <Heading type="h2">Recomendar Curso</Heading>
          </Box>
          <Box className="modal-content">
            <Heading type="h1">
              <span>Lewis Amarante</span>
              {' '}
              <em>Enseña</em>
              {' '}
              <strong>Fundamentos de Maquillaje</strong>
            </Heading>

            <Box className="input-group d--flex ai--center jc--center margin-bottom-big">
              <InputField
                id="destination-email"
                name="destination-email"
                type="text"
                label="Recomendar por Correo electrónico"
                full
                big
                value={destinationEmail}
                placeholder="correo@electronico.com.br"
                onChange={(v) => this.setState({ destinationEmail: v })}
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
              />
              <Button className="bt-cta"><span>Enviar</span></Button>
            </Box>

            <Box className="social-share-group">
              <span className="modal-label">Recomendar por redes sociales</span>

              <Row className="d--flex ai--center jc--center fw--wrap">
                <Button textColor="white" className="social-btn facebook">
                  <Icon name="facebook-f" prefix="fab" className="social-icon" />
                  Facebook
                </Button>
                <Button textColor="white" className="social-btn twitter">
                  <Icon name="twitter" prefix="fab" className="social-icon" />
                  Twitter
                </Button>
              </Row>
              <Row className="d--flex ai--center jc--center fw--wrap">
                <Button textColor="white" className="social-btn whatsapp">
                  <Icon name="whatsapp" prefix="fab" className="social-icon" />
                  Whatsapp
                </Button>
                <Button textColor="white" className="social-btn telegram">
                  <Icon name="telegram" prefix="fab" className="social-icon" />
                  Telegram
                </Button>
              </Row>
            </Box>

            <Box className="input-group copy-share-group d--flex ai--center jc--center margin-bottom-big">
              <InputField
                id="share-copy"
                name="share-copy"
                type="text"
                label="Copiar link"
                full
                big
                readonly
                value={courseLink}
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
              />
              <Button className="bt-cta"><span>Copiar</span></Button>
            </Box>
          </Box>
        </Modal>
      </section>

    );
  }
}

export default UnycosMyCourses;
