import React from 'react';

import {
  Container, Box, Modal, Button, Icon, Heading, Paragraph, InputField, NavLink,
} from 'base-ui';

class LoginModal2fa extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  render() {
    const { open } = this.state;
    return (
      <div id="wrapper">
        <Container fluid wrap className="container-single">
          <main id="site-main">
            <div className="row">
              <div className="col-xs-12">
                <Button onClick={this.openModal} style={{ margin: '3rem auto', display: 'table' }}>
                  Open Modal 2FA
                </Button>
                <Modal
                  isOpen={open}
                  closeModal={this.closeModal}
                  appElement="#root"
                  closeIcon
                  height="min-content"
                  styles={{
                    overlay: {
                      position: 'fixed',
                      height: '100%',
                      overflow: 'auto'
                    },
                    content: {
                      position: 'absolute',
                      width: '420px',
                      backgroundColor: '#fff',
                      borderColor: 'transparent',
                      padding: '0.5rem',
                    }
                  }}
                >
                  <Box className="padding-normal content-2fa">
                    <Heading type="h2" className="mediumgray-color font-size-x-medium align-center">
                      <Icon prefix="fas" name="user-shield" className="padding-bottom-small mediumgray-color align-center" />
                      <br />
                      Autenticación 2FA
                    </Heading>
                    <Paragraph className="mediumgray-color font-normal line-height-big align-center padding-top-x-small padding-bottom-small">
                      Para continuar, escribe el código de verificación de 6 dígitos enviado al teléfono con terminación
                      {' '}
                      <span id="txt-cell-number">XXXX</span>
                      .
                    </Paragraph>
                    <hr />
                    <form className="align-center padding-top-small padding-bottom-small">
                      <InputField className="font-size-x-big secondary-font mediumgray-color input-error" value="8" />
                      <InputField className="font-size-x-big secondary-font mediumgray-color input-error" value="3" />
                      <InputField className="font-size-x-big secondary-font mediumgray-color" />
                      <InputField className="font-size-x-big secondary-font mediumgray-color" />
                      <InputField className="font-size-x-big secondary-font mediumgray-color" />
                      <InputField className="font-size-x-big secondary-font mediumgray-color" />
                    </form>
                    <Box className="txt-error padding-bottom-small">
                      <Icon prefix="fas" name="exclamation-circle" />
                      <Paragraph className="font-size-small line-height-x-medium ">
                        <strong>Código incorrecto</strong>
                        <br />
                        ¡Tranquilo! Inténtalo de nuevo o reenvía el código
                      </Paragraph>
                    </Box>
                    <Button size="normal" color="secondary" textColor="white" borderColor="secondary">Continuar</Button>
                    <Paragraph className="lightweight-font mediumgray-color font-size-small align-center margin-top-medium">
                      ¿No puedes recibir mensajes?
                      <br />
                      Envía el código
                      {' '}
                      <NavLink className="normal-font">al email</NavLink>
                      {' '}
                      o prueba
                      {' '}
                      <NavLink className="normal-font">otra manera de iniciar sesión</NavLink>
                      .
                    </Paragraph>
                  </Box>
                </Modal>
              </div>
            </div>
          </main>
        </Container>
      </div>
    );
  }
}

export default LoginModal2fa;
