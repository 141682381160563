import React, { useState } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Search,
  Icon,
  NavLink,
  Breadcrumbs
} from 'base-ui';

// Components
import ResultConsult from '../../../components/Consultation/ResultConsult';
import ResultProfessional from '../../../components/Consultation/ResultProfessional';

// Data
const resultsConsults = [
  {
    id: 1,
    thumb: './img/dummy/service-1.png',
    title: 'Terapia de pareja',
    price: '75€',
    type: 'Consulta',
    stars: 3,
    opinions: '3 valoracion',
  },
  {
    id: 2,
    thumb: './img/dummy/service-2.png',
    title: 'Trastorno del pánico',
    price: '70€',
    type: 'Consulta',
    stars: 5,
    opinions: '8 valoracion'
  },
  {
    id: 3,
    thumb: './img/dummy/service-3.png',
    title: 'Niños y adolescentes felices',
    price: '80€',
    type: 'Consulta',
    stars: 4,
    opinions: '10 valoracion',
  },
  {
    id: 4,
    thumb: './img/dummy/service-1.png',
    title: 'Trastorno del pánico',
    price: '85€',
    type: 'Consulta',
    stars: 3,
    opinions: '2 valoracion',
  },
  {
    id: 5,
    thumb: './img/dummy/service-2.png',
    title: 'Resolver problemas pacificamente',
    price: '65€',
    type: 'Consulta',
    stars: 2,
    opinions: '',
  },
  {
    id: 6,
    thumb: './img/dummy/post-9.png',
    title: 'Resolver problemas pacificamente',
    price: '65€',
    type: 'Consulta',
    stars: 3,
    opinions: '10 valoracion',
  },
];
const resultsProfessionals = [
  {
    id: 1,
    thumb: './img/dummy/person-1.png',
    professionalArea: 'Psicólogo',
    price: '75€',
    type: 'Consulta',
    professionalName: 'Julio Americo',
  },
  {
    id: 2,
    thumb: './img/dummy/person-2.png',
    professionalArea: 'Psicólogo',
    price: '80€',
    type: 'Consulta',
    professionalName: 'Bella Etels',
  },
  {
    id: 3,
    thumb: './img/dummy/person-3.png',
    professionalArea: 'Psicólogo',
    price: '70€',
    type: 'Consulta',
    professionalName: 'Otto Travis',
  },
  {
    id: 4,
    thumb: './img/dummy/person-4.png',
    professionalArea: 'Psicólogo',
    price: '65€',
    type: 'Consulta',
    professionalName: 'Pablo Americo',
  },
  {
    id: 5,
    thumb: './img/dummy/person-5.png',
    professionalArea: 'Psicólogo',
    price: '75€',
    type: 'Consulta',
    professionalName: 'Julia Americo',
  }
];


const SearchPage = () => {
  const [search, setSearch] = useState({
    searchOn: false,
    searchError: false,
    searchQuery: ''
  });
  const eventHandler = (data) => {
    setSearch(data);
  };
  const resultSearch = () => {
    let title = '';
    if (!search.searchOn) {
      title = 'Conoce a Nuestros Profesionales';
    }
    if (search.searchOn && !search.searchError) {
      title = 'Consultas econtradas';
    }
    if (search.searchError) {
      title = 'Quizás te pueda gustar';
    }
    const DataBlock = () => (
      <Box className="consultations">
        { (!search.searchOn) && (
          resultsProfessionals.map((result) => (
            <ResultProfessional
              key={result.id}
              data={{
                thumb: result.thumb,
                professionalArea: result.professionalArea,
                price: result.price,
                type: result.type,
                professionalName: result.professionalName,
              }}
            />
          ))
        )}
        { (search.searchOn && !search.searchError) && (
          resultsConsults.map((result) => (
            <ResultConsult
              key={result.id}
              data={{
                thumb: result.thumb,
                title: result.title,
                price: result.price,
                type: result.type,
                stars: result.stars,
                opinions: result.opinions,
              }}
            />
          ))
        )}
        { (search.searchError) && (
          resultsConsults.map((result) => (
            <ResultConsult
              key={result.id}
              data={{
                thumb: result.thumb,
                title: result.title,
                price: result.price,
                type: result.type,
                stars: result.stars,
                opinions: result.opinions,
              }}
            />
          ))
        )}
      </Box>
    );
    return (
      <React.Fragment>
        <Box>
          <Box tag="span" className="align-center titles-color bold-font font-m-big padding-big display-block">
            {title}
          </Box>
        </Box>
        <DataBlock />
      </React.Fragment>
    );
  };

  return (
    <PageWrapper config={{ title: 'Consultation Search', bodyClasses: 'consultation search' }}>

      <Box tag="main" id="site-main" className="with-thumbs">

        <Box tag="section">
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Breadcrumbs>
                  <NavLink href="/consultation">
                    <Icon prefix="fas" name="home" className="main-color" />
                    {' '}
                    Inicio
                  </NavLink>
                  <Box tag="span">Búsqueda</Box>
                </Breadcrumbs>
              </Col>
            </Row>
          </Container>
        </Box>

        <Box tag="section">
          <Container fluid wrap>
            <Row>
              <Col xs="12">

                <Search
                  titleIni="Búsqueda"
                  titleResult="Resultados para"
                  titleNoResult="No hemos encontrado resultados para"
                  gotoText="Puedes intentar buscando algo más o puedes"
                  gotoLabel="regresar al inicio"
                  placeholder="Búsqueda por..."
                  onChange={eventHandler}
                >
                  {resultSearch()}
                </Search>

              </Col>
            </Row>
          </Container>
        </Box>

      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};

export default SearchPage;
