import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';

import {
  Icon,
  Button,
  Container,
  Row,
  Col,
  Card,
  Box,
  Heading,
  InputSelect,
  InputField,
  Paragraph,
  Image,
  Breadcrumbs,
  PageWrapper,
} from 'base-ui';

import Loader from '../../components/AppFooter/Loader';

const RecentCourses = loadable(() => import('../../components/Courses/RecentCourses'));

class CoursesCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialItems: [
        {
          title: 'Reinvéntate',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/reinventate/img/shutterstock_1171469926.jpg',
          teacher: 'Agustín Andrade',
          duration: '15 módulos - 7 horas',
        },
        {
          title: 'Oratoria moderna',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-oratoria-moderna/img/oratoria.jpg',
          teacher: 'Ángel Lafuente Zorrilla',
          duration: '15 módulos - 7 horas',
        },
        {
          title: 'Identificar, gestionar y convivir con gente tóxica',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-identificar-gestionar-y-convivir-con-gente-toxica/img/gente_toxica.jpg',
          teacher: 'Tomás  Navarro',
          duration: '15 módulos - 7 horas',
        },
        {
          title: 'Reinvéntate',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/reinventate/img/shutterstock_1171469926.jpg',
          teacher: 'Agustín Andrade',
          duration: '15 módulos - 7 horas',
        },
        {
          title: 'Oratoria moderna',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-oratoria-moderna/img/oratoria.jpg',
          teacher: 'Ángel Lafuente Zorrilla',
          duration: '15 módulos - 7 horas',
        },
        {
          title: 'Identificar, gestionar y convivir con gente tóxica',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-identificar-gestionar-y-convivir-con-gente-toxica/img/gente_toxica.jpg',
          teacher: 'Tomás  Navarro',
          duration: '15 módulos - 7 horas',
        },
      ],
      items: [],
    };
  }

  componentDidMount() {
    this.setState({
      items: this.state.initialItems
    });
  }

  render() {
    return (
      <PageWrapper config={{ title: 'Courses category' }}>
        <Box>
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Box size="medium">
                  <Breadcrumbs className="margin-bottom-small">
                    <Link to=".">
                      <Icon prefix="fas" name="home" />
                      {' '}
                      Home
                    </Link>
                    <Link to=".">Cursos</Link>
                    <Box tag="span">Psicología</Box>
                  </Breadcrumbs>
                  <Heading type="h1" className="font-size-x-medium main-font without-margin">
                    <Box tag="span" className="lightweight-font">Cursos:</Box>
                    {' '}
Psicología
                  </Heading>
                  <Paragraph className="margin-top-normal">El desarrollo de aptitudes es clave para ser más competente y feliz en la vida. En esta sección encontrarás los mejores cursos para fortalecer tu rendimiento cognitivo, triunfar en tu trabajo y enriquecer tus relaciones sociales y afectivas. Mejorar tus aptitudes personales es ahora más fácil que nunca.</Paragraph>
                  <hr />
                  <Row>
                    <Col xs="12" md="6" className="center-xs start-md">
                      <Button tag="button" type="button" size="medium" color="white" borderColor="grey" textColor="text">
                        <Icon prefix="fas" name="th-large" />
                      </Button>
                      <Button tag="button" type="button" size="medium" color="white" borderColor="grey" textColor="text">
                        <Icon prefix="fas" name="th-list" />
                      </Button>
                      <InputSelect
                        full={false}
                        className="without-shadow"
                        options={[
                          { value: 'all', label: 'Todos los cursos' },
                        ]}
                        style={{
                          marginLeft: '5px',
                          width: 'initial',
                          verticalAlign: 'bottom'
                        }}
                      />
                    </Col>
                    <Col xs="12" md="6" className="center-xs end-md">
                      <InputSelect
                        full={false}
                        className="without-shadow"
                        options={[
                          { value: 'all', label: 'Más recientes' },
                        ]}
                        style={{
                          marginRight: '5px',
                          width: 'initial',
                          verticalAlign: 'top'
                        }}
                      />
                      <Box
                        className="display-inline"
                      >
                        <InputField
                          placeholder="Buscar por nombre..."
                          color="white"
                          full={false}
                          borderColor="grey"
                          textColor="text"
                          type="text"
                          className="without-shadow"
                          style={{
                            padding: '6px 12px',
                          }}
                        />
                        <Button tag="button" color="main" borderColor="main" textColor="white" style={{ padding: '6px 10px' }}>
                          <Icon prefix="fas" name="search" />
                        </Button>
                      </Box>
                    </Col>
                  </Row>
                </Box>
                <br />
                <Card color="white" size="medium" borderColor="main">
                  <a href=".">
                    <Row className="middle-xs between-md">
                      <Col xs="12" sm="5" md="4" className="first-xs last-md margin-bottom-small">
                        <Image className="full" src="https://content.lamenteesmaravillosa.com/courses/curso-aprende-a-gestionar-tu-tiempo/img/gestion_del_tiempo.jpg" alt="Gestión del tiempo" />
                      </Col>
                      <Col xs="12" sm="7">
                        <Box tag="small" className="white-bg font-size-small main-color bold-font box-shadow medium"><strong>Curso destacado</strong></Box>
                        <Heading type="h2" size="medium" className="main-font">Aprende a gestionar tu tiempo</Heading>
                        <Box className="metas margin-bottom-medium">
                          <Box tag="span" className="meta">
                            Un curso de
                            <strong>Felix Toran</strong>
                          </Box>
                        </Box>
                        <Paragraph>El tiempo está en nuestras manos, pero a veces se nos escapa, se nos escurre como finísima arena hasta dejar ante nosotros un horizonte impreciso y muy vago donde ningún objetivo parece cumplirse. Esto puede cambiar. Gestionar el tiempo de forma adecuada es la verdadera clave para el éxito.</Paragraph>
                        <Box className="metas margin-top-small">
                          <Box tag="span" className="meta">
                            <Icon name="play-circle" />
                            {' '}
                            10 módulos - 8 horas
                          </Box>
                        </Box>
                      </Col>
                    </Row>
                  </a>
                </Card>
              </Col>
              <Col xs="12">
                <RecentCourses fallback={<Loader />} />
              </Col>
            </Row>
          </Container>
        </Box>
      </PageWrapper>
    );
  }
}

export default CoursesCategory;
