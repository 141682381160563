import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Image,
  Paragraph,
  NavLink
} from 'base-ui';

const VideoCall = () => (
  <PageWrapper config={{ title: 'Consultation Video Call', bodyClasses: 'consultation' }} className="wrap-overflow">

    <Box tag="main" id="site-main">

      <Box tag="section" className="videocall align-center padding-bottom-x-big padding-top-x-big">
        <Container fluid wrap>
          <Row>
            <Col xs="12" className="box-video-content">
              <Paragraph className="titles-color margin-bottom-big">Estás a punto de unirte a una reunión de video</Paragraph>
              <Box className="box-video position-relative margin-bottom-normal">
                <video>
                  <source src="http://techslides.com/demos/sample-videos/small.webm" type="video/webm" />
                  <source src="http://techslides.com/demos/sample-videos/small.ogv" type="video/ogg" />
                  <source src="http://techslides.com/demos/sample-videos/small.mp4" type="video/mp4" />
                  <source src="http://techslides.com/demos/sample-videos/small.3gp" type="video/3gp" />
                  <track src="captions_es.vtt" kind="captions" srcLang="es" label="spanish_captions"></track>
                </video>
                <Box className="controls align-center position-absolute">
                  <NavLink to=""><Image src="/img/consultation/icons/icon_cam.svg" alt="Icon Cam" /></NavLink>
                  <NavLink to=""><Image src="/img/consultation/icons/icon_microphone.svg" alt="Icom Microphone" /></NavLink>
                  <NavLink to=""><Image src="/img/consultation/icons/icon_tools.svg" alt="Icon Tools" /></NavLink>
                </Box>
              </Box>
              <Paragraph className="titles-color margin-bottom-normal margin-top-normal">Puedes desactivar tu camara web en cualquier momento durante la videollamada</Paragraph>
              <NavLink className="btn padding-small margin-bottom-big main-bg normal-font">Empezar</NavLink>

            </Col>
          </Row>
        </Container>
      </Box>
    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default VideoCall;
