import React, { useState } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Heading,
  Icon,
  Paragraph,
  NavLink,
  InputField,
  InputSelect
} from 'base-ui';

// Components
import MenuPublish from '../../../components/Consultation/MenuPublish';

const PublishPart1 = () => {
  const [showMenuPublish, setShowMenuPublish] = useState(false);

  const handlerClickMenuPublish = () => {
    setShowMenuPublish(!showMenuPublish);
  };

  return (
    <PageWrapper config={{ title: 'Consultation Profile', bodyClasses: `consultation publish ${showMenuPublish ? 'open-aside' : ''}` }}>

      <Box tag="main" id="site-main">

        <MenuPublish atualPage="1" toggleAction={handlerClickMenuPublish} />

        <Box tag="section" id="single" className="single-publish">
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Card>
                  <Heading type="h4">Título del servicio</Heading>
                  <Paragraph>Elige el título que mejor describa la consulta que quieres ofrecer. Por ejemplo, terapia de pareja, terapia infantil o terapia para la ansiedad.</Paragraph>
                  <InputField
                    name="input_title"
                    label="Título"
                    type="text"
                    big
                    placeholder="Título"
                  />
                  <Paragraph className="align-right">0/60</Paragraph>

                  <InputSelect
                    name="select_categorias"
                    label="Categorías"
                    type="text"
                    big
                    className="margin-bottom-big"
                    options={[
                      { value: '', label: 'Selecciona una opción' },
                      { value: '1', label: 'Psicología clínica' },
                      { value: '2', label: 'Psicología general sanitária' },
                      { value: '3', label: 'Pareja' },
                      { value: '4', label: 'Ansiedad y estrés' },
                      { value: '5', label: 'Depresión' },
                      { value: '6', label: 'Adicciones' },
                      { value: '7', label: 'Duelo' },
                      { value: '8', label: 'Transtornos del sueño' },
                      { value: '9', label: 'Transtornos de la alimentación' },
                      { value: '10', label: 'Adolescentes' },
                      { value: '11', label: 'Familia' },
                      { value: '12', label: 'Autoestima y habilidades sociales' },
                      { value: '13', label: 'Comunicación' },
                      { value: '14', label: 'Otras consultas' },
                      { value: '15', label: 'Autoestima' },
                      { value: '16', label: 'Niños' },
                      { value: '17', label: 'Ansiedad' },
                      { value: '18', label: 'Estrés' },
                    ]}
                  />
                  <hr className="margin-top-x-big" />

                  <NavLink to="publish-2" className="btn main-bg main-border white-color big">
                    Continuar
                    {' '}
                    <Icon prefix="fas" name="arrow-right" />
                  </NavLink>
                </Card>
              </Col>
            </Row>
          </Container>
        </Box>

      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};

export default PublishPart1;
