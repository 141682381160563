import React from 'react';
import {
  BrandHeader, DefaultHeader, Icon
} from 'base-ui';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

class AppHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: 'Beverly',
        picture: './img/dummy/author.jpg',
        link: '#',
        roles: ['*']
      },
      settings: {
        targetApp: 'store',
        hasAuth: true,
        logo: {
          icon: 'https://base.layout.mcontigo.io/img/brands/lmem-icon.svg',
          img: './img/lmem-logo.svg',
          alt: 'Mejor Con Salud',
          link: 'https://base.layout.mcontigo.io/'
        },
        brandBar: [
          {
            icon: 'https://base.layout.mcontigo.io/img/brands/icon-mejorconsalud.png',
            img: 'https://base.layout.mcontigo.io/img/brands/mejorconsalud.png',
            link: 'https://mejorconsalud.com',
            alt: 'Mejor con Salud'
          },
          {
            icon: 'https://base.layout.mcontigo.io/img/brands/icon-mcontigo.png',
            img: 'https://base.layout.mcontigo.io/img/brands/mcontigo.png',
            link: 'https://mcontigo.com',
            alt: 'MContigo'
          },
        ],
        router: {
          strategy: 'none'
        },
        accountLabels: {
          newNotifications: 'nuevas',
          settings: 'Configuraciones de la cuenta'
        },
        menus: {
          search: {
            hasSearch: true,
            actionUrl: 'https://mcontigo.com/',
            placeholderText: 'Buscar artículos...',
            queryText: ''
          },
          auth: {
            signIn: {
              label: 'Conéctate',
              action: () => this.handleSignInAction()
            },
            signUp: {
              label: 'Regístrate',
              action: () => this.handleSignInAction()
            },
            welcome: {
              label: 'Hola',
            },
          },
          account: [
            {
              url: '/office',
              label: 'Escritorio',
              visibility: {
                roles: ['*'],
                route: {}
              },
              icon: <Icon prefix="fas" name="columns" />,
            },
            {
              url: '/configuration',
              label: 'Configuración',
              visibility: {
                roles: ['*'],
                route: {}
              },
              icon: <Icon prefix="fas" name="cog" />,
            },
            {
              url: '/courses',
              label: 'Mis cursos',
              visibility: {
                roles: ['*'],
                route: {}
              },
              icon: <Icon prefix="fas" name="user-graduate" />,
            },
            {
              url: '/messages',
              label: 'Mensajes',
              visibility: {
                roles: ['*'],
                route: {}
              },
              icon: <Icon prefix="far" name="comments" />,
            },
            {
              url: '/desire-list',
              label: 'Lista de deseos',
              visibility: {
                roles: ['*'],
                route: {}
              },
              icon: <Icon prefix="far" name="heart" />,
            },
            {
              label: 'Desconectar',
              action: () => this.handleSignOutAction(),
              visibility: {
                roles: ['*'],
                route: {}
              },
              icon: <Icon prefix="fas" name="sign-out-alt" />,
            },
          ],
          topHeader: [
            {
              url: '/categories',
              label: 'Categorías',
              visibility: {
                roles: ['*'],
                route: {}
              },
              className: 'b02',
              icon: <span><i className="fas fa-list"></i></span>,
              children: [
                {
                  url: '/cursos/categorias/desarrollo-de-aptitudes/',
                  label: 'Desarrollo de Aptitudes',
                  visibility: {
                    roles: ['*'],
                    route: {}
                  },
                  icon: './img/categories-menu/desarrollo-icon.svg',
                },
                {
                  url: '/cursos/categorias/educacion/',
                  label: 'Educación',
                  visibility: {
                    roles: ['*'],
                    route: {}
                  },
                  icon: './img/categories-menu/educacion-icon.svg',
                },
                {
                  url: '/cursos/categorias/relaciones/',
                  label: 'Relaciones',
                  visibility: {
                    roles: ['*'],
                    route: {}
                  },
                  icon: './img/categories-menu/relaciones-icon.svg',
                },
                {
                  url: '/cursos/categorias/salud-y-bienestar/',
                  label: 'Salud y Bienestar',
                  visibility: {
                    roles: ['*'],
                    route: {}
                  },
                  icon: './img/categories-menu/salud-icon.svg',
                },
                {
                  url: '/cursos/categorias/autoconocimiento/',
                  label: 'Autoconocimiento',
                  visibility: {
                    roles: ['*'],
                    route: {}
                  },
                  icon: './img/categories-menu/autoconocimiento-icon.svg',
                },
                {
                  url: '/cursos/categorias/coaching/',
                  label: 'Coaching',
                  visibility: {
                    roles: ['*'],
                    route: {}
                  },
                  icon: './img/categories-menu/coaching-icon.svg',
                },
                {
                  url: '/cursos/categorias/meditacion/',
                  label: 'Meditación',
                  visibility: {
                    roles: ['*'],
                    route: {}
                  },
                  icon: './img/categories-menu/meditacion-icon.svg',
                },
                {
                  url: '/cursos/categorias/neurociencias/',
                  label: 'Neurociencias',
                  visibility: {
                    roles: ['*'],
                    route: {}
                  },
                  icon: './img/categories-menu/neurociencias-icon.svg',
                },
              ],
            },
            {
              url: '/howworks',
              label: 'Cómo funciona',
              visibility: {
                roles: ['*'],
                route: {}
              },
              className: 'item-how-works',
              icon: <span><i className="fas fa-file-alt"></i></span>
            },
            {
              url: '/help',
              label: 'Ayuda',
              visibility: {
                roles: ['*'],
                route: {}
              },
              className: 'item-consultations',
              icon: <span><i className="fas fa-comments"></i></span>
            },
          ],
          notification: {
            url: '/notification',
            label: 'Notificaciones',
            data: [],
          },
          main: [
            {
              id: 1000,
              label: 'Menu 1',
              children: [
                {
                  id: 1100,
                  label: 'Menu 1.1',
                  url: '#',
                  routerStrategy: 'none',
                },
                {
                  id: 1200,
                  label: 'Menu 1.2',
                  url: '#',
                  routerStrategy: 'none'
                }
              ]
            },
            {
              id: 2000,
              label: 'Menu 2',
              url: '#',
              routerStrategy: 'none'
            },
            {
              id: 3000,
              label: 'Menu 3',
              children: [
                {
                  id: 3100,
                  label: 'Menu 3.1',
                  url: '#',
                  routerStrategy: 'none'
                },
                {
                  id: 3200,
                  label: 'Menu 3.2',
                  url: '#',
                  routerStrategy: 'none'
                }
              ]
            },
            {
              id: 4000,
              label: 'Menu 4',
              url: '#',
              routerStrategy: 'none'
            },
            {
              id: 5000,
              label: 'Menu 5',
              url: '#',
              routerStrategy: 'none'
            }
          ]
        }
      }
    };
  }

  handleSignInAction = () => (this.setState({
    user: {
      name: 'Beverly',
      picture: './img/dummy/author.jpg',
      link: '#',
      roles: ['*']
    }
  }));

  handleSignOutAction = () => (this.setState({ user: null }));

  verifyUrl = (regexUrlArray) => {
    const { location } = this.props;
    let reply = false;

    regexUrlArray.forEach((regex) => {
      if (location.pathname.match(regex)) {
        reply = true;
      }
    });

    return reply;
  }


  render() {
    const { settings, user } = this.state;
    const regexUrlArray = [
      /consultation\//,
      /courses\//
    ];

    if (!this.verifyUrl(regexUrlArray)) {
      return (
        <React.Fragment>
          <BrandHeader
            settings={settings.brandBar}
          />
          <DefaultHeader
            user={user}
            settings={settings}
            className="lang-de"
          />
        </React.Fragment>
      );
    }
    return null;
  }
}

AppHeader.propTypes = {
  location: PropTypes.object
};

export default withRouter(AppHeader);
