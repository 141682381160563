import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Icon,
  Box
} from 'base-ui';

class UnycosAccordionItem extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label, title },
    } = this;

    return (
      <Box className={isOpen ? 'accordion-cat-item accordion-open' : 'accordion-cat-item accordion-closed'}>
        <Box className="accordion-label" onClick={onClick}>
          <Box tag="span" className="label">{title || label}</Box>
          <Box className="accordion-icon">
            {!isOpen && <Icon prefix="fas" name="plus" />}
            {isOpen && <Icon prefix="fas" name="minus" />}
          </Box>
        </Box>
        <Box className={`accordion-content ${isOpen && 'open-tab'}`}>
          {this.props.children}
        </Box>
      </Box>
    );
  }
}

export default UnycosAccordionItem;
