import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Button,
  Icon,
  Message,
  Lists,
  NavLink
} from 'base-ui';

const LastArticleSidebar = ({
  className, label, data
}) => {
  const [openBiliography, setOpenBiliography] = useState(false);

  const handleClickBiliography = () => {
    setOpenBiliography(!openBiliography);
  };

  return (
    <Box tag="section" className={`single-bibliography ${className}`}>
      <Button type="button" onClick={handleClickBiliography} color="white" textColor="titles" borderColor="grey">
        {label}
        {' '}
        <Icon prefix="fas" name={`${openBiliography ? 'minus' : 'plus'}`} />
      </Button>
      <Message className={`bibliography ${openBiliography ? 'open' : ''}`}>
        <Lists>
          {data.map((item) => (
            <li key={item.id}>
              <div
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
              {' '}
              <NavLink href={item.url} target="_blank" rel="noopener noreferrer">{item.label}</NavLink>
            </li>
          ))}
        </Lists>
      </Message>
    </Box>
  );
};


LastArticleSidebar.defaultProps = {
  className: '',
};

LastArticleSidebar.propTypes = {
  data: PropTypes.array.isRequired,
  label: PropTypes.string,
  className: PropTypes.string
};

export default LastArticleSidebar;
