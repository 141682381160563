import React from 'react';
import PropTypes from 'prop-types';

// Base-UI components
import {
  Box,
  Heading
} from 'base-ui';

// Components
import SubjectButton from './SubjectButton';

// style
// import styleSubjectsLists from './styleSubjectsLists.js';
import './style.scss';

const SubjectsList = (props) => {
  const { title, list, onClick } = props;

  const handlerClick = (e) => onClick(e);

  return (
    <Box className="box-subjects-list">
      {title && (
        <Heading type="h1" className="align-center margin-top-x-big margin-bottom-x-big font-xx-big">{title}</Heading>
      )}
      <Box className="box-list padding-normal without-padding-top">
        { list.map((button) => (
          <SubjectButton
            key={button.id}
            onClick={(e) => handlerClick(e)}
            button={{
              value: button.id,
              label: button.label,
              url: button.url
            }}
          />
        )) }
      </Box>
      { /* <style jsx>{styleSubjectsLists}</style> */ }
    </Box>
  );
};

SubjectsList.propTypes = {
  /**
  * The title of the list
  */
  title: PropTypes.string,

  /**
  * The data from buttons subjects
  */
  list: PropTypes.object.isRequired,

  /**
  * The function to return to parent, button data.
  */
  onClick: PropTypes.func,

};

export default SubjectsList;
