import React, { Component } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';
import UnycosPaymentForm from './components/UnycosPaymentForm';

/* To show the approval screen that needs to check how it would look if the
 * user was logged in and with a saved card, I did it with just a
 * simple parameter check in the url
*/

class UnycosPayment extends Component {
  state = {
    pageTitle: 'Completa tu compra',
    completed: false,
    hasPreAccount: true,

    // for demo purpose
    // eslint-disable-next-line
    isLogged: this.props.location.search ? true : false, 
  }

  componentDidMount() {
    const {
      cart, payment, isLogged
    } = this.state;
    let { hasPreAccount } = this.state;

    if (isLogged) {
      hasPreAccount = false;
      this.checkSavedCard();
    }

    this.setState({
      cart,
      payment,
      hasPreAccount
    });
  }

  render() {
    const {
      pageTitle,
      isLogged,
      completed,
    } = this.state;

    const completedTitle = (
      <React.Fragment>
        <span className="display-block align-center">{pageTitle.split(' ').slice(0, 3).join(' ')}</span>
        <span>{pageTitle.split(' ').slice(3).join(' ')}</span>
      </React.Fragment>
    );

    return (
      <PageWrapper config={{ title: pageTitle }}>
        <UnycosLayout className="unycos payment">
          <UnycosHeader statuslogged={isLogged} showMenu={false} />
          <Container fluid wrap className="margin-top-medium margin-bottom-big">
            <Row className="center-xs">
              <Col xs="12">
                <Box className="hero align-center margin-top-x-big margin-bottom-x-big" style={{ padding: '2vw 0' }}>
                  <SwitchTransition mode="out-in">
                    <CSSTransition key={completed} classNames="fade">
                      <Heading
                        type="h2"
                        size="big"
                        textColor="white"
                        className="title normal-font text-uppercase with-letter-spacing"
                      >
                        {!completed ? pageTitle : completedTitle}
                      </Heading>
                    </CSSTransition>
                  </SwitchTransition>
                </Box>
              </Col>
            </Row>
            <UnycosPaymentForm />
          </Container>
          <UnycosFooter
            showHideElements={{ social: true, cta: true }}
            contactText="Contacta con tu asesor personal"
            contactNumber="0000000"
            goldenButton
            from="landing-page"
          />
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

UnycosPayment.propTypes = {
  location: PropTypes.object
};

export default UnycosPayment;
