import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Box,
  Heading,
  InputField,
  Label,
  Button,
  Icon,
  NavLink,
  Paragraph,
} from 'base-ui';

import './styles.css';

const Input = ({
  inputType,
  name,
  placeholder,
  iconName,
  iconPrefix,
  value,
  onChange,
}) => (
  <Box className="input-wrapper">
    <InputField
      name={name}
      required
      type={inputType}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    <Icon name={iconName} prefix={iconPrefix} className="icon" />
  </Box>
);

const Auth = ({ title, onSubmitRegister, onSubmitLogin }) => {
  const [type, setType] = useState('register');

  const renderRegister = () => (
    <React.Fragment>
      <form>
        <Input
          inputType="text"
          name="name"
          placeholder="Nombre"
          iconName="user"
          iconPrefix="fas"
          value=""
        />
        <Input
          inputType="email"
          name="email"
          placeholder="Correo electrónico"
          iconName="envelope"
          iconPrefix="fas"
          value=""
        />
        <Input
          inputType="password"
          name="password"
          placeholder="Contraseña"
          iconName="lock"
          iconPrefix="fas"
          value=""
        />
        <Box className="terms margin-top-medium margin-bottom-medium">
          <InputField
            id="checkbox_acept"
            name="checkbox_acept"
            type="checkbox"
            required
          />
          <Label inputId="checkbox_acept">
            <Box tag="span">
              Acepto los
              {' '}
              <NavLink to="." className="secondary-color">
                términos y condiciones
              </NavLink>
              {' '}
              y nuestra
              {' '}
              <NavLink to="." className="secondary-color">
                política de privacidad.
              </NavLink>
            </Box>
          </Label>
        </Box>
        <Box className="submit-wrapper">
          <Button
            tag="button"
            color="secondary"
            textColor="white"
            borderColor="secondary"
            className="bt-cta"
            onClick={onSubmitRegister}
          >
            Regístrate
          </Button>
        </Box>
      </form>
      <Paragraph textColor="regent-grey" className="margin-top-medium">
        ¿Ya tienes una cuenta en LMEM?
        {' '}
        <span
          tabIndex="-1"
          role="button"
          style={{ display: 'block', cursor: 'pointer' }}
          onClick={() => setType('login')}
          onKeyDown={() => setType('login')}
          className="align-center"
        >
          Inicia sesión
        </span>
      </Paragraph>
    </React.Fragment>
  );

  const renderLogin = () => (
    <React.Fragment>
      <form>
        <Input
          inputType="email"
          name="email"
          placeholder="Correo electrónico"
          iconName="envelope"
          iconPrefix="fas"
          value=""
        />
        <Input
          inputType="password"
          name="password"
          placeholder="Contraseña"
          iconName="lock"
          iconPrefix="fas"
          value=""
        />
        <Box className="terms margin-top-medium margin-bottom-medium">
          <InputField
            id="checkbox_acept"
            name="checkbox_acept"
            type="checkbox"
            required
          />
          <Label inputId="checkbox_acept">
            <Box tag="span">
              Acepto los
              {' '}
              <NavLink to="." className="secondary-color">
                términos y condiciones
              </NavLink>
              {' '}
              y la
              {' '}
              <NavLink to="." className="secondary-color">
                política de privacidad.
              </NavLink>
            </Box>
          </Label>
        </Box>
        <Box className="submit-wrapper">
          <Button
            tag="button"
            color="secondary"
            textColor="white"
            borderColor="secondary"
            className="bt-cta"
            onClick={onSubmitLogin}
          >
            Iniciar sesión
          </Button>
        </Box>
      </form>
      <Paragraph textColor="regent-grey" className="margin-top-medium">
        ¿No tienes una cuenta LMEM?
        {' '}
        <span
          tabIndex="-1"
          role="button"
          style={{ display: 'block', cursor: 'pointer' }}
          onClick={() => setType('register')}
          onKeyDown={() => setType('register')}
          className="align-center"
        >
          Regístrate ahora
        </span>
      </Paragraph>
    </React.Fragment>
  );

  return (
    <Row className="center-xs">
      <Col xs="12" sm="7" className="align-center consultation-auth">
        <Heading
          type="h2"
          size="big"
          className="margin-bottom-medium align-center"
        >
          {title}
        </Heading>
        <Box className="social-wrapper align-center">
          <Button textColor="white" className="social-btn facebook">
            <Icon name="facebook-f" prefix="fab" className="social-icon" />
            {' '}
            {type === 'register' ? 'Registrarse' : 'Conectarse'}
            {' '}
            con Facebook
            {' '}
          </Button>
          <Button
            color="white"
            textColor="text"
            borderColor="grey"
            className="social-btn google"
          >
            <div
              className="social-icon"
              style={{ width: '16px', height: '16px' }}
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="16px"
                height="16px"
                viewBox="0 0 48 48"
                className="abcRioButtonSvg"
              >
                <g>
                  <path
                    fill="#EA4335"
                    d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                  >
                  </path>
                  <path
                    fill="#4285F4"
                    d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                  >
                  </path>
                  <path
                    fill="#FBBC05"
                    d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                  >
                  </path>
                  <path
                    fill="#34A853"
                    d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                  >
                  </path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </g>
              </svg>
            </div>
            {' '}
            {type === 'register' ? 'Registrarse' : 'Conectarse'}
            {' '}
            con Google
          </Button>
        </Box>
        <Box className="divider align-center" data-content="O"></Box>
        {type === 'register' ? (
          <React.Fragment>{renderRegister()}</React.Fragment>
        ) : (
          <React.Fragment>{renderLogin()}</React.Fragment>
        )}
      </Col>
    </Row>
  );
};

Auth.propTypes = {
  title: PropTypes.string,
  onSubmitRegister: PropTypes.func,
  onSubmitLogin: PropTypes.func,
};

export default Auth;
