import React from 'react';

import {
  PageWrapper
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';
import UnycosNewPassword from './components/UnycosNewPassword';

class UnycosHome extends React.PureComponent {
  render() {
    return (
      <PageWrapper config={{ title: 'Unycos New Password' }}>
        <UnycosLayout>
          <UnycosHeader statuslogged={false} />
          <UnycosNewPassword />
          <UnycosFooter showHideElements={{ social: false, cta: false }} />
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

export default UnycosHome;
