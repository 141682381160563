import React from 'react';
import {
  Box,
  Container,
  Row,
  Col,
  Heading,
  Paragraph,
  Card
} from 'base-ui';

const UnycoUserComments = (props) => (
  <Box className={`teachers-reviews margin-top-medium ${props.className}`}>
    <Container fluid wrap>
      <Row className="center-xs">
        <Col xs="12">
          <Heading type="h3" className="center-xs normal-font text-uppercase letter-spacing__normal white-color font-size-x-medium margin-bottom-normal">Comentarios de usuarios</Heading>
        </Col>
        <Col xs="12" sm="11" md="12">
          <Box className="comments comments__hscroll--md">
            <Box className="horizontal-scroll-md">
              <Box className="comment">
                <Card className="radius__normal without-shadow small">
                  <Paragraph size="small" className="without-margin-top line-height-medium titles-color">Ha sido un curso muy gratificante,en el que he podido aprender muchas cosas gracias a los grandes ponentes que han colaborado y aportado sus conocimientos. Ahora toca ponerse manos a la obra y poner en practica todo lo aprendido. Estoy muy orgulloso.</Paragraph>
                  <Box className="end-xs">
                    <Box tag="span" className="text-uppercase black-color font-size-small">Samuel F.</Box>
                  </Box>
                </Card>
              </Box>
              <Box className="comment">
                <Card className="radius__normal without-shadow small">
                  <Paragraph size="small" className="without-margin-top line-height-medium titles-color">Ha sido un curso muy gratificante.</Paragraph>
                  <Box className="end-xs">
                    <Box tag="span" className="text-uppercase black-color font-size-small">Samuel F.</Box>
                  </Box>
                </Card>
              </Box>
              <Box className="comment">
                <Card className="radius__normal without-shadow small">
                  <Paragraph size="small" className="without-margin-top line-height-medium titles-color">Ha sido un curso muy gratificante,en el que he podido aprender muchas cosas gracias a los grandes ponentes que han colaborado y aportado sus conocimientos.</Paragraph>
                  <Box className="end-xs">
                    <Box tag="span" className="text-uppercase black-color font-size-small">Samuel F.</Box>
                  </Box>
                </Card>
              </Box>
              <Box className="comment">
                <Card className="radius__normal without-shadow small">
                  <Paragraph size="small" className="without-margin-top line-height-medium titles-color">Ha sido un curso muy gratificante,en el que he podido aprender muchas cosas gracias a los grandes ponentes que han colaborado y aportado sus conocimientos. Ahora toca ponerse manos a la obra y poner en practica todo lo aprendido. Estoy muy orgulloso.</Paragraph>
                  <Box className="end-xs">
                    <Box tag="span" className="text-uppercase black-color font-size-small">Samuel F.</Box>
                  </Box>
                </Card>
              </Box>
            </Box>
          </Box>
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycoUserComments;
