import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  Box,
  NavLink,
  Image,
  Icon,
  Lists,
  Button
} from 'base-ui';

const MenuPublish = (props) => {
  const {
    toggleAction
  } = props;
  return (
    <React.Fragment>
      <Container fluid wrap className="header-publish-nav">
        <Row>
          <Col xs="12">
            <Button onClick={toggleAction} className="navicon btn main-border main-bg white-color">
              <Icon prefix="fas" name="bars" />
            </Button>
          </Col>
        </Row>
      </Container>
      <aside className="publish-sidebar">
        <Box className="logo">
          <NavLink to="publish">
            <Image src="./img/logo.svg" alt="Logo LMEM" />
          </NavLink>
        </Box>
        <Lists>
          <li className="title">
              Información Esencial
          </li>
          <li>
            <NavLink to="publish">
              <Icon prefix="far" name="check-circle" />
              {' '}
              Servicio
            </NavLink>
          </li>
          <li className="current check">
            <NavLink to="publish-2">
              <Icon prefix="far" name="check-circle" />
              {' '}
              Descripción
            </NavLink>
          </li>
          <li><NavLink to="publish-3">Politicas</NavLink></li>
          <li><NavLink to="publish-4">Finalizar</NavLink></li>
        </Lists>
      </aside>

      <Box className="overlay-menu-publish" onClick={toggleAction}></Box>
    </React.Fragment>
  );
};

MenuPublish.propTypes = {
  toggleAction: PropTypes.any
};

export default MenuPublish;
