import React from 'react';
import {
  Container,
  Box,
  Row,
  Col,
  Image,
  Paragraph,
  Icon,
  Button
} from 'base-ui';

const UnycosSingleFeatures = () => (
  <Box className="unycos-features">
    <Container fluid wrap>
      <Row className="around-xs middle-xs">
        <Col xs="5" md="4" className="margin-top-small margin-bottom-small">
          <Row className="center-xs middle-xs">
            <Col xs="12" sm="4" className="center-xs">
              <Icon className="with-outline white-border">
                <Image className="icon" src="./img/unycos/icons/icon-book.svg" />
              </Icon>
            </Col>
            <Col xs="12" sm="7" className="without-padding-left without-padding-right">
              <Paragraph className="label line-height-medium center-xs start-md text-uppercase white-color font-size-medium">12 módulos</Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs="5" md="4" className="margin-top-small margin-bottom-small">
          <Row className="center-xs middle-xs">
            <Col xs="12" sm="4" className="center-xs">
              <Icon className="with-outline white-border">
                <Image className="icon" src="./img/unycos/icons/icon-video.svg" />
              </Icon>
            </Col>
            <Col xs="12" sm="7" className="without-padding-left without-padding-right">
              <Paragraph className="label line-height-medium center-xs start-md text-uppercase white-color font-size-medium">+3,5 horas</Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="4" className="first-xs last-md">
          <Button className="main-bg main-border btn--medium black-color text-uppercase with-letter-spacing normal-font font-size-small bt-click" data-target="single-more-info" full>Más información</Button>
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycosSingleFeatures;
