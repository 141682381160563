import React from 'react';
import PropTypes from 'prop-types';

import {
  Box
} from 'base-ui';

import classes from './AppointmentTime.module.scss';

const AppointmentTimes = (props) => {
  const {
    selected, id, click, children
  } = props;
  const style = [classes.Appointment];
  if (selected) {
    style.push(classes.Active);
  }
  return (
    <Box tag="span" id={id} onClick={click} className={style.join(' ')}>
      {children}
    </Box>
  );
};

AppointmentTimes.propTypes = {
  selected: PropTypes.bool,
  id: PropTypes.string,
  click: PropTypes.func,
  children: PropTypes.string
};

export default AppointmentTimes;
