import React from 'react';

import {
  Card,
  Box,
  NavLink,
  Lists,
  Icon
} from 'base-ui';

const MenuAccount = () => (
  <Card>
    <Box className="box-title without-margin-bottom">
      <Box tag="span" className="label main-font">Navegación</Box>
    </Box>
    <Lists className="with-icons">
      <li>
        <NavLink to="account" className="normal-font main-color">
          <Icon prefix="fas" name="user" className="main-color" />
          {' '}
          Cuenta
        </NavLink>
      </li>
      <li>
        <NavLink to="payment-methods" className="normal-font">
          <Icon prefix="fas" name="credit-card" className="main-color" />
          {' '}
          Metodos de pago
        </NavLink>
      </li>
      <li>
        <NavLink to="payment-preferences" className="normal-font">
          <Icon prefix="fas" name="hand-holding-usd" className="main-color" />
          {' '}
          Preferencias de pago
        </NavLink>
      </li>
      <li>
        <NavLink to="charges-preferences" className="normal-font">
          <Icon prefix="fas" name="hand-holding-usd" className="main-color" />
          {' '}
          Preferencias de cobro
        </NavLink>
      </li>
      <li>
        <NavLink to="payment-history" className="normal-font">
          <Icon prefix="fas" name="receipt" className="main-color" />
          {' '}
          Historial
        </NavLink>
      </li>
      <li>
        <NavLink to="account-security" className="normal-font">
          <Icon prefix="fas" name="shield-alt" className="main-color" />
          {' '}
          Seguridad
        </NavLink>
      </li>
      <li>
        <NavLink to="account-subscription" className="normal-font">
          <Icon prefix="fas" name="check-circle" className="main-color" />
          {' '}
          Suscripción
        </NavLink>
      </li>
    </Lists>
  </Card>
);


export default MenuAccount;
