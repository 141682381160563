import React, { Component } from 'react';
import { NavLink } from 'base-ui';

class BlogMorePostsList extends Component {

	render() {
		return(
			<section id="more-topics-list-widget" className="widget">
        <div className="section-title transparent-border">
          <span className="label text-uppercase titles-color">Más de MejorConSalud</span>
        </div>
        <div className="row">
          <div className="list-col col-xs-12 col-sm-4">
            <div className="section-title main-border">
              <span className="label main-color">Remedios naturales</span>
            </div>
            <NavLink href="/single" className="display-block">Jengibre con azucar para la tos</NavLink>
            <NavLink href="/single" className="display-block">Miel con limon para la gripe</NavLink>
            <NavLink href="/single" className="display-block">Vitamina C como suplemento</NavLink>
            <NavLink href="/single" className="display-block">Cebolla al vapor para los poros</NavLink>
            <br />
          </div>
          <div className="list-col col-xs-12 col-sm-4">
            <div className="section-title main-border">
              <span className="label main-color">Nutrición</span>
            </div>
            <NavLink href="/single" className="display-block">Jengibre con azucar para la tos</NavLink>
            <NavLink href="/single" className="display-block">Miel con limon para la gripe</NavLink>
            <NavLink href="/single" className="display-block">Vitamina C como suplemento</NavLink>
            <NavLink href="/single" className="display-block">Cebolla al vapor para los poros</NavLink>
            <br />
          </div>
          <div className="list-col col-xs-12 col-sm-4">
            <div className="section-title main-border">
              <span className="label main-color">Medicamentos</span>
            </div>
            <NavLink href="/single" className="display-block">Jengibre con azucar para la tos</NavLink>
            <NavLink href="/single" className="display-block">Miel con limon para la gripe</NavLink>
            <NavLink href="/single" className="display-block">Vitamina C como suplemento</NavLink>
            <NavLink href="/single" className="display-block">Cebolla al vapor para los poros</NavLink>
            <br />
          </div>
        </div>
      </section>
		)
	}
}

export default BlogMorePostsList;
