import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Image,
  Card,
  Paragraph,
  Button
} from 'base-ui';

const ThankYou = () => (
  <PageWrapper config={{ title: 'Consultation Thank You', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section">
        <Container fluid wrap>
          <Row className="center-xs">
            <Col xs="12">
              <Heading type="h2" className="lmem-color line-height-x-medium font-size-x-big align-center">
                ¡Enhorabuena!
                <br />
                Tu cita ha sido reservada con éxito.
              </Heading>
            </Col>
            <Col xs="12" sm="8" md="7">
              <Card className="card-resume flex-box middle margin-x-medium">
                <Image src="./img/dummy/author.jpg" alt="Thumb Avatar Professional Daniela Fluchaire" className="thumb-120" />
                <Box className="group-text">
                  <Heading type="h3" className="font-size-x-medium main-color">Consulta con Daniela Fluchaire</Heading>
                  <Paragraph className="font-size-medium line-height-medium">
                    Miércoles, 28 de Noviembre
                    <br />
                    13:00 - 13:30
                  </Paragraph>
                </Box>
              </Card>
            </Col>
            <Col xs="12">
              <Button href="/consultation/buy-confirmation" size="normal" color="lmem" textColor="white" borderColor="lmem" className="display-block bt-extended bt-center">Ver información</Button>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);


export default ThankYou;
