import React, { Component } from 'react';
import cs from 'classnames';
import creditCardType from 'credit-card-type';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Box,
  Heading,
  Image,
  InputField,
  Paragraph,
  Label,
  NavLink,
  Container,
  Button,
  Icon,
  Modal
} from 'base-ui';

import CouponForm from '../../../../components/Sections/CouponForm';

import UnycosCourseConfirmation from '../UnycosCourseConfirmation';

import './styles.css';

/* To show the approval screen that needs to check how it would look if the
 * user was logged in and with a saved card, I did it with just a
 * simple parameter check in the url
*/

class UnycosPaymentForm extends Component {
  state = {
    cart: [],
    payment: {
      method: 'credit-card',
      card: {
        name: '',
        number: '',
        date: '',
        cvv: '',
        type: ''
      }
    },
    total: 0,
    hasCoupon: false,
    buttonActive: false,
    completed: false,
    hasPreAccount: true,
    preAccount: {
      name: 'Joselito', email: '', confirmEmail: '', error: false
    },

    // for demo purpose
    // eslint-disable-next-line
    isLogged: true,
    savedCard: {},

    // modal for checking payment / coupon
    isModalOpened: false,
    modalText: 'cupón'
  }

  componentDidMount() {
    const {
      cart, payment, isLogged
    } = this.state;
    let { hasPreAccount } = this.state;

    if (isLogged) {
      payment.method = 'saved-card';
      hasPreAccount = false;
      this.checkSavedCard();
    }

    cart.push({
      title: 'Fundamentos de Maquillaje',
      by: 'Maquillaje',
      price: '€ 99.90',
      thumb: './img/unycos/courses/maquillaje/maquillaje-creativo.jpg'
    });

    this.setState({
      cart,
      payment,
      hasPreAccount
    });

    this.calcTotal();
  }

  handlePaymentMethodChange = (e) => {
    const { payment } = this.state;

    payment.method = e.target.value;

    this.setState({
      payment,
    });
  }

  checkSavedCard = () => {
    const savedCard = {};

    savedCard.name = 'Bruno S. Almeida';
    savedCard.number = '4424 0230 7820 9043';
    savedCard.date = '04 / 20';
    savedCard.cvv = '233';
    savedCard.type = creditCardType(savedCard.number)[0].type;

    this.setCreditCardInfo(savedCard, 'saved-card');
  }

  maskInput = (value, type) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let matches;
    let match;
    let parts = '';

    const { payment: { card } } = this.state;

    switch (type) {
      case 'cardNumber':
        matches = v.match(/\d{4,16}/g);
        // eslint-disable-next-line
        match = matches && matches[0] || '';
        parts = [];

        for (let i = 0; i < match.length; i += 4) {
          parts.push(match.substring(i, i + 4));
        }

        if (parts.length) {
          card.number = parts.join(' ');
        } else {
          card.number = value;
        }

        if (creditCardType(v)[0] && v.length > 2) {
          card.type = creditCardType(v)[0].type;
        } else {
          card.type = '';
        }

        break;
      case 'cardDate':
        v = v.replace(/^(\d\d)/g, '$1 / ');
        card.date = v.substring(0, 7);
        break;
      case 'cardCVV':
        card.cvv = v.substring(0, 3);
        break;
      default:
        card.name = value;
        break;
    }

    this.setCreditCardInfo(card, 'credit-card');
  };

  setCreditCardInfo = (card, method) => {
    if (method === 'credit-card') {
      const { payment } = this.state;

      payment.card = card;
      this.setState({
        payment
      });
    }

    if (method === 'saved-card') {
      this.setState({
        savedCard: card
      });
    }
  }

  applyCoupon = (coupon) => {
    let { total } = this.state;

    // fake coupon validation
    if (coupon === 'blablabla') {
      const discountPercentage = 50 / 100;

      // calculate new total
      const newTotal = total - (total * discountPercentage);
      total = newTotal.toFixed(2);

      this.setState({
        hasCoupon: true,
        total,
      });
      this.closeModal();
      return {
        status: 'success',
        message: `Descuento aplicado -${discountPercentage.toFixed(2).split('.')[1]}%`
      };
    }
    this.closeModal();
    return {
      status: 'error',
      message: '¡Cupón no válido!'
    };
  }

  openModal = (text = 'coupon') => {
    this.setState({ isModalOpened: true });

    if (text !== 'coupon') {
      this.setState({ modalText: text });
    }

    document.body.style.overflow = 'hidden';
  }

  closeModal = () => {
    this.setState({ isModalOpened: false });
    document.body.style.overflow = 'unset';
  }

  handleEmailChange = (v, t) => {
    const { preAccount } = this.state;

    if (t === 'confirmEmail') {
      preAccount.error = v !== preAccount.email;
      preAccount.errorMessage = '¡Los correos no coinciden!';
    }

    preAccount[t] = v;
    this.setState({
      preAccount
    });
  }

  savedCard = () => {
    const {
      payment: { method },
      savedCard: { name, number, type }
    } = this.state;

    let lastNumbers = '0000';
    let cardName = '';
    let cardType = '';
    let cardImage = '';

    if (number) {
      lastNumbers = number.split(' ').slice(-1).pop();
      cardName = name;
      cardType = type;
      cardImage = `./img/credit-flags/${cardType}.png`;
    }

    return (
      <Box
        className={cs('payment-box payment-option dark-grey-bg text-uppercase margin-bottom-small rounded-small', {
          active: method === 'saved-card'
        })}
      >
        <Box className="payment-option-header">
          <Box className="option">
            <input
              type="radio"
              id="saved-card"
              name="payment-method"
              value="saved-card"
              checked={method === 'saved-card'}
              onChange={this.handlePaymentMethodChange}
            />
            {/* eslint-disable-next-line */}
            <label htmlFor="saved-card">
              <Heading type="h4" size="normal" textColor="regent-grey" className="option-name saved">
                VISA ****
                {' '}
                {lastNumbers}
                <br />
                <span className="font-size-small">{cardName}</span>
              </Heading>
            </label>
            <ul className="credit-brands">
              <li><Image src={cardImage} alt={cardType} /></li>
            </ul>
          </Box>
        </Box>
      </Box>
    );
  }

  creditCardForm = () => {
    const {
      payment: {
        card: {
          name, number, date, cvv, type
        }
      }
    } = this.state;

    // const cardImage = `./img/credit-flags/transparent/${type}.png`;

    return (
      <form>
        <Row className="fade-in">
          <Col xs="12" sm="12">
            <InputField
              name="credit-name"
              type="text"
              label="Nombre del titular"
              full
              big
              required
              value={name}
              placeholder="Ex: Sergio Ramos Torres"
              onChange={(v) => this.maskInput(v)}
              className="without-shadow rounded-small full text-uppercase letter-spacing__small"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" lg="6" className="position-relative">
            <InputField
              name="credit-number"
              type="text"
              label="Número de la Tarjeta"
              full
              big
              required
              value={number}
              placeholder="0000 0000 0000 0000"
              onChange={(v) => this.maskInput(v, 'cardNumber')}
              className="without-shadow rounded-small full text-uppercase letter-spacing__small"
            />
            {type !== 'mastercard' ? (
              <Icon name={`cc-${type}`} prefix="fab" color="white" size="medium" className="credit-type position-absolute" />
            ) : (
              <Image src="./img/credit-flags/transparent/mastercard.png" alt="mastercard logo" className="credit-type position-absolute" />
            )}
          </Col>
          <Col xs="6" lg="3">
            <InputField
              name="credit-date"
              type="text"
              label="Fecha de Cad."
              full
              big
              required
              value={date}
              placeholder="00 / 00"
              onChange={(v) => this.maskInput(v, 'cardDate')}
              className="without-shadow rounded-small full text-uppercase letter-spacing__small regent-grey-bg"
            />
          </Col>
          <Col xs="6" lg="3">
            <InputField
              name="credit-cvv"
              type="number"
              label="CVV"
              full
              big
              required
              value={cvv}
              placeholder="000"
              onChange={(v) => this.maskInput(v, 'cardCVV')}
              className="without-shadow rounded-small full text-uppercase letter-spacing__small"
            />
          </Col>
        </Row>
      </form>
    );
  }

  payPal = () => (
    <Paragraph className="rounded-small fade-in">
      Será redirigido al sitio web de Paypal para completar su
      compra de forma segura.
    </Paragraph>
  );

  calcTotal = () => {
    const { cart } = this.state;
    let { total } = this.state;

    total += cart
      .map(({ price }) => Number(price.split(' ')[1]))
      .reduce((a, i) => a + i);

    this.setState({
      total
    });
  }

  buy = () => {
    const {
      hasPreAccount, preAccount, isLogged
    } = this.state;

    this.openModal('pago');

    setTimeout(() => {
      if (!hasPreAccount || (!preAccount.error && preAccount.email && preAccount.confirmEmail) || isLogged) {
        this.setState((prevState) => ({
          completed: !prevState.completed
        }));

        const { hasCompleted } = this.props;
        hasCompleted();
      } else {
        // Scroll to top
        document.querySelector('.identification').scrollIntoView();
        preAccount.error = true;
        preAccount.errorMessage = 'Confirma tu correo eletrônico';
        this.setState({
          preAccount
        });
      }
      this.closeModal();
    }, 5000);
  }

  renderBuyForm = (number) => {
    const { buttonActive, total } = this.state;

    return (
      <Box className="margin-top-medium margin-bottom-big">
        <Box>
          <InputField id={`save-card-${number}`} type="checkbox" />
          <Label inputId={`save-card-${number}`} className="font-size-small">
            Guardar de forma segura tu tarjeta para futuros pagos.
          </Label>
        </Box>
        <Box>
          <InputField
            id={`terms-${number}`}
            type="checkbox"
            onChange={() => this.setState((prevState) => ({ buttonActive: !prevState.buttonActive }))}
          />
          <Label inputId={`terms-${number}`} className="font-size-small">
            <Box tag="span">
              Acepto los
              {' '}
              <NavLink href="." className="white-color">
                términos y condiciones
              </NavLink>
              {' '}
              y nuestra
              {' '}
              <NavLink href="." className="white-color">
                cláusula informativa.
              </NavLink>
            </Box>
          </Label>
        </Box>
        <Button
          tag="button"
          color="main"
          textColor="white"
          borderColor="main"
          disabled={!buttonActive}
          full
          size="big"
          onClick={this.buy}
        >
          Comprar
          {' '}
          <span className="hidden-dsk">
            €
            {' '}
            {Number(total).toFixed(2)}
          </span>
        </Button>
      </Box>
    );
  };

  renderSuccess = () => {
    const { cart, total } = this.state;

    const course = cart.map((item) => ({
      title: item.title,
      by: item.by,
      thumb: item.thumb,
      price: `€ ${Number(total).toFixed(2)}`
    }));

    return (
      <Box className="rounded-small">
        <UnycosCourseConfirmation className="rounded-small margin-bottom-big" course={course[0]}>
          <Box className="actions" style={{ fontSize: '1rem' }}>
            <NavLink to="/unycos/courses/">
              <Button
                tag="button"
                color="transparent"
                textColor="main"
                borderColor="main"
                size="big"
                className="left text-uppercase letter-spacing__normal full without-margin fade-in delay-4"
              >
                Ver más cursos
              </Button>
            </NavLink>
            <NavLink to="/unycos/cuenta/pagos">
              <Button
                tag="button"
                textColor="white"
                color="main"
                borderColor="main"
                size="big"
                className="right text-uppercase letter-spacing__normal full without-margin fade-in delay-5"
              >
                Ver detalles de compra
              </Button>
            </NavLink>
          </Box>
        </UnycosCourseConfirmation>
      </Box>
    );
  };

  render() {
    const {
      payment: { method },
      cart,
      total,
      hasCoupon,
      isLogged,
      completed,
      isModalOpened,
      modalText,
      preAccount
    } = this.state;
    const [master, visa, amex] = ['./img/credit-flags/mastercard.png', './img/credit-flags/visa.png', './img/credit-flags/amex.png'];
    const { gift } = this.props;

    let cartItems = (
      cart.map(({
        title, by, thumb, price
      }) => (
        <Box key={title} className="buy-item margin-bottom-small">
          <Box className="item">
            <Box className="item-info">
              <Box className="item-image rounded-small" style={{ backgroundImage: `url('${thumb}')` }}></Box>
              <Box className="item-heading text-uppercase">
                <Heading type="h3" textColor="grey" size="normal" className="without-margin">{by}</Heading>
                <Heading type="h4" textColor="regent-grey" size="normal" className="without-margin normal-font" style={{ textTransform: 'lowercase' }}>
                  Enseña
                  {' '}
                  {title}
                </Heading>
              </Box>
            </Box>
            <Box className="item-price">
              <Heading type="h3" size="normal" textColor="grey">{price}</Heading>
            </Box>
          </Box>
        </Box>
      ))
    );

    if (gift) {
      cartItems = (
        cart.map(({
          title, by, thumb, price
        }) => (
          <Box key={title} className="buy-item margin-bottom-small">
            <Box className="item item-gift">
              <Box className="item-info">
                <Box className="item-image rounded-small" style={{ backgroundImage: `url('${thumb}')` }}>
                  <Box className="item-price">
                    <Heading type="h3" size="normal" textColor="grey">{price}</Heading>
                  </Box>
                  <Box className="item-heading text-uppercase">
                    <Heading type="h3" textColor="grey" size="normal" className="without-margin">{by}</Heading>
                    <Heading type="h4" textColor="regent-grey" size="normal" className="without-margin normal-font" style={{ textTransform: 'lowercase' }}>
                      Enseña
                      {' '}
                      {title}
                    </Heading>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      );
    }

    return (
      <>
        <Row style={{ overflow: 'hidden' }}>
          {!completed && (
            <>
              <Col xs="12" md="7" className="payment-method margin-top-x-small">
                <Box id="payment-box">
                  <Heading type="h3" size="medium" textColor="grey" className="text-uppercase margin-bottom-medium">Método de Pago</Heading>
                  {isLogged && (
                    <>
                      { this.savedCard() }
                    </>
                  )}
                  <Box
                    className={cs('payment-box payment-option dark-grey-bg text-uppercase margin-bottom-small rounded-small', {
                      active: method === 'credit-card'
                    })}
                  >
                    <Box className="payment-option-header">
                      <Box className="option">
                        <input
                          type="radio"
                          id="credit-card"
                          name="payment-method"
                          value="credit-card"
                          checked={method === 'credit-card'}
                          onChange={this.handlePaymentMethodChange}
                        />
                        {/* eslint-disable-next-line */}
                        <label htmlFor="credit-card">
                          <Heading type="h4" size="normal" textColor="regent-grey" className="option-name">Tarjeta de crédito</Heading>
                        </label>
                        <ul className="credit-brands">
                          <li><Image src={master} alt="Mastercard Logo" /></li>
                          <li><Image src={visa} alt="Visa Logo" /></li>
                          <li><Image src={amex} alt="Amex Logo" /></li>
                        </ul>
                      </Box>
                    </Box>
                    <Box className="payment-option-body">
                      { this.creditCardForm() }
                    </Box>
                  </Box>
                  <Box
                    className={cs('payment-box payment-option paypal dark-grey-bg text-uppercase margin-bottom-small rounded-small', {
                      active: method === 'paypal'
                    })}
                  >
                    <Box className="payment-option-header">
                      <Box className="option">
                        <input
                          type="radio"
                          id="paypal"
                          name="payment-method"
                          value="paypal"
                          checked={method === 'paypal'}
                          onChange={this.handlePaymentMethodChange}
                        />
                        {/* eslint-disable-next-line */}
                        <label htmlFor="paypal">
                          <Image src="./img/paypal-white.png" alt="Logo Paypal" className="option-name" />
                        </label>
                      </Box>
                    </Box>
                    <Box className="payment-option-body text">
                      { this.payPal() }
                    </Box>
                  </Box>
                  <Box className="hidden-dsk margin-top-big">
                    { this.renderBuyForm(1) }
                  </Box>
                  <Row className="margin-top-big">
                    <Col xs="12" sm="6" className="security-item margin-bottom-medium">
                      <Image src="./img/unycos/icons/security.png" alt="security icon" />
                      <Box>
                        <Heading type="h5" textColor="main" size="normal" className="text-uppercase without-margin">Pago Seguro</Heading>
                        <Paragraph size="small" className="without-margin" textColor="regent-grey">
                          Todas las transacciones son seguras y encriptadas. La información crediticia nunca se almacena.
                        </Paragraph>
                      </Box>
                    </Col>
                    <Col xs="12" sm="6" className="security-item margin-bottom-medium">
                      <Image src="./img/unycos/icons/cashback.png" alt="cashback icon" />
                      <Box>
                        <Heading type="h5" textColor="main" size="normal" className="text-uppercase without-margin">Garantía de satisfacción</Heading>
                        <Paragraph size="small" className="without-margin" textColor="regent-grey">
                          Seguro que te encantará, pero si no es así, puedes solicitar reembolso hasta 30 días de tu compra.
                        </Paragraph>
                      </Box>
                    </Col>
                  </Row>
                </Box>
              </Col>
              <Col xs="12" md="1"></Col>
              <Col xs="12" md="4" className="payment-resume gift-sidebar margin-top-x-small margin-bottom-small">
                <Box id="aside-review">
                  <Heading type="h3" size="medium" textColor="grey" className="text-uppercase margin-bottom-medium">{gift ? 'Resumen' : 'Resumen del pedido'}</Heading>
                  <Box className="payment-box dark-grey-bg ronded-small rounded-small">
                    { cartItems }
                    {gift && (
                      <>
                        <Heading type="h5" size="small" textColor="grey" className="text-uppercase margin-bottom-medium">Etiqueta</Heading>
                        <Box className="gift-card gift-card-review full">
                          <Heading type="h3">
                            {gift.nameTo}
                            {', '}
                          </Heading>
                          <Paragraph>
                            {gift.message}
                          </Paragraph>
                          <Paragraph className="align-right without-margin">
                            -
                            {' '}
                            {preAccount.name}
                          </Paragraph>
                        </Box>
                      </>
                    )}
                    <Box className="coupon-form margin-top-medium margin-bottom-medium">
                      <CouponForm
                        onSubmitCoupon={this.applyCoupon}
                        couponActive={hasCoupon}
                        openModal={this.openModal}
                        closeModal={this.closeModal}
                      />
                    </Box>
                    <hr className="mid-grey-bg" />
                    <Paragraph textColor="regent-grey" size="medium" className="total bold-font text-uppercase">
                      Total:
                      <strong className="main-color">
                        €
                        {' '}
                        {Number(total).toFixed(2)}
                      </strong>
                    </Paragraph>
                    <Box className="hidden-mb">
                      { this.renderBuyForm(2) }
                    </Box>
                  </Box>
                </Box>
              </Col>
            </>
          )}
          <CSSTransition
            in={completed}
            timeout={600}
            classNames="slide-right"
            unmountOnExit
          >
            <Container fluid>
              {this.renderSuccess()}
              <Paragraph className="align-center font-size-medium regent-grey-color">¿Quieres una tarjeta de regalo personalizada para imprimir en casa?</Paragraph>
              <Paragraph className="align-center font-size-medium regent-grey-color">
                Escríbenos a
                {' '}
                <a href="mailto:contacto@unycos.com" className="main-color">contacto@unycos.com</a>
              </Paragraph>
            </Container>
          </CSSTransition>
        </Row>
        <Modal
          isOpen={isModalOpened}
          appElement="#root"
          closeIcon={false}
          width={784}
          height={400}
          animate={{
            directionFrom: 'bottom',
            effectIni: 300,
            effectEnd: 200,
          }}
          className="modal-processing"
        >
          <Row>
            <Col xs={12} md={5} className="align-center">
              <Icon name="money-bill-wave" prefix="fas" size="big" color="black" className="pulsate" />
              <Heading type="h2" size="x-big" className="title normal-font text-uppercase with-letter-spacing">
                Estamos Procesando tu
                {' '}
                {modalText}
              </Heading>
              <Paragraph>Esto puede tardar algunos segundos no actualices la pantalla.</Paragraph>
            </Col>
            <Col xs={12} md={6} mdOffset={1} className="align-center">
              <Image src="./img/unycos/process-information.svg" alt="Processing Information" />
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

UnycosPaymentForm.propTypes = {
  gift: PropTypes.object,
  hasCompleted: PropTypes.func
};

export default UnycosPaymentForm;
