import React, {Component} from 'react';
import { Facebook } from 'react-content-loader'

import {
	Box
} from 'base-ui';

class Loader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [1,2,3]
		}
	}
	render() {
		return(
			<Box>
				{this.state.items.map(item => (
					<Box tag="article" className="platform-content-course">
						<Facebook />
					</Box>
				))}
			</Box>
		)
	}
}

export default Loader;