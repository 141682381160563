import React, { useState } from 'react';

// Base-UI components
import {
  PageWrapper,
  Modal,
  Box,
  Button
} from 'base-ui';

// Components
import SubjectsList from '../../../components/SubjectsList/SubjectsList';

// style
const styleModalMailchimp = {
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.80)',
    zIndex: '9999',
  },
  content: {
    position: 'absolute',
    right: '0px',
    left: '0px',
    bottom: '20px',
    margin: 'auto',
    boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
    overflow: 'auto',
    padding: '0px',
    borderRadius: '5px',
    display: 'block',
  },
  close: {
    position: 'absolute',
    right: '10px',
    top: '5px',
    zIndex: 1,
    cursor: 'pointer',
  },
};

// data
const subjects = [
  {
    id: 1,
    label: 'Educación',
    url: '#'
  },
  {
    id: 2,
    label: 'Relaciones sexuales',
    url: '#'
  },
  {
    id: 3,
    label: 'Relaciones afectivas',
    url: '#'
  },
  {
    id: 4,
    label: 'Pareja',
    url: '#'
  },
  {
    id: 5,
    label: 'Hijos / Familia',
    url: '#'
  },
  {
    id: 6,
    label: 'Comunicación',
    url: '#'
  },
  {
    id: 7,
    label: 'Personalidad',
    url: '#'
  },
  {
    id: 8,
    label: 'Desarrollo de aptitudes',
    url: '#'
  },
  {
    id: 9,
    label: 'Meditación',
    url: '#'
  },
  {
    id: 10,
    label: 'Inteligencia emocional / Manejo de emociones',
    url: '#'
  },
];

const selectedSubjects = [];

const MailchimpModal = () => {
  const [showModalMailchimp, setModalMailchimp] = useState(false);

  const clicked = (e) => {
    const index = selectedSubjects.indexOf(e);
    if (index < 0) { selectedSubjects.push(e); } else { selectedSubjects.splice(index, 1); }
  };

  return (
    <PageWrapper
      config={{
        title: 'Modal Mailchimp',
        bodyClasses: '',
      }}
    >
      <Box
        style={{
          height: '250px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Button
          onClick={() => { setModalMailchimp(true); }}
        >
          Open Modal
        </Button>
        <Modal
          isOpen={showModalMailchimp}
          appElement="#root"
          idModal="modal-mailchimp"
          className="modal"
          closeModal={() => setModalMailchimp(false)}
          closeIcon
          styles={styleModalMailchimp}
          width="1024px"
          height="470px"
        >
          <SubjectsList
            title="¿Cuáles son los temas que más te interesan?"
            list={subjects}
            onClick={(e) => clicked(e)}
          />
        </Modal>

      </Box>
    </PageWrapper>
  );
};

export default MailchimpModal;
