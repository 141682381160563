import React, { useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';

import {
  Box,
  Paragraph,
  InputField,
  Icon,
  Button
} from 'base-ui';

const CouponForm = ({
  onSubmitCoupon, couponActive, inputClasses, isUnycos
}) => {
  const [showCoupon, setShowCoupon] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [submitted, setSubmitted] = useState('');
  const [result, setResult] = useState('');
  const [error, setError] = useState('');

  const renderCoupon = () => {
    let render = '';

    const checkCoupon = (code) => {
      const res = onSubmitCoupon(code);
      setSubmitted(true);
      if (res.status === 'success') {
        setResult(
          <Paragraph textColor="main" className="success-message text-uppercase bold-font fade-in">
            <Icon name="check" prefix="fas" />
            {' '}
            { res.message }
          </Paragraph>
        );
      } else {
        setError(res.message);
      }
    };

    if (!showCoupon && !couponActive) {
      render = (
        <Button
          color="transparent"
          textColor={isUnycos ? 'main' : 'secondary'}
          size=""
          borderColor="transparent"
          onClick={() => setShowCoupon(true)}
          className="coupon-ask text-uppercase normal-font with-letter-spacing fade-in"
        >
          <Icon name="ticket-alt" prefix="fas" />
          {' '}
          ¿Tienes un cupón?
        </Button>
      );
    } else if (couponActive) {
      render = (
        <Paragraph textColor={isUnycos ? 'main' : 'secondary'} className="success-message text-uppercase bold-font fade-in">
          <Icon name="check" prefix="fas" />
          {' '}
          ¡Descuento aplicado! - 50%
        </Paragraph>
      );
    } else {
      render = (
        <React.Fragment>
          <Box className={cs('input-wrapper', { 'coupon-error': error })}>
            <InputField
              type="text"
              name="coupon"
              full={false}
              onChange={(e) => setCoupon(e)}
              placeholder="Código"
              autoFocus
              className={cs('rounded-small without-shadow text-uppercase fade-in', inputClasses)}
            />
            {error && (
              <Paragraph textColor="red" className="error-message fade-in">
                <Icon name="times" prefix="fas" />
                {' '}
                { error }
              </Paragraph>
            )}
          </Box>
          <Button
            tag="button"
            color={isUnycos ? 'main' : 'secondary'}
            textColor="white"
            borderColor={isUnycos ? 'main' : 'secondary'}
            onClick={() => checkCoupon(coupon)}
            className="confirm fade-in delay-1"
          >
            {isUnycos ? (
              <>Aplicar</>
            ) : (
              <Icon name="check" prefix="fas" />
            )}
          </Button>
          <Button
            tag="button"
            color="transparent"
            textColor="text"
            borderColor="text"
            onClick={() => {
              setShowCoupon(false);
              setCoupon('');
            }}
            className="cancel fade-in delay-2"
          >
            <Icon name="times" prefix="fas" />
          </Button>
        </React.Fragment>
      );
    }


    if (submitted && !error) {
      // eslint-disable-next-line
      return result;
    }
    // eslint-disable-next-line
    return render;
  };

  return renderCoupon();
};

CouponForm.defaultProps = {
  isUnycos: true
};

CouponForm.propTypes = {
  onSubmitCoupon: PropTypes.func,
  couponActive: PropTypes.bool,
  inputClasses: PropTypes.string,
  isUnycos: PropTypes.bool
};

export default CouponForm;
