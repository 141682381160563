import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Box,
  Row,
  Col,
  Image,
  Paragraph,
  Icon,
  Button,
  Heading
} from 'base-ui';

const UnycosSingleExclusive = ({ buttonText, onClick }) => {
  // -- state
  const [clicked, setClicked] = useState(false);

  // -- scripts
  const handleClick = () => {
    setClicked(!clicked);
    if (onClick !== undefined) {
      onClick(true);
    }
  };

  return (
    <Box className="unycos-exclusive-content">
      <Container fluid wrap>
        <Row className="around-xs top-xs bottom-md center-md">
          <Col xs="12 margin-bottom-x-medium hidden-md">
            <Heading type="h3" className="center-xs letter-spacing__normal normal-font text-uppercase white-color font-size-x-medium">Contenido exclusivo</Heading>
          </Col>
          <Col xs="3" md="2" className="center-xs align-center">
            <Icon>
              <Image className="icon" src="./img/unycos/icons/icon-books.svg" />
            </Icon>
            <Paragraph className="label line-height-medium center-xs start-md text-uppercase white-color font-size-medium">E-Books</Paragraph>
          </Col>
          <Col xs="3" md="2" className="center-xs align-center">
            <Icon>
              <Image className="icon" src="./img/unycos/icons/icon-tests.svg" />
            </Icon>
            <Paragraph className="label line-height-medium center-xs start-md text-uppercase white-color font-size-medium">Tests</Paragraph>
          </Col>
          <Col xs="3" md="2" className="center-xs align-center">
            <Icon>
              <Image className="icon" src="./img/unycos/icons/icon-edit.svg" />
            </Icon>
            <Paragraph className="label line-height-medium center-xs start-md text-uppercase white-color font-size-medium">Tareas</Paragraph>
          </Col>
          <Col xs="3" md="2" className="center-xs align-center">
            <Icon>
              <Image className="icon icon--certified" src="./img/unycos/icons/icon-certified.svg" />
            </Icon>
            <Paragraph className="label line-height-medium center-xs start-md text-uppercase white-color font-size-medium">Certificado</Paragraph>
          </Col>
        </Row>
        <Row className="center-xs margin-top-x-medium hidden-md">
          <Col xs="4">
            <Button className="main-bg main-border btn--medium black-color text-uppercase with-letter-spacing normal-font font-size-small bt-click" data-target="single-more-info" full onClick={handleClick}>{buttonText}</Button>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

UnycosSingleExclusive.defaultProps = {
  buttonText: 'Más información',
};

UnycosSingleExclusive.propTypes = {
  /*
  The text of the button
  */
  buttonText: PropTypes.string,

  /*
  The function to return de click
  */
  onClick: PropTypes.func,

};

export default UnycosSingleExclusive;
