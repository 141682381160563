/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';

import {
  PageWrapper,
  Box,
  Heading,
  Container,
  Row,
  Col,
  Paragraph,
  Icon
} from 'base-ui';

import CourseDesc from '../../../components/Single/CourseDesc';
import CourseExcerptMobile from '../../../components/Single/CourseExcerptMobile';
import YoullLearnMobile from '../../../components/Single/YoullLearnMobile';
import AboutTeacher from '../../../components/Single/AboutTeacher';
import CourseContentDesktop from '../../../components/Single/CourseContentDesktop';
import CourseContentMobile from '../../../components/Single/CourseContentMobile';
import CourseReviewsDesktop from '../../../components/Single/CourseReviewsDesktop';
import CourseReviewsMobile from '../../../components/Single/CourseReviewsMobile';
import WhatsIncluded from '../../../components/Single/WhatsIncluded';
import ToWhere from '../../../components/Single/ToWhere';
import BuyBtnMobile from '../../../components/Single/BuyBtnMobile';
import CourseSubscriptionWidget from '../../../components/Single/CourseSubscriptionWidget';
import SidebarWidgetInfo from '../../../components/Single/SidebarWidgetInfo';
import RelatedCourses from '../../../components/Single/RelatedCourses';
import YoullLearnDesktop from '../../../components/Single/YoullLearnDesktop';

class Single extends Component {
  componentDidMount() {
    document.body.classList.toggle('single-course');
  }

  render() {
    return (
      <PageWrapper config={{ title: 'Single course' }}>
        <Box id="single-course">
          <Box className="single-course-main titles-bg padding-top-small padding-bottom-big">
            <Container fluid wrap>
              <Row>
                <Col xs="12" md="8">
                  <Box className="course-preview-mobile hidden-lg">
                    <iframe title="iframe" width="560" height="315" src="https://www.youtube.com/embed/KmLVXffHLd4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </Box>
                  <Heading type="h1" size="big" textColor="white" className="line-height-medium">Del miedo escénico, al placer para hablar en público: Oratoria moderna</Heading>
                  <Paragraph className="yellow-color hidden-md">
                    <Icon prefix="fas" name="thumbs-up" />
                    100% de valoraciones positivas
                  </Paragraph>
                  <Paragraph className="alpha-color">
                    Un curso de <a href="." className="white-color">Ángel Lafuente Z.</a>
                  </Paragraph>
                  <hr className="alpha-bg" />
                  <Paragraph className="yellow-color hidden-lg">
                    <Icon prefix="fas" name="thumbs-up" />
                    100% de valoraciones positivas
                  </Paragraph>
                  <YoullLearnDesktop />
                  <CourseExcerptMobile />
                </Col>
              </Row>
            </Container>
          </Box>
          <Box>
            <Container fluid wrap>
              <Row>
                <Col xs="12" md="8" className="single-course-content margin-top-medium">
                  <BuyBtnMobile />
                  <BuyBtnMobile isFixed />
                  <YoullLearnMobile />
                  <CourseDesc />
                  <AboutTeacher />
                  <WhatsIncluded />
                  <ToWhere />
                  <CourseContentDesktop />
                  <CourseSubscriptionWidget />
                  <CourseContentMobile />
                  <CourseReviewsMobile />
                  <CourseReviewsDesktop />
                </Col>
                <Col xs="4" className="single-course-sidebar hidden-md">
                  <SidebarWidgetInfo />
                </Col>
              </Row>
              <RelatedCourses />
            </Container>
          </Box>
        </Box>
      </PageWrapper>
    );
  }
}

export default Single;
