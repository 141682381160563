export default {
  legal: {
    isLoading: false,
    content:
      [
        {
          ID: 270096,
          type: 'post_type',
          title: 'Quiénes somos',
          link: 'https://dev.mejorconsalud.com/quienes-somos/',
          description: '',
          item_id: 267208,
          slug: 'quienes-somos',
          name: 'Quiénes somos',
          order: 1,
          classes: null,
          children: null
        },
        {
          ID: 192379,
          type: 'post_type',
          title: 'Política de privacidad',
          link: 'https://dev.mejorconsalud.com/politica-de-privacidad/',
          description: '',
          item_id: 5372,
          slug: 'politica-de-privacidad',
          name: 'Política de privacidad',
          order: 2,
          classes: null,
          children: null
        },
        {
          ID: 192380,
          type: 'post_type',
          title: 'Términos y condiciones de uso',
          link: 'https://dev.mejorconsalud.com/terminos-y-condiciones-de-uso/',
          description: '',
          item_id: 109,
          slug: 'terminos-y-condiciones-de-uso',
          name: 'Términos y condiciones de uso',
          order: 3,
          classes: null,
          children: null
        },
        {
          ID: 192378,
          type: 'post_type',
          title: 'Política de cookies',
          link: 'https://dev.mejorconsalud.com/politica-de-cookies/',
          description: '',
          item_id: 51269,
          slug: 'politica-de-cookies',
          name: 'Política de cookies',
          order: 4,
          classes: null,
          children: null
        }
      ]
  },
  social_links: {
    isLoading: false,
    content: [
      {
        ID: 186607,
        type: 'custom',
        title: 'Facebook',
        link: 'https://www.facebook.com/mejorconsalud',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 1,
        classes: [
          'fab',
          'fa-facebook'
        ],
        children: null
      },
      {
        ID: 186610,
        type: 'custom',
        title: 'Pinterest',
        link: 'https://www.pinterest.com/EsMejorConSalud/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 2,
        classes: [
          'fab',
          'fa-pinterest'
        ],
        children: null
      },
      {
        ID: 186608,
        type: 'custom',
        title: 'Twitter',
        link: 'https://twitter.com/mejorconsalud',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 3,
        classes: [
          'fab',
          'fa-twitter'
        ],
        children: null
      },
      {
        ID: 186611,
        type: 'custom',
        title: 'Instagram',
        link: 'https://instagram.com/mejorconsalud/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 4,
        classes: [
          'fab',
          'fa-instagram'
        ],
        children: null
      },
      {
        ID: 318090,
        type: 'custom',
        title: 'YouTube',
        link: 'https://www.youtube.com/user/mejorconsaludblog',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 5,
        classes: [
          'fab',
          'fa-youtube'
        ],
        children: null
      }
    ]
  },
  footer_grid: {
    isLoading: false,
    content: {
      id: 'footer-grid',
      name: 'Footer Grid',
      description: 'Footer Grid',
      url: 'https://dev.mejorconsalud.com/wp-json/mc/v1/sidebars/footer-grid',
      widgets: [
        {
          id: 'nav_menu-2',
          name: 'Menú de navegación',
          type: 'nav_menu',
          options: {
            title: 'Nosotros',
            nav_menu: 18711
          },
          content: [
            {
              ID: 380098,
              type: 'post_type',
              title: 'Quiénes somos',
              link: 'https://dev.mejorconsalud.com/quienes-somos/',
              description: '',
              item_id: 267208,
              slug: 'quienes-somos',
              name: 'Quiénes somos',
              order: 1,
              classes: null,
              children: null
            }
          ]
        },
        {
          id: 'nav_menu-3',
          name: 'Menú de navegación',
          type: 'nav_menu',
          options: {
            title: 'Plataformas',
            nav_menu: 18710
          },
          content: [
            {
              ID: 380097,
              type: 'custom',
              title: 'Consultas',
              link: '/consultas/',
              description: '',
              item_id: null,
              slug: null,
              name: null,
              order: 1,
              classes: null,
              children: null
            }
          ]
        },
        {
          id: 'nav_menu-4',
          name: 'Menú de navegación',
          type: 'nav_menu',
          options: {
            title: 'Enlaces útiles',
            nav_menu: 6806
          },
          content: [
            {
              ID: 270096,
              type: 'post_type',
              title: 'Quiénes somos',
              link: 'https://dev.mejorconsalud.com/quienes-somos/',
              description: '',
              item_id: 267208,
              slug: 'quienes-somos',
              name: 'Quiénes somos',
              order: 1,
              classes: null,
              children: null
            },
            {
              ID: 192379,
              type: 'post_type',
              title: 'Política de privacidad',
              link: 'https://dev.mejorconsalud.com/politica-de-privacidad/',
              description: '',
              item_id: 5372,
              slug: 'politica-de-privacidad',
              name: 'Política de privacidad',
              order: 2,
              classes: null,
              children: null
            },
            {
              ID: 192380,
              type: 'post_type',
              title: 'Términos y condiciones de uso',
              link: 'https://dev.mejorconsalud.com/terminos-y-condiciones-de-uso/',
              description: '',
              item_id: 109,
              slug: 'terminos-y-condiciones-de-uso',
              name: 'Términos y condiciones de uso',
              order: 3,
              classes: null,
              children: null
            },
            {
              ID: 192378,
              type: 'post_type',
              title: 'Política de cookies',
              link: 'https://dev.mejorconsalud.com/politica-de-cookies/',
              description: '',
              item_id: 51269,
              slug: 'politica-de-cookies',
              name: 'Política de cookies',
              order: 4,
              classes: null,
              children: null
            }
          ]
        }
      ]
    }
  },
  version_links: {
    isLoading: false,
    content: [
      {
        ID: 380099,
        type: 'custom',
        title: 'Français (France)',
        link: 'https://amelioretasante.com',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 1,
        classes: null,
        children: null
      },
      {
        ID: 380100,
        type: 'custom',
        title: 'Italiano',
        link: 'https://viverepiusani.it/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 2,
        classes: null,
        children: null
      },
      {
        ID: 380101,
        type: 'custom',
        title: 'Português (Brasil)',
        link: 'https://melhorcomsaude.com.br/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 3,
        classes: null,
        children: null
      }
    ]
  },
  external_links: {
    isLoading: false,
    content: [
      {
        ID: 380102,
        type: 'custom',
        title: 'La mente es Maravillosa',
        link: 'https://lamenteesmaravillosa.com',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 1,
        classes: [
          'icon-lmem-alpha'
        ],
        children: null
      },
      {
        ID: 380103,
        type: 'custom',
        title: 'Eres mamá',
        link: 'https://eresmama.com/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 2,
        classes: [
          'icon-em-alpha'
        ],
        children: null
      },
      {
        ID: 380105,
        type: 'custom',
        title: 'Eres deportista',
        link: 'https://eresdeportista.com/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 3,
        classes: [
          'icon-dep-alpha'
        ],
        children: null
      },
      {
        ID: 380106,
        type: 'custom',
        title: 'Mi decoración',
        link: 'https://midecoracion.com/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 4,
        classes: [
          'icon-md-alpha'
        ],
        children: null
      },
      {
        ID: 380107,
        type: 'custom',
        title: 'Mi viaje',
        link: 'https://miviaje.com/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 5,
        classes: [
          'icon-mv-alpha'
        ],
        children: null
      },
      {
        ID: 380108,
        type: 'custom',
        title: 'Busco un coche',
        link: 'https://buscouncoche.com/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 6,
        classes: [
          'icon-buc-alpha'
        ],
        children: null
      },
      {
        ID: 380109,
        type: 'custom',
        title: 'Mis animales',
        link: 'https://misanimales.com/',
        description: '',
        item_id: null,
        slug: null,
        name: null,
        order: 7,
        classes: [
          'icon-ma-alpha'
        ],
        children: null
      }
    ]
  }
};
