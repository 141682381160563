import React from 'react';
import PropTypes from 'prop-types';
import makeSlug from '../../../utils/stringToSlug';

import {
  Box,
  Icon,
  Message,
  Heading,
  Image,
  NavLink
} from 'base-ui';

function renderDescription(description) {
  description = description.replace(/<\/?a[^>]*>/g, '');
  return description.replace(/(?:\r\n|\r|\n)/g, '<br>');
}

const TextContentPost = ({ className, data }) => (
  <Box tag="section" id={makeSlug(data.title)} className={`section-text ${data.date ? 'section-intro' : ''} ${className}`}>
    <Heading type={data.titleType}>
      <NavLink name={makeSlug(data.title)}>
        {data.title}
      </NavLink>
    </Heading>
    {data.date && (
      <Box className="metas">
        <span className="meta author">
          <Icon prefix="far" name="calendar" />
          {' '}
          {data.date}
        </span>
      </Box>
    )}
    {data.revision && (
      <Message iconPrefix="fas" iconName="check-double" borderColor="blue" textColor="gray" className="good single-assigned ">
        {data.revision.message}
        {' '}
        <NavLink href={data.revision.link} className="highlight blue-border">{data.revision.person}</NavLink>
      </Message>
    )}
    {data.image && (
      <>
        <figure>
          <Image src={data.image} alt={`Image ${data.title}`} />
          <figcaption>{data.imageLegend}</figcaption>
        </figure>
        <hr />
      </>
    )}
    <div dangerouslySetInnerHTML={{ __html: renderDescription(data.content) }} />
  </Box>
);

TextContentPost.defaultProps = {
  className: '',
};

TextContentPost.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default TextContentPost;
