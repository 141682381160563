import React from 'react';

import {
  Row,
  Col,
	Box,
	Image,
	Card,
	Button,
	Icon,
	Paragraph
} from 'base-ui';

const ForumComments = ({ items, className }) => {
  return (
    <Box className={`${className}`}>
			{items.map((items) =>
			<Card key={items._id}>
				<Row className="middle-xs around-xs">
					<Col xs="6" sm="2" className="center-xs">
						<Button borderColor="grey" className="align-center">
							<Icon prefix="fas" name="chevron-up" />
							<Box tag="strong" className="display-block margin-top-small align-center font-size-medium">{items.votes.number}</Box>
							<Box tag="p" className="font-size-small normal-font">Votos</Box>
						</Button>
					</Col>
					<Col xs="6" sm="2" className="first-xs last-sm">
						<Box className="author center-xs">
							<Image src={items.commentAuthorImg} className="author-avatar rounded x-small" />
							<Paragraph className="display-block align-center font-size-small">{items.commentAuthor}</Paragraph>
						</Box>
					</Col>
					<Col xs="12" sm="7" className="last-xs">
						<Box
							dangerouslySetInnerHTML={{
								__html: items.commentContent,
							}}
						/>
						<Box className="metas margin-bottom-normal">
							<Box tag="span" className="meta">Hace 30 minutos</Box>
						</Box>
					</Col>
				</Row>
			</Card>
			)}
		</Box>
  );
};

export default ForumComments;
