import React from 'react';

import {
  Box,
  Row,
  Col,
  Image
} from 'base-ui';

const WhatsIncluded = () => (
  <Box className="whats-include margin-top-x-medium">
    <Box className="section-title thin-border font-size-normal">
      <Box tag="span" className="label line-height-small without-margin text-uppercase titles-color">
        El curso incluye
      </Box>
    </Box>
    <Row className="middle-xs margin-top-normal">
      <Col xs="12" md="3" className="item start-xs center-md">
        <Box className="icon">
          <Image src="./img/modules-icon.svg" />
        </Box>
        <Box tag="span">17 Módulos</Box>
      </Col>
      <Col xs="12" md="6" className="item start-xs center-md">
        <Box className="icon">
          <Image src="./img/material-icon.svg" />
        </Box>
        <Box tag="span">Exclusivo material adicional</Box>
      </Col>
      <Col xs="12" md="3" className="item start-xs center-md">
        <Box className="icon">
          <Image src="./img/certificate-icon.svg" />
        </Box>
        <Box tag="span">Certificado</Box>
      </Col>
    </Row>
  </Box>
);

export default WhatsIncluded;
