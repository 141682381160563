import React from 'react';

import {
  Container,
  Row,
  Col,
  Box,
  Heading,
  Button,
  PageWrapper,
} from 'base-ui';

import CourseCard from '../../../components/Courses/CourseCard';

const ThankYou = () => (
  <PageWrapper config={{ title: 'Thank you' }}>
    <Box>
      <Container fluid wrap>
        <Row className="center-xs align-center margin-top-x-big">
          <Col xs="10" md="5">
            <Heading type="h1" size="x-medium" textColor="main" className="margin-bottom-medium">
              ¡Enhorabuena! Tu compra ha sido realizada con éxito.
            </Heading>
          </Col>
        </Row>
        <Row className="center-xs align-center margin-bottom-x-big">
          <Col xs="12" sm="8" md="5" className="wow tada" data-wow-duration="0.8s">
            <CourseCard
              courseImage="./img/categories/autoconocimiento.jpg"
              courseTitle="Mindfulness para la vida cotidiana"
            />
          </Col>
          <Col xs="12" className="center-xs">
            <Button color="main" borderColor="main" textColor="white" size="big">
              ¡Empieza ya!
            </Button>
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default ThankYou;
