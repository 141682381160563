import React from 'react';
import PropTypes from 'prop-types';

import {
  Heading,
  Box,
  NavLink,
  Image
} from 'base-ui';

const UnycosContentCourse = (props) => {
  const {
    image, teacher, course
  } = props;
  return (
    <Box className="content-course-unycos radius__normal">
      <NavLink to="#">
        <Box className="thumb radius__normal">
          <Image src={image} alt="title" />
        </Box>
        <Box className="caption">
          <Box>
            <Heading type="h2" textColor="white" className="teacher-name normal-font text-uppercase">
              {teacher}
            </Heading>
            <Heading type="h3" textColor="white" className="course-name font-size-normal normal-font">
              <Box tag="span" className="teaches text-uppercase">enseña</Box>
              <Box tag="span" className="text-uppercase">{course}</Box>
            </Heading>
          </Box>
        </Box>
      </NavLink>
    </Box>
  );
};

UnycosContentCourse.defaultProps = {
  image: './img/unycos/no-image_dark.png',
  teacher: 'Name Teacher',
  course: 'Course Title',
};

UnycosContentCourse.propTypes = {
  image: PropTypes.string,
  teacher: PropTypes.string,
  course: PropTypes.string
};

export default UnycosContentCourse;
