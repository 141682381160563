import React from 'react';

import {
  Container,
  PageWrapper,
} from 'base-ui';

import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';

const CoursesLoading = () => (
  <PageWrapper config={{ title: 'Courses Loading' }} className="page-loading align-center padding-top-big padding-bottom-x-big">
    <Container fluid wrap>
      <LoadingScreen label="button cta" imagesColor="main" />
    </Container>
  </PageWrapper>
);

export default CoursesLoading;
