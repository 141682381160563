import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Card,
  Heading,
  Paragraph
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';

const UnycosPrivacidad = () => (
  <PageWrapper className="default-page" config={{ title: 'Unycos - Política de Privacidad' }}>
    <UnycosLayout>
      <UnycosHeader statuslogged={false} />
      <Container fluid wrap>
        <Row className="around-xs middle-xs">
          <Col xs="12">
            <Heading type="h1" className="title-page margin-bottom-x-big margin-top-x-medium font-size-x-big light-white-color normal-font text-uppercase">Política de Privacidad</Heading>
          </Col>
          <Col xs="12" md="10">
            <Card className="card-default-text">

              <Paragraph>GRUPO M CONTIGO SL se compromete a proteger y respetar la privacidad de los usuarios que visitan y utilizan su web, cuya página principal se encuentra en: www.unycos.com (el “Sitio web”).</Paragraph>
              <Paragraph>A efecto de lo previsto en la legislación vigente en materia de Protección de Datos de Carácter Personal, GRUPO M CONTIGO SL con CIF B37527900 con domicilio en Calle Concejo, 13, 2ºA, 37002 Salamanca, informa al Usuario de la existencia de tratamientos de datos de carácter personal para el cumplimiento de las finalidades legítimas de la entidad y de la existencia de un registro de actividades de tratamiento.</Paragraph>
              <Paragraph>La utilización del Sitio web o de los servicios asociados implica la aceptación de las disposiciones contenidas en la presente Política de Privacidad, permitiendo que sus datos personales sean tratados según se estipula en la misma.</Paragraph>

              <Heading type="h4">Envío y registro de datos de carácter personal</Heading>
              <Paragraph>El envío de datos de carácter personal es obligatorio para contactar y recibir información sobre los productos y servicios ofrecidos por la entidad, por tanto deberá aceptarse la cláusula informativa en los diferentes medios de registro.</Paragraph>
              <Paragraph>El titular de los datos declara ser mayor de 14 años. Si un usuario proporciona datos de menores de 14 años, este garantiza el previo consentimiento de sus padres o tutores. Si GRUPO M CONTIGO SL lo requiere, el usuario tendrá que acreditar haber obtenido dicho consentimiento.</Paragraph>
              <Paragraph>No facilitar los datos personales solicitados o no aceptar la presente política de protección de datos supone la imposibilidad de suscribirse, registrarse o recibir información de los productos y servicios ofrecidos por la entidad.</Paragraph>

              <Heading type="h4">Exactitud y veracidad de los datos facilitados</Heading>
              <Paragraph>El Usuario que envía la información a GRUPO M CONTIGO SL es el único responsable de la veracidad, corrección y pertinencia de los datos incluidos, exonerando a GRUPO M CONTIGO SL. de cualquier responsabilidad al respecto.</Paragraph>
              <Paragraph>El Usuario garantiza y responde, en cualquier caso, de la exactitud, vigencia y autenticidad de los datos personales facilitados, y se compromete a mantenerlos debidamente actualizados.</Paragraph>
              <Paragraph>El usuario acepta proporcionar información completa y correcta en el formulario de registro o suscripción.</Paragraph>
              <Paragraph>GRUPO M CONTIGO SL no responde de la veracidad de las informaciones que no sean de elaboración propia y de las que se indique otra fuente, por lo que tampoco asume responsabilidad alguna en cuanto a hipotéticos perjuicios que pudieran originarse por el uso de dicha información.</Paragraph>
              <Paragraph>Se exonera a GRUPO M CONTIGO SL de responsabilidad ante cualquier daño o perjuicio que pudiera sufrir el Usuario como consecuencia de errores, defectos u omisiones, en la información facilitada por GRUPO M CONTIGO SL siempre que proceda de fuentes ajenas a la misma.</Paragraph>

              <Heading type="h4">Cesión de datos a terceros</Heading>
              <Paragraph>GRUPO M CONTIGO SL no cederá los datos de los usuarios a terceros. No obstante, en el caso de ser cedidos a alguna empresa, previamente a la cesión, se solicitará el consentimiento expreso del afectado.</Paragraph>

              <Heading type="h4">Ejercicio de derechos de acceso, rectificación, cancelación y resto</Heading>
              <Paragraph>Podrá dirigir sus comunicaciones y ejercitar los derechos de acceso, rectificación, cancelación y resto por correo: dirigido GRUPO M CONTIGO SL, Calle Concejo, 13, 2ºA, 37002 Salamanca o a través de la dirección de email protecciondedatos@mcontigo.com, indicando en el asunto “Protección de Datos”.</Paragraph>
              <Paragraph>Para ejercer dichos derechos y en cumplimiento de la Instrucción 1/1998, de 19 de enero, de la Agencia Española de Protección de Datos, es necesario que el usuario acredite su personalidad frente a GRUPO M CONTIGO SL mediante el envío de fotocopia de Documento Nacional de Identidad o cualquier otro medio válido en Derecho.</Paragraph>

              <Heading type="h4">Medidas de seguridad informática</Heading>
              <Paragraph>GRUPO M CONTIGO SL ha adoptado los niveles de seguridad de protección de los Datos Personales legalmente requeridos. No obstante, dispone de otros medios adicionales, como son firewalls de última generación, aparte de medidas técnicas como software para la encriptación de la información confidencial y control de acceso a la información de carácter personal, usuarios restringidos, políticas de seguridad, usuarios y contraseñas que caducan y otros sistemas orientados a evitar el mal uso, alteración, acceso no autorizado y robo de los Datos Personales facilitados a GRUPO M CONTIGO SL.</Paragraph>
              <Paragraph>GRUPO M CONTIGO SL no será responsable de posibles daños o perjuicios que se pudieran derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo de este sistema electrónico, motivadas por causas ajenas a la misma. Tampoco se hará responsable de retrasos o bloqueos en el uso del presente sistema electrónico causados por deficiencias o sobrecargas de líneas telefónicas o sobrecargas en el C.P.D. (Centro de Procesos de Datos), en el sistema de Internet o en otros sistemas electrónicos, así como de daños que puedan ser causados por terceras personas mediante intromisiones ilegítimas fuera del control de la entidad.</Paragraph>

              <Heading type="h4">Aceptación y consentimiento</Heading>
              <Paragraph>El Usuario declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento de los mismos por parte de GRUPO M CONTIGO SL, en la forma y para las finalidades indicadas en la presente Política de Protección de Datos Personales. La no aceptación supondrá la imposibilidad de la prestación del servicio solicitado.</Paragraph>
              <Paragraph>GRUPO M CONTIGO SL se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, GRUPO M CONTIGO SL anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.</Paragraph>

            </Card>
          </Col>
        </Row>
      </Container>
      <UnycosFooter className="hidden-lg" showHideElements={{ social: true, cta: false }} />
    </UnycosLayout>
  </PageWrapper>
);

export default UnycosPrivacidad;
