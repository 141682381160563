import React from 'react';
import {
  Box,
  Container,
  Row,
  Col,
  Image,
  Heading,
  Paragraph,
  Card
} from 'base-ui';

const UnycosTeacherComments = () => (
  <Box className="teachers-reviews margin-top-medium">
    <Container fluid wrap>
      <Row className="center-xs">
        <Col xs="12">
          <Heading type="h3" className="center-xs normal-font text-uppercase letter-spacing__normal white-color font-size-medium margin-bottom-normal">Lo Que dicen los profesores</Heading>
        </Col>
        <Col xs="12">
          <Box className="comments comments__hscroll--md">
            <Box className="horizontal-scroll-md">
              <Box className="comment">
                <Card className="radius__normal without-shadow small">
                  <Row className="middle-xs">
                    <Col xs="3">
                      <Box className="avatar avatar__size--small">
                        <Image
                          src="./img/teachers/josejuanrivero.png"
                          alt="teacher"
                          className="radius__normal"
                        />
                      </Box>
                    </Col>
                    <Col xs="9" className="without-padding-left">
                      <Box>
                        <Heading type="h4" size="normal" className="titles-color text-uppercase without-margin normal-font">Mireia Belmonte</Heading>
                        <Paragraph size="small" className="without-margin">@miss_belmont</Paragraph>
                      </Box>
                    </Col>
                  </Row>
                  <Paragraph size="small" className="without-margin-bottom line-height-medium titles-color">Todos queremos ser únicos en aquello que nos gusta. Si amas la natación, en este curso te enseñaré todas esas estrategias, rutinas y secretos que me han permitido alcanzar la élite en este deporte.</Paragraph>
                </Card>
              </Box>
              <Box className="comment">
                <Card className="radius__normal without-shadow small">
                  <Row className="middle-xs">
                    <Col xs="3">
                      <Box className="avatar avatar__size--small">
                        <Image
                          src="./img/teachers/josejuanrivero.png"
                          alt="teacher"
                          className="radius__normal"
                        />
                      </Box>
                    </Col>
                    <Col xs="9" className="without-padding-left">
                      <Box>
                        <Heading type="h4" size="normal" className="titles-color text-uppercase without-margin normal-font">Mireia Belmonte</Heading>
                        <Paragraph size="small" className="without-margin">@miss_belmont</Paragraph>
                      </Box>
                    </Col>
                  </Row>
                  <Paragraph size="small" className="without-margin-bottom line-height-medium titles-color">Todos queremos ser únicos en aquello que nos gusta. Si amas la natación, en este curso te enseñaré todas esas estrategias, rutinas y secretos que me han permitido alcanzar la élite en este deporte.</Paragraph>
                </Card>
              </Box>
              <Box className="comment">
                <Card className="radius__normal without-shadow small">
                  <Row className="middle-xs">
                    <Col xs="3">
                      <Box className="avatar avatar__size--small">
                        <Image
                          src="./img/teachers/josejuanrivero.png"
                          alt="teacher"
                          className="radius__normal"
                        />
                      </Box>
                    </Col>
                    <Col xs="9" className="without-padding-left">
                      <Box>
                        <Heading type="h4" size="normal" className="titles-color text-uppercase without-margin normal-font">Mireia Belmonte</Heading>
                        <Paragraph size="small" className="without-margin">@miss_belmont</Paragraph>
                      </Box>
                    </Col>
                  </Row>
                  <Paragraph size="small" className="without-margin-bottom line-height-medium titles-color">Todos queremos ser únicos en aquello que nos gusta. Si amas la natación, en este curso te enseñaré todas esas estrategias, rutinas y secretos que me han permitido alcanzar la élite en este deporte.</Paragraph>
                </Card>
              </Box>
              <Box className="comment">
                <Card className="radius__normal without-shadow small">
                  <Row className="middle-xs">
                    <Col xs="3">
                      <Box className="avatar avatar__size--small">
                        <Image
                          src="./img/teachers/josejuanrivero.png"
                          alt="teacher"
                          className="radius__normal"
                        />
                      </Box>
                    </Col>
                    <Col xs="9" className="without-padding-left">
                      <Box>
                        <Heading type="h4" size="normal" className="titles-color text-uppercase without-margin normal-font">Mireia Belmonte</Heading>
                        <Paragraph size="small" className="without-margin">@miss_belmont</Paragraph>
                      </Box>
                    </Col>
                  </Row>
                  <Paragraph size="small" className="without-margin-bottom line-height-medium titles-color">Todos queremos ser únicos en aquello que nos gusta. Si amas la natación, en este curso te enseñaré todas esas estrategias, rutinas y secretos que me han permitido alcanzar la élite en este deporte.</Paragraph>
                </Card>
              </Box>
            </Box>
          </Box>
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycosTeacherComments;
