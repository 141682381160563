import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Image,
  Card,
  NavLink,
  InputField,
  Label,
  Icon,
  Paragraph,
  Lists,
  Button,
  Modal,
  Badge,
  Slider
} from 'base-ui';

// Components
import AppointmentTime from '../../../components/Consultation/AppointmentTime';

const Single = () => {
  const [showModalDates, setModalDates] = useState(false);
  const [showSelected, setSelected] = useState(0);
  const [showComplementary, setComplementary] = useState(false);

  // show/hide info on ModalDates
  const [showOpenInfo, setOpenInfo] = useState(false);
  const toggle = () => setOpenInfo(!showOpenInfo);

  // data
  const arrayTime = [
    {
      label: '09:00',
      schedule: [
        false, true, true, true, true, true, true,
      ]
    },
    {
      label: '10:00',
      schedule: [
        false, true, true, true, true, true, true,
      ]
    },
    {
      label: '11:00',
      schedule: [
        false, true, true, true, true, true, true,
      ]
    },
    {
      label: '12:00',
      schedule: [
        false, true, true, true, true, true, true,
      ]
    },
    {
      label: '13:00',
      schedule: [
        false, true, true, true, true, true, true,
      ]
    },
    {
      label: '14:00',
      schedule: [
        false, true, true, true, true, true, true,
      ]
    },
    {
      label: '15:00',
      schedule: [
        false, true, true, true, true, true, false,
      ]
    },
    {
      label: '16:00',
      schedule: [
        false, true, true, true, true, true, false,
      ]
    },
    {
      label: '17:00',
      schedule: [
        false, true, true, true, true, true, false,
      ]
    },
    {
      label: '18:00',
      schedule: [
        false, true, true, true, true, true, false,
      ]
    },
    {
      label: '19:00',
      schedule: [
        false, true, true, true, true, true, false,
      ]
    },
    {
      label: '20:00',
      schedule: [
        false, true, true, true, true, true, false,
      ]
    },
    {
      label: '21:00',
      schedule: [
        false, true, true, true, true, true, false,
      ]
    },
  ];
  const consultData = {
    professional: {
      name: 'Lidia Beatriz\nPlaza García',
      thumb: './img/dummy/avatar-1-big.jpg',
      about: 'Soy coach personal, ejecutivo y sistémico certificada por ICF y estudiante de Psicología por la UOC.  Mentor VIP de Coaching al Cuadrado y CEO de www.coachingtualiado.com. Especializada en Desarrollo personal, Gestión del Tiempo y Proyectos, en mi web ayuda a profesionales y emprendedores a desarrollar sus nuevos proyectos personales y profesionales, a descubrirse y a saber organizar su tiempo para poder disfrutar de una vida en equilibrio.       Por propia experiencia he vivido la necesidad de reinventarme y comenzar de cero (en relaciones, trabajo, hábitos, mentalidad....). Mi vida se había convertido en una rutina que no me hacía feliz. Ahora soy una persona polifácetica y entusiasta y emocionada con mis nuevos proyectos y mi vida.\nComo coach profesional te ayudaré a trabajar tu nueva imagen personal, que estará alineada con tus valores, tu personalidad y te proporcionará de nuevo el bienestar y la paz interior que necesitas. Trabajaremos nuevos hábitos, volverás a ser dueño de tu tiempo y podrás poner en marcha nuevos proyectos personales y profesionales que conectan con tus talentos y potenciales, la mejor versión de ti mismo.\nSi estás en un momento difícil en tu vida y deseas recuperar tu bienestar personal y profesional, con ayuda de procesos de coaching y mentoría definidos:\n- encontrarás un paso a paso y una forma sencilla y amena de reencontrarte contigo mismo,\n- recuperarás de nuevo la ilusión, la confianza y la seguridad en tu vida\n- te sentirás mucho mejor, porque ahora estarás viviendo cada día una vida con sentido\n- te harás dueño de tu tiempo y tendrás energía y motivación para lograr tus objetivos.\nPuedes conocer más sobre mí en mi web www.coachingtualiado.com y en mi perfil de LinkedIn\nhttps://www.linkedin.com/in/lidiaplazacoach'
    },
    consult: {
      title: 'Coaching de Logro de metas',
      thumb: 'https://content.lamenteesmaravillosa.com/services/5d8c79bd39b0031021359bf1/image.jpeg',
      description: '',
      price: '60 €'
    }
  };

  // Modal Styles
  const styleModalDates = {
    overlay: {
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.80)',
      zIndex: '9999'
    },
    content: {
      position: 'absolute',
      right: '10px',
      left: '10px',
      bottom: '45px',
      margin: 'auto',
      boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
      overflow: 'auto',
      padding: '10px',
      borderRadius: '5px',
      display: 'block'
    },
    close: {
      position: 'absolute',
      right: '15px',
      top: '15px',
      zIndex: 1,
      cursor: 'pointer'
    }
  };

  const selectedHandle = (event) => {
    setSelected(event.target.id);
    setComplementary(true);
  };

  const table = arrayTime.map((hour) => (
    <tr key={hour.label}>
      <td>{hour.label}</td>
      {
        hour.schedule.map((item, index) => {
          const key = hour.label.replace(':', '') + index;
          const selected = (key === `${showSelected}`);
          const td = item ? <td key={key}><AppointmentTime id={key} selected={selected} click={selectedHandle}>{hour.label}</AppointmentTime></td> : <td key={key}></td>;
          return (
            td
          );
        })
      }
      <td></td>
    </tr>
  ));

  // Content form .box-highlight
  const BoxHighlight = (props) => {
    const {
      className
    } = props;
    return (
      <Box className={`box-highlight padding-small margin-top-small ${className}`}>
        <Paragraph className="titles-color">
          <Image src="./img/icons/videocall.svg" alt="Icon Videocall" />
          Videollamada
        </Paragraph>
        <Paragraph className="titles-color">
          <Image src="./img/icons/time.svg" alt="Icon time" />
          30 Min
        </Paragraph>
      </Box>
    );
  };
  BoxHighlight.propTypes = {
    className: PropTypes.string
  };

  return (
    <PageWrapper config={{ title: 'Consultation Single', bodyClasses: 'consultation consultation-single' }}>

      <Box tag="main" id="site-main">

        <Box tag="section">
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Heading type="h1" className="title font-x-big margin-bottom-normal without-margin-top">
                  {consultData.consult.title}
                </Heading>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="8" className="first-md last-xs">
                <Card className="medium">
                  <Row>
                    <Col xs="12" md="4">
                      <Box className="author margin-top-normal">
                        <NavLink to="profile" title="Professional Profile">
                          <Box className="author-avatar" style={{ backgroundImage: `url(${consultData.professional.thumb})` }} title="Lidia Beatriz Plaza García"></Box>
                        </NavLink>
                      </Box>
                      <Heading type="h2" className="author-name font-size-medium margin-top-normal">
                        <NavLink to="profile" title="Professional Profile">
                          {
                            consultData.professional.name.split('\n').map((item) => (
                              <span key={`${item}`}>
                                {item}
                                <br />
                              </span>
                            ))
                          }
                        </NavLink>
                      </Heading>
                    </Col>
                    <Col xs="12" md="8">
                      <Paragraph>
                        {
                          consultData.professional.about.split('\n').map((item) => (
                            <span key={`${item}`}>
                              {item}
                              <br />
                            </span>
                          ))
                        }
                      </Paragraph>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs="12" md="4">
                      <Heading type="h3">Descripción</Heading>
                    </Col>
                    <Col xs="12" md="8">
                      <div dangerouslySetInnerHTML={{ __html: consultData.consult.description }} />

                      <Paragraph>
                        ¿Tienes un proyecto o idea en mente y no sabes cómo llevarla a cabo?
                        <br />
                        ¿Deseas
                        {' '}
                        <strong>un acompañamiento en tu proyecto de negocio</strong>
                        {' '}
                        que te ayude a mantenerte motivado y concentrado en tus objetivos?
                        <br />
                        ¿Necesitas realizar *
                        <em>una planificación de un proyecto o evento *</em>
                        y te sientes frustrado y desbordado porque no sabes cómo acometerlo?
                      </Paragraph>
                      <Paragraph>Mediante sesiones de coaching estratégico y de gestión de proyectos conseguirás:</Paragraph>
                      <Lists>
                        <li>Mantener la orientación constante y enfocada en tu objetivo. </li>
                        <li>Trabajar tu proyecto desde sus bases, con una completa planificación y seguimiento de metas.</li>
                        <li>Mantener la claridad y la motivación constante</li>
                        <li>Saber afrontar los retos aprovechando el aprendizaje y la información que te  proporcionan para tu crecimiento personal y profesional.</li>
                        <li>Mantener una perspectiva que te ilusione cada día y que haga disfrutar de tu pasión</li>
                        <li>Mejorar tu productividad para disfrutar al máximo de tu trabajo en equilibrio con tus valores y tu vida personal.</li>
                      </Lists>
                      <Paragraph>Comienza a utilizar tus habilidades y talentos con este coaching para lograr tus metas. Consigue tu máximo potencial.</Paragraph>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs="12" md="4">
                      <Heading type="h3">
                        Política
                        <br />
                        de cancelación
                      </Heading>
                    </Col>
                    <Col xs="12" md="8">
                      <Paragraph>
                        <strong>Política flexible</strong>
                        <NavLink className="highlight main-border" target="_blank" href="/consultas/policia-de-cancelacion/flexible/">
                          Verifica la política de cancelación
                        </NavLink>
                      </Paragraph>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col id="sidebar" xs="12" md="4" className="consultation-sidebar">
                <Box className="widget consultation-sticky-widget">
                  <Card className="with-top-thumb">
                    <Box className="thumb">
                      <Box className="thumb-wrapper">
                        <Image loading="auto" alt="Coaching de Logro de metas" className="without-margin-top full" src={consultData.consult.thumb} srcset="" title="Coaching de Logro de metas" />
                      </Box>
                    </Box>
                    <Box className="caption">
                      <Row className="middle-xs">
                        <Col xs="6">
                          <strong>
                            <span>
                              {consultData.consult.price}
                              {' '}
                            </span>
                          </strong>
                          <span>por consulta</span>
                        </Col>
                        <Col xs="6" className="end-xs">
                          <NavLink className="btn main-bg main-border white-color" role="presentation" onClick={() => setModalDates(true)}>
                            <Icon prefix="far" name="calendar" />
                            {' '}
                            <span>Pedir cita</span>
                          </NavLink>
                        </Col>
                      </Row>
                      <hr />
                      <Box className="align-center">
                        <NavLink className="grey-border full">
                          <Icon prefix="fas" name="gift" className="blue-color" />
                          {' '}
                          <span>Comprar como regalo</span>
                        </NavLink>
                        <Box className="align-center">
                          <NavLink role="presentation">
                            <Box role="button" tabindex="0" className="SocialMediaShareButton SocialMediaShareButton--facebook btn white-bg white-border facebook-color">
                              <Icon prefix="fab" name="facebook" />
                            </Box>
                          </NavLink>
                          <NavLink role="presentation">
                            <Box role="button" tabindex="0" className="SocialMediaShareButton SocialMediaShareButton--twitter btn white-bg white-border twitter-color">
                              <Icon prefix="fab" name="twitter" />
                            </Box>
                          </NavLink>
                          <NavLink role="presentation">
                            <NavLink className="btn white-bg white-border text-color" event="Share" data="[object Object]" role="presentation">
                              <Icon prefix="far" name="envelope" />
                            </NavLink>
                          </NavLink>
                          <NavLink className="white-bg transparent-border small text-color full">
                            <Icon prefix="far" name="flag" className="red-color" />
                            {' '}
                            <span>Denunciar este servicio</span>
                          </NavLink>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                  <Box className="widget">
                    <Box id="widget-service-request-information">
                      <strong className="font-medium margin-top-big margin-bottom-small display-block">
                        <span>Enviar mensaje</span>
                      </strong>
                      <form>
                        <Box className="field-with-icon">
                          <Icon prefix="far" name="user" />
                          <InputField
                            type="text"
                            id="name"
                            full
                            className="without-shadow"
                            name="name"
                            value="Henrique Sikorski"
                            placeholder="Nombre"
                          />
                        </Box>
                        <Box className="field-with-icon">
                          <Icon prefix="fas" name="envelope" />
                          <InputField
                            type="email"
                            id="email"
                            className="without-shadow"
                            name="email"
                            value="hsikorski@mcontigo.com"
                            placeholder="Correo electrónico"
                          />
                        </Box>
                        <Box className="field-with-icon">
                          <Icon prefix="fas" name="phone" />
                          <InputField
                            type="tel"
                            id="phone"
                            inputmode="numeric"
                            full
                            className="without-shadow"
                            name="phone"
                            placeholder="Teléfono"
                          />
                        </Box>
                        <Box className="field-with-icon">
                          <Icon prefix="fas" name="pencil-alt" />
                          <InputField
                            type="textarea"
                            id="message"
                            full
                            className="textarea without-shadow"
                            name="message"
                            placeholder="Tus dudas"
                          />
                        </Box>
                        <Row>
                          <Col xs="12" md="8">
                            <InputField
                              id="a2"
                              type="checkbox"
                              required
                            />
                            <Label inputId="a2">
                              <span className="lightweight-font">He leído y acepto la </span>
                              <NavLink target="_blank" href="/privacidad/">Política de privacidad</NavLink>
                            </Label>
                          </Col>
                          <Col xs="12" md="4" className="end-xs">
                            <Button className="bt-send blue-border blue-bg white-color" type="submit">
                              <span>Enviar</span>
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    </Box>
                  </Box>
                  <Card className="card-reviews medium without-border without-shadow">
                    <Box id="reviews"></Box>
                  </Card>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>

        {/* ini #modal-dates */}
        <Modal
          isOpen={showModalDates}
          appElement="#root"
          idModal="modal-dates"
          className="modal"
          closeModal={() => setModalDates(false)}
          closeIcon
          styles={styleModalDates}
          width="990px"
          height="700px"
        >
          <Row className="date-tabs">
            <Col xs="12" className="hidden-md">
              <Heading type="h2" className="display-block main-font normal-font font-medium text-uppercase align-center without-margin-top margin-bottom-medium">
                SELECCIONA UNA
                {' '}
                <strong>FECHA</strong>
                {' '}
                Y
                {' '}
                <strong>HORA</strong>
              </Heading>
              <Heading type="h1" className="font-m-big align-center lmem-color without-margin-bottom">Febrero 2020</Heading>
              <br />
            </Col>

            <Col xs="12" md="4" className="last-md">
              <Card className="without-shadow without-border">
                <Heading type="h3" className="without-margin-top titles-color font-big ">
                  Estás reservando una
                </Heading>
                <Box className="info-professional">
                  <Image src="./img/dummy/author.jpg" className="thumb-60" alt="Thumb Avatar Professional Daniela Fluchaire" />
                  <Paragraph className="line-height-x-medium lmem-color text-uppercase font-big ">
                    <strong>consulta online</strong>
                    {' '}
                    CON
                    {' '}
                    <strong>DANIELA FLUCHAIRE</strong>
                  </Paragraph>
                </Box>
                <Box id="group-text-more-info" className={showOpenInfo ? 'open' : 'close'}>
                  <Box className="text-more-info">
                    <Paragraph className="line-height-medium">
                      La consulta con el psicólogo se llevará a cabo por
                      {' '}
                      <strong>videollamada en nuestra plataforma.</strong>
                    </Paragraph>
                    <Paragraph className="line-height-medium">Podrás estar en contacto con el profesional en todo momento.</Paragraph>
                    <BoxHighlight className="visible-sm hidden-lg" />
                  </Box>
                  <Button color="white" textColor="main" borderColor="transparent" id="bt-see" onClick={toggle}>
                    <strong id="bt-see-open" className="aqua-color">Ver Más</strong>
                    <strong id="bt-see-close" className="aqua-color">Ver Menos</strong>
                  </Button>
                </Box>

                <BoxHighlight className="hidden-md" />

                {showComplementary && (
                  <Box id="box-complementary">
                    <Box className="box-date padding-xsmall">
                      <Paragraph className="titles-color">
                        <strong className="lmem-color font-size-normal">¿Cuándo?</strong>
                        <br />
                        Miércoles, 13 de Febrero
                        <br />
                        12:00 - 12:30
                      </Paragraph>
                    </Box>
                    <Box className="box-highlight box-price padding-xsmall margin-bottom-small">
                      <Paragraph className="lmem-color"><strong>Precio</strong></Paragraph>
                      <Paragraph className="font-big lmem-color"><strong>50 €</strong></Paragraph>
                    </Box>
                    <Button href="consultation/thank-you/" full color="lmem" borderColor="lmem" textColor="white">
                      Reservar
                    </Button>
                  </Box>
                )}
              </Card>
            </Col>
            <Col xs="12" md="8">
              <Box className="v-scroll">
                <Box className="content-v-scroll">
                  <Slider responsive={[{ breakpoint: 2500, settings: { centerPadding: true, slidesToShow: 1, slidesToScroll: 1 } }]}>

                    <Box>
                      <table className="dates-table" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Dom.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">01</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mar.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">02</Badge>
                            </th>
                            <th className="center">
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mier.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">03</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mier.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">04</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Jue.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">05</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Vie.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">06</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Sab.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">07</Badge>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                      </table>
                    </Box>
                    <Box>
                      <table className="dates-table" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Dom.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">08</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mar.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">09</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mier.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">10</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mier.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">11</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Jue.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">12</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Vie.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">13</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Sab.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">14</Badge>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                      </table>
                    </Box>
                    <Box>
                      <table className="dates-table" cellSpacing="0" cellPadding="0">
                        <thead>
                          <tr>
                            <th></th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Dom.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">15</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mar.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">16</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mier.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">17</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Mier.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">18</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Jue.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">19</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Vie.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">20</Badge>
                            </th>
                            <th>
                              <Box tag="span" className="day display-block align-center">
                                <strong>Sab.</strong>
                              </Box>
                              <Badge rounded color="grey" className="font-big">21</Badge>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                      </table>
                    </Box>

                  </Slider>
                  <table className="dates-table appointments" cellSpacing="0" cellPadding="0">
                    <tbody>
                      {table}
                    </tbody>
                  </table>
                </Box>
              </Box>
            </Col>
          </Row>
        </Modal>
        {/* end #modal-dates --> */}

      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};


export default Single;
