import React from 'react'

import {
  Row,
  Col,
	Box,
	Heading,
	Lists,
	Card,
	Tabs,
	Icon
} from 'base-ui';

const PricingTableMobile = () => {
	const PlanTabBasic =
	<Card className="center-xs align-center">
		<Icon prefix="fas" name="user" color="main" className="plan-icon" />
		<Heading size="medium">Básico</Heading>
		<Box tag="span" className="font-size-small main-color">9.90€ / Mes</Box>
	</Card>;
	const PlanTabPremium =
	<Card className="center-xs align-center">
		<Icon prefix="fas" name="ticket-alt" color="main" className="plan-icon" />
		<Heading size="medium">Premium</Heading>
		<Box tag="span" className="font-size-small main-color">19.90€ / Mes</Box>
	</Card>;
	return(
		<Box className="pricing-plans mobile position-relative margin-bottom-medium">
			<Row className="center-xs">
				<Col xs="12" sm="8" className="align-center center-xs margin-bottom-small">
					<Heading type="h2" size="x-medium">Selecciona el mejor plan para ti</Heading>
				</Col>
			</Row>
			<Tabs align="center">
				<Box label={PlanTabBasic}>
					<Card className="pricin-plans-features">
						<Lists className="pricing-plans-list">
							<li>Acceso a más de 30 cursos</li>
							<li>1 curso nuevo por mes</li>
							<li>Disponible en todos los dispositivos</li>
							<li>Cancela en cualquier momento</li>
							<li>Seguimiento del avance del curso</li>
							<li><del className="regent-grey-color">Contenido offline en las apps</del></li>
							<li><del className="regent-grey-color">Revisión de test y trabajos</del></li>
							<li><del className="regent-grey-color">30% descuento en nuestra tienda</del></li>
						</Lists>
					</Card>
				</Box>
				<Box label={PlanTabPremium}>
					<Card className="pricin-plans-features">
						<Lists className="pricing-plans-list">
							<li>Acceso a más de 30 cursos</li>
							<li>1 curso nuevo por mes</li>
							<li>Disponible en todos los dispositivos</li>
							<li>Cancela en cualquier momento</li>
							<li>Seguimiento del avance del curso</li>
							<li>Contenido offline en las apps</li>
							<li>Revisión de test y trabajos</li>
							<li>30% descuento en nuestra tienda</li>
						</Lists>
					</Card>
				</Box>
			</Tabs>
		</Box>
	)
}

export default PricingTableMobile;