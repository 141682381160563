import React from 'react';
import PropTypes from 'prop-types';

// Base-UI Components
import {
  Box,
  Heading,
  NavLink,
  Paragraph,
  Image,
  Icon
} from 'base-ui';

const BlogArticle = ({
  thumb, title, subcategory, description, linkTo, className, isBookmark, children
}) => (
  <Box tag="article" className={className}>
    <NavLink to={linkTo}>
      <Box className="thumb">
        {isBookmark ? (
          <span className="remove">
            {children}
          </span>
        ) : (
          <span className="favorite">
            <Icon name="bookmark" />
          </span>
        )}
        <Box className="thumb-wrap" style={{ backgroundImage: `url(${thumb})` }}>
          <Image src={thumb} alt={title} className="thumb-bg" style={{ display: 'none' }} />
        </Box>
      </Box>
    </NavLink>
    <Box className="caption">
      <Box className="metas">
        <span className="meta car">
          <NavLink to={linkTo}>{subcategory}</NavLink>
        </span>
      </Box>
      <Heading type="h2" className="title">
        <NavLink to={linkTo}>
          {title}
        </NavLink>
      </Heading>
      <Paragraph className="excerpt" textColor="regent-grey">
        {description}
      </Paragraph>
    </Box>
  </Box>
);

BlogArticle.defaultProps = {
  className: '',
  isBookmark: false
};

BlogArticle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  thumb: PropTypes.string.isRequired,
  subcategory: PropTypes.string,
  linkTo: PropTypes.string.isRequired,
  className: PropTypes.string,
  isBookmark: PropTypes.bool,
  children: PropTypes.element
};

export default BlogArticle;
