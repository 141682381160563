import React from 'react';

import {
  Card,
  Box,
  NavLink,
  Lists,
  Icon
} from 'base-ui';

const MenuConsults = () => (
  <React.Fragment>
    <Card>
      <Box class="box-title without-margin-bottom">
        <Box tag="span" class="label main-font">Navegación</Box>
      </Box>
      <Lists class="with-icons">
        <li>
          <NavLink to="consultations" class="display-block">
            <Icon prefix="far" name="check-square" className="main-color" />
            {' '}
            Tus consultas
          </NavLink>
        </li>
        <li>
          <NavLink to="schedule-manage" class="display-block normal-font">
            <Icon prefix="far" name="calendar-alt" className="regent-grey-color" />
            {' '}
            Gestionar agenda
          </NavLink>
        </li>
        <li>
          <NavLink to="clinics" class="display-block normal-font">
            <Icon prefix="far" name="hospital" className="regent-grey-color" />
            {' '}
            Mis clínicas
          </NavLink>
        </li>
      </Lists>
    </Card>
    <NavLink to="publish" className="btn full main-border white-bg main-color">
      Publicar nueva consulta
    </NavLink>
  </React.Fragment>
);


export default MenuConsults;
