import React, { useState } from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Search
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogArticle from '../../components/Blog/BlogArticle';
import BlogMorePostsList from '../../components/Blog/BlogMorePostsList';

const articles = [
  {
    id: 1,
    title: 'Maneras de comenzar una rutina diaria y no morir en el intento',
    description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-13.png',
    link: '/single'
  },
  {
    id: 2,
    title: 'Rutina diaria y no morir en el intento',
    description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-3.png',
    link: '/single'
  },
  {
    id: 3,
    title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
    description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-4.png',
    link: '/single'
  },
  {
    id: 4,
    title: 'Etiam at tristique ipsum, sed gravida lorem.',
    description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-5.png',
    link: '/single'
  },
  {
    id: 5,
    title: '9 things I wish I knew before playing Rainbow Six Siege',
    description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-6.png',
    link: '/single'
  },
  {
    id: 6,
    title: 'Si quieres llevar una vida más sana sigue estos 4 increíbles consejos',
    description: 'Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…',
    subcategory: 'Subcategoria',
    thumb: './img/dummy/post-7.png',
    link: '/single'
  }
];

// const relatedLinks = [
//   {
//     title: 'Como eliminar el bello de la cara de manera natural',
//     url: '#'
//   },
//   {
//     title: 'Recetas que te ayudan a mejorar la circulación',
//     url: '#'
//   },
//   {
//     title: '5 posiciones sexuales más placenteras para ellas',
//     url: '#'
//   }
// ];

const BlogSearch = () => {
  const [search, setSearch] = useState({
    searchOn: false,
    searchError: false,
    searchQuery: ''
  });
  const [totalResults, setTotalResults] = useState(5);
  const [searchQueryIni, setSearchQueryIni] = useState('InitialQuery');

  const eventHandler = (searchQuery) => {
    if (searchQuery === '') {
      setSearch({
        searchOn: false,
        searchError: false,
        searchQuery
      });
      setSearchQueryIni(searchQuery);
      setTotalResults(0);
    } else if (searchQuery.length < 6) {
      setSearch({
        searchOn: true,
        searchError: true,
        searchQuery
      });
      setSearchQueryIni(searchQuery);
      setTotalResults(0);
    } else {
      setSearch({
        searchOn: true,
        searchError: false,
        searchQuery
      });
      setSearchQueryIni(searchQuery);
      setTotalResults(10);
    }
  };
  const resultSearch = () => {
    let title = '';
    if (!search.searchOn) {
      title = 'Artículos interesantes';
    }
    if (search.searchOn && totalResults > 0) {
      title = 'Consultas econtradas';
    }
    if (totalResults === 0) {
      title = 'Quizás te pueda gustar';
    }

    // random array order, just for simulate diferent results
    const func = () => (
      0.5 - Math.random()
    );
    const nArticles = articles.sort(func);

    const DataBlock = () => (
      <Box className="search-result">
        { (!search.searchOn) && (
          articles.map((article) => (
            <BlogArticle
              key={article.id}
              thumb={article.thumb}
              title={article.title}
              subcategory={article.subcategory}
              linkTo={article.link}
              className="content-post news"
            />
          ))
        )}
        { (search.searchOn && !search.searchError) && (
          nArticles.map((article) => (
            <BlogArticle
              key={article.id}
              thumb={article.thumb}
              title={article.title}
              subcategory={article.subcategory}
              linkTo={article.link}
              className="content-post news"
            />
          ))
        )}
        { (search.searchError) && (
          nArticles.map((article) => (
            <BlogArticle
              key={article.id}
              thumb={article.thumb}
              title={article.title}
              subcategory={article.subcategory}
              linkTo={article.link}
              className="content-post news"
            />
          ))
        )}
      </Box>
    );
    return (
      <React.Fragment>
        <Box>
          <Box tag="span" className="align-center titles-color bold-font font-m-big padding-big display-block">
            {title}
          </Box>
        </Box>
        <DataBlock />
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <BlogMenu />
      <PageWrapper config={{ title: 'Busca' }}>
        <Container fluid wrap>
          <Box tag="main" id="site-main">
            <Row>
              <Col xs="12">

                <Search
                  titleIni="Búsqueda"
                  titleResult="Resultados para"
                  titleNoResult="No hemos encontrado resultados para"
                  gotoText="Puedes intentar buscando algo más o puedes"
                  gotoLink="/"
                  gotoLabel="regresar al inicio"
                  placeholder="Búsqueda por..."
                  onChange={eventHandler}
                  searchQueryIni={searchQueryIni}
                  totalResults={totalResults}
                >
                  {resultSearch()}
                </Search>

              </Col>
            </Row>
          </Box>
          <BlogMorePostsList />
        </Container>
      </PageWrapper>
    </React.Fragment>
  );
};

export default BlogSearch;
