import React from 'react';
import {
  Container,
  Box,
  Row,
  Col,
  Heading,
  Button
} from 'base-ui';

const UnycosBlogArticles = () => (
  <Box className="margin-bottom-big">
    <Container fluid wrap>
      <Row className="center-xs">
        <Col xs="12">
          <Heading type="h3" className="center-xs letter-spacing__normal normal-font text-uppercase white-color font-size-x-medium without-margin-top margin-bottom-big">Artículos relacionados</Heading>
        </Col>
        <Col xs="12" md="9">
          <Row>
            <Col xs="12" md="6">
              <Box>
                <a href=".">
                  <Heading type="h2" textColor="white" className="line-height__medium text-uppercase normal-font without-margin" size="normal">Consejos para nadadores principiantes</Heading>
                  <Box>
                    <Box tag="span" className="text-color__white--dark font-size-small">22.06.09</Box>
                  </Box>
                  <hr />
                </a>
              </Box>
            </Col>
            <Col xs="12" md="6">
              <Box>
                <a href=".">
                  <Heading type="h2" textColor="white" className="line-height__medium text-uppercase normal-font without-margin" size="normal">Recomendaciones para lograr que un niño venza el miedo al agua</Heading>
                  <Box>
                    <Box tag="span" className="text-color__white--dark font-size-small">22.06.09</Box>
                  </Box>
                  <hr />
                </a>
              </Box>
            </Col>
            <Col xs="12" md="6">
              <Box>
                <a href=".">
                  <Heading type="h2" textColor="white" className="line-height__medium text-uppercase normal-font without-margin" size="normal">¿Nadar es un buen método para adelgazar?</Heading>
                  <Box>
                    <Box tag="span" className="text-color__white--dark font-size-small">22.06.09</Box>
                  </Box>
                  <hr />
                </a>
              </Box>
            </Col>
            <Col xs="12" md="6">
              <Box>
                <a href=".">
                  <Heading type="h2" textColor="white" className="line-height__medium text-uppercase normal-font without-margin" size="normal">Consejos para mejorar la técnica del estilo Crol</Heading>
                  <Box>
                    <Box tag="span" className="text-color__white--dark font-size-small">22.06.09</Box>
                  </Box>
                </a>
              </Box>
            </Col>
          </Row>
        </Col>
        <Col xs="12" md="4" className="margin-top-normal">
          <Button borderColor="white" color="transparent" textColor="white" className="text-uppercase normal-font" full>Leer más en nuestro blog</Button>
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycosBlogArticles;
