import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Card,
  Heading,
  Paragraph
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';

const UnycosProtecion = () => (
  <PageWrapper className="default-page" config={{ title: 'Unycos - Cláusula Informativa y de Consentimento' }}>
    <UnycosLayout>
      <UnycosHeader statuslogged={false} />
      <Container fluid wrap>
        <Row className="around-xs middle-xs">
          <Col xs="12">
            <Heading type="h1" className="title-page margin-bottom-x-big margin-top-x-medium font-size-x-big light-white-color normal-font text-uppercase">Términos y Condiciones</Heading>
          </Col>
          <Col xs="12" md="10">
            <Card className="card-default-text">
              <Heading type="h4">¿Quién es el responsable del tratamiento de sus datos?</Heading>
              <blockquote>
                <Paragraph>
                  <strong>Identidad:</strong>
                  {' '}
  GRUPO M CONTIGO, S.L
                  <br />
                  <strong>CIF:</strong>
                  {' '}
  B37527900
                  <br />
                  <strong>Dir. postal:</strong>
                  {' '}
  C/ Consejo,12 37002 / Salamanca
                  <br />
                  <strong>Teléfono:</strong>
                  {' '}
  923267346
                  <br />
                  <strong>Email:</strong>
                  {' '}
  protecciondedatos@mcontigo.com
                  <br />
                </Paragraph>
              </blockquote>

              <Heading type="h4">¿Con qué finalidad tratamos sus datos personales?</Heading>
              <Paragraph> En GRUPO M CONTIGO, S.L tratamos la información que nos facilitan las personas interesadas con el fin de cumplir con la prestación del servicio.</Paragraph>

              <Heading type="h4">¿Por cuánto tiempo conservaremos sus datos?</Heading>
              <Paragraph>Los datos personales proporcionados se conservarán mientras se preste el servicio o posteriormente para posibles responsabilidades legales y para el caso de comunicaciones comerciales hasta que retire el consentimiento.</Paragraph>

              <Heading type="h4">¿Cuál es la legitimación para el tratamiento de sus datos?</Heading>
              <Paragraph>La base legal para el tratamiento de sus datos es el consentimiento del interesado.</Paragraph>

              <Heading type="h4">¿A qué destinatarios se comunicarán sus datos?</Heading>
              <Paragraph>Los datos no se ceden a terceros ajenos a la empresa.</Paragraph>

              <Heading type="h4">¿Cuáles son sus derechos cuando nos facilita sus datos?</Heading>
              <Paragraph>Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos personales que les conciernan, o no.  </Paragraph>
              <Paragraph>Las personas interesadas tienen derecho a si acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.  </Paragraph>
              <Paragraph>En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.  </Paragraph>
              <Paragraph>En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En ese caso dejaremos de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones. Le informamos también del derecho a tutela de la autoridad de protección de datos, www.agpd.es.</Paragraph>

            </Card>
          </Col>
        </Row>
      </Container>
      <UnycosFooter className="hidden-lg" showHideElements={{ social: true, cta: false }} />
    </UnycosLayout>
  </PageWrapper>
);

export default UnycosProtecion;
