import React, { useState } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Icon,
  Heading,
  InputField,
  Label,
  Paragraph,
  NavLink
} from 'base-ui';

// Components
import MenuPublish from '../../../components/Consultation/MenuPublish';

const PublishPart3 = () => {
  const [showMenuPublish, setShowMenuPublish] = useState(false);

  const handlerClickMenuPublish = () => {
    setShowMenuPublish(!showMenuPublish);
  };

  return (
    <PageWrapper config={{ title: 'Consultation Publish Part 3', bodyClasses: `consultation publish ${showMenuPublish ? 'open-aside' : ''}` }}>

      <Box tag="main" id="site-main">

        <MenuPublish toggleAction={handlerClickMenuPublish} />

        <Box tag="section" id="single" className="single-publish">
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Card className="medium">
                  <Heading type="h4">Política de cancelación</Heading>
                  <InputField
                    name="cancellation_policy"
                    value="accept"
                    type="radio"
                    id="r1"
                  />
                  <Label inputId="r1">
                  Política flexible
                  </Label>
                  <Paragraph>(i)  Para obtener el reembolso íntegro de las tarifas de servicios, la cancelación debe realizarse al menos 5 días antes de la hora de reserva del día en el que se presta el servicio. No se devolverán los gastos de gestión. En caso que la reserva sea cancelada entre 24 horas y 5 días antes de la fecha de la prestación del servicio, se devolverá el 75% del importe pagado. En caso que la reserva sea cancelada dentro de las 24 horas antes de la fecha de la prestación del servicio, se devolverá el 50% del importe pagado.</Paragraph>
                  <Paragraph>
                    (ii)
                    {' '}
                    <strong>La tarifa de servicio se puede reembolsar hasta 3 veces al año en un plazo de 20 días</strong>
                    {' '}
                    desde el momento de la reserva. La posibilidad de recibir el reembolso dependerá del número de cancelaciones previas.
                  </Paragraph>
                  <Paragraph>(iii) En caso que exista alguna queja por alguna de las partes, es necesario avisar a Grupo M Contigo, SL, propietario de “La mente es maravillosa” en un plazo de 24 horas o menos, tras haber disfrutado del servicio.</Paragraph>
                  <Paragraph>(iv) Grupo M Contigo, SL, mediará entre ambas partes cuando sea necesario y tomará la decisión definitiva en caso que existan problemas. </Paragraph>
                  <Paragraph>(v) Un servicio no queda oficialmente cancelado hasta que el cliente no hace clic en el botón de la página de cancelación que encontrará en (Tus reservas &gt; Servicio &gt; Cancelar &gt; Motivo)</Paragraph>
                  <Paragraph>(vi) En caso de reembolso, la parte que haya cancelado el servicio tendrá que asumir los costes de los impuestos correspondientes. Se retendrán los impuestos aplicables</Paragraph>
                  <hr className="margin-top-x-big" />
                  <NavLink to="publish-4" className="btn main-bg main-border white-color big">
                    Continuar
                    {' '}
                    <Icon prefix="fas" name="arrow-right" />
                  </NavLink>
                </Card>
              </Col>
            </Row>
          </Container>
        </Box>
      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};

export default PublishPart3;
