import React from 'react';
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Icon,
  Button,
  NavLink,
  Paragraph,
  Message
} from 'base-ui';

// Components
import MenuAccount from '../../../components/Consultation/MenuAccount';
import MenuLogged from '../../../components/Consultation/MenuLogged';

const ChargesPreferences = () => (
  <PageWrapper config={{ title: 'Consultation Accout Charges Preferences', bodyClasses: 'consultation' }}>

    <MenuLogged />

    <Box tag="main" id="site-main">

      <Box tag="section" className="consultation-account">
        <Container container fluid wrap>
          <Row className="center-xs">
            <Col xs="12" sm="4">
              <MenuAccount />
            </Col>
            <Col xs="12" sm="8">
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Preferencias de cobro</Box>
                </Box>
                <Box>
                  <Paragraph>
                    Generamos un informe mensual con los ingresos que has obtenido y emitimos una factura, realizamos la transferencia por el método de pago que elijas. Posteriormente, te enviamos el justificante y la factura de
                    {' '}
                    <NavLink>nuestras comisiones</NavLink>
                    <span>
                      Se aplica un 6,5% de comisión en el total generado mensualmente en concepto de transferencia del pago a tu cuenta. En el caso de pacientes captados a través de nuestra plataforma, se cobrará el 100% de la primera consulta del paciente bajo concepto de uso de la plataforma. Si tienes pacientes en terapia presencial u otra plataforma online, te proporcionaremos un cupón con el cual NO se aplicará la comisión del 100% de la primera consulta.
                    </span>
                  </Paragraph>
                  <Message iconName="info-circle" color="blue" textColor="white" iconPrefix="fas">
                    Para completar tu proceso y recebir pagos, debes tener al meno un método de cobro registrado.
                  </Message>
                  <Row>
                    <Col xs="12">
                      <table className="full">
                        <tr>
                          <th>Método</th>
                          <th>Estado</th>
                          <th></th>
                        </tr>
                        <tr>
                          <th>
                            Transferencia bancaria
                            {' '}
                            <small className="badge grey-bg">Predeterminado</small>
                          </th>
                          <th><Box tag="span" className="green-color">Activo</Box></th>
                          <th>
                            <Button borderColor="red" color="white" textColor="red">
                              <Icon prefix="fas" name="trash" />
                            </Button>
                          </th>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <Button to="#" borderColor="main" textColor="main" color="white">
                    <Icon prefix="fas" name="plus" />
                    {' '}
                    Agregar método de cobro
                  </Button>
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default ChargesPreferences;
