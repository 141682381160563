import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Box,
  Heading
} from 'base-ui';

// Components
import CardContentPost from '../CardPost';

const LastArticleSidebar = ({
  className, title, titleType, data
}) => (
  <Box className={`box-last-articles ${className}`}>
    {title && (
      <Heading type={titleType} className="titleLine">{title}</Heading>
    )}
    <Row>
      {data.map((item) => (
        <Col xs="12" sm="6" md="12" key={item.id}>
          <CardContentPost
            className="without-shadow without-border without-padding"
            post={{
              url: item.url,
              thumb: item.thumb,
              category: item.category,
              title: item.title
            }}
          />
        </Col>
      ))}
    </Row>
  </Box>
);


LastArticleSidebar.defaultProps = {
  className: '',
};

LastArticleSidebar.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  titleType: PropTypes.string,
  className: PropTypes.string
};

export default LastArticleSidebar;
