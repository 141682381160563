import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Icon,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Breadcrumbs,
  Card,
  Paragraph,
  Courses,
  PageWrapper,
} from 'base-ui';

class Wishlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialItems: [
        {
          id: '1',
          title: 'Reinvéntate',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/reinventate/img/shutterstock_1171469926.jpg',
          instructors: [
				{ displayName: 'Agustín Andrade' }
		  ],
          duration: '15 módulos - 7 horas',
        },
        {
					id: '2',
          title: 'Oratoria moderna',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-oratoria-moderna/img/oratoria.jpg',
		  instructors: [
				{ displayName: 'Ángel Lafuente Zorrilla' }
	  	  ],
          duration: '15 módulos - 7 horas',
        }
      ],
      items: []
    };
  }

  componentDidMount() {
    const { initialItems } = this.state;
    this.setState({
      items: initialItems
    });
  }

  render() {
    const { items } = this.state;
    return (
      <PageWrapper config={{ title: 'Wish list' }}>
        <Box>
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Box>
                  <Breadcrumbs className="margin-bottom-small">
                    <Link to=".">
                      <Icon prefix="fas" name="home" />
                       Home
                    </Link>
                    <Link to=".">Cuenta</Link>
                    <Box tag="span">Lista de deseos</Box>
                  </Breadcrumbs>
                  <Card>
                    <Row className="middle-xs">
                      <Col xs="3" sm="2" className="align-center center-xs">
                        <Icon name="heart" className="big main-color" />
                      </Col>
                      <Col xs="9" md="8">
                        <Heading type="h1" className="font-size-x-medium main-font without-margin">
                          Lista de deseos
                        </Heading>
                        <Paragraph>
                          Guarda los cursos que más te interesen para disfrutar de sus contenidos de forma más
                          rápida o para realizarlos más tarde.
                        </Paragraph>
                      </Col>
                    </Row>
                  </Card>
                </Box>
              </Col>
              <Col xs="12">
                <Box className={'courses-row'}>
                  <Courses
                    className="without-fixed-height"
                    items={items}
                    hasWishlistButton
                  />
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
      </PageWrapper>
    );
  }
}

export default Wishlist;
