import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Row,
  Col,
  Slider,
  Card,
  NavLink,
  Image,
  Heading,
  Paragraph,
} from 'base-ui';

class BlogCategoryHero extends React.PureComponent {
  render() {
    const {
      content
    } = this.props;
    const contentSlider = content.map((value) => (
      <Card key={value.id}>
        <NavLink to="#" aria-label={value.title}>
          <Image alt={value.title} src={value.image} />
          {' '}
          <Heading type="h2" className="font-medium">{value.title}</Heading>
          <Paragraph>
            Last update
            {' '}
            {value.time}
            {' '}
            ago
          </Paragraph>
        </NavLink>
      </Card>
    ));
    return (
      <Box tag="section" id="box-slider">
        <Row>
          <Col xs="12">
            <Slider autoplay responsive={[{ breakpoint: 2500, settings: { slidesToShow: 4, arrows: false, slidesToScroll: 1 } }, { breakpoint: 1024, settings: { slidesToShow: 3, arrows: false, slidesToScroll: 1 } }, { breakpoint: 485, settings: { slidesToShow: 2, arrows: false, slidesToScroll: 1 } }]}>
              {contentSlider}
            </Slider>
          </Col>
        </Row>
      </Box>
    );
  }
}

BlogCategoryHero.defaultProps = {
  content: null,
};

BlogCategoryHero.propTypes = {
  content: PropTypes.array,
};

export default BlogCategoryHero;
