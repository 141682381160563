/* eslint-disable radix */
import 'intersection-observer';

import React, { Component } from 'react';
import WOW from 'wow.js';
import ThemePicker from '../Utils/ThemePicker';
import Router from '../Router';

import 'base-ui/dist/lib/assets/css/index';

const cssFileNames = [
  { name: 'LMEM', file: '_themes/lmem' },
  { name: 'MCS', file: '_themes/mcs' },
  { name: 'BUC', file: '_themes/buc' },
  { name: 'ED', file: '_themes/ed' },
  { name: 'EM', file: '_themes/em' },
  { name: 'MA', file: '_themes/ma' },
  { name: 'MD', file: '_themes/md' },
  { name: 'MV', file: '_themes/mv' },
  { name: 'VBB', file: '_themes/vbb' },
  { name: 'MS', file: '_themes/ms' },
  { name: 'UC', file: '_themes/uc' },
  { name: 'UNYCOS', file: '_themes/unycos' },
];

function getUrlParameter(name) {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

// Init App Main Component
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { picker: false, theme: cssFileNames[0].name };
  }

  componentWillMount() {
    let picker = localStorage.getItem('picker');
    if (getUrlParameter('picker') === '1') {
      picker = true;
    } else if (getUrlParameter('picker') === '0') {
      picker = false;
    }
    if (picker !== null) {
      localStorage.setItem('picker', picker);
    }
    this.setState({ picker });

    if (window.location.pathname.startsWith('/unycos')) {
      this.setState({ picker: true });
      this.setState({ theme: 'UNYCOS' });
    }
  }

  componentDidMount() {
    const wow = new WOW();
    wow.init();
  }

  render() {
    const { picker, theme } = this.state;

    function loadDefaultTheme(val) {
      import(`base-ui/assets/dist/${val}.css`).then(() => {
        // eslint-disable-next-line no-console
        console.log('imported');
      });
    }

    return (
      <div>
        {process.env.NODE_ENV !== 'production' || picker
          ? <ThemePicker themes={cssFileNames} defaultTheme={theme} />
          : loadDefaultTheme(cssFileNames[0].file)
        }
        <Router />
      </div>
    );
  }
}

export default App;
