import React, { Component } from 'react';

// Base-UI Components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Card,
  Icon,
  Image,
  Box,
  Heading,
  NavLink
} from 'base-ui';

// Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogMorePostsList from '../../components/Blog/BlogMorePostsList';
import BlogArticle from '../../components/Blog/BlogArticle';

class BlogAuthor extends Component {
  state = {
    profilePicture: '',
    author: '',
    location: '',
    articlesNumber: 0,
    socialMedia: [],
    bio: '',
    articles: [],
    pageConfig: {}
  }

  componentDidMount() {
    this.setState({
      profilePicture: 'https://content.lamenteesmaravillosa.com/users/5bbe108030c1ac536a2cd2c5/profile.png',
      author: 'Valeria Sabater',
      location: 'Valencia, España',
      articlesNumber: 1024,
      socialMedia: [
        {
          type: 'facebook',
          url: '#'
        },
        {
          type: 'twitter',
          url: '#'
        },
        {
          type: 'instagram',
          url: '#'
        },
        {
          type: 'youtube',
          url: '#'
        },
      ],
      bio: 'Soy psicóloga y escritora. La curiosidad por el conocimiento humano es mi cerradura particular, la psicología mi llave, la escritura, mi pasión.',
      articles: [
        {
          id: 1,
          title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-2.png',
          slug: 'article-slug-link'
        },
        {
          id: 2,
          title: '9 things I wish',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-3.png',
          slug: 'article-slug-link'
        },
        {
          id: 3,
          title: '9 things I wish I knew before playing Rainbow Six Siege Outbreak mode',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-4.png',
          slug: 'article-slug-link'
        },
        {
          id: 4,
          title: '10 maneras de comenzar una rutina diaria y no morir en el intento Rainbow Six Siege Outbreak mod',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-2.png',
          slug: 'article-slug-link'
        },
        {
          id: 5,
          title: '9 things I wish I knew before ',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-3.png',
          slug: 'article-slug-link'
        },
        {
          id: 6,
          title: '9 things I wish I knew before playing Rainbow',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-4.png',
          slug: 'article-slug-link'
        },
        {
          id: 7,
          title: '10 maneras de comenzar una rutina diaria y no morir en el intento',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-2.png',
          slug: 'article-slug-link'
        },
        {
          id: 8,
          title: '9 things I wish I knew before playing Rainbow Six Siege Outbreak mode',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-3.png',
          slug: 'article-slug-link'
        },
        {
          id: 9,
          title: '9 things I wish I knew before playing Rainbow Six Siege Outbreak mode',
          subcategory: 'Subcategoria',
          thumb: './img/dummy/post-4.png',
          slug: 'article-slug-link'
        },
      ],
      pageConfig: {
        title: 'Valeria Sabater',
        bodyClasses: 'author-page',
        meta: [
          { name: 'description', content: 'Soy psicóloga y escritora. La curiosidad por el conocimiento humano es mi cerradura particular, la psicología mi llave, la escritura, mi pasión.' }
        ]
      }
    });
  }

  render() {
    const {
      profilePicture,
      author,
      location,
      articlesNumber,
      bio,
      socialMedia,
      articles,
      pageConfig
    } = this.state;

    return (
      <React.Fragment>
        <BlogMenu />
        <PageWrapper config={pageConfig}>
          <Container fluid wrap>
            <main id="site-main">
              <Row>
                <Col xs="12">
                  <Card size="medium" className="author author-box align-center">
                    <Box className="author-avatar">
                      <Image
                        src={profilePicture}
                        alt={author}
                        className="thumb-bg"
                        style={{ objectFit: 'cover' }}
                        maxWidth={120}
                        maxHeight={120}
                      />
                    </Box>
                    <Row className="center-xs">
                      <Col xs="12">
                        <Heading type="h1" className="label font-x-big">{ author }</Heading>
                        <Box className="metas align-center">
                          <Box className="meta location">
                            <Icon name="compass" color="main" />
                            { location }
                          </Box>
                          <Box className="meta articles-number">
                            <Icon name="edit" color="main" />
                            <strong>{ articlesNumber }</strong>
                            {' '}
                            Artículos redactados
                          </Box>
                        </Box>
                      </Col>
                      <Col xs="12" className="align-center">
                        <hr />
                        { socialMedia.map((item) => (
                          <NavLink key={item.type} to={item.url} target="_blank" className={`btn transparent-bg ${item.type}-color transparent-border`}>
                            <Icon prefix="fab" name={item.type} />
                          </NavLink>
                        ))}
                      </Col>
                      <Col xs="12" sm="10">
                        <blockquote className="biography font-big without-border align-center">
                          {bio}
                        </blockquote>
                      </Col>
                    </Row>
                    <br />
                  </Card>
                </Col>
                <Col xs="12">
                  <Box tag="section" id="latest-post-widget" className="widget">
                    <Box className="section-title align-center">
                      <Box tag="span" className="label">
                        Artículos de
                        {' '}
                        {author}
                      </Box>
                    </Box>
                    <Box className="posts-row">
                      { articles.map((article) => (
                        <BlogArticle
                          key={article.id}
                          title={article.title}
                          subcategory={article.subcategory}
                          thumb={article.thumb}
                          linkTo={article.slug}
                          className="content-post news"
                        />
                      )) }
                    </Box>
                  </Box>
                </Col>
              </Row>
            </main>
            <BlogMorePostsList />
          </Container>
        </PageWrapper>
      </React.Fragment>
    );
  }
}

export default BlogAuthor;
