import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  InputSelect,
  InputField
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';

class Inputs extends React.Component {
  constructor() {
    super();
    this.state = {
      basic: '',
      withLabel: '',
      withError: '',
      styles: { padding: '1rem', textAlign: 'center', backgroundColor: 'whitesmoke' },
      list: [],
      autocompleteValue: '',
    };
  }

  componentDidMount() {
    fetch('https://jsonplaceholder.typicode.com/users/')
      .then((response) => response.json())
      .then((data) => data.map((item) => (
        this.setState((prevState) => ({
          list: prevState.list.concat({ label: item.name, value: item.email })
        }))
      )));
  }

  render() {
    const {
      basic,
      withLabel,
      withError,
      styles,
      list,
      autocompleteValue
    } = this.state;

    return (
      <React.Fragment>
        <BlogMenu />
        <PageWrapper config={{ title: 'Test Select Input' }}>
          <Container fluid wrap>
            <Box tag="main" id="site-main" style={{ marginBottom: '200px' }}>
              <Row className="center-xs">
                <Col xs="12" md="5">
                  <Box>
                    <h3>Basic select with placeholder and value</h3>
                    <hr />
                    <InputSelect
                      name="genre"
                      value={basic}
                      onChange={(v) => this.setState({ basic: v })}
                      required
                      placeholder="Select Gender"
                      options={[
                        { value: 'male', label: 'Male' },
                        { value: 'female', label: 'Female', disabled: true },
                      ]}
                    />
                    <div style={styles}>
                      {basic || 'No value selected'}
                    </div>
                    <br />
                    <br />
                    <h3>Select with label</h3>
                    <hr />
                    <InputSelect
                      label="Genre"
                      name="genre"
                      value={withLabel}
                      onChange={(v) => this.setState({ withLabel: v })}
                      required
                      options={[
                        { value: 'male', label: 'Male' },
                        { value: 'female', label: 'Female' },
                      ]}
                    />
                    <div style={styles}>
                      {withLabel || 'No value selected'}
                    </div>
                    <br />
                    <br />
                    <h3>Select with error and disabled</h3>
                    <hr />
                    <InputSelect
                      label="Genre"
                      name="genre"
                      value={withError}
                      onChange={(v) => this.setState({ withError: v })}
                      required
                      disabled
                      options={[
                        { value: 'male', label: 'Male' },
                        { value: 'female', label: 'Female' },
                      ]}
                      meta={{
                        touched: 'true', error: 'Required field', warning: 'false',
                      }}
                    />
                    <div style={styles}>
                      {withError || 'No value selected'}
                    </div>
                    <br />
                    <br />
                    <h3>Select with autocomplete</h3>
                    <hr />
                    <InputSelect
                      label="Genre"
                      name="genre"
                      value={autocompleteValue}
                      onChange={(v) => this.setState({ autocompleteValue: v })}
                      required
                      placeholder="Select User"
                      autocomplete
                      options={list}
                      meta={{
                        touched: 'true', error: 'Required field', warning: 'false',
                      }}
                    />
                    <div style={styles}>
                      {autocompleteValue || 'No value selected'}
                    </div>
                    <br />
                    <br />
                    <h3>Input Radio</h3>
                    <hr />
                    <InputField type="radio" label="male" name="gender" id="male" big />
                    <InputField type="radio" label="female" name="gender" id="female" big />
                  </Box>
                </Col>
              </Row>
            </Box>
          </Container>
        </PageWrapper>
      </React.Fragment>
    );
  }
}

export default Inputs;
