import React from 'react';

import {
  Box,
  Image,
  Paragraph,
  Icon
} from 'base-ui';

const CourseReviewsMobile = () => (
  <Box id="course-reviews" className="margin-bottom-x-big hidden-lg">
    <Box className="section-title thin-border without-margin-bottom font-size-normal">
      <Box tag="span" className="label line-height-small without-margin text-uppercase titles-color">
        Valoraciones
      </Box>
    </Box>
    <Box>
      <Paragraph className="secondary-color without-margin-top">
        <Box tag="strong">
          <Icon prefix="fas" name="thumbs-up" />
          100% de valoraciones positivas
        </Box>
      </Paragraph>
    </Box>
    <Box>
      <Box className="horizontal-scroll-negative-md">
        <Box className="horizontal-scroll-md">
          <Box className="course-review margin-bottom-x-medium">
            <Box className="avatar rounded">
              <Image src="./img/teachers/cristina.jpg" />
            </Box>
            <Box className="author">
              <Box tag="strong" className="name">Rosa Pereira</Box>
            </Box>
            <Paragraph className="review without-margin-top">&quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.&quot;</Paragraph>
          </Box>
          <Box className="course-review margin-bottom-x-medium">
            <Box className="avatar rounded">
              <Image src="./img/teachers/claudia.png" />
            </Box>
            <Box className="author">
              <Box tag="strong" className="name">Demelsa Yanes</Box>
            </Box>
            <Paragraph className="review without-margin-top">&quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.&quot;</Paragraph>
          </Box>
          <Box className="course-review margin-bottom-x-medium">
            <Box className="avatar rounded">
              <Image src="./img/teachers/josejuanrivero.png" />
            </Box>
            <Box className="author">
              <Box tag="strong" className="name">Mauricio Bravo</Box>
            </Box>
            <Paragraph className="review without-margin-top">&quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididu.&quot;</Paragraph>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default CourseReviewsMobile;
