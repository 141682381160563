import React, { Component } from 'react';
import {
  Button,
  CourseSectionsPlayer, Heading, Image, Paragraph
} from 'base-ui';

class UnycosStartCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalBuy: true,
      modalRetry: false,
      modalFinish: false,
    };
  }

  backToMenu(e) {
    e.preventDefault();
    this.setState({});
  }

  closeBuyModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalBuy: false, modalRetry: true });
  }

  closeRetryModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalRetry: false, modalFinish: true });
  }

  closeFinishModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalFinish: false });
  }

  render() {
    const { modalBuy, modalRetry, modalFinish } = this.state;

    return (
      <section id="start-course" className="start-course">
        <CourseSectionsPlayer
          playerLinks={{ headerIconURL: '/' }}
          withModal={false}
          currentModal={null}
          enrollmentData={{ progress: 28.82562277580071, user: '5a40fb5cc30ed72342ac0b94' }}
          enrollmentModules={[
            {
              _id: '58ac61cd29f0631c98ba62cf1',
              name: 'Acerca del positivismo',
              url: '/',
              sections: [{
                _id: '58ac61cd29f0631c98ba62cf2',
                name: 'Introducción',
                type: 'text',
                url: '/',
              },
              {
                _id: '58ac61cd29f0631c98ba62cf3',
                name: '¿Qué es el positivismo?',
                type: 'text',
                url: '/',
              },
              {
                id: '58b6e535414b4e7903a3f350',
                url: '/',
                section: {
                  model: 'file',
                  name: 'Autoevaluación: ¿Qué tan positivamente estoy viviendo mi realidad?',
                  url: 'https://content.lamenteesmaravillosa.com/courses/descubriendo-al-ser-positivo-que-hay-dentro-de-ti/pdf/autoevaluacion-mi-realidad.pdf'
                }
              }]
            },
            {
              _id: '58ac61cd29f0631c98ba62cf4',
              name: 'Psicología positiva',
              sections: [{
                _id: '58ac61cd29f0631c98ba62cf5',
                name: '¿Sobre la actitud y el pensamiento positivo?',
                type: 'text',
                url: '/',
              }]
            }
          ]}
          current={{
            id: '58b6e535414b4e7903a3f350',
            url: '/',
            section: {
              model: 'file',
              name: 'Autoevaluación: ¿Qué tan positivamente estoy viviendo mi realidad?',
              url: 'https://content.lamenteesmaravillosa.com/courses/descubriendo-al-ser-positivo-que-hay-dentro-de-ti/pdf/autoevaluacion-mi-realidad.pdf'
            }
          }}
        />

        <div className={`start-course__dialog course-dialog ${modalBuy ? 'visible' : ''}`}>
          <div className="course-dialog__content">
            <Image className="course-dialog__img" src="/assets/images/graduationcap-icon.png"></Image>
            <Heading type="h2" className="course-dialog__title">¿Quieres realizar el curso?</Heading>
            <Paragraph className="course-dialog__text">Haz clic en comprar y comienza a disfrutar del curso.</Paragraph>
            <Button className="course-dialog__btn inverse" onClick={(e) => { this.closeBuyModal(e); }}>Comprar Curso</Button>
          </div>
        </div>

        <div className={`finish-course__dialog course-dialog ${modalRetry ? 'visible' : ''}`}>
          <div className="course-dialog__content">
            <div className="course-dialog__stars">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star-half-alt"></i>
              <i className="far fa-star"></i>
              <i className="far fa-star"></i>
            </div>
            <Heading type="h2" className="course-dialog__title">Vaya has estado cerca de conseguirlo?</Heading>
            <Paragraph className="course-dialog__text">Has acertado 3 de 10. Solo puede ter una respuesta errônea.</Paragraph>
            <Paragraph className="course-dialog__text">Vuelve a intentarlo y lo lograrás :)</Paragraph>
            <Button className="course-dialog__btn inverse" onClick={(e) => { this.closeRetryModal(e); }}>Intentar ahora</Button>
            <Button className="course-dialog__btn" onClick={(e) => { this.closeRetryModal(e); }}>Intentar más tarde</Button>
          </div>
        </div>

        <div className={`finish-course__dialog course-dialog ${modalFinish ? 'visible' : ''}`}>
          <div className="course-dialog__content">
            <div className="course-dialog__stars">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
            <Heading type="h2" className="course-dialog__title">Enhorabuena!</Heading>
            <Paragraph className="course-dialog__text">Has completado con éxito el test.</Paragraph>
            <Button className="course-dialog__btn inverse" onClick={(e) => { this.closeFinishModal(e); }}>Continuar</Button>
          </div>
        </div>
      </section>
    );
  }
}

export default UnycosStartCourse;
