import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import {
  Breadcrumbs,
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  NavLink,
  Button,
  Heading,
  Lists,
  Image,
  Icon
} from 'base-ui';

// Components
import BlogMenu from '../../../components/Blog/BlogMenu';
import SliderFeaturedPosts from '../../../components/Blog/SliderFeaturedPosts';
import TopicsList from '../../../components/Blog/TopicsList';
import TextContent from '../../../components/Blog/TextContent';
import SliderPosts from '../../../components/Blog/SliderPosts';
import CardContentPost from '../../../components/Blog/CardPost';
import AccordionFaq from '../../../components/Blog/AccordionFaq';
import LastArticlesSidebar from '../../../components/Blog/LastArticlesSidebar';
import BoxBibliography from '../../../components/Blog/BoxBibliography';

// Utils
import makeSlug from '../../../utils/stringToSlug';

// Data
import {
  wrapperConfig, contentMenu, symptoms, lastArticles, relevantArticles, featuredPost, relatedArticles, faqQuestions, moreArticles
} from './ContentData';

const BlogDiseases = () => {
  const refs = contentMenu.reduce((acc, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  // Scroll Into View API: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
  // Script from: https://www.robinwieruch.de/react-scroll-to-item
  const handleClickAnchor = (id) => {
    console.log(`clicked ${id}`);
    /*
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    */
  };

  return (
    <>
      <BlogMenu />
      <Container fluid wrap>
        <Row>
          <Col xs="12">
            <Breadcrumbs className="margin-top-medium">
              <NavLink href="#" aria-label="Inicio">Home</NavLink>
              <NavLink href="#" aria-label="Enfermedades">Enfermedades</NavLink>
              <NavLink href="#" aria-label="Cutáneas">Cutáneas</NavLink>
              <Box tag="span">Acné</Box>
            </Breadcrumbs>
          </Col>
        </Row>
      </Container>
      <PageWrapper config={wrapperConfig}>
        <Box tag="main" id="site-main">
          <Container fluid wrap>
            <Row>
              <Col xs="12" md="8">
                <div ref={refs[contentMenu[0].id]}>
                  <TextContent data={contentMenu[0]} className="margin-bottom-big" />
                </div>

                <TopicsList
                  title="En este artículo"
                  titleType="h3"
                  data={contentMenu.slice(1, contentMenu.length)}
                  handler={handleClickAnchor}
                />

                <SliderFeaturedPosts
                  className="margin-top-big"
                  data={relevantArticles}
                />

                <div ref={refs[contentMenu[1].id]}>
                  <TextContent data={contentMenu[1]} />
                </div>

                <Box tag="section" className="section-single-featured-post">
                  <CardContentPost
                    key={featuredPost.id}
                    className="featured-post margin-top-medium"
                    post={{
                      url: featuredPost.url,
                      thumb: featuredPost.thumb,
                      title: featuredPost.title,
                      excerpt: featuredPost.excerpt
                    }}
                  />
                </Box>

                <div ref={refs[contentMenu[2].id]}>
                  <TextContent data={contentMenu[2]} />
                </div>

                <SliderPosts
                  title="Más sobre acné"
                  titleType="h2"
                  className="margin-top-big"
                  data={relatedArticles}
                />

                <div ref={refs[contentMenu[3].id]}>
                  <TextContent data={contentMenu[3]} />
                </div>

                <AccordionFaq
                  title="Perguntas frecuentes sobre el acné"
                  icon="heartbeat"
                  className="margin-top-big"
                  content={faqQuestions}
                />

                <div ref={refs[contentMenu[4].id]}>
                  <TextContent data={contentMenu[4]} />
                </div>

                <Box tag="section" className="section-more-articles margin-top-big margin-bottom-medium">
                  <Heading type="h3">
                    <NavLink href="#">
                      Otras enfermedades cutáneas
                      <span>
                        <strong>Ver más</strong>
                        <Icon prefix="fas" name="chevron-right" />
                      </span>
                    </NavLink>
                  </Heading>
                  <Row>
                    {moreArticles.map((item) => (
                      <Col xs="12" sm="6" key={item.id}>
                        <CardContentPost
                          post={{
                            url: item.url,
                            thumb: item.thumb,
                            title: item.title
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Box>

                <BoxBibliography
                  label="Bibliografía"
                  data={[
                    {
                      id: 1,
                      text: 'Muñoz Sandoval, M. y Poletti Vázquez, E. (2008). <em>Acné, dieta y debate: un veredicto pendiente.</em> Medicina Interna de MéxicoVol 24, Nº 5: 346-352. [En línea]. Disponible en:',
                      label: 'https://www.cmim.org/boletin/pdf2008/MedIntContenido05_07.pdf',
                      url: 'https://www.cmim.org/boletin/pdf2008/MedIntContenido05_07.pdf'
                    },
                    {
                      id: 2,
                      text: 'VV.AA. (2011). <em>Influencia de la dieta en el acné: revisión de la liteatura</em>.Rev. Chilena Dermatol. Vol. 27, Nº 1: 82-85. [En línea]. Disponible en:',
                      label: 'https://www.sochiderm.org/web/revista/27_1/9.pdf',
                      url: 'https://www.sochiderm.org/web/revista/27_1/9.pdf'
                    },
                  ]}
                />

              </Col>
              <Col xs="12" md="4">
                <Box className="menu-nav">
                  <Lists>
                    {contentMenu.map((item) => (
                      <li key={item.id}>
                        <Link smooth to={`#${makeSlug(item.title)}`} aria-label={item.label} className={`${item.id === '1' ? 'is-active' : ''}`}>{item.label}</Link>
                      </li>
                    ))}
                  </Lists>
                </Box>

                <Box className="box-publicity align-center margin-top-medium margin-bottom-medium">
                  <span>Publicidad</span>
                  <Image src="https://s0.2mdn.net/simgad/7718551250627356464" alt="Publicidad" />
                </Box>

                <Box className="box-symptoms margin-top-big margin-bottom-big padding-normal">
                  <Heading type="h3" className="titleLine">Explorar Síntomas</Heading>
                  {symptoms.map((item) => (
                    <Button key={item.id} tag="a" rounded textColor="white" color="main">{item.label}</Button>
                  ))}
                </Box>

                <LastArticlesSidebar
                  title="Últimos artículos"
                  titleType="h3"
                  className="margin-top-medium margin-bottom-medium"
                  data={lastArticles}
                />

                <Box className="box-publicity align-center margin-top-medium margin-bottom-medium">
                  <span>Publicidad</span>
                  <Image src="https://s0.2mdn.net/simgad/7718551250627356464" alt="Publicidad" />
                </Box>

              </Col>
            </Row>
          </Container>
        </Box>
      </PageWrapper>
    </>
  );
};

export default BlogDiseases;
