import React, { Component } from 'react';

import {
  Row,
  Col,
  Box,
  Heading,
  Image,
  Card,
  Paragraph,
  Button,
  Icon,
  InputField
} from 'base-ui';

class CourseSidebarCard extends Component {
  constructor() {
    super();
    this.state = {
      shown: true,
    };
  }

  toggle() {
    this.setState({
      shown: !this.state.shown
    });
  }

  render() {
    const hidden = {
      display: this.state.shown ? 'none' : 'block'
    };
    return (
      <Card className="margin-top-medium">
        <Image src={this.props.imgSrc} alt={this.props.title} />
        <Row className="align-center center-xs">
          <Col xs="12" md="9">
            <Heading type="h3" size="medium">{this.props.title}</Heading>
            <Paragraph>{this.props.modules}</Paragraph>
          </Col>
          <Col xs="12">
            <hr />
          </Col>
          <Col xs="6">
            <Box tag="strong">Subtotal (EUR)</Box>
          </Col>
          <Col xs="6" className="end-xs">
            <Box tag="span">{this.props.subtotal}</Box>
          </Col>
          <Col xs="12">
            <hr />
          </Col>
          <Col xs="6">
            <Box tag="strong">Total</Box>
          </Col>
          <Col xs="6" className="end-xs">
            <Box tag="span">{this.props.total}</Box>
          </Col>
          <Col xs="12 margin-top-small">
            <Button onClick={this.toggle.bind(this)} color="white" borderColor="green" textColor="green" full size="normal">
              <Icon prefix="fas" name="ticket-alt" />
              {' '}
              ¿Tienes un cupón?
            </Button>
            <Box style={hidden}>
              <Row>
                <Col xs="8">
                  <InputField
                    placeholder="Código del cupón"
                    full
                  />
                </Col>
                <Col xs="4">
                  <Button color="main" borderColor="main" textColor="white" full size="normal">
                    Aplicar
                  </Button>
                </Col>
              </Row>
            </Box>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default CourseSidebarCard;
