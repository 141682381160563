import React from 'react';

import {
  Box,
  Image,
  Paragraph
} from 'base-ui';
import ReadMore from './ReadMore';

const CourseDesc = () => (
  <Box>
    <Box className="section-title thin-border without-margin-bottom font-size-normal">
      <Box tag="span" className="label line-height-small without-margin text-uppercase titles-color">
        Descripción del curso
      </Box>
    </Box>
    <Box>
      <ReadMore
        maxHeightvalue="180px"
        excerpt={(
          <Box>
            <Paragraph>Dominar la palabra hablada constituye una herramienta imprescindible para el ejercicio de cualquier profesión, y para el desarrollo de todas las relaciones personales y sociales. De continuo surgen oportunidades de hablar en público, en privado, en radio y en televisión. En cualquier situación, el que sabe comunicarse aventaja a los demás.</Paragraph>
            <Image src="./img/categories/desarrollo-de-aptitudes.jpg" />
          </Box>
        )}
        fullText={(
          <Box>
            <Paragraph>Pero con frecuencia se sufre un incómodo miedo escénico, e incontables carencias expositivas. Es hora de acabar con limitaciones tan absurdas mediante un método que conduce al “placer escénico” mediante técnicas sencillas de dominio de la palabra siempre y ante cualquier auditorio.</Paragraph>
          </Box>
        )}
      />
    </Box>
  </Box>
);

export default CourseDesc;
