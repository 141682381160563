import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Hero,
  Box,
  Card,
  Heading,
  Paragraph,
  NavLink
} from 'base-ui';

// Components
import ResultConsult from '../../components/Consultation/ResultConsult';
import ResultProfessional from '../../components/Consultation/ResultProfessional';

// Data
const resultsConsults = [
  {
    id: 1,
    thumb: './img/dummy/service-1.png',
    title: 'Terapia de pareja',
    price: '75€',
    type: 'Consulta',
    stars: 3,
    opinions: '3 valoracion',
  },
  {
    id: 2,
    thumb: './img/dummy/service-2.png',
    title: 'Trastorno del pánico',
    price: '70€',
    type: 'Consulta',
    stars: 5,
    opinions: '8 valoracion'
  },
  {
    id: 3,
    thumb: './img/dummy/service-3.png',
    title: 'Niños y adolescentes felices',
    price: '80€',
    type: 'Consulta',
    stars: 4,
    opinions: '10 valoracion',
  },
  {
    id: 4,
    thumb: './img/dummy/service-1.png',
    title: 'Trastorno del pánico',
    price: '85€',
    type: 'Consulta',
    stars: 3,
    opinions: '2 valoracion',
  },
  {
    id: 5,
    thumb: './img/dummy/service-2.png',
    title: 'Resolver problemas pacificamente',
    price: '65€',
    type: 'Consulta',
    stars: 2,
    opinions: '0 valoracion',
  },
];
const resultsProfessionals = [
  {
    id: 1,
    thumb: './img/dummy/person-1.png',
    professionalArea: 'Psicólogo',
    price: '75€',
    type: 'Consulta',
    professionalName: 'Julio Americo',
  },
  {
    id: 2,
    thumb: './img/dummy/person-2.png',
    professionalArea: 'Psicólogo',
    price: '80€',
    type: 'Consulta',
    professionalName: 'Bella Etels',
  },
  {
    id: 3,
    thumb: './img/dummy/person-3.png',
    professionalArea: 'Psicólogo',
    price: '70€',
    type: 'Consulta',
    professionalName: 'Otto Travis',
  },
  {
    id: 4,
    thumb: './img/dummy/person-4.png',
    professionalArea: 'Psicólogo',
    price: '65€',
    type: 'Consulta',
    professionalName: 'Pablo Americo',
  },
  {
    id: 5,
    thumb: './img/dummy/person-5.png',
    professionalArea: 'Psicólogo',
    price: '75€',
    type: 'Consulta',
    professionalName: 'Julia Americo',
  }
];

const Initial = () => (
  <PageWrapper config={{ title: 'Consultation Initial', bodyClasses: 'consultation home-consultation home' }}>

    <Hero
      className="hero-banner consultation-home"
      BgImage="./img/consultation/services-hero.jpg"
      search={{
        labelButton: 'Búsqueda',
        placeholder: 'Buscar por...'
      }}
    >
      <Container fluid wrap>
        <Row className="middle-xs center-xs start-md">
          <Col xs="12" sm="8" md="6" className="center-xs start-md">
            <Heading type="h1" className="white-color font-x-big normal-font center-xs start-md">
              Atención
              {' '}
              <strong>psicológica y consultas de desarrollo personal</strong>
              {' '}
              donde y cuando lo necesites.
            </Heading>
            <Paragraph className="font-big white-color center-xs start-md">Comienza a sentirte mejor con la ayuda de un profesional especializado</Paragraph>
            <NavLink to="#" className="btn main-bg white-border white-color big">Empezar ya</NavLink>
          </Col>
        </Row>
      </Container>
    </Hero>

    <Box tag="main" id="site-main" className="with-thumbs">

      <Box id="featured-professionals" className="margin-top-x-big padding-top-big">
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Box className="section-title without-border align-center">
                <Box tag="span" className="label titles-color font-big">
                  Profesionales destacados
                </Box>
              </Box>
              <Box className="consultations-row consultations-slider professionals">
                { resultsProfessionals.map((result) => (
                  <ResultProfessional
                    key={result.id}
                    data={{
                      thumb: result.thumb,
                      professionalArea: result.professionalArea,
                      price: result.price,
                      type: result.type,
                      professionalName: result.professionalName,
                    }}
                  />
                ))}
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box id="recent-consultation" className="margin-top-big">
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Box className="section-title without-border align-center">
                <Box tag="span" className="label titles-color font-big">
                  Consultas recientes
                </Box>
              </Box>
              <Box className="consultations-row consultations-slider consultations">
                { resultsConsults.map((result) => (
                  <ResultConsult
                    key={result.id}
                    data={{
                      thumb: result.thumb,
                      title: result.title,
                      price: result.price,
                      type: result.type,
                      stars: result.stars,
                      opinions: result.opinions,
                    }}
                  />
                ))}
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box id="featured-professionals" className="margin-top-big">
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Box className="section-title without-border align-center">
                <Box tag="span" className="label titles-color font-big">
                  Profesionales mejor valorados
                </Box>
              </Box>
              <Box className="consultations-row consultations-slider professionals">
                { resultsProfessionals.map((result) => (
                  <ResultProfessional
                    key={result.id}
                    data={{
                      thumb: result.thumb,
                      professionalArea: result.professionalArea,
                      price: result.price,
                      type: result.type,
                      professionalName: result.professionalName,
                    }}
                  />
                ))}
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box className="call-to-action" style={{ backgroundImage: 'url(./img/consultation/home-cta.png)' }}>
        <Container fluid wrap>
          <Row className="middle-xs center-xs">
            <Col xs="10">
              <Card className="alpha-bg align-center without-shadow">
                <Heading type="h3" className="titles-color font-x-big">Conoce a nuestros profesionales.</Heading>
                <NavLink href="single" className="btn main-bg big main-border white-color">Ver todos</NavLink>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box id="recent-consultation">
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Box className="section-title without-border align-center">
                <Box tag="span" className="label titles-color font-big">
                  Consultas destacadas
                </Box>
              </Box>
              <Box className="consultations-row consultations-slider consultations">
                { resultsConsults.map((result) => (
                  <ResultConsult
                    key={result.id}
                    data={{
                      thumb: result.thumb,
                      title: result.title,
                      price: result.price,
                      type: result.type,
                      stars: result.stars,
                      opinions: result.opinions,
                    }}
                  />
                ))}
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box id="featured-professionals">
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Box className="section-title without-border align-center">
                <Box tag="span" className="label titles-color font-big">
                  Coaching
                </Box>
              </Box>
              <Box className="consultations-row consultations-slider professionals">
                { resultsProfessionals.map((result) => (
                  <ResultProfessional
                    key={result.id}
                    data={{
                      thumb: result.thumb,
                      professionalArea: result.professionalArea,
                      price: result.price,
                      type: result.type,
                      professionalName: result.professionalName,
                    }}
                  />
                ))}
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box id="recent-consultation">
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Box className="section-title without-border align-center">
                <Box tag="span" className="label titles-color font-big">
                  Nuevas consultas
                </Box>
              </Box>
              <Box className="consultations-row consultations-slider consultations">
                { resultsConsults.map((result) => (
                  <ResultConsult
                    key={result.id}
                    data={{
                      thumb: result.thumb,
                      title: result.title,
                      price: result.price,
                      type: result.type,
                      stars: result.stars,
                      opinions: result.opinions,
                    }}
                  />
                ))}
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default Initial;
