import React from 'react';
import {
  Row,
  Col,
	Box,
	Heading,
	Paragraph
} from 'base-ui';

const SubscriptionLandingFAQ = () => {
  return(
    <Box>
      <Row>
				<Col xs="12">
					<Box className="full-width-bg grey-bg">
						<Row className="center-xs">
							<Col xs="12" sm="10" md="6">
								<Paragraph size="big center-xs main-color"><strong>Preguntas frecuentes</strong></Paragraph>
							</Col>
						</Row>
						<Row className="center-xs between-md">
							<Col xs="12" sm="6" md="3">
								<Heading type="h3" className="main-font" size="medium" textColor="main"><strong>¿Qué tengo que hacer para apuntarme al curso?</strong></Heading>
								<Paragraph>Para suscribirte a cualquiera de nuestros cursos, debes registrarte y hacer clic en el botón comprar que aparece en la descripción del curso que quieres realizar.</Paragraph>
							</Col>
							<Col xs="12" sm="6" md="3">
								<Heading type="h3" className="main-font" size="medium" textColor="main"><strong>¿Tengo que registrarme para realizar el curso?</strong></Heading>
								<Paragraph>Sí, es necesario que te registres. Puedes hacerlo con tu cuenta de Facebook o con tu cuenta de correo electrónico.</Paragraph>
							</Col>
							<Col xs="12" sm="6" md="3">
								<Heading type="h3" className="main-font" size="medium" textColor="main"><strong>¿Qué necesito para inscribirme en un curso?</strong></Heading>
								<Paragraph>Solo necesitas una conexión a Internet y un ordenador, tableta o smartphone. ¡Es así de fácil!</Paragraph>
							</Col>
							<Col xs="12" sm="6" md="3">
								<Heading type="h3" className="main-font" size="medium" textColor="main"><strong>¿Puedo acceder a los cursos desde diferentes dispositivos?</strong></Heading>
								<Paragraph>Sí, puedes utilizar tu perfil de La Mente es Maravillosa para acceder a los cursos a través de diferentes dispositivos.</Paragraph>
							</Col>
						</Row>
					</Box>
				</Col>
			</Row>
    </Box>
  )
}

export default SubscriptionLandingFAQ;