import React, { Component } from 'react';
import cs from 'classnames';

// import ReactCSSTransitionGroup from 'react-transition-group';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Modal,
  Image,
  Icon,
  Heading,
  Paragraph,
  Button
} from 'base-ui';

import UnycosLayout from './UnycosLayout';

import './Coupon.css';

class GiftRecovery extends Component {
  state = {
    isModalOpened: true
  }

  openModal = () => {
    this.setState({ isModalOpened: true });

    document.body.style.overflow = 'hidden';
  }

  closeModal = () => {
    this.setState({ isModalOpened: false });
    document.body.style.overflow = 'unset';
  }

  render() {
    const {
      isModalOpened
    } = this.state;

    return (
      <PageWrapper config={{ title: 'Activate Coupon' }}>
        <UnycosLayout className="coupon">
          <Container fluid wrap className="coupon-bar margin-top-medium margin-bottom-big">
            <Row>
              <Col xs="12">
                <Box className={cs('coupon-bar-wrapper white-bg rounded-small')}>
                  <Button onSubmit={this.openModal}>
                    Open Modal
                  </Button>
                </Box>
              </Col>
            </Row>
            <Modal
              isOpen={isModalOpened}
              appElement="#root"
              closeIcon={false}
              width={784}
              height={400}
              animate={{
                directionFrom: 'bottom',
                effectIni: 300,
                effectEnd: 200,
              }}
              className="modal-processing"
            >
              <Row>
                <Col xs={12} md={5} className="align-center">
                  <Icon name="gift" prefix="fas" size="big" color="black" className="pulsate" />
                  <Heading type="h2" size="x-big" className="title normal-font text-uppercase with-letter-spacing">Abriendo Regalo</Heading>
                  <Paragraph>Esto puede tardar algunos segundos, no cierres ni vuelvas a cargar la página.</Paragraph>
                </Col>
                <Col xs={12} md={6} mdOffset={1} className="align-center">
                  <Image src="./img/unycos/opening-gift.svg" alt="Processing Information" />
                </Col>
              </Row>
            </Modal>
          </Container>
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

export default GiftRecovery;
