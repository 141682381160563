/**
 * Create reducers.
 *
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';

// Component reducers
import { reducer as formReducer } from 'redux-form';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    form: formReducer,
    ...injectedReducers
  });
}
