import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Image,
  Paragraph,
  NavLink,
  Icon,
  Button
} from 'base-ui';

const NameReply = (props) => {
  const {
    type, name
  } = props;
  if (type !== 'Team') {
    return (
      <>
        <Box tag="span" className="teacher-name">{name}</Box>
        {' '}
        &middot;
        {' '}
        {type}
      </>
    );
  }
  return name;
};

const UnycosSingleComment = (props) => {
  const {
    from, labels, student, unycos
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [showComment, setShowComment] = useState(false);

  const clickSeeMore = () => {
    setIsOpen(true);
  };

  const toggleShowHideComment = () => {
    setShowComment(!showComment);
  };

  const Comment = (comment, label) => {
    const commentElement = <Paragraph textColor="white">{comment}</Paragraph>;

    if (comment.length > 290) {
      return (
        <>
          <Box className={`text-comment ${isOpen ? '-open' : null}`}>
            {commentElement}
          </Box>
          { !isOpen && (
            <NavLink to="#" onClick={clickSeeMore} className="bt-see-more normal-font main-color font-size-normal">
              <Icon prefix="fas" name="plus" />
              {' '}
              {label}
            </NavLink>
          )}
        </>
      );
    }
    return commentElement;
  };

  return (
    <Box className="student-comment">
      {from !== 'landing-page' && (
        <Box className="avatar avatar__size--big">{student.picture}</Box>
      )}
      <Box className="comment position-relative">
        <Button className={`btn-show-hide-comment ${showComment ? 'active' : null}`} color="transparent" textColor="main" border="main" onClick={toggleShowHideComment}>
          <Icon name="check-circle" prefix="far" />
          {labels.showHide}
        </Button>
        <Box>
          <Box tag="span" className="author letter-spacing__normal normal-font text-uppercase white-color font-size-normal margin-bottom-normal">{student.name}</Box>
          <Box tag="span" className="date letter-spacing__normal normal-font text-color__white--dark text-uppercase font-size-normal margin-bottom-normal">
            <Image src="./img/unycos/icons/icon-thumbs-up.svg" className="icon" />
            {' '}
            {student.date}
          </Box>
        </Box>
        {Comment(student.comment, labels.seeMore)}
        { unycos !== null && (
          <Box className="comment-reply">
            <Paragraph className="text-color__white--dark">{unycos.comment}</Paragraph>
            <Box>
              <Box tag="span" className="normal-font main-color font-size-normal margin-bottom-normal">
                <Icon name="check-circle" prefix="far" />
                {' '}
                <NameReply
                  type={unycos.type}
                  name={unycos.name}
                />
              </Box>
              <Box tag="span" className="normal-font text-color__white--dark font-size-normal margin-bottom-normal"> - Hace 1 mes</Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

UnycosSingleComment.propTypes = {
  from: PropTypes.string,
  labels: PropTypes.object,
  student: PropTypes.object,
  unycos: PropTypes.object
};

export default UnycosSingleComment;
