import React from 'react';
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Icon,
  Button,
  Paragraph
} from 'base-ui';

// Components
import MenuAccount from '../../../components/Consultation/MenuAccount';

const AccountSecurity = () => (
  <PageWrapper config={{ title: 'Consultation Accout Payment Preferences', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section" className="consultation-account">
        <Container container fluid wrap>
          <Row className="center-xs">
            <Col xs="12" sm="4">
              <MenuAccount />
            </Col>
            <Col xs="12" sm="8">
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Preferencias de pago</Box>
                </Box>
                <Box>
                  <Paragraph>Configura la forma en que podremos contactarte el equipo</Paragraph>
                  <Row>
                    <Col xs="12">
                      <table className="full">
                        <tr>
                          <th>Método</th>
                          <th>Detalles</th>
                          <th>Estado</th>
                        </tr>
                        <tr>
                          <th>
                            Wester Union
                            {' '}
                            <small className="badge grey-bg">Predeterminado</small>
                          </th>
                          <th>Stiven Martínez</th>
                          <th><Box tag="span" className="green-color">Activo</Box></th>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <Button to="#" borderColor="main" textColor="main" color="white">
                    <Icon prefix="fas" name="plus" />
                    {' '}
                    Añadir nuevo
                  </Button>
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default AccountSecurity;
