import React from 'react';

import {
  Box,
  Row,
  Col,
  Button,
  Icon
} from 'base-ui';

const BuyBtnMobile = (props) => (
  <Box className={`hidden-lg buy-widget ${props.isFixed ? 'fixed' : 'normal'}`}>
    <Row className="middle-xs">
      <Col xs="4" className="without-padding-right">
        <Box tag="del" className="font-size-small text-color">39.90 €</Box>
        <Box tag="strong" className="font-size-big display-block titles-color">39.90€</Box>
      </Col>
      <Col xs="6" className="without-padding-left without-padding-right">
        <Button className="text-uppercase" color="orange" textColor="white" borderColor="orange" size="big" full>
          Comprar ahora
        </Button>
      </Col>
      <Col xs="2">
        <Button color="white" textColor="secondary" borderColor="secondary" size="big" full className="center-xs">
          <Icon prefix="fas" name="gift" />
        </Button>
      </Col>
    </Row>
  </Box>
);

export default BuyBtnMobile;
