import React, { Component } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Button,
  Icon,
  Modal,
  InputField,
  InputSelect,
  NavLink
} from 'base-ui';

// Components
import MenuAccount from '../../../components/Consultation/MenuAccount';

class AccountSecurity extends Component {
  state = { showModalCard: false };

  openModalCard = () => {
    this.setState({ showModalCard: true });
  }

  closeModalCard = () => {
    this.setState({ showModalCard: false });
  }

  render() {
    const {
      showModalCard
    } = this.state;

    const styleModalCard = {
      overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.80)',
        zIndex: '9999'
      },
      content: {
        position: 'absolute',
        right: '10px',
        left: '10px',
        bottom: '20px',
        margin: 'auto',
        boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
        overflow: 'auto',
        padding: '25px',
        borderRadius: '5px',
        display: 'block'
      },
      close: {
        position: 'absolute',
        right: '15px',
        top: '15px',
        zIndex: 1,
        cursor: 'pointer'
      }
    };
    return (
      <PageWrapper config={{ title: 'Consultation Accout Payment Methods', bodyClasses: 'consultation' }}>

        <Box tag="main" id="site-main">

          <Box tag="section" className="consultation-account">
            <Container container fluid wrap>
              <Row className="center-xs">
                <Col xs="12" sm="4">
                  <MenuAccount />
                </Col>
                <Col xs="12" sm="8">
                  <Card>
                    <Box className="box-title grey-bg">
                      <Box tag="span" className="label main-font">Métodos de pago</Box>
                    </Box>
                    <Row>
                      <Col xs="12" sm="6">
                        <Card>
                          <Icon prefix="fab" name="cc-paypal" className="big regent-grey-color" />
                          <br />
                          <br />
                          <strong>Paypal</strong>
                          <br />
                          Pagos@stivmartinez.com
                          <br />
                          <br />
                          <strong>Predeterminada</strong>
                          <br />
                          <small>No puede eliminar el predeterminado</small>
                        </Card>
                      </Col>
                      <Col xs="12" sm="6">
                        <Card>
                          <Icon prefix="fab" name="cc-mastercard" className="big regent-grey-color" />
                          <br />
                          <br />
                          <strong>XXXXXXXXXXXX4233</strong>
                          <br />
                          06 / 2021
                          <br />
                          <br />
                          <NavLink to="#">Elegir predeterminada</NavLink>
                          <br />
                          <NavLink to="#" className="red-color">Eliminar</NavLink>
                        </Card>
                      </Col>
                    </Row>
                    <Button to="#" borderColor="main" color="white" textColor="main" onClick={this.openModalCard}>
                      <Icon prefix="fas" name="plus" />
                      {' '}
                      Añadir nuevo
                    </Button>

                  </Card>
                </Col>
              </Row>
            </Container>
          </Box>

        </Box>
        {/* end main section */}

        {/* ini #modal-card */}
        <Modal
          isOpen={showModalCard}
          appElement="#root"
          idModal="modal-card"
          className="modal"
          onAfterOpen={this.afterOpenModal}
          closeModal={this.closeModalCard}
          closeIcon
          width="400px"
          height="425px"
          styles={styleModalCard}
        >
          <form>
            <Row>
              <Col xs="12">
                <InputField
                  full
                  id="card_nr"
                  required
                  label="Número de la tarjeta"
                  type="text"
                  className="input"
                  placeholder="XXXX XXXX XXXX XXXX"
                />
              </Col>
              <Col xs="12" sm="6">
                <InputField
                  full
                  id="card_good_tru"
                  required
                  label="Caducidad"
                  type="text"
                  className="input"
                  placeholder="MM / AA"
                />
              </Col>
              <Col xs="12" sm="6">
                <InputField
                  full
                  id="card_cvv"
                  required
                  label="CVV"
                  type="text"
                  className="input"
                  placeholder="XXX"
                />
              </Col>
              <Col xs="12">
                <InputField
                  full
                  id="card_name"
                  required
                  label="Nombre Completo"
                  type="text"
                  className="input"
                  placeholder="Tu nombre"
                />
                <br />
              </Col>
              <Col xs="12" sm="6">
                <InputField
                  full
                  id="card_zip_code"
                  required
                  label="Código postal"
                  type="text"
                  className="input"
                  placeholder="00000000"
                />
              </Col>
              <Col xs="12" sm="6">
                <InputSelect
                  name="genre"
                  id="card_country"
                  label="País"
                  full
                  required
                  options={[
                    { value: 'es', label: 'España' },
                    { value: 'br', label: 'Brasil' },
                  ]}
                />
              </Col>
              <Col xs="12">
                <hr />
                <Button to="#" full color="main" borderColor="main" textColor="white">
                  Añadir nuevo
                </Button>
              </Col>
            </Row>
          </form>
        </Modal>
        {/* end #modal-card */}

      </PageWrapper>
    );
  }
}

export default AccountSecurity;
