import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import {
  Container,
  Box,
  Row,
  Col,
  Image,
  Lists,
  NavLink,
  Button,
  Modal,
  Heading,
  Label,
  InputField,
  Icon
} from 'base-ui';

// Components
import UnycosWhatsapp from './UnycosWhatsapp';

// style
const stylesModals = {
  overlay: {
    position: 'fixed',
    height: '100%',
    overflow: 'auto'
  },
  content: {
    position: 'relative',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    padding: '0.5rem',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
  }
};

class UnycosHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showmenu: false,
      hasMsg: true,
      logged: props.statuslogged,
      loggedArea: props.isLoggedArea,
      // eslint-disable-next-line react/no-unused-state
      sticky: props.sticky,
      showModalIniSession: false,
      showModalRegister: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.openModalIniSession = this.openModalIniSession.bind(this);
    this.closeModalIniSession = this.closeModalIniSession.bind(this);
    this.openModalRegister = this.openModalRegister.bind(this);
    this.closeModalRegister = this.closeModalRegister.bind(this);
    this.iniSession = this.iniSession.bind(this);
    this.endSession = this.endSession.bind(this);
  }

  componentDidMount() {
    const { showMenu } = this.props;

    if (!showMenu) {
      window.addEventListener('resize', () => {
        document.getElementById('bg-nav').style.display = 'none';
        document.getElementById('submenu-user').classList.add('inactive-submenu');
        this.setState({ showmenu: false });
      });
    }
  }

  toggleMenu = (e) => {
    e.preventDefault();
    const { showmenu } = this.state;
    if (!showmenu) { document.getElementById('submenu-user').classList.remove('inactive-submenu'); }
    this.setState({ showmenu: !showmenu });
    const bgNav = document.getElementById('bg-nav');
    let display;
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 1024) {
        if (!showmenu) {
          display = 'block';
        } else {
          display = 'none';
        }
        bgNav.style.display = display;
      }
    }
  }

  toggleSidebarHandler = () => {
    const { toggleSidebar } = this.props;

    toggleSidebar();
  }

  backHistory = () => {
    const { history } = this.props;

    history.push('/unycos/my-account');
    // history.go(-1); // back history navigation
  }

  iniSession(e) {
    e.preventDefault();
    this.setState({ logged: true });
    this.closeModalIniSession();
  }

  endSession(e) {
    this.toggleMenu(e);
    this.setState({ logged: false });
  }

  openModalIniSession(e) {
    e.preventDefault();
    const { logged } = this.state;

    if (logged) {
      this.toggleMenu(e);
    } else {
      this.setState({ showModalIniSession: true });
      this.closeModalRegister();
    }
  }

  closeModalIniSession() {
    this.setState({ showModalIniSession: false });
  }

  openModalRegister(e) {
    e.preventDefault();
    this.setState({ showModalRegister: true });
    this.closeModalIniSession();
  }

  closeModalRegister() {
    this.setState({ showModalRegister: false });
  }

  render() {
    const {
      hasMsg, logged, loggedArea, showmenu, showModalRegister, showModalIniSession
    } = this.state;

    const {
      modalTitle, showModal, showMenu, sticky, isLoggedArea, onlyBackBtn
    } = this.props;

    return (
      <Box id="unycos-header" className={sticky ? 'sticky-header' : ''}>
        <Container fluid className="header-container">
          <Row>
            <Col xs="12">
              <div id="unycos-menu-left">
                {onlyBackBtn && (
                  <Button id="unycos-header-btn-menu" className="back-btn" onClick={() => { this.backHistory(); }}>
                    <Icon prefix="fas" name="chevron-left" color="white" size="medium"></Icon>
                  </Button>
                )}
                {logged && loggedArea && !onlyBackBtn && (
                  <Button id="unycos-header-btn-menu" onClick={() => { this.toggleSidebarHandler(); }}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                  </Button>
                )}
              </div>

              <Box className="logo">
                <NavLink href="./unycos/">
                  <Image src="./img/unycos/logo.svg" alt="Unycos" />
                </NavLink>
              </Box>

              <Box id="unycos-menu" tag="nav">
                {logged
                  && (
                    <React.Fragment>
                      <NavLink href="unycos/courses/" className="bt-menu-desktop">Cursos</NavLink>
                      <NavLink
                        href="."
                        id="bt-user-menu-desktop"
                        onClick={this.toggleMenu}
                        className={`
                          bt-menu-desktop 
                          ${showmenu && 'active-submenu'} 
                          ${hasMsg ? 'has-notification' : ''}
                        `}
                      >
                        Hola,
                        <strong> Daniela</strong>
                        <Icon prefix="fas" name="chevron-up" />
                      </NavLink>
                    </React.Fragment>
                  )
                }
                <Box id="submenu-user" className={`rounded-small ${showmenu && 'active-submenu'}`} tag="nav">
                  {logged
                  && (
                    <React.Fragment>
                      <Image src="./img/unycos/logo.svg" alt="Unycos" className="logo-unycos hidden-lg" />
                      <Box tag="span" className="text-uppercase atual-user hidden-lg">
                        Hola,
                        <strong> Daniela</strong>
                      </Box>
                      <Box className="submenu-user-content">
                        <Lists className="menu-connected">
                          {!isLoggedArea && <li className="text-uppercase with-letter-spacing"><NavLink href="/unycos/my-account">Panel de Control</NavLink></li>}
                          {!isLoggedArea && <li className="text-uppercase with-letter-spacing"><NavLink href="/unycos/my-account#courses-processing">Mis cursos</NavLink></li>}
                          <li className="text-uppercase with-letter-spacing"><NavLink href="/unycos/my-account#profile-edit">Perfil</NavLink></li>
                          <li className={`text-uppercase with-letter-spacing p--relative notification-item ${hasMsg ? 'has-notification' : ''}`}>
                            <NavLink href="/unycos/my-account#account-notification">
                              Notificaciones
                            </NavLink>
                          </li>
                          {!isLoggedArea && <li className="text-uppercase with-letter-spacing"><NavLink href="/unycos/my-account#account-config-notification">Ajustes</NavLink></li>}
                          <li className="text-uppercase with-letter-spacing"><NavLink href="/unycos/courses">Todos los cursos</NavLink></li>
                        </Lists>
                        <UnycosWhatsapp className="hidden-lg" />
                        <NavLink to="/unycos" id="bt-logout" className="text-uppercase with-letter-spacing">Cerrar sesión</NavLink>
                      </Box>
                    </React.Fragment>
                  )
                  }
                </Box>
                <Box id="bg-nav" onClick={this.toggleMenu}>&nbsp;</Box>
                <Button id="bt-user-menu-mobile" className={`${logged && hasMsg ? 'has-notification' : ''}`} onClick={(e) => this.openModalIniSession(e)}>
                  {logged && <Image src="./img/unycos/icons/icon_person-outline.svg" alt="Icon Person" />}
                  {!logged && <Image src="./img/unycos/icons/icon_login.svg" alt="Icon Person" />}
                </Button>

                {(!logged && showMenu)
                  && (
                    <React.Fragment>
                      <Lists className="menu-login">
                        <li className="text-uppercase with-letter-spacing"><NavLink href="unycos/courses/">Cursos</NavLink></li>
                        <li className="text-uppercase with-letter-spacing"><NavLink to="." onClick={this.openModalRegister}>Register</NavLink></li>
                        <li className="text-uppercase with-letter-spacing"><NavLink to="." onClick={(e) => this.openModalIniSession(e)}>Login</NavLink></li>
                      </Lists>
                    </React.Fragment>
                  )
                }
              </Box>
              <Modal
                isOpen={showModalIniSession || showModal}
                appElement="#root"
                idModal="modal-register"
                className="modal-unycos"
                onAfterOpen={this.afterOpenModal}
                closeModal={this.closeModalIniSession}
                closeIcon={false}
                styles={stylesModals}
              >
                <Box className="modal-header">
                  <NavLink to="#" onClick={this.closeModalIniSession} id="bt-close-modal"><Image src="./img/unycos/icons/icon_times.svg" alt="Icon Times" /></NavLink>
                  {!modalTitle ? (
                    <Heading type="h2">Iniciar Sesión</Heading>
                  ) : (
                    <Heading type="h2">{modalTitle}</Heading>
                  )}
                </Box>
                <Box className="modal-content">
                  <Button className="bt-cta bt-login bt-facebook">
                    <Icon name="facebook-f" prefix="fab" />
                    {' '}
                    Conéctate con Facebook
                    {' '}
                  </Button>
                  <Button className="bt-cta bt-login bt-google">
                    <Icon name="google" prefix="fab" />
                    {' '}
                    Conéctate con Google
                  </Button>
                  <Box className="box-division"><span>o</span></Box>
                  <InputField
                    name="input_email"
                    required
                    type="text"
                    placeholder="CORREO ELECTRÓNICO"
                  />
                  <InputField
                    name="input_password"
                    required
                    type="text"
                    placeholder="CONTRASEÑA"
                  />
                  <NavLink to="#" className="text-contrasena">¿Olvidaste tu contraseña?</NavLink>
                  <Button className="bt-cta" onClick={this.iniSession}>
                    <span>Iniciar Sesión</span>
                  </Button>
                  <NavLink to="#" className="text-register" onClick={this.openModalRegister}>
                    ¿No tienes una cuenta?
                    &nbsp;
                    <strong>¡Regístrate!</strong>
                  </NavLink>
                </Box>
              </Modal>
              <Modal
                isOpen={showModalRegister}
                appElement="#root"
                idModal="modal-register"
                className="modal-unycos"
                onAfterOpen={this.afterOpenModal}
                closeModal={this.closeModalRegister}
                closeIcon={false}
                styles={stylesModals}
              >
                <Box className="modal-header">
                  <NavLink to="#" onClick={this.closeModalRegister} id="bt-close-modal"><Image src="./img/unycos/icons/icon_times.svg" alt="Icon Times" /></NavLink>
                  {!modalTitle ? (
                    <Heading type="h2">Regístrate</Heading>
                  ) : (
                    <Heading type="h2">{modalTitle}</Heading>
                  )}
                </Box>
                <Box className="modal-content">
                  <Button className="bt-cta bt-login bt-facebook">
                    <Icon name="facebook-f" prefix="fab" />
                    {' '}
                    Seguir con Facebook
                    {' '}
                  </Button>
                  <Button className="bt-cta bt-login bt-google">
                    <Icon name="google" prefix="fab" />
                    {' '}
                    Seguir con Google
                  </Button>
                  <Box className="box-division"><span>o</span></Box>
                  <InputField
                    name="input_name"
                    required
                    type="text"
                    placeholder="NOMBRE"
                  />
                  <InputField
                    name="input_email"
                    required
                    type="text"
                    placeholder="CORREO ELECTRÓNICO"
                  />
                  <InputField
                    name="input_password"
                    required
                    type="text"
                    placeholder="CONTRASEÑA"
                  />
                  <InputField
                    id="input_conditions"
                    name="input_conditions"
                    type="checkbox"
                    className="inverse"
                  />
                  <Label inputId="input_conditions" className="input_conditions">
                    <span>
                      Acepto las&nbsp;
                      <NavLink to="#" className="text-register">condiciones de uso</NavLink>
                      &nbsp;y&nbsp;
                      <NavLink to="#" className="text-register">protección de datos</NavLink>
                    </span>
                  </Label>
                  <Button className="bt-cta">
                    <span>Regístrate</span>
                  </Button>
                  <NavLink to="#" className="text-register" onClick={this.openModalIniSession}>
                    ¿Ya tienes una cuenta?
                    &nbsp;
                    <strong>¡Inicia sesión!</strong>
                  </NavLink>
                </Box>
              </Modal>
            </Col>
          </Row>
        </Container>
      </Box>
    );
  }
}

UnycosHeader.defaultProps = {
  statuslogged: false,
  showModal: false,
  showMenu: true
};

UnycosHeader.propTypes = {
  statuslogged: PropTypes.bool,
  isLoggedArea: PropTypes.bool,
  sticky: PropTypes.bool,
  showModal: PropTypes.bool,
  modalTitle: PropTypes.string,
  showMenu: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  onlyBackBtn: PropTypes.bool,
  history: PropTypes.object
};

export default withRouter(UnycosHeader);
