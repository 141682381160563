import React, { Component } from 'react';

class BlogDiseaseList extends Component {
	
	render() {
		return(
			<section id="diseases-list-widget" className="widget">
        <div className="section-title main-border">
          <span className="label text-uppercase titles-color">Enfermedades A - Z</span>
          <a href="." className="btn small grey-bg transparent-border">Ver todas <i className="fas fa-angle-right"></i></a>
        </div>
        <div className="row">
          <div className="list-col col-xs-12 col-sm-6 col-md-3">
            <a href="." className="display-block">Antivirales</a>
            <a href="." className="display-block">Fiebre</a>
            <a href="." className="display-block">Gripa</a>
            <a href="." className="display-block">Bacterias</a>
            <a href="." className="display-block">Bicarbonato de Sodio</a>
            <a href="." className="display-block">Humedad</a>
            <a href="." className="display-block">Antigripales</a>
          </div>
          <div className="list-col col-xs-12 col-sm-6 col-md-3">
            <a href="." className="display-block">Antivirales</a>
            <a href="." className="display-block">Fiebre</a>
            <a href="." className="display-block">Gripa</a>
            <a href="." className="display-block">Bacterias</a>
            <a href="." className="display-block">Bicarbonato de Sodio</a>
            <a href="." className="display-block">Humedad</a>
            <a href="." className="display-block">Antigripales</a>
          </div>
          <div className="list-col col-xs-12 col-sm-6 col-md-3">
            <a href="." className="display-block">Antivirales</a>
            <a href="." className="display-block">Fiebre</a>
            <a href="." className="display-block">Gripa</a>
            <a href="." className="display-block">Bacterias</a>
            <a href="." className="display-block">Bicarbonato de Sodio</a>
            <a href="." className="display-block">Humedad</a>
            <a href="." className="display-block">Antigripales</a>
          </div>
          <div className="list-col col-xs-12 col-sm-6 col-md-3">
            <a href="." className="display-block">Antivirales</a>
            <a href="." className="display-block">Fiebre</a>
            <a href="." className="display-block">Gripa</a>
            <a href="." className="display-block">Bacterias</a>
            <a href="." className="display-block">Bicarbonato de Sodio</a>
            <a href="." className="display-block">Humedad</a>
            <a href="." className="display-block">Antigripales</a>
          </div>
        </div>

      </section>
		)
	}
}

export default BlogDiseaseList;