import React from 'react';
import PropTypes from 'prop-types';

// Base-UI Components
import {
  Hero,
  Container,
  Row,
  Col,
  Heading
} from 'base-ui';


const BlogHero = ({
  title, subtitle, backgroundImage, className
}) => (
  <Hero BgImage={backgroundImage} className={className}>
    <Container fluid wrap>
      <Row className="middle-xs">
        <Col xs="12" md="7">
          <Heading type="h1" className="title">{title}</Heading>
          <Heading type="h2" className="subline">{subtitle}</Heading>
        </Col>
      </Row>
    </Container>
  </Hero>
);

BlogHero.defaultProps = {
  subtitle: '',
  backgroundImage: ''
};

BlogHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  backgroundImage: PropTypes.string,
  className: PropTypes.string
};

export default BlogHero;
