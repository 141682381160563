import React from 'react';

// Base-UI Components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Image,
  Paragraph,
  Heading
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogHero from '../../components/Blog/BlogHero';
import BlogFooterOffices from '../../components/Blog/BlogFooterOffices';

const pageConfig = {
  product: 'Mejor con Salud',
  title: 'Danos tu feedback',
  meta: [
    { name: 'description', content: 'Si has encontrado algún error de contenido o has experimentado alguna incidencia mientras navegabas en nuestra página, por favor, notifícanoslo a través del formulario que encontrarás abajo para que podamos seguir mejorando.' }
  ]
};

const BlogFeedbackWithoutForm = () => {
  return (
    <React.Fragment>
      <BlogMenu />
      <PageWrapper config={pageConfig}>
        <Box className="page-template-feedback">
          <Box id="feedback-hero">
            <BlogHero
              title={pageConfig.title}
              subtitle={pageConfig.meta[0].content}
              backgroundImage="./img/feedback-hero.jpg"
              className="hero"
            />
          </Box>
          <Box className="special-form">
            <Container fluid wrap>
              <Row className="center-xs">
                <Col xs="12" md="12">
                  <Card color="yellow" size="medium" textColor="black" borderColor="yellow" className="covid-alert">
                    <Row className="center-xs">
                      <Col xs="12" sm="2" className="image-content">
                        <Image src="./img/blog/work-from-home.png" />
                      </Col>
                      <Col xs="12" sm="10" className="alert-content">
                        <Heading type="h2" size="x-medium" textColor="black">Nos quedamos en casa</Heading>
                        <Paragraph>
                          Apoyamos la cuarentena, pero seguimos disponibles. Por ello, si deseas contactar con nosotros, llámanos a
                          <strong> (+34) 687 586 929</strong>
                        </Paragraph>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Box>
          <BlogFooterOffices />
        </Box>
      </PageWrapper>
    </React.Fragment>
  );
};

export default BlogFeedbackWithoutForm;
