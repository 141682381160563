import React, { Component } from 'react';

import {
  Container,
  Image,
  Row,
  Col,
  Card,
  Heading,
  Paragraph,
  Button,
  Icon,
  PageWrapper,
  Box
} from 'base-ui';

class UserCertificate2020 extends Component {
  componentDidMount() {
    document.body.classList.toggle('certificate');
  }

  printFunction = () => {
    window.print();
  }

  render() {
    const teachers = [
      {
        id: '1',
        name: 'Adolfo Pérez Agustí',
        genre: 'm',
        desc: 'Entrenador de desarrollo personal',
        signature: './img/teachers/adolfo_signature.png'
      },
      {
        id: '2',
        name: 'Cristina Soria',
        genre: 'f',
        desc: 'Coach, formadora en inteligencia emocional y escritora',
        signature: './img/teachers/crisina_signature.png'
      }
    ];

    const genre = (tip) => {
      if (tip === 'm') {
        return 'el professor';
      }
      return 'la prefessora';
    };

    const student = 'Kenia Montes Muñoz';
    const course = 'Descubre tu personalidad con el eneagrama';
    const courseDescription = 'Este corso explica el eneagrama con una técnica de coaching que permite profundizar en el conocimiento de la propia personalidad. Plantea el autoconocimiento como la base para tomar mejores decisiones, potenciar los talentos, y la confianza en uno mismo.';
    const date = '10 de Enero 2020';

    return (
      <PageWrapper config={{ title: 'User certificate' }}>
        <Container fluid wrap>
          <Card size="big" className="position-relative">
            <Row className="middle-xs">
              <Col xs="12">
                <Box className="box-header position-absolute">
                  <Image src="./img/lmem/img_div.png" alt="division" />
                </Box>
                <Box className="box-footer position-absolute">
                  <Image src="./img/lmem/img_div.png" alt="division" />
                </Box>
                <Box className="box-image position-absolute">
                  <Image src="./img/lmem/img_certificate.png" alt="background" />
                </Box>
                <Box className="box-content position-relative">
                  <Image src="./img/lmem-logo.svg" alt="La mente es maravillosa logo" className="lmem-logo margin-bottom-normal" />
                  <Paragraph className="font-size-normal margin-top-normal margin-bottom-small line-height-big">Se otorga el presente certificado a</Paragraph>
                  <Heading type="h2" className="font-size-x-medium margin-bottom-big">{student}</Heading>
                  <Paragraph className="font-size-normal margin-top-normal margin-bottom-normal line-height-big">Por haber realizado satisfatoriamente el curso online</Paragraph>
                  <Heading type="h2" className="font-size-x-medium margin-top-small margin-bottom-small">{course}</Heading>
                  <Paragraph className="font-size-normal margin-top-normal margin-bottom-normal">
                    Dirigido por
                    {' '}
                    {genre(teachers[1].genre)}
                    {' '}
                    <strong>{teachers[1].name}</strong>
                  </Paragraph>
                  <Paragraph className="course-content font-size-small margin-top-small margin-bottom-big line-height-big">{courseDescription}</Paragraph>
                  <Paragraph className="date font-size-small margin-top-small margin-bottom-normal line-height-big">
                    Salamanca,
                    {' '}
                    {date}
                  </Paragraph>
                  <Image src={teachers[1].signature} alt={`Signature ${teachers[1].name}`} className="teacher-sign position-relative" />
                  <Paragraph className="teacher-name line-height-normal bold-font font-size-normal">
                    {teachers[1].name}
                    <br />
                    <span className="normal-font font-size-small">{teachers[1].desc}</span>
                  </Paragraph>
                </Box>
              </Col>
            </Row>
          </Card>
          <Card size="big" className="print-msg">
            <Row className="center-xs align-center">
              <Col xs="12" md="8" className="center-xs">
                <Paragraph>
                  IMPORTANTE: Para una mejor visualización de tu certificado es necesario que selecciones la
                  opción paisaje (horizontal) al momento de imprimir.
                </Paragraph>
                <Button onClick={() => this.printFunction()} textColor="white" color="main" borderColor="main" size="big">
                  <Icon prefix="fas" name="print" />
                  &nbsp; Imprimir
                </Button>
              </Col>
            </Row>
          </Card>
        </Container>
      </PageWrapper>
    );
  }
}

export default UserCertificate2020;
