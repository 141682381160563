import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Box,
  Row,
  Col,
  Image,
  Paragraph,
  Icon
} from 'base-ui';

const UnycosFeatures = ({ className, style }) => (
  <Box className={`unycos-features ${className}`} style={style}>
    <Container fluid wrap>
      <Row className="around-xs">
        <Col xs="4" md="3">
          <Row className="center-xs middle-xs">
            <Col xs="12" sm="5" className="center-xs">
              <Icon className="with-outline white-border">
                <Image className="icon" src="./img/unycos/icons/icon-smartphone.svg" alt="Smartphone Icon" />
              </Icon>
            </Col>
            <Col xs="12" sm="7" className="without-padding-left without-padding-right">
              <Paragraph className="label line-height-medium letter-spacing__normal center-xs start-md text-uppercase white-color font-size-medium">Cuando y donde quieras</Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs="4" md="3">
          <Row className="center-xs middle-xs">
            <Col xs="12" sm="5" className="center-xs">
              <Icon className="with-outline white-border">
                <Image className="icon" src="./img/unycos/icons/icon-award.svg" alt="Award Icon" />
              </Icon>
            </Col>
            <Col xs="12" sm="7" className="without-padding-left without-padding-right">
              <Paragraph className="label line-height-medium letter-spacing__normal center-xs start-md text-uppercase white-color font-size-medium">Contenido exclusivo</Paragraph>
            </Col>
          </Row>
        </Col>
        <Col xs="4" md="3">
          <Row className="center-xs middle-xs">
            <Col xs="12" sm="5" className="center-xs">
              <Icon className="with-outline white-border">
                <Image className="icon" src="./img/unycos/icons/icon-checkmark-circle.svg" alt="Checkmark Icon" />
              </Icon>
            </Col>
            <Col xs="12" sm="7" className="without-padding-left without-padding-right">
              <Paragraph className="label line-height-medium letter-spacing__normal center-xs start-md text-uppercase white-color font-size-medium">Garantía de satisfacción</Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </Box>
);

UnycosFeatures.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default UnycosFeatures;
