import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Icon,
  Box
} from 'base-ui';

class AccordionItem extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    onClick: PropTypes.func.isRequired,
  };

  onClick = () => {
    const { onClick, label } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label, title },
    } = this;
    const { children } = this.props;
    return (
      <Box className={isOpen ? 'accordion-cat-item accordion-open' : 'accordion-cat-item accordion-closed'}>
        <Box className="accordion-label" onClick={onClick}>
          <Box tag="span" className="label">{title || label}</Box>
          <Box className="accordion-icon">
            {!isOpen && <Icon prefix="fas" name="plus" />}
            {isOpen && <Icon prefix="fas" name="minus" />}
          </Box>
        </Box>
        {isOpen && (
          <Box className="accordion-content">
            {children}
          </Box>
        )}
      </Box>
    );
  }
}

export default AccordionItem;
