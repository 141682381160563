import React, { Component } from 'react'

import { Box, Card, Col, Heading, Icon, Lists, Row } from 'base-ui';

class PricingTable extends Component {
	state = {
    active: "about"
  };

  setActive = value => {
    if (this.state.active !== value) {
      this.setState({ active: value });
    }
  };
	render() {
		const { active } = this.state;
		return (
			<Box className="pricing-plans position-relative margin-top-big margin-bottom-medium">
				<Row className="center-xs">
					<Col xs="5" className="align-center center-xs margin-bottom-small">
						<Heading type="h2" size="x-medium">Selecciona el mejor plan para ti</Heading>
					</Col>
				</Row>
				<Card className="pricing-plans-features">
					<Lists className="pricing-plans-list">
						<li>Acceso a más de 30 cursos</li>
						<li>1 curso nuevo por mes</li>
						<li>Disponible en todos los dispositivos</li>
						<li>Cancela en cualquier momento</li>
						<li>Seguimiento del avance del curso</li>
						<li>Contenido offline en las apps</li>
						<li>Revisión de test y trabajos</li>
						<li>30% descuento en nuestra tienda</li>
					</Lists>
				</Card>
				<Card
					className={active === "pricing-plans-plan left" ? "pricing-plans-plan left active" : "pricing-plans-plan left"}
					onClick={() => this.setActive("pricing-plans-plan left")}
				>
					<Box className="center-xs align-center">
						<Icon prefix="fas" name="user" color="main" className="plan-icon"/>
						<Heading size="medium">Básico</Heading>
						<Box tag="span" className="font-size-small main-color">9.90€ / Mes</Box>
					</Box>
					<Lists className="pricing-plans-list">
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="times" color="red"/></li>
						<li><Icon prefix="fas" name="times" color="red"/></li>
						<li><Icon prefix="fas" name="times" color="red"/></li>
					</Lists>
				</Card>
				<Card
					className={active === "pricing-plans-plan right" ? "pricing-plans-plan right active" : "pricing-plans-plan right"}
					onClick={() => this.setActive("pricing-plans-plan right")}
				>
					<Box className="center-xs align-center">
						<Icon prefix="fas" name="ticket-alt" color="main" className="plan-icon"/>
						<Heading size="medium">Premium</Heading>
						<Box tag="span" className="font-size-small main-color">19.90€ / Mes</Box>
					</Box>
					<Lists className="pricing-plans-list">
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
						<li><Icon prefix="fas" name="check" color="main"/></li>
					</Lists>
				</Card>
			</Box>
		)
	}
};

export default PricingTable;