import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Box, Button, Heading, Icon
} from 'base-ui';

import './styles.css';

const ScheduleTime = ({
  isActive, title, estimedTime, hours, setHour, activeHour, formattedScheduleDate
}) => {
  const [IsSelected, setIsSelected] = useState('');

  return (
    <Box
      className={cs('timer grey-bg text-color', {
        'is-active': isActive,
      })}
    >
      <Box className="timer-header">
        <p className="date">
          <Icon
            name="calendar"
            prefix="far"
            className="icon text-color"
          />
          {' '}
          {formattedScheduleDate}
        </p>
        <Heading type="h2" size="big">
          {title}
        </Heading>
        <Heading type="h4" size="normal" textColor="regent-grey">
          Duración:
          {' '}
          {estimedTime}
        </Heading>
      </Box>
      <Box className="timer-hours">
        <ul>
          {hours.map((hour) => (
            <li
              key={hour}
              className={cs({
                'is-selected': hour === IsSelected || hour === activeHour,
              })}
            >
              <Button
                tag="button"
                color="white"
                textColor="text"
                size="big"
                borderColor="transparent"
                onClick={() => setIsSelected(hour)}
              >
                {hour}
              </Button>
              <Button
                tag="button"
                color="main"
                textColor="white"
                size="big"
                borderColor="main"
                onClick={() => setHour(hour)}
              >
                Confirmar
              </Button>
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};

ScheduleTime.defaultProps = {
  isActive: true,
};

ScheduleTime.propTypes = {
  /**
   * For play entrance animation
   */
  isActive: PropTypes.bool,

  /**
   * Title for component
   */
  title: PropTypes.string,

  /**
   * Session estimed time ex.: '35min'
   */
  estimedTime: PropTypes.string,

  /**
   * The availabe hours ex.: ['04:00', '05:00', '06:00']
   */
  hours: PropTypes.arrayOf(PropTypes.string).isRequired,

  /**
   * The callback function to set hour
   */
  setHour: PropTypes.func.isRequired,

  /**
   * The active hour to set class
   */
  activeHour: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  /**
   * The selected date
   */
  formattedScheduleDate: PropTypes.string
};

export default ScheduleTime;
