import React, { Component } from 'react';

import {
  Box,
  Card,
  Icon,
  Heading,
  Button,
  InputField,
  Row,
  Col
} from 'base-ui';

class SidebarWidgetInfoB extends Component {
  constructor() {
    super();
    this.state = {
      shown: true,
    };
  }

  toggle() {
    this.setState({
      shown: !this.state.shown
    });
  }

  render() {
    const hidden = {
      display: this.state.shown ? 'none' : 'block'
    };
    return (
      <Box className="widget sticky">
        <Card className="white-bg white-border small">
          <Box className="course-preview">
            <iframe title="iframe" width="560" height="315" src="https://www.youtube.com/embed/KmLVXffHLd4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Box>
          <Row className="middle-xs">
            <Col xs="6">
              <Box tag="del" className="font-size-medium text-color">39.90 €</Box>
            </Col>
            <Col xs="6" className="end-xs">
              <Box tag="strong" className="font-size-big titles-color">39.90 €</Box>
            </Col>
          </Row>
          <Row>
            <Col xs="9">
              <Button color="orange" textColor="white" borderColor="orange" size="big" full>
                Comprar ahora
              </Button>
            </Col>
            <Col xs="3">
              <Button color="white" textColor="secondary" borderColor="secondary" size="big" full className="center-xs">
                <Icon prefix="fas" name="gift" />
              </Button>
            </Col>
          </Row>
          <Button color="white" textColor="titles" borderColor="titles" className="border-strong" full>
            <Icon prefix="fas" name="heart" />
            Guardar en la lista de deseos
          </Button>
          <hr className="spacer" />
          <Box className="more-info-cta">
            <Box>
              <Heading type="h3" size="normal" className="text-uppercase main-font">¿Que incluye?</Heading>
            </Box>
            <Box>
              <Box className="meta font-size-small">
                <strong>Duración:</strong>
                16 módulos/7 horas
              </Box>
              <Box className="meta font-size-small">
                <strong>Valoraciones:</strong>
                100.0% positivas
              </Box>
              <Box className="meta font-size-small">
                <strong>Modalidad:</strong>
                Online y a tu ritmo
              </Box>
            </Box>
            <Box>
              <Button onClick={this.toggle.bind(this)} color="white" borderColor="white" textColor="titles" full size="normal" className="text-uppercase">
                <Icon prefix="fas" name="ticket-alt" />
                ¿Tienes un cupón?
              </Button>
              <Box style={hidden}>
                <Row>
                  <Col xs="8">
                    <InputField
                      placeholder="Código del cupón"
                      full
                    />
                  </Col>
                  <Col xs="4">
                    <Button color="main" borderColor="main" textColor="white" full size="normal">
                      Aplicar
                    </Button>
                  </Col>
                </Row>
              </Box>
            </Box>
            <hr className="spacer without-margin grey-bg" />
            <Box>
              <Button onClick={this.toggle.bind(this)} color="white" borderColor="white" textColor="titles" full size="normal" className="text-uppercase">
                <Icon prefix="fas" name="share" />
                Compartir
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  }
}

export default SidebarWidgetInfoB;
