import React from 'react';

import {
  Breadcrumbs,
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  NavLink,
  Lists,
  Image,
  Icon,
  Message,
  Heading,
  Paragraph
} from 'base-ui';

// Components
import BlogMenu from '../../../components/Blog/BlogMenu';
import SliderPosts from '../../../components/Blog/SliderPosts';
import TextContent from '../../../components/Blog/TextContent';
import CardContentPost from '../../../components/Blog/CardPost';
import AccordionFaq from '../../../components/Blog/AccordionFaq';
import SliderFeaturedPosts from '../../../components/Blog/SliderFeaturedPosts';
import LastArticlesSidebar from '../../../components/Blog/LastArticlesSidebar';
import BoxBibliography from '../../../components/Blog/BoxBibliography';

// Data
import {
  wrapperConfig, contentMenu, lastArticles, featuredPost, faqQuestions, relatedRecipes, relatedFoods
} from './ContentData';

const BlogFoods = () => (
  <>
    <BlogMenu />
    <Container fluid wrap>
      <Row>
        <Col xs="12">
          <Breadcrumbs className="margin-top-medium">
            <NavLink href="#" aria-label="Inicio">Home</NavLink>
            <NavLink href="#" aria-label="Medicamentos">Medicamentos</NavLink>
            <NavLink href="#" aria-label="Cutáneas">Analgésicos</NavLink>
            <Box tag="span">Acetaminofén</Box>
          </Breadcrumbs>
        </Col>
      </Row>
    </Container>
    <PageWrapper config={wrapperConfig}>
      <Box tag="main" id="site-main">
        <Container fluid wrap>
          <Row>
            <Col xs="12" md="8">

              <TextContent data={contentMenu[0]} />

              <Message borderColor="yellow" textColor="title" className="alert section-about-food">
                <Row>
                  <Col sm="12">
                    <Heading type="h2">
                      <Icon prefix="fas" name="exclamation-circle" />
                      Información nutricional
                    </Heading>
                    <Paragraph>La utilización Eridosis para el acné, si bien no es nueva, ha encontrado una cierta fama en base a las</Paragraph>
                    <Heading type="h3">En una porción de 100g hay:</Heading>
                    <Box className="btn white-bg rounded">
                      <Icon prefix="fas" name="fire" color="red" />
                      {' '}
                      <strong>
                        57
                        {' '}
                        <span>calrías</span>
                      </strong>
                    </Box>
                    <Box className="btn white-bg rounded">
                      <Icon prefix="fas" name="tint" color="orange" />
                      {' '}
                      <strong>
                        0g
                        {' '}
                        <span>grassa</span>
                      </strong>
                    </Box>
                    <Box className="btn white-bg rounded">
                      <Icon prefix="fas" name="leaf" color="green" />
                      {' '}
                      <strong>
                        14,5g
                        {' '}
                        <span>carbh</span>
                      </strong>
                    </Box>
                    <Box className="btn white-bg rounded">
                      <Icon prefix="fas" name="fish" color="blue" />
                      {' '}
                      <strong>
                        0g
                        {' '}
                        <span>proteína</span>
                      </strong>
                    </Box>
                  </Col>
                  <Col sm="12" md="6">
                    <Heading type="h3">
                      <Icon prefix="fas" name="lemon" />
                      {' '}
                      Aporte de vitaminas:
                    </Heading>
                    <Lists>
                      <li>
                        <NavLink>Vitamina A</NavLink>
                      </li>
                      <li>
                        <NavLink>Vitamina C</NavLink>
                      </li>
                      <li>
                        <NavLink>Vitamina K</NavLink>
                      </li>
                    </Lists>
                    <Lists>
                      <li>
                        <NavLink>Vitamina B9</NavLink>
                      </li>
                    </Lists>
                  </Col>
                  <Col sm="12" md="6">
                    <Heading type="h3">
                      <Icon prefix="fas" name="layer-group" />
                      {' '}
                      Aporte de minerales:
                    </Heading>
                    <Lists>
                      <li>
                        <NavLink>Calcio</NavLink>
                      </li>
                      <li>
                        <NavLink>Potassio</NavLink>
                      </li>
                      <li>
                        <NavLink>Magnesio</NavLink>
                      </li>
                    </Lists>
                    <Lists>
                      <li>
                        <NavLink>Fósforo</NavLink>
                      </li>
                      <li>
                        <NavLink>Sodio</NavLink>
                      </li>
                    </Lists>
                  </Col>
                </Row>
              </Message>

              <TextContent data={contentMenu[1]} />

              <CardContentPost
                className="featured-post margin-top-medium"
                post={{
                  url: featuredPost.url,
                  thumb: featuredPost.thumb,
                  title: featuredPost.title,
                  excerpt: featuredPost.excerpt
                }}
              />

              <BoxBibliography
                label="Bibliografía"
                data={[
                  {
                    id: 1,
                    text: 'Muñoz Sandoval, M. y Poletti Vázquez, E. (2008). <em>Acné, dieta y debate: un veredicto pendiente.</em> Medicina Interna de MéxicoVol 24, Nº 5: 346-352. [En línea]. Disponible en:',
                    label: 'https://www.cmim.org/boletin/pdf2008/MedIntContenido05_07.pdf',
                    url: 'https://www.cmim.org/boletin/pdf2008/MedIntContenido05_07.pdf'
                  },
                  {
                    id: 2,
                    text: 'VV.AA. (2011). <em>Influencia de la dieta en el acné: revisión de la liteatura</em>.Rev. Chilena Dermatol. Vol. 27, Nº 1: 82-85. [En línea]. Disponible en:',
                    label: 'https://www.sochiderm.org/web/revista/27_1/9.pdf',
                    url: 'https://www.sochiderm.org/web/revista/27_1/9.pdf'
                  },
                ]}
              />

              <SliderPosts
                title="Recetas con arándanos"
                titleType="h2"
                data={relatedRecipes}
              />

              <AccordionFaq
                title="Contenido de interés sobre los arándanos"
                icon="apple-alt"
                className="margin-top-big"
                content={faqQuestions}
              />

              <SliderFeaturedPosts
                title="Otros alimentos"
                titleType="h3"
                data={relatedFoods}
              />

            </Col>
            <Col xs="12" md="4">

              <Box className="box-publicity align-center margin-top-medium margin-bottom-medium">
                <span>Publicidad</span>
                <Image src="https://static.criteo.net/design/dt/29088/200415/f9ffbf5c13d64470a3f41cd57dff10bc_cpn_336x280_1.png" alt="Publicidad" />
              </Box>

              <LastArticlesSidebar
                title="Últimos artículos"
                titleType="h3"
                className="margin-top-medium margin-bottom-medium"
                data={lastArticles}
              />

              <Box className="box-publicity align-center margin-top-medium margin-bottom-medium">
                <span>Publicidad</span>
                <Image src="https://s0.2mdn.net/simgad/7718551250627356464" alt="Publicidad" />
              </Box>

            </Col>

          </Row>
        </Container>
      </Box>
    </PageWrapper>
  </>
);

export default BlogFoods;
