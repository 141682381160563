import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  AccordionGroup,
  Accordion,
  AccordionControl,
  AccordionContent,
  NavLink,
  Image,
  Heading,
  Icon,
  Paragraph
} from 'base-ui';

const AccordionFaq = ({
  content,
  title,
  icon,
  className
}) => {
  useLayoutEffect(() => {
    function updateSize() {
      // open/close first child accordion
      const element = document.getElementsByClassName('accordion-node')[0];
      if (window.innerWidth >= 1024) {
        element.classList.add('open');
        element.lastChild.style.maxHeight = '500px';
      } else {
        element.classList.remove('open');
        element.lastChild.style.maxHeight = '0';
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const contentAccordion = content.map((value) => (
    <Accordion key={value.id} className="accordion-node">
      <AccordionControl className="accordion-title">
        <Heading type="h3" className="normal-font">{value.title}</Heading>
      </AccordionControl>
      <AccordionContent className="accordion-content">
        <NavLink to="#">
          <Box className="thumb-wrap">
            <Image src={value.image} className="thumb-bg" alt={value.title} />
          </Box>
          <Paragraph className="font-size-normal line-height-medium">
            {value.desc}
            <Box tag="span">
              {' '}
              &nbsp;Continuar leyendo...
            </Box>
          </Paragraph>
        </NavLink>
      </AccordionContent>
    </Accordion>
  ));
  return (
    <Box tag="section" className={`section-accordion ${className}`}>
      {title && (
        <Heading type="h2">
          <Icon prefix="fas" name={icon} />
          {' '}
          <span>{title}</span>
        </Heading>
      )}
      <AccordionGroup className="group-accordion">
        {contentAccordion}
      </AccordionGroup>
    </Box>
  );
};

AccordionFaq.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.array.isRequired,
};

export default AccordionFaq;
