import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  InputField,
  Label,
  Paragraph
} from 'base-ui';

// Components
import MenuAccount from '../../../components/Consultation/MenuAccount';

const Account = () => (
  <PageWrapper config={{ title: 'Consultation Access Not Allowed', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section" className="consultation-account">
        <Container container fluid wrap>
          <Row className="center-xs">
            <Col xs="12" sm="4">
              <MenuAccount />
            </Col>
            <Col xs="12" sm="8">
              <Card>
                <Box class="box-title grey-bg">
                  <Box tag="span" class="label main-font">Configuración de notificaciones push</Box>
                </Box>
                <Box>
                  <Paragraph>Recibe notificaciones push en tus diferentes dispositivos</Paragraph>
                  <InputField
                    id="a1"
                    type="checkbox"
                    required
                  />
                  <Label inputId="a1">
                    Mensajes
                    <Paragraph>Profesionales y usuarios</Paragraph>
                  </Label>
                  <hr />
                  <InputField
                    id="a2"
                    type="checkbox"
                    required
                  />
                  <Label inputId="a2">
                    Reservas
                    <Paragraph>Actualizaciones sobre tus reservas</Paragraph>
                  </Label>
                  <hr />
                  <InputField
                    id="a3"
                    type="checkbox"
                    required
                  />
                  <Label inputId="a3">
                    Actividades
                    <Paragraph>Actualizaciones sobre nuestros eventos</Paragraph>
                  </Label>
                </Box>
              </Card>
              <Card>
                <Box class="box-title grey-bg">
                  <Box tag="span" class="label main-font">Configuración de notificaciones por correo electrónico</Box>
                </Box>
                <Box>
                  <Paragraph>Recibe notificaciones directamente en tu correo electrónico</Paragraph>
                  <InputField
                    id="b1"
                    type="checkbox"
                    required
                  />
                  <Label inputId="b1">
                    General
                    <Paragraph>Promociones, novedades, noticias sobre la plataforma o promociones de servicios y campañas asociados, encuestas a los usuarios, historias inspiradora</Paragraph>
                  </Label>
                  <hr />
                  <InputField
                    id="b2"
                    type="checkbox"
                    required
                  />
                  <Label inputId="b2">
                    Recordatorios
                    <Paragraph>Recordatorios sobre las reservas y evaluaciones pendientes</Paragraph>
                  </Label>
                </Box>
              </Card>
              <Card>
                <Box class="box-title grey-bg">
                  <Box tag="span" class="label main-font">Configuración de llamadas</Box>
                </Box>
                <Box>
                  <Paragraph>Configura la forma en que podremos contactarte el equipo</Paragraph>
                  <InputField
                    id="c1"
                    type="checkbox"
                    required
                  />
                  <Label inputId="c1">
                    General
                    <Paragraph>Los usuarios tendrá configurado sus números, por lo que podemos llamarles con respecto a la cuenta, sus anuncios, su reservas o la comunidad de la plataforma</Paragraph>
                  </Label>
                </Box>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default Account;
