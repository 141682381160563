import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunk from 'redux-thunk';
import createReducers from '../reducers';

// Create the store with middleware
// 1. thunk: Makes redux-thunk work
export const middlewares = [
  thunk,
];

export default function configureStore(initialState = {}) {
  return createStore(
    createReducers(),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
}
