import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Icon,
  Container,
  Row,
  Col,
	Box,
  Breadcrumbs,
	PageWrapper,
	Paragraph,
	Heading
} from 'base-ui';

import ForumDiscussion from '../../../components/Forum/ForumDiscussion';
import ForumComments from '../../../components/Forum/ForumComments';

class ForumSingle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialItems: [
        {
          id: '1',
          commentAuthor: 'Sara Gonzalez',
					commentAuthorImg: './img/dummy/avatar-2.png',
					commentContent: '<p>Esperienza fantastica, oltre le aspettative . Host Mauro disponibile, molto cordiale e discreto. Soggiornare sullalbero crea atmosfera di totale relax, ci ha fatto vivere in una realtà surreale, oltre ad essere molto romantica. Grazie per la meravigliosa idea che avete avuto e lospitalità!</p>',
          votes: {
						number: '456',
					}
				},
				{
          id: '2',
          commentAuthor: 'Ricky Martin',
					commentAuthorImg: './img/dummy/avatar-4.png',
					commentContent: '<p>Esperienza fantastica, oltre le aspettative . Host Mauro disponibile, molto cordiale e discreto. Soggiornare sullalbero crea atmosfera di totale relax, ci ha fatto vivere in una realtà surreale, oltre ad essere molto romantica. Grazie per la meravigliosa idea che avete avuto e lospitalità!</p>',
          votes: {
						number: '12',
					}
				},
				{
          id: '3',
          commentAuthor: 'Maria Carmenza',
					commentAuthorImg: './img/dummy/avatar-3.png',
					commentContent: '<p>Esperienza fantastica, oltre le aspettative . Host Mauro disponibile, molto cordiale e discreto. Soggiornare sullalbero crea atmosfera di totale relax, ci ha fatto vivere in una realtà surreale, oltre ad essere molto romantica. Grazie per la meravigliosa idea che avete avuto e lospitalità!</p>',
          votes: {
						number: '823',
					}
				},
			],
			items: []
		};
  }
  
  componentDidMount() {
    this.setState({items: this.state.initialItems})
	}

	render() {
		return(
			<PageWrapper config={{ title: 'Single forum' }}>
				<Box>
					<Container fluid wrap>
						<Row className="center-xs">
							<Col xs="12">
								<Box>
									<Breadcrumbs className="margin-bottom-small">
										<Link to="."><Icon prefix="fas" name="home" /> Home</Link>
										<Link to=".">Cursos</Link>
										<Link to=".">Mindfulness para la vida cotidiana</Link>
										<Box tag="span">Foro</Box>
									</Breadcrumbs>
									<hr />
								</Box>
							</Col>
							<Col xs="12" md="10">
								<ForumDiscussion
									authorImg="./img/dummy/avatar-1.png"
									discussionTitle="Título de la pregunta hecha por el usuario"
									authorName="Stiven Martínez">
									<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc hendrerit gravida nisi ut sollicitudin. Sed vitae dictum justo, a posuere purus. Mauris ultricies sem in ipsum suscipit ornare eget in eros. Nulla lorem sapien, facilisis et urna vel, iaculis viverra mi. Vestibulum consectetur eros enim, non dignissim odio aliquam at. Sed a malesuada nibh. Vestibulum sollicitudin massa auctor leo auctor vehicula. Phasellus nec porttitor lacus. Cras in ex sit amet dui euismod ornare. Integer elementum posuere leo non suscipit. Sed vehicula dignissim felis, quis laoreet purus ultrices accumsan. Curabitur sagittis neque in lectus congue blandit. Pellentesque vel urna non quam rhoncus ornare nec vitae felis.</Paragraph>
									<Paragraph>In molestie porta dui, sed sagittis neque scelerisque nec. Vestibulum at sollicitudin eros, et rhoncus eros. Aliquam sit amet urna sagittis, euismod sem et, vulputate urna. Mauris malesuada erat ut sagittis finibus. Nunc maximus tempus enim non ultricies. Ut semper leo odio, et tristique quam iaculis vitae. Pellentesque ex arcu, dictum et dui euismod, ornare fermentum erat.</Paragraph>
								</ForumDiscussion>
							</Col>
							<Col xs="12" md="10">
								<Box className="section-title margin-top-x-medium">
									<Heading type="h3" className="main-font without-margin-top">Respuesta recibidas</Heading>
								</Box>
							</Col>
							<Col xs="12" md="10" className="margin-bottom-x-big">
								<ForumComments
									items={this.state.items}
								/>
							</Col>
						</Row>
					</Container>
				</Box>
			</PageWrapper>
		)
	}
};

export default ForumSingle;
