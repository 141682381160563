/* eslint-disable func-names */
/* eslint-disable no-plusplus */
import React, { Component } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Modal,
  Image,
  NavLink,
  Button
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooterCTA from './components/UnycosFooterCTA';
import UnycosSingleFeatures from './components/UnycosSingleFeatures';
import UnycosFooter from './components/UnycosFooter';
import UnycosSingleExclusive from './components/UnycosSingleExclusive';
import UnycosSingleCourses from './components/UnycosSingleCourses';
import UnycosSingleMoreInfo from './components/UnycosMoreInfo';
import UnycosBlogArticles from './components/UnycosBlogArticles';
import UnycosSingleLesson from './components/UnycosSingleLesson';
import UnycosSingleSlides from './components/UnycosSingleSlides';
import UnycosStudentComments from './components/UnycosStudentComments';
import UnycosSingleLessonMobile from './components/UnycosSingleLessonMobile';
// import UnycosSingleFAQ from './components/UnycosSingleFAQ';
import UnycosSingleReviews from './components/UnycosSingleReviews';
import UnycosUserComments from './components/UnycosUserComments';

class UnycosSingle extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    // ease animation
    Math.easeInOutQuad = function (t, b, c, d) {
      t /= d / 2;
      if (t < 1) return ((c / 2) * (t * t)) + b;
      t--;
      return ((-c / 2) * ((t * (t - 2)) - 1)) + b;
    };

    // animation
    function scrollTo(to, duration) {
      const element = document.scrollingElement;
      const start = (element && element.scrollTop) || window.pageYOffset;
      const change = to - start;
      const increment = 20;
      let currentTime = 0;

      const animateScroll = function () {
        currentTime += increment;
        const val = Math.easeInOutQuad(currentTime, start, change, duration);
        window.scrollTo(0, val);
        if (currentTime < duration) {
          window.setTimeout(animateScroll, increment);
        }
      };
      animateScroll();
    }
    // listener click goTo
    const btsClick = document.querySelectorAll('.bt-click');
    let hTarget = 0;
    btsClick.forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        const elementToGo = e.target.getAttribute('data-target');
        const box = document.getElementById(elementToGo);
        const targetPos = box.getBoundingClientRect();
        const atualPos = window.pageYOffset;
        const offset = (window.innerHeight - targetPos.height) / 2;
        hTarget = targetPos.top + atualPos - offset;
        scrollTo(box.scrollTop || hTarget, 1000);
      });
    });

    // config fixed floatingCTA
    const fixedbox = document.getElementsByClassName('box-floating-cta')[0];
    let lastClassName;
    let currentScrollPos = window.pageYOffset;

    function setHeaderPosition() {
      let className;
      if (window.innerWidth <= 1024) {
        className = 'bottom-fixed';
      } else {
        className = 'top-fixed';
      }
      fixedbox.classList.add(className);
      fixedbox.classList.add('show');
      if (currentScrollPos < 100) {
        fixedbox.classList.remove('show');
      }
      lastClassName = className;
    }

    // show/hide fixed floatingCTA on resize page
    window.addEventListener('resize', () => {
      fixedbox.classList.remove(lastClassName);
      setHeaderPosition();
    });

    // show/hide fixed floatingCTA on scroll page
    window.addEventListener('scroll', () => {
      currentScrollPos = window.pageYOffset;
      setHeaderPosition();
    });

    document.querySelectorAll('#single-more-info input[type=input]').forEach((item) => {
      item.addEventListener('focus', () => {
        if (window.innerWidth <= 1024) {
          fixedbox.classList.remove('show');
        }
      });
      item.addEventListener('blur', () => {
        if (window.innerWidth <= 1024) {
          fixedbox.classList.add('show');
        }
      });
    });

    setHeaderPosition();
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal } = this.state;
    return (
      <PageWrapper className="page-single" config={{ title: 'Unycos home', bodyClasses: 'single-page' }}>
        <UnycosLayout>
          <Box className="box-floating-cta">
            <Button to="#" onClick={this.openModal} className="bt-spot">Ver spot</Button>
            <Button to="#" className="bt-more-info bt-click black-color" data-target="single-more-info">Más información</Button>
          </Box>

          <UnycosHeader statuslogged={false} />

          <Box id="unycos-single-hero">
            <Box className="unycos-single-hero single-page">
              <Container fluid wrap>
                <Row className="bottom-xs position-relative">
                  <Box className="thumb">
                    <Box className="box-shadow"></Box>
                    <Image lazy src="./img/unycos/hero/hero-mireia.jpg" loadingProps={{ type: 'blur' }} alt="title" />
                  </Box>
                  <Col xs="12">
                    <NavLink to="#" onClick={this.openModal} className="bt-video"><Image src="./img/unycos/icons/icon_video.svg" alt="Show Video" /></NavLink>
                    <Box className="caption center-xs align-center">
                      <Heading type="h2" size="x-big" textColor="white" className="teacher-name normal-font text-uppercase">
                      Mireia Belmonte
                      </Heading>
                      <Heading type="h3" size="big" textColor="white" className="text-uppercase course-name normal-font">
                        <Box tag="span" className="teaches">enseña</Box>
                        <Box tag="span">Natación</Box>
                      </Heading>
                    </Box>
                  </Col>
                </Row>
              </Container>
            </Box>
            <Modal
              isOpen={showModal}
              appElement="#root"
              idModal="modal-video-single"
              className="modal-video-single"
              closeModal={this.closeModal}
              closeIcon={false}
              styles={{
                overlay: {
                  position: 'fixed',
                },
                content: {
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  boxShadow: 'none',
                  padding: '0',
                  overflow: 'unset',
                  top: '25px',
                  right: '10px',
                  bottom: '10px',
                  left: '10px',
                },
                close: {
                  display: 'none',
                }
              }}
            >
              <Button onClick={this.closeModal} id="bt-close-modal"><Image src="./img/unycos/icons/icon_times.svg" alt={''} /></Button>
              <iframe id="video" title="Mireia Belmonte" src="https://player.vimeo.com/video/345710922?embedparameter=value" width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
            </Modal>
          </Box>

          <UnycosSingleFeatures />
          {/* <UnycosSingleFAQ className="hidden-lg" /> */}
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <UnycosSingleSlides />
              </Col>
            </Row>
          </Container>
          <UnycosSingleExclusive />
          <UnycosSingleLesson />
          <UnycosSingleLessonMobile className="hidden-lg" />
          <UnycosSingleReviews className="hidden-lg" />
          <UnycosSingleCourses className="hidden-md" />
          <UnycosSingleMoreInfo />
          <UnycosUserComments className="hidden-lg" />
          <UnycosSingleCourses className="hidden-lg" />
          <UnycosStudentComments className="hidden-md" margin="-4px" />
          <UnycosBlogArticles />
          <UnycosFooterCTA />
          <UnycosFooter showHideElements={{ social: true, cta: true }} />
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

export default UnycosSingle;
