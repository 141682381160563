import React from 'react';

import {
  PageWrapper
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';
import UnycosRecoverPassword from './components/UnycosRecoverPassword';

class UnycosHome extends React.PureComponent {
  render() {
    return (
      <PageWrapper config={{ title: 'Unycos Recover Password' }}>
        <UnycosLayout>
          <UnycosHeader statuslogged={false} />
          <UnycosRecoverPassword />
          <UnycosFooter showHideElements={{ social: false, cta: false }} />
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

export default UnycosHome;
