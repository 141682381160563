import React from 'react';

import {
  PageWrapper,
  Container,
  Box,
  Row,
  Col,
  Card,
  Heading,
  Paragraph,
  Icon,
  NavLink,
  Button
} from 'base-ui';

// Components
import QuizQuestion from '../../../components/Courses/QuizQuestion';

// Data
import { QuestionsData } from './QuestionsData';

// Styles
import './style.scss';

const Quiz = () => (
  <PageWrapper config={{ bodyClasses: 'platform-taking-course-quiz' }}>
    <Box tag="section" id="taking-course" class="platform-taking-course">
      <Box className="taking-course-header">
        <Container fluid>
          <Row className="middle-xs">
            <Col xs="2">
              <NavLink class="white-color transparent-border" href="/cursos/matriculas/">
                <Icon prefix="fas" name="arrow-left" />
              </NavLink>
            </Col>
            <Col xs="8" className="align-center center-xs">
              <Heading type="h2" className="font-size-normal white-color taking-course-title main-font">Quiz</Heading>
            </Col>
            <Col xs="2" className="end-xs">
              <NavLink className="btn transparent-border white-color">
                <Icon prefix="fas" name="bars" />
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box className="taking-course-content">
        <Container fluid wrap>
          <Card className="card-main without-border without-shadow">
            {QuestionsData.map(({
              id,
              question,
              options,
              answare,
              teacherComment
            }) => (
              <QuizQuestion
                key={id}
                data={
                  {
                    id,
                    question,
                    options,
                    answare,
                    teacherComment
                  }
                }
              />
            ))}
            <hr />
            <Button color="main" borderColor="main" textColor="white" className="bt-save">Registrar respuestas</Button>
          </Card>
        </Container>
      </Box>

      <Box className="taking-course-footer">
        <Container fluid>
          <Row className="middle-xs center-xs">
            <Col xs="4" className="middle-xs">
              <Button className="transparent-border transparent-bg  full titles-color" type="button" id="previousButton">
                <Icon prefix="fas" name="chevron-left" />
                {' '}
                <span className="hidden-sm">Anterior</span>
              </Button>
            </Col>
            <Col xs="4" className="middle-xs align-center center-xs middle-xs">
              <Paragraph>100/100</Paragraph>
            </Col>
            <Col xs="4" className="end-xs middle-xs">
              <Button className="transparent-border transparent-bg  full titles-color" type="button" id="nextButton">
                <span className="hidden-sm">Siguiente</span>
                {' '}
                <Icon prefix="fas" name="chevron-right" />
              </Button>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
  </PageWrapper>
);

export default Quiz;
