import React, { useState } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Icon,
  Image,
  Heading,
  Paragraph,
  NavLink
} from 'base-ui';

// Components
// import Layout from '../../../components/Consultation/Layout';
import MenuPublish from '../../../components/Consultation/MenuPublish';

const PublishPart4 = () => {
  const [showMenuPublish, setShowMenuPublish] = useState(false);

  const handlerClickMenuPublish = () => {
    setShowMenuPublish(!showMenuPublish);
  };

  return (
    <PageWrapper config={{ title: 'Consultation Publish Part 4', bodyClasses: `consultation publish ${showMenuPublish ? 'open-aside' : ''}` }}>

      <Box tag="main" id="site-main">

        <MenuPublish toggleAction={handlerClickMenuPublish} />

        <Box tag="section" id="single" className="single-publish">
          <Container fluid wrap>
            <Row>
              <Col xs="12">

                <Card>
                  <Card className="card-finish-header buc-secondary-border buc-secondary-bg white-color">
                    <Row className="middle-sx">
                      <Col xs="12" sm="6">
                        <Heading type="h4" className="white-color without-margin">Vista previa del servicio</Heading>
                        <Paragraph className="without-margin">
                          Revisa que todos datos sean correctos y cumpla con los estándares. Si todo esta bien nuestro equipo lo aprobará. Serás notificado por correo electrónico
                        </Paragraph>
                      </Col>
                      <Col xs="12" sm="6" className="end-md">
                        <NavLink className="btn blue-border blue-bg big white-color">
                          Enviar para revisar
                          {' '}
                          <Icon prefix="fas" name="paper-plane" />
                        </NavLink>
                      </Col>
                    </Row>
                  </Card>
                  <Card>
                    <Row>
                      <Col xs="12" md="8">
                        <Row className="single-service">
                          <Col xs="12">
                            <Heading type="h1" className="service-title font-x-big">Consulta gratis</Heading>
                          </Col>
                          <Col xs="12" sm="8" className="meta">
                            <Paragraph className="professional">Profesional: Gabriel Terapeuta</Paragraph>
                          </Col>
                          <Col xs="12" sm="4">
                            <Box className="avatar medium avatar-image">
                              <Image src="https://storage.googleapis.com/staging-storage-1/content/dev.lamenteesmaravillosa.com/users/5e8a0217034af30013808921/profile_1586102845197.png" alt="Thumb Avatar Professional" />
                            </Box>
                          </Col>
                        </Row>
                        <br />
                        <Card>
                          <Row>
                            <Col xs="12" md="6">
                              <Heading type="h2" className="title-info">Acerca del profesional</Heading>
                            </Col>
                            <Col xs="12" md="6">
                              <Paragraph className="publish-data">Lorem ipsum.</Paragraph>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xs="12" md="6">
                              <Heading type="h2" className="title-info">Descripción</Heading>
                            </Col>
                            <Col xs="12" md="6">
                              <Paragraph className="publish-data">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent convallis, mi et fringilla tincidunt, nunc tellus dapibus sem, in faucibus est quam ut justo. Aliquam ut pharetra ante, sit amet tristique sapien. Curabitur elementum sem mi, vel interdum orci lacinia vitae. Donec dictum eros eu suscipit fermentum. Donec consequat ligula eu justo porta luctus. Suspendisse maximus ante id ligula fringilla volutpat. Sed turpis felis, ullamcorper a neque ac, molestie euismod sem. Mauris sagittis sapien ut lectus pulvinar auctor.</Paragraph>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xs="12" md="6">
                              <Heading type="h2" className="title-info">Política de cancelación</Heading>
                            </Col>
                            <Col xs="12" md="6">
                              <Paragraph className="publish-data">Política flexible</Paragraph>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col xs="12" md="4" id="sidebar">
                        <Card className=" service-preview-info">
                          <figure className="featured-thumbnail">
                            <Image src="https://storage.googleapis.com/staging-storage-1/content/dev.lamenteesmaravillosa.com/services/5e8cbee2cd0d240012dd5c87/1-consulta-gratis.jpeg" alt="Thumb Avatar Professional" />
                          </figure>
                          <Box className="textpart">
                            <Box className="cost center-xs">
                              <Paragraph className="end-xs">
                                <strong ng-if="price.currency === 'USD'">50€</strong>
                                <Box tag="span">/por consulta</Box>
                              </Paragraph>
                            </Box>
                            <NavLink className="btn blue-border blue-bg white-color full filled">Ver fechas</NavLink>
                            <Box className="share align-center">
                              <NavLink className="facebook" href="#"><Icon prefix="fab" name="facebook" className="facebook-color" /></NavLink>
                              <NavLink className="twitter" href="#"><Icon prefix="fab" name="twitter" className="twitter-color" /></NavLink>
                              <NavLink className="google-plus" href="#"><Icon prefix="fab" name="google-plus" className="googleplus-color" /></NavLink>
                              <NavLink className="mail" href="#"><Icon prefix="fab" name="envelope-o" /></NavLink>
                            </Box>
                            <NavLink href="#" className="black report">
                              <small>
                                <Icon prefix="fa" name="flag" />
                                {' '}
                                Denunciar este servicio
                              </small>
                            </NavLink>
                          </Box>
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Card>

              </Col>
            </Row>
          </Container>
        </Box>
      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};

export default PublishPart4;
