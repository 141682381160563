import React from 'react';
import PropTypes from 'prop-types';

import {
  Image, Heading, Paragraph, Box, Icon
} from 'base-ui';

import './styles.css';

const TestimonialsAvatar = ({
  by,
  forName,
  withRole,
  profileImage,
  textColor,
  className,
  testimony,
  showQuote,
}) => (
  <Box className={`testimony ${className}`}>
    <Box className="testimony-text">
      {showQuote && (
        <Icon
          name="quote-left"
          prefix="fas"
          size="big"
          className="quote-icon open"
        />
      )}
      <Paragraph>{testimony}</Paragraph>
      {showQuote && (
        <Icon
          name="quote-right"
          prefix="fas"
          size="big"
          className="quote-icon close"
        />
      )}
    </Box>
    <Box className="testimony-by">
      <Image src={profileImage} />
      <Box className="testimony-info">
        <Heading
          type="h5"
          size="small"
          textColor={textColor}
          className="without-margin"
        >
          Escrito por
          {' '}
          {by}
          {' para'}
        </Heading>
        <Heading
          type="h4"
          size="medium"
          textColor={textColor}
          className="without-margin"
        >
          {forName}
        </Heading>
        <Paragraph textColor={textColor} className="without-margin">
          {withRole}
        </Paragraph>
      </Box>
    </Box>
  </Box>
);

TestimonialsAvatar.propTypes = {
  by: PropTypes.string,
  forName: PropTypes.string,
  withRole: PropTypes.string,
  profileImage: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
  testimony: PropTypes.string,
  showQuote: PropTypes.bool,
};

export default TestimonialsAvatar;
