import React, { Component } from 'react';
import moment from 'moment';
import cs from 'classnames';

import {
  Box,
  Col,
  Container,
  PageWrapper,
  Row,
  Heading,
  Paragraph,
  Image,
  Icon,
  NavLink,
  Button,
} from 'base-ui';

import ScheduleCalendar from '../../../components/Consultation/ScheduleCalendar';
import ScheduleTime from '../../../components/Consultation/ScheduleTime';
import SchedulePayment from '../../../components/Consultation/SchedulePayment';
import TimeZonePicker from '../../../components/Consultation/TimeZonePicker';

import './styles.css';

class ScheduleNew extends Component {
  state = {
    title: 'Consulta Online',
    withThe: {
      profilePicture: './img/dummy/avatar-4.png',
      name: 'Irati Novella',
      role: 'Psicóloga / Coach',
      bio:
        'Dore me dori latire dori, me interimo ameno dimere imperavi adapare omenare reo matiro. Latire ameno interimo imperavi reo matiremo matiro emulari adapare dori me omenare me latiremo.',
    },
    type: 'Videollamada',
    estimedTime: '35 min',
    price: '40 €',
    scheduleDate: {},
    timezone: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    hours: [
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
    ],
    showTimeSelect: false,
    isPayment: false,
    isCompleted: false,
  };

  setDate = (date) => {
    const {
      dayName, day, selectedMonth, year
    } = date;

    const scheduleDate = {
      dayName,
      day,
      selectedMonth,
      year,
    };

    this.setState({
      scheduleDate,
      showTimeSelect: true,
    });
  };

  setHour = (hour) => {
    const { scheduleDate } = this.state;
    scheduleDate.hour = hour;

    this.setState({
      scheduleDate,
      showTimeSelect: false,
      isPayment: true,
    });
  };

  setTimezone = (timezone) => {
    this.setState({
      timezone,
    });
  };

  onBuy = () => {
    this.setState({
      isCompleted: true,
    });
  };

  backState = () => {
    const { scheduleDate, showTimeSelect } = this.state;

    if (!scheduleDate.hour || showTimeSelect) {
      this.setState({
        showTimeSelect: false,
      });
    } else {
      this.setState({
        isPayment: false,
        showTimeSelect: true,
      });
    }
  };

  applyCoupon = (coupon) => {
    let { price } = this.state;

    // fake coupon validation
    if (coupon === 'blablabla') {
      const discountPercentage = 50 / 100;
      const actualPrice = Number(price.split('€')[0]);
      // calculate new price
      const newPrice = actualPrice - (actualPrice * discountPercentage);
      price = `${newPrice.toFixed(2)}€`;

      this.setState({
        hasCoupon: true,
        price,
      });

      return {
        status: 'success',
        message: `Descuento aplicado -${discountPercentage.toFixed(2).split('.')[1]}%`
      };
    }

    return {
      status: 'error',
      message: '¡Cupón no válido!'
    };
  }

  formatSchedule = () => {
    const { scheduleDate, estimedTime } = this.state;
    const {
      day, selectedMonth, year, hour
    } = scheduleDate;

    const endTime = String(
      parseFloat(hour.split(':').join('.'))
        + parseFloat(`0.${estimedTime.split(' ')[0]}`)
    );
    const monthNumber = moment().month(selectedMonth).format('M');
    const formatedDate = moment(`${monthNumber}-${day}-${year} ${hour}`);
    // console.log(`${formatedDate.format('LLLL')} - ${endTime.split('.').join(':')}`);
    return `${formatedDate.format('LLLL').charAt(0).toUpperCase() + formatedDate.format('LLLL').slice(1)} - ${endTime.split('.').join(':')}`;
  };

  render() {
    const {
      title,
      withThe,
      type,
      estimedTime,
      price,
      timezone,
      hours,
      scheduleDate,
      showTimeSelect,
      isPayment,
      isCompleted,
      hasCoupon,
    } = this.state;

    return (
      <React.Fragment>
        <PageWrapper config={{ title }}></PageWrapper>
        <Box className="schedule">
          <Row>
            <Col
              xs="12"
              sm="4"
              md="4"
              lg="3"
              className="schedule-info main-bg white-color"
            >
              <Container fluid>
                <Box tag="aside">
                  <Box tag="header">
                    <Box tag="nav" className="schedule-nav">
                      {scheduleDate.day && !isCompleted && (
                        <Button
                          tag="button"
                          color="transparent"
                          textColor="white"
                          borderColor="transparent"
                          onClick={this.backState}
                          className="back"
                        >
                          <Icon
                            name="chevron-left"
                            prefix="fas"
                            size="medium"
                          />
                        </Button>
                      )}
                      <NavLink to="profile">
                        <Image
                          src="./img/lmem-logo-white.svg"
                          alt="Logo La Mente es Maravillosa"
                          className="logo-white"
                        />
                        <Image
                          src="./img/logo.svg"
                          alt="Logo La Mente es Maravillosa"
                          className="logo-normal"
                        />
                      </NavLink>
                    </Box>
                    <Box className="header-info">
                      <Image
                        src={withThe.profilePicture}
                        alt={withThe.name}
                        className="profile-image"
                      />
                      <Heading
                        type="h2"
                        textColor="white"
                        size="x-big"
                        className="profile-name"
                      >
                        {withThe.name}
                      </Heading>
                      <Heading
                        type="h3"
                        textColor="white"
                        size="medium"
                        className="profile-name"
                      >
                        {withThe.role}
                      </Heading>
                    </Box>
                  </Box>
                  <Paragraph className="profile-bio margin-top-big">
                    {withThe.bio}
                  </Paragraph>
                </Box>
              </Container>
            </Col>
            <Col
              xs="12"
              sm="8"
              md="8"
              lg="9"
              className={cs('schedule-app', {
                'is-active': showTimeSelect,
                'is-payment': scheduleDate.hour && isPayment,
              })}
            >
              <Container fluid>
                {isCompleted && (
                  <Heading
                    type="h2"
                    size="big"
                    textColor="main"
                    className="margin-bottom-x-big align-center"
                  >
                    ¡Reserva Confirmada!
                  </Heading>
                )}
                <Heading
                  type="h1"
                  textColor="titles"
                  size="big"
                  className="title align-center"
                >
                  {title}
                </Heading>
                <Box
                  className={cs('info text-color align-center', {
                    'margin-bottom-small': !isPayment && !isCompleted,
                  })}
                >
                  <span className={cs({ 'secondary-color': hasCoupon })}>
                    <Icon
                      name="money-bill-alt"
                      size="small"
                      prefix="far"
                    />
                    {' '}
                    {price}
                  </span>
                  <span>
                    <Icon name="video" size="small" prefix="fas" color="text" />
                    {' '}
                    {type}
                  </span>
                  <span>
                    <Icon name="clock" size="small" prefix="far" color="text" />
                    {' '}
                    {estimedTime}
                  </span>
                </Box>
                {isPayment ? (
                  <Box className="payment">
                    <Paragraph textColor="text" className="align-center">
                      <span className="payment-info align-center">
                        <Icon name="calendar" prefix="far" />
                        {' '}
                        {this.formatSchedule()}
                      </span>
                      <span className="payment-info align-center">
                        <Icon name="globe-americas" prefix="fas" />
                        {' '}
                        {timezone}
                      </span>
                    </Paragraph>
                    <SchedulePayment
                      onBuy={this.onBuy}
                      isCompleted={isCompleted}
                      price={price}
                      validateCoupon={this.applyCoupon}
                      hasCoupon={hasCoupon}
                      textColor="text"
                      color="white"
                    />
                  </Box>
                ) : (
                  <React.Fragment>
                    <Paragraph textColor="main" size="normal" className="align-center">
                      {Object.entries(scheduleDate).length === 0 ? (
                        <strong>Selecciona un día</strong>
                      ) : (
                        <>
                          <Icon
                            name="calendar"
                            prefix="far"
                            className="icon text-color"
                          />
                          {' '}
                          <span className="text-color">{`${scheduleDate.dayName.charAt(0).toUpperCase() + scheduleDate.dayName.slice(1)} ${scheduleDate.day} de ${scheduleDate.selectedMonth} de ${scheduleDate.year}`}</span>
                        </>
                      )}
                    </Paragraph>
                    <ScheduleCalendar
                      lang="es"
                      daysAvailable={{
                        5: [1, 4, 5, 6, 7, 8, 11, 12, 13],
                        6: [4, 5, 6],
                        7: [7, 8, 9],
                      }}
                      selectedDay={scheduleDate.day || ''}
                      selectedMonth={scheduleDate.selectedMonth || ''}
                      setDate={this.setDate}
                    />
                    <TimeZonePicker currentValue={timezone} onChange={(v) => this.setTimezone(v)} />
                    {/* <Box className="schedule-timezone">
                      <Icon
                        name="globe-americas"
                        prefix="fas"
                        className="icon"
                      />
                      <InputSelect
                        value={timezone}
                        required
                        onChange={(v) => this.setTimezone(v)}
                        options={[
                          { value: 'Europa Central', label: 'Europa Central' },
                        ]}
                      />
                    </Box> */}
                  </React.Fragment>
                )}
              </Container>
            </Col>
            { showTimeSelect && <Box className="overlay" onClick={this.backState}></Box> }
            <ScheduleTime
              isActive={showTimeSelect}
              title="Selecciona una hora"
              estimedTime={estimedTime}
              hours={hours}
              setHour={this.setHour}
              activeHour={scheduleDate.hour || ''}
              formattedScheduleDate={showTimeSelect ? `${scheduleDate.dayName.charAt(0).toUpperCase() + scheduleDate.dayName.slice(1)} ${scheduleDate.day} de ${scheduleDate.selectedMonth} de ${scheduleDate.year}` : ''}
            />
          </Row>
        </Box>
      </React.Fragment>
    );
  }
}

export default ScheduleNew;
