import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Box,
  Col,
  Heading,
  Hero,
  Button,
  Lists,
  Paragraph,
  Image,
  NavLink,
  Slider,
} from 'base-ui';

import Testimony from '../../../components/Consultation/Testimony';

import './styles.css';

const ConsultationQuizLanding = () => (
  <React.Fragment>
    <Box className="quiz-topbar">
      <NavLink to="/" className="quiz-topbar-logo">
        <Image src="./img/logo.svg" alt="Logo La Mente es Maravillosa" />
      </NavLink>
    </Box>
    <PageWrapper config={{ title: 'Terapia' }}>
      <Box className="quiz-landing home">
        <Hero
          BgImage="./img/lmem/woman-hero.jpeg"
          className="align-center hero"
        >
          <Container fluid wrap>
            <Row className="middle-xs">
              <Col xs="12" md="12">
                <Heading
                  type="h1"
                  size="x-big"
                  className="title"
                  textColor="white"
                >
                  Mereces ser Feliz
                </Heading>
                <Heading type="h2" className="subline" textColor="white">
                  ¿Qué tipo de terapia buscas?
                </Heading>
                <Box className="align-center">
                  <NavLink
                    to={{
                      pathname: '/consultation/quiz/app',
                      state: { currentStep: 1 }
                    }}
                  >
                    <Button
                      tag="button"
                      color="secondary"
                      textColor="white"
                      size="big"
                      borderColor="secondary"
                    >
                      Individual
                    </Button>
                  </NavLink>
                  <NavLink
                    to={{
                      pathname: '/consultation/quiz/app',
                      state: { currentStep: 1 }
                    }}
                  >
                    <Button
                      tag="button"
                      color="secondary"
                      textColor="white"
                      size="big"
                      borderColor="secondary"
                    >
                      Pareja
                    </Button>
                  </NavLink>
                  <NavLink
                    to={{
                      pathname: '/consultation/quiz/app',
                      state: { currentStep: 1 }
                    }}
                  >
                    <Button
                      tag="button"
                      color="secondary"
                      textColor="white"
                      size="big"
                      borderColor="secondary"
                    >
                      Niños o adolescentes
                    </Button>
                  </NavLink>
                </Box>
              </Col>
            </Row>
          </Container>
        </Hero>
        <Box className="psi full-width-bg medium regent-gray-bg">
          <Container fluid wrap>
            <Row className="center-xs">
              <Col xs="12" sm="12" md="6" className="psi-text">
                <Heading type="h3" size="big">
                  Psicólogos con experiencia en los que puedes confiar
                </Heading>
                <Lists type="ol">
                  <li>1. Elegidos bajo un proceso riguroso de selección</li>
                  <li>2. Con formación homologada y especializada</li>
                  <li>3. Colegiados y capacitados para realizar terapia online</li>
                </Lists>
                <NavLink to="/consultation/quiz/app">
                  <Button
                    tag="button"
                    color="main"
                    size="big"
                    textColor="white"
                    borderColor="primary"
                  >
                    Quiero que me asignen un psicólogo
                  </Button>
                </NavLink>
              </Col>
              <Col xs="12" sm="12" md="6" className="align-center position-relative">
                <Image
                  src="./img/lmem/brain-illustra.svg"
                  className="illustra"
                />
              </Col>
            </Row>
          </Container>
        </Box>
        <Box className="how-to full-width-bg medium white-bg">
          <Container fluid wrap>
            <Row className="align-center">
              <Col xs="12">
                <Heading type="h3" size="big">
                  ¿Cómo funciona?
                </Heading>
              </Col>
            </Row>
            <Row className="how-works">
              <Col xs="12" md="3" className="align-center">
                <Image src="./img/lmem/brain.png" alt="Brain Illustration" />
                <Heading type="h4">
                  Te ayudamos a encontrar el profesional más adecuado para ti
                </Heading>
                <Paragraph>
                  Responde unas breves preguntas y nuestro equipo seleccionará al profesional que mejor se adapte a tus necesidades.
                </Paragraph>
              </Col>
              <Col xs="12" md="3" className="align-center">
                <Image
                  src="./img/lmem/interview.png"
                  alt="Interview Illustration"
                />
                <Heading type="h4">Reserva una cita</Heading>
                <Paragraph>
                  Elige el día y la hora para tu primera sesión.
                </Paragraph>
              </Col>
              <Col xs="12" md="3" className="align-center">
                <Image src="./img/lmem/payment.png" alt="Brain Illustration" />
                <Heading type="h4">Paga por consulta</Heading>
                <Paragraph>
                  Sin suscripción ni tarifas sorpresas. Paga por cada sesión.
                </Paragraph>
              </Col>
              <Col xs="12" md="3" className="align-center">
                <Image
                  src="./img/lmem/satisfaction.png"
                  alt="Brain Illustration"
                />
                <Heading type="h4">Satisfacción garantizada</Heading>
                <Paragraph>
                  Estamos seguros de que te encantará el psicólogo que hemos seleccionado para ti, pero si no cumple con tus expectativas, te buscamos otro sin ningún compromiso.
                </Paragraph>
              </Col>
            </Row>
            <Box className="align-center" style={{ width: '100%' }}>
              <NavLink to="/consultation/quiz/app">
                <Button
                  tag="button"
                  color="secondary"
                  textColor="white"
                  borderColor="secondary"
                  size="big"
                >
                  Empezar ahora
                </Button>
              </NavLink>
            </Box>
          </Container>
        </Box>
        <Box className="testimonials medium main-bg white-color">
          <Container fluid wrap className="align-center">
            <Heading type="h3" size="big" textColor="white">
              Testimonios
            </Heading>
          </Container>
          <Box>
            <Slider
              autoplay={false}
              dots
              responsive={[
                {
                  breakpoint: 3500,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                    centerMode: false,
                    adaptiveHeight: true
                  },
                },
              ]}
            >
              <Testimony
                textColor="white"
                by="un paciente"
                forName="Gema Sánchez Cuevas"
                withRole="Psicóloga"
                profileImage="./img/dummy/avatar-4.png"
                testimony="Ya había asistido a consulta presencial con Gema, pero mi situación actual no me permite seguir haciéndolo, así que decidí darle una oportunidad a la plataforma. Al principio dudaba de la terapia online, pero me ha sorprendido mucho lo bien que funciona."
              />
              <Testimony
                textColor="white"
                by="un paciente"
                forName="Julia Marquez Arrico"
                withRole="Psicóloga"
                profileImage="./img/dummy/avatar-4.png"
                testimony="Desde pequeña he tenido problemas de autoestima que me han impedido luchar por lo que realmente quiero, pero no me atrevía a buscar ayuda profesional. Pensé en hacerlo de forma online y encontré esta plataforma y a Julia. Las consultas por videollamada son una buena manera de comenzar la terapia para los que no lo hemos hecho nunca. Gracias a Julia me siento capaz de lograr lo que me proponga."
              />
              <Testimony
                textColor="white"
                by="un paciente"
                forName="Fátima Servián Franco"
                withRole="Psicóloga"
                profileImage="./img/dummy/avatar-4.png"
                testimony="Hace poco decidí abrir un negocio y desde entonces he padecido estrés y ansiedad. Al principio decidí llevarlo solo, pero llegó un punto en que no pude más. Con la ayuda de Fátima siento que puedo manejar la situación, lo cual repercute también en el éxito del negocio. Si no fuera por esta plataforma y la comodidad de las consultas online, ni siquiera habría tenido tiempo de encontrar la solución. Ya había asistido a consulta presencial con Gema, pero mi situación actual no me permite seguir haciéndolo."
              />
            </Slider>
          </Box>
        </Box>
        <Box className="full-width-bg medium white-bg big">
          <Container fluid wrap>
            <Row className="align-center">
              <Col xs="12">
                <Heading type="h3" size="big">
                  Preguntas frecuentes
                </Heading>
              </Col>
            </Row>
            <Row className="contact-us center-xs without-margin">
              <Col xs="12" className="align-center">
                <Box className="item">
                  <Heading>¿Cómo se realizan las sesiones online?</Heading>
                  <Paragraph>
                    Las sesiones se hacen a través de videollamada, te enviaremos por email un enlace con el que podrás acceder directamente minutos antes de la hora establecida.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Heading>¿Qué precio tienen las sesiones?</Heading>
                  <Paragraph>
                    El valor lo establece cada profesional, por lo que hay precios diferentes dependiendo de cada psicólogo y del tipo de consulta o terapia.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Heading>
                    ¿Los psicólogos y coaches están certificados?
                  </Heading>
                  <Paragraph>
                    Todos nuestros profesionales han presentado documentación que acredita que están capacitados para ejercer, como su número de colegiado y la habilitación sanitaria.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Heading>¿Puedo cancelar o cambiar mi cita?</Heading>
                  <Paragraph>
                    {'Sí, de acuerdo a la disponibilidad del profesional y a nuestra política de cancelaciones: '}
                    <NavLink
                      to="https://lamenteesmaravillosa.com/consultas/policia-de-cancelacion/flexible/"
                      target="_blank"
                      className="secondary-color"
                    >
                      https://lamenteesmaravillosa.com/consultas/policia-de-cancelacion/flexible/
                    </NavLink>
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Heading>
                    ¿Puedo comunicarme con el profesional antes de la cita?
                  </Heading>
                  <Paragraph>
                    Sí, puedes enviar mensajes directos al profesional a través de la plataforma en el apartado de reservas.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Heading>
                    ¿Qué pasa si tengo problemas con la plataforma?
                  </Heading>
                  <Paragraph>
                    {'Escríbenos a '}
                    <NavLink
                      to="mailto:contacto@lamenteesmaravillosa.com"
                      className="secondary-color"
                    >
                      contacto@lamenteesmaravillosa.com
                    </NavLink>
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Heading>¿Es seguro el sistema de pago?</Heading>
                  <Paragraph>
                    Nuestro sistema de pago es 100% confiable y funciona sobre un servidor seguro utilizando el protocolo SSL (Secure Socket Layer). La información facilitada es cifrada con algoritmos de 128 bits, permitiendo que sea solo inteligible para el ordenador del usuario y el sitio web.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Heading>
                    ¿Un menor de edad puede reservar una sesión?
                  </Heading>
                  <Paragraph>
                    Desafortunadamente no. La normativa vigente y nuestra política de privacidad establecen que si eres menor de edad es necesaria la autorización de tus padres o tutor legal. Te recomendamos que solicites ayuda a un adulto de confianza.
                  </Paragraph>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
      </Box>
    </PageWrapper>
  </React.Fragment>
);

export default ConsultationQuizLanding;
