import React from 'react';

import {
  PageWrapper,
  Container,
  Breadcrumbs,
  Row,
  Col,
  Box,
  Icon,
  Heading,
  Button,
  InputField,
  InputSelect,
  NavLink
} from 'base-ui';

// Components
import ResultProfessional from '../../../components/Consultation/ResultProfessional';

// Data
const resultsProfessionals = [
  {
    id: 1,
    thumb: './img/dummy/person-1.png',
    professionalArea: 'Psicólogo',
    price: '75€',
    type: 'Consulta',
    professionalName: 'Julio Americo',
  },
  {
    id: 2,
    thumb: './img/dummy/person-2.png',
    professionalArea: 'Psicólogo',
    price: '80€',
    type: 'Consulta',
    professionalName: 'Bella Etels',
  },
  {
    id: 3,
    thumb: './img/dummy/person-3.png',
    professionalArea: 'Psicólogo',
    price: '70€',
    type: 'Consulta',
    professionalName: 'Otto Travis',
  },
  {
    id: 4,
    thumb: './img/dummy/person-4.png',
    professionalArea: 'Psicólogo',
    price: '65€',
    type: 'Consulta',
    professionalName: 'Pablo Americo',
  },
  {
    id: 5,
    thumb: './img/dummy/person-5.png',
    professionalArea: 'Psicólogo',
    price: '75€',
    type: 'Consulta',
    professionalName: 'Julia Americo',
  }
];


const ArchiveProfessionals = () => (
  <PageWrapper config={{ title: 'Consultation Archive Professionals', bodyClasses: 'consultation archive-professionals' }}>

    <Box tag="main" id="site-main">

      <Box tag="section">
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Breadcrumbs>
                <NavLink href="/consultation">
                  <Icon prefix="fas" name="home" className="main-color" />
                  {' '}
                  Inicio
                </NavLink>
                <Box tag="span">profesionales</Box>
              </Breadcrumbs>
            </Col>
          </Row>
        </Container>
      </Box>

      <Box id="recent-consultation">
        <Container fluid wrap>
          <Row>
            <Col xs="12">
              <Heading type="h1" className="margin-top-big label main-font titles-color font-x-big">
                Todos los profesionales
              </Heading>
              <Row className="between-xs margin-bottom-big">
                <Col xs="6" sm="3">
                  <InputSelect
                    name="select_filter"
                    className="without-shadow"
                    options={[
                      { value: '', label: 'Filtrar por categoria' },
                    ]}
                  />
                </Col>
                <Col xs="6" sm="3">
                  <InputSelect
                    name="select_order"
                    className="without-shadow"
                    options={[
                      { value: '', label: 'Ordenar por' },
                    ]}
                  />
                </Col>
                <Col xs="12" sm="6">
                  <Row>
                    <Col xs="8">
                      <Box className="field-with-icon">
                        <Icon prefix="fas" name="search icon" />
                        <InputField
                          type="text"
                          name="input_search"
                          className="input without-shadow full"
                          placeholder="Buscar por nombre..."
                        />
                      </Box>
                    </Col>
                    <Col xs="4">
                      <Button className="btn main-bg white-border full white-color">Buscar</Button>
                    </Col>
                  </Row>
                </Col>
                <hr />
              </Row>
              <Box className="consultations">
                { resultsProfessionals.map((result) => (
                  <ResultProfessional
                    key={result.id}
                    data={{
                      thumb: result.thumb,
                      professionalArea: result.professionalArea,
                      price: result.price,
                      type: result.type,
                      professionalName: result.professionalName,
                    }}
                  />
                ))}
              </Box>
              <hr />
              <Box className="align-center display-block">
                <NavLink to="#" className="btn big main-bg main-border white-color">Cargar más</NavLink>
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default ArchiveProfessionals;
