import React from 'react';

import {
  Box,
  Row,
  Col,
  Button,
  Icon,
  Paragraph
} from 'base-ui';

import AccordionCat from '../Courses/AccordionCat';

const CourseSubscriptionWidget = () => (
  <Box id="course-subscription-widget" className="hidden-lg margin-bottom-x-medium margin-top-x-medium">
    <Box className="section-title thin-border without-margin-bottom font-size-normal">
      <Box tag="span" className="label line-height-small without-margin text-uppercase titles-color">
        ¿Cómo me inscribo?
      </Box>
    </Box>
    <Box>
      <AccordionCat version="subscription-version">
        <Box
          title={(
            <Box>
              <Row className="middle-xs">
                <Col xs="8">
                  <Paragraph size="medium" className="titles-color without-margin">Suscripción premium</Paragraph>
                </Col>
                <Col xs="4" className="end-xs">
                  <Box tag="span" className="font-size-medium normal-font titles-color">
                    19.99€
                    <Box tag="small"> / mes</Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
          label="module-1"
          isOpen
        >
          <Paragraph size="small" className="titles-color without-margin-bottom">+30 cursos disponibles offline, revisión de tus tests y trabajos, además de 30% de descuento en nuestra tienda.</Paragraph>
          <Box className="cost-row">
            <Row className="middle-xs margin-top-small">
              <Col xs="9">
                <Button className="text-uppercase" color="orange" textColor="white" borderColor="orange" size="big" full>
                  Comprar ahora
                </Button>
              </Col>
              <Col xs="3">
                <Button color="white" textColor="secondary" borderColor="secondary" size="big" full className="center-xs">
                  <Icon prefix="fas" name="gift" />
                </Button>
              </Col>
            </Row>
          </Box>
        </Box>
        <Box
          title={(
            <Box>
              <Row className="middle-xs">
                <Col xs="8">
                  <Paragraph size="medium" className="titles-color without-margin">Suscripción básica</Paragraph>
                </Col>
                <Col xs="4" className="end-xs">
                  <Box tag="span" className="font-size-medium normal-font titles-color">
                    12.99€
                    <Box tag="small"> / mes</Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
          label="module-2"
        >
          <Paragraph size="small" className="titles-color without-margin-bottom">+30 cursos con vídeos, tests, tareas y ebooks con acceso en todos tus dispositivos.</Paragraph>
          <Box className="cost-row">
            <Row className="middle-xs margin-top-small">
              <Col xs="9">
                <Button className="text-uppercase" color="orange" textColor="white" borderColor="orange" size="big" full>
                  Comprar ahora
                </Button>
              </Col>
              <Col xs="3">
                <Button color="white" textColor="secondary" borderColor="secondary" size="big" full className="center-xs">
                  <Icon prefix="fas" name="gift" />
                </Button>
              </Col>
            </Row>
          </Box>
        </Box>
        <Box
          title={(
            <Box>
              <Row className="middle-xs">
                <Col xs="8">
                  <Paragraph size="medium" className="titles-color without-margin">Un curso</Paragraph>
                </Col>
                <Col xs="4" className="end-xs">
                  <Box tag="span" className="font-size-medium normal-font titles-color">
                    69.99€
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
          label="module-3"
        >
          <Paragraph size="small" className="titles-color without-margin-bottom">Un sólo curso con acceso completo al material.</Paragraph>
          <Box className="cost-row">
            <Row className="middle-xs margin-top-small">
              <Col xs="9">
                <Button className="text-uppercase" color="orange" textColor="white" borderColor="orange" size="big" full>
                  Comprar ahora
                </Button>
              </Col>
              <Col xs="3">
                <Button color="white" textColor="secondary" borderColor="secondary" size="big" full className="center-xs">
                  <Icon prefix="fas" name="gift" />
                </Button>
              </Col>
            </Row>
          </Box>
        </Box>
      </AccordionCat>
    </Box>
  </Box>
);

export default CourseSubscriptionWidget;
