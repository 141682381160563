import React from 'react';

import {
  Container,
  Row,
  Col,
  Box,
  Heading,
  Button,
  PageWrapper,
} from 'base-ui';

import CourseCard from '../../../components/Courses/CourseCard';

const CoursesSubscriptionThanks = () => (
  <PageWrapper config={{ title: 'Courses subscription thanks' }}>
    <Box>
      <Container fluid wrap>
        <Row className="center-xs align-center margin-top-x-big">
          <Col xs="12">
            <Heading type="h1" size="x-medium" textColor="main" className="margin-bottom-x-medium">
              ¡Enhorabuena! Tu suscripción al plan básico ha sido realizada con éxito.
            </Heading>
          </Col>
        </Row>
        <Row className="thank-you wow bounceInUp center-xs align-center" data-wow-delay="0.3s">
          <Col className="thank-you-card one" xs="12" sm="8" md="4">
            <CourseCard
              courseImage="./img/dummy/post-10.png"
              courseTitle="Mindfulness para la vida cotidiana"
            />
          </Col>
          <Col className="thank-you-card two" xs="12" sm="8" md="4">
            <CourseCard
              courseImage="./img/dummy/post-11.png"
              courseTitle="Como conquistar a una mujer"
            />
          </Col>
          <Col className="thank-you-card three" xs="12" sm="8" md="4">
            <CourseCard
              courseImage="./img/dummy/post-12.png"
              courseTitle="Como hablar en publico, oratoria moderna"
            />
          </Col>
        </Row>
        <Row className="wow fadeIn center-xs align-center margin-bottom-x-big" data-wow-delay="0.6s">
          <Col xs="12" className="center-xs margin-top-medium">
            <Button color="main" borderColor="main" textColor="white" size="big">
              Empieza ya un curso
            </Button>
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default CoursesSubscriptionThanks;
