import React from 'react';

import {
  Container,
  Row,
  Col,
  Box,
  Hero,
  Card,
  Heading,
  Paragraph,
  Icon,
  InputField,
  Button,
  PageWrapper
} from 'base-ui';

const CourseCoupon = () => (
  <PageWrapper config={{ title: 'Course coupon' }}>
    <Box>
      <Hero BgImage="/img/coupon-hero.png">
        <Row className="middle-xs center-xs">
          <Col xs="12" sm="8" md="6">
            <Card
              className="alpha-bg wow bounceIn without-margin without-shadow center-xs align-center"
              data-wow-delay="0.3s"
            >
              <Heading type="h1" size="x-medium" textColor="main" className="without-margin-top">
                ¿Tienes un cupón?
              </Heading>
              <InputField
                placeholder="Anadir código"
                big
                full
              />
              <Button color="main" borderColor="main" textColor="white" size="big" full>
                Comienza ahora
              </Button>
            </Card>
          </Col>
        </Row>
      </Hero>
      <Container fluid wrap>
        <Row className="center-xs between-md padding-top-x-big padding-bottom-x-big">
          <Col
            xs="9"
            sm="4"
            md="3"
            className="wow fadeInUp center-xs align-center margin-bottom-medium"
            data-wow-delay="0.3s"
          >
            <Icon prefix="fas" name="globe" color="secondary" size="big" className="margin-bottom-small" />
            <Heading type="h3" size="x-medium" textColor="secondary" className="margin-bottom-small">
              100% Online
            </Heading>
            <Paragraph>Cursos con acceso 24 horas,los cursos empiezan y acaban cuando tú quieras.</Paragraph>
          </Col>
          <Col
            xs="9"
            sm="4"
            md="3"
            className="wow fadeInUp center-xs align-center margin-bottom-medium"
            data-wow-delay="0.6s"
          >
            <Icon prefix="fas" name="laptop" color="orange" size="big" className="margin-bottom-small" />
            <Heading type="h3" size="x-medium" textColor="orange" className="margin-bottom-small">
              Donde quieras
            </Heading>
            <Paragraph>Puedes realizar los cursos desde cualquier dispositivo con acceso a internet.</Paragraph>
          </Col>
          <Col
            xs="9"
            sm="4"
            md="3"
            className="wow fadeInUp center-xs align-center margin-bottom-medium"
            data-wow-delay="0.9s"
          >
            <Icon prefix="fas" name="graduation-cap" color="blue" size="big" className="margin-bottom-small" />
            <Heading type="h3" size="x-medium" textColor="blue" className="margin-bottom-small">Certificado</Heading>
            <Paragraph>Completa el curso y recibe un certificado en tu correo electrónico.</Paragraph>
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default CourseCoupon;
