import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Image,
  Box,
  InputField,
  Row,
  Container,
  InputSelect,
} from 'base-ui';

class UnycosProfileEdit extends Component {
  state = {
    profile: {
      name: '',
      nick: '',
      gender: '',
      birthday: '',
      email: '',
      username: '',
      numbers: [''],
      currency: '',
      location: '',
      bio: '',
      academy: '',
      role: '',
    }
  }

  handleProfileForm(value, key) {
    const { profile } = this.state;

    profile[key] = value;

    this.setState({
      profile
    });
  }

  render() {
    const {
      profile,
    } = this.state;

    return (
      <section id="profile-edit" className="fade-exit-active">
        <Container fluid>
          <Row>
            <Col xs="12" className="center-xs">
              <Box className="profile-avatar">
                <Image src="/assets/images/profile.png" alt="Avatar Thumb"></Image>
                <Link to="#" onClick={() => {}}>Editar</Link>
              </Box>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <InputField
                id="profile-name"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="name-to"
                type="text"
                label="Nombre:"
                placeholder="Nombre"
                required={false}
                value={profile.name}
                onChange={(value) => this.handleProfileForm(value, 'name')}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputField
                id="profile-nick"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="nick"
                type="text"
                label="Apellidos:"
                placeholder="Apellidos"
                required={false}
                value={profile.nick}
                onChange={(value) => this.handleProfileForm(value, 'nick')}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputSelect
                id="profile-gender"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="gender"
                type="text"
                label="Sexo:"
                placeholder="Sexo"
                required={false}
                value={profile.gender}
                options={[
                  { label: 'Mujer', value: 'female' },
                  { label: 'Hombre', value: 'male' }
                ]}
                onChange={(value) => this.handleProfileForm(value, 'gender')}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputField
                id="profile-birthday"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="birthday"
                type="date"
                label="Fecha de nacimiento:"
                placeholder="Fecha de nacimiento"
                required={false}
                value={profile.birthday}
                onChange={(value) => this.handleProfileForm(value, 'birthday')}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputField
                id="profile-email"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="birthday"
                type="email"
                label="Correo electrónico:"
                placeholder="Correo electrónico"
                required={false}
                value={profile.email}
                onChange={(value) => this.handleProfileForm(value, 'email')}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputSelect
                id="profile-currency"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="currency"
                type="text"
                label="Divisa preferida:"
                placeholder="Moneda"
                required={false}
                value={profile.currency}
                options={[
                  { label: 'EUR', value: 'eur' },
                  { label: 'USD', value: 'usd' },
                  { label: 'BRL', value: 'BRL' }
                ]}
                onChange={(value) => this.handleProfileForm(value, 'currency')}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputField
                id="profile-location"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="location"
                type="text"
                label="Dónde vives:"
                placeholder="Madrid, España"
                required={false}
                value={profile.location}
                onChange={(value) => this.handleProfileForm(value, 'location')}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputField
                id="profile-academy"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="academy"
                type="text"
                label="Nivel de estudio:"
                placeholder="Graduado, Mestre, Doctor"
                required={false}
                value={profile.academy}
                onChange={(value) => this.handleProfileForm(value, 'academy')}
              />
            </Col>
            <Col xs="12" sm="12">
              <InputField
                id="profile-bio"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="bio"
                type="textarea"
                label="Acerca de ti:"
                placeholder="Bio"
                required={false}
                value={profile.bio}
                onChange={(value) => this.handleProfileForm(value, 'bio')}
              />
            </Col>
          </Row>
          <Row>
            <Col className="align-right">
              <Button onClick={() => window.open('')} className="orange square hover-effect">Guardar</Button>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default UnycosProfileEdit;
