/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import omit from 'object.omit';

import './style.css';

/**
 * Simple Loading Spinner.
 * Based of: https://loading.io/css/
 */
export default class Loading extends React.PureComponent {
  handleCreateStyle = (justify, type, backgroundColor, vAlign) => {
    const styleClass = {};
    if (type === 'gradient') {
      styleClass.width = '100%';
      styleClass.height = '100%';
      styleClass.background = backgroundColor;
    }
    switch (justify) {
      case 'right': {
        styleClass.marginLeft = '90%';
        break;
      }
      case 'center': {
        styleClass.marginLeft = '48%';
        break;
      }
      case 'left': {
        styleClass.marginLeft = '0%';
        break;
      }
      default:
        break;
    }
    if (vAlign) {
      styleClass.display = 'flex';
      styleClass.height = '100%';
      switch (vAlign) {
        case 'center': {
          styleClass.alignItems = 'center';
          break;
        }
        case 'bottom': {
          styleClass.alignItems = 'flex-end';
          break;
        }
        default:
          styleClass.alignItems = 'flex-start';
          break;
      }
    }
    return styleClass;
  };

  handleCreateType = (type) => {
    let styleType;
    switch (type) {
      case 'bars': {
        styleType = 'lds-facebook';
        break;
      }
      case 'circle': {
        styleType = 'lds-ring';
        break;
      }
      case 'pulse': {
        styleType = 'lds-pulse';
        break;
      }
      case 'gradient': {
        styleType = 'lds-gradient';
        break;
      }
      default:
        break;
    }
    return styleType;
  };


  render() {
    const {
      justify, type, scale, backgroundColor, className, vAlign
    } = this.props;
    const propsBlacklist = [
      'vAlign',
      'justify',
      'type',
      'scale'
    ];
    const allowedProps = omit(this.props, propsBlacklist);
    return (
      <div
        className={`loading ${className}`}
        style={this.handleCreateStyle(justify, type, backgroundColor, vAlign)}
        {...allowedProps}
      >
        <div
          className={this.handleCreateType(type)}
          style={{ fontSize: `${scale}px` }}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

    );
  }
}

Loading.defaultProps = {
  justify: 'left',
  type: 'bars',
  scale: '8px',
  backgroundColor: '#ccc',
  className: '',
  vAlign: 'top'
};

Loading.propTypes = {
  /**
   * Defines the justify from component: left, center or right.
   */
  justify: PropTypes.string,
  /**
   * The type of Loading.
   */
  type: PropTypes.oneOf(['bars', 'circle', 'pulse', 'gradient']),
  /**
   * Background color to gradient
   */
  backgroundColor: PropTypes.string,
  /**
   * Scale size
   */
  scale: PropTypes.string,
  /**
   * Config vertical align, top, center, bottom
   */
  vAlign: PropTypes.oneOf(['top', 'center', 'bottom']),

  /**
   * Add classes if needed
   */
  className: PropTypes.string,
};
