import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import {
  Box, InputField
} from 'base-ui';

// Styles
import './style.scss';

const QuizAnsware = ({ data }) => {
  const {
    questionId, id, text, answare, click, canAnsware
  } = data;

  const [questionAnsware, setQuestionAnsware] = useState({ active: false, correct: null });

  const handleClick = (val) => {
    click(true);
    let status;
    if (answare === val) {
      status = true;
    } else {
      status = false;
    }
    setQuestionAnsware((prevState) => (
      {
        ...prevState,
        active: true,
        correct: status,
      }
    ));
  };

  return (
    <Box
      className={cs('answare-options',
        {
          'is-active': questionAnsware.active,
          'is-correct': questionAnsware.correct,
          'is-wrong': !questionAnsware.correct && questionAnsware.correct !== null
        }
      )}
    >
      <InputField
        label={text}
        name={`radio_answare_question_${questionId}`}
        id={`rd_question-${questionId}_answare-${id}`}
        type="radio"
        onChange={() => handleClick(id)}
        disabled={!canAnsware}
      />
    </Box>
  );
};

QuizAnsware.propTypes = {
  data: PropTypes.shape({
    questionId: PropTypes.number,
    id: PropTypes.number,
    text: PropTypes.string,
    answare: PropTypes.number,
    click: PropTypes.func,
    canAnsware: PropTypes.bool,
  }),
};

export default QuizAnsware;
