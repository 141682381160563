import React from 'react';

import {
  Box,
  Lists,
  Icon,
  Heading
} from 'base-ui';

import ReadMore from './ReadMore';

const YoullLearnMobile = () => (
  <Box className="hidden-lg margin-bottom-medium margin-top-normal">
    <Heading type="h3" size="normal" textColor="titles" className="text-uppercase margin-bottom-medium">En este curso aprenderas:</Heading>
    <Lists hasIcons className="to-learn-list">
      <ReadMore
        maxHeightvalue="180px"
        excerpt={(
          <Box>
            <li className="item">
              <Icon className="titles-color" prefix="fas" name="users" />
              <Box tag="span" className="titles-color">Comprender cómo son y cómo piensan los demás</Box>
            </li>
            <li className="item">
              <Icon className="titles-color" prefix="fas" name="fire" />
              <Box tag="span" className="titles-color">Saber por dónde empezar a mejorar tus capacidades</Box>
            </li>
            <li className="item">
              <Icon className="titles-color" prefix="fas" name="heart" />
              <Box tag="span" className="titles-color">Aumentar enormemente la confianza en ti mismo</Box>
            </li>
          </Box>
        )}
        fullText={(
          <Box>
            <li className="item">
              <Icon className="titles-color" prefix="fas" name="star" />
              <Box tag="span" className="titles-color">Descubrir tus mejores talentos y virtudes</Box>
            </li>
            <li className="item">
              <Icon className="titles-color" prefix="fas" name="hand-point-right" />
              <Box tag="span" className="titles-color">Mejorar tu inteligencia emocional y social</Box>
            </li>
            <li className="item">
              <Icon className="titles-color" prefix="fas" name="check" />
              <Box tag="span" className="titles-color">Mejorar tu autocontrol y gestión de tus emociones</Box>
            </li>
          </Box>
        )}
      />
    </Lists>
  </Box>
);

export default YoullLearnMobile;
