import React, { useState } from 'react';

// Base-UI Components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Paragraph,
  InputField,
  InputFile,
  Label,
  Image,
  Button,
  NavLink
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogHero from '../../components/Blog/BlogHero';
import BlogFooterOffices from '../../components/Blog/BlogFooterOffices';

const pageConfig = {
  product: 'Mejor con Salud',
  title: 'Únete a nosotros',
  meta: [
    { name: 'description', content: 'El trabajo en equipo es clave para nuestra empresa. ¿Estás pensando en hacer tu carrera profesional enel mundo digital?' }
  ]
};

const BlogJoinUs = () => {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [presentation, setPresentation] = useState('');
  const [cv, setCV] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert(`
      Nombre: ${name},
      Asunto: ${subject},
      Email: ${email},
      Presentación: ${presentation},
    `);
  };

  return (
    <React.Fragment>
      <BlogMenu />
      <PageWrapper config={pageConfig}>
        <Box className="page-template-join-us">
          <Box id="join-us-hero">
            <BlogHero
              title={pageConfig.title}
              subtitle={pageConfig.meta[0].content}
              backgroundImage="./img/joinus-hero.jpg"
              className="hero"
            />
          </Box>
          <Box className="full-width-bg small main-bg" style={{ paddingTop: '15px', paddingBottom: '15px' }}>
            <Container fluid wrap>
              <Row className="center-xs">
                <Col xs="12" md="9" className="align-center">
                  <Paragraph textColor="white">
                    Estamos interesados en personas expertas en el sector sanitario o perfiles profesionales analíticos, creativos y con capacidad de
                    <br className="hidden-md" />
                    gestionar equipos de trabajo.
                  </Paragraph>
                </Col>
              </Row>
            </Container>
          </Box>
          <Box className="special-form">
            <Container fluid wrap>
              <Row className="center-xs">
                <Col xs="12" md="8">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <Box>
                      <Image src="./img/user-icon.svg" alt="User Icon" className="icon" />
                      <InputField
                        name="name"
                        value={name}
                        required
                        type="text"
                        full
                        big
                        placeholder="Nombre"
                        onChange={(v) => setName(v)}
                      />
                    </Box>
                    <Box>
                      <Image src="./img/briefcase-icon.svg" alt="Briecase Icon" className="icon briefcase" />
                      <InputField
                        name="name"
                        value={subject}
                        required
                        type="text"
                        full
                        big
                        placeholder="Asunto"
                        onChange={(v) => setSubject(v)}
                      />
                    </Box>
                    <Box>
                      <Image src="./img/icon-envelope.svg" alt="Email Icon" className="icon envelope" />
                      <InputField
                        name="name"
                        value={email}
                        required
                        type="email"
                        full
                        big
                        placeholder="Email"
                        onChange={(v) => setEmail(v)}
                      />
                    </Box>
                    <Box>
                      <Image src="./img/icon-cv.svg" alt="CV Icon" className="icon" />
                      <InputField
                        name="name"
                        value={presentation}
                        required
                        type="textarea"
                        full
                        big
                        placeholder="Carta de Presentación"
                        onChange={(v) => setPresentation(v)}
                      />
                    </Box>
                    <br />
                    <Box className="row between-xs">
                      <Col xs="12" sm="8" className="center-xs start-sm">
                        <Col>
                          <InputFile
                            name="cv"
                            icon="paperclip"
                            button={{
                              color: 'regent-grey',
                              textColor: 'text',
                              borderColor: 'regent-grey',
                              size: 'big',
                            }}
                            required
                            placeholder="Adjunta tu Cv"
                            onChange={(file) => setCV(file)}
                            value={cv}
                          />
                        </Col>
                      </Col>
                      <Col xs="12" sm="4" className="center-xs end-sm">
                        <Button
                          type="submit"
                          tag="button"
                          color="main"
                          textColor="white"
                          borderColor="main"
                          size="big"
                        >
                          Enviar
                        </Button>
                      </Col>
                    </Box>
                    <Box>
                      <br />
                      <InputField
                        id="c1"
                        type="checkbox"
                        required
                      />
                      <Label inputId="c1">
                        He leído y acepto la
                        {' '}
                        <NavLink to="/page">política de privacidad</NavLink>
                      </Label>
                    </Box>
                  </form>
                </Col>
              </Row>
            </Container>
          </Box>
          <BlogFooterOffices />
        </Box>
      </PageWrapper>
    </React.Fragment>
  );
};

export default BlogJoinUs;
