import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Box
} from 'base-ui';

class ReadMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  showButton = () => {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { expanded } = this.state;
    const { excerpt, fullText } = this.props;
    const { maxHeightvalue } = this.props;
    return (
      <Box className={`read-more-content ${expanded ? 'expanded' : 'no-expanded'}`}>
        <Box className="read-more-excerpt" style={{ maxHeight: maxHeightvalue }}>{excerpt}</Box>
        {
          expanded
          && <Box>{fullText}</Box>
        }
        <Box className={`read-more-btn ${expanded ? 'expanded' : 'no-expanded'}`}>
          <Button onClick={this.showButton} color="white" className="rounded">
            { expanded
              ? <Box tag="span">Leer menos</Box>
              : <Box tag="span">Leer más</Box>
            }
          </Button>
        </Box>
      </Box>
    );
  }
}

ReadMore.propTypes = {
  excerpt: PropTypes.string,
  fullText: PropTypes.string,
  maxHeightvalue: PropTypes.any,
};

export default ReadMore;
