import React from 'react';

import {
  PageError,
} from 'base-ui';

const CoursesError = () => (
  <PageError
    pageConfig={{ title: 'Courses Error Page' }}
    pageClasses="align-center padding-top-big padding-bottom-x-big"
    title="¡Ops! Error 500"
    subTitle="Algo ha salido mal, disculpa las molestias."
    textOne="Vuelve a intentarlo en unos instantes"
    textConjunction="o"
    textTwo="Continúa navegando usando el menú"
    button={{
      bgColor: 'secondary',
      borderColor: 'secondary',
      textColor: 'white',
      label: 'Actualizar pantalla'
    }}
    imageColor="secondary"
  />
);

export default CoursesError;
