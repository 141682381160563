import React, { Component } from 'react';
import {
  Paragraph,
  Row,
  Col,
  Image,
} from 'base-ui';

class UnycosProfileComments extends Component {
  state = {}

  render() {
    return (
      <section id="profile-comments" className="profile-comments fade-exit-active">
        <span className="box-title">
          Evaluaciones escritas por ti
        </span>
        <div className="box-body">
          <Row className="middle-xs">
            <Col xs="12" md="2" className="center-xs">
              <Image className="profile-comments__avatar" src="/assets/images/profile.png" alt="Avatar Thumb"></Image>
            </Col>
            <Col xs="12" md="10">
              <Paragraph className="text-gold"><b>Test regalo, hace 4 días</b></Paragraph>
              <Paragraph className="text-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Paragraph>
            </Col>
          </Row>
          <Row className="middle-xs">
            <Col xs="12" md="2" className="center-xs">
              <Image className="profile-comments__avatar" src="/assets/images/profile.png" alt="Avatar Thumb"></Image>
            </Col>
            <Col xs="12" md="10">
              <Paragraph className="text-gold"><b>Test regalo, hace 4 días</b></Paragraph>
              <Paragraph className="text-gray">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Paragraph>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default UnycosProfileComments;
