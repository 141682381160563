import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  Row,
  Col,
  Box,
  NavLink,
  Image,
  Icon,
  Lists,
  Button
} from 'base-ui';

const MenuPublishProfessional = (props) => {
  const {
    toggleAction
  } = props;
  return (
    <React.Fragment>
      <Container fluid wrap className="header-publish-nav">
        <Row>
          <Col xs="12">
            <Button onClick={toggleAction} className="navicon btn main-border main-bg white-color">
              <Icon prefix="fas" name="bars" />
            </Button>
          </Col>
        </Row>
      </Container>
      <aside className="publish-sidebar">
        <Box className="logo">
          <NavLink to="publish">
            <Image src="./img/logo.svg" alt="Logo LMEM" />
          </NavLink>
        </Box>
        <Lists>
          <li className="title">
            <span>Información Esencial</span>
          </li>
          <li className="check">
            <NavLink to="professional">
              <Icon prefix="far" name="check-circle" />
              {' '}
              <span>Bienvenido</span>
            </NavLink>
          </li>
          <li className="check">
            <NavLink to="professional-2">
              <Icon prefix="far" name="check-circle" />
              {' '}
              <span>Descripción</span>
            </NavLink>
          </li>
          <li className="check">
            <NavLink to="professional-3">
              <Icon prefix="far" name="check-circle" />
              {' '}
              <span>Documentos</span>
            </NavLink>
          </li>
          <li className="check">
            <NavLink to="professional-4">
              <Icon prefix="far" name="check-circle" />
              {' '}
              <span>Disponibilidad</span>
            </NavLink>
            <Lists>
              <li>
                <NavLink to="professional-4">
                  <Icon prefix="far" name="check-circle" />
                  {' '}
                  <span>Online</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="professional-41">
                  <Icon prefix="far" name="check-circle" />
                  {' '}
                  <span>Presencial</span>
                </NavLink>
              </li>
            </Lists>
          </li>
          <li className="check">
            <NavLink to="professional-5">
              <Icon prefix="far" name="check-circle" />
              {' '}
              <span>Políticas</span>
            </NavLink>
          </li>
          <li className="current"><NavLink to="professional-6"><span>Preferencias de Cobro</span></NavLink></li>
          <li><NavLink to="professional-7" disabled><span>Finalizar</span></NavLink></li>
        </Lists>
      </aside>

      <Box className="overlay-menu-publish" onClick={toggleAction}></Box>
    </React.Fragment>
  );
};

MenuPublishProfessional.propTypes = {
  toggleAction: PropTypes.any
};

export default MenuPublishProfessional;
