import React, { Component } from 'react';
import loadable from '@loadable/component';

import {
  CTA,
  Button,
  Image,
  Icon,
  Hero,
  Container,
  Row,
  Col,
  Box,
  Tabs,
  Heading,
  Paragraph,
  PageWrapper,
  InputField,
  Slider
} from 'base-ui';

import MainFeaturesTwo from '../../components/Sections/MainFeaturesTwo';
import Loader from '../../components/AppFooter/Loader';

const RecentCoursesThree = loadable(() => import('../../components/Courses/RecentCoursesThree'));
const FeaturedCoursesThree = loadable(() => import('../../components/Courses/FeaturedCoursesThree'));

class CoursesHomeB1 extends Component {
  componentDidMount() {
    document.body.classList.toggle('courses-home-v2');
  }

  render() {
    return (
      <PageWrapper config={{ title: 'Courses home' }} className="page-b1">
        <Box>
          <Hero className="second-version" BgImage="/img/hero-desktop.png">
            <Box className="hero-mobile" style={{ backgroundImage: 'url(./img/hero-mobile.png)' }}></Box>
            <Row className="middle-xs center-xs start-md">
              <Col xs="11" sm="8" md="7" lg="6" className="center-xs start-md">
                <Heading type="h1" size="x-big" className="font-size-x-big hero-title wow fadeInUp center-xs start-md animated" data-wow-delay="0.1s">Cursos online para empezar a ser la persona que deseas</Heading>
                <Paragraph size="medium" className="font-size-medium  hero-subtitle wow fadeInUp margin-bottom-medium center-xs start-md animated" data-wow-delay="0.3s">Empieza a construir tu felicidad con nosotros</Paragraph>
                <Box className="inline-searcher">
                  <InputField
                    placeholder="¿Qué quieres aprender hoy?"
                    big
                    full
                    className="without-shadow"
                  />
                  <Button className="search-btn transparent-bg" type="button" tag="button" size="big"><Icon name="search" prefix="fas" /></Button>
                </Box>
              </Col>
            </Row>
          </Hero>

          <MainFeaturesTwo
            className="hidden-md"
          />

          <Container fluid wrap className="hidden-md">
            <Row>
              <Col xs="12" className="rounded-slick-nav">
                <Slider slidesToShow={1} slidesToScroll={1}>
                  <Box>
                    <RecentCoursesThree />
                  </Box>
                  <Box>
                    <FeaturedCoursesThree />
                  </Box>
                  <Box>
                    <RecentCoursesThree />
                  </Box>
                </Slider>
              </Col>
            </Row>
          </Container>

          <Container fluid wrap className="hidden-md">
            <Row>
              <Col xs="12" className="rounded-slick-nav">
                <Slider slidesToShow={1} slidesToScroll={1}>
                  <Box>
                    <RecentCoursesThree />
                  </Box>
                  <Box>
                    <FeaturedCoursesThree />
                  </Box>
                  <Box>
                    <RecentCoursesThree />
                  </Box>
                </Slider>
              </Col>
            </Row>
          </Container>

          <Container fluid wrap className="hidden-lg">
            <Row>
              <Col xs="12">
                <Tabs size="medium" align="center">
                  <Box label="Desarrollo de aptitudes">
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <FeaturedCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <RecentCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                  </Box>
                  <Box label="Educación">
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <RecentCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <FeaturedCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                  </Box>
                  <Box label="Autoconocimiento">
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <FeaturedCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <RecentCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                  </Box>
                  <Box label="Salud y bien estar">
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <RecentCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <FeaturedCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                  </Box>
                  <Box label="Coaching">
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <FeaturedCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <RecentCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                  </Box>
                  <Box label="Neurociencias">
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <RecentCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                    <Box className="horizontal-scroll-negative-md padding-bottom-normal">
                      <Box className="horizontal-scroll-md">
                        <FeaturedCoursesThree fallback={<Loader />} />
                      </Box>
                    </Box>
                  </Box>
                </Tabs>
              </Col>
            </Row>
          </Container>

          <Container fluid wrap className="wow fadeIn margin-bottom-medium margin-top-normal">
            <Row>
              <Col xs="12">
                <Box className="section-title thin-border">
                  <Box tag="div" className="font-size-medium main line-height-small"><Box tag="span" className="label font-small without-margin text-uppercase">Categorías</Box></Box>
                </Box>
              </Col>
              <Col xs="12">
                <Row className="categories-menu">
                  <Col xs="6" md="4" lg="4">
                    <Button full borderColor="regent-grey">
                      <Image className="icon" src="./img/categories-menu/desarrollo-icon.svg" alt="Autoconocimiento" />
                      <Box tag="span" className="label">Autoconocimiento</Box>
                    </Button>
                  </Col>
                  <Col xs="6" md="4" lg="4">
                    <Button full borderColor="regent-grey">
                      <Image className="icon" src="./img/categories-menu/desarrollo-icon.svg" alt="Desarrollo de Aptitudes" />
                      <Box tag="span" className="label">
                        Desarrollo de
                        {' '}
                        <br />
                        Aptitudes
                      </Box>
                    </Button>
                  </Col>
                  <Col xs="6" md="4" lg="4">
                    <Button full borderColor="regent-grey">
                      <Image className="icon" src="./img/categories-menu/salud-icon.svg" alt="Salud y bienestar" />
                      <Box tag="span" className="label">Salud y bienestar</Box>
                    </Button>
                  </Col>
                  <Col xs="6" md="4" lg="4">
                    <Button full borderColor="regent-grey">
                      <Image className="icon" src="./img/categories-menu/relaciones-icon.svg" alt="Relaciones" />
                      <Box tag="span" className="label">Relaciones</Box>
                    </Button>
                  </Col>
                  <Col xs="6" md="4" lg="4">
                    <Button full borderColor="regent-grey">
                      <Image className="icon" src="./img/categories-menu/coaching-icon.svg" alt="Coaching" />
                      <Box tag="span" className="label">Coaching</Box>
                    </Button>
                  </Col>
                  <Col xs="6" md="4" lg="4">
                    <Button full borderColor="regent-grey">
                      <Image className="icon" src="./img/categories-menu/neurociencias-icon.svg" alt="Neurociencias" />
                      <Box tag="span" className="label">Neurociencias</Box>
                    </Button>
                  </Col>
                  <Col xs="6" md="4" lg="4">
                    <Button full borderColor="regent-grey">
                      <Image className="icon" src="./img/categories-menu/educacion-icon.svg" alt="Educación" />
                      <Box tag="span" className="label">Educación</Box>
                    </Button>
                  </Col>
                  <Col xs="6" md="4" lg="4">
                    <Button full borderColor="regent-grey">
                      <Image className="icon" src="./img/categories-menu/meditacion-icon.svg" alt="Meditación" />
                      <Box tag="span" className="label">Meditación</Box>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>

          <Container fluid wrap className="wow fadeIn">
            <CTA className="small rounded-small" BgImage="/img/call-action.png">
              <Col xs="12">
                <Row className="middle-xs center-xs between-md">
                  <Col xs="10" md="8" className="start-md">
                    <Heading type="h3" className="cta-title center-xs start-md font-size-medium main-font white-color">¡Accede a todos los cursos desde 9.90€ / mes!</Heading>
                  </Col>
                  <Col xs="10" md="4">
                    <Button size="big" full textColor="white" borderColor="secondary" tag="a" color="secondary" className="cta-btn">
                      Suscribirse ahora
                    </Button>
                  </Col>
                </Row>
              </Col>
            </CTA>
          </Container>

          <Container fluid wrap className="wow fadeIn">
            <Row>
              <Col xs="12">
                <Box className="section-title thin-border">
                  <Box tag="div" className="font-size-medium main line-height-small"><Box tag="span" className="label font-small without-margin text-uppercase">Cursos más vendidos</Box></Box>
                </Box>
              </Col>
              <Col xs="12">
                <Box className="horizontal-scroll-negative-md">
                  <Box className="horizontal-scroll-md">
                    <RecentCoursesThree fallback={<Loader />} />
                  </Box>
                </Box>
              </Col>
            </Row>
          </Container>

          

          <Container fluid wrap className="wow fadeIn margin-top-medium">
            <Row className="center-xs">
              <Col xs="12">
                <Box className="section-title thin-border">
                  <Box tag="div" className="font-size-medium main line-height-small"><Box tag="span" className="label font-small without-margin text-uppercase">Cursos destacados</Box></Box>
                </Box>
              </Col>
              <Col xs="12">
                <Box className="horizontal-scroll-negative-md">
                  <Box className="horizontal-scroll-md">
                    <RecentCoursesThree fallback={<Loader />} />
                  </Box>
                </Box>
              </Col>
              <Col xs="9" sm="6" md="4" className="margin-top-x-medium margin-bottom-x-medium">
                <Button size="normal" color="secondary" textColor="white" borderColor="secondary" className="padding-normal white-color bt-see-all">Ver todos los cursos</Button>
              </Col>
            </Row>
          </Container>
        </Box>
      </PageWrapper>
    );
  }
}

export default CoursesHomeB1;
