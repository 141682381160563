import React from 'react';
import {
  Container,
  Box,
  Row,
  Col,
  Card,
  Heading,
  Button
} from 'base-ui';

const UnycosSingleLessonMobile = (props) => (
  <Box className={`lesson-plan-mob margin-top-normal margin-bottom-normal ${props.className}`}>
    <Container fluid wrap>
      <Row className="center-xs">
        <Col xs="12">
          <Heading type="h3" className="center-xs letter-spacing__normal normal-font text-uppercase white-color font-size-x-medium without-margin-bottom">Contenido exclusivo</Heading>
        </Col>
        <Col xs="12">
          <Card className="lesson-plan-card transparent-bg text-color__white--dark">
            <Row className="lesson-plan-module middle-xs">
              <Col xs="1" className="center-xs">
                <Box tag="span" className="font-size-medium number white-color">03</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="normal" className="text-color__white--dark normal-font">Natación: Aspectos Generales</Heading>
              </Col>
            </Row>
            <Row className="lesson-plan-module middle-xs">
              <Col xs="1" className="center-xs">
                <Box tag="span" className="font-size-medium number white-color">06</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="normal" className="text-color__white--dark normal-font">Bases biomecánicas de la natación</Heading>
              </Col>
            </Row>
            <Row className="lesson-plan-module middle-xs">
              <Col xs="1" className="center-xs">
                <Box tag="span" className="font-size-medium number white-color">08</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="normal" className="text-color__white--dark normal-font">Estilos de natación</Heading>
              </Col>
            </Row>
            <Row className="lesson-plan-module middle-xs">
              <Col xs="1" className="center-xs">
                <Box tag="span" className="font-size-medium number white-color">11</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="normal" className="text-color__white--dark normal-font">Virajes y saltos</Heading>
              </Col>
            </Row>
            <Box className="center-xs see-more-btn">
              <Button className="main-bg white-border transparent-bg btn--normal white-color text-uppercase with-letter-spacing normal-font font-size-small" full>Ver programa completo</Button>
            </Box>
          </Card>
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycosSingleLessonMobile;
