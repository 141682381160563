import React from 'react';
import {
  Container,
  Box,
  Row,
  Col,
  Heading,
  Button
} from 'base-ui';

const UnycosSingleFAQ = (props) => (
  <Box className={props.className}>
    <Container fluid wrap>
      <Row className="center-xs">
        <Col xs="12">
          <Heading type="h3" className="center-xs without-margin-top normal-font text-uppercase white-color font-size-medium margin-bottom-x-medium line-height__big">Aprende natación con una <br />campeona olímpica</Heading>
        </Col>
        <Col xs="12" md="9">
          <Box className="center-xs see-more-btn">
            <Button className="main-bg white-border transparent-bg btn--normal white-color text-uppercase with-letter-spacing normal-font font-size-small" full>Preguntas frecuentes</Button>
          </Box>
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycosSingleFAQ;
