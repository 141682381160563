import React from 'react';

import {
  Container,
  Row,
  Col,
  Box,
  NavLink
} from 'base-ui';

const MenuLogged = () => (
  <Box tag="nav" className="sub-nav medium main-bg inverse">
    <Container fluid wrap>
      <Row>
        <Col xs="12" className="align-center">
          <NavLink href="consultation/desktop" className="element">Escritorio</NavLink>
          <NavLink href="consultation/profile" className="element current">Perfil</NavLink>
          <NavLink href="consultation/messages" className="element">Mensajes</NavLink>
          <NavLink href="consultation/consults" className="element">Consultas</NavLink>
          <NavLink href="consultation/help" className="element">Ayuda</NavLink>
          <NavLink href="consultation/account" className="element">Cuenta</NavLink>
        </Col>
      </Row>
    </Container>
  </Box>
);


export default MenuLogged;
