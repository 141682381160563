import React, { Component } from 'react';

class BlogMostSearched extends Component {
	
	render() {
		return(
			<section id="most-searched-widget" className="widget">

        <div className="section-title main-border">
          <span className="label text-uppercase">Lo más buscado</span>
        </div>
        <div>
          <form className="search-form display-block position-relative">
            <input type="text" placeholder="Buscar artículos..." className="full input border-grey" />
            <button className="btn transparent-bg transparent-border text-color"><i className="fas fa-search"></i></button>
          </form>

          <div className="tag-cloud">
            <a href=".">Antivirales</a>
            <a href=".">Fiebre</a>
            <a href=".">Gripa</a>
            <a href=".">Bacterias</a>
            <a href=".">Bicarbonato de Sodio</a>
            <a href=".">Humedad</a>
            <a href=".">Antigripales</a>
            <a href=".">Bicarbonato</a>
            <a href=".">Memoria</a>
            <a href=".">Alhzeimer</a>
            <a href=".">Enfermedades neurodegenerativas</a>
            <a href=".">Memoria</a>
            <a href=".">Bacterias</a>
            <a href=".">Alzheimer</a>
            <a href=".">Gripa</a>
          </div>
        </div>

      </section>
		)
	}
}

export default BlogMostSearched;