import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Heading,
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosFooter from './components/UnycosFooter';
import UnycosContentCourse from './components/UnycosContentCourse';
import UnycosSingleSlides from './components/UnycosSingleSlides';

class UnycosHome extends React.PureComponent {
  render() {
    return (
      <PageWrapper className="list-courses" config={{ title: 'Unycos All Courses Page' }}>
        <UnycosLayout>
          <UnycosHeader statuslogged={false} />

          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Heading type="h1" className="title-page margin-bottom-x-big margin-top-x-medium font-size-x-big light-white-color normal-font text-uppercase">Todos Los Cursos</Heading>
              </Col>
              <Col xs="12" className="hidden-md">
                <UnycosSingleSlides />
              </Col>
              <Col xs="12" md="6" className="hidden-lg">
                <UnycosContentCourse image="./img/unycos/courses/mireia-big-slider.jpg" teacher="Mireia Belmonte" course="Natación" />
              </Col>
              <Col xs="12" md="6">
                <UnycosContentCourse image="./img/unycos/courses/marcus-big-slider.png" teacher="Marcus Cooper" course="piragüismo" />
              </Col>
              <Col xs="12" md="6">
                <UnycosContentCourse image="./img/unycos/courses/ismael-big-slider.jpg" teacher="ISMAEL CALA" course="DESARROLLO PERSONAL" />
              </Col>
              <Col xs="12" md="6">
                <UnycosContentCourse image="./img/unycos/courses/lewis-big-slider.jpg" teacher="LEWIS AMARANTE" course="MAQUILLAJE" />
              </Col>
              <Col xs="12" md="6">
                <UnycosContentCourse image="./img/unycos/courses/profesor-amos.jpg" teacher="Amós Lora" course="Guitarra" />
              </Col>
            </Row>
          </Container>

          <UnycosFooter showHideElements={{ social: true, cta: true }} />
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

export default UnycosHome;
