import React from 'react';
import {
  Container,
  Box,
  Row,
  Col,
  Card,
  Heading,
  Button,
  Paragraph
} from 'base-ui';

const UnycosSingleLesson = () => (
  <Box className="lesson-plan hidden-md margin-top-normal margin-bottom-normal">
    <Container fluid wrap>
      <Row className="center-xs">
        <Col xs="12">
          <Heading type="h3" className="center-xs letter-spacing__normal normal-font text-uppercase white-color font-size-x-medium margin-bottom-normal">Contenido Exclusivo</Heading>
        </Col>
        <Col xs="12" md="9">
          <Card className="lesson-plan-card">
            <Row className="lesson-plan-module">
              <Col xs="1" className="end-xs">
                <Box tag="span" className="font-size-big number">01</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="medium" className="text-uppercase letter-spacing__normal normal-font">Presentación</Heading>
                <Paragraph>Mireia, nuestra campeona, te da la bienvenida al curso que te llevará a la cima de la natación. Conocerás su técnica, su entrenamiento y muchas cosas más de la mano de un equipo de profesionales de fama internacional</Paragraph>
              </Col>
            </Row>
            <Row className="lesson-plan-module">
              <Col xs="1" className="end-xs">
                <Box tag="span" className="font-size-big number">02</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="medium" className="text-uppercase letter-spacing__normal normal-font">Natación: Aspectos Generales</Heading>
                <Paragraph>Mireia, nuestra campeona, te da la bienvenida al curso que te llevará a la cima de la natación. Conocerás su técnica, su entrenamiento y muchas cosas más de la mano de un equipo de profesionales de fama internacional</Paragraph>
              </Col>
            </Row>
            <Row className="lesson-plan-module">
              <Col xs="1" className="end-xs">
                <Box tag="span" className="font-size-big number">03</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="medium" className="text-uppercase letter-spacing__normal normal-font">Bases biomecánicas de la natación</Heading>
                <Paragraph>Mireia, nuestra campeona, te da la bienvenida al curso que te llevará a la cima de la natación. Conocerás su técnica, su entrenamiento y muchas cosas más de la mano de un equipo de profesionales de fama internacional</Paragraph>
              </Col>
            </Row>
            <Row className="lesson-plan-module">
              <Col xs="1" className="end-xs">
                <Box tag="span" className="font-size-big number">04</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="medium" className="text-uppercase letter-spacing__normal normal-font">Estilos de natación</Heading>
                <Paragraph>Mireia, nuestra campeona, te da la bienvenida al curso que te llevará a la cima de la natación. Conocerás su técnica, su entrenamiento y muchas cosas más de la mano de un equipo de profesionales de fama internacional</Paragraph>
              </Col>
            </Row>
            <Row className="lesson-plan-module">
              <Col xs="1" className="end-xs">
                <Box tag="span" className="font-size-big number">05</Box>
              </Col>
              <Col xs="11">
                <Heading type="h3" size="medium" className="text-uppercase letter-spacing__normal normal-font">Virajes y saltos</Heading>
                <Paragraph>Mireia, nuestra campeona, te da la bienvenida al curso que te llevará a la cima de la natación. Conocerás su técnica, su entrenamiento y muchas cosas más de la mano de un equipo de profesionales de fama internacional</Paragraph>
              </Col>
            </Row>
            <Box className="center-xs see-more-btn">
              <Button className="btn">Ver todos</Button>
            </Box>
          </Card>
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycosSingleLesson;
