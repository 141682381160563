import React, { useState } from 'react';

import {
  Breadcrumbs,
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  NavLink,
  Lists,
  Image,
  Icon,
  Message,
  Paragraph
} from 'base-ui';

// Components
import BlogMenu from '../../../components/Blog/BlogMenu';
import TopicsList from '../../../components/Blog/TopicsList';
import TextContent from '../../../components/Blog/TextContent';
import CardContentPost from '../../../components/Blog/CardPost';
import AccordionFaq from '../../../components/Blog/AccordionFaq';
import SliderFeaturedPosts from '../../../components/Blog/SliderFeaturedPosts';
import LastArticlesSidebar from '../../../components/Blog/LastArticlesSidebar';
import BoxBibliography from '../../../components/Blog/BoxBibliography';

// Data
import {
  wrapperConfig, contentMenu, lastArticles, featuredPost, faqQuestions, relatedDiseases, relatedMedicines
} from './ContentData';

const BlogDiseases = () => {
  const [openWarning, setOpenWarning] = useState(false);

  const handleClickWarning = () => {
    setOpenWarning(!openWarning);
  };

  return (
    <>
      <BlogMenu />
      <Container fluid wrap>
        <Row>
          <Col xs="12">
            <Breadcrumbs className="margin-top-medium">
              <NavLink href="#" aria-label="Inicio">Home</NavLink>
              <NavLink href="#" aria-label="Medicamentos">Medicamentos</NavLink>
              <NavLink href="#" aria-label="Cutáneas">Analgésicos</NavLink>
              <Box tag="span">Acetaminofén</Box>
            </Breadcrumbs>
          </Col>
        </Row>
      </Container>
      <PageWrapper config={wrapperConfig}>
        <Box tag="main" id="site-main">
          <Container fluid wrap>
            <Row>
              <Col xs="12" md="8">

                <TextContent data={contentMenu[0]} />

                <Message borderColor="red" textColor="white" className={`warning ${openWarning ? 'open' : ''}`} iconName="exclamation-triangle" iconPrefix="fas">
                  Tomar demasiado acetaminofén puede cuasar daño hepático, algunas veces tan grave que requiera un transplante de hígado u ocasione la muerte.
                  <NavLink onClick={handleClickWarning}>
                    <Icon prefix="fas" name={`chevron-${openWarning ? 'up' : 'down'}`} />
                    Mostrar más
                  </NavLink>
                </Message>

                <Box tag="section" className="section-about-medicine margin-bottom-medium margin-top-big">
                  <Row>
                    <Col sm="12" md="6">
                      <Paragraph>
                        <strong>Nombre genérico:</strong>
                        {' '}
                        <NavLink aria-label="Más sobre acetaminofén">acetaminofén</NavLink>
                      </Paragraph>
                      <Paragraph>
                        <strong>Nombres comerciales:</strong>
                        {' '}
                        Actamin, Anacin AF, Apra, Tylenol, Elilxure Tycolene, Tylenol, Vitapap.
                      </Paragraph>
                      <Paragraph>
                        <Icon prefix="fas" name="tablets" />
                        <strong>Tipo de medicamento:</strong>
                        {' '}
                        <NavLink aria-label="Más sobre analgésicos">analgésicos</NavLink>
                      </Paragraph>
                    </Col>
                    <Col sm="12" md="6">
                      <Paragraph>
                        <Icon prefix="fas" name="capsules" />
                        <strong>Vía(s) de administración:</strong>
                        {' '}
                        <NavLink aria-label="Más información sobre medicamentos orales">oral</NavLink>
                      </Paragraph>
                      <Paragraph>
                        <Icon prefix="fas" name="exclamation-circle" />
                        <strong>Alertas por composición:</strong>
                      </Paragraph>
                      <Lists>
                        <li>
                          <Icon prefix="fas" name="child" />
                          {' '}
                          <NavLink aria-label="">Embarazo</NavLink>
                        </li>
                        <li>
                          <Icon prefix="fas" name="prescription-bottle" />
                          {' '}
                          <NavLink aria-label="">Lactancia</NavLink>
                        </li>
                        <li>
                          <Icon prefix="fas" name="sun" />
                          {' '}
                          <NavLink aria-label="">Fotosensibilidad</NavLink>
                        </li>
                      </Lists>
                    </Col>
                  </Row>
                </Box>

                {contentMenu.slice(1, contentMenu.length).map((item) => (
                  <>
                    <TextContent key={item.id} data={contentMenu[item.id - 1]} />
                    {item.id === 2 && (
                      <CardContentPost
                        className="featured-post margin-top-medium"
                        post={{
                          url: featuredPost.url,
                          thumb: featuredPost.thumb,
                          title: featuredPost.title,
                          excerpt: featuredPost.excerpt
                        }}
                      />
                    )}
                  </>
                ))}

                <BoxBibliography
                  label="Bibliografía"
                  data={[
                    {
                      id: 1,
                      text: 'Muñoz Sandoval, M. y Poletti Vázquez, E. (2008). <em>Acné, dieta y debate: un veredicto pendiente.</em> Medicina Interna de MéxicoVol 24, Nº 5: 346-352. [En línea]. Disponible en:',
                      label: 'https://www.cmim.org/boletin/pdf2008/MedIntContenido05_07.pdf',
                      url: 'https://www.cmim.org/boletin/pdf2008/MedIntContenido05_07.pdf'
                    },
                    {
                      id: 2,
                      text: 'VV.AA. (2011). <em>Influencia de la dieta en el acné: revisión de la liteatura</em>.Rev. Chilena Dermatol. Vol. 27, Nº 1: 82-85. [En línea]. Disponible en:',
                      label: 'https://www.sochiderm.org/web/revista/27_1/9.pdf',
                      url: 'https://www.sochiderm.org/web/revista/27_1/9.pdf'
                    },
                  ]}
                />

                <AccordionFaq
                  title="Perguntas frecuentes sobre el acetaminofén"
                  icon="heartbeat"
                  content={faqQuestions}
                />

                <TopicsList
                  title="Medicamento relacionado con el tratamiento de:"
                  titleType="h3"
                  data={relatedDiseases}
                />

                <SliderFeaturedPosts
                  title="Otros medicamentos"
                  titleType="h3"
                  className="margin-top-big margin-bottom-big"
                  data={relatedMedicines}
                />

              </Col>
              <Col xs="12" md="4">

                <Box className="box-publicity align-center margin-top-medium margin-bottom-medium">
                  <span>Publicidad</span>
                  <Image src="https://static.criteo.net/design/dt/29088/200415/f9ffbf5c13d64470a3f41cd57dff10bc_cpn_336x280_1.png" alt="Publicidad" />
                </Box>

                <LastArticlesSidebar
                  title="Últimos artículos"
                  titleType="h3"
                  className="margin-top-medium margin-bottom-medium"
                  data={lastArticles}
                />

                <Box className="box-publicity align-center margin-top-medium margin-bottom-medium">
                  <span>Publicidad</span>
                  <Image src="https://s0.2mdn.net/simgad/7718551250627356464" alt="Publicidad" />
                </Box>

              </Col>

            </Row>
          </Container>
        </Box>
      </PageWrapper>
    </>
  );
};

export default BlogDiseases;
