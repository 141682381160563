import React, { useState } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Message,
  Button,
  Icon,
  Paragraph,
  NavLink,
} from 'base-ui';

// Components
import MenuPublishProfessional from '../../../components/Consultation/MenuPublishProfessional';

// current url: https://beta.lamenteesmaravillosa.com/consultas/consultas/publicar/preferencias-de-cobro/

const ProfessionalPart6 = () => {
  const [showMenuPublish, setShowMenuPublish] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);

  const handlerClickMenuPublish = () => {
    setShowMenuPublish(!showMenuPublish);
  };

  const handlerHover = (e) => {
    const element = e.currentTarget;
    const finalPositionLeft = element.offsetLeft + (element.offsetWidth / 2);
    const finalPositionTop = element.offsetTop + element.offsetHeight + 10;
    element.querySelector('div').style.top = `${finalPositionTop}px`;
    element.querySelector('div span').style.left = `${finalPositionLeft}px`;
    setShowToolTip(!showToolTip);
  };

  return (
    <PageWrapper config={{ title: 'Consultation Profile', bodyClasses: `consultation publish ${showMenuPublish ? 'open-aside' : ''}` }}>

      <Box tag="main" id="site-main">

        <MenuPublishProfessional atualPage="1" toggleAction={handlerClickMenuPublish} />

        <Box tag="section" id="publish-professional" className="consultation-single single-publish">
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Card>
                  <Box className="box-title grey-bg">
                    <Box tag="span" className="label main-font">Preferencias de cobro</Box>
                  </Box>
                  <Box>
                    <Paragraph className="tooltip-parent">
                      Generamos un informe mensual con los ingresos que has obtenido y emitimos una factura, realizamos la transferencia por el método de pago que elijas. Posteriormente, te enviamos el justificante y la factura de
                      {' '}
                      <NavLink
                        onMouseOver={(e) => handlerHover(e)}
                        onMouseOut={(e) => handlerHover(e)}
                        onFocus={(e) => handlerHover(e)}
                        onBlur={(e) => handlerHover(e)}
                        className="tooltip-child"
                      >
                        nuestras comisiones.
                        <Box className={`position-absolute tooltip ${showToolTip ? ' display-block' : ' display-none'}`}>
                          Se aplica un 6,5% de comisión en el total generado mensualmente en concepto de transferencia del pago a tu cuenta. En el caso de pacientes captados a través de nuestra plataforma, se cobrará el 100% de la primera consulta del paciente bajo concepto de uso de la plataforma. Si tienes pacientes en terapia presencial u otra plataforma online, te proporcionaremos un cupón con el cual NO se aplicará la comisión del 100% de la primera consulta.
                          <span></span>
                        </Box>
                      </NavLink>

                    </Paragraph>
                    <Message iconName="info-circle" color="blue" textColor="white" iconPrefix="fas">
                      Para completar tu proceso y recebir pagos, debes tener al meno un método de cobro registrado.
                    </Message>
                    <Row>
                      <Col xs="12">
                        <table className="full">
                          <tr>
                            <th>Método</th>
                            <th>Estado</th>
                            <th></th>
                          </tr>
                          <tr>
                            <th>
                              Transferencia bancaria
                              {' '}
                              <small className="badge grey-bg">Predeterminado</small>
                            </th>
                            <th><Box tag="span" className="green-color">Activo</Box></th>
                            <th>
                              <Button borderColor="red" color="white" textColor="red">
                                <Icon prefix="fas" name="trash" />
                              </Button>
                            </th>
                          </tr>
                        </table>
                      </Col>
                    </Row>
                    <br />
                    <Button to="#" borderColor="main" textColor="main" color="white">
                      <Icon prefix="fas" name="plus" />
                      {' '}
                      Añadir preferencia de cobro
                    </Button>
                  </Box>
                </Card>
              </Col>
            </Row>
          </Container>
        </Box>

      </Box>
      {/* end main section */}

    </PageWrapper>
  );
};

export default ProfessionalPart6;
