import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  AvatarForm
} from 'base-ui';

const AvatarDemo = () => (
  <PageWrapper config={{ title: 'Test Select Input' }}>
    <Container fluid wrap>
      <Box tag="main" id="site-main" style={{ marginBottom: '200px' }}>
        <Row className="center-xs">
          <Col xs="12" md="5">
            <Box>
              <AvatarForm
                url="https://placehold.it/400x400"
                onSubmit={() => alert('Submited! =)')}
                textButtonChangePhoto={'Change photo'}
                textButtonCancel={'Cancel'}
                textButtonConfirm={'Confirm'}
                labels={{
                  allowedExtensions: (ext = ['png', 'jpg', 'jpeg']) => (
                    `Solo imágenes to tipo: ${ext.join('/')}`
                  )
                }}
              />
            </Box>
          </Col>
        </Row>
      </Box>
    </Container>
  </PageWrapper>
);

export default AvatarDemo;
