import React from 'react';
import {
  Box,
  Container,
  Row,
  Col,
  Heading,
  Paragraph,
  Icon,
  Image,
  NavLink
} from 'base-ui';

const BlogWidgetPosts7 = () => (
  <Box tag="section" id="featured-posts-widget" className="widget posts-style-7">
    <Container fluid wrap>

      <Box className="section-title main-border">
        <NavLink to="#">
          <Heading type="h2" className="label text-uppercase titles-color font-normal-small">Remedios naturales</Heading>
          <Icon prefix="fas" name="chevron-right" />
        </NavLink>
      </Box>

      <Box className="posts-row">

        <Row>
          <Col xs="12" md="6">
            <Box tag="article" className="content-post news">
              <NavLink to="/single">
                <Box className="thumb">
                  <Box tag="span" className="favorite">
                    <Icon prefix="far" name="bookmark" />
                  </Box>
                  <Box className="thumb-wrap">
                    <Image alt="dummy" src="./img/dummy/post-14.png" className="thumb-bg" />
                  </Box>
                </Box>
              </NavLink>
              <Box className="caption">
                <Heading type="h3" className="title">
                  <NavLink to="/single">
                    9 things I wish I knew before playing Rainbow
                  </NavLink>
                </Heading>
                <Paragraph className="excerpt regent-grey">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor...</Paragraph>
              </Box>
            </Box>

            <Box tag="article" className="content-post news">
              <NavLink to="/single">
                <Box className="thumb">
                  <Box tag="span" className="favorite">
                    <Icon prefix="far" name="bookmark" />
                  </Box>
                  <Box className="thumb-wrap">
                    <Image alt="dummy" src="./img/dummy/post-15.png" className="thumb-bg" />
                  </Box>
                </Box>
              </NavLink>
              <Box className="caption">
                <Heading type="h3" className="title">
                  <NavLink to="/single">
                    9 things I wish I knew before playing Rainbow
                  </NavLink>
                </Heading>
                <Paragraph className="excerpt regent-grey">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor...</Paragraph>
              </Box>
            </Box>
          </Col>
          <Col xs="12" md="6">
            <Box tag="article" className="content-post news">
              <NavLink to="/single">
                <Box className="thumb">
                  <Box tag="span" className="favorite">
                    <Icon prefix="far" name="bookmark" />
                  </Box>
                  <Box className="thumb-wrap">
                    <Image alt="dummy" src="./img/dummy/post-16.png" className="thumb-bg" />
                  </Box>
                </Box>
              </NavLink>
              <Box className="caption">
                <Heading type="h3" className="title">
                  <NavLink to="/single">
                    9 things I wish I knew before playing Rainbow
                  </NavLink>
                </Heading>
                <Paragraph className="excerpt regent-grey">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor...</Paragraph>
              </Box>
            </Box>

            <Box tag="article" className="content-post news">
              <NavLink to="/single">
                <Box className="thumb">
                  <Box tag="span" className="favorite">
                    <Icon prefix="far" name="bookmark" />
                  </Box>
                  <Box className="thumb-wrap">
                    <Image alt="dummy" src="./img/dummy/post-17.png" className="thumb-bg" />
                  </Box>
                </Box>
              </NavLink>
              <Box className="caption">
                <Heading type="h3" className="title">
                  <NavLink to="/single">
                    9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                  </NavLink>
                </Heading>
                <Paragraph className="excerpt regent-grey">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor...</Paragraph>
              </Box>
            </Box>
          </Col>
        </Row>
      </Box>
    </Container>
  </Box>
);

export default BlogWidgetPosts7;
