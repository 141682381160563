import React from 'react';
import {
  Row,
  Col,
	Box,
	Paragraph,
	Image
} from 'base-ui';

const SubscriptionLandingFeatures = () => {
  return(
    <Box>
      <Row className="center-xs padding-top-x-big">
				<Col xs="12" sm="10" md="6">
					<Paragraph size="big" textColor="main" className="center-xs"><strong>Suscríbete y aprende sin límites y desde dónde quieras.</strong></Paragraph>
				</Col>
			</Row>
			<Row className="center-xs between-md padding-top-x-big padding-bottom-x-big">
				<Col xs="9" sm="4" md="3" className="wow fadeInUp center-xs align-center margin-bottom-medium">
					<Image src="/img/icon-devices.svg" />
					<Paragraph className="margin-top-medium">Puedes realizar los cursos <strong>desde cualquier dispositivo</strong> con acceso a internet.</Paragraph>
				</Col>
				<Col xs="9" sm="4" md="3" className="wow fadeInUp center-xs align-center margin-bottom-medium" data-wow-delay="0.2s">
					<Image src="/img/icon-clock.svg" />
					<Paragraph className="margin-top-medium"><strong>Cursos con acceso 24 horas</strong> los cursos empiezan y acaban <strong>cuando tú quieras.</strong></Paragraph>
				</Col>
				<Col xs="9" sm="4" md="3" className="wow fadeInUp center-xs align-center margin-bottom-medium" data-wow-delay="0.4s">
					<Image src="/img/icon-certificate.svg" />
					<Paragraph className="margin-top-medium">Completa el curso y <strong>recibe un certificado</strong> en tu correo electrónico.</Paragraph>
				</Col>
			</Row>
    </Box>
  )
}

export default SubscriptionLandingFeatures;