import React from 'react';
import PropTypes from 'prop-types';

// Base-UI Components
import {
  Box, Heading, Image, Icon, Button, Paragraph, NavLink
} from 'base-ui';

const Subscription = ({
  name, period, image, advantages, color, price, ultra
}) => {
  const periodName = period === 'mes' ? 'mensual' : 'anual';

  return (
    <Box tag="section" className={`plan align-center ${ultra && 'is--ultra'}`}>
      {ultra && (
        <>
          <Heading type="h2" color="main" className="title text-uppercase" size="x-medium">
            Suscripción
            <span className="name golden-color font-size-big">
              {' '}
              {name}
            </span>
          </Heading>
          <Paragraph textColor="main" className="opportunity">
            Una oportunidad única de invertir en tu desarrollo personal
          </Paragraph>
        </>
      )}
      <Heading type="h3" textColor={ultra ? 'main' : color} className="text-uppercase margin-bottom-medium">
        Suscripción
        {' '}
        <span className={ultra && 'golden-color'}>{name}</span>
        {' '}
        ilimitada
        {' '}
        {periodName}
      </Heading>
      {image && (
        <Image src={image} alt={name} className="margin-bottom-medium" />
      )}
      <ul className="align-left without-padding main-color">
        {advantages.map(({
          icon, prefix, text, isBold
        }) => (
          <li key={text} className="plan__advantage d--flex ai--center margin-bottom-medium">
            {icon === 'no-ad' ? (
              <span className="ad-free p--relative">
                <Icon name="ad" prefix="fas" size="medium" />
              </span>
            ) : (
              <Icon name={icon} size="medium" prefix={prefix} />
            )}
            {' '}
            <span className={isBold ? 'bold-font' : ''}>{text}</span>
          </li>
        ))}
      </ul>
      {ultra && (
        <>
          <Paragraph>
            Acceso a más de 30 cursos de La
            <NavLink to="/" className="golden-color"> Mente es Maravillosa </NavLink>
            elegidos exclusivamente para ti.
          </Paragraph>
          <NavLink to="/courses">
            <Button type="button" borderColor="golden" textColor="golden" size="big">
              Ver listado completo de cursos
            </Button>
          </NavLink>
        </>
      )}
      <NavLink to="/subscription/payment">
        <Button type="button" color={color} textColor="white" size="big">
          <span className="d--block align-center">
            Suscribirse a
            {' '}
            <span className={ultra && 'text-uppercase'}>{name}</span>
          </span>
          <span className="d--block align-center font-size-small">
            (
            {price}
            {' '}
            /
            {' '}
            {period}
            )
          </span>
        </Button>
      </NavLink>
      <Paragraph className="renovation">
        Renueva automáticamente. Cancela en cualquier momento.
      </Paragraph>
    </Box>
  );
};

Subscription.propTypes = {
  name: PropTypes.string,
  period: PropTypes.string,
  image: PropTypes.string,
  advantages: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.string,
  price: PropTypes.string,
  ultra: PropTypes.bool
};

export default Subscription;
