import React from 'react';

import {
  Container,
  Row,
  Col,
  Box,
  PageWrapper,
} from 'base-ui';
import CourseSidebarCard from '../../../components/Single/CourseSidebarCard';
import PaymentTabs from '../../../components/Sections/PaymentTabs';

function click() {
  console.log('clicked');
}

const SingleCoursePayment = () => (
  <PageWrapper config={{ title: 'Single course payment' }}>
    <Box>
      <Container fluid wrap>
        <Row>
          <Col xs="12" sm="5">
            <CourseSidebarCard
              imgSrc="https://content.lamenteesmaravillosa.com/courses/aprende-a-disenar-la-vida-que-deseas/img/aprende_a_disenar_la_vida_que_deseas.jpg"
              title="Aprende a diseñar la vida que deseas"
              modules="12 modulos - 13 horas"
              subtotal="90.00 €"
              total="90.00 €"
            />
          </Col>
          <Col xs="12" sm="7">
            <PaymentTabs
              onBuy={(e) => click(e)}
              color="white"
              textColor="title"
              borderColor="grey"
              buttonColor="mediumgray"
              buttonTextColor="white"
              buttonText="Guardar y continuar"
              className="margin-top-medium"
              hasEmail
              hasSave
              hasLabel
              hasHrLine
              securityIcon
              securityText={false}
            />
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default SingleCoursePayment;
