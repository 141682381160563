import React, { Component } from 'react';
import { Icon, Button } from 'base-ui';
import PropTypes from 'prop-types';

import styles from './ThemePicker.module.scss';

function removeCurrentTheme() {
  const head = document.getElementsByTagName('head')[0];
  const nodes = head.childNodes;

  const list = [];

  for (let ix = 0; ix < nodes.length; ix += 1) {
    const node = nodes.item(ix);
    if (node.tagName === 'STYLE' && node.innerText.match(/Theme - Base UI/gm)) {
      list.push(node);
    }
  }
  list.forEach((node) => head.removeChild(node));
}

class ThemePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      currentStyle: '',
      themeNodes: []
    };

    // this.toggleDrawer = this.toggleDrawer.bind(this);
    // this.selectTheme = this.selectTheme.bind(this);

    this.createLayoutOptions();
    // document.body.style.visibility = 'hidden';
    // document.body.style.opacity = '0';
    // document.body.style.transition = 'all .5s';
  }

  componentWillMount() {
    const { defaultTheme } = this.props;
    if (defaultTheme) {
      this.selectTheme(defaultTheme);
    }
  }

  componentDidMount() {
    setTimeout(() => {
      document.body.style.visibility = 'visible';
      document.body.style.opacity = '1';
    }, 1000);
  }

  createLayoutOptions = async () => {
    const navList = [];
    const { themes, defaultTheme } = this.props;

    themes.forEach((element) => {
      import(`base-ui/assets/dist/${element.file}.css`).then(() => {
        const head = document.getElementsByTagName('head')[0];
        const nodes = head.childNodes;

        for (let ix = 0; ix < nodes.length; ix += 1) {
          const node = nodes.item(ix);
          if (node.tagName === 'STYLE') {
            if (node.innerText.match(new RegExp(`Theme Name: ${element.name} Theme - Base UI`, 'gm'))) {
              node.setAttribute('data-key', element.name);
              this.setState((prevState) => ({ themeNodes: prevState.themeNodes.concat([{ name: element.name, node }]) }));
              if (element.name === themes[themes.length - 1].name) {
                setTimeout(() => {
                  this.selectTheme(defaultTheme || themes[0].name);
                }, 2000);
              }
            }
          }
        }
      });
    });

    return navList;
  };

  selectTheme = (key) => {
    removeCurrentTheme();
    const { themeNodes } = this.state;
    this.setState({ currentStyle: key });
    themeNodes.forEach((theme) => {
      if (theme.name === key) {
        const head = document.getElementsByTagName('head')[0];
        head.appendChild(theme.node);
      }
    });
  }

  handleToggleDrawer = () => {
    this.setState((prevState) => ({ isOpen: !(prevState.isOpen) }));
  };

  render() {
    const { themes } = this.props;
    const { isOpen, currentStyle } = this.state;
    const linksList = themes.map((theme) => (
      <Button
        full
        style={{ marginLeft: '15px', marginRight: '15px', width: 'calc(100% - 30px)' }}
        className={`${styles.LinksStyle} ${currentStyle === theme.name ? styles.Active : ''}`}
        key={theme.name}
        data-key={theme.name}
        onClick={(e) => this.selectTheme(e.target.getAttribute('data-key'))}
      >
        {theme.name}
      </Button>
    ));

    return (
      <div>
        <div id="ThemePickerOpenDrawerBtn" className={`${styles.ThemePickerOpenDrawerBtn} ${isOpen ? styles.OpenButton : ''}`}>
          <Button onClick={this.handleToggleDrawer} style={{ border: 'none' }}>
            <Icon prefix="fas" name="paint-brush" />
          </Button>
        </div>
        <div id="ThemePickerNav" className={`${styles.sideNavStyle} ${isOpen ? styles.Open : ''}`}>
          {linksList}
        </div>
      </div>
    );
  }
}

ThemePicker.propTypes = {
  themes: PropTypes.array,
  defaultTheme: PropTypes.string.isRequired
};

ThemePicker.defaultProps = {
  themes: [
    { name: 'MCS', file: '_themes/mcs' },
    { name: 'LMEM', file: '_themes/lmem' },
    { name: 'BUC', file: '_themes/buc' },
    { name: 'ED', file: '_themes/ed' },
    { name: 'EM', file: '_themes/em' },
    { name: 'MA', file: '_themes/ma' },
    { name: 'MD', file: '_themes/md' },
    { name: 'MV', file: '_themes/mv' },
    { name: 'VBB', file: '_themes/vbb' },
    { name: 'MS', file: '_themes/ms' },
    { name: 'UC', file: '_themes/uc' },
    { name: 'UNYCOS', file: '_themes/unycos' },
  ]
};

export default ThemePicker;
