import React from 'react';
import {
  Container,
  Box,
  Row,
  Col
} from 'base-ui';

import UnycosContentCourse from './UnycosContentCourse';

const UnycosFeaturedCourses = () => (
  <Box className="hidden-lg">
    <Container fluid wrap>
      <Row>
        <Col xs="12">
          <Box tag="span" className="display-block center-xs normal-font text-uppercase white-color font-size-normal margin-bottom-normal">Cursos destacados</Box>
        </Col>
        <Col xs="12" sm="6" className="margin-bottom-small">
          <UnycosContentCourse />
        </Col>
        <Col xs="12" sm="6" className="margin-bottom-small">
          <UnycosContentCourse />
        </Col>
      </Row>
    </Container>
  </Box>
);

export default UnycosFeaturedCourses;
