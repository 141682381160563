import React, { Component } from 'react';
import cs from 'classnames';
import debounce from 'lodash/debounce';

// import ReactCSSTransitionGroup from 'react-transition-group';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Modal,
  Image,
  Icon,
  Heading,
  Paragraph
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosHero from './components/UnycosHero';
import UnycosFeatures from './components/UnycosFeatures';
import UnycosCouponForm from './components/UnycosCouponForm';
import UnycosCourseConfirmation from './components/UnycosCourseConfirmation';
import UnycosFooter from './components/UnycosFooter';

import './Coupon.css';

class UnycosHome extends Component {
  state = {
    coupon: '',
    couponStatus: '',
    isSubmited: false,
    course: {},
    isConfirmed: false,
    showModal: false,
    isModalOpened: false
  }

  checkCoupon = (coupon) => {
    this.openModal();

    setTimeout(() => {
      if (coupon.length <= 3) {
        this.setState({ couponStatus: 'error' });
        this.closeModal();
        return;
      }

      const course = {
        title: 'Natación',
        by: 'Mireia Belmonte',
        price: '€ 65.00',
        thumb: './img/unycos/courses/mireia-big-slider.jpg'
      };

      this.setState({
        coupon,
        course,
        isSubmited: true
      });

      this.closeModal();
    }, 3000);
  }

  confirmCourse = () => {
    this.setState({ isConfirmed: true });

    debounce(() => {
      this.setState({ showModal: true });
    }, 1000)();
  }

  declineCourse = () => {
    this.setState({
      isSubmited: false,
      course: {}
    });
  }

  openModal = () => {
    this.setState({ isModalOpened: true });

    document.body.style.overflow = 'hidden';
  }

  closeModal = () => {
    this.setState({ isModalOpened: false });
    document.body.style.overflow = 'unset';
  }

  render() {
    const {
      coupon, couponStatus, isSubmited, course, isConfirmed, showModal, isModalOpened
    } = this.state;

    return (
      <PageWrapper config={{ title: 'Activate Coupon' }}>
        <UnycosLayout className="coupon">
          <UnycosHeader
            statuslogged={false}
            showModal={showModal}
            modalTitle="Crea una cuenta para empezar tu curso"
          />
          <UnycosHero
            hasSlider={false}
            staticInfo={{
              pageTitle: 'Detrás de cada éxito hay una historia.',
              bg: './img/unycos/hero/hero-coupon.jpg'
            }}
          />
          <Container fluid wrap className="coupon-bar margin-top-medium margin-bottom-big">
            <Row>
              <Col xs="12">
                <Box className={cs('coupon-bar-wrapper white-bg rounded-small', { confirm: isSubmited })}>
                  {!isSubmited ? (
                    <UnycosCouponForm
                      value={coupon}
                      status={couponStatus}
                      onSubmit={this.checkCoupon}
                    />
                  ) : (
                    <UnycosCourseConfirmation
                      course={course}
                      actions={{
                        confirm: this.confirmCourse,
                        decline: this.declineCourse
                      }}
                      confirmed={isConfirmed}
                    />
                  )}
                </Box>
              </Col>
            </Row>
            <Modal
              isOpen={isModalOpened}
              appElement="#root"
              closeIcon={false}
              width={784}
              height={400}
              animate={{
                directionFrom: 'bottom',
                effectIni: 300,
                effectEnd: 200,
              }}
              className="modal-processing"
            >
              <Row>
                <Col xs={12} md={5} className="align-center">
                  <Icon name="money-bill-wave" prefix="fas" size="big" color="black" className="pulsate" />
                  <Heading type="h2" size="x-big" className="title normal-font text-uppercase with-letter-spacing">Estamos procesando tu cupón</Heading>
                  <Paragraph>Esto puede tardar algunos segundos no actualices la pantalla.</Paragraph>
                </Col>
                <Col xs={12} md={6} mdOffset={1} className="align-center">
                  <Image src="./img/unycos/process-information.svg" alt="Processing Information" />
                </Col>
              </Row>
            </Modal>
          </Container>
          <UnycosFeatures className="margin-bottom-big" />
          <UnycosFooter
            showHideElements={{ social: true, cta: true }}
            contactText="Contacta con tu asesor personal"
            contactNumber="000000"
            goldenButton
            from="landing-page"
          />
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

export default UnycosHome;
