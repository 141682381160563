import React, { Component } from 'react';
import { NavLink } from 'base-ui';

class BlogInterestingSidebar extends Component {

	render() {
		return(
      <React.Fragment>
        <div className="widget widget-interesting">
          <div className="section-title text-uppercase align-center small utilitary-border utilitary-color">
            <span className="label">Artículos interesantes</span>
          </div>
          <div className="horizontal-scroll-md posts-row">
            <article className="content-post news">
              <NavLink to="/single">
                <div className="thumb">
                  <div className="thumb-wrap">
                    <img alt="" src="./img/dummy/post-9.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <div className="metas">
                  <span className="meta cat">
                    <a href=".">Subcategoria</a>
                  </span>
                </div>
                <h2 className="title">
                  <NavLink to="/single">
                    10 maneras de comenzar una rutina diaria y no morir en el intento
                  </NavLink>
                </h2>
                <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
              </div>
            </article>
            <article className="content-post news">
              <NavLink to="/single">
                <div className="thumb">
                  <div className="thumb-wrap">
                    <img alt="" src="./img/dummy/post-10.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <div className="metas">
                  <span className="meta cat">
                    <a href=".">Subcategoria</a>
                  </span>
                </div>
                <h2 className="title">
                  <NavLink to="/single">
                    10 maneras de comenzar una rutina diaria y no morir en el intento
                  </NavLink>
                </h2>
                <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
              </div>
            </article>
            <article className="content-post news">
              <NavLink to="/single">
                <div className="thumb">
                  <div className="thumb-wrap">
                    <img alt="" src="./img/dummy/post-11.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <div className="metas">
                  <span className="meta cat">
                    <a href=".">Subcategoria</a>
                  </span>
                </div>
                <h2 className="title">
                  <NavLink to="/single">
                    10 maneras de comenzar una rutina diaria y no morir en el intento
                  </NavLink>
                </h2>
                <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
              </div>
            </article>
            <article className="content-post news">
              <NavLink to="/single">
                <div className="thumb">
                  <div className="thumb-wrap">
                    <img alt="" src="./img/dummy/post-12.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <div className="metas">
                  <span className="meta cat">
                    <a href=".">Subcategoria</a>
                  </span>
                </div>
                <h2 className="title">
                  <NavLink to="/single">
                    10 maneras de comenzar una rutina diaria y no morir en el intento
                  </NavLink>
                </h2>
                <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
              </div>
            </article>
            <article className="content-post news">
              <NavLink to="/single">
                <div className="thumb">
                  <div className="thumb-wrap">
                    <img alt="" src="./img/dummy/post-13.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <div className="metas">
                  <span className="meta cat">
                    <a href=".">Subcategoria</a>
                  </span>
                </div>
                <h2 className="title">
                  <NavLink to="/single">
                    10 maneras de comenzar una rutina diaria y no morir en el intento
                  </NavLink>
                </h2>
                <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
              </div>
            </article>
          </div>
        </div>
      </React.Fragment>
		)
	}
}

export default BlogInterestingSidebar;
