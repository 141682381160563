import React from 'react';

import {
  PageWrapper
} from 'base-ui';

import UnycosLayout from './UnycosLayout';
import UnycosHeader from './components/UnycosHeader';
import UnycosHero from './components/UnycosHero';
import UnycosFeatures from './components/UnycosFeatures';
import UnycosTeacherComments from './components/UnycosTeacherComments';
import UnycosFooter from './components/UnycosFooter';
import UnycosFooterCTA from './components/UnycosFooterCTA';
import UnycosFeaturedCourses from './components/UnycosFeaturedCourses';

class UnycosHome extends React.PureComponent {
  render() {
    return (
      <PageWrapper config={{ title: 'Unycos home' }}>
        <UnycosLayout>
          <UnycosHeader statuslogged={false} />
          <UnycosHero className="" />
          <UnycosFeatures />
          <UnycosTeacherComments />
          <UnycosFeaturedCourses />
          <UnycosFooterCTA />
          <UnycosFooter showHideElements={{ social: true, cta: true }} />
        </UnycosLayout>
      </PageWrapper>
    );
  }
}

export default UnycosHome;
