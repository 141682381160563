/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Heading,
  Image,
  NavLink
} from 'base-ui';

const ResultProfessional = (props) => {
  const {
    data
  } = props;
  return (
    <article className="content-consultations professionals">
      <NavLink to="single">
        <Box className="thumb">
          <Image src={data.thumb} alt={`Thumb Avatar ${data.professionalName}`} />
        </Box>
      </NavLink>
      <Box className="caption">
        <Box className="metas">
          <Box tag="span" className="meta">{data.professionalArea}</Box>
        </Box>
        <NavLink to="single">
          <Heading type="h2" className="title">{data.professionalName}</Heading>
        </NavLink>
        <Box className="metas">
          <Box tag="span" className="meta">
            <strong>{data.price}</strong>
            /
            {data.type}
          </Box>
        </Box>
      </Box>
    </article>
  );
};

ResultProfessional.propTypes = {
  data: PropTypes.shape({
    professionalName: PropTypes.string,
    thumb: PropTypes.string,
    professionalArea: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.string,
  })
};

export default ResultProfessional;
