import React, { Component } from 'react';

class BlogMenu extends Component {

	render() {
		return(
			<nav id="menu">
				<div className="container-fluid wrap">
					<div className="row middle-xs">
						<div className="col-xs-12 col-md-11">
              <ul className="menu">
                <li className="current-menu-item"><a href=".">Portada</a></li>
                <li>
                  <a href=".">Salud</a>
                  <ul className="sub-menu">
                    <li><a href=".">Primeros auxilios</a></li>
                    <li><a href=".">Medicamentos</a></li>
                    <li><a href=".">Cuerpo humano</a></li>
                    <li><a href=".">Enfermedades</a></li>
                    <li><a href=".">Tratamientos</a></li>
                  </ul>
                </li>
                <li>
                  <a href=".">Remedios naturales</a>
                  <ul className="sub-menu">
                    <li><a href=".">Primeros auxilios</a></li>
                    <li><a href=".">Medicamentos</a></li>
                    <li><a href=".">Cuerpo humano</a></li>
                    <li><a href=".">Enfermedades</a></li>
                    <li><a href=".">Tratamientos</a></li>
                  </ul>
                </li>
                <li>
                  <a href=".">Bienestar</a>
                  <ul className="sub-menu">
                    <li><a href=".">Primeros auxilios</a></li>
                    <li><a href=".">Medicamentos</a></li>
                    <li><a href=".">Cuerpo humano</a></li>
                    <li><a href=".">Enfermedades</a></li>
                    <li><a href=".">Tratamientos</a></li>
                  </ul>
                </li>
                <li>
                  <a href=".">Dieta</a>
                  <ul className="sub-menu">
                    <li><a href=".">Primeros auxilios</a></li>
                    <li><a href=".">Medicamentos</a></li>
                    <li><a href=".">Cuerpo humano</a></li>
                    <li><a href=".">Enfermedades</a></li>
                    <li><a href=".">Tratamientos</a></li>
                  </ul>
                </li>
                <li>
                  <a href=".">Recetas</a>
                  <ul className="sub-menu">
                    <li><a href=".">Primeros auxilios</a></li>
                    <li><a href=".">Medicamentos</a></li>
                    <li><a href=".">Cuerpo humano</a></li>
                    <li><a href=".">Enfermedades</a></li>
                    <li><a href=".">Tratamientos</a></li>
                  </ul>
                </li>
                <li>
                  <a href=".">Maternidad</a>
                  <ul className="sub-menu">
                    <li><a href=".">Primeros auxilios</a></li>
                    <li><a href=".">Medicamentos</a></li>
                    <li><a href=".">Cuerpo humano</a></li>
                    <li><a href=".">Enfermedades</a></li>
                    <li><a href=".">Tratamientos</a></li>
                  </ul>
                </li>
                <li>
                  <a href=".">Parejas</a>
                  <ul className="sub-menu">
                    <li><a href=".">Primeros auxilios</a></li>
                    <li><a href=".">Medicamentos</a></li>
                    <li><a href=".">Cuerpo humano</a></li>
                    <li><a href=".">Enfermedades</a></li>
                    <li><a href=".">Tratamientos</a></li>
                  </ul>
                </li>
                <li>
                  <a href=".">Lifestyle</a>
                  <ul className="sub-menu">
                    <li><a href=".">Primeros auxilios</a></li>
                    <li><a href=".">Medicamentos</a></li>
                    <li><a href=".">Cuerpo humano</a></li>
                    <li><a href=".">Enfermedades</a></li>
                    <li><a href=".">Tratamientos</a></li>
                  </ul>
                </li>
              </ul>
						</div>
						<div className="col-md-1 search hidden-md end-xs">
							<form className="end-xs">
								<input type="text" placeholder="Buscar noticias, análisis, juegos..." />
								<button className="btn rounded white-color main-bg main-border"><i className="fas fa-search"></i></button>
							</form>
						</div>
					</div>
				</div>
			</nav>
		)
	}
}

export default BlogMenu;
