import React, { Component } from 'react';
import { NavLink, LoadMore } from 'base-ui';

class BlogPostsByCategory extends Component {

	render() {
		return(
			<React.Fragment>
      <section id="featured-posts-widget" className="widget posts-style-3">

        <div className="section-title main-border">
          <span className="label text-uppercase titles-color">Buenos hábitos</span>
        </div>

        <div className="posts-row">

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-13.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  10 maneras de comenzar una rutina diaria y no morir en el intento
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-14.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-15.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-16.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

        </div>

      </section>

      <section id="featured-posts-widget" className="widget posts-style-4">

        <div className="section-title main-border">
          <span className="label text-uppercase titles-color">Salud</span>
        </div>

        <div className="posts-row">

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-17.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  10 maneras de comenzar una rutina diaria y no morir en el intento
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-14.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-15.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-16.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

        </div>

      </section>

      <section id="featured-posts-widget" className="widget posts-style-6">

        <div className="section-title main-border">
          <span className="label text-uppercase titles-color">Remedios naturales</span>
        </div>

        <div className="posts-row">

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-19.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  10 maneras de comenzar una rutina diaria y no morir en el intento
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-14.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-15.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-16.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-17.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

        </div>

      </section>

      <section id="featured-posts-widget" className="widget posts-style-2">

        <div className="section-title main-border">
          <span className="label text-uppercase titles-color">Manualidades</span>
        </div>

        <div className="posts-row">

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-20.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  10 maneras de comenzar una rutina diaria y no morir en el intento
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-14.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-15.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-16.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

        </div>

      </section>

      <section id="featured-posts-widget" className="widget posts-style-3">

        <div className="section-title main-border">
          <span className="label text-uppercase titles-color">Dieta</span>
        </div>

        <div className="posts-row">

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-21.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  10 maneras de comenzar una rutina diaria y no morir en el intento
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-14.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-15.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

          <article className="content-post news">
            <NavLink to="/single">
              <div className="thumb">
                <span className="favorite">
                  <i className="far fa-bookmark"></i>
                </span>
                <div className="thumb-wrap">
                  <img alt="dummy" src="./img/dummy/post-16.png" className="thumb-bg" />
                </div>
              </div>
              </NavLink>
              <div className="caption">
              <div className="metas">
                <span className="meta cat">
                  <a href=".">Subcategoria</a>
                </span>
              </div>
              <h2 className="title">
                <NavLink to="/single">
                  9 things I wish I knew before playing Rainbow Six Siege Outbreak mode
                </NavLink>
              </h2>
              <p className="excerpt regent-grey-color">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor…</p>
            </div>
          </article>

        </div>

        <LoadMore
          color="main"
          rounded
          scale="2.5"
          type="circle"
          classesExtra="load-more-animation center"
          colorTxt="main"
          colorBg="white"
          onRequestLoadMore={(e) => {
            e.preventDefault();
          }}
        >
          <span>
            Load More
          </span>
        </LoadMore>

      </section>
      </React.Fragment>
		)
	}
}

export default BlogPostsByCategory;
