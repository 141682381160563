import React from 'react';

import {
  Box,
  Lists,
  Icon,
  Heading,
  Col
} from 'base-ui';

const YoullLearnDesktop = () => (
  <Box className="hidden-md">
    <Heading type="h3" size="normal" textColor="white" className="text-uppercase">En este curso aprenderas:</Heading>
    <Lists hasIcons className="row to-learn-list">
      <Col xs="4">
        <li className="item">
          <Icon className="alpha-color" prefix="fas" name="users" />
          <Box tag="span" className="white-color">Comprender cómo son y cómo piensan los demás</Box>
        </li>
      </Col>
      <Col xs="4">
        <li className="item">
          <Icon className="alpha-color" prefix="fas" name="heart" />
          <Box tag="span" className="white-color">Aumentar enormemente la confianza en ti mismo</Box>
        </li>
      </Col>
      <Col xs="4">
        <li className="item">
          <Icon className="alpha-color" prefix="fas" name="star" />
          <Box tag="span" className="white-color">Descubrir tus mejores talentos y virtudes</Box>
        </li>
      </Col>
      <Col xs="4">
        <li className="item">
          <Icon className="alpha-color" prefix="fas" name="fire" />
          <Box tag="span" className="white-color">Saber por dónde empezar a mejorar tus capacidades</Box>
        </li>
      </Col>
      <Col xs="4">
        <li className="item">
          <Icon className="alpha-color" prefix="fas" name="hand-point-right" />
          <Box tag="span" className="white-color">Mejorar tu inteligencia emocional y social</Box>
        </li>
      </Col>
      <Col xs="4">
        <li className="item">
          <Icon className="alpha-color" prefix="fas" name="check" />
          <Box tag="span" className="white-color">Mejorar tu autocontrol y gestión de tus emociones</Box>
        </li>
      </Col>
    </Lists>
  </Box>
);

export default YoullLearnDesktop;
