import React from 'react';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';

import {
  Box,
  Icon,
  Heading,
  Lists
} from 'base-ui';

// Utils
import makeSlug from '../../../utils/stringToSlug';

const TopicsList = ({
  className, title, titleType, data, handler
}) => {
  const size = data.length;
  const content1 = data.slice(0, size / 2);
  const content2 = data.slice(size / 2, size);

  // anchor html with react ref
  const handleClick = (id, href) => {
    if (handler !== undefined) {
      handler(id);
    } else {
      window.location = href;
      return false;
    }
  };

  return (
    <Box tag="section" className={`section-anchors padding-normal ${className}`}>
      <Heading type={titleType} className="titleLine">{title}</Heading>
      <Lists>
        {content1.map((item) => (
          <li key={item.id}>
            <Link to={`#${makeSlug(item.title)}`} onClick={() => handleClick(item.id, item.url)} smooth aria-label={item.label}>
              <span>{item.label}</span>
              <Icon prefix="fas" name="chevron-right" />
            </Link>
          </li>
        ))}
      </Lists>
      <Lists>
        {content2.map((item) => (
          <li key={item.id}>
            <Link to={`#${makeSlug(item.title)}`} onClick={() => handleClick(item.id, item.url)} aria-label={item.label}>
              <span>{item.label}</span>
              <Icon prefix="fas" name="chevron-right" />
            </Link>
          </li>
        ))}
      </Lists>
    </Box>
  );
};

TopicsList.defaultProps = {
  className: '',
};

TopicsList.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  titleType: PropTypes.string,
  handler: PropTypes.func,
  className: PropTypes.string
};

export default TopicsList;
