import React from 'react';

import {
  Box,
  Paragraph
} from 'base-ui';

const ToWhere = () => (
  <Box className="margin-top-x-medium">
    <Box className="section-title thin-border without-margin-bottom font-size-normal">
      <Box tag="span" className="label line-height-small without-margin text-uppercase titles-color">
        A quién va dirigido
      </Box>
    </Box>
    <Box>
      <Paragraph>A todos, porque la palabra hablada constituye el sencillo y prodigioso medio que permite al ser humano romper su soledad esencial, establecer el mejor puente de comunicación con los otros, y dejar huella de su paso por el mundo.</Paragraph>
      <Paragraph>Profesionales liberales, políticos, empresarios, directivos, docentes, líderes sociales, técnicos, obreros manuales; doctos e iletrados; niños, jóvenes y adultos… En fin, toda mujer y hombre, porque no se puede desconocer el dominio de una herramienta absolutamente imprescindible en el diario vivir.</Paragraph>
    </Box>
  </Box>
);

export default ToWhere;
