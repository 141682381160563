import React, {Component} from 'react';

import {
  Container,
  Row,
  Col,
	Box,
	Hero,
	Button,
	Heading,
	Paragraph,
	Icon,
	Courses
} from 'base-ui';

class CoursesSubscriptionLanding extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialItems: [
        {
          name: 'Consigue que tus hijos sean felices, seguros y autónomos',
          desc: 'La educación de un hijo requiere paciencia, amor y unas buenas herramientas con las que saber dar respuesta a cada necesidad. Con las estrategias educativas y de gestión emocional de este curso online podrás conseguirlo.',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-consigue-que-tus-hijos-sean-felices-seguros-y-autonomos/img/familia-en-el-campo-haciendo-pompas.jpg',
          instructors: [
			  { displayName: 'Centro de Psicología Álava Reyes' },
		  ],
          duration: '16 módulos - 12 horas',
        },
        {
		  name: 'Oratoria moderna',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-oratoria-moderna/img/oratoria.jpg',
		  instructors: [
			{ displayName: 'Ángel Lafuente Zorrilla' },
		  ],
          duration: '15 módulos - 7 horas',
        },
        {
          name: 'Identificar, gestionar y convivir con gente tóxica',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-identificar-gestionar-y-convivir-con-gente-toxica/img/gente_toxica.jpg',
		  instructors: [
			{ displayName: 'Tomás  Navarro' },
		  ],
          duration: '15 módulos - 7 horas',
				},
				{
          name: 'Reinvéntate',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/reinventate/img/shutterstock_1171469926.jpg',
		  teacher: 'Agustín Andrade',
		  instructors: [
			{ displayName: 'Agustin Andrade' },
		  ],
          duration: '15 módulos - 7 horas',
        },
        {
          name: 'Oratoria moderna',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-oratoria-moderna/img/oratoria.jpg',
		  instructors: [
			{ displayName: 'Ángel Lafuente Zorrilla' },
		  ],
          duration: '15 módulos - 7 horas',
        },
        {
          name: 'Identificar, gestionar y convivir con gente tóxica',
          desc: 'Te proponemos un reto, un maravilloso desafío: reinventarte. A través de nuestro curso...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-de-identificar-gestionar-y-convivir-con-gente-toxica/img/gente_toxica.jpg',
		  instructors: [
			{ displayName: 'Tomás Navarro' },
		  ],
          duration: '15 módulos - 7 horas',
        },
			],
			items: [],
		};
	}
	componentDidMount() {
		this.setState({items: this.state.initialItems})
	}
	render() {

		const HeroSubline =
		<Paragraph size="medium" textColor="text" className="wow fadeInUp margin-top-small margin-bottom-medium"  data-wow-delay="0.3s"><Box tag="span" className="font-size-small display-block">Accede a más de 30 cursos </Box><strong>desde 9.90 € / mes</strong></Paragraph>;

		const fadeEffect = {
			background: 'linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(255,255,255,1) 100%)',
			height: '200px',
			width: '100%',
			position: 'absolute',
			bottom: '40px',
			left: '0px',
		};

		return(
			<Box>
				<Hero
				BgImage="/img/subscribe-catalog.png">
					<Row className="middle-xs">
						<Col xs="8" sm="6" md="4">
							<Heading type="h1" size="x-big" textColor="titles" className="wow fadeInUp">Suscríbete a nuestro catálogo</Heading>
							{HeroSubline}
							<Button size="big" textColor="white" borderColor="secondary" tag="a" color="secondary"  data-wow-delay="0.6s" className="wow fadeInUp">
								Empieza ya
							</Button>
						</Col>
					</Row>
				</Hero>
				<Container fluid wrap>
					<Row className="center-xs between-md padding-top-x-big padding-bottom-x-big">
						<Col xs="9" sm="4" md="3" className="wow fadeInUp center-xs align-center margin-bottom-medium" data-wow-delay="0.3s">
							<Icon prefix="fas" name="globe" color="secondary" size="big" className="margin-bottom-small" />
							<Heading type="h3" size="x-medium" textColor="secondary" className="margin-bottom-small">100% Online</Heading>
							<Paragraph>Cursos con acceso 24 horas,los cursos empiezan y acaban cuando tú quieras.</Paragraph>
						</Col>
						<Col xs="9" sm="4" md="3" className="wow fadeInUp center-xs align-center margin-bottom-medium" data-wow-delay="0.6s">
							<Icon prefix="fas" name="laptop" color="orange" size="big" className="margin-bottom-small" />
							<Heading type="h3" size="x-medium" textColor="orange" className="margin-bottom-small">Donde quieras</Heading>
							<Paragraph>Puedes realizar los cursos desde cualquier dispositivo con acceso a internet.</Paragraph>
						</Col>
						<Col xs="9" sm="4" md="3" className="wow fadeInUp center-xs align-center margin-bottom-medium" data-wow-delay="0.9s">
							<Icon prefix="fas" name="graduation-cap" color="blue" size="big" className="margin-bottom-small" />
							<Heading type="h3" size="x-medium" textColor="blue" className="margin-bottom-small">Certificado</Heading>
							<Paragraph>Completa el curso y recibe un certificado en tu correo electrónico.</Paragraph>
						</Col>
					</Row>
					<Row className="center-xs">
						<Col xs="12" md="11" className="wow fadeInUp">
							<Box className="section-title align-center transparent-border">
								<Heading type="h4" size="medium" textColor="text" className="main-font">Descubre nuestro catálogo</Heading>
							</Box>
							<Courses
								className="align-center without-fixed-height without-card"
								items={this.state.items}
								locale="es"
							/>
						</Col>
						<Col xs="12" className="position-relative">
							<Box style={fadeEffect}></Box>
						</Col>
					</Row>
					<Row className="margin-bottom-x-big">
						<Col xs="12" className="align-center center-xs">
							<Button size="medium" textColor="text" borderColor="transparent" tag="button" color="transparent">
								<Icon prefix="fas" name="chevron-down" /> Ver todos los cursos <Icon prefix="fas" name="chevron-down" />
							</Button>
						</Col>
						<Col xs="12" className="align-center center-xs">
							<Button size="big" textColor="white" borderColor="secondary" tag="a" color="secondary">
								Empieza ya
							</Button>
						</Col>
					</Row>
				</Container>
			</Box>
		)
	}
}

export default CoursesSubscriptionLanding;
