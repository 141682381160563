import React from 'react';

import {
  Container,
  Row,
  Col,
  Box,
  PageWrapper,
} from 'base-ui';

import PricingTable from '../../../components/Sections/PricingTable';
import PricingTableMobile from '../../../components/Sections/PricingTableMobile';
import PaymentTabs from '../../../components/Sections/PaymentTabs';

const CoursesSubscriptionPayment = () => (
  <PageWrapper config={{ title: 'Courses subscription payment' }}>
    <Box>
      <Container fluid wrap>
        <Row>
          <Col xs="12" md="7">
            <PricingTable />
            <PricingTableMobile />
          </Col>
          <Col xs="12" md="5">
            <PaymentTabs />
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default CoursesSubscriptionPayment;
