import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, InputSelect } from 'base-ui';

import timezonesJSON from './data/timezones.json';

const TimeZonePicker = ({
  onChange, currentValue
}) => {
  const timezones = timezonesJSON.map((tz) => ({ value: tz.name, label: tz.name }));

  return (
    <Box className="schedule-timezone">
      <Icon
        name="globe-americas"
        prefix="fas"
        className="icon"
      />
      <InputSelect
        value={currentValue}
        required
        onChange={onChange}
        options={timezones}
      />
    </Box>
  );
};

TimeZonePicker.propTypes = {
  /**
   * Function that responds to setting the value
   */
  onChange: PropTypes.func,

  /**
   * Function that responds to setting the value
   */
  currentValue: PropTypes.string
};

export default TimeZonePicker;
