import React from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Heading,
  InputSelect
} from 'base-ui';

const MinHeightContent = () => (
  <PageWrapper config={{ title: 'Posteingang | Gedankenwelt' }}>
    <Container fluid wrap>
      <Box tag="main" id="site-main" style={{ border: '1px dotted grey' }}>
        <Row className="center-xs middle-xs">
          <Col xs="12" md="10">
            <Card>
              <Box className="box-title grey-bg">
                <span className="label main-font">Nachrichten (0 neue)</span>
                <Box style={{ float: 'right', minWidth: '200px' }}>
                  <InputSelect
                    small
                    className="without-shadow without-padding"
                    name="test"
                    required
                    options={[
                      { value: '1', label: 'Gespeicherte Nachrichten' },
                      { value: '2', label: 'Erhaltene Nachrichten' },
                    ]}
                  />
                </Box>
              </Box>
              <Heading type="h3" className="align-center"><span>Du hast keine Nachricht</span></Heading>
            </Card>
          </Col>
        </Row>
      </Box>
    </Container>
  </PageWrapper>
);

export default MinHeightContent;
