import React, { Component } from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Icon,
  Badge,
  InputSelect,
  Button,
  InputField,
  Tabs,
  Modal,
  Label,
  Paragraph
} from 'base-ui';

// Components
import MenuConsultations from '../../../components/Consultation/MenuConsultations';

class Clinics extends Component {
  state = { showModalAvailability: false };

  openModalAvailability= () => {
    this.setState({ showModalAvailability: true });
  }

  closeModalAvailability = () => {
    this.setState({ showModalAvailability: false });
  }

  render() {
    const {
      showModalAvailability
    } = this.state;
    const styleModalAvailability = {
      overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.80)',
        zIndex: '9999'
      },
      content: {
        position: 'absolute',
        right: '10px',
        left: '10px',
        bottom: '20px',
        margin: 'auto',
        boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
        overflow: 'auto',
        padding: '10px',
        borderRadius: '5px',
        maxWidth: '990px',
        display: 'block'
      }
    };
    return (
      <PageWrapper config={{ title: 'Consultation Clinics', bodyClasses: 'consultation' }}>

        <Box tag="main" id="site-main">

          <Box tag="section" className="consultation-consult">
            <Container container fluid wrap>
              <Row className="center-xs">
                <Col xs="12" sm="4">
                  <MenuConsultations />
                </Col>
                <Col xs="12" sm="8">

                  <Card>
                    <Box className="box-title grey-bg">
                      <Box tag="span" className="label main-font">Insertar clínicas</Box>
                    </Box>
                    <Row>
                      <Col xs="12" className="margin-bottom-small">
                        <InputField
                          full
                          id="clinic_location"
                          required
                          label="Nombre de la ubicación:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: Mi Clínica"
                        />
                      </Col>
                      <Col xs="12" className="margin-bottom-small">
                        <InputField
                          full
                          id="clinic_direction"
                          required
                          label="Dirección física:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: Calle Callejo, 39"
                        />
                      </Col>
                      <Col xs="12" sm="4" className="margin-bottom-small">
                        <InputField
                          full
                          id="clinic_phone"
                          required
                          label="Número de telefono:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: +34 555 555 555"
                        />
                      </Col>
                      <Col xs="12" sm="4" className="margin-bottom-small">
                        <InputField
                          full
                          id="clinic_email"
                          required
                          label="Correo elctrónico:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: micorreo@micorreo.com"
                        />
                      </Col>
                      <Col xs="12" sm="4" className="margin-bottom-small">
                        <InputField
                          full
                          id="clinic_website"
                          required
                          label="Sitio web:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: www.miweb.com"
                        />
                      </Col>
                      <Col xs="12">
                        <InputField
                          full
                          id="clinic_register"
                          required
                          label="Registro sanitario:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: 123.456-789"
                        />
                      </Col>
                      <hr />
                      <Col xs="12" sm="6" className="margin-bottom-small">
                        <InputField
                          full
                          id="clinic_facebook"
                          required
                          label="Facebook:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: www.facebook.com/miperfil"
                        />
                      </Col>
                      <Col xs="12" sm="6" className="margin-bottom-small">
                        <InputField
                          full
                          id="clinic_youtube"
                          required
                          label="Youtube:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: www.youtube.com/miperfil"
                        />
                      </Col>
                      <Col xs="12" sm="6" className="margin-bottom-small">
                        <InputField
                          full
                          id="clinic_instagram"
                          required
                          label="Instagram:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: www.instagram.com/miperfil"
                        />
                      </Col>
                      <Col xs="12" sm="6">
                        <InputField
                          full
                          id="clinic_twitter"
                          required
                          label="Twitter:"
                          type="text"
                          className="without-shadow"
                          placeholder="Ej: www.twitter.com/miperfil"
                        />
                      </Col>
                      <hr />
                      <Col xs="12">
                        <Button big color="main" borderColor="main" textColor="white">
                          Guardar
                          <Icon prefix="far" name="save" />
                        </Button>
                        <Button big color="grey" borderColor="grey" textColor="text">
                          Cancelar
                          <Icon prefix="fas" name="times" />
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                  <Card>
                    <Box className="box-title grey-bg">
                      <Box tag="span" className="label main-font">Clínicas</Box>
                    </Box>
                    <Row>
                      <Col xs="12" sm="6">
                        <Card>
                          <Box className="align-right">
                            <Button to="#" borderColor="grey" textColor="main"><Icon prefix="fas" name="star" /></Button>
                            <Button to="#" borderColor="grey" textColor="blue"><Icon prefix="fas" name="edit" /></Button>
                            <Button to="#" borderColor="grey" textColor="red"><Icon prefix="fas" name="trash-alt" /></Button>
                          </Box>
                          <hr />
                          <strong className="font-big titles-color without-margin-bottom">Nombre de la clínica</strong>
                          <Paragraph className="without-margin-top">Dirección, 98 - Salamanca, ES</Paragraph>
                          <Box>
                            <Box tag="span">
                              <Icon prefix="fas fa-phone" />
                              {' '}
                              999 888 777
                            </Box>
                            <br />
                            <Box tag="span">
                              <Icon prefix="fas fa-envelope" />
                              {' '}
                              correo@correo.com
                            </Box>
                          </Box>
                          <hr />
                          <Box className="align-center">
                            <Button to="#" borderColor="transparent"><Icon prefix="fab" name="facebook" /></Button>
                            <Button to="#" borderColor="transparent"><Icon prefix="fab" name="youtube" /></Button>
                            <Button to="#" borderColor="transparent"><Icon prefix="fab" name="instagram" /></Button>
                            <Button to="#" borderColor="transparent"><Icon prefix="fab" name="twitter" /></Button>
                            <Button to="#" borderColor="transparent"><Icon prefix="fas" name="globe" /></Button>
                          </Box>
                          <hr />
                          <Card className="small without-shadow">
                            <Row className="align-center">
                              <Box xs="12 align-center">
                                <Box tag="span">
                                  <strong>Lunes</strong>
                                  <br />
                                  {' '}
                                  12:30 - 2:30
                                </Box>
                              </Box>
                              <Box xs="12 align-center">
                                <Button to="#" borderColor="transparent" textColor="main"><Icon prefix="fas" name="globe" /></Button>
                                <Button to="#" borderColor="transparent" textColor="blue"><Icon prefix="fas" name="edit" /></Button>
                                <Button to="#" borderColor="transparent" textColor="red"><Icon prefix="fas" name="trash-alt" /></Button>
                              </Box>
                            </Row>
                          </Card>
                          <Button type="" data-featherlight="#availability-modal" className="white-bg main-border full main-color btn">+ Añadir disponibilidad</Button>
                        </Card>
                      </Col>
                      <Col xs="12" sm="6">
                        <Card>
                          <Box className="align-right">
                            <Button to="#" borderColor="grey" textColor="main"><Icon prefix="fas" name="star" /></Button>
                            <Button to="#" borderColor="grey" textColor="blue"><Icon prefix="fas" name="edit" /></Button>
                            <Button to="#" borderColor="grey" textColor="red"><Icon prefix="fas" name="trash-alt" /></Button>
                          </Box>
                          <hr />
                          <strong className="font-big titles-color without-margin-bottom">Nombre de la clínica</strong>
                          <Paragraph className="without-margin-top">Dirección, 98 - Salamanca, ES</Paragraph>
                          <Box>
                            <Box tag="span">
                              <Icon prefix="fas" name="phone" />
                              {' '}
                              999 888 777
                            </Box>
                            <br />
                            <Box tag="span">
                              <Icon prefix="fas" name="envelope" />
                              {' '}
                              correo@correo.com
                            </Box>
                          </Box>
                          <hr />
                          <Box className="align-center">
                            <Button to="#" borderColor="transparent"><Icon prefix="fab" name="facebook" /></Button>
                            <Button to="#" borderColor="transparent"><Icon prefix="fab" name="youtube" /></Button>
                            <Button to="#" borderColor="transparent"><Icon prefix="fab" name="instagram" /></Button>
                            <Button to="#" borderColor="transparent"><Icon prefix="fab" name="twitter" /></Button>
                            <Button to="#" borderColor="transparent"><Icon prefix="fas" name="globe" /></Button>
                          </Box>
                          <hr />
                          <Card className="small without-shadow">
                            <Row className="align-center">
                              <Col xs="12" className="align-center">
                                <Box tag="span">
                                  <strong>Lunes</strong>
                                  <br />
                                  {' '}
                                  12:30 - 2:30
                                </Box>
                              </Col>
                              <Col xs="12" className="align-center">
                                <Button to="#" borderColor="transparent" textColor="main"><Icon prefix="fas" name="globe" /></Button>
                                <Button to="#" borderColor="transparent" textColor="blue"><Icon prefix="fas" name="edit" /></Button>
                                <Button to="#" borderColor="transparent" textColor="red"><Icon prefix="fas" name="trash-alt" /></Button>
                              </Col>
                            </Row>
                          </Card>
                          <Button onClick={this.openModalAvailability} full color="white" borderColor="main" textColor="main">+ Añadir disponibilidad</Button>
                        </Card>
                      </Col>
                    </Row>
                  </Card>


                </Col>
              </Row>
            </Container>
          </Box>

        </Box>
        {/* end main section */}

        {/* ini #modal-availability */}
        <Modal
          isOpen={showModalAvailability}
          appElement="#root"
          idModal="modal-availability"
          className="modal"
          onAfterOpen={this.afterOpenModal}
          closeModal={this.closeModalAvailability}
          closeIcon={false}
          styles={styleModalAvailability}
        >
          <Tabs>
            <Box label={<Badge color="grey">Dom.</Badge>}>
            </Box>
            <Box label={<Badge color="grey">Lun.</Badge>}>
            </Box>
            <Box label={<Badge color="grey">Mier.</Badge>}>
            </Box>
            <Box label={<Badge color="grey">Jue.</Badge>}>
            </Box>
            <Box label={<Badge color="grey">Vie.</Badge>}>
            </Box>
            <Box label={<Badge color="grey">Sab.</Badge>}>
            </Box>
          </Tabs>

          <Card>
            <Row className="middle-xs">
              <Col xs="6" className="margin-bottom-small">
                <InputSelect
                  label="Desde:"
                  name="since"
                  options={[
                    { value: '1330', label: '13:30' },
                    { value: '1400', label: '14:00' },
                  ]}
                />
              </Col>
              <Col xs="6" className="end-xs margin-bottom-small">
                <InputSelect
                  label="Hasta:"
                  name="since"
                  options={[
                    { value: '1530', label: '15:30' },
                    { value: '1600', label: '16:00' },
                  ]}
                />
              </Col>
            </Row>
            <Row className="middle-xs">
              <Col xs="12" sm="6" className="margin-bottom-small">
                <InputField
                  id="a1"
                  type="checkbox"
                  required
                />
                <Label inputId="a1">
                  Copiar la misma disponibilidad para:
                </Label>
              </Col>
              <Col xs="12" sm="6" className="margin-bottom-small">
                <InputSelect
                  label="Full:"
                  name="dates1"
                  options={[
                    { value: 'abc', label: 'Todos los días laborales (L - V)' },
                  ]}
                />
              </Col>
            </Row>
            <Row className="middle-xs">
              <Col xs="12" sm="6" className="margin-bottom-small">
                <InputField
                  id="a2"
                  type="checkbox"
                  required
                />
                <Label inputId="a2">
                  Copiar la misma configuración para:
                </Label>
              </Col>
              <Col xs="12" sm="6" className="margin-bottom-small">
                <InputSelect
                  label="Full:"
                  name="dates2"
                  options={[
                    { value: 'abc', label: 'Los próximos 3 meses' },
                  ]}
                />
              </Col>
            </Row>
            <Button color="main" borderCorlor="main" textColor="white">Guardar</Button>
          </Card>
        </Modal>
        {/* end #modal-availability */}

      </PageWrapper>
    );
  }
}

export default Clinics;
