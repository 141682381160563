import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
	Heading,
  Icon,
  Container,
  Row,
  Col,
	Box,
  Breadcrumbs,
  PageWrapper,
	Card,
	InputField,
	Button,
	InputSelect
} from 'base-ui';

import DiscussionList from '../../../components/Forum/DiscussionList';

class ForumDiscussion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			initialItems: [
        {
          id: '1',
          author: 'Stiven Martínez',
          title: '¿Cómo puedo ver los videos?',
          stats: {
						answers: '456',
						votes: '276',
						visits: '1635',
					}
				},
				{
          id: '2',
          author: 'Stiven Martínez',
          title: 'Otro ejemplo',
          stats: {
						answers: '456',
						votes: '276',
						visits: '1635',
					}
				},
				{
          id: '1',
          author: 'Stiven Martínez',
          title: 'Un ejemplo más',
          stats: {
						answers: '456',
						votes: '276',
						visits: '1635',
					}
				},
			],
			items: []
		};
  }
  
  componentDidMount() {
    this.setState({items: this.state.initialItems})
	}
	render() {
		return(
			<PageWrapper config={{ title: 'Courses forum' }}>
				<Box>
					<Container fluid wrap>
						<Row className="center-xs">
							<Col xs="12">
								<Box>
									<Breadcrumbs className="margin-bottom-small">
										<Link to="."><Icon prefix="fas" name="home" /> Home</Link>
										<Link to=".">Cursos</Link>
										<Link to=".">Mindfulness para la vida cotidiana</Link>
										<Box tag="span">Foro</Box>
									</Breadcrumbs>
									<hr />
								</Box>
							</Col>
							<Col xs="12" md="10">
								<Card title="Módulos del curso">
									<Heading type="h3">
										Título del módulo
									</Heading>
									<Row>
										<Col xs="12" sm="6" className="center-xs start-sm">
											<InputField placeholder="Buscar por nombre..." color="white" full="false" borderColor="grey" textColor="text" type="text" className="without-shadow" />
											<Button tag="button" color="main" borderColor="main" textColor="white">
												<Icon prefix="fas" name="search" />
											</Button>
										</Col>
										<Col xs="12" sm="6" className="center-xs end-sm">
											<InputSelect
												full="false"
												className="without-shadow"
												options={[
													{ value: 'all', label: 'Más recientes' },
												]}
											/>
										</Col>
									</Row>
									<hr />
									<Row className="center-xs end-md margin-bottom-small">
										<Col className="center-xs end-sm" xs="12" sm="6" md="4">
											<Button tag="button" color="main" borderColor="main" textColor="white">
												Crear nueva discusión
											</Button>
										</Col>
									</Row>
									<DiscussionList
										items={this.state.items}
									/>
								</Card>
							</Col>
						</Row>
					</Container>
				</Box>
			</PageWrapper>
		)
	}
};

export default ForumDiscussion;
