/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Paragraph,
  Heading,
  Row,
  Col,
	Box,
} from 'base-ui';

const ForumList = ({ items, className }) => {
  return (
    <Box className={`${className}`}>
      {items.map((items) =>
        <Box className="content-forum" key={items._id}>
          <Link to="forum/discussions">
            <Row className="middle-xs between-md">
              <Col xs="12" sm="10" md="8">
                <Paragraph className="center-xs start-sm normal-font without-margin-bottom">{items.module}</Paragraph>
                <Heading size="medium" type="h2" className="center-xs start-sm main-color without-margin-top">{items.title}</Heading>
              </Col>
              <Col xs="12" sm="2" md="2" className="align-center">
                <Paragraph className="font-size-medium without-margin"><strong>{items.stats.discussionNumber}</strong></Paragraph>
                <Box tag="span" className="font-size-small normal-font">Discusiones</Box>
              </Col>
            </Row>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default ForumList;
