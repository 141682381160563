import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Heading,
  Image,
  NavLink,
  Icon,
  Button
} from 'base-ui';

// Components
import MenuConsultations from '../../../components/Consultation/MenuConsultations';

const Consults = () => (
  <PageWrapper config={{ title: 'Consultation Consults', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section" className="consultation-consults with-thumbs">
        <Container container fluid wrap>
          <Row className="center-xs">
            <Col xs="12" sm="4">
              <MenuConsultations />
            </Col>
            <Col xs="12" sm="8">

              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Consultas publicadas</Box>
                </Box>
                <Row>
                  <Col xs="12" sm="5">
                    <Box className="thumb">
                      <Image src="./img/dummy/service-1.png" alt="img service" />
                    </Box>
                  </Col>
                  <Col xs="12" sm="7">
                    <NavLink to="single">
                      <Heading type="h2" className="title">Trastorno del pánico</Heading>
                    </NavLink>
                    <Box className="metas">
                      <Box tag="span" className="meta">
                        <strong>75€</strong>
                        /Consulta
                      </Box>
                      <br />
                      <Box className="meta">
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        (1 valoracion)
                      </Box>
                    </Box>
                    <Button to="#" border="grey-border">
                      <Icon prefix="far" name="calendar-alt" className="regent-grey-color" />
                      Gestionar disponibilidad
                    </Button>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="12" sm="5">
                    <Box className="thumb">
                      <Image src="./img/dummy/service-2.png" alt="img service" />
                    </Box>
                  </Col>
                  <Col xs="12" sm="7">
                    <NavLink to="single">
                      <Heading type="h2" className="title">Mindfulness para la vida</Heading>
                    </NavLink>
                    <Box className="metas">
                      <Box tag="span" className="meta">
                        <strong>75€</strong>
                        /Consulta
                      </Box>
                      <br />
                      <Box className="meta">
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        <Icon prefix="fas" name="star" className="yellow-color" />
                        (1 valoracion)
                      </Box>
                    </Box>
                    <Button to="#" border="grey" textColor="grey">
                      <Icon prefix="far" name="calendar-alt" className="regent-grey-color" />
                      Gestionar disponibilidad
                    </Button>
                  </Col>
                </Row>
              </Card>
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Consultas pendientes</Box>
                </Box>
                <Row>
                  <Col xs="12" sm="5">
                    <Box className="thumb">
                      <Image src="./img/dummy/service-3.png" alt="img service" />
                    </Box>
                  </Col>
                  <Col xs="12" sm="7">
                    <NavLink to="single">
                      <Heading type="h2" className="title">Ser feliz en pareja</Heading>
                    </NavLink>
                    <Box className="metas">
                      <Box tag="span" className="meta">
                        Última actualización el 6 de Febrero de 2018
                      </Box>
                      <Box tag="span" className="meta">
                        <strong>75€</strong>
                        /Consulta
                      </Box>
                      <br />
                    </Box>
                    <Button to="#" borderColor="main" color="main" textColor="white">
                      Continuar
                    </Button>
                  </Col>
                </Row>
              </Card>

            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default Consults;
