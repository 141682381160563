import React from 'react';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Card,
  Tabs,
  Paragraph
} from 'base-ui';

// Components
import MenuAccount from '../../../components/Consultation/MenuAccount';

const AccountSecurity = () => (
  <PageWrapper config={{ title: 'Consultation Accout Payment History', bodyClasses: 'consultation' }}>

    <Box tag="main" id="site-main">

      <Box tag="section" className="consultation-account">
        <Container container fluid wrap>
          <Row className="center-xs">
            <Col xs="12" sm="4">
              <MenuAccount />
            </Col>
            <Col xs="12" sm="8">
              <Card>
                <Box className="box-title grey-bg">
                  <Box tag="span" className="label main-font">Historial de pagos</Box>
                </Box>
                <Tabs className="tabs-history">
                  <Box label="Transacciones">
                    <table className="full">
                      <tr>
                        <th>Fecha</th>
                        <th>Tipo</th>
                        <th>Información</th>
                        <th>Cantidad</th>
                        <th>Pagado</th>
                      </tr>
                      <tr>
                        <td>17 de Feb, 2017</td>
                        <td>Ejemplo</td>
                        <td>Lorem ipsum dolor sit amet</td>
                        <td>1</td>
                        <td>$500</td>
                      </tr>
                      <tr>
                        <td>17 de Feb, 2017</td>
                        <td>Ejemplo</td>
                        <td>Lorem ipsum dolor sit amet</td>
                        <td>1</td>
                        <td>$500</td>
                      </tr>
                      <tr>
                        <td>17 de Feb, 2017</td>
                        <td>Ejemplo</td>
                        <td>Lorem ipsum dolor sit amet</td>
                        <td>1</td>
                        <td>$500</td>
                      </tr>
                    </table>
                  </Box>
                  <Box label="Futuras transacciones">
                    <table className="full">
                      <tr>
                        <th>Fecha</th>
                        <th>Tipo</th>
                        <th>Información</th>
                        <th>Pagar a</th>
                        <th>Cantidad</th>
                      </tr>
                      <tr>
                        <td colSpan="5" className="center">
                          <Paragraph className="font-big align-center">Sin transacciones</Paragraph>
                        </td>
                      </tr>
                    </table>
                  </Box>
                  <Box label="Ingresos brutos">
                    <table className="full">
                      <tr>
                        <th>Fecha</th>
                        <th>Tipo</th>
                        <th>Información</th>
                        <th>Pagar a</th>
                        <th>Cantidad</th>
                      </tr>
                      <tr>
                        <td colSpan="5" className="center">
                          <Paragraph className="font-big align-center">Sin transacciones</Paragraph>
                        </td>
                      </tr>
                    </table>
                  </Box>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Container>
      </Box>

    </Box>
    {/* end main section */}

  </PageWrapper>
);

export default AccountSecurity;
