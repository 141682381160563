import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Heading,
  Paragraph,
  Icon,
  Image,
  NavLink
} from 'base-ui';

class BlogWidgetPosts extends React.PureComponent {
  render() {
    const {
      styleGroup, nrPosts, title
    } = this.props;

    // eslint-disable-next-line prefer-spread
    const arrayPosts = Array.apply(null, { length: nrPosts }).map(Number.call, Number);
    const contentPosts = arrayPosts.map((value) => (
      <Box key={value} tag="article" className="content-post news">
        <NavLink to="/single">
          <Box className="thumb">
            <Box tag="span" className="favorite">
              <Icon prefix="far" name="bookmark" />
            </Box>
            <Box className="thumb-wrap">
              <Image alt="dummy" src={`./img/dummy/post-${value + 1}.png`} className="thumb-bg" />
            </Box>
          </Box>
        </NavLink>
        <Box className="caption">
          <Heading type="h3" className="title">
            <NavLink to="/single">
              10 maneras de comenzar una rutina diaria y no morir en el intento
            </NavLink>
          </Heading>
          <Paragraph className="excerpt regent-grey">Etiam at tristique ipsum, sed gravida lorem. Donec nec malesuada nisi. Cras volutpat lobortis tempor nec malesuada nisi sed gravida lorem at tristique ipsum volutpat lobortis...</Paragraph>
        </Box>
      </Box>
    ));

    return (
      <Box tag="section" id="featured-posts-widget" className={`widget posts-style-${styleGroup}`}>

        <Box className="section-title main-border">
          <NavLink to="#">
            <Heading type="h2" className="label text-uppercase titles-color font-normal-small">{title}</Heading>
            <Icon prefix="fas" name="chevron-right" />
          </NavLink>
        </Box>

        <Box className="posts-row">
          {contentPosts}
        </Box>
      </Box>
    );
  }
}

BlogWidgetPosts.defaultProps = {
  styleGroup: '1',
  title: 'Title',
  nrPosts: 1
};

BlogWidgetPosts.propTypes = {
  styleGroup: PropTypes.string,
  title: PropTypes.string,
  nrPosts: PropTypes.number
};

export default BlogWidgetPosts;
