import React from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Box,
  PageWrapper,
  Col,
  Heading
} from 'base-ui';

const StepOne = () => (
  <PageWrapper>
    <Box className="learn-steps">
      <Box className="learn-steps-progress">
        <Box className="item active main-bg"></Box>
        <Box className="item"></Box>
        <Box className="item"></Box>
        <Box className="item"></Box>
        <Box className="item"></Box>
      </Box>
      <Container fluid wrap>
        <Row className="special-row middle-xs center-xs">
          <Col xs="12" md="11" className="align-center">
            <Heading type="h2" size="big">¿Qué quieres aprender?</Heading>
            <Box className="center-xs margin-top-medium">
              <Link to="/courses/learn/two" className="btn big main-border main-color category-btn">Coaching</Link>
              <Link to="/courses/learn/two" className="btn big main-border main-color category-btn">Desarrollo de aptitudes</Link>
              <Link to="/courses/learn/two" className="btn big main-border main-color category-btn">Educación</Link>
              <Link to="/courses/learn/two" className="btn big main-border main-color category-btn">Relaciones</Link>
              <Link to="/courses/learn/two" className="btn big main-border main-color category-btn">Salud y Bienestar</Link>
              <Link to="/courses/learn/two" className="btn big main-border main-color category-btn">Meditación</Link>
              <Link to="/courses/learn/two" className="btn big main-border main-color category-btn">Neurociencias</Link>
              <Link to="/courses/learn/two" className="btn big main-border main-color category-btn">Autoconocimiento</Link>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  </PageWrapper>
);

export default StepOne;
