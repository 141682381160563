import React, { Component } from 'react';
import {
  Row,
  Col,
  Paragraph,
  Button,
  Container,
  Modal,
  Box,
  Heading,
  InputField,
  Image,
  InputSelect,
} from 'base-ui';
import { NavLink } from 'react-router-dom';

class UnycosAccountPreferences extends Component {
  state = {
    sectionTitle: 'Preferencias de pago',
    modalState: false,
    address: {
      country: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: ''
    }
  }

  formInputHandler(key, value) {
    const { address } = this.state;

    address[key] = value;
    this.setState({ address });
  }

  openModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalState: true });
  }

  closeModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalState: false });
  }

  render() {
    const { sectionTitle, modalState, address } = this.state;
    const {
      country, address1, address2, city, state, zipcode
    } = address;

    return (
      <section id="account-preferences" className="fade-exit-active">
        <span className="box-title">{sectionTitle}</span>

        <Container fluid>
          <Paragraph className="text-gray">Configura la forma en que podremos contactarte el equipo</Paragraph>
          <Row>
            <Col xs="12">
              <div className="table-container">
                <table className="history-table" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Método</th>
                      <th>Detalles</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Wester Union
                        <span className="badge">Predeterminado</span>
                      </td>
                      <td>Stiven Martínez</td>
                      <td><span className="status accept">Activo</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col xs="12">
              <br />
              <Button onClick={(e) => this.openModal(e)} className="orange square hover-effect">Agregar metodo de pago</Button>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modalState}
          appElement="#root"
          idModal="modal-payment-method"
          className="modal-unycos"
          closeIcon={false}
        >
          <Box className="modal-header">
            <NavLink to="#" onClick={(e) => this.closeModal(e)} id="bt-close-modal">
              <Image src="./img/unycos/icons/icon_times.svg" alt="Icon Times" />
            </NavLink>
            <Heading type="h2">Agregar método de pago</Heading>
          </Box>
          <Box className="modal-content">
            <form>
              <InputSelect
                id="preferenteces-pais"
                name="preferenteces-pais"
                label="País"
                full
                big
                required
                value={country}
                onChange={(v) => this.formInputHandler('country', v)}
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                options={[
                  { label: 'País', value: 'pais' },
                  { label: 'España', value: 'españa' },
                  { label: 'Brazil', value: 'brazil' },
                  { label: 'United States', value: 'united-states' },
                ]}
              />
              <InputField
                id="preferences-addres-1"
                name="preferences-addres-1"
                type="text"
                label="Linea de dirección 1"
                full
                big
                required
                value={address1}
                placeholder="Linea de dirección 1"
                onChange={(v) => this.formInputHandler('address1', v)}
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
              />
              <InputField
                id="preferences-addres-2"
                name="preferences-addres-2"
                type="text"
                label="Linea de dirección 2"
                full
                big
                required
                value={address2}
                placeholder="Linea de dirección 2"
                onChange={(v) => this.formInputHandler('address2', v)}
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
              />
              <InputField
                id="preferences-city"
                name="preferences-city"
                type="text"
                label="Ciudad"
                full
                big
                required
                value={city}
                placeholder="Ciudad"
                onChange={(v) => this.formInputHandler('city', v)}
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
              />
              <InputSelect
                id="preferenteces-state"
                name="preferenteces-state"
                label="Estado/Província"
                full
                big
                required
                value={state}
                onChange={(v) => this.formInputHandler('state', v)}
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                options={[
                  { label: 'Estado/Provincia', value: '' },
                  { label: 'Salamarca', value: 'salamarca' }
                ]}
              />
              <InputField
                id="preferences-zipcode"
                name="preferences-zipcode"
                type="text"
                label="Código Postal"
                full
                big
                required
                value={zipcode}
                placeholder="Código Postal"
                onChange={(v) => this.formInputHandler('zipcode', v)}
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
              />
            </form>
            <Button className="bt-cta">
              <span>Siguiente</span>
            </Button>
          </Box>
        </Modal>
      </section>
    );
  }
}

export default UnycosAccountPreferences;
