import React, {
  useState, useEffect, useLayoutEffect
} from 'react';
import throttle from 'lodash/throttle';
import cs from 'classnames';
import PropTypes from 'prop-types';

const SocialBar = ({ bookmarked }) => {
  const [show, setShow] = useState(true);
  const [maxY, setMaxY] = useState(0);
  const [iniY, setIniY] = useState(0);
  const [lastY, setLastY] = useState(0);

  useEffect(() => {
    function setCoords() {
      setMaxY(document.getElementsByClassName('post-author-box')[0].offsetTop);
      setIniY(window.screen.height);
      window.removeEventListener('load', setCoords);
    }
    window.addEventListener('load', setCoords);

    // function animate show and hide tag
    let tagClose = true;
    function animeTag(element, action) {
      const workElement = element.getElementsByClassName('tag-alert-copied')[0];
      if (tagClose) {
        element.classList.add('show-hide-tag');
        setTimeout(() => {
          tagClose = false;
          workElement.classList.remove(action);
          animeTag(element, 'fadeOut');
        }, 1500);
      }
      workElement.classList.add('animated', action);
      if (!tagClose) {
        setTimeout(() => {
          tagClose = true;
          element.classList.remove('show-hide-tag');
          workElement.classList.remove(action);
        }, 500);
      }
    } // end animateTag()
    // copy url to clipboard
    function copyURL(targetElement) {
      const copyText = targetElement.href;
      document.addEventListener('copy', (e) => {
        e.clipboardData.setData('text/plain', copyText);
        e.preventDefault();
      }, true);
      document.execCommand('copy');
    }
    // listener to share link button
    const targetElement = document.getElementsByClassName('tag-copied-share')[0];
    targetElement.addEventListener('click', (e) => {
      e.preventDefault();
      animeTag(targetElement, 'fadeIn');
      copyURL(targetElement);
    });
  });

  useLayoutEffect(() => {
    const handleScroll = throttle(() => {
      const actualY = window.scrollY;
      if (actualY > maxY || actualY < lastY) {
        setShow(false);
      } else if (actualY > (iniY / 2) && !show) {
        setShow(true);
      }
      setLastY(actualY);
      // console.log('atual: '+actualY+'  iniY: '+iniY+'   maxY: '+maxY+'   lastY: '+lastY+'   show: '+show)
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [maxY, show, iniY, lastY]);

  return (
    <React.Fragment>
      <div
        className={cs('share floating', {
          'is-active': show
        })}
      >
        <div className="buttons">
          <a href="." className={bookmarked ? 'pink-bg share-btn white-color' : 'regent-grey-bg share-btn dark-grey-color'}>
            <i className={bookmarked ? 'fas fa-bookmark' : 'far fa-bookmark'}></i>
          </a>
          <a href="." className="facebook-bg share-btn white-color"><i className="fab fa-facebook"></i></a>
          <a href="." className="twitter-bg share-btn white-color"><i className="fab fa-twitter"></i></a>
          <a href="." className="pinterest-bg share-btn white-color"><i className="fab fa-pinterest"></i></a>
          <a href="." className="whatsapp-bg share-btn white-color"><i className="fab fa-whatsapp"></i></a>
          <a href="https://mcontigo.com/" className="titles-bg share-btn tag-copied tag-copied-share white-color hidden-lg">
            <i className="fas fa-link"></i>
            <span className="tag-alert-copied">
              Copy!
            </span>
          </a>
          <a href="." className="mediumgray-bg share-btn white-color"><i className="fas fa-share-alt"></i></a>
          <a href="." className="grey-bg next-article titles-color hidden-lg">
            Next
            <i className="fas fa-chevron-right"></i>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

SocialBar.defaultProps = {
  bookmarked: false
};

SocialBar.propTypes = {
  bookmarked: PropTypes.bool
};

export default SocialBar;
