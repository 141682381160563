import React, { useState, useEffect } from 'react';

import {
  Container
} from 'base-ui';

import BlogMenu from '../../components/Blog/BlogMenu';
import BlogSingleContent from '../../components/Blog/BlogSingleContent';

// Sidebars
import BlogComparatorSidebar from '../../components/Blog/BlogComparatorSidebar';
import BlogInterestingSidebar from '../../components/Blog/BlogInterestingSidebar';

// SocialBar
import SocialBar from '../../components/SocialBar/SocialBar';

const BlogSingle = () => {
  const [showAdvertising, setShowAdvertising] = useState(false);
  const [bookmarked] = useState(false);

  useEffect(() => {
    function getPositions() {
      setShowAdvertising(true);
      window.removeEventListener('load', getPositions);
    }
    window.addEventListener('load', getPositions);
  });

  return (
    <React.Fragment>
      <BlogMenu />
      <div id="wrapper">
        <div className="ad-space header">
          <div className={`ad-slot large fadeIn ${showAdvertising ? 'open' : 'close'}`}>
            <img alt="dummy" src="https://via.placeholder.com/980x250?Text=Banner+980x250" />
          </div>
        </div>
        <Container fluid wrap className="container-single">
          <main id="site-main">
            <div className="row">
              <div className="col-xs-12 col-md-8">
                <BlogSingleContent bookmarked={bookmarked} />
              </div>
              <aside id="sidebar" className="col-xs-12 col-md-4">
                <div className="ad-space">
                  <img alt="dummy" src="https://via.placeholder.com/300x250?Text=Banner+300x250" />
                </div>
                <BlogComparatorSidebar />
                <BlogInterestingSidebar />
              </aside>
            </div>
          </main>
        </Container>
      </div>
      <SocialBar bookmarked={bookmarked} />
    </React.Fragment>
  );
};

export default BlogSingle;
