import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import creditCardType from 'credit-card-type';
import {
  Row,
  Col,
  Image,
  Paragraph,
  Button,
  Container,
  Box,
  Heading,
  Modal,
  Icon,
  InputField,
} from 'base-ui';

class UnycosAccountMethods extends Component {
  state = {
    sectionTitle: 'Métodos de pago',
    modalState: false,
    payment: {
      method: 'credit-card',
      card: {
        name: '',
        number: '',
        date: '',
        cvv: '',
        type: ''
      }
    },
  }

  maskInput = (value, type) => {
    let v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    let matches;
    let match;
    let parts = '';

    const { payment: { card } } = this.state;

    switch (type) {
      case 'cardNumber':
        matches = v.match(/\d{4,16}/g);
        // eslint-disable-next-line
        match = matches && matches[0] || '';
        parts = [];

        for (let i = 0; i < match.length; i += 4) {
          parts.push(match.substring(i, i + 4));
        }

        if (parts.length) {
          card.number = parts.join(' ');
        } else {
          card.number = value;
        }

        if (creditCardType(v)[0] && v.length > 2) {
          card.type = creditCardType(v)[0].type;
        } else {
          card.type = '';
        }

        break;
      case 'cardDate':
        v = v.replace(/^(\d\d)/g, '$1 / ');
        card.date = v.substring(0, 7);
        break;
      case 'cardCVV':
        card.cvv = v.substring(0, 3);
        break;
      default:
        card.name = value;
        break;
    }

    this.setCreditCardInfo(card, 'credit-card');
  };

  setCreditCardInfo = (card, method) => {
    if (method === 'credit-card') {
      const { payment } = this.state;

      payment.card = card;
      this.setState({
        payment
      });
    }
  }

  openModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalState: true });
  }

  closeModal(e) {
    if (e) e.preventDefault();

    this.setState({ modalState: false });
  }

  render() {
    const { sectionTitle, modalState, payment } = this.state;
    const {
      name, number, date, cvv, type
    } = payment.card;

    return (
      <section id="account-methods" className="fade-exit-active">
        <span className="box-title">{sectionTitle}</span>

        <Container fluid>
          <Row>
            <Col xs="12" sm="6">
              <div className="payment-method">
                <Image src="./assets/images/paypal-mini.png" alt="paypal"></Image>
                <Paragraph>
                  <strong>Paypal</strong>
                  <br />
                  Pagos@stivmartinez.com
                </Paragraph>
                <strong>Predeterminada</strong>
                <br />
                <small className="text-gray">No puede eliminar el predeterminado</small>
              </div>
            </Col>
            <Col xs="12" sm="6">
              <div className="payment-method">
                <Image src="./img/credit-flags/mastercard.png" alt="Mastercard"></Image>
                <Paragraph>
                  <strong>XXXXXXXXXXXX4233</strong>
                  <br />
                  06 / 2021
                </Paragraph>
                <Link to="#">Predeterminada</Link>
                <br />
                <Link to="#">Elminar</Link>
              </div>
            </Col>
            <Col xs="12">
              <Button className="orange square hover-effect" onClick={(e) => this.openModal(e)}>Añadir metodo de pago</Button>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modalState}
          appElement="#root"
          idModal="modal-payment-method"
          className="modal-unycos fade-in"
          closeIcon={false}
        >
          <Box className="modal-header">
            <NavLink to="#" onClick={(e) => this.closeModal(e)} id="bt-close-modal"><Image src="./img/unycos/icons/icon_times.svg" alt="Icon Times" /></NavLink>
            <Heading type="h2">Añadir metodo de pago</Heading>
          </Box>
          <Box className="modal-content">
            {type !== 'mastercard' ? (
              <Icon name={`cc-${type}`} prefix="fab" color="white" size="medium" className="credit-type position-absolute" />
            ) : (
              <Image src="./img/credit-flags/transparent/mastercard.png" alt="mastercard logo" className="credit-type position-absolute" />
            )}
            <form>
              <Row>
                <Col xs="12">
                  <InputField
                    id="credit-name"
                    name="credit-name"
                    type="text"
                    label="Nombre del titular"
                    full
                    big
                    required
                    value={name}
                    placeholder="Ex: Sergio Ramos Torres"
                    onChange={(v) => this.maskInput(v)}
                    className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="12" className="position-relative">
                  <InputField
                    name="credit-number"
                    type="text"
                    label="Número de la Tarjeta"
                    full
                    big
                    required
                    value={number}
                    placeholder="0000 0000 0000 0000"
                    onChange={(v) => this.maskInput(v, 'cardNumber')}
                    className="without-shadow rounded-small full text-uppercase letter-spacing__small"
                  />
                  {type !== 'mastercard' ? (
                    <Icon name={`cc-${type}`} prefix="fab" color="white" size="medium" className="credit-type position-absolute" />
                  ) : (
                    <Image src="./img/credit-flags/transparent/mastercard.png" alt="mastercard logo" className="credit-type position-absolute" />
                  )}
                </Col>
                <Col xs="6">
                  <InputField
                    name="credit-date"
                    type="text"
                    label="Fecha de Cad."
                    full
                    big
                    required
                    value={date}
                    placeholder="00 / 00"
                    onChange={(v) => this.maskInput(v, 'cardDate')}
                    className="without-shadow rounded-small full text-uppercase letter-spacing__small regent-grey-bg"
                  />
                </Col>
                <Col xs="6">
                  <InputField
                    name="credit-cvv"
                    type="text"
                    label="CVV"
                    full
                    big
                    required
                    value={cvv}
                    placeholder="000"
                    onChange={(v) => this.maskInput(v, 'cardCVV')}
                    className="without-shadow rounded-small full text-uppercase letter-spacing__small"
                  />
                </Col>
              </Row>
            </form>
            <Button className="bt-cta">
              <span>Añadir metodo</span>
            </Button>
          </Box>
        </Modal>
      </section>
    );
  }
}

export default UnycosAccountMethods;
