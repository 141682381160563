import React, { Component } from 'react';

import {
  Box,
  Courses,
  NavLink
} from 'base-ui';

class FeaturedCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialItems: [
        {
          name: 'Introducción al coaching educativo',
          short_summary: 'Este curso pretende introducir de manera sencilla y práctica la técnica del mindfulness o de la atención plena, aprovechando así...',
          category: 'Salud y bienestar',
          price: '29.99',
          offer_price: '49.99',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-introduccion-al-coaching-educativo/img/coaching_educativo.jpg',
          instructors: [
            { displayName: 'Beatriz Ariza' }
          ],
          duration: '15 módulos - 7 horas',
          btnAction: (<NavLink>
            <span role='presentation'>
              Comprar
            </span>
          </NavLink>),
          label: (<>
            <Box tag='del' className='del-price regent-grey-color'>
              29.99 €
            </Box>
            <Box tag='span' className='price  red-color'>
              49.99 €
            </Box>
          </>)
        },
        {
          name: 'El poder de la mente',
          short_summary: 'Este curso pretende introducir de manera sencilla y práctica la técnica del mindfulness o de la atención plena, aprovechando así...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-el-poder-de-la-mente/img/el_poder_de_la_mente.jpg',
          category: 'Salud y bienestar',
          price: '29.99',
          offer_price: '49.99',
          instructors: [
            { displayName: 'Carlos Blanco' }
          ],
          duration: '15 módulos - 7 horas',
          btnAction: (<NavLink>
            <span role='presentation'>
              Comprar
            </span>
          </NavLink>),
          label: (<>
            <Box tag='del' className='del-price regent-grey-color'>
              29.99 €
            </Box>
            <Box tag='span' className='price  red-color'>
              49.99 €
            </Box>
          </>)
        },
        {
          name: 'Consígue que tus hijos sean felices, seguros y autónomos',
          short_summary: 'Este curso pretende introducir de manera sencilla y práctica la técnica del mindfulness o de la atención plena, aprovechando así...',
          image: 'https://content.lamenteesmaravillosa.com/courses/curso-consigue-que-tus-hijos-sean-felices-seguros-y-autonomos/img/familia-en-el-campo-haciendo-pompas.jpg',
          category: 'Salud y bienestar',
          price: '29.99',
          offer_price: '49.99',
          instructors: [
            { displayName: 'Centro de Psicología Álava Reyes' }
          ],
          duration: '15 módulos - 7 horas',
          btnAction: (<NavLink>
            <span role='presentation'>
              Comprar
            </span>
          </NavLink>),
          label: (<>
            <Box tag='del' className='del-price regent-grey-color'>
              29.99 €
            </Box>
            <Box tag='span' className='price  red-color'>
              49.99 €
            </Box>
          </>)
        },
      ],
      items: []
    };
  }

  componentDidMount() {
    this.setState({ items: this.state.initialItems });
  }

  render() {
    return (
      <Box>
        <Courses
          hasPrice
          bestSellerTag
          hasDesc
          hasModules
          items={this.state.items}
          locale="es"
        />
      </Box>
    );
  }
}

export default FeaturedCourses;
