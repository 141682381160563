/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

// Base-UI components
import {
  PageWrapper,
  Container,
  Heading,
  Paragraph,
  Row,
  Col,
  Box,
  Image,
  Button
} from 'base-ui';

// Blog Components
import BlogMenu from '../../../components/Blog/BlogMenu';


const BlogPaymentThanks = () => {
  const pageTitle = '¡Obrigado pela sua inscrição Premium!';
  const [categories, setCategories] = useState([
    {
      icon: './img/icons/self-knowledge.svg',
      text: 'Autoconhecimento'
    },
    {
      icon: './img/icons/skills.svg',
      text: 'Desarollo de Aptitudes'
    },
    {
      icon: './img/icons/wellness.svg',
      text: 'Salud y Bienestar'
    },
    {
      icon: './img/icons/education.svg',
      text: 'Educación'
    },
    {
      icon: './img/icons/relationship.svg',
      text: 'Relaciones'
    },
    {
      icon: './img/icons/coaching.svg',
      text: 'Coaching'
    },
    {
      icon: './img/icons/neuroscience.svg',
      text: 'Neurociencias'
    },
    {
      icon: './img/icons/meditation.svg',
      text: 'Meditación'
    }
  ]);

  return (
    <>
      <BlogMenu />
      <PageWrapper config={{ pageTitle }}>
        <Container fluid wrap>
          <Box tag="main" className="margin-top-x-big margin-bottom-x-big">
            <Row className="center-xs">
              <Col xs="12">
                <Box tag="section" className="subscription-thanks margin-bottom-medium">
                  <Image src="./img/subscribed.svg" alt="Subscribed Illustration" className="subscription-thanks__img" />
                  <Heading type="h1" className="font-size-big margin-bottom-medium center-xs">{pageTitle}</Heading>
                  <Paragraph>
                    Você acaba de adquirir a licença
                    {' '}
                    <strong>Premium</strong>
                    , aproveite acesso ilimitado ao nosso blog,
                    <br />
                    bookmark e acesse os artigos offline no nosso app.
                  </Paragraph>
                </Box>
                <Box tag="section" className="subscription-upgrade yellow-bg">
                  <Heading type="h1" className="font-size-big without-margin-bottom center-xs golden-dirty-color">Faça um upgrade para ultra por somente + 5 euro / mes</Heading>
                  <Paragraph className="golden-dirty-color bold-font">Ganhe acesso instantaneo a mais de 30 cursos do LMEM, em diversas categorias:</Paragraph>
                  <Box className="subscription-upgrade__categories margin-top-big">
                    {categories.map((category, index) => (
                      <Box className="subscription-upgrade__category golden-dirty-color white-bg text-uppercase bold-font font-size-x-small" key={category.name}>
                        <Image src={category.icon}></Image>
                        <Box tag="span">{category.text}</Box>
                      </Box>
                    ))}
                  </Box>
                  <Box className="subscription-upgrade__actions margin-top-medium">
                    <Button className="golden-bg golden-border big white-color subscription-upgrade__btn">
                      <Image src="./img/icons/star-ultra.svg" className="subscription-upgrade__btn__icon" />
                      <Box tag="span">Fazer upgrade para</Box>
                      <br />
                      <Box tag="span" className="golden-dirty-color">Inscrição Ultra (15€ / mes)</Box>
                    </Button>
                    <Button className="golden-border big golden-color white-bg subscription-upgrade__btn">
                      Não obrigado, vou continuar
                      <br />
                      como Premium por enquanto
                    </Button>
                  </Box>
                </Box>
                <Box tag="section" className="subscription-ultra-thanks yellow-light-bg margin-top-medium center-xs">
                  <Image src="./img/subscribed-ultra.svg" alt="Subscribed Illustration" className="subscription-ultra-thanks__img" />
                  <Heading type="h1" className="font-size-big golden-dirty-color margin-bottom-medium center-xs">{pageTitle}</Heading>
                  <Paragraph className="golden-dirty-color font-size-medium center-xs margin-bottom-big">
                    Você acaba de adquirir a licença
                    {' '}
                    <strong>Ultra</strong>
                    , aproveite acesso ilimitado ao nosso blog,
                    <br />
                    bookmark, acesse os artigos offline no nosso app e ainda tenha
                    <br />
                    <strong>acesso a mais de 30 cursos do LMEM</strong>
                  </Paragraph>
                  <Button className="golden-dirty-bg golden-dirty-border big white-color">Aproveite!</Button>
                </Box>
              </Col>
            </Row>
          </Box>
        </Container>
      </PageWrapper>
    </>
  );
};

export default BlogPaymentThanks;
