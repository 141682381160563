/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Heading,
  Image,
  Icon,
  NavLink
} from 'base-ui';

const ResultConsult = (props) => {
  const {
    data
  } = props;

  const stars = [];
  let typePrefix;
  for (let i = 0; i < 5; i++) {
    typePrefix = (data.stars <= i) ? 'far' : 'fas';
    stars.push(<Icon key={i} prefix={typePrefix} name="star" className="yellow-color" />);
  }

  return (
    <article className="content-consultations consultations">
      <NavLink to="single">
        <Box className="thumb">
          <Image src={data.thumb} alt={data.title} />
        </Box>
      </NavLink>
      <Box className="caption">
        <NavLink to="single">
          <Heading className="title">{data.title}</Heading>
        </NavLink>
        <Box className="metas">
          <Box tag="span" className="meta">
            <strong>{data.price}</strong>
            /
            {data.type}
          </Box>
          {data.opinions && (
            <small className="meta">
              {stars}
              {' '}
              (
              {data.opinions}
              )
            </small>
          )}
        </Box>
      </Box>
    </article>
  );
};

ResultConsult.propTypes = {
  data: PropTypes.shape({
    thumb: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.string,
    type: PropTypes.string,
    stars: PropTypes.number,
    opinions: PropTypes.string
  })
};

export default ResultConsult;
