import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Card,
  Button,
  Icon,
  Heading,
  Paragraph,
} from 'base-ui';

const LoadingScreen = (props) => {
  const {
    icon, title, subtitle, label, link, imagesColor
  } = props;
  return (
    <Card className="card-loading">
      <Row className="middle-xs center-xs">
        <Col xs="12" md="6" lg="7">
          <Icon prefix="fas" name={icon} className={`pulsate ${imagesColor}-color loading-icon-animate`} />
          <Heading type="h2" className="font-size-big mediumgray-color">{title}</Heading>
          <Paragraph className="font-size-normal mediumgray-color padding-normal padding-bottom-medium without-margin">{subtitle}</Paragraph>
          {label !== '' && (
            <Button href={link} size="normal" color={imagesColor} textColor="white" borderColor={imagesColor} className="display-block">{label}</Button>
          )}
        </Col>
        <Col xs="12" md="6" lg="5" className="align-center">
          <svg width="358" height="339" viewBox="0 0 358 339" fill="none" xmlns="http://www.w3.org/2000/svg" className="margin-top-x-medium margin-bottom-x-medium">
            <path d="M283.846 275.403C298.539 264.336 440.133 148.151 288.535 37.9685C136.937 -72.214 117.363 88.7491 41.6711 126.027C-34.0203 163.305 34.4309 311.9 120.189 325.931C196.629 338.438 252.7 298.86 283.846 275.403Z" fill="#EDEFF4" />
            <path d="M250.66 308.484C250.059 306.101 119.749 389.18 41.1973 287.034" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M36.2002 280.298C36.2002 280.298 -36.3466 171.818 26.8576 128.85" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M221.016 259.818C221.016 259.818 310.809 271.041 334.222 243.956C357.634 216.871 253.333 194.415 241.721 193.255C230.11 192.094 173.711 234.28 173.711 234.28L221.016 259.818Z" fill="white" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M228.952 221.693C228.952 221.693 263.205 209.653 296.682 213.931C296.682 213.931 304.811 212.308 268.235 209.803C231.659 207.298 232.435 203.61 232.435 203.61L228.952 221.693Z" fill="#374858" />
            <path d="M196.053 234.176C196.053 234.176 207.956 231.419 221.163 236.499C234.369 241.579 240.175 247.674 238.143 256.672C236.112 265.669 215.357 263.783 183.718 258.844L196.053 234.176Z" fill="#374858" />
            <path d="M127.549 104.55C127.549 104.55 136.063 105.132 141.803 102.744C147.544 100.356 139.631 132.675 130.461 135.12C121.292 137.564 94.2309 142.073 87.167 136.539C87.167 136.539 94.3026 130.251 93.5095 127.25C92.7164 124.249 127.549 104.55 127.549 104.55Z" fill="white" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M121.933 123.143C121.933 123.143 115.54 134.427 109.482 137.421C109.482 137.421 95.8433 138.547 92.4254 134.029C89.0075 129.512 108.196 113.42 108.196 113.42" fill="#374858" />
            <path d="M76.0721 105.971C76.0721 105.971 84.6353 125.079 96.9315 128.66C109.228 132.241 132.167 126.594 126.967 101.373C121.665 75.649 101.429 76.0727 101.429 76.0727L72.0154 94.1661C72.0154 94.1661 69.7839 100.261 76.0721 105.971Z" fill="white" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M88.5897 98.4312C93.622 104.817 103.067 89.4183 107.615 90.6763C112.163 91.9344 115.744 92.7079 118.742 87.6756C121.741 82.6433 124.631 93.5836 124.631 93.5836C124.631 93.5836 128.611 87.6756 125.128 82.8388C121.645 78.0021 126.098 80.0294 125.033 76.0639C123.968 72.0984 118.986 75.3403 117.969 72.0006C116.952 68.661 115.361 65.4213 111.098 66.147C106.835 66.8727 104.423 72.9198 100.697 69.1477C96.9704 65.3756 92.8115 68.6153 90.2476 72.0984C87.6836 75.5815 82.4579 66.9205 77.9101 70.9859C73.3623 75.0513 75.2657 87.7408 71.2655 88.095C67.2653 88.4492 62.6219 94.1594 67.2675 99.2873C67.2675 99.2873 69.7337 100.691 68.3322 102.964C66.9307 105.237 67.8476 107.657 69.3469 108.865C70.8462 110.073 71.863 110.169 71.0417 112.883C70.2204 115.597 70.7984 120.429 79.9917 121.057C79.9917 121.057 83.5704 121.687 85.7997 120.896C85.7997 120.896 82.975 119.058 84.1353 116.801C86.7797 111.705 80.6588 111.631 81.8126 106.805C82.9664 101.98 80.3285 102.642 79.2334 99.7437C78.1383 96.8451 83.5595 92.0517 88.5897 98.4312Z" fill="#374858" />
            <path d="M72.9867 140.272C72.9867 140.272 67.7501 143.314 59.6236 157.253C51.4971 171.192 37.5626 206.744 63.6868 224.016L91.8449 236.499L228.565 205.729C228.565 205.729 226.242 163.928 223.048 149.124C223.048 149.124 249.368 147.577 256.914 144.963C256.914 144.963 261.947 187.006 276.905 185.265C276.905 185.265 279.338 193.763 280.242 196.086C280.242 196.086 292.756 196.536 295.852 192.344C295.852 192.344 294.24 181.443 294.498 180.611C294.498 180.611 302.449 177.289 296.934 154.936C291.419 132.584 290.691 108.203 247.441 98.7681C204.19 89.3336 165.003 91.2218 137.862 101.525C137.862 101.525 146.425 118.65 125.67 131.641C104.915 144.633 88.5986 132.293 88.5986 132.293L72.9867 140.272Z" fill="white" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M95.1104 183.755C95.1104 183.755 155.359 188.655 222.005 146.684" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M95.1104 195.423C95.1104 195.423 166.402 210.112 224.061 159.247" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M139.159 213.931C139.159 213.931 117.389 210.518 84.5879 216.178C84.5879 216.178 74.7623 228.379 61.773 222.666C61.773 222.666 35.0469 240.266 59.2351 257.108C83.4233 273.95 196.633 260.785 196.633 260.785C196.633 260.785 208.63 238.144 196.633 226.145C184.637 214.147 139.159 213.931 139.159 213.931Z" fill="white" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M149.899 224.022C102.965 221.415 81.5388 226.152 81.5388 226.152C81.5388 226.152 74.2988 224.216 88.5223 219.572C99.6756 215.931 128.961 219.303 140.997 220.441C144.31 220.75 149.899 224.022 149.899 224.022Z" fill="#374858" />
            <path d="M281.57 196.099C281.57 196.099 282.169 200.01 281.394 203.052C280.618 206.094 289.781 215.114 299.391 218.727C299.391 218.727 302.94 219.307 302.101 216.989C301.262 214.67 296.682 213.054 295.522 210.344C294.361 207.635 306.723 211.055 306.723 211.055L312.887 213.697C312.887 213.697 312.731 209.731 310.519 207.313C307.031 203.5 300.941 199.895 297.894 198.217C295.35 196.816 294.807 193.361 294.807 193.361C290.564 194.892 286.126 195.814 281.624 196.099H281.57Z" fill="white" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M295.004 210.696C295.004 210.696 292.523 207.148 293.65 206.47C294.778 205.792 304.317 209.857 304.317 209.857C304.317 209.857 295.393 208.082 295.004 210.696Z" fill="#374858" />
            <path d="M240.232 163.059H143.362C140.72 163.059 138.579 165.2 138.579 167.841V221.363C138.579 224.004 140.72 226.145 143.362 226.145H240.232C242.873 226.145 245.014 224.004 245.014 221.363V167.841C245.014 165.2 242.873 163.059 240.232 163.059Z" fill="#38B5AA" className={`${imagesColor}-fill`} />
            <path d="M191.796 200.116C194.388 200.116 196.489 198.015 196.489 195.423C196.489 192.831 194.388 190.73 191.796 190.73C189.204 190.73 187.103 192.831 187.103 195.423C187.103 198.015 189.204 200.116 191.796 200.116Z" fill="white" />
            <path d="M84.5877 216.178C91.845 205.292 95.037 193.102 96.1451 178.588C97.2533 164.073 85.4981 162.574 85.4981 162.574C85.4981 162.574 86.0783 148.93 85.1114 149.656C84.1444 150.382 73.065 150.334 71.4224 148.93C69.7797 147.527 69.1952 148.93 69.1952 148.93V163.054C65.2841 162.763 61.5033 163.489 57.1489 182.647C52.7945 201.805 64.4062 213.936 64.4062 213.936" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M219.47 131.756L222.421 149.124C222.421 149.124 243.393 147.434 246.598 146.971C249.803 146.508 231.564 144.887 227.21 139.062C223.292 133.819 218.599 126.048 219.47 131.756Z" fill="#374858" />
            <path d="M276.491 184.733C276.491 184.733 280.459 184.248 282.701 183.764C284.222 183.435 279.459 191.747 279.798 195.23L276.491 184.733Z" fill="#374858" />
            <path d="M294.498 180.604C294.498 180.604 292.58 182.282 288.758 182.862" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M255.27 137.514C255.27 137.514 256.914 141.286 256.914 144.963" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M96.1453 177.862C96.1453 177.862 99.3916 184.539 97.2144 196.44C95.0372 208.341 105.632 213.931 105.632 213.931L84.5879 216.178C84.5879 216.178 95.5217 199.777 96.1453 177.862Z" fill="#374858" />
            <path d="M72.2305 149.326C72.2305 149.326 72.6868 126.869 76.6522 124.936C80.6177 123.002 90.0914 126.696 92.3229 129.105C94.5544 131.515 93.972 135.578 87.6838 137.901C87.6838 137.901 87.1797 148.872 81.7498 149.995C75.3637 151.318 72.2305 149.326 72.2305 149.326Z" fill="white" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M87.167 137.514C87.8536 133.638 87.167 131.191 87.167 131.191C87.167 131.191 89.2073 131.998 89.8852 131.78C90.5631 131.563 88.336 132.893 88.3642 133.642C88.3925 134.392 87.984 137.788 87.984 137.788L87.167 137.514Z" fill="#374858" />
            <path d="M69.2012 163.059C69.2012 163.059 74.6855 163.993 76.3955 163.478" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M85.4935 163.182C85.4935 163.182 81.9453 163.541 80.9132 163.182C79.8811 162.824 85.6761 154.178 85.6761 154.178L85.4935 163.182Z" fill="#374858" />
            <path d="M142.919 99.7351C142.919 99.7351 156.276 146.073 93.5088 142.299" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M141.603 66.4106C141.603 66.4106 161.349 54.562 157.104 80.0365" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M171.807 70.0674C171.807 70.0674 147.002 64.1247 164.836 49.4253" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M154.329 42.0461C154.329 42.0461 160.547 66.7841 139.269 57.7776" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M95.0199 113.534C95.6501 113.083 95.7445 112.136 95.2308 111.419C94.7171 110.702 93.7898 110.486 93.1596 110.938C92.5294 111.389 92.435 112.337 92.9487 113.054C93.4624 113.771 94.3897 113.986 95.0199 113.534Z" fill="#374858" />
            <path d="M113.648 103.181C114.278 102.729 114.372 101.782 113.859 101.065C113.345 100.348 112.418 100.133 111.788 100.584C111.157 101.036 111.063 101.983 111.577 102.7C112.09 103.417 113.018 103.632 113.648 103.181Z" fill="#374858" />
            <path d="M94.6937 98.4312C94.6937 98.4312 95.1565 103.646 88.9639 106.794" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M114.151 96.8364C114.151 96.8364 110.24 100.313 104.021 97.2319" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M102.134 107.711C102.134 107.711 103.803 110.788 103.722 112.218C103.535 115.532 104.778 118.085 108.972 114.074" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
            <path d="M110.618 121.131C110.618 121.131 111.715 117.003 114.812 116.486" stroke="#374858" strokeWidth="0.85" sstrokeMiterlimit="10" />
          </svg>
        </Col>
      </Row>
    </Card>
  );
};

LoadingScreen.defaultProps = {
  icon: 'money-bill-wave',
  title: 'Estamos procesando tu pago',
  subtitle: 'Esta operación puede tardar varios minutos, pero normalmente es cuestión de segundos si todo esta bien con tu pago.',
  label: '',
  link: '',
  imagesColor: 'main'
};

LoadingScreen.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  imagesColor: PropTypes.string,
};
export default LoadingScreen;
