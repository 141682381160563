import React from 'react';

import {
  Box,
  Col,
  Row,
  Heading,
  Icon,
  Paragraph
} from 'base-ui';
import ReadMore from './ReadMore';

const CourseContentDesktop = () => (
  <Box className="margin-top-x-big margin-bottom-x-big hidden-md">
    <Box className="section-title thin-border without-margin-bottom font-size-normal">
      <Box tag="span" className="label line-height-small without-margin text-uppercase titles-color">
        Contenido del curso
      </Box>
    </Box>
    <Box>
      <ReadMore
        maxHeightvalue="700px"
        excerpt={(
          <Box>
            <Row>
              <Col xs="1">
                <Box tag="span" className="font-size-x-medium titles-color bold-font">01</Box>
              </Col>
              <Col xs="11">
                <Heading type="h4" size="normal" className="bold-font main-font text-uppercase">Introducción</Heading>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Sugerencias de uso de los materiales del curso
                  </Paragraph>
                </Box>
              </Col>
              <Col xs="12">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Box tag="span" className="font-size-x-medium titles-color bold-font">02</Box>
              </Col>
              <Col xs="11">
                <Heading type="h4" size="normal" className="bold-font main-font text-uppercase">Consolidación de la personalidad</Heading>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    ¿Cómo cambiar su personalidad, su vida y su palabra?
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    ¿Hablar o comunicar? Esa es la cuestión
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Dominar la palabra: La otra carrera necesaria
                  </Paragraph>
                </Box>
              </Col>
              <Col xs="12">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Box tag="span" className="font-size-x-medium titles-color bold-font">03</Box>
              </Col>
              <Col xs="11">
                <Heading type="h4" size="normal" className="bold-font main-font text-uppercase">Comunicación, y la atención como requisito imprescindible</Heading>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Economía de la palabra, y singularidad del comunicador
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    El silencio, formidable trampolín para dominar la palabra. ¿Hoy mismo, orador?
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    ¿Cómo mantener las tres atenciones, y al oyente siempre despierto?
                  </Paragraph>
                </Box>
              </Col>
              <Col xs="12">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Box tag="span" className="font-size-x-medium titles-color bold-font">04</Box>
              </Col>
              <Col xs="11">
                <Heading type="h4" size="normal" className="bold-font main-font text-uppercase">Las reglas de oro de la Comunicación</Heading>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Taller de la palabra, y 3 Reglas de Oro
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Maestros de la palabra, y las otras 4 Reglas de Oro
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    La automatización de las 3 + 4 Reglas de Oro
                  </Paragraph>
                </Box>
              </Col>
              <Col xs="12">
                <hr />
              </Col>
            </Row>
            <Row>
              <Col xs="1">
                <Box tag="span" className="font-size-x-medium titles-color bold-font">05</Box>
              </Col>
              <Col xs="11">
                <Heading type="h4" size="normal" className="bold-font main-font text-uppercase">El libre pensamiento, la dignidad personal, y el poder</Heading>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Los prejuicios y el miedo del poder al libre pensamiento
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    ¿Es usted libre? Los tres grandes intereses o amores básicos para comunicar
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Las áreas de aceptación personal
                  </Paragraph>
                </Box>
              </Col>
              <Col xs="12">
                <hr />
              </Col>
            </Row>
          </Box>
        )}
        fullText={(
          <Box>
            <Row>
              <Col xs="1">
                <Box tag="span" className="font-size-x-medium titles-color bold-font">06</Box>
              </Col>
              <Col xs="11">
                <Heading type="h4" size="normal" className="bold-font main-font text-uppercase">Los otros dos grandes intereses para comunicar</Heading>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Amor al destinatario y al mensaje
                  </Paragraph>
                </Box>
                <Box>
                  <Paragraph textColor="text">
                    <Icon prefix="fas" name="video" />
                    Nadie es más que yo; nadie, menos que yo
                  </Paragraph>
                </Box>
              </Col>
            </Row>
          </Box>
        )}
      />
    </Box>
  </Box>
);

export default CourseContentDesktop;
