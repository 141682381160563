import React, { Component } from 'react';
import { NavLink } from 'base-ui';

class BlogFeaturedPosts extends Component {

	render() {
		return(
			<section id="featured-videos-widget" className="widget">
        <ul data-tabs className="tabs">
          <li className="active">
            <a data-tab href="#videos-mas-vistos">
              <h2 className="label text-uppercase">Videos más vistos</h2>
            </a>
          </li>
          <li>
            <a data-tab href="#videos-seleccion-editores">
              <h2 className="label text-uppercase">Selección de los editores</h2>
            </a>
          </li>
        </ul>
        <div data-tabs-content className="horizontal-scroll-sm">
          <div data-tabs-pane className="tabs-pane active align-center" id="videos-mas-vistos">
            <article className="content-post videos">
              <NavLink to="/single">
                <div className="thumb">
                  <span className="favorite">
                    <i className="far fa-heart"></i>
                  </span>
                  <span className="play">
                    <i className="fa fa-play"></i>
                  </span>
                  <div className="thumb-wrap">
                    <img alt="dummy" src="./img/dummy/video-1.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <h2 className="title">
                  <NavLink to="/single">
                    Tu cuerpo agradecera beber agua todas las mañanas antes de trotar
                  </NavLink>
                </h2>
              </div>
            </article>
            <article className="content-post videos">
              <NavLink to="/single">
                <div className="thumb">
                  <span className="favorite">
                    <i className="far fa-heart"></i>
                  </span>
                  <span className="play">
                    <i className="fa fa-play"></i>
                  </span>
                  <div className="thumb-wrap">
                    <img alt="dummy" src="./img/dummy/video-2.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <h2 className="title">
                  <NavLink to="/single">
                    Tu cuerpo agradecera beber agua todas las mañanas antes de trotar
                  </NavLink>
                </h2>
              </div>
            </article>
            </div>
            <div data-tabs-pane className="tabs-pane align-center" id="videos-seleccion-editores">
              <article className="content-post videos">
              <NavLink to="/single">
                <div className="thumb">
                  <span className="favorite">
                    <i className="far fa-heart"></i>
                  </span>
                  <span className="play">
                    <i className="fa fa-play"></i>
                  </span>
                  <div className="thumb-wrap">
                    <img alt="dummy" src="./img/dummy/video-2.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <h2 className="title">
                  <NavLink to="/single">
                    Tu cuerpo agradecera beber agua todas las mañanas antes de trotar
                  </NavLink>
                </h2>
              </div>
            </article>
              <article className="content-post videos">
              <NavLink to="/single">
                <div className="thumb">
                  <span className="favorite">
                    <i className="far fa-heart"></i>
                  </span>
                  <span className="play">
                    <i className="fa fa-play"></i>
                  </span>
                  <div className="thumb-wrap">
                    <img alt="dummy" src="./img/dummy/video-1.png" className="thumb-bg" />
                  </div>
                </div>
              </NavLink>
              <div className="caption">
                <h2 className="title">
                  <NavLink to="/single">
                    Tu cuerpo agradecera beber agua todas las mañanas antes de trotar
                  </NavLink>
                </h2>
              </div>
            </article>
            </div>
        </div>
      </section>
		)
	}
}

export default BlogFeaturedPosts;
