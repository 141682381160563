import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.css';

// Root App
import App from './components/App/App';

// Import global store
import configureStore from './store';

ReactDOM.render(
  <Provider store={configureStore({})}>
    <App />
  </Provider>,
  document.querySelector('#root')
);