import React, { Component } from 'react';

import {
	Heading,
	Image,
	Card
} from 'base-ui';

class CourseCard extends Component {
	render() {
		return(
			<Card className="course-card">
				<Image src={this.props.courseImage} className="course-thumb full" />
				<Heading type="h2" size="normal" className="course-title main-font">
					{this.props.courseTitle}
				</Heading>
			</Card>
		)
	}
}

export default CourseCard;