import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  InputField,
  Button,
} from 'base-ui';

class UnycosAccountSecurity extends Component {
  state = {}

  render() {
    return (
      <section id="account-security" className="fade-exit-active">

        <span className="box-title">Cambiar tu contraseña</span>
        <Container fluid>
          <Row>
            <Col xs="12" sm="6">
              <InputField
                id="old-password"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="old-password"
                type="password"
                label="Contraseña anterior"
                placeholder=""
                required={false}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputField
                id="new-password"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="new-password"
                type="password"
                label="Nuevo contraseña"
                placeholder=""
                required={false}
              />
            </Col>
            <Col xs="12" sm="6">
              <InputField
                id="confirm-password"
                full
                big
                className="without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="confirm-password"
                type="password"
                label="Confirmar contraseña"
                placeholder=""
                required={false}
              />
            </Col>
            <Col xs="12">
              <br />
              <Button className="orange square hover-effect">Actualizar</Button>
            </Col>
          </Row>
        </Container>

        <span className="box-title">Notificaciones de inicio de sesión</span>
        <Container fluid>
          <Row>
            <Col xs="12">
              <InputField
                id="notification-session"
                full
                big
                className="input-checkbox without-shadow rounded-small full letter-spacing__small regent-grey-bg"
                name="notification-session"
                type="checkbox"
                label="Activar las notificaciones de inicio de sesión: Las notificaciones de inicio de sesión son una medida de seguridad adicional. Si las activas, te informaremos cada vez que se produzcan accesos a tu cuenta de Airbnb desde un nuevo navegador. Esto te permitirá mantener tu cuenta a salvo."
              />
              <hr />
              <Button className="orange square hover-effect">Guardar</Button>
            </Col>
          </Row>
        </Container>

        <span className="box-title">Historial de inicios de sesión</span>
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="table-container">
                <table className="history-table" style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <th>Navegador/Dispositivo</th>
                      <th>Ubicacióon</th>
                      <th>Actividad reciente</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Chrome</strong>
                        <br />
                        Windows 10
                      </td>
                      <td>Colombia</td>
                      <td>Cerca de 6 horas atrás</td>
                      <td><Link to="/unycos" className="table-link">Cerrar sesión</Link></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default UnycosAccountSecurity;
