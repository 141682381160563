import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Heading, Paragraph, Icon, Row, Col, Card, Button, NavLink
} from 'base-ui';

import PaymentTabs from '../../Sections/PaymentTabs';

import './styles.css';

const SchedulePayment = ({
  onBuy,
  price,
  color,
  textColor,
  isCompleted,
  validateCoupon,
  hasCoupon
}) => (
  <React.Fragment>
    {isCompleted ? (
      <Box className="payment-completed">
        <Card
          borderColor="secondary"
          textColor="secondary"
          size="medium"
          className="align-center"
        >
          <Heading type="h3" size="x-medium" textColor="secondary">
            <Icon name="envelope" prefix="fas" />
            {' '}
            Se ha enviado la confirmación a tu correo electrónico
          </Heading>
        </Card>
        <Button color="main" textColor="white" size="big">
          Crear alerta en iCalendar
        </Button>
        <Button color="main" textColor="white" size="big">
          Crear alerta en Google calendar
        </Button>
        <Button color="secondary" textColor="white" size="big">
          Mis reservas
        </Button>
      </Box>
    ) : (
      <React.Fragment>
        <PaymentTabs
          onBuy={onBuy}
          color={color}
          textColor={textColor}
          borderColor="transparent"
          buttonColor="secondary"
          buttonTextColor="white"
          buttonText={`Comprar - ${price}`}
          hasEmail={false}
          hasSave
          hasCoupon
          couponActive={hasCoupon}
          onSubmitCoupon={validateCoupon}
        />
        <Row className="payment-extra">
          <Col xs="12" className="align-center margin-top-medium">
            <Heading type="h4">¿Tienes dudas?</Heading>
            <Paragraph>Escríbenos a <NavLink to="mailto:contacto@lamenteesmaravillosa.com">contacto@lamenteesmaravillosa.com</NavLink></Paragraph>
          </Col>
        </Row>
      </React.Fragment>
    )}
  </React.Fragment>
);

SchedulePayment.propTypes = {
  onBuy: PropTypes.func,
  isCompleted: PropTypes.bool,
  price: PropTypes.string,
  validateCoupon: PropTypes.func,
  hasCoupon: PropTypes.bool,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

export default SchedulePayment;
