/* eslint-disable no-plusplus */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Box,
  Image,
  Icon,
} from 'base-ui';

const Review = (props) => {
  const {
    data, children
  } = props;

  const stars = [];
  let typePrefix;
  for (let i = 0; i < 5; i++) {
    typePrefix = (data.stars <= i) ? 'far' : 'fas';
    stars.push(<Icon key={i} prefix={typePrefix} name="star" className="yellow-color" />);
  }

  return (
    <Row className="review">
      <Col xs="3" sm="2">
        <Image src={data.avatar} alt={`Avatar Author ${data.name}`} />
      </Col>
      <Col xs="9" sm="10">
        <Row className="between-xs">
          <Col xs="12" sm="8">
            <Box tag="span" className="name">
              <strong>{data.name}</strong>
              {' '}
              <small>
                <em>
                  {' '}
                  -
                  {' '}
                  {data.date}
                </em>
              </small>
            </Box>
          </Col>
          <Col xs="12" sm="4" className="end-sm">
            {stars}
          </Col>
        </Row>
        {children}
      </Col>
    </Row>
  );
};

Review.propTypes = {
  children: PropTypes.any,
  data: PropTypes.shape({
    name: PropTypes.string,
    avatar: PropTypes.string,
    date: PropTypes.string,
    stars: PropTypes.number
  })
};

export default Review;
