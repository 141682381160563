import React from 'react';
import {
  Container,
  Box,
  Row,
  Col,
  Heading,
  Image,
  // Paragraph
} from 'base-ui';

const UnycosSingleReviews = (props) => (
  <Box className={`unycos-single-reviews margin-top-normal margin-bottom-normal ${props.className}`}>
    <Container fluid wrap>
      <Row>
        <Col xs="2" className="center-xs">
          <Image src="./img/unycos/icons/icon-star.svg" />
        </Col>
        <Col xs="10">
          <Heading type="h3" className="normal-font text-uppercase white-color font-size-normal without-margin-top without-margin-bottom line-height__medium">98.7% DE VALORACIONES POSITIVAS / TOTAL DE 726 VALORACIONES</Heading>
        </Col>
      </Row>
      {/* <Row className="center-xs">
        <Col xs="11" md="8">
          <Paragraph className="text-color__white--dark line-height__normal">Al 100% de nuestros estudiantes les ha gustado el curso. ¿No estás seguro?  Tenemos garantía de satisfacción de 30 días, si no te convence el curso hacemos la devolución de tu dinero.</Paragraph>
        </Col>
      </Row> */}
    </Container>
  </Box>
);

export default UnycosSingleReviews;
