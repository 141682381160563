import React from 'react';

// Base-UI Components
import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Paragraph,
  Image,
  Icon,
  NavLink
} from 'base-ui';

// Blog Components
import BlogMenu from '../../components/Blog/BlogMenu';
import BlogHero from '../../components/Blog/BlogHero';
import BlogFooterOffices from '../../components/Blog/BlogFooterOffices';

const pageConfig = {
  product: 'Mejor con Salud',
  title: 'Sobre Nosotros',
  meta: [
    { name: 'description', content: 'Es la publicación digital sobre salud y bienestar más leida del mundo en español.' }
  ],
  contentType: 'salud',
  aboutPortal: 'En nuestro portal, encontrarás contenido de diversa índole: enfermedades y tratamientos, bienestar, remedios naturales, dietas y recetas, psicología, pareja, maternidad y estilo de vida. Todo ello desarrollado por un equipo de periodistas especializados en salud que se apoyan en la investigación científica, los descubrimientos farmacéuticos y, en general, toda la información de vanguardia en cada temática.',
  team: [
    {
      name: 'Ruben',
      profilePicture: './img/dummy/person-1.png',
      role: 'CEO',
      linkedin: 'https://linkedin.com/'
    },
    {
      name: 'Maria',
      profilePicture: './img/dummy/person-2.png',
      role: 'CEO',
      linkedin: 'https://linkedin.com/'
    },
    {
      name: 'Lopez',
      profilePicture: './img/dummy/person-3.png',
      role: 'CEO',
      linkedin: 'https://linkedin.com/'
    },
    {
      name: 'González',
      profilePicture: './img/dummy/person-1.png',
      role: 'CEO',
      linkedin: 'https://linkedin.com/'
    },
    {
      name: 'Lallita',
      profilePicture: './img/dummy/person-4.png',
      role: 'CEO',
      linkedin: 'https://linkedin.com/'
    },
    {
      name: 'Maria do Bairro',
      profilePicture: './img/dummy/person-2.png',
      role: 'CEO',
      linkedin: 'https://linkedin.com/'
    },
    {
      name: 'John Wick',
      profilePicture: './img/dummy/person-1.png',
      role: 'Press',
      linkedin: 'https://linkedin.com/'
    },
    {
      name: 'Stiven',
      profilePicture: './img/dummy/person-4.png',
      role: 'Badass Developer',
      linkedin: 'https://linkedin.com/'
    },
  ],
  editorials: ['salud', 'descubrimientos sanitarios y farmacéuticos', 'psicología', 'belleza', 'estilo de vida'],
  doctors: [
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-1.png'
    },
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-2.png'
    },
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-3.png'
    },
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-4.png'
    },
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-5.png'
    },
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-6.png'
    },
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-7.png'
    },
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-8.png'
    },
    {
      name: 'Samara',
      role: 'Senior Director',
      profilePicture: './img/dummy/avatar-9.png'
    },
  ]
};

const BlogAbout = () => (
  <React.Fragment>
    <BlogMenu />
    <PageWrapper config={pageConfig}>
      <Box className="page-template-about-us">
        <Box id="about-us-hero">
          <BlogHero
            backgroundImage="./img/aboutus-hero.png"
            title={pageConfig.product}
            subtitle={pageConfig.meta[0].content}
            className="hero"
          />
        </Box>
        <Box className="full-width-bg small main-bg big">
          <Container fluid wrap>
            <Row>
              <Col xs="12" className="align-center">
                <Paragraph textColor="white">
                  {pageConfig.aboutPortal}
                </Paragraph>
              </Col>
            </Row>
          </Container>
        </Box>
        <Box className="the-team align-center">
          <Container fluid wrap>
            <Row>
              <Col xs="12">
                <Paragraph style={{ margin: '30px 0' }}>
                  A través de artículos, vídeos, infografías, entrevistas y reportajes, caracterizados por el rigor y la profesionalidad de nuestro equipo, hemos logrado ser los líderes en el campo de la divulgación informativa sobre
                  {' '}
                  {pageConfig.contentType}
.
                </Paragraph>
              </Col>
              <Col xs="12">
                <Heading type="h2" textColor="text" size="big" style={{ margin: '15px 0' }}>
                  Nuestro equipo
                </Heading>
                <Box className="team-slider">
                  {pageConfig.team.map((member) => (
                    <Box key={Math.random()} className="team">
                      <Box className="thumb">
                        <Box className="thumb-wrap" style={{ backgroundImage: `url(${member.profilePicture})` }}>
                          <Image src={member.profilePicture} alt={member.name} style={{ display: 'none' }} />
                        </Box>
                      </Box>
                      <Box className="caption">
                        <NavLink to={member.linkedin} target="blank">
                          <Icon prefix="fab" name="linkedin" className="icon" />
                        </NavLink>
                        <Heading type="h3" className="name">
                          <strong>{member.name}</strong>
                        </Heading>
                        <span>{member.role}</span>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
        <Box className="cta full-width-bg small" style={{ marginTop: '120px' }}>
          <Container fluid wrap>
            <Row className="center-xs">
              <Col xs="12" md="10" className="align-center">
                <Image
                  src="./img/globe-icon.svg"
                  alt="Globe Illustration"
                  style={{ width: '100%', maxWidth: '150px', margin: '-100px 0 0 0' }}
                />
                <Paragraph textColor="white" style={{ margin: '30px 0' }}>
                  Gracias al trabajo del equipo directivo,
                  {' '}
                  {pageConfig.product}
                  {' '}
está disponible en 23 idiomas, cuenta con el apoyo de más de 300 profesionales que garantizan nuestros estándares de calidad, y se ha afianzando en más de 60 países.
                </Paragraph>
              </Col>
            </Row>
          </Container>
        </Box>
        <Box className="followers">
          <Container fluid wrap>
            <Row>
              <Col xs="12" className="align-center">
                <Heading type="h2" size="big" textColor="text" style={{ margin: '0 0 30px 0' }}>
                  Nuestros seguidores
                </Heading>
              </Col>
            </Row>
            <Row className="social-count center-xs">
              <Col xs="12" sm="4" className="item center-xs start-sm">
                <span className="icon">
                  <Image src="./img/chart-icon.svg" alt="Chart Icon" />
                </span>
                <strong className="count font-x-big">400.000.000</strong>
                <Paragraph className="center-xs start-sm" style={{ margin: '0' }}>Visitas mensuales</Paragraph>
              </Col>
              <Col xs="12" sm="4" className="item center-xs start-sm">
                <span className="icon">
                  <Icon prefix="fab" name="facebook-f" />
                </span>
                <strong className="count font-x-big">9.400.000</strong>
                <Paragraph className="center-xs start-sm" style={{ margin: '0' }}>Fans</Paragraph>
              </Col>
              <Col xs="12" sm="4" className="item center-xs start-sm">
                <span className="icon">
                  <Icon prefix="fab" name="pinterest" />
                </span>
                <strong className="count font-x-big">7.800.000</strong>
                <Paragraph className="center-xs start-sm" style={{ margin: '0' }}>Fans</Paragraph>
              </Col>
            </Row>
            <Row className="center-xs">
              <Col xs="12" className="align-center">
                <Paragraph style={{ margin: '40px 0' }}>
                  <strong>
                    La recompensa a nuestro compromiso divulgativo son los más de 9.400.000 seguidores que leen nuestra publicación en Facebook. Es en ellos donde encontramos la motivación para mejorar y crecer cada día.
                  </strong>
                </Paragraph>
                <Paragraph style={{ margin: '30px 0' }}>
                  En
                  {' '}
                  {pageConfig.product}
                  {' '}
estamos comprometidos con el conocimiento y la información de calidad. Para lograrlo, entre nuestras filas contamos con profesionales de diferentes áreas, destacando a los responsables editoriales de
                  {' '}
                  {`${pageConfig.editorials.slice(0, -1).join(', ')} y ${pageConfig.editorials.slice(-1)}`}
.
                </Paragraph>
              </Col>
            </Row>
          </Container>
        </Box>
        <Box className="professionals">
          <Container fluid wrap>
            <Row className="center-xs">
              <Col xs="12" className="align-center">
                <Heading type="h2" size="big" textColor="text" style={{ margin: '30px 0' }}>Equipo médico colaborador</Heading>
              </Col>
              {pageConfig.doctors.map((doctor) => (
                <Col key={Math.random()} xs="12" sm="5" md="4" className="professional">
                  <Row className="middle-xs">
                    <Col xs="4">
                      <Image src={doctor.profilePicture} alt={doctor.name} />
                    </Col>
                    <Col xs="8">
                      <Paragraph className="without-margin">
                        <strong>{doctor.name}</strong>
                      </Paragraph>
                      <span>{doctor.role}</span>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Container>
        </Box>
        <Box className="cta full-width-bg small" style={{ margin: '25px 0' }}>
          <Container fluid wrap>
            <Row className="center-xs">
              <Col xs="12" className="align-center">
                <Paragraph textColor="white">
                  <strong>
                    Además,
                    {' '}
                    {pageConfig.product}
                    {' '}
está avalada por la marca MContigo, garantía de calidad como empresa líder en contenido digital, responsable del desarrollo de más de 80 websites en todo el mundo, con más de 100 millones de visitas mensuales.
                  </strong>
                </Paragraph>
              </Col>
            </Row>
          </Container>
        </Box>
        <Box className="logos">
          <Container fluid wrap>
            <Row className="center-xs">
              <Col xs="12" md="11" className="align-center">
                <Paragraph style={{ margin: '25px 0' }}>
                  <strong>Otras de las publicaciones de MContigo en español son:</strong>
                </Paragraph>
                <Image src="./img/logos.png" alt="Otras de las publicaciones de MContigo en español" />
              </Col>
            </Row>
          </Container>
        </Box>
        <Box className="contact-us">
          <Container fluid wrap>
            <Row className="center-xs">
              <Col xs="12" className="align-center">
                <Heading type="h2" size="big" textColor="text" style={{ margin: '30px 0' }}>Contacta con nosotros</Heading>
                <Box className="item">
                  <Paragraph><strong>¿Quieres hacer una consulta médica?</strong></Paragraph>
                  <Paragraph>
                    Desde nuestra web no podemos brindar una atención personalizada, pero puedes usar nuestro
                    {' '}
                    <NavLink to="/">directorio de Salud</NavLink>
                    {' '}
                     donde encontrarás contenido redactado por profesionales del sector sanitario. Si crees que estás en una situación crítica, llama a los servicios de emergencia.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Paragraph><strong>¿Has detectado algún fallo o errata en nuestro contenido?</strong></Paragraph>
                  <Paragraph>
                    Por favor, háznoslo saber a través de
                    {' '}
                    <NavLink to="/">este formulario.</NavLink>
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Paragraph><strong>¿Has experimentado alguna incidencia durante la navegación en nuestra página?</strong></Paragraph>
                  <Paragraph>
                    Por favor, detalla tu experiencia usando
                    {' '}
                    <NavLink to="/">este formulario.</NavLink>
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Paragraph><strong>¿Estás interesado en hacernos una propuesta comercial? ¿Quieres ofrecernos un producto o servicio?</strong></Paragraph>
                  <Paragraph>
                    Por favor, envía un
                    {' '}
                    <NavLink to="/">email</NavLink>
                    {' '}
                    a nuestro equipo de desarrollo y expansión.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Paragraph><strong>¿Te gustaría utilizar nuestro contenido?</strong></Paragraph>
                  <Paragraph>
                    Por favor, envía un
                    {' '}
                    <NavLink to="/">email</NavLink>
                    {' '}
                    a nuestro equipo legal.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Paragraph><strong>¿Quieres anunciarte en nuestra página?</strong></Paragraph>
                  <Paragraph>
                    Por favor,
                    {' '}
                    <NavLink to="/">contacta</NavLink>
                    {' '}
                    con el departamento de publicidad.
                  </Paragraph>
                </Box>
                <Box className="item">
                  <Paragraph><strong>¿Quieres suscribirte a nuestra newsletter o unirte a nuestra comunidad de Facebook?</strong></Paragraph>
                  <Paragraph>
                    Por favor, para suscribirte entra en
                    {' '}
                    <NavLink to="/">este enlace,</NavLink>
                    {' '}
                    para visitar nuestra fan page puedes hacer click aquí.
                  </Paragraph>
                </Box>
              </Col>
            </Row>
          </Container>
        </Box>
        <BlogFooterOffices />
      </Box>
    </PageWrapper>
  </React.Fragment>
);

export default BlogAbout;
