import React, { Component } from 'react';

class BlogComparatorSidebar extends Component {

	render() {
		return(
      <React.Fragment>
        <section id="comparator-widget" className="widget comparator-sidebar-widget">
          <div className="card">
            <div className="box-title main-bg">
              <div className="logo">
                <img src="./img/logo-isalud.png" alt="" />
              </div>
              <span className="label main-font white-color">Compara y encuentra tu <strong>seguro médico</strong> desde <strong>9€ al mes</strong></span>
              <img src="./img/idoctor-2.png" className="mascot hidden-md" alt="" />
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="select full">
                  <select>
                    <option>Provincia</option>
                  </select>
                </div>
              </div>
              <div className="col-xs-12">
                <input type="email" className="input full" placeholder="Correo electrónico" />
              </div>
              <div className="col-xs-12">
                <input type="text" className="input full" placeholder="Nombre" />
              </div>
              <div className="col-xs-6">
                <input type="number" className="input full" placeholder="Edad" />
              </div>
              <div className="col-xs-6">
                <input type="text" className="input full" placeholder="Teléfono" />
              </div>
              <div className="col-xs-12">
                <input id="c1" type="checkbox" value="" />
                <label htmlFor="c1">
                  <span style={{"fontWeight": "400", "fontSize": "0.75rem"}}>He leído y acepto la <a href="." className="titles-color">política de privacidad</a></span>
                </label>
                <button className="btn full main-bg main-border white-color big">Comparar</button>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
		)
	}
}

export default BlogComparatorSidebar;
