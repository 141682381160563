/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';

import {
  PageWrapper,
  Box,
  Heading,
  Container,
  Row,
  Col,
  Paragraph,
  Icon
} from 'base-ui';

import CourseDesc from '../../../components/Single/CourseDesc';
import AboutTeacher from '../../../components/Single/AboutTeacher';
import ToWhere from '../../../components/Single/ToWhere';
import CourseContentDesktop from '../../../components/Single/CourseContentDesktop';
import CourseReviewsDesktop from '../../../components/Single/CourseReviewsDesktop';
import SidebarWidgetInfoB from '../../../components/Single/SidebarWidgetInfoB';
import RelatedCourses from '../../../components/Single/RelatedCourses';
import YoullLearnDesktopB from '../../../components/Single/YoullLearnDesktopB';

class SingleB extends Component {
  componentDidMount() {
    document.body.classList.toggle('single-course');
  }

  render() {
    return (
      <PageWrapper config={{ title: 'Single course' }}>
        <Box id="single-course">
          <Box className="single-course-main titles-bg padding-top-small padding-bottom-big">
            <Container fluid wrap>
              <Row>
                <Col xs="12" sm="8">
                  <Heading type="h1" size="big" textColor="white" className="line-height-medium">Del miedo escénico, al placer para hablar en público: Oratoria moderna</Heading>
                  <Paragraph className="yellow-color">
                    <Icon prefix="fas" name="thumbs-up" />
                    100% de valoraciones positivas
                  </Paragraph>
                  <Paragraph className="alpha-color">
                    Un curso de <a href="." className="white-color">Ángel Lafuente Z.</a>
                  </Paragraph>
                  <hr className="alpha-bg" />
                  <YoullLearnDesktopB />
                </Col>
              </Row>
            </Container>
          </Box>
          <Box>
            <Container fluid wrap>
              <Row>
                <Col xs="12" md="8" className="single-course-content margin-top-medium">
                  <CourseDesc />
                  <AboutTeacher />
                  <ToWhere />
                  <CourseContentDesktop />
                  <CourseReviewsDesktop />
                </Col>
                <Col xs="4" className="single-course-sidebar hidden-md">
                  <SidebarWidgetInfoB />
                </Col>
              </Row>
              <RelatedCourses />
            </Container>
          </Box>
        </Box>
      </PageWrapper>
    );
  }
}

export default SingleB;
