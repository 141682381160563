/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';

import {
  PageWrapper,
  Box,
  Heading,
  Container,
  Row,
  Col,
  Paragraph,
  Icon
} from 'base-ui';

import CourseDesc from '../../../components/Single/CourseDesc';
import AboutTeacher from '../../../components/Single/AboutTeacher';
import ToWhere from '../../../components/Single/ToWhere';
import CourseContentDesktop from '../../../components/Single/CourseContentDesktop';
import CourseReviewsDesktop from '../../../components/Single/CourseReviewsDesktop';
import SidebarWidgetInfoC from '../../../components/Single/SidebarWidgetInfoC';
import SidebarWidgetinfoCMobile from '../../../components/Single/SidebarWidgetInfoCMobile';
import RelatedCourses from '../../../components/Single/RelatedCourses';
import YoullLearnDesktopB from '../../../components/Single/YoullLearnDesktopB';

class SingleB extends Component {
  componentDidMount() {
    document.body.classList.toggle('single-course');

    // show hide box discount on scroll page
    const widgetBuy = document.getElementById('widget-buy-mobile');

    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024 && widgetBuy) {
        widgetBuy.classList.remove('fixed-element');
      } else {
        widgetBuy.classList.add('fixed-element');
      }
    });

    if (window.innerWidth <= 1023) {
      widgetBuy.classList.add('fixed-element');
    } else {
      widgetBuy.classList.remove('fixed-element');
    }
    // end show hide box discount on scroll page
  }

  render() {
    return (
      <PageWrapper config={{ title: 'Single course' }}>
        <Box id="single-course">
          <Box id="single-course-header" className="single-course-main titles-bg padding-top-small padding-bottom-big">
            <Container fluid wrap>
              <Row>
                <Col xs="12" sm="12" md="8">
                  <Box className="course-preview hidden-lg align-center">
                    <Box className="videoWrapper"><iframe title="iframe" width="560" height="315" src="https://www.youtube.com/embed/KmLVXffHLd4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></Box>
                  </Box>
                  <Heading type="h1" size="big" textColor="white" className="line-height-medium">Del miedo escénico, al placer para hablar en público: Oratoria moderna</Heading>
                  <Paragraph className="font-size-normal alpha-color font-size-small hidden-lg">
                    Un curso de <a href="." className="white-color">Ángel Lafuente Z.</a>
                  </Paragraph>
                  <hr className="alpha-bg hidden-lg" />
                  <Paragraph className="yellow-color">
                    <Icon prefix="fas" name="thumbs-up" />
                    100% de valoraciones positivas
                  </Paragraph>
                  <Paragraph className="txt-course-from alpha-color hidden-md">
                    Un curso de <a href="." className="white-color">Ángel Lafuente Z.</a>
                  </Paragraph>
                  <Paragraph className="hidden-lg alpha-color white-color font-size-small line-height-x-medium">La autoestima es el músculo psicológico más importante, pero ¿cómo ejercitarlo? Con este curso descubrirás valiosas herramientas para practicar el amor propio, ganar en seguridad y crear un adecuado plan de acción para alcanzar tus metas personales. ¿Estás listo?</Paragraph>
                  <YoullLearnDesktopB className="hidden-md " />
                </Col>
              </Row>
            </Container>
          </Box>
          <Box id="widget-buy-mobile">
            <Box id="txt-discount-bar" className="hidden-lg box-tit-discount align-center text-uppercase font-size-small text-color padding-xsmall letter-spacing__medium">Descuento especial</Box>
            <Box>
              <Container fluid wrap>
                <SidebarWidgetinfoCMobile className="hidden-lg" />
              </Container>
            </Box>
          </Box>
          <Box className="">
            <Container fluid wrap>
              <Row>
                <YoullLearnDesktopB className="hidden-lg to-mobile" />
              </Row>
            </Container>
          </Box>
          <Box>
            <Container fluid wrap>
              <Row>
                <Col xs="12" md="8" className="single-course-content margin-top-medium">
                  <CourseDesc />
                  <AboutTeacher />
                  <ToWhere />
                  <CourseContentDesktop />
                  <CourseReviewsDesktop />
                </Col>
                <Col xs="4" className="single-course-sidebar hidden-md">
                  <SidebarWidgetInfoC />
                </Col>
              </Row>
              <RelatedCourses />
            </Container>
          </Box>
        </Box>
      </PageWrapper>
    );
  }
}

export default SingleB;
