export const QuestionsData = [
  {
    id: 1,
    question: '¿Cuál es lá comunicaión más importante?',
    options: [
      {
        id: 1,
        text: 'Comunicación en público',
      },
      {
        id: 2,
        text: 'Comunicación personal',
      },
      {
        id: 3,
        text: 'Comunicaión con uno mismo',
      }
    ],
    answare: 3,
    teacherComment: 'Recuerda que lo que comunicamos hacia fuera es la consecuencia direct de lo que pensamos y por tanto, de cómo nos hablamos a nosostros mismos.',
  },
  {
    id: 2,
    question: '¿Nacemos con las capacidades o las desarrollamos?',
    options: [
      {
        id: 1,
        text: 'Nacemos',
      },
      {
        id: 2,
        text: 'Las desarrollamos',
      },
      {
        id: 3,
        text: 'Ambas',
      }
    ],
    answare: 3,
    teacherComment: 'Todos nacemos con la capacidad de aprender y con la possibilidad de elegir desarrollaria.',
  },
  {
    id: 3,
    question: 'Para desarrollar una capacidad, ¿es más importante la actitud o la aptidud?',
    options: [
      {
        id: 1,
        text: 'Aptitud',
      },
      {
        id: 2,
        text: 'Actitud',
      },
      {
        id: 3,
        text: 'Ambas',
      }
    ],
    answare: 2,
    teacherComment: null,
  }
];
