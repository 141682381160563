import React, { Component } from 'react';
import {
  Button,
  Paragraph,
} from 'base-ui';

class UnycosProfileVerification extends Component {
  state = {}

  render() {
    return (
      <section id="account-verification" className="fade-exit-active">
        <span className="box-title">
          E-mail Confirmación
        </span>
        <div className="box-body">
          <Paragraph className="text-gold"><b>regalo1@gmail.com</b></Paragraph>
          <Paragraph className="text-gray">Tu correo electrónico aún no ha sido verificado.</Paragraph>
        </div>

        <span className="box-title">
          Facebook
        </span>
        <div className="box-body">
          <Paragraph className="text-gray">Si conectas tu cuenta con tu perfil de Facebook puedes brindar mayor confianza.</Paragraph>
          <Button className="orange square hover-effect">Asociar con tu Facebook</Button>
        </div>

        <span className="box-title">
          Google
        </span>
        <div className="box-body">
          <Paragraph className="text-gray">Si conectas tu cuenta con tu perfil de Google+ puedes brindar mayor confianza.</Paragraph>
          <Button className="orange square hover-effect">Conectarse a Google+</Button>
        </div>
      </section>
    );
  }
}

export default UnycosProfileVerification;
