import React from 'react';
import PropTypes from 'prop-types';

// Base-UI Components
import {
  Container,
  Row,
  Col,
  Box,
  Paragraph
} from 'base-ui';

const BlogFooterOffices = ({
  city, location, cp, tel
}) => (
  <Box className="cta full-width-bg grey-bg small">
    <Container fluid wrap>
      <Row className="center-xs">
        <Col xs="12" className="align-center">
          <Paragraph>
            <strong>
              Oficinas en
              {' '}
              {city}
            </strong>
          </Paragraph>
        </Col>
        <Col xs="12" md="3">
          <Paragraph className="align-center">
            <span>Dirección</span>
            <br />
            <span>{location}</span>
            <br />
            <span>{cp}</span>
          </Paragraph>
        </Col>
        <Col xs="12" md="3">
          <Paragraph className="align-center">
            <span>Teléfono</span>
            <br />
            <span>{tel}</span>
            <br />
          </Paragraph>
        </Col>
      </Row>
    </Container>
  </Box>
);

BlogFooterOffices.defaultProps = {
  city: 'Salamanca',
  location: 'C/ Concejo, 13',
  cp: 'CP 37002',
  tel: 'XXXX XXXX XXXX'
};

BlogFooterOffices.propTypes = {
  city: PropTypes.string,
  location: PropTypes.string,
  cp: PropTypes.string,
  tel: PropTypes.string,
};

export default BlogFooterOffices;
