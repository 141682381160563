import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  PageWrapper,
  Container,
  Row,
  Col,
  Box,
  Heading,
  Paragraph,
  Icon,
  Image,
  Badge,
  Card,
  InputField,
  NavLink,
  Modal,
  Button
} from 'base-ui';

// Content form .card-orientation
const Orientation = (props) => {
  const {
    className
  } = props;
  return (
    <Card className={`card-orientation without-shadow without-border ${className}`}>
      <Heading type="h3" className="font-big">Siguientes pasos</Heading>
      <Heading type="h4" className="font-size-normal without-margin-bottom margin-top-normal">Confirma tu cuenta</Heading>
      <Paragraph className="font-small without-margin-top position-relative">
        <Image src="./img/icons/email.svg" alt="Revisa tu email" className="position-absolute" />
        <strong>Revisa tu email</strong>
        ,
        {' '}
        te hemos enviado un enlace que confirmes tu cuenta en la plataforma. Es importante hacerlo antes de la cita con el profesional.
      </Paragraph>
      <Heading type="h4" className="font-size-normal without-margin-bottom margin-top-normal">Entra a la videollamada </Heading>
      <Paragraph className="font-small without-margin-top position-relative">
        <Image src="./img/icons/monitor.svg" alt="Entra a la videollamada" className="position-absolute" />
        Te enviaremos un
        {' '}
        <strong>email antes de tu cita</strong>
        {' '}
        con un enlace para acceder a la videollamada.
      </Paragraph>
      <Paragraph className="font-small ">
        También puedes
        {' '}
        <strong>acceder desde la plataforma en esta misma pantalla</strong>
        ,
        {' '}
        aparecerá un botón de “entrar a la cita” minutos antes de la hora establecida.
      </Paragraph>
      <Paragraph className="txt-doubts font-small align-center">
        ¿Tienes alguna duda?
        <br />
        Escríbenos un email a
        {' '}
        <NavLink to="#">contacto@lamenteesmaravillosa.com</NavLink>
      </Paragraph>
    </Card>
  );
};
Orientation.propTypes = {
  className: PropTypes.string
};

const Reservations = () => {
  const [showModalExperience, setModalExperience] = useState(false);
  const [tag, setTag] = useState(true);

  return (
    <PageWrapper config={{ title: 'Consultation Reservations', bodyClasses: 'consultation' }} className="wrap-overflow">

      <Box tag="main" id="site-main">

        <Box tag="section">
          <Container wrap>
            <Row className="center-xs">
              <Col xs="12" sm="4">
                <Card>
                  <NavLink to="#" className="main-font  margin-bottom-small display-block">Próximas reservas</NavLink>
                  <NavLink to="#" className="main-font  margin-top-normal display-block">Reservas anteriores</NavLink>
                </Card>

                <Orientation className="hidden-sm" />

              </Col>
              <Col xs="12" sm="8">
                <Card className="card-next-reservation">
                  <Box className="box-title grey-bg">
                    <Box tag="span" className="label main-font mediumgray-color">Reservas anteriores</Box>
                  </Box>
                  <Row>
                    <Col xs="12" sm="3">
                      <Paragraph className="txt-date font-size-medium">
                        abr.
                        {' '}
                        <Badge rounded color="grey" className="">08</Badge>
                      </Paragraph>
                    </Col>
                    <Col xs="12" sm="3">
                      <Image src="./img/dummy/author.jpg" alt="Thumb Avatar Professional Daniela Fluchaire" className="thumb-120" />
                    </Col>
                    <Col xs="12" sm="6">
                      <Heading type="h1" className="font-m-big without-margin-top">Consulta con Layla Luciano Teste</Heading>
                      <Box tag="span" className="txt-status grey-bg ">Online</Box>
                      <Paragraph className=" line-height-x-medium ">
                        Por
                        {' '}
                        <strong>Daniela Fluchaire</strong>
                        <br />
                        07:00 - 07:50
                      </Paragraph>
                      <Button color="white" textColor="title" borderColor="title" className="btn-experience" onClick={() => setModalExperience(true)}>Valorar al profesional</Button>
                    </Col>
                  </Row>
                  <Box className="box-navigation margin-x-medium padding-normal position-relative">
                    {tag && (
                      <Box id="tag-alert" className="position-absolute padding-small">
                        <Icon prefix="fas" name="clock" className="white-color" />
                        <Paragraph className="white-color font-small align-center">
                          Aquí se mostrará el
                          {' '}
                          <strong>botón para entrar</strong>
                          {' '}
                          a la videollamada minutos antes de tu consulta.
                        </Paragraph>
                        <Button onClick={() => setTag(false)}><Icon prefix="fas" name="times" className="white-color" /></Button>
                      </Box>
                    )}
                    <Button to="#">
                      <Icon prefix="far" name="comment" />
                      Mensaje
                    </Button>
                    <Button to="#">
                      <Icon prefix="far" name="file" />
                      Factura
                    </Button>
                    <Button to="#">
                      <Icon prefix="fas" name="ban" />
                      Cancelar
                    </Button>
                  </Box>
                </Card>

                <Orientation className="visible-sm hidden-md hidden-lg" />

              </Col>
            </Row>
          </Container>
        </Box>

      </Box>
      {/* end main section */}

      {/* ini #modalc-Experience */}
      <Modal
        isOpen={showModalExperience}
        appElement="#root"
        idModal="modal-Experience"
        className="modal"
        closeModal={() => setModalExperience(false)}
        closeIcon
        width="500px"
        height="425px"
        styles={{
          overlay: {
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.80)',
            zIndex: '9999'
          },
          content: {
            position: 'absolute',
            right: '10px',
            left: '10px',
            bottom: '10px',
            top: '0',
            margin: 'auto',
            boxShadow: '0 12px 15px 0 rgba(0,0,0,.25)',
            overflow: 'hidden',
            padding: '0',
            borderRadius: '2px',
            display: 'block'
          },
          close: {
            position: 'absolute',
            right: '15px',
            top: '15px',
            zIndex: 1,
            cursor: 'pointer'
          }
        }}
      >
        <Card>
          <Box className="box-title grey-bg align-center">
            <Box tag="span" className="label main-font titles-color">Experience tu experiencia</Box>
          </Box>
          <dl>
            <dd>Evaluación para la experiencia</dd>
            <dt>
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="far" name="star" className="yellow-color" />
            </dt>
            <dd>Evaluación para el profesional</dd>
            <dt>
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="far" name="star" className="yellow-color" />
            </dt>
            <dd>Envia un comentario sobre el profesional</dd>
            <dt>
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="fas" name="star" className="yellow-color" />
              <Icon prefix="far" name="star" className="yellow-color" />
            </dt>
          </dl>
          <InputField
            name="message"
            type="textarea"
            className="textarea full"
            placeholder="Deja tu mensaje aca..."
          />
          <Button className="btn main-bg big main-border white-color">
            Enviar evaluación
          </Button>
        </Card>
      </Modal>
      {/* end #modal-Experience */}

    </PageWrapper>
  );
};


export default Reservations;
