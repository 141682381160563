import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Box } from 'base-ui';

// Courses Pages
import CoursesHomeA1 from '../screens/courses/CoursesHomeA1';
import CoursesHomeA2 from '../screens/courses/CoursesHomeA2';
import CoursesHomeB1 from '../screens/courses/CoursesHomeB1';
import CoursesOffline from '../screens/courses/CoursesOffline';
import CoursesError from '../screens/courses/CoursesError';
import CoursesLoading from '../screens/courses/CoursesLoading';
import CoursesArchive from '../screens/courses/CoursesArchive';
import CoursesCategory from '../screens/courses/CoursesCategory';
import CoursesCoupon from '../screens/courses/CoursesCoupon';
import CoursesSubscriptionPayment from '../screens/courses/suscription/CoursesSubscriptionPayment';
import CoursesSubscriptionLanding from '../screens/courses/suscription/CoursesSubscriptionLanding';
import CoursesSubscriptionLandingTwo from '../screens/courses/suscription/CoursesSubscriptionLandingTwo';
import CoursesSubscriptionThanks from '../screens/courses/suscription/CoursesSubscriptionThanks';
import Single from '../screens/courses/single/Single';
import SingleB from '../screens/courses/single/SingleB';
import SingleC from '../screens/courses/single/SingleC';
import SingleCourseGift from '../screens/courses/single/SingleCourseGift';
import SingleCoursePayment from '../screens/courses/single/SingleCoursePayment';
import UserCertificate from '../screens/courses/account/UserCertificate2020';
import ThankYou from '../screens/courses/course/ThankYou';
import Wishlist from '../screens/courses/account/Wishlist';
import Forum from '../screens/courses/forum/Forum';
import ForumDiscussion from '../screens/courses/forum/ForumDiscussion';
import ForumSingle from '../screens/courses/forum/ForumSingle';
import StepOne from '../screens/courses/learnsteps/StepOne';
import StepTwo from '../screens/courses/learnsteps/StepTwo';
import StepThree from '../screens/courses/learnsteps/StepThree';
import StepFour from '../screens/courses/learnsteps/StepFour';
import StepFive from '../screens/courses/learnsteps/StepFive';
import UserCoursesWithout from '../screens/courses/user/CoursesWithout';
import UserCourses from '../screens/courses/user/Courses';
import CoursesQuiz from '../screens/courses/Quiz';

// Blog Pages
import BlogHome from '../screens/blog/BlogHome';
import BlogSingle from '../screens/blog/BlogSingle';
import BlogSingleSpecialPubli from '../screens/blog/BlogSingleSpecialPubli';
import BlogCategory from '../screens/blog/BlogCategory';
import BlogCategoryNew from '../screens/blog/BlogCategoryNew';
import BlogAuthor from '../screens/blog/BlogAuthor';
import BlogArchive from '../screens/blog/BlogArchive';
import Blog404 from '../screens/blog/Blog404';
import BlogBookmarks from '../screens/blog/BlogBookmarks';
import BlogPage from '../screens/blog/BlogPage';
import BlogSearch from '../screens/blog/BlogSearch';
import BlogFeedback from '../screens/blog/BlogFeedback';
import BlogJoinUs from '../screens/blog/BlogJoinUs';
import BlogFeedbackWithoutForm from '../screens/blog/BlogFeedbackWithoutForm';
import BlogNewsletter from '../screens/blog/BlogNewsletter';
import BlogAbout from '../screens/blog/BlogAbout';
import BlogDiseases from '../screens/blog/Diseases';
import BlogMedicines from '../screens/blog/Medicines';
import BlogFoods from '../screens/blog/Foods';
import BlogSubscription from '../screens/blog/BlogSubscription';
import BlogPayment from '../screens/blog/BlogPayment';
import BlogPaymentThanks from '../screens/blog/BlogPaymentThanks';

// UNYCOS
import UnycosHome from '../screens/unycos/Home';
import UnycosSingle from '../screens/unycos/Single';
import UnycosSingleTwo from '../screens/unycos/SingleTwo';
import UnycosSingleThree from '../screens/unycos/SingleThree';
import UnycosTerminosDeUso from '../screens/unycos/TerminosDeUso';
import UnycosAvisosLegales from '../screens/unycos/AvisosLegales';
import UnycosPoliticaPrivacidad from '../screens/unycos/PoliticaPrivacidad';
import UnycosProteccionDatos from '../screens/unycos/ProteccionDatos';
import UnycosRecoverPassword from '../screens/unycos/RecoverPassword';
import UnycosNewPassword from '../screens/unycos/NewPassword';
import UnycosCourses from '../screens/unycos/Courses';
import Unycos404 from '../screens/unycos/Error404';
import UnycosCoupon from '../screens/unycos/Coupon';
import UnycosPayment from '../screens/unycos/UnycosPayment';
import UnycosGift from '../screens/unycos/Gift';
import UnycosGiftRecovery from '../screens/unycos/GiftRecovery';
import UnycosMyAccount from '../screens/unycos/MyAccount';
import UnycosStartCourse from '../screens/unycos/UnycosStartCourse';
import UnycosRequestCertificate from '../screens/unycos/RequestCertificate';

// Consultation Pages
import ConsultationProfile from '../screens/Consultation/Profile';
import ConsultationThankYouPage from '../screens/Consultation/ThankYou';
import ConsultationBuyConfirmation from '../screens/Consultation/BuyConfirmation';
import ConsultationReservations from '../screens/Consultation/Reservations';
import ConsultationVideocall from '../screens/Consultation/Videocall';
import ConsultationAbout from '../screens/Consultation/About';
import ConsultationAccessNotAllowed from '../screens/Consultation/AccessNotAllowed';
import ConsultationAccount from '../screens/Consultation/Account';
import ConsultationPaymentMethods from '../screens/Consultation/PaymentMethods';
import ConsultationPaymentPreferences from '../screens/Consultation/PaymentPreferences';
import ConsultationChargesPreferences from '../screens/Consultation/ChargesPreferences';
import ConsultationPaymentHistory from '../screens/Consultation/PaymentHistory';
import ConsultationAccountSecurity from '../screens/Consultation/AccountSecurity';
import ConsultationAccountSubscription from '../screens/Consultation/AccountSubscription';
import ConsultationPaymentProcessing from '../screens/Consultation/PaymentProcessing';
import ConsultationConsultations from '../screens/Consultation/Consultations';
import ConsultationScheduleManage from '../screens/Consultation/ScheduleManage';
import ConsultationScheduleNew from '../screens/Consultation/ScheduleNew';
import ConsultationClinics from '../screens/Consultation/Clinics';
import ConsultationPublishPart1 from '../screens/Consultation/PublishPart1';
import ConsultationPublishPart2 from '../screens/Consultation/PublishPart2';
import ConsultationPublishPart3 from '../screens/Consultation/PublishPart3';
import ConsultationPublishPart4 from '../screens/Consultation/PublishPart4';
import ConsultationProfessionalPart6 from '../screens/Consultation/ProfessionalPart6';
import ConsultationSearch from '../screens/Consultation/Search';
import ConsultationArchiveProfessionals from '../screens/Consultation/ArchiveProfessionals';
import ConsultationArchive from '../screens/Consultation/Archive';
import ConsultationSingle from '../screens/Consultation/Single';
import ConsultationTerms from '../screens/Consultation/Terms';
import ConsultationInformativeClause from '../screens/Consultation/InformativeClause';
import Consultation from '../screens/Consultation';
import ConsultationQuizLanding from '../screens/Consultation/Quiz';
import ConsultationQuizLandingApp from '../screens/Consultation/Quiz/App';

// LMEM Store
import Store from '../screens/store/StoreHome';

// Test Screens
import Inputs from '../screens/demo/Inputs';
import AvatarDemo from '../screens/demo/AvatarDemo';
import MailchimpModal from '../screens/demo/MailchimpModal';
import LoginModal2fa from '../screens/demo/LoginModal2fa';
import MinHeightContent from '../screens/demo/MinHeightContent';
import Paywall from '../screens/demo/Paywall';
import AdsHide from '../screens/demo/AdsHide';
//

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';

import Image from '../screens/demo/Image';
import AccordionScreenV2 from '../screens/demo/AccordionV2';
import AccordionScreen from '../screens/demo/Accordion';

// Check if every page need's this component
// import BlogMorePostsList from './Blog/BlogMorePostsList';

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const Router = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Box>
      <AppHeader store />
      <Switch>
        <Route exact path="/courses/" component={CoursesHomeA1} />
        <Route exact path="/courses/A2" component={CoursesHomeA2} />
        <Route exact path="/courses/B1" component={CoursesHomeB1} />
        <Route exact path="/courses/loading" component={CoursesLoading} />
        <Route exact path="/courses/offline" component={CoursesOffline} />
        <Route exact path="/courses/error" component={CoursesError} />
        <Route exact path="/courses/archive" component={CoursesArchive} />
        <Route exact path="/courses/category" component={CoursesCategory} />
        <Route exact path="/courses/coupon" component={CoursesCoupon} />
        <Route
          exact
          path="/courses/subscription/premium"
          component={CoursesSubscriptionPayment}
        />
        <Route
          exact
          path="/courses/subscription/landing"
          component={CoursesSubscriptionLandingTwo}
        />
        <Route
          exact
          path="/courses/subscription/thanks"
          component={CoursesSubscriptionThanks}
        />
        <Route
          exact
          path="/courses/subscription"
          component={CoursesSubscriptionLanding}
        />
        <Route exact path="/courses/single" component={Single} />
        <Route exact path="/courses/singleB" component={SingleB} />
        <Route exact path="/courses/singleC" component={SingleC} />
        <Route exact path="/courses/single/gift" component={SingleCourseGift} />
        <Route
          exact
          path="/courses/single/payment"
          component={SingleCoursePayment}
        />
        <Route exact path="/courses/course/thanks" component={ThankYou} />
        <Route
          exact
          path="/courses/account/certificate"
          component={UserCertificate}
        />
        <Route exact path="/courses/account/wishlist" component={Wishlist} />
        <Route exact path="/courses/forum" component={Forum} />
        <Route
          exact
          path="/courses/forum/discussions"
          component={ForumDiscussion}
        />
        <Route exact path="/courses/forum/single" component={ForumSingle} />
        <Route exact path="/courses/learn" component={StepOne} />
        <Route exact path="/courses/learn/two" component={StepTwo} />
        <Route exact path="/courses/learn/three" component={StepThree} />
        <Route exact path="/courses/learn/four" component={StepFour} />
        <Route exact path="/courses/learn/five" component={StepFive} />
        <Route
          exact
          path="/courses/user-courses-without"
          component={UserCoursesWithout}
        />
        <Route
          exact
          path="/courses/user-courses-without"
          component={UserCoursesWithout}
        />
        <Route exact path="/courses/user-courses" component={UserCourses} />
        <Route exact path="/courses/quiz" component={CoursesQuiz} />

        <Route exact path="/unycos/" component={UnycosHome} />
        <Route exact path="/unycos/coupon" component={UnycosCoupon} />
        <Route exact path="/unycos/single" component={UnycosSingle} />
        <Route exact path="/unycos/terminos-de-uso" component={UnycosTerminosDeUso} />
        <Route exact path="/unycos/aviso-legal" component={UnycosAvisosLegales} />
        <Route exact path="/unycos/privacidad" component={UnycosPoliticaPrivacidad} />
        <Route exact path="/unycos/protection-data" component={UnycosProteccionDatos} />
        <Route exact path="/unycos/new-password" component={UnycosNewPassword} />
        <Route exact path="/unycos/recover-password" component={UnycosRecoverPassword} />
        <Route exact path="/unycos/single-two" component={UnycosSingleTwo} />
        <Route exact path="/unycos/single-three" component={UnycosSingleThree} />
        <Route exact path="/unycos/courses" component={UnycosCourses} />
        <Route exact path="/unycos/404" component={Unycos404} />
        <Route exact path="/unycos/gift" component={UnycosGift} />
        <Route exact path="/unycos/gift-recovery" component={UnycosGiftRecovery} />
        <Route exact path="/unycos/payment" component={UnycosPayment} />
        <Route exact path="/unycos/my-account" component={UnycosMyAccount} />
        <Route exact path="/unycos/my-account/start-course" component={UnycosStartCourse} />
        <Route exact path="/unycos/my-account/request-certificate" component={UnycosRequestCertificate} />

        <Route exact path="/consultation/schedule-new" component={ConsultationScheduleNew} />
        <Route exact path="/consultation/quiz" component={ConsultationQuizLanding} />
        <Route exact path="/consultation/quiz/app" component={ConsultationQuizLandingApp} />
        <Route exact path="/consultation/about" component={ConsultationAbout} />
        <Route
          exact
          path="/consultation/access-not-allowed"
          component={ConsultationAccessNotAllowed}
        />
        <Route
          exact
          path="/consultation/account"
          component={ConsultationAccount}
        />
        <Route
          exact
          path="/consultation/payment-methods"
          component={ConsultationPaymentMethods}
        />
        <Route
          exact
          path="/consultation/payment-preferences"
          component={ConsultationPaymentPreferences}
        />
        <Route
          exact
          path="/consultation/charges-preferences"
          component={ConsultationChargesPreferences}
        />
        <Route
          exact
          path="/consultation/payment-history"
          component={ConsultationPaymentHistory}
        />
        <Route
          exact
          path="/consultation/account-security"
          component={ConsultationAccountSecurity}
        />
        <Route
          exact
          path="/consultation/account-subscription"
          component={ConsultationAccountSubscription}
        />
        <Route
          exact
          path="/consultation/payment-processing"
          component={ConsultationPaymentProcessing}
        />
        <Route
          exact
          path="/consultation/consultations"
          component={ConsultationConsultations}
        />
        <Route
          exact
          path="/consultation/schedule-manage"
          component={ConsultationScheduleManage}
        />
        <Route
          exact
          path="/consultation/terms-and-conditions"
          component={ConsultationTerms}
        />
        <Route
          exact
          path="/consultation/informative-clause"
          component={ConsultationInformativeClause}
        />
        <Route
          exact
          path="/consultation/clinics"
          component={ConsultationClinics}
        />
        <Route
          exact
          path="/consultation/videocall"
          component={ConsultationVideocall}
        />
        <Route
          exact
          path="/consultation/profile"
          component={ConsultationProfile}
        />
        <Route
          exact
          path="/consultation/thank-you"
          component={ConsultationThankYouPage}
        />
        <Route
          exact
          path="/consultation/buy-confirmation"
          component={ConsultationBuyConfirmation}
        />
        <Route
          exact
          path="/consultation/reservations"
          component={ConsultationReservations}
        />
        <Route
          exact
          path="/consultation/publish"
          component={ConsultationPublishPart1}
        />
        <Route
          exact
          path="/consultation/publish-2"
          component={ConsultationPublishPart2}
        />
        <Route
          exact
          path="/consultation/publish-3"
          component={ConsultationPublishPart3}
        />
        <Route
          exact
          path="/consultation/publish-4"
          component={ConsultationPublishPart4}
        />
        <Route
          exact
          path="/consultation/professional-6"
          component={ConsultationProfessionalPart6}
        />
        <Route
          exact
          path="/consultation/search"
          component={ConsultationSearch}
        />
        <Route
          exact
          path="/consultation/archive-professionals"
          component={ConsultationArchiveProfessionals}
        />
        <Route
          exact
          path="/consultation/archive"
          component={ConsultationArchive}
        />
        <Route
          exact
          path="/consultation/single"
          component={ConsultationSingle}
        />
        <Route exact path="/consultation/" component={Consultation} />

        <Route exact path="/store" component={Store} />

        <Route exact path="/test" component={Inputs} />
        <Route exact path="/avatar" component={AvatarDemo} />
        <Route exact path="/mailchimp-modal" component={MailchimpModal} />
        <Route exact path="/paywall" component={Paywall} />
        <Route exact path="/adshide" component={AdsHide} />

        <Route exact path="/image" component={Image} />
        <Route exact path="/modal2fa" component={LoginModal2fa} />
        <Route exact path="/accordion" component={AccordionScreen} />
        <Route exact path="/accordionv2" component={AccordionScreenV2} />
        <Route exact path="/min-height-content" component={MinHeightContent} />

        <Route exact path="/" component={BlogHome} />
        <Route exact path="/category" component={BlogCategory} />
        <Route exact path="/about" component={BlogAbout} />
        <Route exact path="/join-us" component={BlogJoinUs} />
        <Route exact path="/category-new" component={BlogCategoryNew} />
        <Route exact path="/single" component={BlogSingle} />
        <Route
          exact
          path="/single-special-publi"
          render={(props) => (
            <BlogSingleSpecialPubli
              {...props}
              logo="https://cdn.worldvectorlogo.com/logos/google-2015.svg"
              bgColor="titles"
              borderColor="yellow"
              fontColor="regent-grey"
              adType="Especial publicidad"
              featuredImage="./img/dummy/portada.jpg"
            />
          )}
        />
        <Route exact path="/author" component={BlogAuthor} />
        <Route exact path="/archive" component={BlogArchive} />
        <Route exact path="/bookmarks" component={BlogBookmarks} />
        <Route exact path="/page" component={BlogPage} />
        <Route exact path="/search" component={BlogSearch} />
        <Route exact path="/feedback" component={BlogFeedback} />
        <Route exact path="/diseases" component={BlogDiseases} />
        <Route exact path="/medicines" component={BlogMedicines} />
        <Route exact path="/foods" component={BlogFoods} />
        <Route
          exact
          path="/feedback-sin-form"
          component={BlogFeedbackWithoutForm}
        />
        <Route exact path="/newsletter" component={BlogNewsletter} />
        <Route excat path="/404" component={Blog404} />
        <Route
          exact
          path="/feedback-sin-form"
          component={BlogFeedbackWithoutForm}
        />
        <Route exact path="/joinus" component={BlogJoinUs} />
        <Route exact path="/newsletter" component={BlogNewsletter} />
        <Route exact path="/about" component={BlogAbout} />
        <Route exact path="/404" component={Blog404} />
        <Route exact path="/subscription" component={BlogSubscription} />
        <Route exact path="/subscription/payment" component={BlogPayment} />
        <Route exact path="/subscription/payment/thanks" component={BlogPaymentThanks} />
      </Switch>
      <AppFooter />
    </Box>
  </BrowserRouter>
);

export default Router;
